import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, Drawer } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FlexContainer, Icon, Typography } from 'components/atoms';
import { Dropdown, FormControl, Search } from 'components/molecules';
import useWindowSize from 'hooks/useWindowSize';
import THEME from 'util/styledTheme';
import { optionFormatter } from 'util/functions';
import Edit from 'containers/Webapp/Orders/OrdersList/Edit';
import { OrderProps, OrderCardProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 15px;
`;

const CardContainer = styled(FlexContainer)`
  transition: 0.5s all;
`;

const CardSkeleton: React.FC = () => (
  <FlexContainer
    container
    padding='10px 20px 10px 10px'
    borderColor={THEME.colors.borderColor}
    backgroundColor='#FFFFFF'
    justify='flex-end'
  >
    <FlexContainer direction='column' width='90%'>
      <Skeleton width='80%' height={25} />
      <Skeleton width='60%' height={25} />
      <Skeleton width='70%' height={25} />
    </FlexContainer>
  </FlexContainer>
);

const OrderCard: React.FC<OrderCardProps> = ({
  data,
  selected,
  focus,
  toggleSelect,
  onEdit,
}) => {
  const size = useWindowSize();
  const [showCheckbox, setShowCheckbox] = useState<boolean>(false);

  return (
    <CardContainer
      id={data.order_id}
      container
      padding='10px 5px 10px 10px'
      backgroundColor={focus ? '#DBDBFF' : '#FFFFFF'}
      direction='column'
      alignItems='flex-end'
      borderColor={THEME.colors.borderColor}
      onMouseEnter={() => setShowCheckbox(true)}
      onMouseLeave={() => setShowCheckbox(false)}
    >
      <FlexContainer container alignItems='center' margin='0 0 5px'>
        <FlexContainer width='10%'>
          {(showCheckbox || selected || size.width <= 768) && (
            <Checkbox
              checked={selected}
              disableRipple
              color='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
              style={{ padding: 0 }}
              onClick={e => e.stopPropagation()}
              onChange={e => toggleSelect(e.target.checked)}
            />
          )}
        </FlexContainer>
        <FlexContainer
          width='90%'
          alignItems='center'
          justify='space-between'
          height='24px'
        >
          <FlexContainer container alignItems='center' justify='space-between'>
            <Typography fontSize={13} fontWeight={600} margin='0 5px 0 0'>
              {data.reference_code || data.code}
            </Typography>

            {data.batch && (
              <Typography
                fontSize={11}
                margin='0 5px 0 0'
                color='text.secondary'
              >
                {data.batch}
              </Typography>
            )}
          </FlexContainer>
          {size.width > 768 && (
            <FlexContainer alignItems='center'>
              <Dropdown
                label={<Icon icon='options-icon' size={20} />}
                direction='bottom'
                position='right'
                showCaret={false}
                options={[
                  // {
                  //   label: 'Detalle',
                  //   key: 'detail',
                  //   // onClick: () => onDetail(),
                  //   onClick: () => {},
                  // },
                  {
                    label: 'Editar',
                    key: 'edit',
                    onClick: () => onEdit(),
                  },
                ]}
                padding='0 5px'
                height='20px'
              />
            </FlexContainer>
          )}
        </FlexContainer>
      </FlexContainer>
      <FlexContainer width='90%' direction='column'>
        <FlexContainer
          container
          alignItems='center'
          justify='space-between'
          margin='0 0 5px 0'
        >
          <Typography fontSize={12}>{data.contact_person}</Typography>
        </FlexContainer>
        {!data.all_coordinates_found && data.pending_message !== '' ? (
          <FlexContainer container alignItems='center'>
            <Icon icon='error-icon' size={18} margin='0 5px 0 0' />
            <Typography fontSize={11} color={THEME.colors.error}>
              {data.pending_message}
            </Typography>
          </FlexContainer>
        ) : (
          <>
            <Typography fontSize={11} color='text.secondary'>
              {data.address}
            </Typography>
            {data.address_detail && (
              <Typography fontSize={11} color='text.secondary'>
                {data.address_detail}
              </Typography>
            )}
          </>
        )}
      </FlexContainer>
    </CardContainer>
  );
};

const Orders: React.FC<OrderProps> = ({
  orders,
  focusOrder,
  loading,
  selectedOrders,
  onSelectOrder,
  originOptions,
  windowOptions,
  origin,
  onOriginChange,
  timeWindow,
  onWindowChange,
}) => {
  const [search, setSearch] = useState<string>('');
  const [filteredOrders, setFilteredOrders] = useState<Array<any>>([]);
  const [formattedOrigins, setFormattedOrigins] = useState<Array<any>>([]);
  const [formattedWindows, setFormattedWindows] = useState<Array<any>>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [editId, setEditId] = useState<null | string>(null);

  useEffect(() => {
    if (originOptions.length > 0) {
      setFormattedOrigins(
        optionFormatter(originOptions, {
          id: item => JSON.stringify(item),
          name: item => item.address,
        })
      );
    }
  }, [originOptions]);

  useEffect(() => {
    if (windowOptions.length > 0) {
      const defaultOption = {
        id: '',
        name: 'Ninguna',
      };
      setFormattedWindows(
        [defaultOption].concat(
          optionFormatter(windowOptions, {
            id: item => JSON.stringify(item),
            name: item => `${item.start_hour} - ${item.end_hour}`,
          })
        )
      );
    }
  }, [windowOptions]);

  const filterOrders = () => {
    let filtered = orders;
    if (search) {
      filtered = orders.filter(order => {
        const aux = {
          code: order.code,
          status_label: order.status_label,
          contact_person: order.contact_person,
          address: order.address,
          address_detail: order.address_detail,
          id: order.id,
          reference_code: order.reference_code,
          worker: order.worker,
          batch: order.batch,
        };
        return JSON.stringify(aux)
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    }
    setFilteredOrders(filtered);
  };

  useEffect(() => {
    setSearch('');
    setFilteredOrders(orders);
  }, [orders]);

  return (
    <FlexContainer
      container
      height='100%'
      overflow='auto'
      direction='column'
      backgroundColor='#FFFFFF'
      borderRadius='5px'
      style={{ zIndex: 1002 }}
    >
      <FlexContainer container padding='10px' direction='column'>
        <FlexContainer
          container
          alignItems='center'
          justify='space-between'
          margin='0 0 15px'
        >
          <Search
            onChangeValue={value => setSearch(value)}
            searchValue={search}
            onSubmit={() => filterOrders()}
          />
        </FlexContainer>

        <Typography color='text.secondary' fontSize={13} margin='0 0 10px'>
          Origen:
        </Typography>
        <FormControl
          control='selectSearch'
          options={formattedOrigins}
          placeholder='Seleccione origen'
          value={origin}
          onChange={value => onOriginChange(value)}
          width='100%'
          margin='0 0 15px'
        />

        <Typography color='text.secondary' fontSize={13} margin='0 0 10px'>
          Ventana horaria
        </Typography>
        <FormControl
          control='select'
          options={formattedWindows}
          placeholder='Seleccione ventana (opcional)'
          value={timeWindow}
          onChange={value => onWindowChange(value)}
          width='100%'
          margin='0 0 15px'
        />

        <FlexContainer container alignItems='center' justify='space-between'>
          <FlexContainer alignItems='center'>
            <Checkbox
              checked={
                filteredOrders.length > 0 &&
                filteredOrders.every(item =>
                  selectedOrders.includes(item.order_id)
                )
              }
              disableRipple
              color='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
              style={{ padding: 0 }}
              onChange={e => {
                onSelectOrder(
                  filteredOrders.map(order => order.order_id),
                  e.target.checked
                );
              }}
            />
            <Typography color='text.secondary' fontSize={13} margin='0 0 0 5px'>
              {loading ? (
                <Skeleton width='200px' height={25} />
              ) : (
                `${filteredOrders.length} órdenes encontradas`
              )}
            </Typography>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      {loading ? (
        <>
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </>
      ) : (
        <>
          {filteredOrders.map(order => (
            <OrderCard
              key={order.order_id}
              data={order}
              focus={order.order_id === focusOrder}
              selected={selectedOrders.includes(order.order_id)}
              toggleSelect={checked => onSelectOrder([order.order_id], checked)}
              onEdit={() => {
                setEditId(order.order_id);
                setOpenDrawer(true);
              }}
            />
          ))}
        </>
      )}

      <Drawer
        anchor='right'
        open={openDrawer}
        onClose={() => {
          setEditId(null);
          setOpenDrawer(false);
        }}
      >
        <FlexContainer
          width='1200px'
          position='relative'
          padding='20px 50px 50px'
          direction='column'
        >
          <CloseIcon
            onClick={() => {
              setEditId(null);
              setOpenDrawer(false);
            }}
          >
            <Icon
              icon='cancel-icon'
              color={THEME.colors.placeholderColor}
              size={25}
            />
          </CloseIcon>
          <Edit editId={editId} onEdit={() => onOriginChange(origin, true)} />
        </FlexContainer>
      </Drawer>
    </FlexContainer>
  );
};

export default Orders;
