import React from 'react';
import IcoMoon from 'react-icomoon';
import styled from 'styled-components';
import { IconProps, IconStyleProps } from './types';

const iconSet = require('./selection.json');

const StyledIcon = styled(IcoMoon)<IconStyleProps>`
  ${props => props.color && `color: ${props.color};`}
  ${props => props.margin && `margin: ${props.margin};`}
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

const Icon: React.FC<IconProps> = ({ icon, size, onClick, margin, color }) => {
  return (
    <StyledIcon
      iconSet={iconSet}
      icon={icon}
      size={size}
      onClick={e => (onClick ? onClick(e) : {})}
      margin={margin}
      color={color}
    />
  );
};

export default Icon;
