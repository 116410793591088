import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { Spinner } from 'components/molecules';
import { FlexContainer, Icon } from 'components/atoms';
import { ExpandCell, AimoContainer } from 'components/organisms/DataTable';
import THEME from 'util/styledTheme';
import { DetailTableProps } from './types';

export const HeaderTableCell = styled(TableCell)`
  padding: 10px 0 0 10px;
  color: #222349;
  font-size: 10px;
  font-weight: 700;
  border-bottom: none !important;
`;

const DetailTable: React.FC<DetailTableProps> = ({
  data,
  dataIdentifier,
  expandedData,
  headers,
  expandedHeaders,
  expandable,
  onExpand,
  loading,
  photorable,
  photoHeaders,
  photosData,
  detailable,
  detailData,
  detailHeaders,
}) => {
  const [expanded, setExpanded] = useState<string>('');

  return (
    <AimoContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map(header => (
              <TableCell key={header.id}>{header.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <Spinner height='350px' />
            </TableRow>
          ) : (
            <>
              {data.map(item => (
                <React.Fragment
                  key={dataIdentifier ? item[dataIdentifier] : item.id}
                >
                  <TableRow>
                    {headers.map(head => (
                      <TableCell key={head.id}>{head.cell(item)}</TableCell>
                    ))}
                  </TableRow>
                  {expanded === item.id && (
                    <>
                      {expandedData.map(infoItem => (
                        <React.Fragment key={infoItem.id}>
                          <TableRow>
                            {expandedHeaders.map(infoHead => (
                              <HeaderTableCell
                                key={infoHead.id}
                                colSpan={infoHead.colSpan || 1}
                              >
                                {infoHead.label}
                              </HeaderTableCell>
                            ))}
                          </TableRow>
                          <TableRow>
                            {expandedHeaders.map(expInfo => (
                              <TableCell
                                key={expInfo.id}
                                colSpan={expInfo.colSpan || 1}
                              >
                                {expInfo.cell(infoItem)}
                              </TableCell>
                            ))}
                          </TableRow>
                        </React.Fragment>
                      ))}
                      {photorable &&
                        photosData.map(photoItem => (
                          <React.Fragment key={photoItem.id}>
                            <TableRow>
                              {photoHeaders.map(photoHead => (
                                <HeaderTableCell
                                  key={photoHead.id}
                                  colSpan={photoHead.colSpan || 1}
                                >
                                  {photoHead.label}
                                </HeaderTableCell>
                              ))}
                            </TableRow>
                            <TableRow key={photoItem.id}>
                              {photoHeaders.map(expPhoto => (
                                <TableCell
                                  key={expPhoto.id}
                                  colSpan={expPhoto.colSpan || 1}
                                  style={{ backgroundColor: '#FFFFFF' }}
                                >
                                  {expPhoto.cell(photoItem)}
                                </TableCell>
                              ))}
                            </TableRow>
                          </React.Fragment>
                        ))}
                      {detailable &&
                        detailData.map(detailItem => (
                          <React.Fragment key={detailItem.id}>
                            <TableRow>
                              <TableCell colSpan={headers.length}>
                                <FlexContainer
                                  container
                                  justify='flex-start'
                                  backgroundColor='#FFFFFF'
                                >
                                  <Typography color='primary'>
                                    Detalle de Entrega
                                  </Typography>
                                </FlexContainer>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              {detailHeaders.map(detailHead => (
                                <HeaderTableCell
                                  key={detailHead.id}
                                  colSpan={detailHead.colSpan || 1}
                                >
                                  {detailHead.label}
                                </HeaderTableCell>
                              ))}
                            </TableRow>
                            <TableRow key={detailItem.id}>
                              {detailHeaders.map(expDetail => (
                                <TableCell
                                  key={expDetail.id}
                                  colSpan={expDetail.colSpan || 1}
                                  style={{ backgroundColor: '#FFFFFF' }}
                                >
                                  {expDetail.cell(detailItem)}
                                </TableCell>
                              ))}
                            </TableRow>
                          </React.Fragment>
                        ))}
                    </>
                  )}
                  {expandable && (
                    <TableRow>
                      <ExpandCell colSpan={headers.length}>
                        <FlexContainer
                          container
                          justify='center'
                          backgroundColor={THEME.colors.borderColor}
                          padding='2px'
                          onClick={() => {
                            if (item.id === expanded) {
                              setExpanded('');
                            } else {
                              setExpanded(item.id);
                              onExpand(item.id);
                            }
                          }}
                        >
                          {expanded === item.id ? (
                            <div style={{ transform: 'rotate(180deg)' }}>
                              <Icon icon='arrow-icon' size={15} />
                            </div>
                          ) : (
                            <Icon icon='arrow-icon' size={15} />
                          )}
                        </FlexContainer>
                      </ExpandCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </AimoContainer>
  );
};

export default DetailTable;
