export type MenuType = {
  name: string;
  url: string;
  img: string;
  extraMargin?: boolean;
  path?: boolean;
  permissions?: Array<string>;
  submenu?: Array<any>;
};

const menus: Array<MenuType> = [
  {
    name: 'Inicio',
    url: '/home',
    img: 'home-icon1',
  },
  {
    name: 'Monedero',
    url: '/monedero',
    img: 'wallet-icon',
    permissions: ['core.wallet.retrieve'],
  },
  {
    name: 'Métricas',
    url: '/reportes',
    img: 'reports-menu1',
    submenu: [
      {
        name: 'Métricas',
        url: '/reportes',
        path: true,
        // img: 'my-products-menu1',
        // permissions: ['core.order.list'],
      },
      {
        name: 'Histórico',
        url: '/reportes/historico',
        path: true,
        // permissions: ['core.order.list', 'core.route.list'],
      },
      {
        name: 'Mapa de calor',
        url: '/reportes/mapa_de_calor',
        path: true,
        // permissions: ['core.order.list', 'core.route.list'],
      },
      {
        name: 'Reportes',
        url: '/reportes/reportes',
        path: true,
        permissions: ['analytics.report.list'],
      },
    ],
  },
  {
    name: 'Monitoreo',
    url: '/monitoreo',
    img: 'dashboard-icon',
    permissions: ['front_permission.monitoring.show'],
  },
  {
    name: 'Mis productos',
    url: '/inventario',
    img: 'my-products-menu1',
    // permissions: ['core.order.list'],
    // submenu: [
    //   {
    //     name: 'Productos',
    //     url: '/inventario',
    //     // img: 'my-products-menu1',
    //     // permissions: ['core.order.list'],
    //   },
    //   {
    //     name: 'Categorías',
    //     url: '/inventario',
    //     // permissions: ['core.order.list', 'core.route.list'],
    //   },
    //   {
    //     name: 'Inventario',
    //     url: '/inventario',
    //     // permissions: ['core.order.list', 'core.route.list'],
    //   },
    // ],
  },
  {
    name: 'Mis órdenes',
    url: '/ordenes',
    img: 'orders-menu1',
    permissions: ['core.order.list', 'core.route.list'],
    submenu: [
      {
        name: 'Órdenes compra',
        url: '/ordenes-de-compra',
        permissions: ['core.order.list'],
      },
      {
        name: 'Órdenes',
        url: '/ordenes',
        permissions: ['core.order.list'],
      },
      {
        name: 'Rutas',
        url: '/rutas',
        permissions: ['core.route.list'],
      },
    ],
  },
  // {
  //   name: 'Rutas',
  //   url: '/rutas',
  //   img: 'tracking-menu',
  //   permissions: ['core.route.list'],
  // },
  {
    name: 'Flota',
    url: '/flota/',
    img: 'logistics-menu1',
    permissions: [
      'core.worker.list',
      'core.vehicle.list',
      'core.provider.list',
    ],
  },
  {
    name: 'Clientes',
    url: '/customers',
    img: 'my-clients-menu1',
    // permissions: ['core.client.list'],
    submenu: [
      {
        name: 'Mis clientes',
        url: '/customers',
        permissions: ['core.customers.list'],
        // img: 'my-products-menu1',
        // permissions: ['core.order.list'],
      },
      {
        name: 'Clientes org.',
        url: '/clientes',
        permissions: ['core.client.list'],
      },
    ],
  },
  // {
  //   name: 'Clientes org.',
  //   url: '/clientes',
  //   img: 'client-menu',
  //   permissions: ['core.client.list'],
  // },
  // {
  //   name: 'Reportes',
  //   url: '/reportes',
  //   img: 'reports-menu',
  // },
  {
    name: 'Configuración',
    url: '/configuracion',
    img: 'settings-menu1',
  },
];

export default menus;
