import React from 'react';
import { FlexContainer } from 'components/atoms';
import { CircularProgress } from '@material-ui/core';
import { SpinnerProps } from './types';

const Spinner: React.FC<SpinnerProps> = ({
  height,
  spinnerSize,
  colorSecondary,
  margin,
}) => (
  <FlexContainer
    container
    height={height}
    alignItems='center'
    justify='center'
    margin={margin}
  >
    <CircularProgress
      size={spinnerSize || 45}
      color={colorSecondary ? 'secondary' : 'primary'}
    />
  </FlexContainer>
);

export default Spinner;
