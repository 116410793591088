import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import useRequest from 'hooks/useRequest';
import useForm from 'hooks/useForm';
import { Grid } from '@material-ui/core';
import {
  Button,
  Divider,
  FlexContainer,
  Typography,
  Icon,
} from 'components/atoms';
import { FormControl, Spinner } from 'components/molecules';
import { SearchWithResults } from 'components/organisms';
import { useTypedSelector } from 'store';
import THEME from 'util/styledTheme';
import {
  postVehicles,
  patchVehicles,
  getVehiclesTypeList,
  getWorkersForVehicles,
} from 'services';
import { workerFormatter } from 'util/functions';
import { EditTypes } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const validationState = {
  vehicleTypeId: ['mandatory'],
  plate: ['mandatory'],
};

const EditVehicles: React.FC<EditTypes> = ({ data, onCreate, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedOrganization = useTypedSelector(
    state => state.organization.selectedOrganization
  );
  const branchOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  const [form, onFormChange, validate, errors, reset] = useForm(
    {
      vehicleTypeId: data && data.vehicle_type ? data.vehicle_type.id : '',
      model: data ? data.model : '',
      plate: data ? data.plate : '',
      insuranceNumber: data ? data.insurance_number : '',
      expirationDate: data ? data.insurance_expiration_date : null,
      color: data ? data.color : '',
      name: data ? data.name : '',
      workerName: data && data.worker ? data.worker.full_name : '',
    },
    validationState
  );

  // API calls
  const [vehicleTypes, fetchVehicleTypes, loadingVehicleTypes] = useRequest(
    getVehiclesTypeList,
    []
  );
  const [updatedVehicle, updateVehicle, loadingUpdate] = useRequest(
    patchVehicles
  );
  const [createdVehicle, createVehicle, loadingCreate] = useRequest(
    postVehicles
  );
  const [workers, fetchWorkers] = useRequest(getWorkersForVehicles, null);
  // Values
  const [assignSearchOptions, setAssignSearchOptions] = useState([]);
  const [workerId, setWorkerId] = useState(null);
  const [isWorkerDisplayed, setIsWorkerDisplayed] = useState(
    data && data.worker
  );

  useEffect(() => {
    fetchVehicleTypes({
      page_size: 0,
    });
  }, [fetchVehicleTypes]);

  const handleSearchWorker = (search: string) => {
    fetchWorkers({
      search,
      organization_branch_office_id: branchOfficeId,
    });
  };

  useEffect(() => {
    if (workers) {
      if (workers.length === 0) {
        enqueueSnackbar('No se encontraron coincidencias para su búsqueda.', {
          variant: 'warning',
        });
      } else {
        setAssignSearchOptions(workerFormatter(workers));
      }
    }
  }, [workers, enqueueSnackbar]);

  useEffect(() => {
    if (!isEmpty(createdVehicle)) {
      enqueueSnackbar('El vehículo fue creado satisfactoriamente', {
        variant: 'success',
      });
      onCreate();
      reset();
    }
  }, [createdVehicle, enqueueSnackbar, reset]);

  useEffect(() => {
    if (!isEmpty(updatedVehicle)) {
      enqueueSnackbar('Se actualizó el vehículo correctamente.', {
        variant: 'success',
      });
      onCreate();
    }
  }, [updatedVehicle, enqueueSnackbar]);

  const validateForm = () => {
    if (validate()) {
      if (data) {
        const editVehicle = {
          vehicle_type_id: form.vehicleTypeId,
          model: form.model,
          plate: form.plate,
          insurance_number: form.insuranceNumber,
          insurance_expiration_date: form.expirationDate,
          worker_id: workerId,
        };
        updateVehicle(editVehicle, data.id);
      } else {
        const newVehicle = {
          vehicle_type_id: form.vehicleTypeId,
          model: form.model,
          plate: form.plate,
          insurance_number: form.insuranceNumber,
          insurance_expiration_date: form.expirationDate,
          organization: selectedOrganization.id,
        };
        createVehicle(newVehicle);
      }
    }
  };

  return (
    <FlexContainer
      container
      minWidth='800px'
      position='relative'
      padding='40px 50px 50px'
      direction='column'
    >
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <Typography fontSize={18} margin='10px 0 0'>
        {data ? 'Editar Vehículo' : 'Creación de Vehículo'}
      </Typography>
      <Divider orientation='horizontal' margin='15px 0 30px' />

      <FlexContainer
        container
        direction='column'
        padding='30px 30px'
        margin='0 0 30px'
        borderRadius='10px'
        backgroundColor={THEME.colors.backgroundColor}
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer
          container
          justify='space-between'
          alignItems='center'
          margin='0 0 30px'
        >
          <Typography
            color={THEME.colors.primary}
            fontWeight={500}
            fontSize={17}
            margin='10px 0 0 0'
          >
            Información básica del vehículo
          </Typography>
        </FlexContainer>

        <FlexContainer container direction='column'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                control='select'
                label='Tipo de Vehículo'
                placeholder='Seleccione vehículo'
                onChange={value => onFormChange(value, 'vehicleTypeId')}
                value={form.vehicleTypeId}
                options={vehicleTypes}
                margin='0 0 20px'
                error={errors.vehicleTypeId}
                width='100%'
                disabled={loadingCreate || loadingUpdate || loadingVehicleTypes}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Placa'
                placeholder='Escriba la placa del vehículo'
                onChange={value => onFormChange(value, 'plate')}
                value={form.plate}
                margin='0 0 20px'
                error={errors.plate}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Modelo'
                placeholder='Escriba el modelo del vehículo'
                onChange={value => onFormChange(value, 'model')}
                value={form.model}
                margin='0 0 20px'
                error={errors.model}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Color'
                placeholder='Escriba el color del vehículo'
                onChange={value => onFormChange(value, 'color')}
                value={form.color}
                margin='0 0 20px'
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='SOAT'
                placeholder='Escriba el número de SOAT del vehículo'
                onChange={value => onFormChange(value, 'insuranceNumber')}
                value={form.insuranceNumber}
                margin='0 0 20px'
                error={errors.insuranceNumber}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                control='date'
                label='Fecha de vencimiento del SOAT'
                placeholder='Escriba la fecha de vencimiento'
                onChange={value => onFormChange(value, 'expirationDate')}
                value={form.expirationDate}
                margin='0 0 20px'
                error={errors.expirationDate}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
                toolbar={false}
              />
            </Grid>
          </Grid>
        </FlexContainer>
      </FlexContainer>

      {data && (
        <FlexContainer
          container
          direction='column'
          padding='30px 30px'
          margin='0 0 30px'
          borderRadius='10px'
          backgroundColor={THEME.colors.backgroundColor}
          borderColor={THEME.colors.borderColor}
        >
          <FlexContainer
            container
            justify='space-between'
            alignItems='center'
            margin='0 0 30px'
          >
            <Typography
              color={THEME.colors.primary}
              fontWeight={500}
              fontSize={17}
            >
              Buscar worker para asignar:
            </Typography>
          </FlexContainer>
          <Grid container spacing={3}>
            <FlexContainer margin='10px 60px 20px 10px' container>
              <SearchWithResults
                onSearch={search => {
                  setAssignSearchOptions([]);
                  handleSearchWorker(search);
                }}
                onSelect={id => setWorkerId(id)}
                searchOptions={assignSearchOptions}
              />
            </FlexContainer>
          </Grid>
          {isWorkerDisplayed && data.worker && (
            <FlexContainer alignItems='center' margin='0 0 30px' container>
              <Typography>Asignado a:</Typography>
              <FlexContainer
                margin='0 0 0 10px'
                padding='4px'
                backgroundColor={THEME.colors.borderColor}
                borderRadius='10px'
                justify='space-between'
                alignItems='center'
              >
                <Typography fontSize='13' margin='0 10px'>
                  {form.workerName}
                </Typography>
                <FlexContainer margin='0 10px'>
                  <Icon
                    icon='cancel-icon'
                    size='18'
                    onClick={() => {
                      setWorkerId(null);
                      setIsWorkerDisplayed(null);
                      enqueueSnackbar(
                        'Guarda tus cambios para liberar el worker',
                        {
                          variant: 'warning',
                        }
                      );
                    }}
                  />
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          )}
        </FlexContainer>
      )}

      <Button
        variant='contained'
        color='primary'
        padding='5px 30px'
        onClick={() => validateForm()}
      >
        {(loadingCreate || loadingUpdate) && (
          <Spinner
            height='15px'
            spinnerSize={20}
            colorSecondary
            margin='0 8px 0 0'
          />
        )}
        {data ? 'Guardar' : 'Crear'}
      </Button>
    </FlexContainer>
  );
};

export default EditVehicles;
