import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'store';
import {
  FlexContainer,
  Typography,
  Divider,
  Button,
  Icon,
} from 'components/atoms';
import { Header } from 'components/organisms';
import evalPermission from 'util/evalPermission';
import { ReactComponent as Arrow } from 'assets/images/arrow-left.svg';
import OrdersList from './OrdersList';
import MassiveCharge from './MassiveCharge';
import CreateOrder from './CreateOrder';

const Orders: React.FC = () => {
  const showMenu = useTypedSelector(state => state.sidebar);
  const user = useTypedSelector(state => state.user);
  const location = useLocation();

  return (
    <FlexContainer container height='100%'>
      <Header showMenu={showMenu} />
      <FlexContainer container padding='50px 0 0'>
        <FlexContainer direction='column' container height='100vh'>
          <FlexContainer direction='column' container padding='35px 40px 0'>
            <FlexContainer
              container
              justify='space-between'
              alignItems='center'
              padding='0 0 20px'
            >
              <FlexContainer alignItems='center'>
                {location.pathname !== '/ordenes' &&
                  location.pathname !== '/ordenes/' && (
                    <Link to='/ordenes'>
                      <FlexContainer margin='0 5px 0 0'>
                        <Arrow />
                      </FlexContainer>
                    </Link>
                  )}
                <Typography fontSize={26} fontWeight={600} color='#000929'>
                  Órdenes
                </Typography>
              </FlexContainer>
              <FlexContainer>
                {user.permissions.includes('core.order.batch_xls') && (
                  <Button
                    variant='contained'
                    color='secondary'
                    margin='0 10px 0 0'
                    padding='0 8px'
                    borderRadius='50%'
                    onClick={() =>
                      window.open('/ordenes/carga-masiva', '_self')
                    }
                  >
                    <Icon icon='upload' size={25} />
                  </Button>
                )}
                {user.permissions.includes('core.order.create') && (
                  <Button
                    variant='contained'
                    color='primary'
                    margin='0'
                    padding='12px 20px'
                    onClick={() => window.open('/ordenes/crear-orden', '_self')}
                  >
                    <Icon icon='plus-icon' size={22} margin='0 5px 0 0' />
                    Crear
                  </Button>
                )}
              </FlexContainer>
            </FlexContainer>
            <Divider orientation='horizontal' type='solid' color='#E0DEF7' />
          </FlexContainer>
          <Switch>
            <Route
              exact
              path='/ordenes'
              render={() =>
                evalPermission(user.permissions, 'core.order.list', OrdersList)
              }
            />
            <Route
              exact
              path='/ordenes/carga-masiva'
              render={() =>
                evalPermission(
                  user.permissions,
                  'core.order.batch_xls',
                  MassiveCharge
                )
              }
            />
            <Route
              exact
              path='/ordenes/crear-orden'
              render={() =>
                evalPermission(
                  user.permissions,
                  'core.order.create',
                  CreateOrder
                )
              }
            />
          </Switch>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Orders;
