import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useLocation, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import { Spinner } from 'components/molecules';
import { FlexContainer, Typography, Button } from 'components/atoms';
import { verifyToken } from 'services';
import AimoLogoImage from 'assets/images/AimoLogoFull.svg';

const SuccessRegistration: React.FC = () => (
  <FlexContainer
    container
    height='100%'
    direction='column'
    alignItems='center'
    justify='center'
  >
    <FlexContainer
      margin='15px 0'
      padding='5px'
      direction='column'
      alignItems='center'
    >
      <FlexContainer
        direction='column'
        justify='center'
        alignItems='center'
        height='100%'
      >
        <img
          src={AimoLogoImage}
          alt='aimo-logo'
          width='160px'
          height='28px'
          style={{ margin: '20px 30px 50px 0' }}
        />
      </FlexContainer>
      <Typography fontWeight={700} fontSize='18px' textAlign='center'>
        ¡Felicitaciones su cuenta ha sido verificada con éxito!
      </Typography>
      <FlexContainer width='60%'>
        <Link to='/login' style={{ width: '100%', textDecoration: 'none' }}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            borderRadius='28px'
            padding='20px 20px'
          >
            <Typography
              fontSize='15px'
              fontWeight='700'
              color='white'
              letterSpacing='0.5px'
            >
              Inicia sesión
            </Typography>
          </Button>
        </Link>
      </FlexContainer>
    </FlexContainer>
  </FlexContainer>
);

const FailedRegistration: React.FC = () => (
  <FlexContainer
    container
    height='100%'
    direction='column'
    alignItems='center'
    justify='center'
  >
    <FlexContainer
      margin='15px 0'
      padding='5px'
      direction='column'
      alignItems='center'
    >
      <Typography fontWeight={700} fontSize='18px' margin='40px 0'>
        La cuenta ha sido verificada anteriormente
      </Typography>
      <FlexContainer width='70%'>
        <Link to='/login' style={{ width: '100%', textDecoration: 'none' }}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            borderRadius='28px'
            padding='20px 20px'
          >
            <Typography
              fontSize='15px'
              fontWeight='700'
              color='white'
              letterSpacing='0.5px'
            >
              Inicia sesión
            </Typography>
          </Button>
        </Link>
      </FlexContainer>
    </FlexContainer>
  </FlexContainer>
);

const VerifySignUp: React.FC = () => {
  const location = useLocation();
  const windowHost = window.location.host;
  const { enqueueSnackbar } = useSnackbar();
  const [, , , , ,] = useForm(
    {
      token: '',
    },
    {}
  );
  // API calls
  const [, verify, loadingVerify, , errorRequest, statusRequest] = useRequest(
    verifyToken
  );

  // Values
  const [isSuccess, setIsSuccess] = useState<null | boolean>(null);
  const [emailVerified, setEmailVerified] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    setEmailVerified(
      token.substring(token.indexOf(':') + 1, token.lastIndexOf(':'))
    );

    verify({
      verify_token: token,
    });
  }, [location]);

  useEffect(() => {
    if (statusRequest === 201) {
      setIsSuccess(true);

      // @ts-ignores
      window.analytics.reset();

      if (windowHost === 'app.aimo.co') {
        // @ts-ignores
        window.analytics.identify(emailVerified, {
          created_at: moment().format(),
        });
        // @ts-ignores
        window.analytics.track('Sign up', {
          email: emailVerified,
          created_at: moment().format(),
        });
      }
    } else if (
      (statusRequest && statusRequest !== 201) ||
      (errorRequest && errorRequest.status === 500)
    ) {
      setIsSuccess(false);
    }
  }, [statusRequest, enqueueSnackbar, errorRequest, emailVerified]);

  return (
    <FlexContainer
      container
      height='100vh'
      direction='column'
      justify='center'
      alignItems='center'
    >
      {loadingVerify ? (
        <FlexContainer
          container
          padding='30px 40px'
          direction='column'
          justify='center'
          alignItems='center'
          height='80vh'
        >
          <Spinner height='350px' />
        </FlexContainer>
      ) : (
        <>
          {isSuccess ? (
            <>
              <SuccessRegistration />
            </>
          ) : (
            <FailedRegistration />
          )}
        </>
      )}
    </FlexContainer>
  );
};

export default VerifySignUp;
