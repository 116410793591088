import React from 'react';
import styled from 'styled-components';
import { FlexContainer, Select } from 'components/atoms';
import { Search } from 'components/molecules';
import { SearchFilterProps } from './types';

const CustomSelectContainer = styled.div`
  // .MuiOutlinedInput-root {
  //   border-top-right-radius: 0;
  //   border-bottom-right-radius: 0;
  // }
`;

const CustomInputContainer = styled.div`
  width: 100%;
  // .MuiOutlinedInput-root {
  //   border-top-left-radius: 0;
  //   border-bottom-left-radius: 0;
  // }
`;

const SearchWithFilter: React.FC<SearchFilterProps> = ({
  filterLabel,
  options,
  filterValue,
  onChangeFilterValue,
  searchValue,
  onChangeSearchValue,
  onSubmit,
  maxWidth = '700px',
  placeholder,
  inputWidth,
}) => {
  return (
    <FlexContainer container maxWidth={maxWidth}>
      <CustomSelectContainer>
        <Select
          placeholder={filterLabel}
          options={options}
          value={filterValue}
          onChange={id => onChangeFilterValue(id)}
          width='160px'
        />
      </CustomSelectContainer>
      <CustomInputContainer>
        <Search
          onChangeValue={value => onChangeSearchValue(value)}
          searchValue={searchValue}
          onSubmit={() => onSubmit()}
          placeholder={placeholder}
          width={inputWidth}
        />
      </CustomInputContainer>
    </FlexContainer>
  );
};

export default SearchWithFilter;
