import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import useRequest from 'hooks/useRequest';
import { cloneDeep } from 'lodash';
import { Drawer } from '@material-ui/core';
import { Button, FlexContainer, Typography, Icon } from 'components/atoms';
import { Cell, Spinner } from 'components/molecules';
import {
  DataTable,
  FiltersContainer,
  SearchWithFilter,
  AssignBox,
} from 'components/organisms';
import THEME from 'util/styledTheme';
import DefaultVehicle from 'assets/images/DefaultVehicle.svg';
import { useTypedSelector } from 'store';
import {
  getVehiclesList,
  assignProviderVehicles,
  getProvidersMinimal,
  getVehiclesTypeList,
  getProviders,
} from 'services';
import {
  providerFormatter,
  optionFormatter,
  providerOptionFormatter,
} from 'util/functions';
import Minutes99 from 'assets/images/providers/99minutos.png';
import Olva from 'assets/images/providers/olva.jpg';
import Glovo from 'assets/images/providers/glovo.svg';
import Savar from 'assets/images/providers/savar.png';
import Logo from 'assets/images/DefaultLogo.png';
import Chazki from 'assets/images/providers/chazki.png';
import Moova from 'assets/images/providers/moova.png';
import { ReactComponent as MoreOptions } from 'assets/images/filter_icon.svg';
import NoData from 'assets/images/no_data.svg';
import EditVehicles from '../Edit';

const initialFilters = [
  {
    label: 'Tipo de vehículo',
    placeholder: 'Seleccione vehículo',
    name: 'vehicle_type__id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
  },
  {
    label: 'Proveedor',
    placeholder: 'Seleccione Proveedor',
    name: 'provider__id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
    permission: 'core.provider.list',
  },
];

const options = [
  {
    name: 'Placa',
    id: 'plate',
  },
  {
    name: 'Worker',
    id: 'worker_name',
  },
];

const PlateContainer = styled(FlexContainer)`
  &:hover {
    background-color: ${THEME.colors.secondaryBackground};
  }
`;

const MoreOptionIcon = styled(MoreOptions)`
  .cls-2 {
    stroke: #8c8cb1;
  }

  ${({ active }) =>
    active &&
    `
    .cls-2{
      stroke: #404071;
    }
  `}
`;

const VehiclesList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const branchOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  const user = useTypedSelector(state => state.user);
  const [filterValues, setFilterValues] = useState({
    vehicle_type__id: null,
    provider__id: null,
  });

  // API calls
  const [vehicles, fetchVehicles, loadingVehicles, pageData] = useRequest(
    getVehiclesList,
    []
  );
  const [vehicleTypes, fetchVehicleTypes, loadingVehicleTypes] = useRequest(
    getVehiclesTypeList,
    []
  );
  const [providers, fetchProviders] = useRequest(getProvidersMinimal, null);
  const [providerList, fetchProviderList, loadingProviderList] = useRequest(
    getProviders,
    []
  );
  const [, providerAssign, , , , statusRequest] = useRequest(
    assignProviderVehicles,
    null
  );
  // Values
  const [filters, setFilters] = useState(initialFilters);
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [page, setPage] = useState<number>(1);

  const [assignOption, setAssignOption] = useState<string>('');
  const [assignSearchOptions, setAssignSearchOptions] = useState([]);

  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState(null);

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [firstLoadingVehicles, setFirstLoadingVehicles] = useState<boolean>(
    false
  );
  const [firstVehiclesRender, setFirstVehiclesRender] = useState<boolean>(
    false
  );
  const [searchWithFilter, setSearchWithFilter] = useState<boolean>(false);

  const requestVehicles = (currentPage: number) => {
    setSelectedRows([]);
    const params = {
      vehicle_type__id: filterValues.vehicle_type__id,
      provider__id: filterValues.provider__id,
      page: currentPage,
    };

    if (searchFilter !== '') {
      params[searchFilter] = searchInput;
    }
    setPage(currentPage);
    fetchVehicles(params);
    setFirstVehiclesRender(false);
  };

  const handleSearch = (search: string) => {
    if (selectedRows.length === 0) {
      enqueueSnackbar('Seleccione al menos un vehículo para asignar.', {
        variant: 'error',
      });
      return;
    }

    fetchProviders({
      organization_branch_office_id: branchOfficeId,
      vehicles: selectedRows.join(''),
      search,
    });
  };

  const handleAssign = (providerId: string) => {
    providerAssign({
      provider_id: providerId,
      vehicle_ids: selectedRows,
      organization_branch_office_id: branchOfficeId,
    });
  };

  const handleResetAssign = () => {
    setAssignOption('');
    setSearchInput('');
    setAssignSearchOptions([]);
  };

  useEffect(() => {
    fetchProviderList({
      page_size: 0,
    });
    fetchVehicleTypes({
      page_size: 0,
    });
  }, [fetchProviderList, fetchVehicleTypes]);

  useEffect(() => {
    requestVehicles(page);
    setFirstVehiclesRender(true);
  }, []);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    if (updatedFilters[0].options.length === 0 && vehicleTypes.length > 0) {
      updatedFilters[0].options = optionFormatter(vehicleTypes);
      setFilters(updatedFilters);
    }
  }, [filters, vehicleTypes]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    if (updatedFilters[1].options.length === 0 && providerList.length > 0) {
      updatedFilters[1].options = providerOptionFormatter(providerList);
      setFilters(updatedFilters);
    }
  }, [filters, providerList]);

  useEffect(() => {
    if (providers) {
      if (providers.length === 0) {
        enqueueSnackbar('No se encontraron coincidencias para su búsqueda.', {
          variant: 'warning',
        });
      } else {
        const pickImage = code => {
          switch (code) {
            case '99minutos':
              return Minutes99;
            case 'olva':
              return Olva;
            case 'glovo':
              return Glovo;
            case 'savar':
              return Savar;
            case 'chazki':
              return Chazki;
            case 'moova':
              return Moova;
            default:
              return '';
          }
        };
        setAssignSearchOptions(
          providerFormatter(providers, {
            id: option => option.id,
            name: option => option.business_name,
            phone: option => option.phone,
            logo: option =>
              option.code ? pickImage(option.code) : option.logo || Logo,
          })
        );
      }
    }
  }, [providers, enqueueSnackbar]);

  useEffect(() => {
    if (statusRequest === 200) {
      enqueueSnackbar('Se asignó el vehiculo al proveedor correctamente.', {
        variant: 'success',
      });
      requestVehicles(page);
      handleResetAssign();
    } else if (statusRequest && statusRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusRequest, enqueueSnackbar]);

  useEffect(() => {
    if (searchWithFilter) {
      setFirstVehiclesRender(false);
    } else if (firstVehiclesRender && loadingVehicles && page === 1) {
      setFirstLoadingVehicles(true);
    } else {
      setFirstLoadingVehicles(false);
    }
  }, [firstVehiclesRender, loadingVehicles]);

  const headers = useMemo(
    () => [
      {
        label: 'Placa',
        id: 'vehicle_plate',
        cell: row =>
          row.plate ? (
            <PlateContainer
              width='80%'
              padding='2px'
              backgroundColor={THEME.colors.borderColor}
              borderRadius='4px'
              justify='center'
              cursor='pointer'
              onClick={() => {
                if (user.permissions.includes('core.vehicle.update')) {
                  setEditData(row);
                  setOpenEdit(true);
                }
              }}
            >
              <Typography fontSize='13'>{row.plate}</Typography>
            </PlateContainer>
          ) : (
            '-'
          ),
      },
      {
        label: 'Tipo de Vehículo',
        id: 'vehicle_type',
        cell: row =>
          row.vehicle_type.image ? (
            <Cell.Image src={row.vehicle_type.image || DefaultVehicle}>
              <Typography color={THEME.colors.textSecondary}>
                {row.vehicle_type.name}
              </Typography>
            </Cell.Image>
          ) : (
            '-'
          ),
      },
      {
        label: 'Modelo',
        id: 'vehicle_model',
        cell: row => row.model || '-',
      },
      {
        label: 'SOAT',
        id: 'insurance_number',
        cell: row => row.insurance_number || '-',
      },
      {
        label: 'Vencimiento del SOAT',
        id: 'insurance_expiration_date',
        cell: row =>
          row.insurance_expiration_date
            ? moment(row.insurance_expiration_date).format('LL')
            : '-',
      },
      {
        label: 'Proveedor',
        id: 'provider_business_name',
        cell: row => (row.provider ? row.provider.business_name : '-'),
      },
      {
        label: 'Worker',
        id: 'worker_full_name',
        cell: row => (row.worker ? row.worker.full_name : '-'),
      },
    ],
    [selectedRows]
  );

  return (
    <>
      {firstLoadingVehicles ? (
        <FlexContainer
          container
          padding='30px 40px'
          direction='column'
          justify='center'
          alignItems='center'
          height='80vh'
        >
          <Spinner height='350px' />
        </FlexContainer>
      ) : (
        <FlexContainer container padding='30px 40px' direction='column'>
          {firstVehiclesRender && !searchWithFilter && vehicles.length === 0 ? (
            <FlexContainer
              container
              direction='column'
              justify='center'
              alignItems='center'
              height='70vh'
            >
              <img src={NoData} alt='no_data' />
              <Typography color='text.secondary'>
                No hay vehículos registrados
              </Typography>
              <FlexContainer>
                {user.permissions.includes('core.vehicle.create') && (
                  <Button
                    variant='contained'
                    color='primary'
                    margin='20px 0 0 20px'
                    padding='0 40px'
                    fontSize='13px'
                    onClick={() => setOpenEdit(true)}
                  >
                    Crear vehículo
                  </Button>
                )}
              </FlexContainer>
            </FlexContainer>
          ) : (
            <>
              <FlexContainer
                container
                alignItems='center'
                justify='space-between'
                margin='0 0 30px'
              >
                <Typography fontSize={18} fontWeight={300}>
                  Vehículos
                </Typography>
              </FlexContainer>
              <FlexContainer
                container
                direction='column'
                margin='0 0 20px'
                padding='20px'
                backgroundColor='#FFFFFF'
                borderRadius='4px'
                borderColor={THEME.colors.borderColor}
              >
                <FlexContainer container justify='space-between'>
                  <FlexContainer container>
                    <SearchWithFilter
                      filterLabel='Buscar por'
                      filterValue={searchFilter}
                      onChangeFilterValue={value => setSearchFilter(value)}
                      options={options}
                      onChangeSearchValue={value => setSearchInput(value)}
                      searchValue={searchInput}
                      onSubmit={() => requestVehicles(1)}
                      inputWidth='250px'
                      maxWidth='550px'
                      placeholder='Buscar'
                    />

                    <Button
                      variant='contained'
                      color='primary'
                      margin='0 10px 0 5px'
                      onClick={() => {
                        requestVehicles(1);
                        setSearchWithFilter(true);
                      }}
                    >
                      Buscar
                    </Button>
                  </FlexContainer>

                  <FlexContainer container>
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={() => setShowOptions(!showOptions)}
                    >
                      <MoreOptionIcon
                        active={
                          Object.values(filterValues).some(
                            value => value !== null
                          )
                            ? 'active'
                            : ''
                        }
                      />
                    </Button>
                  </FlexContainer>

                  <FlexContainer justify='flex-end'>
                    {user.permissions.includes(
                      'core.vehicle.assign_provider'
                    ) && (
                      <Button
                        variant='outlined'
                        color='primary'
                        fontSize='13px'
                        onClick={() => setAssignOption('vehículo')}
                      >
                        Asignar Proveedor
                      </Button>
                    )}
                    {user.permissions.includes('core.vehicle.create') && (
                      <Button
                        color='primary'
                        margin='0 0 0 20px'
                        fontSize='13px'
                        startIcon={<Icon icon='plus-icon' size={18} />}
                        onClick={() => setOpenEdit(true)}
                      >
                        Crear Vehiculo
                      </Button>
                    )}
                  </FlexContainer>
                </FlexContainer>

                {showOptions && (
                  <FiltersContainer
                    labelWeight={600}
                    filters={filters}
                    value={filterValues}
                    onChange={(value, name) =>
                      setFilterValues({
                        ...filterValues,
                        [name]: value,
                      })
                    }
                    disabled={loadingVehicleTypes || loadingProviderList}
                    permissions={user.permissions}
                  />
                )}
              </FlexContainer>

              {assignOption !== '' && (
                <AssignBox
                  title='Busca al proveedor al que desees asignar'
                  type='search'
                  onSearch={search => {
                    setAssignSearchOptions([]);
                    handleSearch(search);
                  }}
                  onSelect={id => handleAssign(id)}
                  onClose={() => {
                    setAssignOption('');
                    setAssignSearchOptions([]);
                  }}
                  selectOptions={[]}
                  searchOptions={assignSearchOptions}
                />
              )}

              <DataTable
                headers={headers}
                data={vehicles}
                selectedRows={selectedRows}
                onSelectRow={rows => setSelectedRows(rows)}
                onChangePage={selectedPage => requestVehicles(selectedPage)}
                totalPages={pageData.totalPages}
                totalItems={pageData.totalItems}
                pageSize={pageData.pageSize}
                page={page}
                loading={loadingVehicles}
                selectable
              />
            </>
          )}
          <Drawer
            anchor='right'
            open={openEdit}
            onClose={() => {
              setEditData(null);
              setOpenEdit(false);
            }}
          >
            <EditVehicles
              data={editData}
              onCreate={() => requestVehicles(page)}
              onClose={() => {
                setEditData(null);
                setOpenEdit(false);
              }}
            />
          </Drawer>
        </FlexContainer>
      )}
    </>
  );
};

export default VehiclesList;
