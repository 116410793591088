import {
  UserData,
  USER_SET,
  USER_CLEAR,
  USER_ONBOARDING,
  UserActionTypes,
} from './types';

const initialState: UserData = {
  id: '',
  permissions: [],
  full_name: '',
  photo: null,
  document_type: '',
  national_identity_document: '',
  email: '',
  phone: '',
  onboarding: true,
};

export function user(state = initialState, action: UserActionTypes): UserData {
  switch (action.type) {
    case USER_SET:
      return action.payload;
    case USER_CLEAR:
      return initialState;
    case USER_ONBOARDING:
      return {
        ...state,
        onboarding: action.payload,
      };
    default:
      return state;
  }
}

export default user;
