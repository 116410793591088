import React from 'react';
import { FlexContainer, Typography } from 'components/atoms';
import THEME from 'util/styledTheme';
import { StatusProps } from './types';

const Status: React.FC<StatusProps> = ({ color, label, fontSize, margin }) => (
  <FlexContainer
    alignItems='center'
    borderColor={color || THEME.colors.primary}
    borderRadius='8px'
    padding='3px 8px'
    style={{ display: 'inline-flex' }}
    margin={margin}
  >
    <FlexContainer
      backgroundColor={color || THEME.colors.primary}
      borderRadius='50px'
      width='5px'
      height='5px'
      margin='0 5px 0 0'
    />
    <Typography color={color || THEME.colors.primary} fontSize={fontSize}>
      {label}
    </Typography>
  </FlexContainer>
);

export default Status;
