import React, { useState } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox, LinearProgress, Tooltip } from '@material-ui/core';
import { FlexContainer, Icon, Typography, Status } from 'components/atoms';
import useWindowSize from 'hooks/useWindowSize';
import THEME from 'util/styledTheme';
import statusColors from 'util/statusColors';
import { RouteCardProps } from './types';

const Progress = withStyles({
  root: {
    height: 7,
    backgroundColor: THEME.colors.borderColor,
  },
  bar: {
    backgroundColor: THEME.colors.purpleBackground,
  },
})(LinearProgress);

export const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: THEME.colors.purpleBackground,
    color: '#FFF',
    fontSize: 12,
    marginBottom: 7,
    padding: 8,
  },
  arrow: {
    color: THEME.colors.purpleBackground,
  },
})(Tooltip);

const StatusCircle = styled.div<{ code?: string }>`
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background-color: ${props =>
    statusColors[props.code] || statusColors.default};
`;

const Divider = styled.div`
  height: 20px;
  border-left: 1px solid ${props => props.theme.colors.borderColor};
  margin: 0 8px;
`;

const CardContainer = styled(FlexContainer)<{ active: boolean }>`
  background-color: #ffffff;
  &:hover {
    background-color: #ffffff;
  }
  @media only screen and (min-width: 768px) {
    background-color: ${props =>
      props.active ? props.theme.colors.secondary : '#ffffff'};
    &:hover {
      background-color: ${props => props.theme.colors.secondary};
    }
  }
`;

const RouteCard: React.FC<RouteCardProps> = ({
  data,
  active,
  selected,
  onDetail,
  toggleSelect,
  toggleVisibility,
  showRoute,
}) => {
  const size = useWindowSize();
  const [showCheckbox, setShowCheckbox] = useState<boolean>(false);

  const finished = data.tasks.reduce((prev, next) => {
    if (
      next &&
      !next.is_start &&
      (next.status === 'completed' || next.status === 'failed')
    ) {
      return prev + 1;
    }
    return prev;
  }, 0);

  return (
    <CardContainer
      container
      padding='10px 20px 10px 10px'
      borderColor={THEME.colors.borderColor}
      cursor='pointer'
      direction='column'
      alignItems='flex-end'
      active={active}
      onMouseEnter={() => setShowCheckbox(true)}
      onMouseLeave={() => setShowCheckbox(false)}
      onClick={() => {
        if (size.width > 768) {
          onDetail();
        }
      }}
    >
      <FlexContainer container alignItems='center'>
        <FlexContainer width='10%'>
          {(showCheckbox || selected || size.width <= 768) && (
            <Checkbox
              checked={selected}
              disableRipple
              color='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
              style={{ padding: 0 }}
              onClick={e => e.stopPropagation()}
              onChange={e => toggleSelect(e.target.checked)}
            />
          )}
        </FlexContainer>
        <FlexContainer
          width='90%'
          alignItems='center'
          justify='space-between'
          height='24px'
        >
          <FlexContainer alignItems='center'>
            <Typography fontSize={13} fontWeight={600} margin='0 5px 0 0'>
              {data.reference_code || data.code}
            </Typography>
            {size.width > 768 && (
              <CustomTooltip arrow placement='top' title={data.status_label}>
                <div style={{ display: 'inline-block' }}>
                  <StatusCircle code={data.status} />
                </div>
              </CustomTooltip>
            )}
          </FlexContainer>
          {size.width > 768 ? (
            <FlexContainer
              onClick={e => {
                e.stopPropagation();
                toggleVisibility();
              }}
            >
              {showRoute ? (
                <Icon icon='show-icon' size={20} />
              ) : (
                <Icon icon='no-show-icon' size={20} />
              )}
            </FlexContainer>
          ) : (
            <Status
              color={statusColors[data.status] || statusColors.default}
              label={data.status_label}
              fontSize={12}
            />
          )}
        </FlexContainer>
      </FlexContainer>
      <FlexContainer width='90%' direction='column'>
        <FlexContainer
          container
          alignItems='flex-end'
          justify='space-between'
          margin='0 0 5px 0'
        >
          <FlexContainer direction='column'>
            <Typography fontSize={12} color='text.secondary'>
              {data.worker &&
                `${data.worker.first_name} ${data.worker.last_name}`}
            </Typography>
            <Typography fontSize={12} color='text.secondary'>
              {data.vehicle && `${data.vehicle.plate}`}
            </Typography>
          </FlexContainer>

          {data.provider_data && (
            <FlexContainer
              backgroundColor={THEME.colors.borderColor}
              alignItems='center'
              justify='center'
              padding='2px 10px'
              borderRadius='5px'
            >
              <Typography color='text.secondary' fontSize={12}>
                {data.provider_data.provider_name}
              </Typography>
            </FlexContainer>
          )}
        </FlexContainer>

        <FlexContainer container alignItems='center' justify='space-between'>
          <FlexContainer width='35%' direction='column'>
            <Progress
              variant='determinate'
              value={(finished / data.total_orders) * 100}
            />
            <Typography fontSize={11} color='text.secondary' margin='5px 0 0'>
              {`${finished} de ${data.total_orders} órdenes`}
            </Typography>
          </FlexContainer>

          <FlexContainer justify='flex-end' alignItems='center'>
            <Typography fontSize={11} color='text.secondary'>
              {`${data.estimated_duration} hrs`}
            </Typography>
            <Divider />
            <Typography fontSize={11} color='text.secondary'>
              {`${data.estimated_distance} km`}
            </Typography>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </CardContainer>
  );
};

export default RouteCard;
