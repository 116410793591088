import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { useTypedSelector } from 'store';
import {
  clearOrganization,
  selectBranchOffice,
} from 'store/organization/actions';
import { logout } from 'store/session/actions';
import { setUser, clearUser, toggleOnboarding } from 'store/user/actions';
import Layout from 'components/Layout';
import { FirstLogin } from 'components/organisms';
import { FlexContainer, Icon, Typography, Button } from 'components/atoms';
import { getProfile, getOrganization } from 'services';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import { ReactComponent as AimoLogo } from 'assets/images/AimoLogoIcon.svg';
import Whatsapp from 'assets/images/whatsapp-black.svg';
import Email from 'assets/images/email-black.svg';
import Clients from './Clients';
import Fleet from './Fleet';
import Orders from './Orders';
import MyOrders from './PurchaseOrders';
import Inventory from './Inventory';
import Routes from './Routes';
import Settings from './Settings';
import Metrics from './Metrics';
import Dashboard from './Dashboard';
// import Onboarding from './Onboarding';
import Platforms from './Shopify';
import Purse from './Purse';
import Home from './Home';
import Client from './Client';

const LoadingSection = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled(AimoLogo)`
  width: 80px;
  height: 80px;
`;

const LoadingContainer = styled.div`
  width: 76px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DotsContainer = styled.div`
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Dots = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #5b5be5;
`;

const dot = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
`;

const FirstDot = styled(Dots)`
  animation: ${dot} 0.4s ease 0s infinite alternate;
`;

const SecondDot = styled(Dots)`
  animation: ${dot} 0.4s ease 0.2s infinite alternate;
`;

const ThirdDot = styled(Dots)`
  animation: ${dot} 0.4s ease 0.4s infinite alternate;
`;

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const CheckIcon = styled(FlexContainer)`
  margin: 70px 0 20px 0;
`;

const ThanksMessage: React.FC<{ onClose: () => void }> = ({ onClose }) => (
  <FlexContainer
    container
    position='relative'
    direction='column'
    alignItems='center'
    borderRadius='25px'
  >
    <CloseIcon onClick={() => onClose()}>
      <Icon
        icon='cancel-icon'
        color={THEME.colors.placeholderColor}
        size={25}
      />
    </CloseIcon>
    <CheckIcon>
      <Icon icon='check-icon' color={THEME.colors.placeholderColor} size={90} />
    </CheckIcon>
    <Typography
      color='text.primary'
      fontSize='28px'
      textAlign='center'
      fontWeight={600}
    >
      Bienvenido!
    </Typography>
    <Typography
      color='text.primary'
      fontSize='16px'
      textAlign='center'
      margin='0 0 35px 0'
    >
      Estamos aquí para ayudarte.
    </Typography>
  </FlexContainer>
);

const Webapp: React.FC = () => {
  const windowUrl = window.location.host;
  const location = useLocation();
  const dispatch = useDispatch();
  const organization = useTypedSelector(state => state.organization);
  const user = useTypedSelector(state => state.user);
  const [onboardingLoading, setOnboardingLoading] = useState(true);
  const [toOnboarding, setToOnboarding] = useState(false);
  const [showThankMessage, setThankMessage] = useState(false);

  const [profile, fetchProfile, loading] = useRequest(getProfile);
  const [orgaRetrieve, fetchOrganization] = useRequest(getOrganization);
  const [sentEvent, setSentEvent] = useState(true);

  useEffect(() => {
    fetchOrganization(organization.selectedOrganization.id);
    if (!organization.selectedBranchOffice.id) {
      if (organization.selectedOrganization.typeUser.branchOffices.length > 0) {
        dispatch(
          selectBranchOffice({
            id: organization.selectedOrganization.typeUser.branchOffices[0].id,
            name:
              organization.selectedOrganization.typeUser.branchOffices[0].name,
            reference_code:
              organization.selectedOrganization.typeUser.branchOffices[0]
                .reference_code,
          })
        );
      } else {
        localStorage.clear();
        dispatch(clearUser());
        dispatch(clearOrganization());
        dispatch(logout());
      }
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(profile)) {
      if (profile.client_id !== null) {
        localStorage.setItem(
          'AIMO_ADMIN_CLIENT_ID',
          profile.client_id.toString()
        );
      }
      dispatch(
        setUser({
          id: profile.user_id,
          permissions: profile.permissions,
          full_name: profile.full_name,
          photo: profile.photo,
          document_type: profile.document_type,
          national_identity_document: profile.national_identity_document,
          email: profile.email,
          phone: profile.phone,
          role: profile.role,
        })
      );
    }
  }, [profile]);

  useEffect(() => {
    if (user.permissions.length === 0) {
      fetchProfile();
    } else {
      // eslint-disable-next-line no-lonely-if
      if (user.permissions.length > 0 && orgaRetrieve.id && sentEvent) {
        // eslint-disable-next-line no-lonely-if
        if (windowUrl === 'app.aimo.co') {
          // @ts-ignore
          window.analytics.identify(user.email, {
            name: user.full_name,
            email: user.email,
            phone: user.phone,
            company_id: organization.selectedOrganization.id,
            company_name: organization.selectedOrganization.name,
            stote_url: orgaRetrieve.store_url,
            created_at: moment(orgaRetrieve.created_at).format('DD/MM/YYYY'),
          });

          // @ts-ignores
          window.analytics.track('User login', {
            name: user.full_name,
            email: user.email,
            phone: user.phone,
            company_id: organization.selectedOrganization.id,
            tradename: organization.selectedOrganization.name,
            area: orgaRetrieve.product_category
              ? orgaRetrieve.product_category
              : '',
            ordersPerDay: orgaRetrieve.orders_per_day
              ? orgaRetrieve.orders_per_day
              : '',
            delivery: orgaRetrieve.delivery ? orgaRetrieve.delivery : '',
            productSalesMeans: orgaRetrieve.product_sales_means
              ? orgaRetrieve.product_sales_means
              : '',
            producesContent: orgaRetrieve.produces_content
              ? orgaRetrieve.produces_content
              : '',
            created_at: moment().format(),
          });
        }
        setSentEvent(false);
      }
    }
  }, [user, orgaRetrieve]);

  useEffect(() => {
    if (!isEmpty(orgaRetrieve)) {
      if (orgaRetrieve.is_filled) {
        dispatch(toggleOnboarding(false));
      } else {
        dispatch(toggleOnboarding(true));
        setToOnboarding(true);
      }
      setOnboardingLoading(false);
    }
  }, [orgaRetrieve]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  if (loading || onboardingLoading) {
    return (
      <LoadingSection>
        <Logo alt='aimo' />
        <LoadingContainer>
          <DotsContainer>
            <FirstDot />
            <SecondDot />
            <ThirdDot />
          </DotsContainer>
        </LoadingContainer>
      </LoadingSection>
    );
  }

  return (
    <Layout>
      <Switch>
        <Route
          exact
          path='/'
          render={() => {
            return <Redirect to='/home' />;
          }}
        />
        <Route path='/home' component={Home} />
        <Route path='/monedero' component={Purse} />
        <Route path='/reportes' component={Metrics} />
        <Route path='/monitoreo' component={Dashboard} />
        <Route path='/inventario' component={Inventory} />
        <Route path='/ordenes' component={Orders} />
        <Route path='/ordenes-de-compra' component={MyOrders} />
        <Route path='/rutas' component={Routes} />
        <Route path='/flota' component={Fleet} />
        <Route path='/clientes' component={Clients} />
        <Route path='/customers' component={Client} />
        <Route path='/configuracion' component={Settings} />
        {/* <Route path='/onboarding' component={Onboarding} /> */}
        <Route path='/shopify' component={Platforms} />
      </Switch>
      {toOnboarding && (
        <Dialog
          maxWidth='sm'
          open={toOnboarding}
          scroll='paper'
          fullWidth
          PaperProps={{
            style: { borderRadius: 12 },
          }}
        >
          <FirstLogin
            formCompleted={() => {
              setThankMessage(true);
              setToOnboarding(false);
            }}
          />
        </Dialog>
      )}
      {showThankMessage && (
        <Dialog
          maxWidth='sm'
          open={showThankMessage}
          onClose={() => setThankMessage(false)}
          fullWidth
          PaperProps={{
            style: { borderRadius: 12 },
          }}
        >
          <DialogContent dividers>
            <ThanksMessage onClose={() => setThankMessage(false)} />
          </DialogContent>
          <DialogActions>
            <FlexContainer container alignItems='center' direction='column'>
              <Typography
                color='text.primary'
                fontSize='12.5px'
                textAlign='center'
                margin='25px 0 0 0'
              >
                Contactanos acá
              </Typography>
              <FlexContainer container justify='center' margin='20px 0 20px 0'>
                <Button
                  customColor='#DBD9F5'
                  borderRadius='50%'
                  padding='0'
                  height='38px'
                  width='38px'
                  margin='0 10px'
                  onClick={() => (window.location.href = `mailto:hola@aimo.co`)}
                >
                  <img src={Email} alt='email' />
                </Button>
                <Button
                  customColor='#FFF2E4'
                  borderRadius='50%'
                  padding='0'
                  height='38px'
                  width='38px'
                  margin='0 10px'
                  onClick={() =>
                    window.open(`https://wa.me/51946565757?text=¡Hola!`)
                  }
                >
                  <img src={Whatsapp} alt='whatsapp' />
                </Button>
              </FlexContainer>
            </FlexContainer>
          </DialogActions>
        </Dialog>
      )}
    </Layout>
  );
};

export default Webapp;
