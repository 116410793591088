import {
  SelectedOrganization,
  BranchOffices,
  ORGANIZATION_CLEAR,
  ORGANIZATION_SELECT,
  BRANCH_OFFICE_SELECT,
  OrganizationActionTypes,
} from './types';

interface InitialState {
  selectedOrganization: SelectedOrganization;
  selectedBranchOffice: BranchOffices;
}

const initialState: InitialState = {
  selectedOrganization: null,
  selectedBranchOffice: {
    id: null,
    name: null,
    reference_code: null,
  },
};

export function organization(
  state = initialState,
  action: OrganizationActionTypes
): InitialState {
  switch (action.type) {
    case ORGANIZATION_SELECT:
      return {
        ...state,
        selectedOrganization: action.payload,
      };
    case BRANCH_OFFICE_SELECT:
      return {
        ...state,
        selectedBranchOffice: action.payload,
      };
    case ORGANIZATION_CLEAR:
      return initialState;
    default:
      return state;
  }
}

export default organization;
