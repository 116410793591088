import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Button, Card, FlexContainer, Typography } from 'components/atoms';
import { installShopify } from 'services';
import { LinearProgress } from '@material-ui/core';
import THEME from 'util/styledTheme';
import { useTypedSelector } from 'store';
import ShopifyStep2 from 'assets/images/ShopifyStep2.png';
import ShopifyStep3 from 'assets/images/ShopifyStep3.png';
import ShopifyStep4 from 'assets/images/ShopifyStep4.png';
import AimoLogo from 'assets/images/AimoLogoFull.svg';

const ShopIntegration: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState<boolean>(false);
  const [installing, setInstalling] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(
    'Hubo un error con la url de tu tienda.'
  );
  const session = useTypedSelector(state => state.session);
  const selectedOrganization = useTypedSelector(
    state => state.organization.selectedOrganization
  );
  const authorize = session && selectedOrganization;

  const shopifyRequest = () => {
    const searchParams = new URLSearchParams(location.search);
    const shop = searchParams.get('shop');
    const state = searchParams.get('state');

    if (shop && state) {
      if (authorize) {
        setInstalling(true);
        installShopify({
          shop,
          state,
        })
          .then(() => {
            setMessage(`Your store ${shop} was connected successfully.`);
            localStorage.removeItem('shopify');
          })
          .catch(err => {
            if (err.response.status === 400) {
              setError(true);
            } else {
              setError(true);
            }
            setMessage(`There was an error connecting your store ${shop}`);
          })
          .finally(() => {
            setInstalling(false);
          });
      } else {
        window.localStorage.setItem(
          'shopify',
          JSON.stringify({
            shop,
            state,
          })
        );
      }
    }
  };

  useEffect(() => {
    shopifyRequest();
  }, [location]);

  return (
    <FlexContainer
      container
      height='100%'
      direction='column'
      padding='30px'
      justify='center'
      alignItems='center'
    >
      <img src={AimoLogo} alt='Aimo' style={{ margin: '20px' }} />

      {installing || error ? (
        <FlexContainer
          direction='column'
          width='400px'
          height='400px'
          alignItems='center'
          justify='center'
        >
          {installing && (
            <>
              <LinearProgress />
              <Typography fontSize={14} margin='20px 0 0'>
                Estamos conectando tu tienda, espera por favor.
              </Typography>
            </>
          )}
          {error && (
            <Typography fontSize={14} margin='20px 0 0'>
              {message}
            </Typography>
          )}
        </FlexContainer>
      ) : (
        <>
          <FlexContainer
            margin='20px 0'
            width='100%'
            maxWidth='980px'
            borderColor={THEME.colors.borderColor}
          >
            <Card width='100%' height='100%' padding='30px' display='flex'>
              <Typography fontSize={16}>
                Step 1: Connect Aimo Account
              </Typography>
              {authorize && location.search ? (
                <Typography fontSize={14} margin='20px 0 0'>
                  {message}
                </Typography>
              ) : (
                <>
                  <Typography fontSize={14} margin='20px 0 0'>
                    If you have a Routific account, click &quot;Login&ldquo;
                    below.
                  </Typography>
                  <Typography fontSize={14} margin='20px 0 0'>
                    Don&apos;t have an account yet? Choose &quot;Signup&ldquo;
                    to create an Aimo account.
                  </Typography>
                  <FlexContainer justify='flex-start'>
                    <Button
                      variant='contained'
                      color='primary'
                      margin='20px 0px'
                      onClick={() => history.push('/login')}
                    >
                      Login
                    </Button>
                    <Button
                      variant='contained'
                      color='secondary'
                      margin='20px 10px'
                      onClick={() => history.push('/registro')}
                    >
                      Signup
                    </Button>
                  </FlexContainer>
                </>
              )}
            </Card>
          </FlexContainer>

          {authorize && location.search && (
            <FlexContainer
              margin='20px 0'
              width='100%'
              maxWidth='980px'
              borderColor={THEME.colors.borderColor}
            >
              <Card width='100%' height='100%' padding='30px' display='flex'>
                <Typography fontSize={16}>
                  Update settings and customize how to import orders
                </Typography>
                <Typography fontSize={14} margin='20px 0 0'>
                  You are using the default settings
                </Typography>
                <FlexContainer justify='flex-start'>
                  <Button
                    variant='contained'
                    color='primary'
                    margin='20px 0px'
                    onClick={() => history.push('/configuracion/plataformas')}
                  >
                    Edit Settings
                  </Button>
                </FlexContainer>
              </Card>
            </FlexContainer>
          )}

          <FlexContainer
            margin='20px 0'
            width='100%'
            maxWidth='980px'
            borderColor={THEME.colors.borderColor}
          >
            <Card width='100%' height='100%' padding='30px' display='flex'>
              <Typography fontSize={16}>
                Step 2: Connect or Create Logistic Providers
              </Typography>
              <Typography fontSize={14} margin='20px 0 0'>
                Go to&nbsp;
                <Link to='/flota/proveedores' target='_blank'>
                  Proveedores
                </Link>
                &nbsp;section and activate the providers you want to work with.
              </Typography>
              <img
                src={ShopifyStep2}
                alt='Connect Providers'
                style={{
                  margin: '20px 0',
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Card>
          </FlexContainer>
          <FlexContainer
            margin='20px 0'
            width='100%'
            maxWidth='980px'
            borderColor={THEME.colors.borderColor}
          >
            <Card width='100%' height='100%' padding='30px' display='flex'>
              <Typography fontSize={16}>
                Step 3: Select orders to send to Aimo
              </Typography>
              <Typography fontSize={14} margin='20px 0 0'>
                Select paid orders in your store and click the option in the
                menu to send to Aimo.
              </Typography>
              <img
                src={ShopifyStep3}
                alt='Send orders to Aimo'
                style={{
                  margin: '20px 0',
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Card>
          </FlexContainer>
          <FlexContainer
            margin='20px 0'
            width='100%'
            maxWidth='980px'
            borderColor={THEME.colors.borderColor}
          >
            <Card width='100%' height='100%' padding='30px' display='flex'>
              <Typography fontSize={16}>
                Step 4: Dispatch your orders
              </Typography>
              <Typography fontSize={14} margin='20px 0 0'>
                Go to&nbsp;
                <Link to='/ordenes' target='_blank'>
                  order list
                </Link>
                &nbsp;section, if you agree with the assigned provider select
                the order and click on &quot;Confirmar Provider&ldquo;. If you
                need to change it click on &quot;Asignar Provider&ldquo;.
              </Typography>
              <iframe
                title='Assign Provider'
                style={{ margin: '20px 0' }}
                width='100%'
                height='500'
                src='https://www.youtube-nocookie.com/embed/YDKPPFV0Zos'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />

              <Typography fontSize={14} margin='20px 0 0'>
                You can verify on your store the order was fulfilled and has a
                shipping carrier and tracking link.
              </Typography>
              <img
                src={ShopifyStep4}
                alt='Verify order was fulfilled'
                style={{
                  margin: '20px 0',
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Card>
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );
};

export default ShopIntegration;
