import React, { useState } from 'react';
import { Radio } from '@material-ui/core';
import {
  Divider,
  FlexContainer,
  Icon,
  Status,
  Typography,
} from 'components/atoms';
import { Dropdown } from 'components/molecules';
import useWindowSize from 'hooks/useWindowSize';
import THEME from 'util/styledTheme';
import statusColors from 'util/statusColors';
import { OrderCardProps } from './types';

const DetailCard: React.FC<OrderCardProps> = ({
  data,
  toggleSelect,
  onDetail,
  showDetail,
}) => {
  const size = useWindowSize();
  const [showCheckbox, setShowCheckbox] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <FlexContainer
      container
      padding='10px'
      backgroundColor='#FFFFFF'
      borderColor={THEME.colors.borderColor}
      direction='column'
      alignItems='flex-end'
      onMouseEnter={() => {
        if (!data.is_end && !data.is_start && data.can_change_sequence) {
          setShowCheckbox(true);
        }
      }}
      onMouseLeave={() => setShowCheckbox(false)}
    >
      <FlexContainer container alignItems='center' margin='0 0 10px'>
        <FlexContainer width='10%'>
          {showCheckbox ||
          checked ||
          (size.width <= 768 &&
            !data.is_end &&
            !data.is_start &&
            data.can_change_sequence) ? (
            <Radio
              checked={checked}
              disableRipple
              color='primary'
              onClick={e => {
                e.stopPropagation();
                setChecked(!checked);
                toggleSelect(!checked);
              }}
              style={{ padding: 0 }}
            />
          ) : (
            <FlexContainer
              width='20px'
              height='20px'
              justify='center'
              alignItems='center'
              borderColor={THEME.colors.borderColor}
              borderRadius='20px'
              margin='0 10px 0 0'
            >
              <Typography fontSize={12}>{data.current_sequence}</Typography>
            </FlexContainer>
          )}
        </FlexContainer>
        <FlexContainer width='90%' justify='space-between' alignItems='center'>
          <FlexContainer
            padding='5px'
            borderColor={THEME.colors.borderColor}
            backgroundColor={THEME.colors.backgroundColor}
            borderRadius='5px'
          >
            <Typography fontSize={12} color='text.secondary' margin='0 5px 0 0'>
              Actual
            </Typography>
            <Typography fontSize={12} margin='0 3px 0 0'>
              {data.current_eta}
            </Typography>
            <Divider orientation='vertical' margin='0 8px' />
            <Typography fontSize={12} color='text.secondary' margin='0 5px 0 0'>
              Original
            </Typography>
            <Typography fontSize={12} margin='0 3px 0 0'>
              {data.original_eta}
            </Typography>
          </FlexContainer>

          <Typography
            fontSize={12}
            color='text.secondary'
            margin={showDetail ? '0' : '0 20px 0 0'}
          >
            {`${data.time_window_start} - ${data.time_window_end}`}
          </Typography>

          {showDetail && (
            <Dropdown
              label={<Icon icon='options-icon' size={20} />}
              direction='bottom'
              position='right'
              showCaret={false}
              options={[
                {
                  label: 'Detalle',
                  key: 'detail',
                  onClick: () => {
                    onDetail();
                  },
                },
              ]}
              padding='0 10px'
              hover
            />
          )}
        </FlexContainer>
      </FlexContainer>

      <FlexContainer width='90%' direction='column'>
        <FlexContainer container justify='space-between'>
          <FlexContainer direction='column' margin='0 20px 0 0'>
            <FlexContainer margin='0 0 5px'>
              {!data.is_start && (
                <Typography fontSize={13} fontWeight={600} margin='0 10px 0 0'>
                  {data.order.reference_code || data.order.code}
                </Typography>
              )}
              <Typography fontSize={12}>{data.contact_person}</Typography>
            </FlexContainer>

            {data.order.batch !== '' && (
              <Typography fontSize={12} color='text.secondary' margin='0 0 5px'>
                {data.order.batch}
              </Typography>
            )}

            <Typography fontSize={12} color='text.secondary'>
              {data.address}
            </Typography>
            {data.address_detail && (
              <Typography fontSize={12} color='text.secondary'>
                {data.address_detail}
              </Typography>
            )}
          </FlexContainer>

          <Status
            color={statusColors[data.status] || statusColors.default}
            label={data.status_label}
            fontSize={12}
          />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default DetailCard;
