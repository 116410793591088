import React, { useState, useEffect } from 'react';
import { isEmpty, find } from 'lodash';
import { useTypedSelector } from 'store';
import { selectBranchOffice } from 'store/organization/actions';
import { useSnackbar } from 'notistack';
import useRequest from 'hooks/useRequest';
import { useDispatch } from 'react-redux';
import { FlexContainer } from 'components/atoms';
import { FormControl } from 'components/molecules';
import { excelParse, getMassiveCharge, chooseOrganization } from 'services';
import { optionFormatter } from 'util/functions';
import Upload from './Upload';
import Success from './Success';
import Confirm from './Confirm';

const Massive: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const branchOffice = useTypedSelector(store =>
    store.organization.selectedBranchOffice.id.toString()
  );
  const organization = useTypedSelector(state => state.organization);
  const dispatch = useDispatch();

  const [currentView, setCurrentView] = useState<string>('1');
  // Upload API calls
  const [jobId, postExcel, , , errorJobId] = useRequest(excelParse);
  const [parsedExcel, fetchJobConsult] = useRequest(getMassiveCharge);
  // Values
  const [loading, setLoading] = useState<boolean>(false);
  const [officeOptions, setOfficeOptions] = useState([]);
  const defaultOption = { id: '', name: 'Todas', reference_code: '' };
  const toSelectDefaultOption = {
    id: JSON.stringify(defaultOption),
    name: 'Todas',
  };

  useEffect(() => {
    if (!isEmpty(jobId)) {
      fetchJobConsult(jobId.job_id);
    }
  }, [jobId]);

  useEffect(() => {
    if (!isEmpty(errorJobId)) {
      enqueueSnackbar(errorJobId.errors[0], {
        variant: 'error',
      });
    }
  }, [errorJobId]);

  useEffect(() => {
    if (!isEmpty(parsedExcel)) {
      switch (parsedExcel.status) {
        case 'PENDING': {
          setTimeout(() => {
            fetchJobConsult(jobId.job_id);
          }, 2000);
          break;
        }
        case 'FAILURE': {
          setLoading(false);
          if (parsedExcel.result && parsedExcel.result.errors) {
            enqueueSnackbar(parsedExcel.result.errors[0], {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Ocurrio un error, vuelva a intentarlo.', {
              variant: 'error',
            });
          }
          break;
        }
        case 'SUCCESS': {
          if (parsedExcel.result) {
            const errors =
              parsedExcel.result.result &&
              parsedExcel.result.result.reduce(
                (prev, row) => prev || row.errors.length > 0,
                false
              );

            setLoading(false);
            if (errors) {
              setCurrentView('2');
            } else {
              setCurrentView('3');
            }
          }
          break;
        }
        default:
          break;
      }
    }
  }, [parsedExcel]);

  const handleDropFile = acceptedFiles => {
    const formData = new FormData();
    formData.append('branch_office', branchOffice);
    formData.append('excel_file', acceptedFiles[0]);

    setLoading(true);
    postExcel(formData);
  };

  const handleNewMassive = () => {
    setCurrentView('1');
  };

  const renderView = () => {
    switch (currentView) {
      case '1':
        return <Upload onDrop={handleDropFile} loading={loading} />;
      case '2':
        return (
          <Confirm
            data={parsedExcel.result.result}
            onNewMassive={() => handleNewMassive()}
          />
        );
      case '3':
        return <Success onNewMassive={() => handleNewMassive()} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    chooseOrganization({
      page_size: 0,
    }).then(res => {
      const organizationObj = find(res.data, {
        id: parseInt(`${organization.selectedOrganization.id}`, 10),
      });

      if (organizationObj) {
        setOfficeOptions(
          optionFormatter(organizationObj.administrator_branch_offices, {
            id: option => JSON.stringify(option),
            name: option => option.name,
          })
        );
      }
    });
  }, []);

  return (
    <FlexContainer
      container
      padding='30px 40px'
      direction='column'
      height='100%'
    >
      <FormControl
        control='select'
        label='Seleccione una sucursal'
        placeholder='Seleccione una sucursal'
        value={JSON.stringify(organization.selectedBranchOffice)}
        margin='20px 20px 0 0'
        options={officeOptions}
        defaultOption={toSelectDefaultOption}
        onChange={value => {
          const parsedValue = JSON.parse(value);
          dispatch(
            selectBranchOffice({
              id: parsedValue.id,
              name: parsedValue.name,
              reference_code: parsedValue.reference_code,
            })
          );
        }}
      />
      {renderView()}
    </FlexContainer>
  );
};

export default Massive;
