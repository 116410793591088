import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { Grid } from '@material-ui/core';
import {
  Button,
  Divider,
  FlexContainer,
  Icon,
  Typography,
  Switch,
} from 'components/atoms';
import { FormControl, Spinner } from 'components/molecules';
import { useTypedSelector } from 'store';
import THEME from 'util/styledTheme';
import { patchPackageTypes, postPackageTypes } from 'services';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import { EditProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const validationState = {
  name: ['mandatory'],
  code: ['mandatory'],
  width: ['mandatory', 'positiveFloat'],
  height: ['mandatory', 'positiveFloat'],
  depth: ['mandatory', 'positiveFloat'],
  weight: ['mandatory', 'positiveFloat'],
};

const EditPackings: React.FC<EditProps> = ({ data, onCreate, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedOrganization = useTypedSelector(
    state => state.organization.selectedOrganization
  );

  const [form, onFormChange, validate, errors, reset] = useForm(
    {
      name: data ? data.name : '',
      code: data ? data.code : '',
      description: data ? data.description : '',
      width: data ? data.width : '',
      height: data ? data.height : '',
      depth: data ? data.depth : '',
      weight: data ? data.weight : '',
      isActive: data ? data.is_active : true,
      isUnitMeasure: data ? data.is_a_unit_of_measure : false,
    },
    validationState
  );

  // API calls
  const [updatedPackingType, updatePackingType, loadingUpdate] = useRequest(
    patchPackageTypes
  );
  const [createdPackingType, createPackingType, loadingCreate] = useRequest(
    postPackageTypes
  );

  const validateForm = () => {
    if (validate()) {
      const body = {
        name: form.name,
        code: form.code,
        description: form.description,
        width: form.width,
        height: form.height,
        depth: form.depth,
        weight: form.weight,
        is_active: form.isActive,
        is_a_unit_of_measure: form.isUnitMeasure,
        organization: selectedOrganization.id,
      };
      if (data) {
        updatePackingType(body, data.id);
      } else {
        createPackingType(body);
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(createdPackingType)) {
      enqueueSnackbar('El tipo de paquete fue creado correctamente.', {
        variant: 'success',
      });
      onCreate();
      reset();
    }
  }, [createdPackingType, enqueueSnackbar, onCreate, reset]);

  useEffect(() => {
    if (!isEmpty(updatedPackingType)) {
      enqueueSnackbar('Se actualizó el tipo de paquete correctamente.', {
        variant: 'success',
      });
      onCreate();
    }
  }, [updatedPackingType, enqueueSnackbar, onCreate]);

  return (
    <FlexContainer
      container
      minWidth='800px'
      position='relative'
      padding='40px 50px 50px'
      direction='column'
    >
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <Typography fontSize={18} margin='10px 0 0'>
        {data ? data.name : 'Creación de Tipo de Paquete'}
      </Typography>
      <Divider orientation='horizontal' margin='15px 0 30px' />

      <FlexContainer
        container
        direction='column'
        padding='30px 30px'
        margin='0 0 30px'
        borderRadius='10px'
        backgroundColor={THEME.colors.backgroundColor}
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer container alignItems='center' margin='0 0 30px'>
          <FlexContainer container justify='space-between' alignItems='center'>
            <Typography
              color={THEME.colors.primary}
              fontWeight={500}
              fontSize={17}
            >
              Información básica
            </Typography>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer container direction='column'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FlexContainer alignItems='center' margin='0 0 20px 0'>
                <Typography fontSize={12}>Activo:</Typography>
                <Switch
                  color='primary'
                  checked={form.isActive}
                  onChange={event =>
                    onFormChange(event.target.checked, 'isActive')
                  }
                />
              </FlexContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FlexContainer alignItems='center' margin='0 0 20px 0'>
                <Typography fontSize={12}>Unidad de medida:</Typography>
                <Switch
                  color='primary'
                  checked={form.isUnitMeasure}
                  onChange={event =>
                    onFormChange(event.target.checked, 'isUnitMeasure')
                  }
                />
              </FlexContainer>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Nombre'
                placeholder='Escribe el nombre del paquete'
                onChange={value => onFormChange(value, 'name')}
                value={form.name}
                margin='0 0 20px'
                error={errors.name}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Código'
                placeholder='Escribe el código del paquete'
                onChange={value => onFormChange(value, 'code')}
                value={form.code}
                margin='0 0 20px'
                error={errors.code}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Descripción'
                placeholder='Escriba una descripción del paquete'
                onChange={value => onFormChange(value, 'description')}
                value={form.description}
                margin='0 0 20px'
                error={errors.description}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Peso'
                placeholder='Escriba el peso del paquete'
                onChange={value => onFormChange(value, 'weight')}
                value={form.weight}
                margin='0 0 20px'
                error={errors.weight}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Ancho'
                placeholder='Escriba el ancho del paquete'
                onChange={value => onFormChange(value, 'width')}
                value={form.width}
                margin='0 0 20px'
                error={errors.width}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Alto'
                placeholder='Escriba el alto del paquete'
                onChange={value => onFormChange(value, 'height')}
                value={form.height}
                margin='0 0 20px'
                error={errors.height}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Profundidad'
                placeholder='Escriba la profundidad del paquete'
                onChange={value => onFormChange(value, 'depth')}
                value={form.depth}
                margin='0 0 20px'
                error={errors.depth}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
          </Grid>
        </FlexContainer>
      </FlexContainer>

      <Button
        variant='contained'
        color='primary'
        padding='5px 30px'
        onClick={() => validateForm()}
      >
        {(loadingCreate || loadingUpdate) && (
          <Spinner
            height='15px'
            spinnerSize={20}
            colorSecondary
            margin='0 8px 0 0'
          />
        )}
        {data ? 'Guardar' : 'Crear'}
      </Button>
    </FlexContainer>
  );
};

export default EditPackings;
