import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {
  Grid,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  Dialog,
  Chip,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTypedSelector } from 'store';
import Cropper from 'react-easy-crop';
import { isEmpty, find } from 'lodash';
import {
  FlexContainer,
  Typography,
  Icon,
  Divider,
  Button,
  Switch,
} from 'components/atoms';
import {
  FormControl,
  Tabs,
  Spinner,
  MultipleSelect,
} from 'components/molecules';
import { DataTable } from 'components/organisms';
import {
  generateObjectId,
  optionFormatter,
  validateNumbersInput,
} from 'util/functions';
import THEME from 'util/styledTheme';
import useRequest from 'hooks/useRequest';
import useForm from 'hooks/useForm';
import useWindowSize from 'hooks/useWindowSize';
import {
  getBranchOffices,
  getProduct,
  patchProduct,
  getProductCategories,
  addPhotoToProduct,
  removePhotoToProduct,
} from 'services';
import { EditProps, ProductVariation, ProductVariationDetail } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 15px;
`;

const DeleteIcon = styled(FlexContainer)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const AddImage = styled(FlexContainer)`
  border-style: dotted;
`;

const SelectDisabled = styled(FlexContainer)<{ loading: boolean }>`
  .MuiSelect-select {
    cursor: not-allowed;
  }
  .Mui-disabled {
    background: ${props => (props.loading ? '#efeff5' : '#ffffff')};
  }
`;

const DeleteProductButton = styled(Button)`
  color: #f1564f;
  border: 1px solid rgba(241, 86, 79, 0.5);

  &: hover {
    border: 1px solid #f1564f;
    background-color: rgba(241, 86, 79, 0.2);
  }
`;

const ColoredLine = styled.hr`
  border: 1px solid #ebebed;
  width: 100%;
`;

const CropContainer = styled.div`
  position: relative;
  margin: 40px auto;
  width: 100%;
  height: 350px;

  @media only screen and (min-width: 1250px) {
    width: 90%;
    height: 400px;
  }
`;

const VariationsContainer = styled.div`
  display: flex;
  justifycontent: center;
  flexwrap: wrap;
  liststyle: none;
  padding-left: 12px;
  margin-top: -15px;
  margin-bottom: 30px;
`;

const tabOptions: any = [
  {
    label: 'Registro',
    value: 'register',
  },
  {
    label: 'Caracteristicas',
    value: 'properties',
  },
  /* {
    label: 'Stock',
    value: 'stock',
  }, */
];

const validateStateProduct = {
  name: ['mandatory'],
  price: ['priceFloat', 'mandatory'],
  cost: ['priceFloat'],
  long: ['positiveFloat', 'mandatory'],
  width: ['positiveFloat', 'mandatory'],
  height: ['positiveFloat', 'mandatory'],
  weight: ['positiveFloat', 'mandatory'],
};

const validateStateStock = {
  inStock: ['number'],
  damaged: ['number'],
  reserved: ['number'],
  returned: ['number'],
};

type ConfirmDeleteProps = {
  title: string;
  message: string;
  onConfirm: () => void;
  onClose: () => void;
};

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

async function getCroppedImg(imageSrc, pixelCrop) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob(file => {
      resolve({ file, cropped: URL.createObjectURL(file) });
    }, 'image/jpeg');
  });
}

export const ConfirmDelete: React.FC<ConfirmDeleteProps> = ({
  title,
  message,
  onConfirm,
  onClose,
}) => (
  <FlexContainer
    padding='30px'
    direction='column'
    alignItems='center'
    justify='center'
  >
    <Typography color={THEME.colors.primary} fontSize={15} margin='0 0 20px'>
      {title}
    </Typography>
    <Typography margin='0 0 30px' fontSize={13}>
      {message}
    </Typography>
    <FlexContainer container alignItems='center' justify='space-between'>
      <Button
        variant='contained'
        color='primary'
        fontSize='13px'
        onClick={() => onConfirm()}
      >
        Eliminar
      </Button>

      <Button fontSize='14px' onClick={() => onClose()}>
        Cancelar
      </Button>
    </FlexContainer>
  </FlexContainer>
);

const EditProduct: React.FC<EditProps> = ({
  productId,
  clientId,
  onClose,
  onCreate,
}) => {
  const user = useTypedSelector(state => state.user);
  const photoInput = useRef<HTMLInputElement>();
  const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowSize();
  const organizationId = useTypedSelector(
    state => state.organization.selectedOrganization.id
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [productProperties, setProductProperties] = useState<string[][]>([
    ['', ''],
  ]);

  const [productVariations, setProductVariations] = useState<
    ProductVariation[]
  >([]);
  const [productVariationsDetails, setProductVariationsDetails] = useState<
    ProductVariationDetail[]
  >([]);

  const [
    productForm,
    onProductChange,
    validateProduct,
    productErrors,
    ,
    updateProductForm,
  ] = useForm(
    {
      sku: '',
      name: '',
      description: '',
      currency: 'PEN',
      price: 0,
      cost: 0,
      long: 0,
      height: 0,
      width: 0,
      weight: 0,
      isPublic: false,
      category_id: [],
      hasVariations: false,
      // photos: [],
    },
    validateStateProduct
  );

  const [
    stockForm,
    onStockChange,
    validateStock,
    stockErrors,
    resetStock,
  ] = useForm(
    {
      branchOfficeId: '',
      inStock: '',
      damaged: '',
      reserved: '',
      returned: '',
    },
    validateStateStock
  );
  // API calls
  const [branchOffices, fetchBranchOffices] = useRequest(getBranchOffices, []);
  const [categories, fetchCategories, loadingCategories] = useRequest(
    getProductCategories,
    []
  );
  const [updatedProduct, updateProduct, loadingUpdate] = useRequest(
    patchProduct
  );
  const [productInfo, fetchProductData, loadingProduct] = useRequest(
    getProduct
  );
  const [addedPhoto, addPhoto, , , errorAdd] = useRequest(addPhotoToProduct);

  const [removedPhoto, removePhoto, , , errorRemove] = useRequest(
    removePhotoToProduct
  );

  // Values
  const [selectedTab, setSelectedTab] = useState<string>('register');
  const [branchOfficeOptions, setBranchOfficeOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [stockInBranchOffices, setStockInBranchOffices] = useState([]);
  const [previewProductImages, setPreviewProductImages] = useState([]);

  const [openCropImage, setOpenCropImage] = useState(false);

  // crop image
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    fetchProductData(productId);
    fetchBranchOffices({
      page_size: 0,
    });
    fetchCategories({
      client_code: clientId,
      $limit: -1,
      is_active: true,
    });
  }, [fetchBranchOffices, fetchCategories, fetchProductData, productId]);

  useEffect(() => {
    if (!productInfo._id || productInfo._id === '') {
      return;
    }

    // enableProductVariations(productInfo.has_variations);

    let updateData: any = {
      sku: productInfo.sku ? productInfo.sku : '',
      name: productInfo.name,
      description: productInfo.description,
      price: productInfo.price,
      isPublic: productInfo.is_public,
      photos: productInfo.photos,
      category_id: productInfo.category_id,
      hasVariations: productInfo.has_variations,
    };

    if (productInfo.dimensions) {
      updateData = {
        ...updateData,
        long: productInfo.dimensions.long,
        height: productInfo.dimensions.height,
        width: productInfo.dimensions.width,
        weight: productInfo.dimensions.weight,
      };
    }

    if (productInfo.hasVariations) {
      setProductVariations(productInfo.variations);
      setProductVariationsDetails(productInfo.variations_detail);
    }

    setPreviewProductImages(productInfo.photos ? productInfo.photos : []);
    updateProductForm(updateData);
    setProductProperties(
      productInfo.properties.length === 0 ? [['', '']] : productInfo.properties
    );

    const emptyVariation = {
      _id: generateObjectId(),
      name: '',
      options: [],
    };

    if (productInfo.variations.length === 0) {
      setProductVariations([emptyVariation]);
    } else {
      // buildVariations(productInfo.variations);
      setProductVariations(productInfo.variations);
      setProductVariationsDetails(productInfo.variations_details);
    }
  }, [productInfo]);

  useEffect(() => {
    if (productInfo && productInfo.category_id) {
      const selectedCategory = categoriesOptions.filter(category => {
        // eslint-disable-next-line no-underscore-dangle
        return productInfo.category_id.includes(category.id);
      });

      setSelectedCategories(selectedCategory);
    }
  }, [productInfo, categoriesOptions, setSelectedCategories]);

  useEffect(() => {
    if (branchOffices.length > 0) {
      setBranchOfficeOptions(optionFormatter(branchOffices));
    }
  }, [branchOffices]);

  useEffect(() => {
    if (categories) {
      setCategoriesOptions(optionFormatter(categories));
    }
  }, [categories]);

  useEffect(() => {
    if (!isEmpty(addedPhoto)) {
      setPreviewProductImages(addedPhoto.photos);
    }
  }, [addedPhoto]);

  useEffect(() => {
    if (!isEmpty(removedPhoto)) {
      setPreviewProductImages(removedPhoto.photos);
    }
  }, [removedPhoto]);

  useEffect(() => {
    if (!isEmpty(errorAdd)) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [errorAdd]);

  useEffect(() => {
    if (!isEmpty(errorRemove)) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [errorRemove]);

  useEffect(() => {
    if (!isEmpty(updatedProduct)) {
      if (updatedProduct.is_active) {
        enqueueSnackbar('El producto se actualizó correctamente.', {
          variant: 'success',
        });
      }
      onCreate();
    }
  }, [updatedProduct]);

  const validateForm = () => {
    if (validateProduct()) {
      const selectedCategoriesIds = selectedCategories.map(
        category => category.id
      );

      const lastIndex = productProperties.length - 1;
      if (lastIndex >= 0) {
        let counterEmpty = productProperties[lastIndex][0] === '' ? 1 : 0;
        counterEmpty += productProperties[lastIndex][1] === '' ? 1 : 0;
        if (counterEmpty === 1) {
          enqueueSnackbar('Complete la nueva caracteristica.', {
            variant: 'info',
            preventDuplicate: true,
          });
          return;
        }
      }

      const body: any = {
        sku: productForm.sku,
        name: productForm.name,
        description: productForm.description,
        price: productForm.price,
        cost: productForm.cost,
        category_id: selectedCategoriesIds,
        dimensions: {
          long: productForm.long,
          height: productForm.height,
          width: productForm.width,
          weight: productForm.weight,
        },
        is_public: productForm.isPublic,
        is_active: true,
        has_inventory: false,
        organization_code: organizationId,
        properties: productProperties,
        has_variations: productForm.hasVariations,
      };

      if (body.has_variations) {
        body.variations = productVariations;
        body.variations_details = productVariationsDetails;
      }

      // eslint-disable-next-line no-underscore-dangle
      updateProduct(body, productId);
    }

    if (
      selectedTab === 'stock' &&
      (productErrors.sku !== '' || productErrors.name !== '')
    ) {
      enqueueSnackbar('Falta completar algunos datos en registro', {
        variant: 'error',
      });
    }
  };

  const validateStockForm = () => {
    const stock = validateStock();

    if (stock) {
      setStockInBranchOffices([...stockInBranchOffices, stockForm]);
      resetStock();
    }
  };

  const handleDelete = (branchOfficeId: string) => {
    const newPackages = stockInBranchOffices.filter(
      item => item.branchOfficeId !== branchOfficeId
    );
    setStockInBranchOffices(newPackages);
  };

  const handleImageDelete = (imageURL: string) => {
    removePhoto({ url: imageURL }, productId);
  };

  const handleUploadFile = event => {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files).map((file: any) =>
        URL.createObjectURL(file)
      );

      setImageSrc(fileArray[0]);

      setOpenCropImage(true);
    }
  };

  const headers = useMemo(
    () => [
      {
        label: 'Sucursal',
        id: 'branchOffice',
        cell: row =>
          branchOfficeOptions.find(item => item.id === row.branchOfficeId).name,
      },
      {
        label: 'En stock',
        id: 'inStock',
        cell: row => `${row.inStock} unidad`,
      },
      {
        label: 'Dañado',
        id: 'damaged',
        cell: row => `${row.damaged} unidad`,
      },
      {
        label: 'Reservado',
        id: 'reserved',
        cell: row => `${row.reserved} unidad`,
      },
      {
        label: 'Devoluciones',
        id: 'returned',
        cell: row => `${row.returned} unidad`,
      },
      {
        label: '',
        id: 'sku',
        cell: row => (
          <IconButton
            size='small'
            onClick={() => handleDelete(row.branchOfficeId)}
          >
            <Icon icon='cancel-icon' size={12} />
          </IconButton>
        ),
      },
    ],
    [stockInBranchOffices]
  );

  const addProductProperty = () => {
    const lastIndex = productProperties.length - 1;

    if (
      lastIndex >= 0 &&
      (productProperties[lastIndex][0] === '' ||
        productProperties[lastIndex][1] === '')
    ) {
      enqueueSnackbar('Complete la nueva caracteristica.', {
        variant: 'info',
        preventDuplicate: true,
      });
      return;
    }

    setProductProperties([...productProperties, ['', '']]);
  };

  const addProductVariation = () => {
    if (productVariations.length >= 3) {
      enqueueSnackbar('No se puede ingresar mas de 3 variaciones.', {
        variant: 'info',
        preventDuplicate: true,
      });
      return;
    }

    const lastIndex = productVariations.length - 1;

    if (
      lastIndex >= 0 &&
      (productVariations[lastIndex].name === '' ||
        productVariations[lastIndex].options.length === 0)
    ) {
      enqueueSnackbar('Complete la nueva variación.', {
        variant: 'info',
        preventDuplicate: true,
      });
      return;
    }

    setProductVariations([
      ...productVariations,
      { _id: generateObjectId(), name: '', options: [], editing: '' },
    ]);
  };

  const updateProductProperty = (
    index: number,
    position: number,
    value: string
  ) => {
    const copy = JSON.parse(JSON.stringify(productProperties));
    copy[index][position] = value;
    setProductProperties(copy);
  };

  const updateProductVariation = (
    index: number,
    key: string,
    value: string
  ) => {
    const copy = JSON.parse(JSON.stringify(productVariations));
    copy[index][key] = value;
    setProductVariations(copy);
  };

  const addProductVariationDetail = (index: number) => {
    if (productVariations[index].name.trim() === '') {
      enqueueSnackbar('Ingrese el nombre de la variación.', {
        variant: 'info',
      });
      return;
    }

    if (productVariations[index].options.length >= 10) {
      enqueueSnackbar('No se puede ingresar mas de 10 tipos.', {
        variant: 'info',
      });
      return;
    }

    const copy = JSON.parse(JSON.stringify(productVariations));
    const newDetail = productVariations[index].editing.trim();

    if (copy[index].options.includes(newDetail)) {
      enqueueSnackbar('Variación ya existe.', {
        variant: 'info',
      });
      return;
    }

    copy[index].options.push(newDetail);
    copy[index].editing = '';
    setProductVariations(copy);
    buildVariations(copy);
  };

  const buildVariations = (inputData?: any) => {
    const data = generateVariations(inputData.map(item => item.options));
    console.log({ data });
    const variationsData = data.map((item, index) => {
      const reverseArray = item.slice(0).reverse();
      return {
        _id: '',
        name: reverseArray.join(' - '),
        price: 0,
        stock: 0,
        sku: '',
        combination: reverseArray.map(
          (variation: string, idx) =>
            `${productVariations[idx]._id}:${variation}`
        ),
      };
    });
    setProductVariationsDetails(variationsData);
  };

  const generateVariations = (
    variationsData: string[][],
    isCombination: boolean = false
  ) => {
    if (variationsData.length === 1) {
      return variationsData[0].map(item => [item]);
    }
    if (variationsData.length === 2) {
      const combinations = [];
      for (let i = 0; i < variationsData[1].length; i++) {
        for (let j = 0; j < variationsData[0].length; j++) {
          let combination: any;
          if (isCombination) {
            combination = variationsData[1][i].slice();
            combination.push(variationsData[0][j]);
          } else {
            combination = [variationsData[1][i], variationsData[0][j]];
          }
          combinations.push(combination);
        }
      }
      return combinations;
    }
    if (variationsData.length > 2) {
      let last2: any = variationsData.slice(-2);
      const butLast2 = variationsData.slice(0, variationsData.length - 2);
      last2 = generateVariations(last2, isCombination);
      butLast2.push(last2);
      const combinations = butLast2;
      return generateVariations(combinations, (isCombination = true));
    }
  };

  const deleteProductVariationDetail = (index: number, value: string) => {
    const copy = JSON.parse(JSON.stringify(productVariations));
    copy[index].options = productVariations[index].options.filter(
      item => item !== value
    );
    setProductVariations(copy);
    buildVariations(copy);
  };

  const deleteProductProperty = (_index: number) => {
    const copy: any = [];

    productProperties.forEach((property: string[], index: number) => {
      if (index !== _index) {
        copy.push(property);
      }
    });

    setProductProperties(copy);
  };

  const deleteProductVariation = (_index: number) => {
    const copy: any = [];

    productVariations.forEach((property: ProductVariation, index: number) => {
      if (index !== _index) {
        copy.push(property);
      }
    });

    setProductVariations(copy);
  };

  const deleteProduct = () => {
    const body = {
      is_active: false,
    };

    updateProduct(body, productId);
    onClose();
    enqueueSnackbar('Producto eliminado correctamente.', {
      variant: 'success',
    });
  };

  // crop image
  const onCropComplete = useCallback((croppedArea, croppedAreaPixel) => {
    setCroppedAreaPixels(croppedAreaPixel);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedData: any = await getCroppedImg(imageSrc, croppedAreaPixels);
      const croppedImageData = new File(
        [croppedData.file],
        'croppedImage.jpg',
        {
          type: 'image/jpeg',
        }
      );

      const formData = new FormData();

      formData.append('photo', croppedImageData);
      addPhoto(formData, productId);
      setImageSrc(null);
      setOpenCropImage(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const enableProductVariations = (isEnabled: boolean) => {
    /*
    TODO: Aqui
     */
  };

  const variationsHeaders = [
    {
      label: 'Variante',
      id: 'name',
      cell: row => row.name,
    },
    {
      label: 'Precio',
      id: 'price',
      cell: row => (
        <FormControl
          placeholder='Precio'
          onChange={value => updateVariationHeaders(value, 'price', row)}
          value={row.price}
          width='max-parent'
        />
      ),
    },
    /* {
      label: 'Stock',
      id: 'stock',
      cell: row => (
        <FormControl
          placeholder='Stock'
          onChange={value => updateVariationHeaders(value, 'stock', row)}
          value={row.stock}
          width='max-parent'
        />
      ),
    }, */
    {
      label: 'SKU',
      id: 'sku',
      cell: row => (
        <FormControl
          placeholder='SKU'
          onChange={value => updateVariationHeaders(value, 'sku', row)}
          value={row.sku}
          width='max-parent'
        />
      ),
    },
  ];

  const updateVariationHeaders = (value: string, key: string, row: any) => {
    const rowIndex = productVariationsDetails.indexOf(row);
    const copy = JSON.parse(JSON.stringify(productVariationsDetails));

    copy[rowIndex][key] = value;
    setProductVariationsDetails(copy);
  };

  console.log(productInfo);
  return (
    <>
      <FlexContainer
        width={width > 715 ? '720px' : '555px'}
        position='relative'
        padding='40px 50px 50px'
        direction='column'
      >
        <CloseIcon onClick={() => onClose()}>
          <Icon
            icon='cancel-icon'
            color={THEME.colors.placeholderColor}
            size={25}
          />
        </CloseIcon>
        <FlexContainer
          container
          direction='row'
          alignItems='space-between'
          justify='space-between'
        >
          <Typography fontSize={18} margin='10px 0 0'>
            Editar producto
          </Typography>
          {user.role !== 'client' && (
            <DeleteProductButton
              variant='outlined'
              color='primary'
              style={{ margin: '10px 0 0' }}
              onClick={() => setOpenDeleteDialog(true)}
            >
              <Icon icon='delete-icon' size={16} /> Eliminar
            </DeleteProductButton>
          )}
        </FlexContainer>
        <Divider orientation='horizontal' margin='15px 0 30px' />
        <>
          <FlexContainer container justify='center' margin='0 0 20px'>
            <FlexContainer width='100%'>
              <Tabs
                selected={selectedTab}
                tabs={tabOptions}
                onChange={value => setSelectedTab(value)}
                tabHeight='40px'
                fullWidth
              />
            </FlexContainer>
          </FlexContainer>

          {selectedTab === 'register' && (
            <>
              <FlexContainer
                container
                direction='column'
                padding='30px 30px'
                margin='0 0 30px'
                borderRadius='10px'
                backgroundColor={THEME.colors.backgroundColor}
                borderColor={THEME.colors.borderColor}
              >
                <Typography
                  color={THEME.colors.primary}
                  fontWeight={500}
                  fontSize={17}
                  margin='0 0 20px'
                >
                  Información básica
                </Typography>
                <FlexContainer container direction='column'>
                  <FlexContainer container justify='center' margin='0 0 20px'>
                    {loadingProduct ? (
                      <FlexContainer
                        alignItems='center'
                        width='125px'
                        height='125px'
                        margin='0 10px 0 0'
                      >
                        <Spinner
                          height='15px'
                          spinnerSize={30}
                          margin='0 8px 0 0'
                        />
                      </FlexContainer>
                    ) : (
                      <>
                        {previewProductImages.length > 0 ? (
                          <FlexContainer
                            container
                            borderColor={THEME.colors.textPrimary}
                            padding='10px'
                          >
                            <>
                              {previewProductImages.map(image => (
                                <FlexContainer
                                  key={image}
                                  position='relative'
                                  width='125px'
                                  height='125px'
                                  margin='0 10px 0 0'
                                >
                                  <DeleteIcon
                                    onClick={() => handleImageDelete(image)}
                                  >
                                    <Icon
                                      icon='cancel-icon'
                                      color={THEME.colors.placeholderColor}
                                      size={20}
                                    />
                                  </DeleteIcon>
                                  <img
                                    src={image}
                                    alt='product'
                                    style={{ width: 125, height: 125 }}
                                  />
                                </FlexContainer>
                              ))}

                              {previewProductImages.length < 3 && (
                                <FlexContainer
                                  direction='column'
                                  alignItems='center'
                                  cursor='pointer'
                                  onClick={() => photoInput.current.click()}
                                >
                                  <AddImage
                                    direction='column'
                                    width='125px'
                                    height='125px'
                                    justify='center'
                                    alignItems='center'
                                    borderColor={THEME.colors.textPrimary}
                                    borderRadius='5px'
                                    padding='8px'
                                  >
                                    <Typography fontSize={35} fontWeight={600}>
                                      +
                                    </Typography>
                                    <Typography>Subir imágenes</Typography>
                                  </AddImage>
                                  <input
                                    ref={photoInput}
                                    type='file'
                                    accept='image/*'
                                    onChange={handleUploadFile}
                                    style={{ display: 'none' }}
                                  />
                                </FlexContainer>
                              )}
                            </>
                          </FlexContainer>
                        ) : (
                          <FlexContainer
                            direction='column'
                            alignItems='center'
                            cursor='pointer'
                            onClick={() => photoInput.current.click()}
                          >
                            <AddImage
                              direction='column'
                              width='135px'
                              height='125px'
                              justify='center'
                              alignItems='center'
                              borderColor={THEME.colors.textPrimary}
                              borderRadius='5px'
                              padding='8px'
                            >
                              <Typography fontSize={35} fontWeight={600}>
                                +
                              </Typography>
                              <Typography>Subir imágenes</Typography>
                              <Typography
                                fontSize={11}
                                color={THEME.colors.textSecondary}
                                margin='5px 0 0'
                              >
                                (Máximo 3 imágenes)
                              </Typography>
                            </AddImage>
                            <input
                              ref={photoInput}
                              type='file'
                              accept='image/*'
                              onChange={handleUploadFile}
                              style={{ display: 'none' }}
                            />
                          </FlexContainer>
                        )}
                      </>
                    )}
                  </FlexContainer>
                  <FlexContainer direction='column' margin='0 0 20px'>
                    <Typography
                      fontSize={11}
                      textAlign='center'
                      color='#8c8cb1'
                    >
                      Imagen cuadrada recomendada de 500 x 500 pixeles.
                    </Typography>
                    <Typography
                      fontSize={11}
                      margin='5px 0 0'
                      textAlign='center'
                      color='#8c8cb1'
                    >
                      Tamaño máximo del archivo 3MB MB.
                    </Typography>
                    <Typography
                      fontSize={11}
                      margin='5px 0 0'
                      textAlign='center'
                      color='#8c8cb1'
                    >
                      Tipos de imágenes incluidas: JPG o PNG.
                    </Typography>
                  </FlexContainer>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <FlexContainer
                        container
                        direction='column'
                        margin='0 0 20px'
                      >
                        <Typography fontSize={12}>Código</Typography>
                        <Typography fontSize={12} margin='5px 0 0 0'>
                          {productInfo._id}
                        </Typography>
                      </FlexContainer>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <FlexContainer
                        container
                        direction='column'
                        margin='0 0 20px'
                      >
                        <FormControl
                          label='Nombre del producto'
                          onChange={value => onProductChange(value, 'name')}
                          value={productForm.name}
                          error={productErrors.name}
                          margin='0 0 10px'
                          width='100%'
                          disabled={loadingUpdate || loadingProduct}
                        />
                        <Typography fontSize={11} color='#8c8cb1'>
                          Recuerda no utilizar caracteres especiales &%$·/()=
                        </Typography>
                      </FlexContainer>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='SKU'
                        onChange={value => onProductChange(value, 'sku')}
                        value={productForm.sku}
                        margin='0 0 20px'
                        error={productErrors.sku}
                        width='100%'
                        optional
                        disabled={loadingUpdate || loadingProduct}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Precio'
                        onChange={value => {
                          let replacedValue = value.replace(/,/g, '.');
                          replacedValue = replacedValue
                            .replace(/[^0-9.]/g, '')
                            .replace(/(\..*?)\..*/g, '$1');
                          onProductChange(replacedValue, 'price');
                        }}
                        value={productForm.price}
                        margin='0 0 20px'
                        error={productErrors.price}
                        width='100%'
                        disabled={loadingUpdate || loadingProduct}
                        startAdornment={
                          <SelectDisabled
                            loading={loadingUpdate || loadingProduct}
                          >
                            <Select
                              value='S'
                              variant='standard'
                              disableUnderline
                              disabled
                            >
                              <MenuItem value='S'>S./</MenuItem>
                              <MenuItem value='D'>$</MenuItem>
                            </Select>
                          </SelectDisabled>
                        }
                      />
                    </Grid>
                  </Grid>
                  <FlexContainer container direction='column'>
                    <Typography
                      fontSize={12}
                      fontWeight={500}
                      color='#404071'
                      margin='0 0 10px'
                    >
                      Añadir categorías
                    </Typography>
                    <MultipleSelect
                      options={categoriesOptions}
                      values={selectedCategories}
                      onChange={option => {
                        const found = find(selectedCategories, {
                          id: option.id,
                        });
                        if (found) {
                          setSelectedCategories(
                            selectedCategories.filter(
                              item => item.id !== option.id
                            )
                          );
                        } else {
                          setSelectedCategories(
                            selectedCategories.concat(option)
                          );
                        }
                      }}
                      onDelete={option => {
                        setSelectedCategories(
                          selectedCategories.filter(
                            item => item.id !== option.id
                          )
                        );
                      }}
                      margin='0 0 20px'
                      width='100%'
                      disabled={
                        loadingUpdate || loadingProduct || loadingCategories
                      }
                    />
                  </FlexContainer>
                  <FlexContainer container direction='column'>
                    <FormControl
                      label='Descripción'
                      control='textarea'
                      onChange={value => onProductChange(value, 'description')}
                      value={productForm.description}
                      error={productErrors.description}
                      margin='0 0 20px'
                      width='100%'
                      optional
                      disabled={loadingUpdate || loadingProduct}
                    />
                  </FlexContainer>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FlexContainer container direction='column'>
                        <Typography
                          fontSize={12}
                          fontWeight={500}
                          color='#404071'
                        >
                          Mostrar en catálogo digital
                        </Typography>
                        <FlexContainer alignItems='center'>
                          <Switch
                            checked={productForm.isPublic}
                            onChange={event =>
                              onProductChange(event.target.checked, 'isPublic')
                            }
                          />
                          <Typography
                            fontSize={13}
                            fontWeight={500}
                            color='#888888'
                            margin='0 0 0 5px'
                          >
                            {productForm.isPublic
                              ? 'Habilitado'
                              : 'Deshabilitado'}
                          </Typography>
                        </FlexContainer>
                      </FlexContainer>
                    </Grid>
                  </Grid>
                </FlexContainer>
              </FlexContainer>

              <FlexContainer
                container
                direction='column'
                padding='30px 30px'
                margin='0 0 30px'
                borderRadius='10px'
                backgroundColor={THEME.colors.backgroundColor}
                borderColor={THEME.colors.borderColor}
              >
                <Typography
                  color={THEME.colors.primary}
                  fontWeight={500}
                  fontSize={17}
                  margin='0 0 20px'
                >
                  Dimensiones
                </Typography>
                <FlexContainer container direction='column'>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm>
                      <FormControl
                        label='Largo'
                        placeholder='Ingrese la medida'
                        onChange={value =>
                          onProductChange(validateNumbersInput(value), 'long')
                        }
                        value={productForm.long}
                        margin='0 0 20px'
                        error={productErrors.long}
                        width='100%'
                        disabled={loadingUpdate || loadingProduct}
                        endAdornment={
                          <InputAdornment position='end'>cm</InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm>
                      <FormControl
                        label='Ancho'
                        placeholder='Ingrese la medida'
                        onChange={value =>
                          onProductChange(validateNumbersInput(value), 'width')
                        }
                        value={productForm.width}
                        margin='0 0 20px'
                        error={productErrors.width}
                        width='100%'
                        disabled={loadingUpdate || loadingProduct}
                        endAdornment={
                          <InputAdornment position='end'>cm</InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm>
                      <FormControl
                        label='Alto'
                        placeholder='Ingrese la medida'
                        onChange={value =>
                          onProductChange(validateNumbersInput(value), 'height')
                        }
                        value={productForm.height}
                        margin='0 0 20px'
                        error={productErrors.height}
                        width='100%'
                        disabled={loadingUpdate || loadingProduct}
                        endAdornment={
                          <InputAdornment position='end'>cm</InputAdornment>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm>
                      <FormControl
                        label='Peso'
                        placeholder='Ingrese la cantidad'
                        onChange={value =>
                          onProductChange(validateNumbersInput(value), 'weight')
                        }
                        value={productForm.weight}
                        error={productErrors.weight}
                        margin='0 0 20px'
                        width='100%'
                        disabled={loadingUpdate || loadingProduct}
                        endAdornment={
                          <InputAdornment position='end'>kg</InputAdornment>
                        }
                      />
                    </Grid>
                  </Grid>
                </FlexContainer>
              </FlexContainer>

              <FlexContainer
                container
                direction='row'
                alignItems='flex-start'
                style={{
                  marginBottom: productForm.hasVariations ? '0px' : '20px',
                }}
              >
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  color='#404071'
                  style={{ alignSelf: 'center', marginLeft: '10px' }}
                >
                  ¿Este producto tiene variaciones?
                </Typography>
                <FlexContainer alignItems='center'>
                  <Switch
                    checked={productForm.hasVariations}
                    onChange={event => {
                      onProductChange(event.target.checked, 'hasVariations');
                    }}
                  />
                  <Typography
                    fontSize={13}
                    fontWeight={500}
                    color='#888888'
                    margin='0 0 0 5px'
                  >
                    {productForm.hasVariations ? 'Habilitado' : 'Deshabilitado'}
                  </Typography>
                </FlexContainer>
              </FlexContainer>
              {productForm.hasVariations && (
                <FlexContainer
                  container
                  direction='column'
                  padding='30px 30px'
                  margin='0 0 30px'
                  borderRadius='10px'
                  backgroundColor={THEME.colors.backgroundColor}
                  borderColor={THEME.colors.borderColor}
                >
                  <FlexContainer
                    container
                    direction='row'
                    alignItems='space-between'
                    justify='space-between'
                  >
                    <Typography
                      color={THEME.colors.primary}
                      fontWeight={500}
                      fontSize={17}
                      margin='0 0 20px'
                    >
                      Variaciones
                    </Typography>
                  </FlexContainer>

                  <FlexContainer
                    container
                    direction='column'
                    margin='30px 0 30px'
                  >
                    <FlexContainer container direction='column'>
                      {productVariations.map(
                        (variation: ProductVariation, index: number) => {
                          return (
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={2} md={1}>
                                <IconButton
                                  size='small'
                                  onClick={() => deleteProductVariation(index)}
                                >
                                  <Icon
                                    icon='delete-icon'
                                    size={24}
                                    color='#f1564f'
                                  />
                                </IconButton>
                              </Grid>
                              <Grid item xs={12} sm={5} md={4}>
                                <FormControl
                                  placeholder='Variación'
                                  onChange={value =>
                                    updateProductVariation(index, 'name', value)
                                  }
                                  value={variation.name}
                                  margin='0 0 20px'
                                  width='100%'
                                />
                              </Grid>
                              <Grid item xs={12} sm={5} md={7}>
                                <FormControl
                                  placeholder={`Añadir ${variation.name}`}
                                  value={variation.editing}
                                  onChange={value => {
                                    const name = value
                                      .replace(/ /g, '')
                                      .replace(/[^a-z0-9]/gi, '');

                                    if (name.length > 25) {
                                      return;
                                    }

                                    updateProductVariation(
                                      index,
                                      'editing',
                                      name
                                    );
                                  }}
                                  onPressEnter={() =>
                                    addProductVariationDetail(index)
                                  }
                                  margin='0 0 20px'
                                  width='100%'
                                  endAdornment={
                                    <IconButton
                                      size='small'
                                      onClick={() =>
                                        addProductVariationDetail(index)
                                      }
                                    >
                                      <Icon
                                        icon='plus-icon'
                                        size={16}
                                        color='#51B575'
                                      />
                                    </IconButton>
                                  }
                                />
                              </Grid>
                              <VariationsContainer>
                                {variation.options.map(item => (
                                  <Chip
                                    size='small'
                                    color='primary'
                                    label={item}
                                    style={{ marginRight: '10px' }}
                                    onDelete={() =>
                                      deleteProductVariationDetail(index, item)
                                    }
                                  />
                                ))}
                              </VariationsContainer>
                            </Grid>
                          );
                        }
                      )}
                      <FlexContainer
                        container
                        justify='flex-end'
                        margin=' 0 0 20px'
                      >
                        <Button
                          variant='outlined'
                          color='primary'
                          onClick={() => addProductVariation()}
                        >
                          Añadir Variante
                        </Button>
                      </FlexContainer>
                    </FlexContainer>

                    <Typography
                      color={THEME.colors.primary}
                      fontWeight={500}
                      fontSize={17}
                      margin='0 0 20px'
                    >
                      Variaciones a crear
                    </Typography>
                    <FlexContainer container direction='column'>
                      <DataTable
                        headers={variationsHeaders}
                        data={productVariationsDetails}
                        showPagination={false}
                        dataIdentifier='name'
                        marginBottom='0'
                        onChangePage={undefined}
                        totalItems={productVariationsDetails.length}
                        totalPages={1}
                        pageSize={productVariationsDetails.length}
                        page={1}
                      />
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
              )}
            </>
          )}
          {selectedTab === 'stock' && (
            <FlexContainer
              container
              direction='column'
              padding='30px 30px'
              margin='0 0 30px'
              borderRadius='10px'
              backgroundColor={THEME.colors.backgroundColor}
              borderColor={THEME.colors.borderColor}
            >
              <Typography
                color={THEME.colors.primary}
                fontWeight={500}
                fontSize={17}
                margin='0 0 20px'
              >
                Stock
              </Typography>
              <FlexContainer container direction='column'>
                <FlexContainer container margin='0 0 20px'>
                  <FormControl
                    control='select'
                    label='A que sucursal pertenece'
                    placeholder='Seleccione una sucursal'
                    onChange={value => onStockChange(value, 'branchOfficeId')}
                    value={stockForm.branchOfficeId}
                    width='100%'
                    options={branchOfficeOptions}
                    // disabled={disabled}
                  />
                </FlexContainer>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm>
                    <FormControl
                      label='En stock'
                      placeholder='Ingrese la cantidad'
                      onChange={value => onStockChange(value, 'inStock')}
                      value={stockForm.inStock}
                      error={stockErrors.inStock}
                      margin='0 0 20px'
                      width='100%'
                      disabled={loadingUpdate || loadingProduct}
                      endAdornment={
                        <InputAdornment position='end'>unid</InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm>
                    <FormControl
                      label='Dañado'
                      placeholder='Ingrese la cantidad'
                      onChange={value => onStockChange(value, 'damaged')}
                      value={stockForm.damaged}
                      error={stockErrors.damaged}
                      margin='0 0 20px'
                      width='100%'
                      disabled={loadingUpdate || loadingProduct}
                      endAdornment={
                        <InputAdornment position='end'>unid</InputAdornment>
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm>
                    <FormControl
                      label='Reservado'
                      placeholder='Ingrese la cantidad'
                      onChange={value => onStockChange(value, 'reserved')}
                      value={stockForm.reserved}
                      error={stockErrors.reserved}
                      margin='0 0 20px'
                      width='100%'
                      disabled={loadingUpdate || loadingProduct}
                      endAdornment={
                        <InputAdornment position='end'>unid</InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm>
                    <FormControl
                      label='Devoluciones'
                      placeholder='Ingrese la cantidad'
                      onChange={value => onStockChange(value, 'returned')}
                      value={stockForm.returned}
                      error={stockErrors.returned}
                      margin='0 0 20px'
                      width='100%'
                      disabled={loadingUpdate || loadingProduct}
                      endAdornment={
                        <InputAdornment position='end'>unid</InputAdornment>
                      }
                    />
                  </Grid>
                </Grid>
                <FlexContainer container justify='flex-end' margin=' 0 0 20px'>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => validateStockForm()}
                  >
                    Añadir Stock
                  </Button>
                </FlexContainer>
              </FlexContainer>

              {stockInBranchOffices.length > 0 && (
                <FlexContainer container margin='0 0 10px'>
                  <DataTable
                    headers={headers.filter(header => header)}
                    data={stockInBranchOffices}
                    onChangePage={() => {}}
                    totalPages={0}
                    totalItems={0}
                    pageSize={0}
                    page={1}
                    showPagination={false}
                  />
                </FlexContainer>
              )}
            </FlexContainer>
          )}
          {selectedTab === 'properties' && (
            <FlexContainer
              container
              direction='column'
              padding='30px 30px'
              margin='0 0 30px'
              borderRadius='10px'
              backgroundColor={THEME.colors.backgroundColor}
              borderColor={THEME.colors.borderColor}
            >
              <Typography
                color={THEME.colors.primary}
                fontWeight={500}
                fontSize={17}
                margin='0 0 20px'
              >
                Caracteristicas adicionales
              </Typography>
              <FlexContainer container direction='column'>
                {productProperties.map((property: string[], index: number) => {
                  return (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={5} md={5}>
                        <FormControl
                          placeholder='Caracteristica'
                          onChange={value =>
                            updateProductProperty(index, 0, value)
                          }
                          value={property[0]}
                          margin='0 0 20px'
                          width='100%'
                        />
                      </Grid>
                      <Grid item xs={12} sm={5} md={5}>
                        <FormControl
                          placeholder='Valor'
                          onChange={value =>
                            updateProductProperty(index, 1, value)
                          }
                          value={property[1]}
                          margin='0 0 20px'
                          width='100%'
                        />
                      </Grid>
                      <Grid item xs={12} sm={2} md={2}>
                        <IconButton
                          size='small'
                          onClick={() => deleteProductProperty(index)}
                        >
                          <Icon icon='delete-icon' size={24} color='#f1564f' />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}
                <FlexContainer container justify='flex-end' margin=' 0 0 20px'>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={() => addProductProperty()}
                  >
                    Añadir Caracteristica
                  </Button>
                </FlexContainer>
              </FlexContainer>

              {stockInBranchOffices.length > 0 && (
                <FlexContainer container margin='0 0 10px'>
                  <DataTable
                    headers={headers.filter(header => header)}
                    data={stockInBranchOffices}
                    onChangePage={() => {}}
                    totalPages={0}
                    totalItems={0}
                    pageSize={0}
                    page={1}
                    showPagination={false}
                  />
                </FlexContainer>
              )}
            </FlexContainer>
          )}

          <FlexContainer container justify='flex-end'>
            <Button
              variant='contained'
              color='primary'
              onClick={() => validateForm()}
            >
              {loadingUpdate && (
                <Spinner
                  height='15px'
                  spinnerSize={20}
                  colorSecondary
                  margin='0 8px 0 0'
                />
              )}
              Guardar cambios
            </Button>
          </FlexContainer>

          <Dialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
          >
            <ConfirmDelete
              title='Eliminar Producto'
              message='¿Estás seguro de eliminar el producto?'
              onConfirm={() => {
                deleteProduct();
                setOpenDeleteDialog(false);
              }}
              onClose={() => setOpenDeleteDialog(false)}
            />
          </Dialog>
        </>
      </FlexContainer>
      {openCropImage && (
        <Dialog
          fullWidth
          open={openCropImage}
          maxWidth='md'
          onClose={() => {
            setOpenCropImage(false);
            setImageSrc(null);
            setCroppedAreaPixels(null);
          }}
        >
          <FlexContainer container direction='column' padding='20px 40px'>
            <FlexContainer margin='25px 0 20px' alignItems='center'>
              <Typography
                fontSize={18}
                fontWeight={500}
                lineHeight='15px'
                margin='0 0 0 10px'
              >
                Recortar
              </Typography>
            </FlexContainer>
            <ColoredLine />
            <CropContainer>
              <Cropper
                image={imageSrc}
                crop={crop}
                aspect={3 / 3}
                zoom={zoom}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </CropContainer>
            <FlexContainer container justify='flex-end' margin='20px 0 0'>
              <Button
                variant='contained'
                color='primary'
                padding='5px 30px'
                onClick={() => showCroppedImage()}
              >
                Continuar
              </Button>
            </FlexContainer>
          </FlexContainer>
        </Dialog>
      )}
    </>
  );
};

export default EditProduct;
