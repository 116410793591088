/* eslint-disable camelcase */
export interface UserData {
  id: string;
  permissions: Array<string>;
  full_name: string;
  photo: string | null;
  document_type: string;
  national_identity_document: string;
  email: string;
  phone: string;
  onboarding?: boolean;
  role?: string;
}

export const USER_SET = 'USER_SET';
export const USER_CLEAR = 'USER_CLEAR';
export const USER_ONBOARDING = 'USER_ONBOARDING';

interface UserClear {
  type: typeof USER_CLEAR;
}

interface UserSet {
  type: typeof USER_SET;
  payload: UserData;
}

interface UserOnboarding {
  type: typeof USER_ONBOARDING;
  payload: boolean;
}

export type UserActionTypes = UserClear | UserSet | UserOnboarding;
