import TOGGLE_SIDEBAR from './types';

const toggleMenu = (status: boolean) => {
  return {
    type: TOGGLE_SIDEBAR,
    payload: status,
  };
};

export default toggleMenu;
