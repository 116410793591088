import React, { useState } from 'react';
import styled from 'styled-components';
import { useTypedSelector } from 'store';
import { FlexContainer, Typography } from 'components/atoms';
import BranchOffices from './BranchOffices';
import Coverages from './Coverages';

type ViewType = 'branchOffices' | 'coverages';

type TabProps = {
  view: ViewType;
  onClick: (view: ViewType) => void;
};

const list = [
  {
    name: 'Lista de Sucursales',
    value: 'branchOffices' as const,
    permision: 'core.branch_office.list',
  },
  {
    name: 'Coberturas',
    value: 'coverages' as const,
    permision: 'core.coverage.list',
  },
];

const Option = styled(FlexContainer)<{ active: boolean }>`
  text-decoration: none;
  cursor: pointer;
  ${props =>
    props.active && `border-bottom: 2px solid ${props.theme.colors.primary}`}
`;

const Navigation: React.FC<TabProps> = ({ view, onClick }) => {
  const user = useTypedSelector(state => state.user);
  return (
    <FlexContainer
      container
      height='100%'
      justify='space-between'
      alignItems='center'
    >
      <FlexContainer alignItems='center'>
        {list.map(option => {
          if (user.permissions.includes(option.permision)) {
            return (
              <FlexContainer
                key={option.name}
                padding='0 30px 0 0'
                onClick={() => onClick(option.value)}
              >
                <Option
                  alignItems='center'
                  padding='14px 0'
                  active={view === option.value}
                >
                  <Typography>{option.name}</Typography>
                </Option>
              </FlexContainer>
            );
          }
          return null;
        })}
      </FlexContainer>
    </FlexContainer>
  );
};

const BranchOficce: React.FC = () => {
  // Values
  const [view, setView] = useState<'branchOffices' | 'coverages'>(
    'branchOffices'
  );

  return (
    <FlexContainer container direction='column' padding='30px 40px'>
      <Typography fontSize={19} fontWeight={600} margin='0 0 10px'>
        Sucursales
      </Typography>
      <Navigation view={view} onClick={newView => setView(newView)} />
      <FlexContainer container>
        {view === 'branchOffices' && <BranchOffices />}
        {view === 'coverages' && <Coverages />}
      </FlexContainer>
    </FlexContainer>
  );
};

export default BranchOficce;
