import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { Checkbox, Collapse } from '@material-ui/core';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { FormControl, Search } from 'components/molecules';
import useWindowSize from 'hooks/useWindowSize';
import THEME from 'util/styledTheme';
import { optionFormatter } from 'util/functions';
import RouteCard from './RouteCard';
import { RouteBlockTypes } from './types';

const CardSkeleton: React.FC = () => (
  <FlexContainer
    container
    padding='10px 20px 10px 10px'
    borderColor={THEME.colors.borderColor}
    backgroundColor='#FFFFFF'
    justify='flex-end'
  >
    <FlexContainer direction='column' width='90%'>
      <Skeleton width='80%' height={25} />
      <Skeleton width='60%' height={25} />
      <Skeleton width='70%' height={25} />
    </FlexContainer>
  </FlexContainer>
);

const RouteBlock: React.FC<RouteBlockTypes> = ({
  routes,
  loading,
  selectedDetail,
  selectedRoutes,
  filterStatus,
  filterType,
  onDetail,
  onSelect,
  toggleVisibility,
  showingRoutes,
  workerFilter,
  resetWorkerFilter,
  multipleSelect,
}) => {
  const size = useWindowSize();
  const [search, setSearch] = useState<string>('');
  const [filteredRoutes, setFilteredRoutes] = useState<Array<any>>([]);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [statusOptions, setStatusOptions] = useState<Array<any>>([]);
  const [typeOptions, setTypeOptions] = useState<Array<any>>([]);
  const [selectedFilter, setSelectedFilter] = useState({
    status: null,
    type: null,
  });

  const filterRoutes = () => {
    let filtered = routes;
    if (search) {
      filtered = routes.filter(route => {
        const aux = {
          id: route.id,
          code: route.code,
          status: route.status,
          provider: route.provider,
          reference_code: route.reference_code,
          vehicle: route.vehicle,
          worker: route.worker,
        };
        return JSON.stringify(aux)
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    }
    if (selectedFilter.status) {
      filtered = filtered.filter(
        route => route.status === selectedFilter.status
      );
    }
    if (selectedFilter.type) {
      filtered = filtered.filter(route => route.status === selectedFilter.type);
    }
    setFilteredRoutes(filtered);
  };

  useEffect(() => {
    setFilteredRoutes(routes);
  }, [routes]);

  useEffect(() => {
    filterRoutes();
  }, [selectedFilter]);

  useEffect(() => {
    setStatusOptions(
      optionFormatter(filterStatus, {
        id: option => option.code,
        name: option => option.overwritten_label,
      })
    );
  }, [filterStatus]);

  useEffect(() => {
    setTypeOptions(
      optionFormatter(filterType, {
        id: option => option.code,
        name: option => option.name,
      })
    );
  }, [filterType]);

  useEffect(() => {
    if (!isEmpty(workerFilter)) {
      setSearch(`${workerFilter.first_name} ${workerFilter.last_name}`);
      const newList = routes.filter(
        route => route.worker && route.worker.user_id === workerFilter.user_id
      );
      setFilteredRoutes(newList);
    }
  }, [workerFilter]);

  return (
    <FlexContainer
      container
      height='100%'
      overflow='auto'
      direction='column'
      borderColor={THEME.colors.borderColor}
      backgroundColor='#FFFFFF'
      borderRadius='5px'
      style={{ zIndex: 1002 }}
      padding={selectedRoutes.length > 0 ? '0 0 50px' : '0'}
    >
      <div style={{ width: '100%' }}>
        <FlexContainer container padding='10px' direction='column'>
          <FlexContainer
            container
            alignItems='center'
            justify='space-between'
            margin='0 0 15px'
          >
            <Search
              onChangeValue={value => {
                setSearch(value);
                resetWorkerFilter();
              }}
              searchValue={search}
              onSubmit={() => filterRoutes()}
            />
            <Button
              variant='contained'
              color='secondary'
              margin='0 0 0 10px'
              padding='0 10px'
              onClick={() => setShowFilters(!showFilters)}
            >
              <Icon icon='filter-icon' size={25} />
            </Button>
          </FlexContainer>
          <Collapse in={showFilters} timeout='auto'>
            <FlexContainer margin='0 0 10px' direction='column'>
              <FlexContainer container alignItems='center' margin='0 0 5px'>
                <Typography fontSize={12} width='50%'>
                  Estado de ruta
                </Typography>
                <FormControl
                  control='select'
                  value={selectedFilter.status}
                  options={statusOptions}
                  defaultOption={{
                    id: '',
                    name: 'Todos',
                  }}
                  onChange={option =>
                    setSelectedFilter({
                      ...selectedFilter,
                      status: option,
                    })
                  }
                  width='50%'
                />
              </FlexContainer>
              <FlexContainer container alignItems='center' margin='0 0 5px'>
                <Typography fontSize={12} width='50%'>
                  Tipo de ruta
                </Typography>
                <FormControl
                  control='select'
                  value={selectedFilter.type}
                  options={typeOptions}
                  defaultOption={{
                    id: '',
                    name: 'Todos',
                  }}
                  onChange={option =>
                    setSelectedFilter({
                      ...selectedFilter,
                      type: option,
                    })
                  }
                  width='50%'
                />
              </FlexContainer>
            </FlexContainer>
          </Collapse>
          <FlexContainer container alignItems='center' justify='space-between'>
            <FlexContainer alignItems='center' flex='1'>
              {multipleSelect && (
                <FlexContainer width='10%'>
                  <Checkbox
                    checked={
                      filteredRoutes.length > 0 &&
                      filteredRoutes.every(item =>
                        selectedRoutes.includes(item.id)
                      )
                    }
                    disableRipple
                    color='primary'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    style={{ padding: 0 }}
                    onChange={e => {
                      onSelect(
                        filteredRoutes.map(route => route.id),
                        e.target.checked
                      );
                    }}
                  />
                </FlexContainer>
              )}
              <Typography
                color='text.secondary'
                fontSize={13}
                margin={multipleSelect ? '0' : '0 0 0 29px'}
              >
                {`${filteredRoutes.length} rutas encontradas`}
              </Typography>
            </FlexContainer>
            {size.width > 768 && (
              <FlexContainer
                onClick={() => {
                  toggleVisibility(
                    routes,
                    !(showingRoutes.length === routes.length)
                  );
                }}
              >
                {showingRoutes.length === routes.length ? (
                  <Icon icon='show-icon' size={20} />
                ) : (
                  <Icon icon='no-show-icon' size={20} />
                )}
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>
      </div>
      {loading ? (
        <>
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </>
      ) : (
        <>
          {filteredRoutes.map(route => (
            <div key={route.id} style={{ width: '100%' }}>
              <RouteCard
                data={route}
                active={selectedDetail === route.id}
                selected={selectedRoutes.includes(route.id)}
                onDetail={() => onDetail(route)}
                toggleSelect={checked => onSelect([route.id], checked)}
                showRoute={showingRoutes.includes(route.id)}
                toggleVisibility={visibility => {
                  toggleVisibility(
                    [route],
                    visibility || !showingRoutes.includes(route.id)
                  );
                }}
              />
            </div>
          ))}
        </>
      )}
    </FlexContainer>
  );
};

export default RouteBlock;
