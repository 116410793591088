const statusColors = {
  pending: '#F28A8A',
  created: '#89909F',
  searching: '#37C6F0',
  accepted: '#8F8FE6',
  in_progress: '#EFAC28',
  completed: '#51B575',
  ended: '#51B575',
  cancelled: '#A27562',
  failed: '#CE4D47',
  expired: '#000000',
  default: '#000000',
  payed: '#51B575',

  preparing: '#37C6F0',
  ready_to_ship: '#8F8FE6',
  shipped: '#54996c',
  returned: '#EFAC28',
};

export const dashboardColors = [
  '#4675ce',
  '#7ea3ea',
  '#46cec4',
  '#37b1fd',
  '#2066eb',
  '#8ca5d5',
];

export const routeColors = [
  '#F1564F',
  '#450C45',
  '#F0CB00',
  '#4131D3',
  '#42BFB1',
];

export default statusColors;
