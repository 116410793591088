const productsStorage = [
  {
    id: 'warehouse',
    name: 'Almacén',
  },
  {
    id: 'store',
    name: 'Tienda',
  },
  {
    id: 'house',
    name: 'Casa',
  },
  {
    id: 'closed_door_work',
    name: 'Trabajo a puertas cerradas',
  },
];

const countryOptions = [
  {
    name: 'Perú',
    id: 'PE',
  },
  {
    name: 'México',
    id: 'MX',
  },
  {
    name: 'Chile',
    id: 'CL',
  },
  {
    name: 'Argentina',
    id: 'AR',
  },
  {
    name: 'Colombia',
    id: 'CO',
  },
  {
    name: 'Ecuador',
    id: 'EC',
  },
  {
    name: 'Uruguay',
    id: 'UY',
  },
  {
    name: 'Bolivia',
    id: 'BO',
  },
];

export { productsStorage, countryOptions };
