import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import { getChannels } from 'services';
import { FlexContainer, Typography } from 'components/atoms';
import { Header } from 'components/organisms';
import { Spinner } from 'components/molecules';
import WelcomeBackground from 'assets/images/home/WelcomeBackground.svg';
import ShareIcon from 'assets/images/externalLink.svg';

const months = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

const WelcomeBanner = styled(FlexContainer)`
  width: 90%;
  height: 200px;
  background-color: #dbd9f5;
  border-radius: 6px;
  padding: 15px;
  background-image: url(${WelcomeBackground});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: calc(33% - 40px);

  @media only screen and (min-width: 309px) and (max-width: 400px) {
    width: 90%;
    height: 180px;
    padding: 15px;
  }

  @media only screen and (min-width: 401px) and (max-width: 680px) {
    width: 85%;
    height: 190px;
    padding: 20px 30px;
  }

  @media only screen and (min-width: 681px) {
    width: 80%;
    height: 160px;
    padding: 22px 45px;
  }
`;

const WelcomeTitle = styled(Typography)`
  font-size: 20px;

  @media only screen and (min-width: 401px) and (max-width: 580px) {
    font-size: 25px;
  }

  @media only screen and (min-width: 581px) {
    font-size: 28px;
  }
`;

const LinkContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (min-width: 580px) {
    flex-direction: row;
    align-items: center;
  }
`;

const LinkSection = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (min-width: 309px) {
    flex-direction: row;
    align-items: center;
  }
`;

const StoreLink = styled.a`
  color: #5641f0;
  font-size: 18px;
  line-height: 24px;
  margin: 0 5px 0 0;

  @media only screen and (min-width: 400px) {
    margin: 0 5px;
  }
`;

const BlogSection = styled(FlexContainer)`
  padding: 0 30px 30px;

  @media only screen and (min-width: 700px) and (max-width: 900px) {
    padding: 0 50px 40px;
  }

  @media only screen and (min-width: 901px) {
    padding: 0 70px 50px;
  }
`;

const PostContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin-top: 20px;
  justify-items: center;

  @media only screen and (min-width: 700px) and (max-width: 969px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }

  @media only screen and (min-width: 970px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }
`;

const Summary = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #333;
  font-size: 13px;
  line-height: 25px;
  height: 55px;
  margin: 0;
  overflow: hidden;
`;

const CardLink = styled.a`
  text-decoration: none;
`;

const Post = styled(FlexContainer)`
  position: relative;
  margin-bottom: 20px;
  max-width: 348px;
  width: calc(90% - 30px);
  // height: 400px;
  border-radius: 10px;
  border: 1px solid #f0f8ff;
  z-index: 1;
  transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.08), 0 0 0 transparent;

  @media only screen and (min-width: 700px) and (max-width: 949px) {
    width: calc(90% - 40px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 950px) {
    width: calc(90% - 20px);
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
  }
`;

const ImageContainer = styled(FlexContainer)`
  position: relative;
  height: 240px;
  border-radius: 10px 10px 0 0;
  background-color: #f4f8fd;
  overflow: hidden;
  transform: translateZ(0);
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #473ede;
  font-size: 12px;
  letter-spacing: 0.2px;
  text-align: center;
  width: 100%;
  @media only screen and (min-width: 768px) {
    width: auto;
    justify-content: flex-start;
  }
`;

type BlogCardProps = {
  image: string;
  title: string;
  summary: string;
  date: string;
  url: string;
  startWithVideo: boolean;
};

const BlogCard: React.FC<BlogCardProps> = ({
  image,
  title,
  summary,
  date,
  url,
  startWithVideo,
}) => {
  const windowUrl = window.location.host;
  const formattedDate = new Date(date);

  return (
    <Post
      direction='column'
      container
      justify={image ? 'flex-start' : 'center'}
      onClick={() => {
        if (windowUrl === 'app.aimo.co') {
          // @ts-ignores
          window.analytics.track('View post', {
            name: title,
          });
        }
      }}
    >
      <ImageContainer container>
        {image && (
          <a target='_blank' href={url} rel='noopener noreferrer'>
            <img
              src={image}
              alt='blog_image'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            />
          </a>
        )}
      </ImageContainer>
      <CardLink target='_blank' href={url} rel='noopener noreferrer'>
        <FlexContainer
          direction='column'
          width='100%'
          height='200px'
          padding='20px 25px 25px'
        >
          <Typography
            color='#333'
            fontSize='15px'
            lineHeight='1.3'
            letterSpacing='0.4px'
            fontWeight={600}
            margin='0 0 10px'
            title={title}
            style={{
              width: '100%',
              overflow: 'hidden',
            }}
          >
            {title}
          </Typography>
          <Summary>{!startWithVideo && summary}</Summary>
          <Typography
            color='#8C8CB1'
            fontSize='11px'
            letterSpacing='0.2px'
            margin='10px 0 0'
          >
            {`${
              months[formattedDate.getMonth()]
            } ${formattedDate.getDate()}, ${formattedDate.getFullYear()}`}
          </Typography>
        </FlexContainer>
      </CardLink>
    </Post>
  );
};

const Home: React.FC = () => {
  const showMenu = useTypedSelector(state => state.sidebar);
  const user = useTypedSelector(state => state.user);

  // API calls
  const [channels, fetchChannels, loadingChannels] = useRequest(getChannels);

  // Values
  const [storeUrl, setStoreUrl] = useState<string>('');
  const [academyPosts, setAcademyPosts] = useState([]);

  useEffect(() => {
    fetchChannels();
    getPosts();
  }, []);

  useEffect(() => {
    if (!isEmpty(channels)) {
      const digitalCatalog = channels.filter(
        channel => channel.platform === 'digital_catalog'
      );
      setStoreUrl(
        digitalCatalog && digitalCatalog.length > 0
          ? digitalCatalog[0].store
          : ''
      );
    }
  }, [channels]);

  const getPosts = async () => {
    const response = await axios.get(
      `https://blog.aimo.co/ghost/api/v3/content/posts/?key=${process.env.REACT_APP_GHOST_API_KEY}&include=tags`
    );

    setAcademyPosts(
      response.data.posts.filter(post =>
        post.tags.find(name => name.slug === 'academy')
      )
    );
  };

  return (
    <FlexContainer container height='100%'>
      <Header showMenu={showMenu} />
      <FlexContainer container padding='50px 0 0' height='100%'>
        {loadingChannels ? (
          <FlexContainer
            container
            height='100%'
            justify='center'
            alignItems='center'
          >
            <Spinner height='350px' />
          </FlexContainer>
        ) : (
          <FlexContainer
            container
            direction='column'
            height='100%'
            alignItems='center'
            padding='30px 0'
          >
            <WelcomeBanner alignItems='center'>
              <FlexContainer direction='column'>
                <WelcomeTitle
                  color='#000000'
                  fontWeight={600}
                  lineHeight='36px'
                >
                  ¡Te damos la bienvenida, {user.full_name}!
                </WelcomeTitle>
                <LinkContainer margin='10px 0 0'>
                  <Typography
                    color='#000000'
                    fontSize={18}
                    fontWeight={400}
                    lineHeight='24px'
                  >
                    Tu tienda online:
                  </Typography>
                  {storeUrl !== '' ? (
                    <LinkSection>
                      <StoreLink href={`https://${storeUrl}`} target='_blank'>
                        {storeUrl}
                      </StoreLink>
                      <StoreLink href={`https://${storeUrl}`} target='_blank'>
                        <img
                          src={ShareIcon}
                          alt='share'
                          style={{ width: 16 }}
                        />
                      </StoreLink>
                    </LinkSection>
                  ) : (
                    <Typography
                      color='#000000'
                      fontSize={18}
                      fontWeight={400}
                      lineHeight='24px'
                      margin='0 0 0 5px'
                    >
                      Todavia no tienes una tienda :(
                    </Typography>
                  )}
                </LinkContainer>
              </FlexContainer>
            </WelcomeBanner>
            <BlogSection
              direction='column'
              container
              margin='40px 0 0'
              alignItems='center'
            >
              <FlexContainer direction='column'>
                <Typography
                  fontSize={20}
                  fontWeight={700}
                  letterSpacing='0.5px'
                  margin='0 0 5px'
                >
                  Aimo Academy
                </Typography>
                <Typography fontSize={14} color='#8c8cb1' letterSpacing='0.5px'>
                  Aimo Academy es una compilación de hacks para tus distintos
                  emprendimientos, cada tutorial ha sido preparado para que
                  termines con algo tangible (realizable) en un lapso no mayor a
                  20 minutos, sin conocimientos previos de algún tipo. Nunca
                  pares de aprender.
                </Typography>
                <PostContainer>
                  {academyPosts.map(post => (
                    <BlogCard
                      image={post.feature_image}
                      title={post.title}
                      summary={post.excerpt}
                      url={post.url}
                      date={post.created_at}
                      startWithVideo={
                        post.html.startsWith(
                          '<figure class="kg-card kg-video-card"><div class="kg-video-container">'
                        ) ||
                        post.html.includes(
                          '<div class="kg-card kg-file-card kg-file-card-medium">'
                        )
                      }
                    />
                  ))}
                </PostContainer>
                <FlexContainer container justify='center'>
                  <StyledLink
                    target='_blank'
                    href='https://blog.aimo.co/'
                    rel='noopener'
                  >
                    Ver más
                  </StyledLink>
                </FlexContainer>
              </FlexContainer>
            </BlogSection>
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Home;
