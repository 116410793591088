import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledProvider } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import configureStore from './store';
import Router from './containers/Router';
import theme from './util/theme';
import styled from './util/styledTheme';
import 'react-phone-input-2/lib/material.css';
import '../node_modules/react-vis/dist/style.css';

const { store, persistor } = configureStore();

const App: React.FC = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <StyledProvider theme={styled}>
          <CssBaseline />
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </StyledProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

export default App;
