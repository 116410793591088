import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty, find } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { FeatureGroup, Polygon } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import { FlexContainer, Typography, Button, Icon } from 'components/atoms';
import { FormControl, Map, MultipleSelect } from 'components/molecules';
import { optionFormatter } from 'util/functions';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import {
  postCoverages,
  patchCoverages,
  getCoverage,
  getBranchOffices,
} from 'services';
import { EditCoverageProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const Title = styled(Typography)`
  position: absolute;
  top: 10px;
  left: 250px;
`;

const validationState = {
  name: ['mandatory'],
};

const ComponentSkeleton = () => (
  <FlexContainer container margin='0 0 20px' direction='column'>
    <FlexContainer
      container
      padding='10px'
      direction='column'
      justify='space-around'
      borderColor={THEME.colors.borderColor}
    >
      <FlexContainer container alignItems='center' margin='20px 0 0'>
        <Skeleton variant='rect' width='90%' height='650px' />
      </FlexContainer>
      <Skeleton variant='rect' width='80%' height='40px' />
    </FlexContainer>
  </FlexContainer>
);

const CreateCoverage: React.FC<EditCoverageProps> = ({
  data,
  onClose,
  onCreate,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [form, onFormChange, validate, errors, reset, updateForm] = useForm(
    {
      name: '',
      coordinates: [],
      branch_offices: [],
    },
    validationState
  );

  // API calls
  const [createdCoverage, createCoverage, loadingCreate] = useRequest(
    postCoverages
  );
  const [updatedCoverage, updateCoverage, loadingUpdate] = useRequest(
    patchCoverages
  );
  const [coverage, fetchCoverage, loadingCoverage] = useRequest(getCoverage);
  const [branchOffices, fetchBranchOffices, loadingOffices] = useRequest(
    getBranchOffices,
    []
  );

  // Values
  const [coordinates, setCoordinates] = useState([]);
  const [branchOfficeOptions, setBranchOfficeOptions] = useState([]);
  const [selectedBranchOffices, setSelectedBranchOffices] = useState([]);

  useEffect(() => {
    if (!isEmpty(data)) {
      fetchCoverage(data.id);
    }
  }, [data]);

  useEffect(() => {
    fetchBranchOffices();
  }, [fetchBranchOffices]);

  useEffect(() => {
    setBranchOfficeOptions(optionFormatter(branchOffices));
  }, [branchOffices]);

  useEffect(() => {
    if (!isEmpty(coverage)) {
      updateForm({
        name: coverage.name,
        coordinates: coverage.coordinates.map(coord => [coord[1], coord[0]]),
      });
    }
  }, [coverage]);

  useEffect(() => {
    if (!isEmpty(createdCoverage)) {
      enqueueSnackbar('La cobertura fue creada correctamente', {
        variant: 'success',
      });
      onCreate();
      reset();
    }
  }, [createdCoverage, enqueueSnackbar, onCreate, reset]);

  useEffect(() => {
    if (!isEmpty(updatedCoverage)) {
      enqueueSnackbar('La cobertura fue actualizada correctamente', {
        variant: 'success',
      });
      onCreate();
    }
  }, [updatedCoverage, enqueueSnackbar, onCreate]);

  const validateForm = () => {
    if (validate()) {
      const body = {
        name: form.name,
        coordinates,
        branch_offices: selectedBranchOffices.map(office => office.id),
      };
      if (coordinates.length === 0) {
        delete body.coordinates;
      }
      if (data) {
        updateCoverage(body, data.id);
      } else {
        createCoverage(body);
      }
    }
  };

  return (
    <FlexContainer container height='900px' direction='column' padding='20px'>
      {loadingCoverage ? (
        <ComponentSkeleton />
      ) : (
        <>
          <FlexContainer margin='0 0 10px'>
            <CloseIcon onClick={() => onClose()}>
              <Icon
                icon='cancel-icon'
                color={THEME.colors.placeholderColor}
                size={15}
              />
            </CloseIcon>
          </FlexContainer>
          {/* <FlexContainer container margin='10px 0'>
            <Typography fontSize={16} margin='0 0 0 10px'>
              {data ? 'Editar Cobertura' : 'Crear cobertura'}
            </Typography>
          </FlexContainer> */}
          <Title>{data ? 'Editar Cobertura' : 'Crear cobertura'}</Title>

          <FlexContainer container height='80%'>
            <Map zoom={13} position={[-12.1166362, -77.0138613]}>
              <FeatureGroup>
                <EditControl
                  position='topright'
                  onCreated={e => {
                    const layerOnCreate = e.layer;
                    const coords = layerOnCreate.getLatLngs();
                    const coordsToSend = coords[0].map(pair => [
                      pair.lng,
                      pair.lat,
                    ]);
                    const firstPoint = coordsToSend[0];
                    setCoordinates([...coordsToSend, firstPoint]);
                  }}
                  onEdited={e => {
                    const layerOnEdit = e.layers;
                    const coords = layerOnEdit.toGeoJSON().features[0].geometry
                      .coordinates;
                    setCoordinates(coords[0]);
                  }}
                  edit={
                    data
                      ? { edit: true, remove: false }
                      : { edit: false, remove: false }
                  }
                  draw={{
                    polygon: !data && true,
                    marker: false,
                    circle: false,
                    rectangle: false,
                    polyline: false,
                    circlemarker: false,
                  }}
                />
                <Polygon positions={form.coordinates} name={form.name} />
              </FeatureGroup>
            </Map>
          </FlexContainer>

          <FlexContainer
            container
            direction='column'
            padding='10px 10px 5px 10px'
            margin='20px 0'
            borderRadius='8px'
            backgroundColor={THEME.colors.backgroundColor}
          >
            <FlexContainer container>
              <FormControl
                label='Nombre'
                placeholder='Escriba el nombre de la cobertura'
                onChange={value => onFormChange(value, 'name')}
                value={form.name}
                margin='0 0 10px'
                error={errors.name}
                width='40%'
                disabled={loadingCreate || loadingUpdate || loadingCoverage}
                autofocus={!data}
                inline
              />
              <MultipleSelect
                inline
                label='Sucursales'
                placeholder='Seleccione sucursales'
                options={branchOfficeOptions}
                values={selectedBranchOffices}
                onChange={option => {
                  const found = find(selectedBranchOffices, {
                    id: option.id,
                  });
                  if (found) {
                    setSelectedBranchOffices(
                      selectedBranchOffices.filter(
                        item => item.id !== option.id
                      )
                    );
                  } else {
                    setSelectedBranchOffices(
                      selectedBranchOffices.concat(option)
                    );
                  }
                }}
                onDelete={option => {
                  setSelectedBranchOffices(
                    selectedBranchOffices.filter(item => item.id !== option.id)
                  );
                }}
                margin='0 0 20px 20px'
                width='100%'
                disabled={loadingUpdate || loadingCreate || loadingOffices}
              />
            </FlexContainer>
          </FlexContainer>

          {/* <FlexContainer
            container
            padding='10px 10px 0 10px'
            margin='20px 0 0'
            borderRadius='10px'
            backgroundColor={THEME.colors.backgroundColor}
            borderColor={THEME.colors.borderColor}
          >
            <MultipleSelect
              options={branchOfficeOptions}
              values={selectedBranchOffices}
              onChange={option => {
                const found = find(selectedBranchOffices, {
                  id: option.id,
                });
                if (found) {
                  setSelectedBranchOffices(
                    selectedBranchOffices.filter(item => item.id !== option.id)
                  );
                } else {
                  setSelectedBranchOffices(
                    selectedBranchOffices.concat(option)
                  );
                }
              }}
              onDelete={option => {
                setSelectedBranchOffices(
                  selectedBranchOffices.filter(item => item.id !== option.id)
                );
              }}
              margin='0 0 20px'
              width='100%'
              disabled={loadingUpdate || loadingCreate || loadingOffices}
            />
          </FlexContainer> */}

          <FlexContainer container justify='flex-end' margin='10px 0 20px'>
            <Button
              variant='contained'
              color='primary'
              padding='5px 30px'
              onClick={() => validateForm()}
              disabled={loadingCreate || loadingUpdate || loadingCoverage}
            >
              {data ? 'Guardar' : 'Crear'}
            </Button>
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );
};

export default CreateCoverage;
