import React from 'react';
import styled from 'styled-components';
import { FlexContainer, Switch, Typography } from 'components/atoms';
import { RowProps } from './types';

const Box = styled(FlexContainer)`
  border-right: 1px solid rgba(140, 140, 177, 0.2);
  &:hover {
    background-color: ${props => props.theme.colors.borderColor};
    div {
      color: ${props => props.theme.colors.primary};
    }
  }
  &:last-child {
    border-right: none;
  }
`;

const Row: React.FC<RowProps> = ({ data, onClick, onToggle }) => {
  return (
    <FlexContainer
      container
      alignItems='center'
      justify='space-between'
      padding='50px 0'
    >
      <FlexContainer width='20%'>
        {data.logo ? (
          <FlexContainer onClick={() => onClick()} cursor='pointer'>
            <img
              src={data.logo}
              alt='prov_image'
              style={{
                width: '90%',
                height: 'auto',
                cursor: 'pinter',
              }}
            />
          </FlexContainer>
        ) : (
          <Typography
            fontSize={16}
            fontWeight={600}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => onClick()}
          >
            {data.business_name}
          </Typography>
        )}
      </FlexContainer>
      <FlexContainer
        width='60%'
        padding='0 20px'
        alignItems='center'
        justify='space-between'
      >
        <Box
          width='33.3%'
          direction='column'
          alignItems='center'
          padding='10px'
        >
          <div>
            <Typography margin='0 0 5px'>Usuarios</Typography>
            <Typography color='text.secondary'>
              {data.number_of_administrators}
            </Typography>
          </div>
        </Box>
        <Box
          width='33.3%'
          direction='column'
          alignItems='center'
          padding='10px'
        >
          <div>
            <Typography margin='0 0 5px'>Conductores</Typography>
            <Typography color='text.secondary'>
              {data.number_of_workers}
            </Typography>
          </div>
        </Box>
        <Box
          width='33.3%'
          direction='column'
          alignItems='center'
          padding='10px'
        >
          <div>
            <Typography margin='0 0 5px'>Vehiculos</Typography>
            <Typography color='text.secondary'>
              {data.number_of_vehicles}
            </Typography>
          </div>
        </Box>
      </FlexContainer>
      <FlexContainer width='10%'>
        <Switch checked={data.is_active} onChange={() => onToggle()} />
      </FlexContainer>
    </FlexContainer>
  );
};

export default Row;
