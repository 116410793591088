import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useTypedSelector } from 'store';
import { setSession } from 'store/session/actions';
import { selectOrganization } from 'store/organization/actions';
import { TypeUsers } from 'store/organization/types';
import { login, chooseOrganization } from 'services';
import LoginContainer from 'components/organisms/LoginContainer';
import { LoginForm } from 'components/organisms/LoginContainer/types';
import { FlexContainer } from 'components/atoms';
import AimoLogo from 'assets/images/login/login-image.png';
import { OrganizationType } from './types';

const FullGrid = styled(Grid)`
  height: 100vh;
  background-color: #ffffff;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1.2fr 1fr;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 768px) and (max-width: 820px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 821px) and (max-width: 1190px) {
    grid-template-columns: 1.3fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 1191px) {
    grid-template-columns: 1.1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const AimoBackground = styled(FlexContainer)`
  background-color: #f1564f;
  border-radius: 0;
  padding: 50px 0;

  @media only screen and (min-width: 768px) {
    padding: 0;
    border-radius: 48px 0 0 48px;
  }
`;

const LogoContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  img {
    padding-top: 50px;
    width: 85%;
    height: auto;
  }

  @media only screen and (min-width: 768px) {
    img {
      padding-top: 50px;
      max-width: 550px;
      height: auto;
    }
  }
`;

const RightSide: React.FC = () => (
  <AimoBackground container justify='center' height='100%'>
    <LogoContainer>
      <img src={AimoLogo} alt='aimo_logo' />
    </LogoContainer>
  </AimoBackground>
);

const Login: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const session = useTypedSelector(state => state.session);
  const organization = useTypedSelector(state => state.organization);
  const dispatch = useDispatch();
  const history = useHistory();
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showOrganization, setShowOrganization] = useState<boolean>(false);
  const [redirect, setRedirect] = useState<boolean>(false);
  // const [error, setError] = useState<boolean>(false);
  const [form, setForm] = useState<LoginForm>({
    email: '',
    password: '',
  });
  useEffect(() => {
    if (session && organization.selectedOrganization) {
      const shopify = window.localStorage.getItem('shopify');
      if (shopify) {
        const shopifyData = JSON.parse(shopify);
        if (shopifyData.shop && shopifyData.state) {
          history.push(
            `/shopify?shop=${shopifyData.shop}&state=${shopifyData.state}`
          );
        } else {
          history.push('/');
        }
      } else {
        history.push('/');
      }
    }
  }, [history, session, organization]);

  const organizationFormatter = (organizations: Array<OrganizationType>) => {
    return organizations.map(item => {
      const typeOfUsers: Array<TypeUsers> = [];
      if (item.administrator_branch_offices.length > 0) {
        typeOfUsers.push({
          id: 'administrator_branch_offices',
          name: 'Administrador',
          branchOffices: item.administrator_branch_offices,
        });
      }
      return {
        id: item.id.toString(),
        name: item.tradename,
        photo: item.photo,
        typeOfUsers,
        slug: item.slug,
        plan: item.pricing_plan.code,
        planName: item.pricing_plan.name,
      };
    });
  };

  const getUserOrganizations = () => {
    chooseOrganization({
      page_size: 0,
    })
      .then(res => {
        const organizations = organizationFormatter(res.data);
        if (organizations.length === 0) {
          setRedirect(true);
        } else if (organizations.length === 1) {
          const uniqueOrg = {
            id: parseInt(organizations[0].id, 10),
            name: organizations[0].name,
            photo: organizations[0].photo,
            typeUser: organizations[0].typeOfUsers[0],
            slug: organizations[0].slug,
            plan: organizations[0].plan,
            planName: organizations[0].planName,
          };
          localStorage.setItem(
            'AIMO_ADMIN_ORGANIZATION_ID',
            uniqueOrg.id.toString()
          );
          dispatch(selectOrganization(uniqueOrg));
        } else {
          setOrganizationOptions(organizations);
          setShowOrganization(true);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    setLoading(true);
    login(form)
      .then(res => {
        localStorage.setItem('AIMO_API_TOKEN', res.data.access_token);
        dispatch(setSession(res.data));
        getUserOrganizations();
        localStorage.setItem('user_email', form.email);
      })
      .catch(() => {
        enqueueSnackbar('Los datos ingresados no coinciden con una cuenta.', {
          variant: 'error',
        });
        // setError(err.response);
        setLoading(false);
      });
  };

  const handleOrganizationSelect = (organizationObj, user) => {
    const selectedOrganization = {
      id: organizationObj.id,
      name: organizationObj.name,
      photo: organizationObj.photo,
      typeUser: user,
      slug: organizationObj.slug,
      plan: organizationObj.plan,
      planName: organizationObj.planName,
    };

    localStorage.setItem(
      'AIMO_ADMIN_ORGANIZATION_ID',
      organizationObj.id.toString()
    );
    dispatch(selectOrganization(selectedOrganization));
  };

  return (
    <FullGrid container>
      <Container>
        <FlexContainer container height='100%'>
          <LoginContainer
            form={form}
            onChange={(value, name) => {
              setForm({
                ...form,
                [name]: value,
              });
            }}
            onLoginSubmit={() => handleSubmit()}
            loading={loading}
            showOrganizationSelect={showOrganization}
            organizationOptions={organizationOptions}
            onOrganizationSelect={(organizationId, user) =>
              handleOrganizationSelect(organizationId, user)
            }
          />
        </FlexContainer>
        <RightSide />
      </Container>
      {redirect && <Redirect to='/onboarding' />}
    </FullGrid>
  );
};

export default Login;
