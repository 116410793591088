import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import { Route, Switch, useLocation, Link } from 'react-router-dom';
import { useTypedSelector } from 'store';
import {
  FlexContainer,
  Typography,
  Button,
  Icon,
  Divider,
} from 'components/atoms';
import { Header, FiltersContainer } from 'components/organisms';
import evalPermission from 'util/evalPermission';
import useRequest from 'hooks/useRequest';
import { optionFormatter } from 'util/functions';
import { getOrderTypes, getStatuses, getTimeWindows } from 'services';
import THEME from 'util/styledTheme';
import { ReactComponent as Arrow } from 'assets/images/arrow-left.svg';
import OrdersList from './OrdersList';
import MassivePurchaseCharge from './MassivePurchaseCharge';
import CreateOrder from './CreateOrder';

const FiltersSection = styled(FlexContainer)`
  width: 250px;
  min-height: 880px;
  height: 100%;
  background-color: #ffffff;
  padding: 26px 20px;
  box-shadow: inset -1px 0px 0px rgba(0, 0, 0, 0.1);
`;
const CloseIcon = styled(FlexContainer)`
  position: absolute;
  right: 0;
`;

const initialFilters = [
  {
    label: 'Tipo de orden',
    placeholder: 'Tipo de orden',
    name: 'order_type_id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
  },
  {
    label: 'Fecha creada',
    placeholder: 'Fecha creada',
    name: 'created_at',
    type: 'date',
  },
  {
    label: 'Fecha programada',
    placeholder: 'Fecha programada',
    name: 'delivery_programmed_date',
    type: 'date',
  },
  {
    label: 'Tipo de estado',
    placeholder: 'Tipo de estado',
    name: 'last_status__id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
  },
  {
    label: 'Ventanas Horarias',
    placeholder: 'Seleccione ventana horaria',
    name: 'time_window',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
    permission: 'core.time_window.list',
  },
];

const Orders: React.FC = () => {
  const showMenu = useTypedSelector(state => state.sidebar);
  const user = useTypedSelector(state => state.user);
  const location = useLocation();

  // API calls
  const [orderTypes, fetchOrderTypes] = useRequest(getOrderTypes, []);
  const [allStatus, fetchAllStatus] = useRequest(getStatuses, []);
  const [timeWindows, fetchTimeWindows, loadingTimeWindows] = useRequest(
    getTimeWindows,
    null
  );

  // Values
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<boolean>(false);
  const [resetFiltersData, setResetFiltersData] = useState<boolean>(false);
  const [filters, setFilters] = useState(initialFilters);
  const [filterValues, setFilterValues] = useState({
    order_type_id: null,
    last_status__id: null,
    delivery_programmed_date: null,
    created_at: null,
    time_window: null,
    client_id: null,
  });

  useEffect(() => {
    fetchOrderTypes({
      page_size: 0,
    });
    fetchAllStatus({
      model_types: 'order',
      status_types: 'manual,automatic',
      page_size: 0,
    });
    fetchTimeWindows({
      page_size: 0,
    });
  }, [fetchOrderTypes, fetchAllStatus, fetchTimeWindows]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[0].options = optionFormatter(orderTypes);
    setFilters(updatedFilters);
  }, [orderTypes]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[3].options = optionFormatter(allStatus, {
      id: item => item.id,
      name: item => item.overwritten_label,
    });
    setFilters(updatedFilters);
  }, [allStatus]);

  useEffect(() => {
    if (timeWindows && timeWindows.length > 0) {
      const updatedFilters = cloneDeep(filters);
      if (updatedFilters[4].options.length === 0) {
        updatedFilters[4].options = optionFormatter(timeWindows, {
          id: item => item.id,
          name: item => `${item.start_hour} - ${item.end_hour}`,
        });
        setFilters(updatedFilters);
      }
    }
  }, [timeWindows, filters]);

  const resetFilters = () => {
    setFilterValues({
      order_type_id: null,
      last_status__id: null,
      delivery_programmed_date: null,
      created_at: null,
      time_window: null,
      client_id: filterValues.client_id,
    });
    setResetFiltersData(true);
    setFilterData(false);
  };

  return (
    <FlexContainer container height='100%'>
      <Header showMenu={showMenu} />
      <FlexContainer container padding='50px 0 0'>
        {openFilters && (
          <FiltersSection direction='column' justify='space-between'>
            <FlexContainer container direction='column'>
              <FlexContainer
                container
                justify='space-between'
                margin='0 0 20px'
                position='relative'
              >
                <Typography
                  color='#000929'
                  fontSize={18}
                  fontWeight={700}
                  lineHeight='24px'
                >
                  Filtros
                </Typography>
                <CloseIcon onClick={() => setOpenFilters(false)}>
                  <Icon
                    icon='cancel-icon'
                    color={THEME.colors.placeholderColor}
                    size={25}
                  />
                </CloseIcon>
              </FlexContainer>
              <Divider orientation='horizontal' type='solid' color='#E0DEF7' />
              <FiltersContainer
                labelWeight={600}
                filters={filters}
                value={filterValues}
                onChange={(value, name) => {
                  setFilterValues({
                    ...filterValues,
                    [name]: value,
                  });
                  setFilterData(false);
                }}
                disabled={loadingTimeWindows}
                permissions={user.permissions}
                margin='0 0 25px 0'
                wrap='wrap'
                width='100%'
                showLabel
              />
            </FlexContainer>
            <FlexContainer container justify='space-between'>
              <Button
                variant='contained'
                color='secondary'
                margin='0'
                onClick={() => resetFilters()}
              >
                Limpiar
              </Button>
              <Button
                variant='contained'
                color='primary'
                margin='0'
                onClick={() => {
                  setFilterData(true);
                  setResetFiltersData(false);
                }}
              >
                Aplicar
              </Button>
            </FlexContainer>
          </FiltersSection>
        )}
        <FlexContainer direction='column' container height='100vh'>
          <FlexContainer
            container
            direction='column'
            padding={openFilters ? '35px 25px 0' : '35px 40px 0'}
          >
            <FlexContainer
              container
              justify='space-between'
              alignItems='center'
              padding='0 0 20px'
            >
              <FlexContainer alignItems='center'>
                {location.pathname !== '/ordenes-de-compra' &&
                  location.pathname !== '/ordenes-de-compra/' && (
                    <Link to='/ordenes-de-compra'>
                      <FlexContainer margin='0 5px 0 0'>
                        <Arrow />
                      </FlexContainer>
                    </Link>
                  )}
                <Typography fontSize={26} fontWeight={600} color='#000929'>
                  Órdenes de compra
                </Typography>
              </FlexContainer>
              <FlexContainer>
                {user.permissions.includes('core.purchaseorder.batch_xls') && (
                  <Button
                    variant='contained'
                    color='secondary'
                    margin='0 10px 0 0'
                    padding='0 8px'
                    borderRadius='50%'
                    onClick={() =>
                      window.open(
                        '/ordenes-de-compra/carga-masiva-purchase',
                        '_self'
                      )
                    }
                  >
                    <Icon icon='upload' size={25} />
                  </Button>
                )}
                {user.permissions.includes('core.order.create') && (
                  <Button
                    variant='contained'
                    color='primary'
                    margin='0'
                    padding='12px 20px'
                    onClick={() =>
                      window.open('/ordenes-de-compra/crear-orden', '_self')
                    }
                  >
                    <Icon icon='plus-icon' size={22} margin='0 5px 0 0' />
                    Crear
                  </Button>
                )}
              </FlexContainer>
            </FlexContainer>
            <Divider orientation='horizontal' type='solid' color='#E0DEF7' />
          </FlexContainer>
          <Switch>
            <Route
              exact
              path='/ordenes-de-compra'
              render={() => {
                if (user.permissions.includes('core.order.list')) {
                  return (
                    <OrdersList
                      handleOpenFilters={() => setOpenFilters(!openFilters)}
                      filterValues={filterValues}
                      filterData={filterData}
                      setFilterValues={setFilterValues}
                      resetFilters={resetFiltersData}
                    />
                  );
                }
                return null;
              }}
            />
            <Route
              exact
              path='/ordenes-de-compra/carga-masiva-purchase'
              render={() =>
                evalPermission(
                  user.permissions,
                  'core.purchaseorder.batch_xls',
                  MassivePurchaseCharge
                )
              }
            />
            <Route
              exact
              path='/ordenes-de-compra/crear-orden'
              render={() =>
                evalPermission(
                  user.permissions,
                  'core.order.create',
                  CreateOrder
                )
              }
            />
          </Switch>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Orders;
