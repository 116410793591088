import React from 'react';
import styled from 'styled-components';
import THEME from 'util/styledTheme';
import { FlexContainer, Typography } from 'components/atoms';
import trackingDefault from 'assets/images/portal/vehicle-portal-tracking.png';
import noItemsRegisteredImage from 'assets/images/feedback/feedback-bad.svg';
import phoneSvg from 'assets/images/portal/phone-1.svg';
import phoneOnHover from 'assets/images/portal/phone-2.svg';
import {
  ProductInformationProps,
  ContainerProps,
  UserContactProps,
  ResponsiveContainerProps,
} from './types';

const ContainerLogo = styled(FlexContainer)<ContainerProps>`
  height: ${props => props.height || '40'}px;
  width: ${props => props.width || '40'}px;
  border-radius: ${props => props.borderRadius}px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;

  &:hover {
    background-color: ${props => props.backgroundHover};

    div {
      background-image: url(${props => props.imgHover});
    }
  }

  div {
    background-image: url(${props => props.img});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
  }
`;

export const ResponsiveContainer = styled(FlexContainer)<
  ResponsiveContainerProps
>`
  @media screen and (max-width: 768px) {
    ${props => props.resDisplay && `display: ${props.resDisplay};`}
    ${props => props.resMargin && `margin: ${props.resMargin};`}
    ${props => props.resPadding && `padding: ${props.resPadding};`}
    ${props => props.resHeight && `height: ${props.resHeight};`}
    ${props => props.resWidth && `width: ${props.resWidth};`}
    ${props => props.resAlignItems && `align-items: ${props.resAlignItems};`}
    ${props => props.resJustify && `justify-content: ${props.resJustify};`}
    ${props => props.resDirection && `flex-direction: ${props.resDirection};`}
  }
`;

const Label = styled.th`
  color: #404071;
  padding: 20px 15px 10px 0;
  width: auto;
  font-size: 16px;
  font-weight: lighter;
  text-align: left;
`;

const ItemText = styled.td`
  color: #8c8cb1;
  margin: 20px 10px 20px 0;
  font-size: 16px;
  font-weight: lighter;
`;

const UserContact: React.FC<UserContactProps> = ({
  phoneNumber,
  img,
  imgHover,
}) => {
  return (
    <FlexContainer direction='column'>
      <ContainerLogo
        backgroundHover={THEME.colors.primary}
        borderRadius='50px'
        justify='center'
        alignItems='center'
        img={img}
        imgHover={imgHover}
      >
        <div>
          <a href={`tel:${phoneNumber}`}>
            <div />
          </a>
        </div>
      </ContainerLogo>
      <Typography fontSize='12px' color='text.primary' margin='4px 0 0 0'>
        Llamar
      </Typography>
    </FlexContainer>
  );
};

const ProductInformation: React.FC<ProductInformationProps> = ({
  transportationLogo,
  externalCode,
  contactPerson,
  destination,
  destinationDetail,
  itemsInformation,
  existItems,
  trackingPhoneNumber,
}) => {
  return (
    <ResponsiveContainer container resWidth='90%'>
      <ResponsiveContainer
        margin='50px 10px'
        alignItems='center'
        resMargin='10px 0 10px 50px'
      >
        <ResponsiveContainer height='100%' resDisplay='none'>
          <div
            style={{
              height: '400px',
              margin: '50px 10px 0',
              border: 'solid 1px #efeff7',
            }}
          />
        </ResponsiveContainer>

        <FlexContainer container direction='column' alignItems='center'>
          <ResponsiveContainer width='75%' direction='column' resWidth='95%'>
            {existItems ? (
              <>
                <Typography margin='40px 0 0 0' fontSize='16px'>
                  Enviado por
                </Typography>
                <FlexContainer width='300px' alignItems='center'>
                  <img
                    src={transportationLogo || trackingDefault}
                    alt='courrier logo'
                    width='60px'
                    height='auto'
                    style={{ margin: '20px 10px 20px 0' }}
                  />
                  <FlexContainer container direction='column'>
                    <div style={{ marginLeft: '20px' }}>
                      <UserContact
                        phoneNumber={trackingPhoneNumber}
                        img={phoneSvg}
                        imgHover={phoneOnHover}
                      />
                    </div>
                  </FlexContainer>
                </FlexContainer>
                <FlexContainer width='300px'>
                  <Typography margin='0'>{`ID: ${externalCode}`}</Typography>
                </FlexContainer>
                <Typography margin='15px 0' fontSize='16px'>
                  Detalle de entrega
                </Typography>
                <Typography color='text.secondary'>{contactPerson}</Typography>
                <Typography color='text.secondary'>{destination}</Typography>
                {destinationDetail && (
                  <Typography color='text.secondary'>
                    {destinationDetail}
                  </Typography>
                )}
                <table>
                  <thead>
                    <tr>
                      <Label>Código</Label>
                      <Label>Producto</Label>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <ItemText>{itemsInformation.itemCode}</ItemText>
                      <ItemText>
                        {`${itemsInformation.itemDescription} x ${itemsInformation.itemQuantity}`}
                      </ItemText>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              <FlexContainer width='280px' direction='column' margin='20px 0'>
                <Typography margin='5px 0' color='#F1564F'>
                  No hay items registrados en la orden
                </Typography>
                <Typography margin='10px 0' fontSize='14px'>
                  Para más información contactarse al número de contacto
                </Typography>
                <FlexContainer container justify='center'>
                  <img src={noItemsRegisteredImage} alt='sad face' />
                </FlexContainer>
              </FlexContainer>
            )}
          </ResponsiveContainer>
        </FlexContainer>
      </ResponsiveContainer>
    </ResponsiveContainer>
  );
};

export default ProductInformation;
