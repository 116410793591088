import DefaultProfile from 'assets/images/DefaultProfile.png';

type KeyFormat = {
  id: (item: any) => string;
  name: (item: any) => string;
};

type Format = {
  id: string;
  name: string;
};

type SearchKeyFormat = KeyFormat & {
  phone: (item: any) => string;
  photo?: (item: any) => string;
  logo?: (item: any) => string;
};

type SearchFormat = Format & {
  phone: string;
  photo?: string;
  logo?: string;
};

export const optionFormatter: (
  options: Array<any>,
  keys?: KeyFormat
) => Array<Format> = (options, keys) => {
  return options.map(option => {
    if (keys) {
      return {
        id: keys.id(option),
        name: keys.name(option),
      };
    }

    return {
      // eslint-disable-next-line no-underscore-dangle
      id: option.id ? option.id.toString() : option._id,
      name: option.name,
    };
  });
};

export const providerOptionFormatter = options => {
  return options.map(option => {
    return {
      id: option.id.toString(),
      name: option.business_name,
    };
  });
};

export const providerFormatter: (
  options: Array<any>,
  keys?: SearchKeyFormat
) => Array<SearchFormat> = (options, keys) => {
  return options.map(option => {
    if (keys) {
      const object: SearchFormat = {
        id: keys.id(option),
        name: keys.name(option),
        phone: keys.phone(option),
      };
      if (keys.photo) {
        object.photo = keys.photo(option);
      }
      if (keys.logo) {
        object.logo = keys.logo(option);
      }
      return object;
    }

    return {
      id: option.id,
      name: option.business_name,
      photo: option.logo,
      phone: option.phone_number,
    };
  });
};

export const vehicleFormatter = vehicles => {
  return vehicles.map(vehicle => ({
    id: vehicle.id,
    worker_id: vehicle.worker.id,
    name: `${vehicle.worker.full_name}`,
    photo: vehicle.worker.photo || DefaultProfile,
    phone: vehicle.worker.phone,
    plate: vehicle.plate,
  }));
};

export const workerFormatter = workers => {
  return workers.map(worker => ({
    id: worker.id,
    name: `${worker.first_name} ${worker.last_name}`,
    photo: worker.photo || DefaultProfile,
    phone: worker.phone,
  }));
};

export const clientInformationFormatter = orderDataDetail => {
  return orderDataDetail.map(customer => ({
    // dni: customer.contact_id,
    // extra_data:
    //   customer.task_order_points[0].fields_data === []
    //     ? '-'
    //     : customer.task_order_points[0].fields_data.find(
    //         data => data.field_configuration.input_type === 'text'
    //       ),
    received_by: customer.full_name,
    comments: customer.description,
  }));
};

export const orderPhotoFormatter = taskOrders => {
  return taskOrders.map(photo => ({
    sign_photo:
      photo.task_order_points[0].fields_data === []
        ? '-'
        : photo.task_order_points[0].fields_data.find(
            sign => sign.field_configuration.input_type === 'sign'
          ),
    item_pictures:
      photo.task_order_points[0].fields_data === []
        ? '-'
        : photo.task_order_points[0].fields_data.filter(
            image => image.field_configuration.input_type === 'image'
          ),
    marker_coordinates: photo.coordinate
      ? [photo.coordinate.latitude, photo.coordinate.longitude]
      : [],
  }));
};

export const itemDescriptionFormatter = purchaseOrder => {
  return purchaseOrder.map(
    detail =>
      detail.items.length > 0 &&
      detail.items.map(item => {
        return {
          code: item.code,
          description: item.description,
          width: item.width,
          height: item.height,
          weight: item.weight,
          price: item.value,
          quantity: item.quantity,
        };
      })
  );
};

export const orderDetailFormatter = orderTask => {
  return orderTask.map((task, index) => ({
    id: task.id,
    task_order_sequence: index,
    task_name: task.task_order_points[0].task_name,
    task_status: task.task_order_points[0].last_status,
    task_date: task.task_order_points[0].final_status_date,
    original_eta: task.task_order_points[0].original_eta,
    real_eta: task.task_order_points[0].current_eta,
    task_address: task.task_order_points[0].address,
    task_district: task.task_order_points[0].district
      ? task.task_order_points[0].district
      : '',
    task_contact: task.task_order_points[0].received,
    task_reference: task.address_detail,
    task_contact_phone: task.contact_phone,
    task_status_detail:
      task.task_order_points[0].last_status_detail &&
      task.task_order_points[0].last_status_detail.label,
    task_code: task.task_order_points[0].last_status_code,
    final_position: task.task_order_points[0].final_position && [
      task.task_order_points[0].final_position.latitude,
      task.task_order_points[0].final_position.longitude,
    ],
  }));
};

export const orderDropOffDetailFormatter = orderDataDetail => {
  return orderDataDetail.map((order, index) => ({
    id: order.id || '',
    // task_order_sequence: index,
    task_name: 'Entrega',
    // task_status: customer.task_order_points[0].last_status,
    // task_date: customer.task_order_points[0].final_status_date,
    // original_eta: customer.task_order_points[0].original_eta,
    // real_eta: customer.task_order_points[0].current_eta,
    task_address: order.customer && order.customer.address,
    task_contact: order.customer && order.customer.full_name,
    task_reference: order.customer && order.customer.address_detail,
    task_contact_phone: order.customer && order.customer.phone_number,
    // task_status_detail:
    //   customer.task_order_points[0].last_status_detail &&
    //   customer.task_order_points[0].last_status_detail.label,
    // task_code: customer.task_order_points[0].last_status_code,
    final_position: order.customer &&
      order.customer.address_coordinates && [
        order.customer.address_coordinates.latitude,
        order.customer.address_coordinates.longitude,
      ],
  }));
};

export const validateNumbersInput = (value: string) => {
  return value
    .replace(/,/g, '.')
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*?)\..*/g, '$1');
};

export const getLocalStorageClientId = () => {
  const clientId = localStorage.getItem('AIMO_ADMIN_CLIENT_ID');
  // eslint-disable-next-line
  return !!clientId ? parseInt(clientId) : null;
};

export const getProductPrice = (product: any) => {
  if (!product.has_variations) {
    return product.price;
  }

  const variationsDetails = product.variations_details;
  const prices = variationsDetails.map(detail => detail.price);
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  if (minPrice === maxPrice) {
    return minPrice;
  }

  return `${minPrice} - ${maxPrice}`;
};

export const generateObjectId = function() {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function() {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
};
