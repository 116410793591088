import React from 'react';
import {
  Select,
  SelectSearch,
  Input,
  Date,
  Time,
  FlexContainer,
  Typography,
  PhoneInput,
  CountrySelect,
  SimplePhoneInput,
} from 'components/atoms';
import styled from 'styled-components';
import moment from 'moment';
import THEME from 'util/styledTheme';
import { FormControlProps, ContainerProps, LabelProps } from './types';

const Container = styled.div<ContainerProps>`
  display: flex;
  width: ${props => props.width || '220px'};
  align-items: ${props => (props.inline ? 'center' : 'flex-start')};
  flex-direction: ${props => (props.inline ? 'row' : 'column')};
  margin: ${props => props.margin || '0'};
  label {
    margin-bottom: ${props => (props.inline ? '0' : '10px')};
  }
`;

const Label = styled.label<LabelProps>`
  color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.disabled
      ? props.theme.colors.placeholderColor
      : props.secondary
      ? props.theme.colors.textSecondary
      : props.theme.colors.textPrimary};
  font-weight: ${props => props.labelWeight || '500'};
  font-size: ${props => props.labelSize || '12px'};
  margin-right: 15px;
  white-space: nowrap;
`;

const InputError = styled(FlexContainer)`
  top: -12px;
  right: -15px;
  z-index: 1;
`;

const Optional = styled.span`
  color: #8c8cb1;
`;

const FormControl: React.FC<FormControlProps> = ({
  placeholder,
  label,
  secondary,
  labelWeight,
  labelSize,
  value,
  onChange,
  autofocus,
  disabled,
  type,
  margin,
  inline,
  endAdornment,
  startAdornment,
  control,
  options,
  defaultOption,
  width,
  height,
  dateIcon,
  suffix,
  dateFormat,
  dateShow,
  onPressEnter,
  error,
  toolbar,
  rows,
  borderless,
  background = true,
  country,
  optional = false,
  onFocus,
  variant,
  showLabel = false,
}) => {
  return (
    <Container inline={inline} margin={margin} width={width}>
      {label && (
        <Label
          labelWeight={labelWeight}
          labelSize={labelSize}
          disabled={disabled}
          secondary={secondary}
        >
          {label}
          {optional && <Optional> (Opcional)</Optional>}
        </Label>
      )}
      <FlexContainer
        container
        wrap='nowrap'
        alignItems='center'
        position='relative'
      >
        {(control === 'input' || !control) && (
          <Input
            variant={variant || 'outlined'}
            color='primary'
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e.target.value)}
            autoFocus={autofocus}
            disabled={disabled}
            fullWidth
            type={type || 'text'}
            margin='0'
            height={height}
            padding='0 18px'
            InputProps={{
              endAdornment,
              startAdornment,
            }}
            onKeyUp={event => {
              if (event.keyCode === 13 && onPressEnter) {
                onPressEnter();
              }
            }}
          />
        )}
        {control === 'textarea' && (
          <Input
            variant='outlined'
            color='primary'
            placeholder={placeholder}
            value={value}
            onChange={e => onChange(e.target.value)}
            autoFocus={autofocus}
            disabled={disabled}
            fullWidth
            type={type || 'text'}
            margin='0'
            height={height}
            padding='0 18px'
            InputProps={{
              endAdornment,
              startAdornment,
            }}
            onKeyUp={event => {
              if (event.keyCode === 13 && onPressEnter) {
                onPressEnter();
              }
            }}
            multiline
            rows={rows || 5}
            rowsMax={5}
            onFocus={() => {
              if (onFocus) {
                onFocus();
              }
            }}
          />
        )}
        {control === 'select' && (
          <Select
            placeholder={placeholder}
            options={options}
            defaultOption={defaultOption}
            startIcon={startAdornment}
            onChange={(id, name) => onChange(id, name)}
            value={value}
            width='100%'
            disabled={disabled}
            variant={variant || 'outlined'}
            maxWidth={inline && '350px'}
            height={height}
          />
        )}
        {control === 'selectSearch' && (
          <SelectSearch
            placeholder={placeholder}
            options={options}
            variant={variant || 'outlined'}
            defaultOption={defaultOption}
            startIcon={startAdornment}
            onChange={(id, name) => onChange(id, name)}
            value={value}
            width='100%'
            disabled={disabled}
            maxWidth={inline && '350px'}
          />
        )}
        {control === 'date' && (
          <Date
            placeholder={placeholder}
            onChange={date =>
              onChange(date ? moment(date).format('YYYY-MM-DD') : date)
            }
            value={value ? moment(value) : value}
            blocked={disabled}
            toolbar={toolbar}
            borderless={borderless}
            background={background}
          />
        )}
        {control === 'time' && (
          <Time
            onChange={time => onChange(time)}
            value={value ? moment(value, 'HH:mm') : value}
            icon={dateIcon}
            show={dateShow}
            format={dateFormat}
          />
        )}
        {control === 'phone' && (
          <PhoneInput
            placeholder={placeholder}
            value={value as string}
            onChange={(phone, object) => onChange(phone, object)}
            country={country}
            disabled={disabled}
          />
        )}
        {control === 'simplePhone' && (
          <SimplePhoneInput
            placeholder={placeholder}
            value={value as string}
            onChange={(phone, object) => onChange(phone, object)}
            country={country}
            disabled={disabled}
          />
        )}
        {control === 'country' && (
          <CountrySelect
            placeholder={placeholder}
            options={options}
            defaultOption={defaultOption}
            startIcon={startAdornment}
            onChange={(id, name) => onChange(id, name)}
            value={value}
            width='100%'
            disabled={disabled}
            maxWidth={inline && '350px'}
          />
        )}
        <Typography fontSize={12}>{suffix}</Typography>
        {error && (
          <InputError
            borderColor={THEME.colors.error}
            backgroundColor={THEME.colors.errorBg}
            padding='2px 5px'
            position='absolute'
            borderRadius='4px'
          >
            <Typography fontSize={11} color={THEME.colors.error}>
              {error}
            </Typography>
          </InputError>
        )}
      </FlexContainer>
    </Container>
  );
};

export default FormControl;
