import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { Dialog } from '@material-ui/core';
import {
  Button,
  FlexContainer,
  Typography,
  Icon,
  // Pill,
} from 'components/atoms';
import { Dropdown, DropdownIconOption, Spinner } from 'components/molecules';
import { DataTable } from 'components/organisms';
import useRequest from 'hooks/useRequest';
import { getCoverages } from 'services';
import { useTypedSelector } from 'store';
import NoData from 'assets/images/no_data.svg';
import CreateCoverages from './Create/index';

const CoverageList: React.FC = () => {
  const user = useTypedSelector(state => state.user);
  // API calls
  const [coverages, fetchCoverages, loadingCoverages, pageData] = useRequest(
    getCoverages,
    []
  );

  // Values
  const [page, setPage] = useState<number>(1);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState(null);
  const [firstLoadingCoverages, setFirstLoadingCoverages] = useState<boolean>(
    false
  );
  const [firstCoveragesRender, setFirstCoveragesRender] = useState<boolean>(
    false
  );
  const [coveragesList, setCoverageList] = useState([]);

  const requestCoverages = useCallback(() => {
    const params = {
      page,
    };

    fetchCoverages(params);
    setFirstCoveragesRender(false);
  }, [fetchCoverages, page]);

  const settingsOptions = [
    {
      label: 'Editar',
      name: 'edit',
      icon: 'edit-icon',
      onClick: row => {
        setEditData(row);
        setOpenEdit(true);
      },
    },
  ];

  useEffect(() => {
    requestCoverages();
    setFirstCoveragesRender(true);
  }, [page]);

  useEffect(() => {
    if (!isEmpty(coverages)) {
      setCoverageList(coverages);
    }
  }, [coverages]);

  useEffect(() => {
    if (firstCoveragesRender && loadingCoverages && page === 1) {
      setFirstLoadingCoverages(true);
    } else {
      setFirstLoadingCoverages(false);
    }
  }, [firstCoveragesRender, loadingCoverages]);

  const option = row =>
    settingsOptions.map(setting => (
      <DropdownIconOption
        key={setting.name}
        label={setting.label}
        name={setting.name}
        icon={setting.icon}
        onClick={() => setting.onClick(row)}
      />
    ));

  const headers = useMemo(
    () => [
      {
        label: 'Nombre',
        id: 'name',
        cell: row => row.name || '-',
      },
      // {
      //   label: 'Sucursales',
      //   id: 'branch_offices',
      //   cell: row =>
      //     (row.branch_offices.length > 0
      //       ? row.branch_offices.map(branchOffice => (
      //         <Pill key={branchOffice.id} padding='3px 10px' margin='5px'>
      //           {branchOffice.name}
      //         </Pill>
      //       ))
      //       : '-'),
      // },
      {
        label: '',
        id: 'settings',
        cell: row =>
          user.permissions.includes('core.coverage.update') && (
            <Dropdown
              label={<Icon icon='options-icon' size={20} />}
              direction='bottom'
              position='left'
              showCaret={false}
              options={option(row)}
              padding='0 10px'
              hover
            />
          ),
      },
    ],
    []
  );

  return (
    <FlexContainer container padding='20px 15px' direction='column'>
      <FlexContainer
        container
        alignItems='center'
        justify='flex-end'
        margin='0 0 30px'
      >
        {user.permissions.includes('core.coverage.create') && (
          <Button
            color='primary'
            margin='0 0 0 20px'
            fontSize='13px'
            startIcon={<Icon icon='plus-icon' size={18} />}
            onClick={() => setOpenCreate(true)}
          >
            Crear Cobertura
          </Button>
        )}
      </FlexContainer>

      <FlexContainer container justify='center'>
        <FlexContainer
          width='70%'
          direction='column'
          alignItems='center'
          margin='0 40px 0 0'
        >
          {firstLoadingCoverages ? (
            <FlexContainer
              container
              padding='30px 40px'
              direction='column'
              justify='center'
              alignItems='center'
              height='60vh'
            >
              <Spinner height='350px' />
            </FlexContainer>
          ) : (
            <>
              {coveragesList.length === 0 ? (
                <FlexContainer
                  container
                  direction='column'
                  justify='center'
                  alignItems='center'
                  height='60vh'
                >
                  <img src={NoData} alt='no_data' />
                  <Typography color='text.secondary'>
                    No hay coberturas registradas
                  </Typography>
                </FlexContainer>
              ) : (
                <DataTable
                  headers={headers}
                  data={coveragesList}
                  onChangePage={selectedPage => setPage(selectedPage)}
                  totalPages={pageData.totalPages}
                  totalItems={pageData.totalItems}
                  pageSize={pageData.pageSize}
                  page={page}
                  loading={loadingCoverages}
                />
              )}
            </>
          )}
        </FlexContainer>
      </FlexContainer>

      <Dialog
        fullScreen
        maxWidth='lg'
        open={openCreate || openEdit}
        onClose={() => {
          setOpenCreate(false);
          setOpenEdit(false);
          setEditData(null);
        }}
      >
        <CreateCoverages
          data={editData}
          onCreate={useCallback(() => requestCoverages(), [requestCoverages])}
          onClose={() => {
            setOpenCreate(false);
            setOpenEdit(false);
            setEditData(null);
          }}
        />
      </Dialog>
    </FlexContainer>
  );
};

export default CoverageList;
