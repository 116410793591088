import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import {
  XAxis,
  YAxis,
  FlexibleWidthXYPlot,
  VerticalBarSeries,
  LineSeries,
  LabelSeries,
  DiscreteColorLegend,
} from 'react-vis';
import {
  getRouteTypes,
  getOrderTypes,
  getHistorical,
  getProviders,
} from 'services';
import { useTypedSelector } from 'store';
import {
  Button,
  FlexContainer,
  Card,
  Typography,
  Icon,
} from 'components/atoms';
import { Spinner } from 'components/molecules';
import { DataTable, FiltersContainer } from 'components/organisms';
import useRequest from 'hooks/useRequest';
import { optionFormatter } from 'util/functions';
import axios from 'util/axios';
import THEME from 'util/styledTheme';
import statusColors from 'util/statusColors';

const initialFilters = [
  {
    label: 'Fecha inicio',
    placeholder: 'Seleccionar fecha',
    name: 'start_date',
    type: 'date',
  },
  {
    label: 'Fecha final',
    placeholder: 'Seleccionar fecha',
    name: 'end_date',
    type: 'date',
  },
  {
    label: 'Provider',
    placeholder: 'Proveedor',
    name: 'provider_id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
    permission: 'core.provider.list',
  },
  {
    label: 'Tipo de ruta',
    placeholder: 'Tipo de ruta',
    name: 'route_type_id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
    permission: 'core.route_type.list',
  },
  {
    label: 'Tipo de orden',
    placeholder: 'Tipo de órden',
    name: 'order_type_id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
  },
];

const CustomButton = styled.div<{ active: boolean }>`
  padding: 5px 10px;
  background-color: ${props =>
    props.active ? props.theme.colors.backgroundColor : '#ffffff'};
  border-right: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 4px;
  color: ${props => props.theme.colors.textSecondary};
  font-size: 12px;
  cursor: pointer;
`;

const ChartHint = styled.div`
  position: absolute;
  background-color: ${props => props.theme.colors.textPrimary};
  padding: 5px 10px;
  border-radius: 4px;
  color: #ffffff;
`;

const ButtonGroup: React.FC<{
  active: string;
  onSelect: (group: string) => void;
}> = ({ active, onSelect }) => (
  <FlexContainer alignItems='center' borderColor={THEME.colors.borderColor}>
    <CustomButton active={active === 'hour'} onClick={() => onSelect('hour')}>
      Hora
    </CustomButton>
    <CustomButton active={active === 'week'} onClick={() => onSelect('week')}>
      Semana
    </CustomButton>
    <CustomButton active={active === 'month'} onClick={() => onSelect('month')}>
      Mes
    </CustomButton>
    <CustomButton active={active === 'year'} onClick={() => onSelect('year')}>
      Año
    </CustomButton>
  </FlexContainer>
);

const Historical: React.FC = () => {
  const selectedOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  const user = useTypedSelector(store => store.user);
  const [filters, setFilters] = useState(initialFilters);
  const [filterValues, setFilterValues] = useState({
    start_date: moment()
      .startOf('year')
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    route_type_id: null,
    order_type_id: null,
    provider_id: null,
    group_by: 'month',
  });
  // Bars data
  const [completedData, setCompletedData] = useState<any>([]);
  const [failedData, setFailedData] = useState<any>([]);
  const [expiredData, setExpiredData] = useState<any>([]);
  const [cancelledData, setCancelledData] = useState<any>([]);
  // Line data
  const [perfectData, setPerfectData] = useState<any>([]);
  const [deliveredData, setDeliveredData] = useState<any>([]);
  const [delayedData, setDelayedData] = useState<any>([]);
  // Table data
  const [formattedData, setFormattedData] = useState<Array<any>>([]);
  // Hints
  const [hints, setHints] = useState({
    type: '',
    value: null,
  });
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });
  // Visibility
  const [showBars, setShowBars] = useState({
    completed: true,
    failed: true,
    expired: true,
    cancelled: true,
    perfect: true,
    delivered: true,
    delayed: true,
  });
  // API calls
  const [providers, fetchProviders, loadingProviders] = useRequest(
    getProviders,
    []
  );
  const [routeTypes, fetchRouteTypes, loadingRouteTypes] = useRequest(
    getRouteTypes,
    []
  );
  const [orderTypes, fetchOrderTypes, loadingOrderTypes] = useRequest(
    getOrderTypes,
    []
  );
  const [historical, fetchHistorical, loadingHistorical] = useRequest(
    getHistorical
  );

  const requestData = (resetProvider?: boolean) => {
    const params = {
      start_date: filterValues.start_date,
      end_date: filterValues.end_date,
      order_type_id: filterValues.order_type_id,
      route_type_id: filterValues.route_type_id,
      branch_office_id: selectedOfficeId,
      group_by: filterValues.group_by,
      provider_id: resetProvider ? null : filterValues.provider_id,
    };
    fetchHistorical(params);
  };

  const downloadData = () => {
    const params = {
      start_date: filterValues.start_date,
      end_date: filterValues.end_date,
      order_type_id: filterValues.order_type_id,
      route_type_id: filterValues.route_type_id,
      branch_office_id: selectedOfficeId,
      group_by: filterValues.group_by,
      provider_id: filterValues.provider_id,
    };
    axios({
      url: '/analytics/report/orders_by_range',
      method: 'GET',
      params,
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'metrics.xlsx');
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleShowHint = e => {
    setPosition({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
    });
  };

  useEffect(() => {
    fetchOrderTypes({
      page_size: 0,
    });
    fetchRouteTypes({
      page_size: 0,
    });
  }, [fetchOrderTypes, fetchRouteTypes]);

  useEffect(() => {
    setFilterValues({
      start_date: moment()
        .startOf('year')
        .format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      route_type_id: null,
      order_type_id: null,
      provider_id: null,
      group_by: 'month',
    });
    fetchProviders({
      page_size: 0,
      branchoffices__id: selectedOfficeId,
    });
    requestData(true);
  }, [selectedOfficeId]);

  useEffect(() => {
    requestData();
  }, [filterValues.group_by]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[2].options = optionFormatter(providers, {
      id: item => item.id,
      name: item => item.business_name,
    });
    setFilters(updatedFilters);
  }, [providers]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[3].options = optionFormatter(routeTypes);
    setFilters(updatedFilters);
  }, [routeTypes]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[4].options = optionFormatter(orderTypes);
    setFilters(updatedFilters);
  }, [orderTypes]);

  useEffect(() => {
    if (!isEmpty(historical)) {
      const completed = [];
      const failed = [];
      const expired = [];
      const cancelled = [];
      const perfect = [];
      const delivered = [];
      const delayed = [];
      const tableData = [
        {
          label: 'Completado',
          color: statusColors.completed,
        },
        {
          label: 'Fallido',
          color: statusColors.failed,
        },
        {
          label: 'Expirado',
          color: statusColors.expired,
        },
        {
          label: 'Cancelado',
          color: statusColors.cancelled,
        },
      ];
      Object.keys(historical).forEach(key => {
        completed.push({
          x: key,
          y: historical[key].completed,
        });
        failed.push({
          x: key,
          y: historical[key].failed,
        });
        expired.push({
          x: key,
          y: historical[key].expired,
        });
        cancelled.push({
          x: key,
          y: historical[key].cancelled,
        });
        perfect.push({
          x: key,
          y: historical[key].perfect_percentage,
        });
        delivered.push({
          x: key,
          y: historical[key].delivered_orders_percentage,
        });
        delayed.push({
          x: key,
          y: historical[key].delayed_orders_percentage,
        });
        tableData[0][key] = historical[key].completed;
        tableData[1][key] = historical[key].failed;
        tableData[2][key] = historical[key].expired;
        tableData[3][key] = historical[key].cancelled;
      });
      setCompletedData(completed);
      setFailedData(failed);
      setExpiredData(expired);
      setCancelledData(cancelled);
      setPerfectData(perfect);
      setDeliveredData(delivered);
      setDelayedData(delayed);
      setFormattedData(tableData);
    }
  }, [historical]);

  useEffect(() => {
    requestData();
  }, [filterValues]);

  const headers = useMemo(
    () =>
      Object.keys({ aux: '', ...historical }).map((item, index) => ({
        label: item === 'aux' ? '' : item,
        id: item,
        cell: row =>
          index === 0 ? (
            <FlexContainer alignItems='center'>
              <FlexContainer
                height='10px'
                width='10px'
                margin='0 5px 0 0'
                backgroundColor={row.color}
              />
              {row.label}
            </FlexContainer>
          ) : (
            row[item]
          ),
      })),
    [historical]
  );

  return (
    <FlexContainer container padding='30px 40px' direction='column'>
      <FlexContainer container alignItems='flex-start' margin='0 0 20px'>
        <FiltersContainer
          labelWeight={500}
          filters={filters}
          value={filterValues}
          onChange={(value, name) =>
            setFilterValues({
              ...filterValues,
              [name]: value,
            })
          }
          disabled={loadingRouteTypes || loadingOrderTypes || loadingProviders}
          permissions={user.permissions}
          margin='0 10px 10px 0'
          wrap='wrap'
          showBranchOffice
        />
        <Button
          variant='contained'
          color='secondary'
          margin='20px 0 0'
          padding='0 10px'
          onClick={() => downloadData()}
        >
          <Icon icon='download-icon' size={20} />
        </Button>
      </FlexContainer>

      <Card shadow width='100%' padding='20px' margin='0 0 10px'>
        {loadingHistorical ? (
          <Spinner height='200px' />
        ) : (
          <>
            {!isEmpty(historical) ? (
              <>
                <FlexContainer container position='relative' direction='column'>
                  <FlexContainer
                    container
                    alignItems='center'
                    justify='space-between'
                    margin='0 0 10px'
                  >
                    <DiscreteColorLegend
                      items={[
                        {
                          title: 'Completado',
                          value: 'completed',
                          color: statusColors.completed,
                          strokeWidth: 5,
                          disabled: !showBars.completed,
                        },
                        {
                          title: 'Fallido',
                          value: 'failed',
                          color: statusColors.failed,
                          strokeWidth: 5,
                          disabled: !showBars.failed,
                        },
                        {
                          title: 'Expirado',
                          value: 'expired',
                          color: statusColors.expired,
                          strokeWidth: 5,
                          disabled: !showBars.expired,
                        },
                        {
                          title: 'Cancelado',
                          value: 'cancelled',
                          color: statusColors.cancelled,
                          strokeWidth: 5,
                          disabled: !showBars.cancelled,
                        },
                      ]}
                      orientation='horizontal'
                      onItemClick={item =>
                        setShowBars({
                          ...showBars,
                          [item.value]: !showBars[item.value],
                        })
                      }
                    />
                    <ButtonGroup
                      active={filterValues.group_by}
                      onSelect={group =>
                        setFilterValues({
                          ...filterValues,
                          group_by: group,
                        })
                      }
                    />
                  </FlexContainer>
                  <FlexibleWidthXYPlot
                    xType='ordinal'
                    height={500}
                    onMouseMove={handleShowHint}
                  >
                    <XAxis />
                    <YAxis />
                    {showBars.completed && (
                      <VerticalBarSeries
                        colorType='literal'
                        color={statusColors.completed}
                        data={completedData}
                        onValueMouseOver={v =>
                          setHints({
                            type: 'Completado',
                            value: v,
                          })
                        }
                        onSeriesMouseOut={() =>
                          setHints({
                            type: '',
                            value: null,
                          })
                        }
                      />
                    )}
                    {showBars.failed && (
                      <VerticalBarSeries
                        colorType='literal'
                        color={statusColors.failed}
                        data={failedData}
                        onValueMouseOver={v =>
                          setHints({
                            type: 'Fallido',
                            value: v,
                          })
                        }
                        onSeriesMouseOut={() =>
                          setHints({
                            type: '',
                            value: null,
                          })
                        }
                      />
                    )}
                    {showBars.expired && (
                      <VerticalBarSeries
                        colorType='literal'
                        color={statusColors.expired}
                        data={expiredData}
                        onValueMouseOver={v =>
                          setHints({
                            type: 'Expirado',
                            value: v,
                          })
                        }
                        onSeriesMouseOut={() =>
                          setHints({
                            type: '',
                            value: null,
                          })
                        }
                      />
                    )}
                    {showBars.cancelled && (
                      <VerticalBarSeries
                        colorType='literal'
                        color={statusColors.cancelled}
                        data={cancelledData}
                        onValueMouseOver={v =>
                          setHints({
                            type: 'Cancelado',
                            value: v,
                          })
                        }
                        onSeriesMouseOut={() =>
                          setHints({
                            type: '',
                            value: null,
                          })
                        }
                      />
                    )}
                    {hints.type !== '' && (
                      <ChartHint
                        style={{
                          left: position.x,
                          top: position.y,
                        }}
                      >
                        {`${hints.type}: ${hints.value.y}`}
                      </ChartHint>
                    )}
                  </FlexibleWidthXYPlot>
                </FlexContainer>

                <DataTable
                  data={formattedData}
                  dataIdentifier='label'
                  headers={headers}
                  onChangePage={() => {}}
                  totalItems={0}
                  totalPages={0}
                  pageSize={0}
                  page={1}
                  showPagination={false}
                  marginBottom='0'
                />
              </>
            ) : (
              <FlexContainer container justify='center'>
                <Typography>No existen datos para mostrar</Typography>
              </FlexContainer>
            )}
          </>
        )}
      </Card>

      {!isEmpty(historical) && !loadingHistorical && (
        <Card shadow width='100%' padding='20px'>
          <FlexContainer container position='relative' direction='column'>
            <DiscreteColorLegend
              items={[
                {
                  title: 'A tiempo',
                  value: 'perfect',
                  color: statusColors.searching,
                  strokeWidth: 5,
                  disabled: !showBars.perfect,
                },
                {
                  title: 'Entregadas',
                  value: 'delivered',
                  color: statusColors.accepted,
                  strokeWidth: 5,
                  disabled: !showBars.delivered,
                },
                {
                  title: 'Demoradas',
                  value: 'delayed',
                  color: statusColors.in_progress,
                  strokeWidth: 5,
                  disabled: !showBars.delayed,
                },
              ]}
              orientation='horizontal'
              onItemClick={item =>
                setShowBars({
                  ...showBars,
                  [item.value]: !showBars[item.value],
                })
              }
            />
            <FlexibleWidthXYPlot
              xType='ordinal'
              height={500}
              yDomain={[0, 100]}
            >
              <XAxis />
              <YAxis tickValues={[20, 40, 60, 80, 100]} />
              {showBars.perfect && (
                <LineSeries
                  colorType='literal'
                  color={statusColors.searching}
                  data={perfectData}
                />
              )}
              {showBars.delivered && (
                <LineSeries
                  colorType='literal'
                  color={statusColors.accepted}
                  data={deliveredData}
                />
              )}
              {showBars.delayed && (
                <LineSeries
                  colorType='literal'
                  color={statusColors.in_progress}
                  data={delayedData}
                />
              )}
              {showBars.perfect && (
                <LabelSeries
                  data={perfectData}
                  getLabel={d => `${d.y}%`}
                  style={{ stroke: THEME.colors.textPrimary }}
                />
              )}
              {showBars.delivered && (
                <LabelSeries
                  data={deliveredData}
                  getLabel={d => `${d.y}%`}
                  style={{ stroke: THEME.colors.textPrimary }}
                />
              )}
              {showBars.delayed && (
                <LabelSeries
                  data={delayedData}
                  getLabel={d => `${d.y}%`}
                  style={{ stroke: THEME.colors.textPrimary }}
                />
              )}
            </FlexibleWidthXYPlot>
          </FlexContainer>
        </Card>
      )}
    </FlexContainer>
  );
};

export default Historical;
