import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Dialog, IconButton } from '@material-ui/core';
import {
  getClients,
  getChannels,
  getDigitalCatalogue,
  patchDigitalCatalogue,
  getOrganization,
} from 'services';
import {
  Button,
  FlexContainer,
  Icon,
  Typography,
  Select,
} from 'components/atoms';
import { Spinner, FormControl } from 'components/molecules';
import { DataTable } from 'components/organisms';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import { getLocalStorageClientId } from 'util/functions';
import ShareIcon from 'assets/images/externalLink.svg';
import NoData from 'assets/images/no_data.svg';
import EditMethod from './Edit';

const StoreLink = styled.a`
  color: #5641f0;
  font-size: 13px;
  line-height: 24px;
  margin: 0 5px 0 0;
`;

const StatusCircle = styled.div<{ active }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${props =>
    props.active
      ? props.theme.colors.success
      : props.theme.colors.placeholderColor};
  box-shadow: rgb(0 0 0 / 8%) 2px 2px 3px 0px;
  margin: 5px;
`;

const DigitalCatalogue: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedOrganization = useTypedSelector(
    state => state.organization.selectedOrganization
  );

  // API calls
  const [organization, fetchOrganization, loadingOrganization] = useRequest(
    getOrganization
  );
  const [clients, fetchClients, isLoadingClients] = useRequest(getClients, []);
  const [channels, fetchChannels, loadingChannels] = useRequest(getChannels);
  const [digitalCatalogue, fetchDigitalCatalogue, ,] = useRequest(
    getDigitalCatalogue
  );
  const [patchedDigitalCatalogue, updateDigitalCatalogue, ,] = useRequest(
    patchDigitalCatalogue
  );

  // Values
  const [selectedClient, setSelectedClient] = useState(undefined);
  const [hasClients, setHasClients] = useState(false);
  const [catalogues, setCatalogues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editId, setEditId] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [salesChannelId, setSalesChannelId] = useState('');
  const [deliveryMethods, setDeliveryMethods] = useState([
    {
      id: 1,
      name: 'Inmediato',
      isActive: false,
      description: '',
    },
    {
      id: 2,
      name: 'Programado',
      isActive: false,
      description: '',
    },
  ]);

  const requestChannels = useCallback(() => {
    fetchOrganization(selectedOrganization.id);
  }, [fetchOrganization]);

  useEffect(() => {
    fetchChannels();
  }, [selectedClient]);

  useEffect(() => {
    fetchClients();
    fetchOrganization(selectedOrganization.id);
  }, []);

  useEffect(() => {
    if (!isEmpty(organization)) {
      setDeliveryMethods([
        {
          id: 1,
          name: 'Inmediato',
          isActive: organization.is_immediate_active,
          description: organization.immediate_description,
        },
        {
          id: 2,
          name: 'Programado',
          isActive: organization.is_programmed_active,
          description: organization.programmed_description,
        },
      ]);
    }
  }, [organization]);

  useEffect(() => {
    if (!clients || clients.length === 0) {
      setHasClients(false);
      setSelectedClient(getLocalStorageClientId());
      return;
    }

    setHasClients(true);
  }, [clients]);

  useEffect(() => {
    if (!isEmpty(channels)) {
      setIsLoading(true);
      if (selectedClient) {
        setCatalogues(
          channels.filter(
            channel =>
              channel.platform === 'digital_catalog' &&
              channel.client === selectedClient
          )
        );

        const channelSelected = channels.find(
          channel =>
            channel.platform === 'digital_catalog' &&
            channel.client === selectedClient
        );
        setSalesChannelId(channelSelected ? channelSelected.id : '');
      } else {
        setCatalogues(
          channels.filter(channel => channel.platform === 'digital_catalog')
        );
        setSalesChannelId(
          channels.find(channel => channel.platform === 'digital_catalog').id
        );
      }
    } else {
      setCatalogues([]);
    }
    setIsLoading(false);
  }, [channels]);

  useEffect(() => {
    if (salesChannelId !== '' || salesChannelId === undefined) {
      fetchDigitalCatalogue(salesChannelId);
    }
  }, [salesChannelId]);

  useEffect(() => {
    if (!isEmpty(digitalCatalogue)) {
      if (
        digitalCatalogue.payment_methods &&
        digitalCatalogue.payment_methods.length > 0
      ) {
        setPaymentMethods(
          Array.from(digitalCatalogue.payment_methods).map(
            (method: any) => method.name
          )
        );
      }
    }
  }, [digitalCatalogue]);

  useEffect(() => {
    if (!isEmpty(patchedDigitalCatalogue)) {
      enqueueSnackbar('Los métodos de pago se actualizaron correctamente', {
        variant: 'success',
      });
    }
  }, [patchedDigitalCatalogue, enqueueSnackbar]);

  const showCatalogue = () => {
    if (channels.length > 0 && !isLoading) {
      if (
        (clients.length === 0 || (hasClients && selectedClient)) &&
        catalogues.length > 0
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleDelete = (
    name: string,
    methods: Array<string>,
    methodName: string
  ) => {
    const newArray = methods.filter(item => item !== name);

    switch (methodName) {
      case 'payment':
        setPaymentMethods(newArray);
        break;
      default:
        break;
    }
  };

  const updatePaymentMethods = () => {
    updateDigitalCatalogue(salesChannelId, { payment_methods: paymentMethods });
  };

  const headersDelivery = useMemo(
    () => [
      {
        label: 'Nombre',
        id: 'name',
        cell: row => row.name,
      },
      {
        label: 'Descripción',
        id: 'description',
        cell: row => row.description,
      },
      {
        label: 'Activo',
        id: 'is_active',
        cell: row => <StatusCircle active={row.isActive} />,
      },
      {
        label: '',
        id: 'settings',
        cell: row => (
          <Icon
            icon='edit-icon'
            size={20}
            onClick={() => {
              setEditId(row.id);
              setOpenEdit(true);
            }}
          />
        ),
      },
    ],
    [deliveryMethods]
  );

  const headersPayment = useMemo(
    () => [
      {
        label: 'Nombre',
        id: 'description',
        cell: row => row,
      },
      {
        label: '',
        id: 'delete-button',
        cell: row => (
          <FlexContainer justify='flex-end'>
            <IconButton
              size='small'
              onClick={() => handleDelete(row, paymentMethods, 'payment')}
            >
              <Icon icon='cancel-icon' size={12} />
            </IconButton>
          </FlexContainer>
        ),
      },
    ],
    [paymentMethods]
  );

  return (
    <FlexContainer container padding='20px 40px' direction='column'>
      <FlexContainer
        container
        padding='40px 50px'
        direction='column'
        borderRadius='5px'
        backgroundColor='#FFFFFF'
        borderColor={THEME.colors.borderColor}
      >
        {isLoadingClients || loadingOrganization ? (
          <FlexContainer container justify='center' margin='0 15px 0 0'>
            <Spinner spinnerSize={18} />
          </FlexContainer>
        ) : (
          <FlexContainer container direction='column'>
            {!clients || clients.length === 0 ? null : (
              <FlexContainer alignItems='baseline'>
                <Typography>Seleccione un cliente:</Typography>
                <FlexContainer margin='0 10px 10px'>
                  <Select
                    placeholder='Seleccione un cliente'
                    value={selectedClient}
                    options={clients.filter(c => c.is_active)}
                    onChange={value => setSelectedClient(value)}
                    disabled={false}
                  />
                </FlexContainer>
              </FlexContainer>
            )}
            {isLoading || loadingChannels ? (
              <FlexContainer container justify='center' margin='0 15px 0 0'>
                <Spinner spinnerSize={18} />
              </FlexContainer>
            ) : (
              <FlexContainer container margin='20px 0 30px'>
                {showCatalogue() ? (
                  <>
                    {catalogues.map(catalogue => (
                      <FlexContainer container direction='column'>
                        <FlexContainer>
                          <Typography fontWeight={600} margin='0 8px 0 0'>
                            Nombre:
                          </Typography>
                          <Typography>{catalogue.name}</Typography>
                        </FlexContainer>
                        <FlexContainer alignItems='center'>
                          <StoreLink
                            href={`https://${catalogue.store}`}
                            target='_blank'
                          >
                            {catalogue.store}
                          </StoreLink>
                          <StoreLink
                            href={`https://${catalogue.store}`}
                            target='_blank'
                          >
                            <img
                              src={ShareIcon}
                              alt='share'
                              style={{ width: 12 }}
                            />
                          </StoreLink>
                        </FlexContainer>
                        <FlexContainer
                          container
                          direction='column'
                          margin='20px 0 10px'
                        >
                          <Typography
                            color={THEME.colors.primary}
                            fontWeight={500}
                            fontSize={17}
                          >
                            Métodos de entrega
                          </Typography>
                          <FlexContainer container>
                            <DataTable
                              headers={headersDelivery.filter(header => header)}
                              data={deliveryMethods}
                              onChangePage={() => {}}
                              totalPages={0}
                              totalItems={0}
                              pageSize={0}
                              page={1}
                              showPagination={false}
                            />
                          </FlexContainer>
                        </FlexContainer>
                        <FlexContainer
                          direction='column'
                          container
                          margin='0 0 20px'
                        >
                          <FlexContainer
                            container
                            justify='space-between'
                            alignItems='center'
                          >
                            <Typography
                              color={THEME.colors.primary}
                              fontWeight={500}
                              fontSize={17}
                            >
                              Métodos de pago
                            </Typography>
                          </FlexContainer>
                          <FlexContainer
                            direction='column'
                            container
                            margin='7px 0 0'
                          >
                            <FlexContainer container>
                              <FormControl
                                placeholder='Tarjeta de crédito o débito'
                                onChange={value => setPaymentMethod(value)}
                                value={paymentMethod}
                                margin='0 0 15px'
                                width='100%'
                                optional
                              />
                              <Button
                                variant='outlined'
                                color='primary'
                                margin='0 0 0 5px'
                                onClick={() => {
                                  if (paymentMethods.length >= 5) {
                                    enqueueSnackbar('Máximo 5 métodos', {
                                      variant: 'error',
                                    });
                                  } else {
                                    setPaymentMethods([
                                      ...paymentMethods,
                                      paymentMethod,
                                    ]);
                                    setPaymentMethod('');
                                  }
                                }}
                              >
                                Añadir
                              </Button>
                            </FlexContainer>
                            {paymentMethods.length > 0 && (
                              <FlexContainer container margin='0'>
                                <DataTable
                                  headers={headersPayment.filter(
                                    header => header
                                  )}
                                  data={paymentMethods}
                                  onChangePage={() => {}}
                                  totalPages={0}
                                  totalItems={0}
                                  pageSize={0}
                                  page={1}
                                  showPagination={false}
                                />
                              </FlexContainer>
                            )}
                          </FlexContainer>
                          <FlexContainer container justify='flex-end'>
                            <Button
                              variant='contained'
                              color='primary'
                              margin='0 0 0 5px'
                              onClick={() => updatePaymentMethods()}
                            >
                              Actualizar métodos de pago
                            </Button>
                          </FlexContainer>
                        </FlexContainer>
                      </FlexContainer>
                    ))}
                  </>
                ) : (
                  <FlexContainer
                    container
                    direction='column'
                    alignItems='center'
                  >
                    <img src={NoData} alt='no_data' style={{ width: 130 }} />
                    <Typography color='#9B9B9B' fontSize={15}>
                      No tiene catálogo digital
                    </Typography>
                  </FlexContainer>
                )}
              </FlexContainer>
            )}
          </FlexContainer>
        )}
      </FlexContainer>
      <Dialog
        fullWidth
        open={openEdit}
        maxWidth='sm'
        onClose={() => setOpenEdit(false)}
      >
        <EditMethod
          method={deliveryMethods.find(method => method.id === editId)}
          onUpdate={useCallback(() => requestChannels(), [requestChannels])}
          onClose={() => setOpenEdit(false)}
          organizationId={organization.id}
        />
      </Dialog>
    </FlexContainer>
  );
};

export default DigitalCatalogue;
