import React, { useState } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { FlexContainer } from 'components/atoms';
import AimoLogoWhite from 'assets/images/AimoLogoWhite.svg';
import AimoTextWhite from 'assets/images/AimoTextWhite.svg';
import ThirdStep from './ThirdStep';
import SecondStep from './SecondStep';
import FirstStep from './FirstStep';

const FullGrid = styled(Grid)`
  // height: 100vh;
  background-color: #ffffff;
  @media only screen and (min-width: 768px) {
    height: 91vh;
  }
`;

const HeaderGrid = styled(Grid)`
  height: 72px;
  background-color: #5641f0;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 20px;
  @media only screen and (min-width: 768px) {
    margin: 0 0 0 70px;
  }
`;

const ProgressContainer = styled.div<{ display: boolean }>`
  height: 8px;
  width: 100%;
  background-color: #dbd9f5;
  display: ${props => (props.display ? 'block' : 'none')};
`;

const ProgressBar = styled(Grid)<{ progress: number }>`
  height: 100%;
  width: ${props => props.progress}%;
  background-color: #f1564f;
  border-radius: 0 12px 12px 0;
  z-index: 1;
  margin: 0;
`;

const Header = ({ displayProgressBar, progress }) => {
  return (
    <Grid container direction='column'>
      <HeaderGrid container>
        <LogoContainer>
          <img src={AimoLogoWhite} alt='logo_full' />
        </LogoContainer>
        <img src={AimoTextWhite} alt='logo_full' />
      </HeaderGrid>
      <ProgressContainer display={displayProgressBar}>
        <ProgressBar progress={progress} />
      </ProgressContainer>
    </Grid>
  );
};

const Onboarding: React.FC = () => {
  // Values
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(1);
  const [displayProgressBar, setDisplayProgressBar] = useState(false);

  const showStep = (current: number) => {
    switch (current) {
      case 1:
        return <FirstStep changeStep={setStep} />;
      case 2:
        return (
          <SecondStep
            changeStep={setStep}
            setProgress={setProgress}
            setDisplayProgressBar={setDisplayProgressBar}
          />
        );
      case 3:
        return <ThirdStep changeStep={setStep} setProgress={setProgress} />;
      case 4:
        return <Redirect to='/inventario' />;
      default:
        return null;
    }
  };

  return (
    <FullGrid>
      <Header displayProgressBar={displayProgressBar} progress={progress} />
      <FlexContainer container height='100%'>
        {showStep(step)}
      </FlexContainer>
    </FullGrid>
  );
};

export default Onboarding;
