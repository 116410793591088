import React from 'react';
import { Link } from 'react-router-dom';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { useTypedSelector } from 'store';
import { SuccessProps } from './types';

const Success: React.FC<SuccessProps> = ({ onNewMassive }) => {
  const user = useTypedSelector(state => state.user);
  return (
    <FlexContainer container height='100%' alignItems='center' justify='center'>
      <FlexContainer
        container
        maxWidth='400px'
        direction='column'
        alignItems='center'
        margin='10% 0 0 0'
      >
        <Icon icon='check-icon' size='50px' />
        <Typography fontSize={18} margin='30px 0 40px'>
          Se realizó con éxito la carga de inventario.
        </Typography>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Success;
