import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#7065F0',
    },
    secondary: {
      main: '#F9F9FC',
      dark: '#EFEFF5',
    },
    error: {
      main: '#F1564F',
    },
    success: {
      main: '#79E29D',
    },
    text: {
      primary: '#404071',
      secondary: '#8C8CB1',
    },
    action: {
      hoverOpacity: 0.1,
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: '#8C8CB1',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
        letterSpacing: 'normal',
        lineHeight: 'normal',
        minWidth: '10px',
      },
      containedSecondary: {
        color: '#8C8CB1',
        border: '1px solid #DBD9F5',
      },
    },
    MuiLinearProgress: {
      root: {
        width: '100%',
      },
    },
    MuiCollapse: {
      container: {
        width: '100%',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        '&$disabled': {
          color: '#C8C8D3',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          'overflow-x': 'auto',
        },
        'body::-webkit-scrollbar-thumb': {
          backgroundColor: '#BEBED3',
        },
        '*::-webkit-scrollbar': {
          width: '0.5em',
          height: '0.5em',
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
        },
      },
    },
  },
});

export default theme;
