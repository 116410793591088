import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Drawer } from '@material-ui/core';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { Cell, Spinner } from 'components/molecules';
import { SearchWithFilter, DataTable } from 'components/organisms';
import { getVehicleTypes } from 'services';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import NoData from 'assets/images/no_data.svg';
import EditType from './Edit';

const options = [
  {
    name: 'Nombres',
    id: 'name',
  },
  {
    name: 'Etiquetas',
    id: 'city',
  },
];

const VehicleType: React.FC = () => {
  const user = useTypedSelector(state => state.user);
  // API calls
  const [vehicleTypes, fetchVehicleTypes, loadingTypes, pageData] = useRequest(
    getVehicleTypes,
    []
  );

  // Values
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [editData, setEditData] = useState(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const [firstLoadingVehiclesTypes, setFirstLoadingVehiclesTypes] = useState<
    boolean
  >(false);
  const [firstVehiclesTypesRender, setFirstVehiclesTypesRender] = useState<
    boolean
  >(false);

  const requestTypes = useCallback(() => {
    const params = {
      page,
    };

    if (searchFilter !== '') {
      params[searchFilter] = searchInput;
    }

    fetchVehicleTypes(params);
    setFirstVehiclesTypesRender(false);
  }, [fetchVehicleTypes, page, searchInput, searchFilter]);

  useEffect(() => {
    requestTypes();
    setFirstVehiclesTypesRender(true);
  }, [page]);

  useEffect(() => {
    if (firstVehiclesTypesRender && loadingTypes && page === 1) {
      setFirstLoadingVehiclesTypes(true);
    } else {
      setFirstLoadingVehiclesTypes(false);
    }
  }, [firstVehiclesTypesRender, loadingTypes]);

  const headers = useMemo(
    () => [
      {
        label: (
          <Typography margin='0 0 0 20px' color='#6d7996'>
            Nombre
          </Typography>
        ),
        id: 'name',
        cell: row => (
          <FlexContainer margin='0 0 0 20px'>
            <Cell.Image
              src={row.image}
              size='25'
              onClick={() => {
                if (user.permissions.includes('core.vehicle_type.update')) {
                  setEditData(row);
                  setOpenEdit(true);
                }
              }}
            >
              {row.name}
            </Cell.Image>
          </FlexContainer>
        ),
      },
      {
        label: 'Descripción',
        id: 'description',
        cell: row => row.description,
      },
      {
        label: 'Medidas',
        id: 'measurement',
        cell: row => `${row.width} x ${row.height} x ${row.depth}`,
      },
      {
        label: 'Peso',
        id: 'weight',
        cell: row => row.weight,
      },
      {
        label: 'Km. máx.',
        id: 'km_max',
        cell: row => row.max_km_traveled,
      },
      {
        label: 'Destinos máx.',
        id: 'destination_max',
        cell: row => row.max_destinations_traveled,
      },
      {
        label: 'Tipos de Paquete',
        id: 'package',
        cell: row => (
          <FlexContainer wrap='nowrap' alignItems='center'>
            {row.package_types.map(type => (
              <Cell.Pill
                key={type.code}
                backgroundColor={THEME.colors.borderColor}
                textColor={THEME.colors.textSecondary}
              >
                {type.name}
              </Cell.Pill>
            ))}
          </FlexContainer>
        ),
      },
      {
        label: 'Etiquetas',
        id: 'tag',
        cell: row => (
          <FlexContainer wrap='nowrap' alignItems='center'>
            {row.tags.map(tag => (
              <Cell.Pill
                key={tag.id}
                backgroundColor={THEME.colors.borderColor}
                textColor={THEME.colors.textSecondary}
              >
                {tag.name}
              </Cell.Pill>
            ))}
          </FlexContainer>
        ),
      },
    ],
    []
  );

  return (
    <FlexContainer container padding='30px 40px' direction='column'>
      <FlexContainer
        container
        alignItems='center'
        justify='space-between'
        margin='0 0 30px'
      >
        <Typography fontSize={18} fontWeight={300}>
          Tipos de Vehiculos
        </Typography>

        {user.permissions.includes('core.vehicle_type.create') && (
          <Button
            color='primary'
            margin='0 0 0 20px'
            fontSize='13px'
            startIcon={<Icon icon='plus-icon' size={18} />}
            onClick={() => setOpenEdit(true)}
          >
            Crear Tipo
          </Button>
        )}
      </FlexContainer>
      {firstLoadingVehiclesTypes ? (
        <FlexContainer
          container
          padding='30px 40px'
          direction='column'
          justify='center'
          alignItems='center'
          height='60vh'
        >
          <Spinner height='350px' />
        </FlexContainer>
      ) : (
        <>
          {firstVehiclesTypesRender && vehicleTypes.length === 0 ? (
            <FlexContainer
              container
              direction='column'
              justify='center'
              alignItems='center'
              height='60vh'
            >
              <img src={NoData} alt='no_data' />
              <Typography color='text.secondary'>
                No hay tipo de vehículos registrados
              </Typography>
            </FlexContainer>
          ) : (
            <>
              <FlexContainer
                container
                direction='column'
                margin='0 0 20px'
                padding='20px'
                backgroundColor='#FFFFFF'
                borderRadius='4px'
                borderColor={THEME.colors.borderColor}
              >
                <FlexContainer container>
                  <SearchWithFilter
                    filterLabel='Buscar por'
                    filterValue={searchFilter}
                    onChangeFilterValue={value => setSearchFilter(value)}
                    options={options}
                    onChangeSearchValue={value => setSearchInput(value)}
                    searchValue={searchInput}
                    onSubmit={() => requestTypes()}
                    placeholder='Buscar'
                  />
                  <Button
                    variant='contained'
                    color='primary'
                    margin='0 20px'
                    onClick={() => requestTypes()}
                  >
                    Buscar
                  </Button>
                </FlexContainer>
              </FlexContainer>

              <DataTable
                headers={headers}
                data={vehicleTypes}
                onChangePage={selectedPage => setPage(selectedPage)}
                totalPages={pageData.totalPages}
                totalItems={pageData.totalItems}
                pageSize={pageData.pageSize}
                page={page}
                loading={loadingTypes}
              />
            </>
          )}
        </>
      )}
      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => {
          setEditData(null);
          setOpenEdit(false);
        }}
      >
        <EditType
          data={editData}
          onCreate={useCallback(() => requestTypes(), [requestTypes])}
          onClose={() => {
            setEditData(null);
            setOpenEdit(false);
          }}
        />
      </Drawer>
    </FlexContainer>
  );
};

export default VehicleType;
