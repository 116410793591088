import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { FormControl } from 'components/molecules';
import { postTokens } from 'services';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import { TokensCreateProps, TokenContainerProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const validationState = {
  description: ['mandatory'],
};

const TokenContainer: React.FC<TokenContainerProps> = ({ apiResponse }) => {
  return (
    <FlexContainer container direction='column' alignItems='center'>
      <FlexContainer width='80%'>
        <Typography fontWeight={700} fontSize='15px' textAlign='center'>
          Este es el token, que solo se mostrará 1 vez, asegurate de copiarlo y
          tenerlo guardado
        </Typography>
      </FlexContainer>
      <FlexContainer
        borderColor={THEME.colors.primary}
        margin='15px 0'
        padding='5px'
      >
        <Typography>{apiResponse.access_token}</Typography>
      </FlexContainer>
    </FlexContainer>
  );
};

const TokensEdit: React.FC<TokensCreateProps> = ({ onClose, onCreate }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors, reset] = useForm(
    {
      description: '',
    },
    validationState
  );

  // API calls
  const [createdToken, createToken, loadingCreate] = useRequest(postTokens);

  // Values
  const [showRealToken, setShowRealToken] = useState(false);
  const [tokenValue, setTokenValue] = useState('');

  useEffect(() => {
    if (!isEmpty(createdToken)) {
      enqueueSnackbar('El token fue creado correctamente', {
        variant: 'success',
      });
      onCreate();
      reset();
      setShowRealToken(true);
      setTokenValue(createdToken);
    }
  }, [createdToken, enqueueSnackbar, onCreate, reset]);

  const validateForm = () => {
    if (validate()) {
      const body = {
        description: form.description,
      };

      createToken(body);
    }
  };

  return (
    <FlexContainer container direction='column' padding='20px 40px'>
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <FlexContainer container justify='center' margin='20px 0 30px'>
        <Typography color={THEME.colors.primary} fontSize={18}>
          Crear Token de Acceso
        </Typography>
      </FlexContainer>

      {showRealToken ? (
        <TokenContainer apiResponse={tokenValue} />
      ) : (
        <FlexContainer container direction='column'>
          <FormControl
            control='textarea'
            label='Descripción'
            placeholder='Escribe la decripción'
            onChange={value => onFormChange(value, 'description')}
            value={form.description}
            margin='0 0 20px'
            error={errors.description}
            width='100%'
            disabled={loadingCreate}
            autofocus
          />
        </FlexContainer>
      )}

      <FlexContainer container justify='center' margin='30px 0 20px'>
        <Button
          variant='contained'
          color='primary'
          padding='5px 30px'
          onClick={showRealToken ? () => onClose() : () => validateForm()}
          disabled={loadingCreate}
        >
          {showRealToken ? 'Cerrar' : 'Crear'}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default TokensEdit;
