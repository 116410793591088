import TOGGLE_SIDEBAR, { SidebarActionTypes } from './types';

const initialState: boolean = false;

export function sidebar(
  state = initialState,
  action: SidebarActionTypes
): boolean {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return action.payload;
    default:
      return state;
  }
}

export default sidebar;
