import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Drawer, Dialog } from '@material-ui/core';
import {
  Button,
  FlexContainer,
  Icon,
  Typography,
  // Switch,
} from 'components/atoms';
import { Cell } from 'components/molecules';
import { SearchWithFilter, DataTable } from 'components/organisms';
import { getTrackings } from 'services';
import THEME from 'util/styledTheme';
import useRequest from 'hooks/useRequest';
import EditTracking from './Edit';
import CreateTracking from './Create';

// const StatusCircle = styled.div<{ active }>`
//   width: 15px;
//   height: 15px;
//   border-radius: 50%;
//   background-color: ${props =>
//     props.active
//       ? props.theme.colors.success
//       : props.theme.colors.placeholderColor};
//   box-shadow: rgb(0 0 0 / 8%) 2px 2px 3px 0px;
//   margin: 5px;
// `;

const options = [
  {
    name: 'Nombres',
    id: 'full_name',
  },
  {
    name: 'Doc. de Identidad',
    id: 'identity_document',
  },
];

const Tracking = () => {
  // API Calls
  const [trackings, fetchTrackings, loadingTrackings, pageData] = useRequest(
    getTrackings,
    []
  );

  // Values
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [editData, setEditData] = useState(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);

  const requestTrackings = useCallback(() => {
    const params = {
      page,
    };

    if (searchFilter !== '') {
      params[searchFilter] = searchInput;
    }

    fetchTrackings(params);
  }, [fetchTrackings, page, searchInput, searchFilter]);

  useEffect(() => {
    requestTrackings();
  }, [page]);

  const headers = useMemo(
    () => [
      {
        label: 'Nombre',
        id: 'full_name',
        cell: row => (
          <Cell.Link
            onClick={() => {
              setEditData(row);
              setOpenEdit(true);
            }}
          >
            {row.name || '-'}
          </Cell.Link>
        ),
      },
      {
        label: 'Descripción',
        id: 'description',
        cell: row => row.description || '-',
      },
      {
        label: 'Alias Worker',
        id: 'worker_label',
        cell: row => row.worker_label || '-',
      },
      {
        label: 'Notificaciones',
        id: 'notifications',
        cell: row => (
          <FlexContainer>
            {`${row.send_email ? 'email, ' : ''} ${row.send_sms ? 'sms, ' : ''} 
              ${row.send_whatsapp ? 'whatsapp' : ''}`}
          </FlexContainer>
        ),
      },
      {
        label: 'Estado',
        id: 'is_active',
        cell: row => (
          <FlexContainer>
            <FlexContainer
              backgroundColor={row.is_active ? '#D1FAE5' : '#ececec'}
              justify='center'
              borderRadius='10px'
              padding='2px 10px'
            >
              <Typography color={row.is_active ? '#065f46' : '#6e6e7b'}>
                {row.is_active ? 'Activo' : 'Inactivo'}
              </Typography>
            </FlexContainer>
          </FlexContainer>
        ),
      },
    ],
    [editData]
  );

  return (
    <FlexContainer container padding='30px 40px' direction='column'>
      <FlexContainer
        container
        alignItems='center'
        justify='space-between'
        margin='0 0 30px'
      >
        <Typography fontSize={18} fontWeight={300}>
          Tracking Público
        </Typography>

        <FlexContainer>
          <Button
            color='primary'
            margin='0 0 0 20px'
            fontSize='13px'
            startIcon={<Icon icon='plus-icon' size={18} />}
            onClick={() => setOpenCreate(true)}
          >
            Crear Tracking
          </Button>
          <Button
            variant='outlined'
            color='primary'
            margin='0 0 0 20px'
            fontSize='13px'
            startIcon={<Icon icon='external-link-icon' size={13} />}
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_TRACKING_URL}/EXAMPLE1140=`,
                '_blank'
              )
            }
          >
            Ver ejemplo de Tracking
          </Button>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        container
        direction='column'
        margin='0 0 20px'
        padding='20px'
        backgroundColor='#FFFFFF'
        borderRadius='4px'
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer container>
          <SearchWithFilter
            filterLabel='Buscar por'
            filterValue={searchFilter}
            onChangeFilterValue={value => setSearchFilter(value)}
            options={options}
            onChangeSearchValue={value => setSearchInput(value)}
            searchValue={searchInput}
            onSubmit={() => requestTrackings()}
            placeholder='Buscar'
          />
          <Button
            variant='contained'
            color='primary'
            margin='0 20px'
            onClick={() => requestTrackings()}
          >
            Buscar
          </Button>
        </FlexContainer>
      </FlexContainer>

      <DataTable
        headers={headers}
        data={trackings}
        onChangePage={selectedPage => setPage(selectedPage)}
        totalPages={pageData.totalPages}
        totalItems={pageData.totalItems}
        pageSize={pageData.pageSize}
        page={page}
        loading={loadingTrackings}
      />

      <Dialog
        fullWidth
        open={openCreate}
        maxWidth='sm'
        onClose={() => setOpenCreate(false)}
      >
        <CreateTracking
          onCreate={useCallback(() => requestTrackings(), [requestTrackings])}
          onClose={() => setOpenCreate(false)}
        />
      </Dialog>

      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => {
          setEditData(null);
          setOpenEdit(false);
        }}
      >
        <EditTracking
          data={editData}
          onCreate={useCallback(() => requestTrackings(), [requestTrackings])}
          onClose={() => {
            setEditData(null);
            setOpenEdit(false);
          }}
        />
      </Drawer>
    </FlexContainer>
  );
};

export default Tracking;
