import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { FlexContainer, Typography } from 'components/atoms';
import { useTypedSelector } from 'store';
import THEME from 'util/styledTheme';

const Payments: React.FC = () => {
  const [doSubmit, setDoSubmit] = useState(false);

  // Values

  useEffect(() => {
    // @ts-ignore
    window.Mercadopago.setPublishableKey(
      'TEST-e2711b19-ccde-4684-b7dd-d995fc69cb0c'
    );
    // @ts-ignore
    window.Mercadopago.getIdentificationTypes();
  }, []);

  const preventEvent = e => {
    e.preventDefault();
    return false;
  };

  const guessPaymentMethod = e => {
    console.log('console');
    const cardnumber = e.target.value;
    console.log(cardnumber);
    if (cardnumber.length >= 6) {
      const bin = cardnumber.substring(0, 6);
      // @ts-ignore
      window.Mercadopago.getPaymentMethod(
        {
          bin,
        },
        setPaymentMethod
      );
    }
  };

  const setPaymentMethod = (status, response) => {
    if (status == 200) {
      const paymentMethod = response[0];
      // @ts-ignore
      document.getElementById('paymentMethodId').value = paymentMethod.id;

      getIssuers(paymentMethod.id);
    } else {
      alert(`payment method info error: ${response}`);
    }
  };

  const getIssuers = paymentMethodId => {
    // @ts-ignore
    window.Mercadopago.getIssuers(paymentMethodId, setIssuers);
  };

  const setIssuers = (status, response) => {
    if (status == 200) {
      const issuerSelect = document.getElementById('issuer');
      response.forEach(issuer => {
        const opt = document.createElement('option');
        opt.text = issuer.name;
        opt.value = issuer.id;
        issuerSelect.appendChild(opt);
      });

      getInstallments(
        // @ts-ignore
        document.getElementById('paymentMethodId').value,
        // @ts-ignore
        document.getElementById('transactionAmount').value,
        // @ts-ignore
        issuerSelect.value
      );
    } else {
      alert(`issuers method info error: ${response}`);
    }
  };

  const getInstallments = (paymentMethodId, transactionAmount, issuerId) => {
    // @ts-ignore
    window.Mercadopago.getInstallments(
      {
        payment_method_id: paymentMethodId,
        amount: parseFloat(transactionAmount),
        issuer_id: parseInt(issuerId),
      },
      setInstallments
    );
  };

  const setInstallments = (status, response) => {
    if (status == 200) {
      // @ts-ignore
      document.getElementById('installments').options.length = 0;
      response[0].payer_costs.forEach(payerCost => {
        const opt = document.createElement('option');
        opt.text = payerCost.recommended_message;
        opt.value = payerCost.installments;
        document.getElementById('installments').appendChild(opt);
      });
    } else {
      alert(`installments method info error: ${response}`);
    }
  };

  const getCardToken = event => {
    event.preventDefault();
    if (!doSubmit) {
      const $form = document.getElementById('paymentForm');
      // @ts-ignore
      window.Mercadopago.createToken($form, setCardTokenAndPay);
      return false;
    }
  };

  function setCardTokenAndPay(status, response) {
    if (status == 200 || status == 201) {
      const form = document.getElementById('paymentForm');
      const card = document.createElement('input');
      card.setAttribute('name', 'token');
      card.setAttribute('type', 'hidden');
      card.setAttribute('value', response.id);
      form.appendChild(card);
      console.log(response);
      // doSubmit=true;
      // form.submit();
    } else {
      alert(`Verify filled data!\n${JSON.stringify(response, null, 4)}`);
    }
  }

  return (
    <FlexContainer container padding='30px 40px' direction='column'>
      <Typography fontSize={18} fontWeight={300} margin='0 0 30px'>
        Pagos
      </Typography>
      <FlexContainer
        container
        padding='40px 50px'
        direction='column'
        borderRadius='5px'
        backgroundColor='#FFFFFF'
        borderColor={THEME.colors.borderColor}
      >
        <form
          action='/process_payment'
          method='post'
          id='paymentForm'
          onSubmit={getCardToken}
        >
          <h3>Detalles del comprador</h3>
          <div>
            <div>
              <label htmlFor='email'>E-mail</label>
              <input id='email' name='email' type='text' />
            </div>
            <div>
              <label htmlFor='docType'>Tipo de documento</label>
              <select id='docType' name='docType' data-checkout='docType' />
            </div>
            <div>
              <label htmlFor='docNumber'>Número de documento</label>
              <input
                id='docNumber'
                name='docNumber'
                data-checkout='docNumber'
                type='text'
              />
            </div>
          </div>
          <h3>Detalles de la tarjeta</h3>
          <div>
            <div>
              <label htmlFor='cardholderName'>Titular de la tarjeta</label>
              <input
                id='cardholderName'
                data-checkout='cardholderName'
                type='text'
              />
            </div>
            <div>
              <label htmlFor=''>Fecha de vencimiento</label>
              <div>
                <input
                  type='text'
                  placeholder='MM'
                  id='cardExpirationMonth'
                  data-checkout='cardExpirationMonth'
                  // onselectstart={preventEvent}
                  onPaste={preventEvent}
                  onCopy={preventEvent}
                  onCut={preventEvent}
                  onDrag={preventEvent}
                  onDrop={preventEvent}
                  autoComplete='off'
                />
                <span className='date-separator'>/</span>
                <input
                  type='text'
                  placeholder='YY'
                  id='cardExpirationYear'
                  data-checkout='cardExpirationYear'
                  // onselectstart={preventEvent}
                  onPaste={preventEvent}
                  onCopy={preventEvent}
                  onCut={preventEvent}
                  onDrag={preventEvent}
                  onDrop={preventEvent}
                  autoComplete='off'
                />
              </div>
            </div>
            <div>
              <label htmlFor='cardNumber'>Número de la tarjeta</label>
              <input
                type='text'
                id='cardNumber'
                data-checkout='cardNumber'
                // onselectstart={preventEvent}
                onChange={e => guessPaymentMethod(e)}
                onPaste={preventEvent}
                onCopy={preventEvent}
                onCut={preventEvent}
                onDrag={preventEvent}
                onDrop={preventEvent}
                autoComplete='off'
              />
            </div>
            <div>
              <label htmlFor='securityCode'>Código de seguridad</label>
              <input
                id='securityCode'
                data-checkout='securityCode'
                type='text'
                // onselectstart={preventEvent}
                onPaste={preventEvent}
                onCopy={preventEvent}
                onCut={preventEvent}
                onDrag={preventEvent}
                onDrop={preventEvent}
                autoComplete='off'
              />
            </div>
            <div id='issuerInput'>
              <label htmlFor='issuer'>Banco emisor</label>
              <select id='issuer' name='issuer' data-checkout='issuer' />
            </div>
            <div>
              <label htmlFor='installments'>Cuotas</label>
              <select id='installments' name='installments' />
            </div>
            <div>
              <input
                type='hidden'
                name='transactionAmount'
                id='transactionAmount'
                value='100'
              />
              <input
                type='hidden'
                name='paymentMethodId'
                id='paymentMethodId'
              />
              <input type='hidden' name='description' id='description' />
              <br />
              <button type='submit'>Pagar</button>
              <br />
            </div>
          </div>
        </form>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Payments;
