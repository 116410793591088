import React from 'react';
import THEME from 'util/styledTheme';
import { FlexContainer, Typography, Feedback, Button } from 'components/atoms';
import { FormControl } from 'components/molecules';

const FeedbackTemplate: React.FC = () => {
  return (
    <FlexContainer
      direction='column'
      width='340px'
      padding='40px 20px'
      alignItems='center'
      justify='center'
      borderColor={THEME.colors.borderColor}
      borderRadius='4px'
    >
      <FlexContainer container height='80px' direction='column'>
        <Typography fontSize='16px' margin='5px 0' fontWeight='700'>
          Satisfacción de entrega
        </Typography>
        <FlexContainer width='80%' justify='space-between' margin='10px'>
          <Feedback feedbackText='bad' />
          <Feedback feedbackText='normal' />
          <Feedback feedbackText='good' />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        container
        direction='column'
        height='80px'
        margin='30px 0 0'
      >
        <Typography fontSize='16px' margin='20px 0 0 0' fontWeight='700'>
          Satisfacción del producto
        </Typography>
        <FlexContainer width='80%' justify='space-between' margin='10px'>
          <Feedback feedbackText='bad' />
          <Feedback feedbackText='normal' />
          <Feedback feedbackText='good' />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer container direction='column' margin='30px 0 0'>
        <Typography fontSize='16px' margin='30px 0 0 0' fontWeight='700'>
          Comentarios
        </Typography>
        <FormControl
          control='textarea'
          placeholder='Escribe la decripción'
          onChange={() => {}}
          value='Excelente entrega pero me llego otra talla.'
          margin='20px 0'
          width='100%'
          rows={4}
        />

        <FlexContainer container justify='center' margin='30px 0'>
          <Button
            variant='contained'
            color='primary'
            borderRadius='4px'
            padding='20px 40px'
            onClick={() => {}}
          >
            <Typography fontSize='16px' fontWeight='700' color='white'>
              Enviar Feedback
            </Typography>
          </Button>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default FeedbackTemplate;
