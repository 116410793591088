import React, { useState, useEffect, useMemo } from 'react';
import { find } from 'lodash';
import {
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { FlexContainer, Icon, Typography } from 'components/atoms';
import { FormControl } from 'components/molecules';
import { DataTable } from 'components/organisms';
import useWindowSize from 'hooks/useWindowSize';
import { optionFormatter } from 'util/functions';
import THEME from 'util/styledTheme';
import { ParameterProps } from './types';

const Parameters: React.FC<ParameterProps> = ({
  routeTypes,
  measurementUnits,
  vehicles,
  values,
  onChange,
  selectedVehicles,
  onSelectVehicle,
  showLoader,
}) => {
  const size = useWindowSize();
  const [selectMode, setSelectMode] = useState<string>('');
  const [measureOptions, setMeasureOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  useEffect(() => {
    if (measurementUnits) {
      setMeasureOptions(
        optionFormatter(measurementUnits, {
          id: item => item.code,
          name: item => item.label,
        })
      );
    }
  }, [measurementUnits]);

  useEffect(() => {
    if (routeTypes.length > 0) {
      setTypeOptions(optionFormatter(routeTypes));
    }
  }, [routeTypes]);

  useEffect(() => {
    if (values.vehicle_type === 'vehicle_type') {
      setSelectMode('table');
    } else if (values.vehicle_type === 'plate') {
      setSelectMode('search');
    }
  }, [values.vehicle_type]);

  const headers = useMemo(
    () => [
      {
        label: 'Tipo de Vehículo',
        id: 'vehicle_type',
        cell: row => row.name,
      },
      {
        label: 'Dimensiones',
        id: 'dimensions',
        cell: row => `${row.width} x ${row.height} x ${row.depth} cm`,
      },
      {
        label: 'Detalles',
        id: 'weight',
        cell: row =>
          `${row.weight} Kg - ${row.max_km_traveled} Km - ${row.max_destinations_traveled} puntos`,
      },
      {
        label:
          values.measurement_unit !== '' ? (
            <FlexContainer alignItems='center' justify='flex-start'>
              Unid. de medida
              <Icon icon='check-icon' size={18} margin='0 0 0 5px' />
            </FlexContainer>
          ) : (
            'Unid. de medida'
          ),
        id: 'measurement',
        cell: row => {
          if (values.measurement_unit !== '') {
            const packageType = find(row.package_types, {
              code: values.measurement_unit,
            });

            return packageType
              ? `${packageType.quantity} ${packageType.name}`
              : '-';
          }

          return '-';
        },
      },
      {
        label: 'Costo',
        id: 'cost',
        cell: row => `S/. ${row.estimated_cost}`,
      },
    ],
    [values]
  );

  return (
    <FlexContainer padding='28px' container direction='column'>
      <Typography margin='0 0 20px'>Parametros de Ruta</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl
            secondary
            control='select'
            options={typeOptions}
            label='Tipo de ruta'
            placeholder='Seleccionar tipo'
            onChange={value => onChange(value, 'route_type_id')}
            value={values.route_type_id}
            margin={size.width > 768 ? '0 0 20px' : '0'}
            width='100%'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            secondary
            control='time'
            label='Hora de inicio'
            placeholder='12:00'
            onChange={value => onChange(value, 'start_hour')}
            value={values.start_hour}
            margin='0 0 20px'
            width='100%'
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl
            secondary
            control='time'
            label='Tiempo en origen'
            placeholder='12:00'
            onChange={value => onChange(value, 'origin_estimated_duration')}
            value={values.origin_estimated_duration}
            margin={size.width > 768 ? '0 0 20px' : '0'}
            width='100%'
            // dateFormat='mm:ss'
            // dateShow={['minutes' as const, 'seconds' as const]}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            secondary
            control='time'
            label='Tiempo por tarea'
            placeholder='12:00'
            onChange={value =>
              onChange(value, 'destination_estimated_duration')
            }
            value={values.destination_estimated_duration}
            margin={size.width > 768 ? '0 0 20px' : '0'}
            width='100%'
            // dateFormat='mm:ss'
            // dateShow={['minutes' as const, 'seconds' as const]}
          />
        </Grid>
      </Grid>

      <Typography margin='25px 0 20px'>Selección de vehículos</Typography>

      <RadioGroup
        row
        aria-label='permissions'
        name='permissions'
        value={values.vehicle_type}
        onChange={({ target }) => {
          onChange(target.value, 'vehicle_type');
        }}
      >
        <FormControlLabel
          value='vehicle_type'
          control={
            <Radio
              color='primary'
              size='small'
              checked={values.vehicle_type === 'vehicle_type'}
            />
          }
          label={
            <Typography
              fontSize={13}
              color='text.secondary'
              margin='0 20px 0 0'
            >
              Por tipo de vehículo
            </Typography>
          }
        />
        {/* <FormControlLabel */}
        {/*  value='plate' */}
        {/*  control={ */}
        {/*    <Radio */}
        {/*      color='primary' */}
        {/*      size='small' */}
        {/*      checked={values.vehicle_type === 'plate'} */}
        {/*    /> */}
        {/*  } */}
        {/*  label={ */}
        {/*    <Typography fontSize={13} color='text.secondary'> */}
        {/*      Por placa */}
        {/*    </Typography> */}
        {/*  } */}
        {/* /> */}
      </RadioGroup>

      {selectMode === 'table' && (
        <DataTable
          showHeader={false}
          data={vehicles}
          headers={headers}
          pageSize={vehicles.length}
          totalItems={vehicles.length}
          onChangePage={() => {}}
          page={1}
          totalPages={1}
          selectedRows={selectedVehicles}
          onSelectRow={rows => onSelectVehicle(rows)}
          selectable
          showPagination={false}
        />
      )}

      <Typography margin='30px 0 20px'>Criterios de optimización</Typography>

      <FormControl
        secondary
        control='select'
        label='Unidad de medida'
        placeholder='Seleccionar unidad'
        options={measureOptions}
        defaultOption={{
          id: '',
          name: 'Ninguna',
        }}
        value={values.measurement_unit}
        onChange={value => onChange(value, 'measurement_unit')}
        margin='0 0 20px'
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={values.criteria_list.includes('max_points_per_vehicle')}
            onChange={({ target }) => {
              let criteriaList;
              if (target.checked) {
                criteriaList = values.criteria_list.concat(
                  'max_points_per_vehicle'
                );
              } else {
                criteriaList = values.criteria_list.filter(
                  criteria => criteria !== 'max_points_per_vehicle'
                );
              }
              onChange(criteriaList, 'criteria_list');
            }}
            value='max_points_per_vehicle'
            color='primary'
          />
        }
        label={
          <Typography fontSize={13} color='text.secondary'>
            Puntos Máximo
          </Typography>
        }
      />

      <Dialog open={showLoader} disableBackdropClick disableEscapeKeyDown>
        <FlexContainer
          container
          direction='column'
          padding='30px'
          justify='center'
          alignItems='center'
        >
          <FlexContainer
            justify='center'
            alignItems='center'
            borderRadius='100px'
            backgroundColor={THEME.colors.backgroundColor}
            margin='0 0 20px 0'
            height='150px'
            width='150px'
          >
            <Icon icon='wand-icon' size='80px' />
          </FlexContainer>
          <LinearProgress />
          <Typography fontSize={14} margin='20px 0 0 0'>
            Por favor espere, estamos generando rutas
          </Typography>
        </FlexContainer>
      </Dialog>
    </FlexContainer>
  );
};

export default Parameters;
