import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Divider, FlexContainer, Icon, Typography } from 'components/atoms';
import { Search, Spinner } from 'components/molecules';
import THEME from 'util/styledTheme';
import DefaultProfile from 'assets/images/DefaultProfile.png';
import DetailCard from './DetailCard';
import { OrderBlockProps, MeasureBlockProps } from './types';

const details = [
  {
    icon: 'weight-icon',
    key: 'weight_label',
  },
  {
    icon: 'gauge-icon',
    key: 'estimated_distance',
  },
  {
    icon: 'hourglass-icon',
    key: 'estimated_duration',
  },
];

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  right: 12px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.placeholderColor};
`;

const MeasureBlock: React.FC<MeasureBlockProps> = ({ data, value }) => (
  <FlexContainer direction='column'>
    <FlexContainer alignItems='center' margin='0 0 5px'>
      <Icon icon={data.icon} size={17} margin='0 10px 0 0' />
      <Typography fontSize={13} color='text.secondary'>
        {value}
      </Typography>
    </FlexContainer>
  </FlexContainer>
);

const DetailBlock: React.FC<OrderBlockProps> = ({
  data,
  tasks,
  onClose,
  onDrag,
  onSelect,
  onOrderDetail,
  onTaskClick,
  loadingUpdateTask,
  selectedTasksLength,
}) => {
  const [search, setSearch] = useState<string>('');
  const [filteredTasks, setFilteredTasks] = useState<Array<any>>([]);

  const filterOrders = () => {
    let filtered = tasks;
    if (search) {
      filtered = tasks.filter(task => {
        const aux = {
          code: task.order.reference_code,
        };
        return JSON.stringify(aux)
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    }
    setFilteredTasks(filtered);
  };

  useEffect(() => {
    setSearch('');
    setFilteredTasks(tasks);
  }, [tasks]);

  return (
    <>
      <FlexContainer
        container
        padding='50px 15px 10px'
        direction='column'
        position='relative'
      >
        <CloseIcon onClick={() => onClose()}>
          <Icon icon='cancel-icon' color='#FFFFFF' size={25} />
        </CloseIcon>

        {data.worker ? (
          <FlexContainer container>
            <FlexContainer margin='0 10px 0 0'>
              <img src={DefaultProfile} alt='worker_img' width='40px' />
            </FlexContainer>

            <FlexContainer flex='1' direction='column'>
              <FlexContainer container alignItems='center' margin='0 0 5px'>
                <Icon icon='phone' size={15} />
                <Typography fontSize={13} fontWeight={600} margin='0 0 0 8px'>
                  {`${data.worker.first_name} ${data.worker.last_name}`}
                </Typography>
              </FlexContainer>
              <FlexContainer
                container
                alignItems='center'
                justify='space-between'
              >
                <Typography fontSize={12} margin='0 0 0 25px'>
                  {data.vehicle_type && data.vehicle_type.name}
                  {data.vehicle && ` ${data.vehicle.plate}`}
                </Typography>
                {data.provider && (
                  <FlexContainer
                    backgroundColor={THEME.colors.borderColor}
                    alignItems='center'
                    justify='center'
                    padding='2px 10px'
                    borderRadius='5px'
                  >
                    <Typography color='text.secondary' fontSize={12}>
                      {data.provider.name}
                    </Typography>
                  </FlexContainer>
                )}
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        ) : (
          <>
            {data.vehicle_type && (
              <Typography fontSize={12} margin='0 0 0 25px'>
                {data.vehicle_type.name}
              </Typography>
            )}
          </>
        )}
      </FlexContainer>

      {data.worker_data && <Divider orientation='horizontal' type='dotted' />}

      <FlexContainer
        container
        padding={data.worker_data ? '15px 40px' : '0 40px 15px'}
        justify='space-between'
      >
        {details.map(detail => (
          <MeasureBlock
            key={detail.key}
            data={detail}
            value={data[detail.key]}
          />
        ))}
      </FlexContainer>
      <Search
        onChangeValue={value => {
          setSearch(value);
        }}
        searchValue={search}
        onSubmit={() => filterOrders()}
      />
      {!loadingUpdateTask ? (
        filteredTasks.length > 0 && (
          <DragDropContext onDragEnd={onDrag}>
            <Droppable droppableId={`${data.id}`}>
              {dropProvided => (
                <FlexContainer
                  container
                  direction='column'
                  ref={dropProvided.innerRef}
                  {...dropProvided.droppableProps}
                  padding={selectedTasksLength > 0 ? '0 0 50px' : '0'}
                >
                  {filteredTasks.map((task, taskIndex) => {
                    if (task) {
                      if (
                        !task.is_end &&
                        !task.is_start &&
                        task.can_change_sequence
                      ) {
                        return (
                          <Draggable
                            key={task.id}
                            draggableId={task.id.toString()}
                            index={taskIndex}
                          >
                            {provided => (
                              <FlexContainer
                                container
                                ref={provided.innerRef}
                                onClick={() => onTaskClick(task)}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <DetailCard
                                  key={task.id}
                                  data={task}
                                  toggleSelect={checked =>
                                    onSelect(task.id, checked)
                                  }
                                  onDetail={() => onOrderDetail(task.order)}
                                  showDetail
                                />
                              </FlexContainer>
                            )}
                          </Draggable>
                        );
                      }
                      return (
                        <FlexContainer
                          key={task.id}
                          container
                          onClick={() => onTaskClick(task)}
                        >
                          <DetailCard
                            key={task.id}
                            data={task}
                            onDetail={() => onOrderDetail(task.order)}
                          />
                        </FlexContainer>
                      );
                    }
                    return null;
                  })}
                </FlexContainer>
              )}
            </Droppable>
          </DragDropContext>
        )
      ) : (
        <FlexContainer
          container
          direction='column'
          justify='center'
          alignItems='center'
          height='100%'
        >
          <Spinner height='50px' />
        </FlexContainer>
      )}
    </>
  );
};

export default DetailBlock;
