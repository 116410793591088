import styled from 'styled-components';

type LogoProps = {
  width?: string;
  height?: string;
  img?: string;
  margin?: string;
  radius?: string;
};

const Logo = styled.div<LogoProps>`
  width: ${props => props.width || '100'}px;
  height: ${props => props.height || '50'}px;
  background-image: url(${props => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: ${props => props.margin};
  ${props => (props.radius ? `border-radius: ${props.radius}px;` : '')}
`;

export default Logo;
