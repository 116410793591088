import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Drawer, Dialog } from '@material-ui/core';
import {
  Button,
  FlexContainer,
  Icon,
  Typography,
  // Switch,
} from 'components/atoms';
import { Cell } from 'components/molecules';
import { SearchWithFilter, DataTable } from 'components/organisms';
import { getOrderProtocols } from 'services';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import EditProtocol from './Edit';
import CreateProtocol from './Create';

const options = [
  {
    name: 'Nombre',
    id: 'name',
  },
];

const StatusCircle = styled.div<{ active }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${props =>
    props.active
      ? props.theme.colors.success
      : props.theme.colors.placeholderColor};
  box-shadow: rgb(0 0 0 / 8%) 2px 2px 3px 0px;
  margin: 5px;
`;

const OrderProtocol: React.FC = () => {
  const user = useTypedSelector(state => state.user);
  // API calls
  const [
    orderProtocols,
    fetchOrderProtocols,
    loadingProtocols,
    pageData,
  ] = useRequest(getOrderProtocols, []);

  // Values
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [editData, setEditData] = useState(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);

  const requestProtocols = useCallback(() => {
    const params = {
      page,
    };

    if (searchFilter !== '') {
      params[searchFilter] = searchInput;
    }

    fetchOrderProtocols(params);
  }, [fetchOrderProtocols, page, searchInput, searchFilter]);

  useEffect(() => {
    requestProtocols();
  }, [page]);

  const headers = useMemo(
    () => [
      {
        label: (
          <Typography margin='0 0 0 20px' color='#6d7996'>
            Nombre
          </Typography>
        ),
        id: 'name',
        cell: row => (
          <FlexContainer margin='0 0 0 20px'>
            <Cell.Link
              onClick={() => {
                if (user.permissions.includes('core.order_protocol.update')) {
                  setEditData(row);
                  setOpenEdit(true);
                }
              }}
            >
              {row.name}
            </Cell.Link>
          </FlexContainer>
        ),
      },
      {
        label: 'Descripción',
        id: 'description',
        cell: row => row.description,
      },
      {
        label: 'Activo',
        id: 'status',
        cell: row => (
          <StatusCircle active={row.is_active} />
          // {/* <Switch disabled checked={row.is_active} />
        ),
      },
      {
        label: 'Clientes',
        id: 'clients',
        cell: row => row.clients_quantity || '-',
      },
    ],
    []
  );

  return (
    <FlexContainer container padding='30px 40px' direction='column'>
      <FlexContainer
        container
        alignItems='center'
        justify='space-between'
        margin='0 0 30px'
      >
        <Typography fontSize={18} fontWeight={300}>
          Protocolo Digital
        </Typography>
        {user.permissions.includes('core.order_protocol.create') && (
          <Button
            color='primary'
            margin='0 0 0 20px'
            fontSize='13px'
            startIcon={<Icon icon='plus-icon' size={18} />}
            onClick={() => setOpenCreate(true)}
          >
            Crear Protocolo
          </Button>
        )}
      </FlexContainer>

      <FlexContainer
        container
        direction='column'
        margin='0 0 20px'
        padding='20px'
        backgroundColor='#FFFFFF'
        borderRadius='4px'
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer container>
          <SearchWithFilter
            filterLabel='Buscar por'
            filterValue={searchFilter}
            onChangeFilterValue={value => setSearchFilter(value)}
            options={options}
            onChangeSearchValue={value => setSearchInput(value)}
            searchValue={searchInput}
            onSubmit={() => requestProtocols()}
            placeholder='Buscar'
          />
          <Button
            variant='contained'
            color='primary'
            margin='0 20px'
            onClick={() => requestProtocols()}
          >
            Buscar
          </Button>
        </FlexContainer>
      </FlexContainer>

      <DataTable
        headers={headers}
        data={orderProtocols}
        onChangePage={selectedPage => setPage(selectedPage)}
        totalPages={pageData.totalPages}
        totalItems={pageData.totalItems}
        pageSize={pageData.pageSize}
        page={page}
        loading={loadingProtocols}
      />

      <Dialog
        fullWidth
        open={openCreate}
        maxWidth='sm'
        onClose={() => setOpenCreate(false)}
      >
        <CreateProtocol
          onCreate={useCallback(() => requestProtocols(), [requestProtocols])}
          onClose={() => setOpenCreate(false)}
        />
      </Dialog>

      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => {
          setEditData(null);
          setOpenEdit(false);
        }}
      >
        <EditProtocol
          data={editData}
          onCreate={useCallback(() => requestProtocols(), [requestProtocols])}
          onClose={() => {
            setEditData(null);
            setOpenEdit(false);
          }}
        />
      </Drawer>
    </FlexContainer>
  );
};

export default OrderProtocol;
