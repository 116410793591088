import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from 'components/atoms';
import { CollapseProps } from './types';

const Container = styled(FlexContainer)`
  transition: all 0.5s;
  overflow: hidden;
`;

const Content = styled(FlexContainer)`
  overflow: hidden;
  transition: all 0.5s;
`;

const Collapse: React.FC<CollapseProps> = ({
  height,
  open,
  margin,
  children,
}) => (
  <Container container height={open ? height : '0'}>
    <Content container direction='column' padding={margin}>
      {children}
    </Content>
  </Container>
);

export default Collapse;
