import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { chooseOrganization } from 'services';
import { find } from 'lodash';
import { useTypedSelector } from 'store';
import { selectBranchOffice } from 'store/organization/actions';
import { optionFormatter } from 'util/functions';
import { FlexContainer } from 'components/atoms';
import { FormControl } from 'components/molecules';
import { FilterProps } from './types';

const FilterContainer: React.FC<FilterProps> = ({
  filters,
  onChange,
  value,
  disabled,
  labelWeight,
  inline,
  filterWidth,
  justify = 'flex-start',
  width,
  wrap,
  permissions,
  showBranchOffice = false,
  margin,
  showLabel = false,
}) => {
  const organization = useTypedSelector(state => state.organization);
  const dispatch = useDispatch();

  const [officeOptions, setOfficeOptions] = useState([]);

  const defaultOption = { id: '', name: 'Todas', reference_code: '' };
  const toSelectDefaultOption = {
    id: JSON.stringify(defaultOption),
    name: 'Todas',
  };

  useEffect(() => {
    chooseOrganization({
      page_size: 0,
    }).then(res => {
      const organizationObj = find(res.data, {
        id: parseInt(`${organization.selectedOrganization.id}`, 10),
      });

      if (organizationObj) {
        setOfficeOptions(
          optionFormatter(organizationObj.administrator_branch_offices, {
            id: option => JSON.stringify(option),
            name: option => option.name,
          })
        );
      }
    });
  }, []);

  return (
    <FlexContainer
      alignItems='center'
      justify={justify}
      width={width}
      wrap={wrap}
      margin='20px 0 0 0'
    >
      {showBranchOffice && (
        <FormControl
          control='select'
          placeholder='Seleccione una organización'
          value={JSON.stringify(organization.selectedBranchOffice)}
          options={officeOptions}
          defaultOption={toSelectDefaultOption}
          onChange={organizationValue => {
            const parsedValue = JSON.parse(organizationValue);
            dispatch(
              selectBranchOffice({
                id: parsedValue.id,
                name: parsedValue.name,
                reference_code: parsedValue.reference_code,
              })
            );
          }}
          margin={margin || '0 20px 0 0'}
          disabled={disabled}
        />
      )}

      {filters.map(filter => {
        if (!filter.permission || permissions.includes(filter.permission)) {
          return (
            <FormControl
              key={filter.name}
              control={filter.type || 'select'}
              placeholder={filter.placeholder}
              label={filter.label}
              value={value[filter.name]}
              options={filter.options}
              defaultOption={filter.defaultOption}
              onChange={val => onChange(val, filter.name)}
              margin={margin || '0 20px 0 0'}
              disabled={disabled}
              labelWeight={labelWeight}
              inline={inline}
              width={filterWidth || (inline && 'auto')}
              dateIcon={filter.showIcon}
              suffix={filter.suffix}
              dateFormat={filter.format}
              dateShow={filter.show}
              showLabel={showLabel}
            />
          );
        }
        return null;
      })}
    </FlexContainer>
  );
};

export default FilterContainer;
