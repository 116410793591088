import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTypedSelector } from 'store';
import useWindowSize from 'hooks/useWindowSize';
import { FlexContainer, Button, Typography, Icon } from 'components/atoms';
import { Dropdown, DropdownIconOption, Spinner } from 'components/molecules';
import {
  Drawer,
  Dialog,
  Grid,
  IconButton,
  Switch,
  SwitchProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import { getProducts, getProduct, patchProduct, getChannels } from 'services';
import Pagination from '@material-ui/lab/Pagination';
import EmptyBox from 'assets/images/inventory/empty.png';
import { ReactComponent as GridIcon } from 'assets/images/inventory/grid-icon.svg';
import { ReactComponent as ListIcon } from 'assets/images/inventory/list-icon.svg';
import { DataTable } from 'components/organisms';
import { useSnackbar } from 'notistack';
import NoData from 'assets/images/no_data.svg';
import { ReactComponent as CopyClipboardIcon } from 'assets/images/copy-clipboard-icon.svg';
import { ReactComponent as LinkIcon } from 'assets/images/link-icon.svg';
import { ReactComponent as ShareIcon } from 'assets/images/share-icon.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/share-facebook-icon.svg';
import { ReactComponent as WhatsappIcon } from 'assets/images/share-whatsapp-icon.svg';
import { ReactComponent as TwitterIcon } from 'assets/images/share-twitter-icon.svg';
import { getLocalStorageClientId, getProductPrice } from 'util/functions';
import EditProduct, { ConfirmDelete } from './Edit';
import CreateProduct from './Create';

const useStyles = makeStyles({
  drawer: {
    width: '720px',
    flexShrink: 0,
  },
  drawerMobile: {
    width: '555px',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '720px',
  },
  drawerPaperMobile: {
    width: '555px',
  },
});

const ProductsGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  justify-items: center;

  @media only screen and (max-width: 1028px) and (min-width: 780px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }

  @media only screen and (max-width: 1249px) and (min-width: 1029px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
  }

  @media only screen and (min-width: 1250px) {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
  }
`;

const ProductNameContainer = styled(FlexContainer)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: left;
  flex-direction: column;
  &: hover {
    cursor: pointer;

    & > div {
      text-decoration: underline;
    }
  }
`;

const CustomPaginator = styled(Pagination)`
  button {
    border: 1px solid #e8e9ec;
    color: ${props => props.theme.colors.textSecondary};
    &:hover {
      background-color: ${props => props.theme.colors.borderColor};
    }
  }
  button[class^='PaginationItem-selected'],
  button[class*=' PaginationItem-selected'] {
    color: ${props => props.theme.colors.textSecondary};
    background-color: ${props => props.theme.colors.borderColor};
    &:hover {
      background-color: ${props => props.theme.colors.borderColor};
    }
    &:focus {
      background-color: ${props => props.theme.colors.borderColor};
    }
  }
`;

const DropdownOptionContainer = styled(FlexContainer)`
  &:hover {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    background-color: ${props =>
      props.disabled ? '' : props.theme.colors.backgroundColor};
    color: ${props => props.theme.colors.primary};
  }
`;

const GreenSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#f5f6fa',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
  },
}));

const Products = props => {
  const user = useTypedSelector(state => state.user);
  const { clientId, hasClients, isLoadingClients, storeName } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { width } = useWindowSize();
  const [productsView, setProductsView] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productId, setProductId] = useState<string>('');
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dialogMaxWidth, setDialogMaxWidth] = useState(true);
  const [openShare, setOpenShare] = useState(false);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 14,
    total: 0,
  });

  const totalPages = () => {
    const residue = paginationData.total % paginationData.size;
    const result = paginationData.total / paginationData.size;
    // eslint-disable-next-line
    const pages = residue === 0 ? result : parseInt(result + '') + 1;
    return pages;
  };

  const [products, fetchProducts, loadingProducts] = useRequest(
    getProducts,
    []
  );
  // eslint-disable-next-line
  const [updatedProduct, updateProduct, loadingUpdate] = useRequest(
    patchProduct
  );

  const loadProducts = (size?: number) => {
    const pageSize = size || paginationData.size;

    const params = {
      $skip: pageSize * paginationData.page,
      $limit: pageSize,
      is_active: true,
      $select: [
        '_id',
        'name',
        'price',
        'photos',
        'is_public',
        'sku',
        'has_variations',
        'variations_details',
      ],
      client_code: clientId || getLocalStorageClientId(),
    };
    fetchProducts(params);
    setOpenCreate(false);
  };

  useEffect(() => {
    setPaginationData({ ...paginationData, page: 0 });

    if (isLoadingClients) {
      return;
    }

    if (hasClients && !clientId) {
      return;
    }

    loadProducts();
  }, [clientId]);

  useEffect(() => {
    if (!products.data) {
      return;
    }

    const productListTemp = products.data.map(product => {
      return { ...product, price: getProductPrice(product) };
    });

    setProductList(productListTemp);
    setPaginationData({ ...paginationData, total: products.total });
  }, [products]);

  useEffect(() => {
    if (!updatedProduct) {
      return;
    }

    loadProducts();
  }, [updatedProduct]);

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPaginationData({ ...paginationData, page: value - 1 });

    const params = {
      $skip: paginationData.size * (value - 1),
      $limit: paginationData.size,
      is_active: true,
      $select: [
        '_id',
        'name',
        'price',
        'photos',
        'is_public',
        'sku',
        'has_variations',
        'variations_details',
      ],
      client_code: clientId || getLocalStorageClientId(),
    };
    fetchProducts(params);
  };

  const settingsOptions = [
    {
      label: 'Editar',
      name: 'edit',
      icon: 'edit-icon',
      onClick: row => {
        setOpenEdit(true);
        setProductId(row._id);
      },
    },
  ];

  const option = row =>
    settingsOptions.map(setting => (
      <DropdownIconOption
        key={setting.name}
        label={setting.label}
        name={setting.name}
        icon={setting.icon}
        onClick={() => setting.onClick(row)}
      />
    ));

  const headers = useMemo(
    () => [
      {
        label: 'Producto',
        id: 'name',
        cell: row => row.name,
      },
      {
        label: 'SKU',
        id: 'sku',
        cell: row => row.sku,
      },
      {
        label: 'Precio',
        id: 'price',
        cell: row => row.price,
      },
      {
        label: 'Habilitado',
        id: 'is_public',
        cell: row => (row.is_public ? '✔' : ''),
      },
      {
        label: <FlexContainer justify='center' />,
        id: 'actions',
        cell: row => (
          <FlexContainer width='80%' justify='flex-end' margin='0 0 0 40px'>
            <Dropdown
              label={<Icon icon='options-icon' size={20} />}
              direction='bottom'
              position='right'
              showCaret={false}
              options={option(row)}
              padding='0 10px'
              hover
            />
          </FlexContainer>
        ),
      },
    ],
    [productList]
  );

  const canRenderProducts = () => {
    if (isLoadingClients) {
      return false;
    }

    if (hasClients && !clientId) {
      return false;
    }

    return true;
  };

  const settingsProductOptions = [
    {
      label: 'Editar',
      name: 'edit',
      icon: 'edit-icon',
      onClick: (_productId: string) => {
        setOpenEdit(true);
        setProductId(_productId);
      },
    },
    {
      label: 'Ver en catálogo',
      name: 'see',
      icon: <LinkIcon style={{ margin: '0 15px 0 0' }} />,
      disabled: !storeName,
      onClick: (_productId: string) => {
        window.open(`https://${storeName}/product/${_productId}`, '_blank');
      },
    },
    {
      label: 'Copiar link',
      name: 'copy',
      icon: <CopyClipboardIcon style={{ margin: '0 15px 0 0' }} />,
      disabled: !storeName,
      onClick: (_productId: string) => {
        navigator.clipboard.writeText(
          `https://${storeName}/product/${_productId}`
        );
        enqueueSnackbar('Link copiado.', {
          variant: 'success',
        });
      },
    },
    {
      label: 'Compartir',
      name: 'share',
      icon: <ShareIcon style={{ margin: '0 15px 0 0' }} />,
      disabled: !storeName,
      onClick: (_productId: string) => {
        setProductId(_productId);
        setOpenShare(true);
      },
    },
    {
      label: 'Eliminar',
      name: 'delete',
      icon: 'delete-icon-red',
      disabled: user.role === 'client',
      color: '#ff1744',
      borderTop: '1px solid #efeff5',
      padding: '15px 15px',
      onClick: (_productId: string) => {
        setOpenDeleteDialog(true);
        setProductId(_productId);
      },
    },
  ];

  const renderProductOptions = (_productId: string) =>
    settingsProductOptions.map(setting => {
      return (
        <DropdownOptionContainer
          key={setting.name}
          container
          alignItems='center'
          disabled={setting.disabled}
          padding={setting.padding ? setting.padding : '10px 10px'}
          borderTop={setting.borderTop ? setting.borderTop : undefined}
          onClick={() =>
            setting.disabled ? undefined : setting.onClick(_productId)
          }
        >
          {typeof setting.icon === 'string' ? (
            <Icon icon={setting.icon} size={20} margin='0 15px 0 0' />
          ) : (
            setting.icon
          )}
          <Typography
            color={setting.disabled ? '#c8c8d3' : setting.color}
            fontSize={13}
          >
            {setting.label}
          </Typography>
        </DropdownOptionContainer>
      );
    });

  const deleteProduct = () => {
    const body = {
      is_active: false,
    };

    updateProduct(body, productId);
    enqueueSnackbar('Producto eliminado correctamente.', {
      variant: 'success',
    });
  };

  return (
    <div style={{ width: '100%' }}>
      {canRenderProducts() ? (
        <FlexContainer container justify='flex-end'>
          <Button
            color='primary'
            fontSize='13px'
            startIcon={<Icon icon='plus-icon' size={18} />}
            onClick={() => setOpenCreate(true)}
          >
            Crear Producto
          </Button>
          <IconButton
            size='small'
            disabled={!productsView}
            onClick={() => {
              setProductsView(false);
              setPaginationData({ ...paginationData, size: 14 });
              loadProducts(14);
            }}
          >
            <GridIcon stroke={!productsView ? '#4131D3' : '#000000'} />
          </IconButton>
          <IconButton
            size='small'
            disabled={!!productsView}
            onClick={() => {
              setProductsView(true);
              setPaginationData({ ...paginationData, size: 10 });
              loadProducts(10);
            }}
          >
            <ListIcon stroke={productsView ? '#4131D3' : '#000000'} />
          </IconButton>
        </FlexContainer>
      ) : null}
      <FlexContainer container padding='40px 0' direction='column'>
        {canRenderProducts() ? (
          loadingProducts ? (
            <FlexContainer
              container
              padding='30px 40px'
              direction='column'
              justify='center'
              alignItems='center'
              height='80vh'
            >
              <Spinner height='350px' />
            </FlexContainer>
          ) : !productsView ? (
            <ProductsGrid>
              <Button
                variant='outlined'
                color='primary'
                margin='0'
                fontSize='40px'
                style={{
                  borderStyle: 'dashed',
                  width: 250,
                  height: 280,
                  borderWidth: '3px',
                }}
                onClick={() => setOpenCreate(true)}
              >
                <FlexContainer direction='column' alignItems='center'>
                  <Typography fontSize={45} color={THEME.colors.primary}>
                    +
                  </Typography>
                  <Typography fontSize={13} color={THEME.colors.primary}>
                    Crear producto
                  </Typography>
                </FlexContainer>
              </Button>
              {!!productList &&
                productList.map(product => (
                  <FlexContainer
                    // eslint-disable-next-line no-underscore-dangle
                    key={product._id}
                    direction='column'
                    width='250px'
                    height='280px'
                    backgroundColor='#FFFFFF'
                    borderRadius='5px'
                    borderColor={THEME.colors.borderColor}
                  >
                    <FlexContainer
                      width='100%'
                      justify='space-between'
                      padding='10px 10px 5px 25px'
                      borderBottom='1px solid #efeff5'
                      boxShadow='0px 2px #f9f9fc'
                    >
                      <GreenSwitch
                        checked={product.is_public}
                        onChange={e => {
                          const value = e.target.checked;
                          const data = {
                            is_public: value,
                          };
                          updateProduct(data, product._id);

                          const productListTemp = [...productList];
                          const productIndex = productList.findIndex(
                            item => item._id === product._id
                          );
                          productListTemp[productIndex].is_public = value;
                          setProductList(productListTemp);

                          if (value) {
                            enqueueSnackbar(
                              'Producto habilitado correctamente.',
                              {
                                variant: 'success',
                              }
                            );
                          } else {
                            enqueueSnackbar('Se deshabilitó el producto.', {
                              variant: 'warning',
                            });
                          }
                        }}
                      />
                      <Dropdown
                        label={<Icon icon='options-icon' size={20} />}
                        direction='bottom'
                        position='right'
                        showCaret={false}
                        options={renderProductOptions(product._id)}
                        padding='0 10px'
                        hover
                      />
                    </FlexContainer>
                    <div style={{ position: 'relative' }}>
                      <img
                        src={
                          product.photos.length > 0
                            ? product.photos[0]
                            : EmptyBox
                        }
                        alt='product'
                        style={{
                          width: 250,
                          height: 170,
                          objectFit: 'contain',
                          padding: '5px 15px 20px 15px',
                          filter: product.is_public
                            ? 'grayScale(0)'
                            : 'grayScale(1)',
                          opacity: product.is_public ? '1' : '0.3',
                        }}
                      />
                      {!product.is_public && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            fontSize: 'medium',
                            fontWeight: 'bold',
                            transform: 'translate(-50%, -50%)',
                            width: '100%',
                            padding: '5px 15px 5px 15px',
                            filter: 'grayScale(1)',
                            opacity: '0.6',
                            textAlign: 'center',
                          }}
                        >
                          No está disponible en el catálogo digital
                        </div>
                      )}
                    </div>
                    <FlexContainer
                      container
                      direction='row'
                      padding='1px 15px'
                      alignItems='space-between'
                    >
                      <ProductNameContainer
                        onClick={() => {
                          setOpenEdit(true);
                          setProductId(product._id);
                        }}
                      >
                        <Typography
                          fontSize={15}
                          style={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            alignItems: 'center',
                            maxWidth: '100%',
                            minWidth: '0px',
                            padding: '0px 10px 0px 10px',
                            filter: product.is_public
                              ? 'grayScale(0)'
                              : 'grayScale(1)',
                            opacity: product.is_public ? '1' : '0.3',
                          }}
                        >
                          {product.name}
                        </Typography>
                        <Typography
                          fontSize={12}
                          style={{
                            padding: '0px 10px 0px 10px',
                            filter: product.is_public
                              ? 'grayScale(0)'
                              : 'grayScale(1)',
                            opacity: product.is_public ? '1' : '0.3',
                          }}
                        >
                          S./ {getProductPrice(product)}
                        </Typography>
                      </ProductNameContainer>
                    </FlexContainer>
                  </FlexContainer>
                ))}
            </ProductsGrid>
          ) : (
            <DataTable
              headers={headers.filter(header => header)}
              data={productList}
              dataIdentifier='_id'
              onChangePage={e => changePage(null, e)}
              totalPages={totalPages()}
              totalItems={paginationData.total}
              pageSize={paginationData.size}
              page={paginationData.page + 1}
              showPagination
              marginBottom='0'
            />
          )
        ) : (
          <FlexContainer
            container
            direction='column'
            justify='center'
            alignItems='center'
            height='60vh'
          >
            <img src={NoData} alt='no_data' />
            <Typography color='text.secondary'>
              Seleccione un cliente
            </Typography>
          </FlexContainer>
        )}

        <Dialog
          fullWidth
          open={openCreate}
          maxWidth={dialogMaxWidth ? 'md' : 'sm'}
          onClose={() => {
            setOpenCreate(false);
            setDialogMaxWidth(true);
          }}
        >
          <CreateProduct
            onCreate={() => loadProducts()}
            onClose={() => setOpenCreate(false)}
            setDialogMaxWidth={maxWidth => setDialogMaxWidth(maxWidth)}
            clientId={clientId}
          />
        </Dialog>
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <ConfirmDelete
            title='Eliminar Producto'
            message='¿Estás seguro de eliminar el producto?'
            onConfirm={() => {
              deleteProduct();
              setOpenDeleteDialog(false);
            }}
            onClose={() => setOpenDeleteDialog(false)}
          />
        </Dialog>
        <Dialog open={openShare} onClose={() => setOpenShare(false)}>
          <FlexContainer
            container
            direction='row'
            justify='center'
            alignItems='center'
            height='20vh'
            width='300px'
          >
            <IconButton
              size='small'
              style={{ marginRight: '40px', marginLeft: '40px' }}
              onClick={() => {
                const link = document.createElement('a');
                link.setAttribute(
                  'href',
                  `https://www.facebook.com/sharer/sharer.php?u=https://${storeName}/product/${productId}`
                );
                link.setAttribute('target', '_blank');
                link.click();
                link.remove();
              }}
            >
              <FacebookIcon />
            </IconButton>

            <IconButton
              size='small'
              onClick={() => {
                const link = document.createElement('a');
                link.setAttribute(
                  'href',
                  `https://twitter.com/intent/tweet?url=https://${storeName}/product/${productId}`
                );
                link.setAttribute('target', '_blank');
                link.click();
                link.remove();
              }}
            >
              <TwitterIcon />
            </IconButton>

            <IconButton
              size='small'
              style={{ marginRight: '40px', marginLeft: '40px' }}
              onClick={() => {
                const link = document.createElement('a');
                link.setAttribute(
                  'href',
                  `https://wa.me/?text=https://${storeName}/product/${productId}`
                );
                link.setAttribute('target', '_blank');
                link.click();
                link.remove();
              }}
            >
              <WhatsappIcon />
            </IconButton>
          </FlexContainer>
        </Dialog>
        <Drawer
          className={width > 715 ? classes.drawer : classes.drawerMobile}
          classes={{
            paper:
              width > 715 ? classes.drawerPaper : classes.drawerPaperMobile,
          }}
          anchor='right'
          open={openEdit}
          onClose={() => {
            setOpenEdit(false);
          }}
        >
          <EditProduct
            productId={productId}
            clientId={clientId}
            onCreate={() => loadProducts()}
            onClose={() => {
              setOpenEdit(false);
            }}
          />
        </Drawer>
      </FlexContainer>
      {!productsView && canRenderProducts() && (
        <Grid container justify='center'>
          <CustomPaginator
            count={totalPages()}
            shape='rounded'
            variant='outlined'
            size='large'
            onChange={changePage}
          />
        </Grid>
      )}
    </div>
  );
};

export default Products;
