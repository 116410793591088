import React, { useEffect, useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { cloneDeep, find } from 'lodash';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Drawer, LinearProgress, Tooltip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  Button,
  FlexContainer,
  Typography,
  Icon,
  Status,
  Select,
} from 'components/atoms';
import {
  AssignButton,
  Dropdown,
  DropdownIconOption,
  Cell,
  Spinner,
  FormControl,
} from 'components/molecules';
import {
  DataTable,
  FiltersContainer,
  SearchWithFilter,
  AssignBox,
} from 'components/organisms';
import { useTypedSelector } from 'store';
import { selectBranchOffice } from 'store/organization/actions';
import {
  getRoutes,
  getStatuses,
  getProvidersMinimal,
  getVehiclesForRoutes,
  assignRouteWorkerVehicle,
  assignRouteProvider,
  changeRouteStatus,
  releaseRoutes,
  getTimeWindows,
  chooseOrganization,
} from 'services';
import {
  optionFormatter,
  vehicleFormatter,
  providerFormatter,
} from 'util/functions';
import THEME from 'util/styledTheme';
import statusColors from 'util/statusColors';
import useRequest from 'hooks/useRequest';
import Minutes99 from 'assets/images/providers/99minutos.png';
import Olva from 'assets/images/providers/olva.jpg';
import Glovo from 'assets/images/providers/glovo.svg';
import Savar from 'assets/images/providers/savar.png';
import Logo from 'assets/images/DefaultLogo.png';
import Chazki from 'assets/images/providers/chazki.png';
import Moova from 'assets/images/providers/moova.png';
import { ReactComponent as MoreOptions } from 'assets/images/filter_icon.svg';
import NoData from 'assets/images/no_data.svg';
import DetailRoute from '../Detail';

const MoreOptionIcon = styled(MoreOptions)`
  .cls-2 {
    stroke: #8c8cb1;
  }

  ${({ active }) =>
    active &&
    `
    .cls-2{
      stroke: #404071;
    }
  `}
`;

const Progress = withStyles({
  root: {
    height: 7,
    backgroundColor: THEME.colors.borderColor,
  },
  bar: {
    backgroundColor: THEME.colors.purpleBackground,
  },
})(LinearProgress);

export const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: THEME.colors.purpleBackground,
    color: '#FFF',
    fontSize: 12,
    marginBottom: 7,
    padding: 4,
  },
  arrow: {
    color: THEME.colors.purpleBackground,
  },
})(Tooltip);

const initialFilters = [
  {
    label: 'Fecha programada',
    placeholder: 'Fecha programada',
    name: 'delivery_programmed_date',
    type: 'date',
  },
  {
    label: 'Estado de rutas',
    placeholder: 'Seleccione estado',
    name: 'last_status__id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
  },
  {
    label: 'Ventana horaria',
    placeholder: 'Seleccione horario',
    name: 'time_window',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
    permission: 'core.time_window.list',
  },
];

const options = [
  {
    name: 'Provider',
    id: 'provider_name',
  },
  {
    name: 'Worker',
    id: 'worker_name',
  },
  {
    name: 'Código',
    id: 'code',
  },
  {
    name: 'Cod. Referencia',
    id: 'reference_code',
  },
  {
    name: 'Placa',
    id: 'vehicle__plate',
  },
];

const settingsOptions = [
  {
    label: 'Liberar',
    name: 'break',
    icon: 'send-icon',
  },
];

const OrderList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const organization = useTypedSelector(state => state.organization);
  const branchOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  const dispatch = useDispatch();
  const user = useTypedSelector(state => state.user);
  // API calls
  const [routes, fetchRoutes, loadingRoutes, pageData] = useRequest(
    getRoutes,
    []
  );
  const [allStatus, fetchAllStatus] = useRequest(getStatuses, []);
  const [manualStatus, fetchManualStatus] = useRequest(getStatuses, []);
  const [vehicles, fetchVehicles] = useRequest(getVehiclesForRoutes, null);
  const [providers, fetchProviders] = useRequest(getProvidersMinimal, null);
  const [timeWindows, fetchTimeWindows, loadingTimeWindows] = useRequest(
    getTimeWindows,
    null
  );
  const [successWorkerAssign, workerAssign] = useRequest(
    assignRouteWorkerVehicle,
    null
  );
  const [successProviderAssign, providerAssign] = useRequest(
    assignRouteProvider,
    null
  );
  const [, updateStatus, , , , statusRequest] = useRequest(
    changeRouteStatus,
    null
  );
  // Values
  const [, routesRelease, , , , releaseStatusRequest] = useRequest(
    releaseRoutes,
    null
  );
  const [filters, setFilters] = useState(initialFilters);
  const [filterValues, setFilterValues] = useState({
    created_at: null,
    delivery_programmed_date: null,
    last_status__id: null,
    time_window: null,
  });
  const [page, setPage] = useState<number>(1);
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [assignOption, setAssignOption] = useState<string>('');
  const [assignSelectOptions, setAssignSelectOptions] = useState([]);
  const [assignSearchOptions, setAssignSearchOptions] = useState([]);
  const [editData, setEditData] = useState({});
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [firstLoadingRoutes, setFirstLoadingRoutes] = useState<boolean>(false);
  const [firstRoutesRender, setFirstRoutesRender] = useState<boolean>(false);
  const [searchWithFilter, setSearchWithFilter] = useState<boolean>(false);
  const [officeOptions, setOfficeOptions] = useState([]);
  const defaultOption = { id: '', name: 'Todas', reference_code: '' };
  const toSelectDefaultOption = {
    id: JSON.stringify(defaultOption),
    name: 'Todas',
  };

  const requestRoutes = useCallback(
    (isSearch?: boolean) => {
      setSelectedRows([]);
      const params = {
        last_status__id: filterValues.last_status__id,
        delivery_programmed_date: filterValues.delivery_programmed_date,
        created_at: filterValues.created_at,
        time_window: filterValues.time_window,
        organization_branch_office__id: branchOfficeId,
        page_size: 20,
        page: isSearch ? 1 : page,
      };

      if (searchFilter !== '') {
        params[searchFilter] = searchInput;
      }

      if (isSearch) {
        setSearchWithFilter(true);
      }

      fetchRoutes(params);
      setFirstRoutesRender(false);
    },
    [
      filterValues,
      organization,
      page,
      searchFilter,
      searchInput,
      branchOfficeId,
    ]
  );

  const handleSearch = (search: string) => {
    if (selectedRows.length === 0) {
      enqueueSnackbar('Seleccione al menos una ruta para asignar.', {
        variant: 'error',
      });
      return;
    }

    if (assignOption === 'worker') {
      fetchVehicles({
        search,
        routes: selectedRows.join(','),
      });
    }

    if (assignOption === 'provider') {
      fetchProviders({
        organization_branch_office_id: branchOfficeId,
        search,
        routes: selectedRows.join(','),
        service_type: 'internal',
      });
    }
  };

  const handleAssign = (id: string, plate: string) => {
    if (selectedRows.length === 0) {
      enqueueSnackbar('Seleccione al menos una orden para asignar.', {
        variant: 'error',
      });
      return;
    }

    switch (assignOption) {
      case 'worker':
        workerAssign({
          route_ids: selectedRows,
          vehicle: plate,
        });
        break;
      case 'provider':
        providerAssign({
          route_ids: selectedRows,
          provider_id: id,
          organization_branch_office_id: branchOfficeId,
        });
        break;
      case 'status':
        updateStatus({
          route_ids: selectedRows,
          status_id: id,
          organization_branch_office_id: branchOfficeId,
        });
        break;
      default:
        break;
    }
  };

  const handleRelease = () => {
    if (selectedRows.length === 0) {
      enqueueSnackbar('Seleccione al menos una orden para liberar', {
        variant: 'error',
      });
    }
    routesRelease({
      route_ids: selectedRows,
      organization_branch_office_id: branchOfficeId,
    });
  };

  const handleResetAssign = () => {
    setAssignOption('');
    setSearchInput('');
    setAssignSearchOptions([]);
  };

  useEffect(() => {
    fetchAllStatus({
      model_types: 'route',
      status_types: 'manual,automatic',
      page_size: 0,
    });
    fetchManualStatus({
      model_types: 'route',
      status_types: 'manual',
      page_size: 0,
    });
    fetchTimeWindows({
      page_size: 0,
    });
  }, [fetchAllStatus, fetchManualStatus, fetchTimeWindows]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[1].options = optionFormatter(allStatus, {
      id: item => item.id,
      name: item => item.overwritten_label,
    });
    setFilters(updatedFilters);
  }, [allStatus]);

  useEffect(() => {
    setAssignSelectOptions(
      optionFormatter(manualStatus, {
        id: item => item.id,
        name: item => item.overwritten_label,
      })
    );
  }, [manualStatus]);

  useEffect(() => {
    if (timeWindows && timeWindows.length > 0) {
      const updatedFilters = cloneDeep(filters);
      if (updatedFilters[2].options.length === 0) {
        updatedFilters[2].options = optionFormatter(timeWindows, {
          id: item => item.id,
          name: item => `${item.start_hour} - ${item.end_hour}`,
        });
        setFilters(updatedFilters);
      }
    }
  }, [timeWindows, filters]);

  useEffect(() => {
    requestRoutes();
    setFirstRoutesRender(true);
  }, [page, branchOfficeId]);

  useEffect(() => {
    if (vehicles) {
      if (vehicles.length === 0) {
        enqueueSnackbar('No se encontraron coincidencias para su búsqueda.', {
          variant: 'warning',
        });
      } else {
        setAssignSearchOptions(vehicleFormatter(vehicles));
      }
    }
  }, [vehicles]);

  useEffect(() => {
    if (searchWithFilter) {
      setFirstRoutesRender(false);
    } else if (firstRoutesRender && loadingRoutes && page === 1) {
      setFirstLoadingRoutes(true);
    } else {
      setFirstLoadingRoutes(false);
    }
  }, [firstRoutesRender, loadingRoutes]);

  useEffect(() => {
    if (providers) {
      if (providers.length === 0) {
        enqueueSnackbar('No se encontraron coincidencias para su búsqueda.', {
          variant: 'warning',
        });
      } else {
        const pickImage = code => {
          switch (code) {
            case '99minutos':
              return Minutes99;
            case 'olva':
              return Olva;
            case 'glovo':
              return Glovo;
            case 'savar':
              return Savar;
            case 'chazki':
              return Chazki;
            case 'moova':
              return Moova;
            default:
              return '';
          }
        };
        setAssignSearchOptions(
          providerFormatter(providers, {
            id: option => option.id,
            name: option => option.business_name,
            phone: option => option.phone,
            logo: option =>
              option.code ? pickImage(option.code) : option.logo || Logo,
          })
        );
      }
    }
  }, [providers]);

  useEffect(() => {
    if (statusRequest === 200) {
      enqueueSnackbar('Se actualizó el estado de las rutas correctamente.', {
        variant: 'success',
      });
      requestRoutes();
      setAssignOption('');
    } else if (statusRequest && statusRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusRequest, enqueueSnackbar]);

  useEffect(() => {
    if (successWorkerAssign) {
      enqueueSnackbar('Se asignó el worker a las rutas correctamente.', {
        variant: 'success',
      });
      requestRoutes();
      handleResetAssign();
    }
  }, [successWorkerAssign]);

  useEffect(() => {
    if (successProviderAssign) {
      enqueueSnackbar('Se asignó el provider a las rutas correctamente.', {
        variant: 'success',
      });
      requestRoutes();
      handleResetAssign();
    }
  }, [successProviderAssign]);

  useEffect(() => {
    if (releaseStatusRequest === 200) {
      enqueueSnackbar('Se liberaron las rutas correctamente.', {
        variant: 'success',
      });
      requestRoutes();
    } else if (releaseStatusRequest && releaseStatusRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [releaseStatusRequest, enqueueSnackbar]);

  useEffect(() => {
    chooseOrganization({
      page_size: 0,
    }).then(res => {
      const organizationObj = find(res.data, {
        id: parseInt(`${organization.selectedOrganization.id}`, 10),
      });

      if (organizationObj) {
        setOfficeOptions(
          optionFormatter(organizationObj.administrator_branch_offices, {
            id: option => JSON.stringify(option),
            name: option => option.name,
          })
        );
      }
    });
  }, []);

  const option = () =>
    settingsOptions.map(setting => (
      <DropdownIconOption
        key={setting.name}
        label={setting.label}
        name={setting.name}
        icon={setting.icon}
        onClick={() => handleRelease()}
      />
    ));

  const headers = useMemo(
    () => [
      {
        label: 'Código',
        id: 'code',
        cell: row => (
          <Cell.Link
            onClick={() => {
              if (user.permissions.includes('core.route.retrieve')) {
                setEditData(row);
                setOpenEdit(true);
              }
            }}
          >
            <Typography fontWeight={700}>{row.code}</Typography>
            {row.reference_code !== '' && (
              <FlexContainer
                margin='5px 0 0'
                padding='5px'
                borderRadius='5px'
                backgroundColor={THEME.colors.borderColor}
                justify='center'
                width='fit-content'
              >
                <Typography fontWeight={700} color='text.secondary'>
                  {row.reference_code}
                </Typography>
              </FlexContainer>
            )}
          </Cell.Link>
        ),
      },
      {
        label: 'Fecha Programada',
        id: 'delivery_programmed_date',
        cell: row => moment(row.delivery_programmed_date).format('DD/MM/YYYY'),
      },
      {
        label: 'Fecha de Creación',
        id: 'created_at',
        cell: row => moment(row.created_at).format('DD/MM/YYYY, h:mm a'),
      },
      {
        label: 'Tipo de Vehículo',
        id: 'vehicle_type',
        cell: row =>
          row.vehicle_type ? (
            <FlexContainer direction='column' justify='center'>
              <Cell.Image src={row.vehicle_type.vehicle_type_img} size='30px'>
                {row.vehicle_type.vehicle_type_name}
              </Cell.Image>
              {row.provider_data && (
                <FlexContainer
                  margin='5px 0 0'
                  padding='4px 6px'
                  borderRadius='5px'
                  backgroundColor={THEME.colors.borderColor}
                >
                  <Typography fontWeight={600} color='text.secondary'>
                    {row.provider_data.provider_tradename}
                  </Typography>
                </FlexContainer>
              )}
            </FlexContainer>
          ) : (
            '-'
          ),
      },
      {
        label: 'Recorrido',
        id: 'distance',
        cell: row => row.estimated_distance_label,
      },
      {
        label: 'Tiempo',
        id: 'time',
        cell: row => row.estimated_duration,
      },
      {
        label: 'Worker Vehículo',
        id: 'worker',
        cell: row =>
          row.worker_data ? (
            <CustomTooltip
              interactive
              arrow
              placement='top'
              title={row.worker_data.phone}
            >
              <div style={{ display: 'inline-block' }}>
                <Cell.Icon icon='phone' size='15px'>
                  {row.worker_data.worker_name}
                </Cell.Icon>
                {row.vehicle_plate && (
                  <FlexContainer>
                    <FlexContainer
                      margin='5px 0 0 25px'
                      padding='4px 8px'
                      borderRadius='5px'
                      backgroundColor={THEME.colors.borderColor}
                    >
                      <Typography fontWeight={600} color='text.secondary'>
                        {row.vehicle_plate}
                      </Typography>
                    </FlexContainer>
                  </FlexContainer>
                )}
              </div>
            </CustomTooltip>
          ) : (
            '-'
          ),
      },
      {
        label: 'Progreso',
        id: 'progress',
        cell: row => (
          <FlexContainer direction='column'>
            <Typography
              color={THEME.colors.textSecondary}
              margin='0 0 5px'
              textAlign='center'
              fontWeight={400}
            >
              {`${row.finalized_orders} / ${row.total_orders} órdenes`}
            </Typography>
            <Progress
              variant='determinate'
              value={(row.finalized_orders / row.total_orders) * 100}
            />
          </FlexContainer>
        ),
      },
      {
        label: (
          <Typography margin='0 0 0 20px' color='#6d7996'>
            Estado
          </Typography>
        ),
        id: 'last_status',
        cell: row =>
          row.last_status ? (
            <Status
              color={statusColors[row.last_status.code] || statusColors.default}
              label={row.last_status.overwritten_label}
              margin='0 30px 0'
            />
          ) : (
            '-'
          ),
      },
      {
        label: '',
        id: 'settings',
        cell: () =>
          user.permissions.includes('core.route.release_worker') && (
            <Dropdown
              label={<Icon icon='options-icon' size={20} />}
              direction='bottom'
              position='right'
              showCaret={false}
              options={option()}
              padding='0 10px'
              hover
            />
          ),
      },
    ],
    [selectedRows]
  );

  return (
    <>
      {firstLoadingRoutes ? (
        <FlexContainer
          container
          padding='30px 40px'
          direction='column'
          justify='center'
          alignItems='center'
          height='80vh'
        >
          <Spinner height='350px' />
        </FlexContainer>
      ) : (
        <FlexContainer container padding='30px 40px' direction='column'>
          {firstRoutesRender && !searchWithFilter && routes.length === 0 ? (
            <FlexContainer
              container
              direction='column'
              justify='center'
              alignItems='center'
              height='70vh'
            >
              <img src={NoData} alt='no_data' />
              <Typography color='text.secondary'>
                No hay rutas registradas
              </Typography>
            </FlexContainer>
          ) : (
            <FlexContainer container direction='column'>
              <FlexContainer padding='10px 0 20px'>
                <Select
                  placeholder='Seleccione una organización'
                  value={JSON.stringify(organization.selectedBranchOffice)}
                  options={officeOptions}
                  defaultOption={toSelectDefaultOption}
                  onChange={value => {
                    const parsedValue = JSON.parse(value);
                    dispatch(
                      selectBranchOffice({
                        id: parsedValue.id,
                        name: parsedValue.name,
                        reference_code: parsedValue.reference_code,
                      })
                    );
                    setSearchWithFilter(true);
                  }}
                  borderless
                />
              </FlexContainer>

              <FlexContainer
                container
                direction='column'
                margin='0 0 20px'
                padding='20px'
                backgroundColor='#FFFFFF'
                borderRadius='4px'
                borderColor={THEME.colors.borderColor}
              >
                <FlexContainer container justify='space-between'>
                  <FlexContainer container>
                    <SearchWithFilter
                      filterLabel='Buscar por'
                      filterValue={searchFilter}
                      onChangeFilterValue={value => setSearchFilter(value)}
                      options={options}
                      onChangeSearchValue={value => setSearchInput(value)}
                      searchValue={searchInput}
                      onSubmit={() => requestRoutes(true)}
                      maxWidth='550px'
                      placeholder='Buscar'
                      inputWidth='250px'
                    />
                    <Button
                      variant='contained'
                      color='primary'
                      margin='0 20px'
                      onClick={() => requestRoutes(true)}
                    >
                      Buscar
                    </Button>
                    <FlexContainer container>
                      <Button
                        variant='contained'
                        color='secondary'
                        onClick={() => setShowOptions(!showOptions)}
                      >
                        <MoreOptionIcon
                          active={
                            Object.values(filterValues).some(
                              value => value !== null
                            )
                              ? 'active'
                              : ''
                          }
                        />
                      </Button>
                    </FlexContainer>
                  </FlexContainer>

                  <FlexContainer justify='flex-end'>
                    {(user.permissions.includes('core.route.assign_provider') ||
                      user.permissions.includes(
                        'core.route.assign_worker'
                      )) && (
                      <AssignButton
                        onClick={name => {
                          setAssignOption(name);
                        }}
                        permissions={user.permissions}
                      />
                    )}
                    {user.permissions.includes('core.route.update_status') && (
                      <Button
                        variant='contained'
                        color='secondary'
                        fontSize='13px'
                        margin='0 0 0 20px'
                        onClick={() => setAssignOption('status')}
                      >
                        Cambiar de Estado
                      </Button>
                    )}
                  </FlexContainer>
                </FlexContainer>

                {showOptions && (
                  <FlexContainer>
                    <FormControl
                      control='select'
                      placeholder='Seleccione una organización'
                      value={JSON.stringify(organization.selectedBranchOffice)}
                      margin='20px 20px 0 0'
                      options={officeOptions}
                      defaultOption={toSelectDefaultOption}
                      onChange={value => {
                        const parsedValue = JSON.parse(value);
                        dispatch(
                          selectBranchOffice({
                            id: parsedValue.id,
                            name: parsedValue.name,
                            reference_code: parsedValue.reference_code,
                          })
                        );
                        setSearchWithFilter(true);
                      }}
                    />
                    <FiltersContainer
                      labelWeight={600}
                      filters={filters}
                      value={filterValues}
                      onChange={(value, name) =>
                        setFilterValues({
                          ...filterValues,
                          [name]: value,
                        })
                      }
                      disabled={loadingTimeWindows}
                      permissions={user.permissions}
                    />
                  </FlexContainer>
                )}
              </FlexContainer>

              {assignOption !== '' && (
                <AssignBox
                  title={
                    assignOption === 'status'
                      ? 'Selecciona el estado para cambiar.'
                      : `Busca al ${assignOption} que desees asignar`
                  }
                  type={assignOption === 'status' ? 'select' : 'search'}
                  onSearch={search => {
                    setAssignSearchOptions([]);
                    handleSearch(search);
                  }}
                  onSelect={(vehicleId, vehiclePlate) =>
                    handleAssign(vehicleId, vehiclePlate)
                  }
                  onClose={() => {
                    setAssignOption('');
                    setAssignSearchOptions([]);
                  }}
                  selectOptions={assignSelectOptions}
                  searchOptions={assignSearchOptions}
                />
              )}

              <DataTable
                headers={headers}
                data={routes}
                selectedRows={selectedRows}
                onSelectRow={rows => setSelectedRows(rows)}
                onChangePage={selectedPage => setPage(selectedPage)}
                totalPages={pageData.totalPages}
                totalItems={pageData.totalItems}
                pageSize={pageData.pageSize}
                page={page}
                loading={loadingRoutes}
                selectable
              />

              <Drawer
                anchor='right'
                open={openEdit}
                onClose={() => {
                  setOpenEdit(false);
                }}
              >
                <DetailRoute
                  data={editData}
                  onClose={() => {
                    setOpenEdit(false);
                  }}
                />
              </Drawer>
            </FlexContainer>
          )}
        </FlexContainer>
      )}
    </>
  );
};

export default OrderList;
