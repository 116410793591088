import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import THEME from 'util/styledTheme';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ErrorsType, ErrorType } from './types';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableElement: {
    border: "none"
  }
});

function createData(
  name: string,
) {
  return {
    name,
    history: [
      { date: '2020-01-05', customerId: '11091700' },
      { date: '2020-01-02', customerId: 'Anonymous'},
    ],
  };
}

const Row: React.FC<ErrorType> = ({ row, errors}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align='center' style={{color: THEME.colors.error}}>
          <IconButton style={{marginLeft: '10px'}} aria-label="expand row" size="medium" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
          </IconButton>
          <span style={{fontSize: '20px', verticalAlign: 'middle'}}>Fila {row}</span>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>Columna</TableCell>
                    <TableCell align='center'>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(errors).map((error) => (
                    <TableRow key={error}>
                      <TableCell align='center' className={classes.tableElement} padding='default' component="th" scope="row">
                        {error}
                      </TableCell>
                      <TableCell align='center' className={classes.tableElement}>{errors[error]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const CollapsibleTable: React.FC<ErrorsType> = ({ errors }) => {
  const classes = useRowStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.tableElement} aria-label="collapsible table">
        <TableBody>
          {errors.map(error => (
            <Row key={error.row} row={error.row} errors={error.errors} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;
