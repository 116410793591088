import React from 'react';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { FlexContainer, Typography, Icon } from 'components/atoms';
import { UploadProps } from './types';

const DropBox = styled(FlexContainer)`
  border: 1px dashed ${props => props.theme.colors.primary};
  background-color: #fff;
  cursor: pointer;
`;

const Upload: React.FC<UploadProps> = ({ onDrop, loading }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.xlsx, .xls',
  });

  return (
    <FlexContainer container direction='column'>
      <FlexContainer
        container
        direction='column'
        alignItems='center'
        margin='30px 0 20px'
      >
        <div {...getRootProps()} style={{ width: '100%' }}>
          <input {...getInputProps()} />
          <DropBox
            container
            direction='column'
            height='475px'
            justify='center'
            alignItems='center'
            borderRadius='4px'
          >
            <Icon icon='upload-order-icon' size='70px' />
            <FlexContainer
              maxWidth='450px'
              justify='center'
              direction='column'
              margin='30px 0 0 0'
            >
              <Typography fontSize={14} textAlign='center' margin='0 0 15px'>
                Selecciona el archivo desde tu computadora o arrástralo hacia
                aquí.
              </Typography>
              <Typography
                fontSize={12}
                textAlign='center'
                color='text.secondary'
              >
                El archivo debe ser cargado con las siguientes extensiones:
                extensiones: *.xlsx.
              </Typography>
            </FlexContainer>
          </DropBox>
        </div>
        <Typography
          fontSize={12}
          textAlign='center'
          margin='20px 0 0'
          color='text.secondary'
        >
          El formato de ejemplo lo puedes descargar{' '}
          <a
            href='https://aimo-utils.s3.amazonaws.com/sample-full-products.xlsx'
            download
          >
            aqui
          </a>
        </Typography>
      </FlexContainer>

      {loading && <LinearProgress />}
    </FlexContainer>
  );
};

export default Upload;
