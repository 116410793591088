import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FlexContainer, Typography } from 'components/atoms';
import RocketImage from 'assets/images/signup/RocketImage.png';

const CustomLink = styled.a`
  font-size: 13px;
  margin: 0 0 0 5px;
`;

const List = styled.ul`
  li {
    font-size: 13px;
    color: #8c8cb1;
    margin: 0 0 5px;
  }
`;

const ThirdStep = () => {
  return (
    <FlexContainer
      container
      height='100%'
      alignItems='center'
      justify='center'
      direction='column'
    >
      <FlexContainer margin='auto 0 0' />
      <FlexContainer
        width='60%'
        direction='column'
        maxWidth='420px'
        justify='center'
        alignItems='center'
      >
        <img src={RocketImage} alt='congrats-rocket' height='210px' />
        <Typography
          color='text.primary'
          fontWeight='700'
          fontSize='22px'
          margin='40px 0 5px'
        >
          ¡Revisa tu bandeja de correo!
        </Typography>
        <Typography
          color='text.secondary'
          fontSize='15px'
          margin='0 0 40px'
          textAlign='center'
        >
          Acabamos de enviarte un mensaje al correo para que actives tu cuenta.
        </Typography>
      </FlexContainer>
      <FlexContainer width='70%' direction='column' maxWidth='550px'>
        <Typography
          color='text.primary'
          fontWeight='700'
          fontSize='14px'
          margin='0 0 5px'
        >
          ¿No te llegó el correo?
        </Typography>
        <Typography
          color='text.secondary'
          fontSize='13px'
          margin='0'
          textAlign='center'
        >
          Si no ves un correo en los próximos 2 minutos, puede ser por una de
          las siguientes razones:
        </Typography>
        <List>
          <li>El correo esta en tu bandeja de promociones.</li>
          <li>
            El correo que registraste tuvo un error con alguna letra o núme
          </li>
          <li>Tu bandeja tiene un filtro de firewall</li>
        </List>
        <FlexContainer alignItems='center'>
          <Typography color='text.secondary' fontSize='13px' margin='0'>
            No te preocupes, te lo volvemos a enviar solo
            <CustomLink href='http://app.aimo.co/sign-up'>
              reescribe tu correo
            </CustomLink>
          </Typography>
        </FlexContainer>
      </FlexContainer>
      <Typography fontSize='12px' margin='auto 0 30px'>
        © Aimo Privacidad y condiciones
      </Typography>
    </FlexContainer>
  );
};

export default ThirdStep;
