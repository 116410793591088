import { useCallback, useState } from 'react';
import { omit } from 'lodash';

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,9})+$/;
// TODO: phone validation
// const phoneRegex= '';
const mandatoryRegex = /^(?!\s*$).+/;
const numberRegex = /^[1-9]+[0-9]*$/;
const positiveRegex = /^[0-9]+[0-9]*$/;
const positiveFloatRegex = /^([1-9]+[0-9]*(\.[0-9]+)?)|(0\.(0*)[1-9]+[0-9]*)$/;
const identityDocumentRegex = /^[a-zA-Z0-9.]{8,15}$/;
const priceRegex = /^\d+([,.]\d{0,2})?$/;
const storeRegex = /^[a-z0-9]{1,25}$/;
const descriptionRegex = /^.{1,60}$/;
const postalCodeRegex = /^.{4,5}$/;

type ValidationType = {
  [key: string]: Array<string>;
};

const useForm = (initialState, validationState: ValidationType) => {
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [lastIndex, setLastIndex] = useState<number>(
    Array.isArray(form) ? form[form.length - 1].formId : 0
  );

  const onChange = (value: any, name: string, id?: number) => {
    if (Array.isArray(form)) {
      setForm(
        form.map(item => {
          if (item.formId === id) {
            return {
              ...item,
              [name]: value,
            };
          }
          return item;
        })
      );
      setErrors({
        ...errors,
        [id]: {
          ...errors[id],
          [name]: '',
        },
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: '',
      });
    }
  };

  const updateForm = values => {
    if (values.formId) {
      setForm(
        form.map(singleForm => {
          if (singleForm.formId === values.formId) {
            return {
              ...singleForm,
              ...values,
            };
          }
          return singleForm;
        })
      );
    } else {
      setForm({
        ...form,
        ...values,
      });
    }
  };

  const validate = () => {
    let isValid = true;
    let newErrors = {};

    if (Array.isArray(form)) {
      form.forEach(singleForm => {
        Object.keys(singleForm).forEach(key => {
          const validations = validationState[key];
          if (validations) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < validations.length; i++) {
              let validKey = true;
              switch (validations[i]) {
                case 'mandatory':
                  if (!mandatoryRegex.test(singleForm[key])) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Campo obligatorio',
                      },
                    };
                    validKey = false;
                  }
                  break;
                case 'email':
                  if (singleForm[key] && !emailRegex.test(singleForm[key])) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Email inválido',
                      },
                    };
                    validKey = false;
                  }
                  break;
                case 'store':
                  if (singleForm[key] && !storeRegex.test(singleForm[key])) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Solo minúsculas y máximo 25 caracteres',
                      },
                    };
                    validKey = false;
                  }
                  break;
                case 'number':
                  if (singleForm[key] && !numberRegex.test(singleForm[key])) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Cantidad inválida',
                      },
                    };
                    validKey = false;
                  }
                  break;
                case 'positive':
                  if (singleForm[key] && !positiveRegex.test(singleForm[key])) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Cantidad inválida',
                      },
                    };
                    validKey = false;
                  }
                  break;
                case 'priceFloat':
                  if (singleForm[key] && !priceRegex.test(singleForm[key])) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Cantidad inválida',
                      },
                    };
                    validKey = false;
                  }
                  break;
                case 'positiveFloat':
                  if (
                    singleForm[key] &&
                    !positiveFloatRegex.test(singleForm[key])
                  ) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Número inválido',
                      },
                    };
                    validKey = false;
                  }
                  break;
                case 'passwordValidation':
                  if (
                    singleForm[key] &&
                    singleForm[key] !== singleForm.password
                  ) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Contraseñas no coinciden',
                      },
                    };
                    validKey = false;
                  }
                  break;
                case 'validLocation':
                  if (
                    singleForm[key] &&
                    !(singleForm.latitude || singleForm.longitude)
                  ) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Dirección no válida.',
                      },
                    };
                    validKey = false;
                  }
                  break;
                case 'validAdress':
                  if (
                    !mandatoryRegex.test(singleForm[key]) &&
                    !singleForm.postal_code
                  ) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Campo obligatorio.',
                      },
                    };
                    validKey = false;
                  }
                  break;
                case 'identityDocument':
                  if (
                    singleForm[key] &&
                    !identityDocumentRegex.test(singleForm[key])
                  ) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Documento inválido',
                      },
                    };
                    validKey = false;
                  }
                  break;
                case 'description':
                  if (
                    singleForm[key] &&
                    !descriptionRegex.test(singleForm[key])
                  ) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Descripción inválida',
                      },
                    };
                    validKey = false;
                  }
                  break;
                case 'postalCode':
                  if (
                    singleForm[key] &&
                    !postalCodeRegex.test(singleForm[key])
                  ) {
                    newErrors = {
                      ...newErrors,
                      [singleForm.formId]: {
                        ...newErrors[singleForm.formId],
                        [key]: 'Minimo de 4 a 5 caracteres',
                      },
                    };
                    validKey = false;
                  }
                  break;
                default:
                  break;
              }

              if (!validKey) {
                isValid = false;
                break;
              }
            }
          }
        });
      });
    } else {
      Object.keys(form).forEach(key => {
        const validations = validationState[key];
        if (validations) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < validations.length; i++) {
            let validKey = true;
            switch (validations[i]) {
              case 'mandatory':
                if (!mandatoryRegex.test(form[key])) {
                  newErrors = {
                    ...newErrors,
                    [key]: 'Campo obligatorio',
                  };
                  validKey = false;
                }
                break;
              case 'email':
                if (form[key] && !emailRegex.test(form[key])) {
                  newErrors = {
                    ...newErrors,
                    [key]: 'Email inválido',
                  };
                  validKey = false;
                }
                break;
              case 'store':
                if (form[key] && !storeRegex.test(form[key])) {
                  newErrors = {
                    ...newErrors,
                    [key]: 'Solo minúsculas y máximo 25 caracteres',
                  };
                  validKey = false;
                }
                break;
              case 'number':
                if (form[key] && !numberRegex.test(form[key])) {
                  newErrors = {
                    ...newErrors,
                    [key]: 'Cantidad inválida',
                  };
                  validKey = false;
                }
                break;
              case 'positive':
                if (form[key] && !positiveRegex.test(form[key])) {
                  newErrors = {
                    ...newErrors,
                    [key]: 'Cantidad inválida',
                  };
                  validKey = false;
                }
                break;
              case 'positiveFloat':
                if (form[key] && !positiveFloatRegex.test(form[key])) {
                  newErrors = {
                    ...newErrors,
                    [key]: 'Número inválido',
                  };
                  validKey = false;
                }
                break;
              case 'priceFloat':
                if (form[key] && !priceRegex.test(form[key])) {
                  newErrors = {
                    ...newErrors,
                    [key]: 'Máximo 2 decimales',
                  };
                  validKey = false;
                }
                break;
              case 'passwordValidation':
                if (
                  (form[key] || form.password) &&
                  form[key] !== form.password
                ) {
                  newErrors = {
                    ...newErrors,
                    [key]: 'Contraseñas no coinciden',
                  };
                  validKey = false;
                }
                break;
              case 'identityDocument':
                if (form[key] && !identityDocumentRegex.test(form[key])) {
                  newErrors = {
                    ...newErrors,
                    [key]: 'Documento inválido',
                  };
                  validKey = false;
                }
                break;
              case 'description':
                if (form[key] && !descriptionRegex.test(form[key])) {
                  newErrors = {
                    ...newErrors,
                    [key]: 'Máximo 60 caracteres',
                  };
                  validKey = false;
                }
                break;
              case 'postalCode':
                if (form[key] && !postalCodeRegex.test(form[key])) {
                  newErrors = {
                    ...newErrors,
                    [key]: 'Minimo de 4 a 5 caracteres',
                  };
                  validKey = false;
                }
                break;
              default:
                break;
            }

            if (!validKey) {
              isValid = false;
              break;
            }
          }
        }
      });
    }
    setErrors(newErrors);
    return isValid;
  };

  const addForm = values => {
    setForm(
      form.concat(
        values
          ? {
              ...values,
            }
          : {
              ...initialState[0],
              formId: lastIndex + 1,
            }
      )
    );
    setLastIndex(values ? values.formId + 1 : lastIndex + 1);
  };

  const deleteForm = (id: number) => {
    setForm(form.filter(singleForm => singleForm.formId !== id));
    setErrors(omit(errors, [id]));
  };

  const reset = useCallback(() => {
    setForm(initialState);
    setErrors({});
  }, [setForm, setErrors]);

  return [
    form,
    onChange,
    validate,
    errors,
    reset,
    updateForm,
    addForm,
    deleteForm,
  ];
};

export default useForm;
