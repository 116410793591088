import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { FlexContainer, Icon, Typography } from 'components/atoms';
import { FormProps } from './types';

const Card = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.borderColor};
`;

const Header = styled(FlexContainer)`
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
`;

const Collapse = styled(FlexContainer)`
  transition: all 0.5s;
  overflow: hidden;
`;

const Content = styled(FlexContainer)`
  overflow: hidden;
  transition: all 0.5s;
`;

const FormCard: React.FC<FormProps> = () => {
  const [open, setOpen] = useState<boolean>(true);
  return (
    <Card>
      <Header
        container
        alignItems='center'
        justify='space-between'
        padding='10px 40px'
      >
        <FlexContainer alignItems='center'>
          <Typography fontSize={16}>Recojo</Typography>
        </FlexContainer>

        <IconButton onClick={() => setOpen(!open)}>
          <Icon icon='arrow-icon' />
        </IconButton>
      </Header>
      <Collapse container height={open ? '400px' : '0'}>
        <Content container direction='column' padding='20px 40px'>
          Form content
        </Content>
      </Collapse>
    </Card>
  );
};

export default FormCard;
