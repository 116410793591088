import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List';

const Orders: React.FC = () => (
  <Switch>
    <Route exact path='/ordenes' component={List} />
  </Switch>
);

export default Orders;
