import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import Flag from 'assets/images/markers/Flag.svg';
import FlagColor from 'assets/images/markers/FlagColor.svg';
import Pointer from 'assets/images/markers/Marker.svg';
import OriginMarker from 'assets/images/markers/OriginMarker.svg';
import Worker from 'assets/images/markers/Worker.svg';
import CompletedTask from 'assets/images/markers/CompletedTask.svg';
import FailedTask from 'assets/images/markers/FailedTask.svg';
import Task from 'assets/images/markers/Task.svg';
import Truck from 'assets/images/markers/Truck.png';
import Build from 'assets/images/markers/Build.png';
import { MarkerProps } from './types';

const flag = new L.Icon({
  iconUrl: Flag,
  iconSize: [30, 30],
  iconAnchor: [0, 30],
});

const colorFlag = new L.Icon({
  iconUrl: FlagColor,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const pointer = new L.Icon({
  iconUrl: Pointer,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const originMarker = new L.Icon({
  iconUrl: OriginMarker,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const worker = new L.Icon({
  iconUrl: Worker,
  iconSize: [40, 40],
  iconAnchor: [0, 30],
});

const truck = new L.Icon({
  iconUrl: Truck,
  iconSize: [40, 40],
  iconAnchor: [0, 30],
});

const build = new L.Icon({
  iconUrl: Build,
  iconSize: [40, 40],
  iconAnchor: [0, 30],
});

const failed = new L.Icon({
  iconUrl: FailedTask,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const completed = new L.Icon({
  iconUrl: CompletedTask,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const task = new L.Icon({
  iconUrl: Task,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
});

const defaultIcon = new L.Icon.Default();

const CustomMarker: React.FC<MarkerProps> = ({
  position,
  icon,
  children,
  draggable,
  onDragEnd,
  onDragStart,
  onClick,
  openTooltip,
}) => {
  const markerRef = useRef<any>();
  let selectedIcon;
  switch (icon) {
    case 'flag':
      selectedIcon = flag;
      break;
    case 'colorFlag':
      selectedIcon = colorFlag;
      break;
    case 'pointer':
      selectedIcon = pointer;
      break;
    case 'origin':
      selectedIcon = originMarker;
      break;
    case 'worker':
      selectedIcon = worker;
      break;
    case 'completed':
      selectedIcon = completed;
      break;
    case 'failed':
      selectedIcon = failed;
      break;
    case 'task':
      selectedIcon = task;
      break;
    case 'truck':
      selectedIcon = truck;
      break;
    case 'build':
      selectedIcon = build;
      break;
    default:
      selectedIcon = defaultIcon;
      break;
  }

  useEffect(() => {
    if (openTooltip && markerRef.current) {
      markerRef.current.leafletElement.openPopup();
    }
  }, [openTooltip]);

  return (
    <Marker
      ref={markerRef}
      position={position}
      icon={selectedIcon}
      draggable={draggable}
      onDragend={e => onDragEnd(e)}
      onDragstart={() => {
        if (onDragStart) {
          onDragStart();
        }
      }}
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {children}
    </Marker>
  );
};

export default CustomMarker;
