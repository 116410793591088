import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import useRequest from 'hooks/useRequest';
import useForm from 'hooks/useForm';
import { Grid } from '@material-ui/core';
import {
  Button,
  Divider,
  FlexContainer,
  Icon,
  Switch,
  Typography,
} from 'components/atoms';
import THEME from 'util/styledTheme';
import { patchOrderProtocol } from 'services';
import { FormControl, Tabs } from 'components/molecules';
import { EditProps, FieldRowProps, TabProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const validationState = {
  name: ['mandatory'],
  description: ['mandatory'],
};

const configurationValidationState = {
  description: ['mandatory'],
};

const tabOptions = [
  {
    label: 'Recojo',
    value: '1',
  },
  {
    label: 'Entrega',
    value: '2',
  },
];

const FieldRow: React.FC<FieldRowProps> = ({
  data,
  onChange,
  loading,
  errors,
}) => (
  <FlexContainer container margin='10px 0 30px' alignItems='center'>
    <Grid container spacing={2} alignItems='center'>
      <Grid item xs={6}>
        <FormControl
          placeholder='Escribe la etiqueta'
          onChange={value => onChange(value, 'description')}
          value={data.description}
          error={errors.description}
          width='80%'
          disabled={loading}
        />
      </Grid>
      <Grid item xs={2}>
        <FlexContainer container justify='center'>
          <Switch
            checked={data.isActive}
            onChange={event => onChange(event.target.checked, 'isActive')}
          />
        </FlexContainer>
      </Grid>
      <Grid item xs={2}>
        <FlexContainer container justify='center'>
          <Switch
            checked={data.isRequired}
            onChange={event => onChange(event.target.checked, 'isRequired')}
          />
        </FlexContainer>
      </Grid>
      <Grid item xs={2}>
        <FlexContainer container justify='center'>
          <Switch
            checked={data.showTracking}
            onChange={event => onChange(event.target.checked, 'showTracking')}
          />
        </FlexContainer>
      </Grid>
    </Grid>
  </FlexContainer>
);

const TabContent: React.FC<TabProps> = ({
  forms,
  onFormChange,
  errors,
  loading,
}) => (
  <FlexContainer container direction='column' margin='20px 0 0'>
    <FlexContainer container direction='column' margin='20px 0'>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          Completado
        </Grid>
        <Grid item xs={2}>
          <Typography color='text.secondary' textAlign='center'>
            Activo
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color='text.secondary' textAlign='center'>
            Requerido
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color='text.secondary' textAlign='center'>
            Mostrar en el Tracking
          </Typography>
        </Grid>
      </Grid>
    </FlexContainer>
    {forms
      .filter(item => item.status && item.status.code === 'completed')
      .map(pickForm => (
        <FieldRow
          key={pickForm.id}
          data={pickForm}
          onChange={(value, field) =>
            onFormChange(value, field, pickForm.formId)
          }
          loading={loading || pickForm.inputType !== 'image'}
          errors={errors[pickForm.formId] || {}}
        />
      ))}
    <FlexContainer container direction='column' margin='20px 0'>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          Fallido
        </Grid>
        <Grid item xs={2}>
          <Typography color='text.secondary' textAlign='center'>
            Activo
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color='text.secondary' textAlign='center'>
            Requerido
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography color='text.secondary' textAlign='center'>
            Mostrar en el Tracking
          </Typography>
        </Grid>
      </Grid>
    </FlexContainer>
    {forms
      .filter(item => item.status && item.status.code === 'failed')
      .map(pickForm => (
        <FieldRow
          key={pickForm.id}
          data={pickForm}
          onChange={(value, field) =>
            onFormChange(value, field, pickForm.formId)
          }
          loading={loading}
          errors={errors[pickForm.formId] || {}}
        />
      ))}
  </FlexContainer>
);

const getForms = (key, data) => {
  if (data && data.field_configurations) {
    return data.field_configurations
      .filter(field => field.task_type && field.task_type.code === key)
      .map(filteredField => ({
        formId: filteredField.id,
        id: filteredField.id,
        status: filteredField.status,
        description: filteredField.description,
        isActive: filteredField.is_active,
        isRequired: filteredField.is_required,
        showTracking: filteredField.show_in_tracking,
        inputType: filteredField.input_type,
      }));
  }
  return [];
};

const EditProtocol: React.FC<EditProps> = ({ data, onCreate, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors] = useForm(
    {
      name: data ? data.name : '',
      description: data ? data.description : '',
      isActive: data ? data.is_active : true,
    },
    validationState
  );
  const [pickUpForms, onPickUpChange, validatePickUp, pickUpErrors] = useForm(
    useCallback(() => getForms('pickup', data), [getForms]),
    configurationValidationState
  );
  const [
    dropOffForms,
    onDropOffChange,
    validateDropOff,
    dropOffErrors,
  ] = useForm(
    useCallback(() => getForms('dropoff', data), [getForms]),
    configurationValidationState
  );

  // API calls
  const [updatedProtocol, updateProtocol, loadingUpdate] = useRequest(
    patchOrderProtocol
  );
  // Values
  const [selectedTab, setSelectedTab] = useState<string>('1');

  useEffect(() => {
    if (!isEmpty(updatedProtocol)) {
      enqueueSnackbar('Se actualizó el protocolo correctamente.', {
        variant: 'success',
      });
      onCreate();
    }
  }, [updatedProtocol, enqueueSnackbar, onCreate]);

  const validateForm = () => {
    if (validate() && validatePickUp() && validateDropOff()) {
      const pickUps = pickUpForms.map(pickUpForm => ({
        id: pickUpForm.id,
        description: pickUpForm.description,
        is_active: pickUpForm.isActive,
        is_required: pickUpForm.isRequired,
        show_in_tracking: pickUpForm.showTracking,
      }));
      const dropOffs = dropOffForms.map(pickUpForm => ({
        id: pickUpForm.id,
        description: pickUpForm.description,
        is_active: pickUpForm.isActive,
        is_required: pickUpForm.isRequired,
        show_in_tracking: pickUpForm.showTracking,
      }));
      const body = {
        name: form.name,
        description: form.description,
        is_active: form.isActive,
        field_configurations: [...pickUps, ...dropOffs],
      };

      updateProtocol(body, data.id);
    }
  };

  return (
    <FlexContainer
      container
      minWidth='800px'
      position='relative'
      padding='40px 50px 50px'
      direction='column'
    >
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <Typography fontSize={18} margin='10px 0 0'>
        Protocolo Digital
      </Typography>
      <Divider orientation='horizontal' margin='15px 0 30px' />

      <FlexContainer
        container
        direction='column'
        padding='30px'
        margin='0 0 30px'
        borderRadius='10px'
        borderColor={THEME.colors.borderColor}
        backgroundColor={THEME.colors.backgroundColor}
      >
        <FlexContainer container direction='column'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FlexContainer alignItems='center' margin='0 0 20px 0'>
                <Typography fontSize={12}>Activo:</Typography>
                <Switch
                  checked={form.isActive}
                  onChange={event =>
                    onFormChange(event.target.checked, 'isActive')
                  }
                />
              </FlexContainer>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Nombre'
                placeholder='Escribe el nombre'
                onChange={value => onFormChange(value, 'name')}
                value={form.name}
                margin='0 0 20px'
                error={errors.name}
                width='100%'
                disabled={loadingUpdate}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                control='textarea'
                label='Descripción'
                placeholder='Escribe la descripción'
                onChange={value => onFormChange(value, 'description')}
                value={form.description}
                margin='0 0 40px'
                error={errors.description}
                width='100%'
                disabled={loadingUpdate}
              />
            </Grid>
          </Grid>

          <Tabs
            selected={selectedTab}
            tabs={tabOptions}
            onChange={value => setSelectedTab(value)}
            tabHeight='40px'
          />
          {selectedTab === '1' && (
            <TabContent
              forms={pickUpForms}
              errors={pickUpErrors}
              onFormChange={onPickUpChange}
              loading={loadingUpdate}
            />
          )}
          {selectedTab === '2' && (
            <TabContent
              forms={dropOffForms}
              errors={dropOffErrors}
              onFormChange={onDropOffChange}
              loading={loadingUpdate}
            />
          )}
        </FlexContainer>

        <Button
          variant='contained'
          color='primary'
          padding='5px 30px'
          onClick={() => validateForm()}
        >
          Guardar
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default EditProtocol;
