import React, { useState } from 'react';
import styled from 'styled-components';
import { useTypedSelector } from 'store';
import { FlexContainer, Typography } from 'components/atoms';
import Organization from './Organization';
import Users from './Users';
import Subscription from './Subscription';
import Invoices from './Invoices';
import DigitalCatalogue from './DigitalCatalogue';

type ViewType =
  | 'organization'
  | 'team'
  | 'suscription'
  | 'billing'
  | 'catalogue';

type TabProps = {
  view: ViewType;
  onClick: (view: ViewType) => void;
};

const list = [
  {
    name: 'Organización',
    value: 'organization' as const,
    permision: 'core.organization.retrieve',
  },
  {
    name: 'Equipo',
    value: 'team' as const,
    permision: 'core.administrator.list',
  },
  {
    name: 'Suscripción',
    value: 'suscription' as const,
  },
  {
    name: 'Facturación',
    value: 'billing' as const,
  },
  {
    name: 'Catálogo Digital',
    value: 'catalogue' as const,
  },
];

const Option = styled(FlexContainer)<{ active: boolean }>`
  text-decoration: none;
  cursor: pointer;
  ${props =>
    props.active && `border-bottom: 2px solid ${props.theme.colors.primary}`}
`;

const YourAccountNavigation: React.FC<TabProps> = ({ view, onClick }) => {
  const user = useTypedSelector(state => state.user);

  return (
    <FlexContainer
      container
      height='100%'
      justify='space-between'
      alignItems='center'
    >
      <FlexContainer alignItems='center'>
        {list.map(option => {
          if (
            !option.permision ||
            user.permissions.includes(option.permision)
          ) {
            return (
              <FlexContainer
                key={option.name}
                padding='0 30px 0 0'
                onClick={() => onClick(option.value)}
              >
                <Option
                  alignItems='center'
                  padding='14px 0'
                  active={view === option.value}
                >
                  <Typography>{option.name}</Typography>
                </Option>
              </FlexContainer>
            );
          }
          return null;
        })}
      </FlexContainer>
    </FlexContainer>
  );
};

const YourAccount: React.FC = () => {
  // Values
  const [view, setView] = useState<ViewType>('organization');

  return (
    <FlexContainer container direction='column' padding='30px 40px'>
      <Typography fontSize={19} fontWeight={600} margin='0 0 10px'>
        Tu cuenta
      </Typography>
      <YourAccountNavigation
        view={view}
        onClick={newView => setView(newView)}
      />
      <FlexContainer container>
        {view === 'organization' && <Organization />}
        {view === 'team' && <Users />}
        {view === 'suscription' && <Subscription />}
        {view === 'billing' && <Invoices />}
        {view === 'catalogue' && <DigitalCatalogue />}
      </FlexContainer>
    </FlexContainer>
  );
};

export default YourAccount;
