import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { FormControl, Spinner } from 'components/molecules';
import { postProductCategory, patchProductCategory } from 'services';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import { CreateProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const validationState = {
  name: ['mandatory'],
};

const CreateCategory: React.FC<CreateProps> = ({
  onClose,
  onCreate,
  data,
  clientId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors, reset] = useForm(
    {
      name: data ? data.name : '',
    },
    validationState
  );

  // Values
  const [isEdit, setIsEdit] = useState(false);

  // API calls
  const [createdCategory, createCategory, loadingCreate] = useRequest(
    postProductCategory
  );
  const [updatedCategory, updateCategory, loadingUpdate] = useRequest(
    patchProductCategory
  );

  useEffect(() => {
    if (!isEmpty(createdCategory)) {
      enqueueSnackbar('La categoría fue creada correctamente.', {
        variant: 'success',
      });
      onCreate();
      reset();
    }
  }, [createdCategory]);

  useEffect(() => {
    if (!isEmpty(updatedCategory)) {
      enqueueSnackbar('La categoría se actualizó correctamente.', {
        variant: 'success',
      });
      onCreate();
    }
  }, [updatedCategory]);

  useEffect(() => {
    if (!isEmpty(data)) {
      setIsEdit(true);
    }
  }, [data]);

  const validateForm = () => {
    if (validate()) {
      const body = {
        name: form.name,
        client_code: clientId,
      };

      if (data) {
        updateCategory(body, data._id);
      } else {
        createCategory(body);
      }
    }
  };

  return (
    <FlexContainer container direction='column' padding='20px 40px'>
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <FlexContainer container justify='center' margin='20px 0 40px'>
        <Typography color={THEME.colors.primary} fontSize={18}>
          {isEdit ? 'Editar Categoría' : 'Crear Categoría'}
        </Typography>
      </FlexContainer>

      <FlexContainer container direction='column'>
        <FormControl
          label='Nombre de la categoría'
          placeholder='Escribe el nombre'
          onChange={value => onFormChange(value, 'name')}
          value={form.name}
          margin='0 0 20px'
          error={errors.name}
          width='100%'
          disabled={loadingCreate}
        />
        <FlexContainer container justify='center' margin='10px 0 20px'>
          <Button
            variant='contained'
            color='primary'
            margin='0'
            onClick={() => validateForm()}
          >
            {(loadingCreate || loadingUpdate) && (
              <Spinner
                height='15px'
                spinnerSize={20}
                colorSecondary
                margin='0 8px 0 0'
              />
            )}
            {isEdit ? 'Guardar' : 'Crear'}
          </Button>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default CreateCategory;
