const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

interface ToggleMenu {
  type: typeof TOGGLE_SIDEBAR;
  payload: boolean;
}

export type SidebarActionTypes = ToggleMenu;

export default TOGGLE_SIDEBAR;
