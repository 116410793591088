import React from 'react';
import { Card, FlexContainer, Typography } from 'components/atoms';
import MyProviders from './MyProviders';

const Providers: React.FC = () => {
  return (
    <FlexContainer container height='100%' justify='center' padding='30px'>
      <FlexContainer width='100%' maxWidth='980px' height='100%'>
        <Card width='100%' height='100%' padding='40px 80px'>
          <Typography fontSize={18} textAlign='center' margin='0 0 40px'>
            Mis proveedores
          </Typography>
          <MyProviders />
        </Card>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Providers;
