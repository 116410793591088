import React from 'react';
import { Icon, Input } from 'components/atoms';
import { InputAdornment } from '@material-ui/core';
import { SearchProps } from './types';

const Search: React.FC<SearchProps> = ({
  onChangeValue,
  searchValue,
  onSubmit,
  maxWidth,
  width,
  height,
  placeholder,
  autofocus = false,
}) => (
  <Input
    variant='outlined'
    color='primary'
    placeholder={placeholder}
    value={searchValue}
    onChange={({ target }) => onChangeValue(target.value)}
    width={width || '100%'}
    height={height}
    maxWidth={maxWidth}
    style={{ padding: '0' }}
    onKeyUp={event => {
      if (event.keyCode === 13) {
        onSubmit();
      }
    }}
    autoFocus={autofocus}
    InputProps={{
      startAdornment: (
        <InputAdornment position='start'>
          <Icon icon='search-icon1' size={19} onClick={() => onSubmit()} />
        </InputAdornment>
      ),
    }}
  />
);

export default Search;
