import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { Dialog } from '@material-ui/core';
import { Button, FlexContainer, Typography, Icon } from 'components/atoms';
import { DataTable } from 'components/organisms';
import moment from 'moment';
import { getTokens, deleteTokens } from 'services';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import CreateTokens from './Create';

const TokensList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  // API calls
  const [tokens, fetchTokens, loadingTokens, pageData] = useRequest(
    getTokens,
    []
  );
  const [, tokenDelete, , , , statusRequest] = useRequest(deleteTokens, null);

  // Values
  const [page, setPage] = useState<number>(1);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [tokenId, setTokenId] = useState(null);
  const [tokenList, setTokenList] = useState([]);
  const [tokenDeleted, setTokenDeleted] = useState(null);

  const requestTokens = useCallback(() => {
    const params = {
      page,
    };

    fetchTokens(params);
  }, [fetchTokens, page]);

  useEffect(() => {
    fetchTokens();
  }, [fetchTokens]);

  useEffect(() => {
    if (!isEmpty(tokens)) {
      setTokenList(tokens);
    }
  }, [tokens]);

  const handleDelete = id => {
    tokenDelete(tokenId);
    const tokenToDelete = tokenList.find(token => token.id === id);
    setTokenDeleted(tokenToDelete);
  };

  useEffect(() => {
    if (statusRequest === 204) {
      enqueueSnackbar('Se eliminó el token correctamente.', {
        variant: 'success',
      });
      const filteredList = tokenList.filter(
        token => token.id !== tokenDeleted.id
      );
      setTokenList(filteredList);
    } else if (statusRequest && statusRequest !== 204) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusRequest, enqueueSnackbar]);

  const headers = useMemo(
    () => [
      {
        label: 'Token',
        id: 'tokens',
        cell: row => row.access_token || '',
      },
      {
        label: 'Descripción',
        id: 'description',
        cell: row => row.description || '',
      },
      {
        label: 'Fecha de creación',
        id: 'created_at',
        cell: row => moment(row.created_at).format('DD/MM/YYYY') || '',
      },
      {
        label: 'Usuario',
        id: 'user',
        cell: row => `${row.user.first_name} ${row.user.last_name}` || '',
      },
      {
        label: '',
        id: 'delete',
        cell: row => (
          <Icon
            icon='delete-icon'
            size={20}
            onClick={() => {
              setOpenConfirm(true);
              setTokenId(row.id);
            }}
          />
        ),
      },
    ],
    []
  );

  return (
    <FlexContainer container margin='10px 20px'>
      <FlexContainer
        container
        // padding={user.onboarding ? '95px 0 0' : '50px 0 0'}
        height='100%'
      >
        <FlexContainer container padding='30px 40px' direction='column'>
          <FlexContainer
            container
            alignItems='center'
            justify='space-between'
            margin='0 0 30px'
          >
            <Typography fontSize={18} fontWeight={300}>
              Acceso API
            </Typography>

            <FlexContainer justify='flex-end'>
              <Button
                color='primary'
                margin='0 0 0 20px'
                fontSize='13px'
                startIcon={<Icon icon='plus-icon' size={18} />}
                onClick={() => setOpenCreate(true)}
              >
                Crear Token
              </Button>
              <Button
                variant='outlined'
                color='primary'
                margin='0 0 0 20px'
                fontSize='13px'
                startIcon={<Icon icon='book-icon' size={18} />}
                onClick={() => window.open('https://developers.aimo.co/')}
              >
                Lee la documentación
              </Button>
            </FlexContainer>
          </FlexContainer>

          <DataTable
            headers={headers}
            data={tokenList}
            onChangePage={selectedPage => setPage(selectedPage)}
            totalPages={pageData.totalPages}
            totalItems={pageData.totalItems}
            pageSize={pageData.pageSize}
            page={page}
            loading={loadingTokens}
          />

          <Dialog
            fullWidth
            maxWidth='sm'
            open={openCreate}
            onClose={() => setOpenCreate(false)}
          >
            <CreateTokens
              onCreate={useCallback(() => requestTokens(), [requestTokens])}
              onClose={() => setOpenCreate(false)}
            />
          </Dialog>

          <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
            <FlexContainer
              padding='30px'
              direction='column'
              alignItems='center'
              justify='center'
            >
              <Typography
                color={THEME.colors.primary}
                fontSize={15}
                margin='0 0 20px'
              >
                Borrar token
              </Typography>
              <Typography margin='0 0 30px' fontSize={13}>
                ¿Estás seguro de eliminar el token?
              </Typography>
              <FlexContainer
                container
                alignItems='center'
                justify='space-between'
              >
                <Button
                  variant='contained'
                  color='primary'
                  fontSize='13px'
                  onClick={() => {
                    handleDelete(tokenId);
                    setOpenConfirm(false);
                  }}
                >
                  Eliminar
                </Button>

                <Button fontSize='14px' onClick={() => setOpenConfirm(false)}>
                  Cancelar
                </Button>
              </FlexContainer>
            </FlexContainer>
          </Dialog>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default TokensList;
