import React, { useEffect, useState } from 'react';
import { isEmpty, find } from 'lodash';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { FormControl, MultipleSelect } from 'components/molecules';
import { useTypedSelector } from 'store';
import { postTimeWindows, getBranchOffices, patchTimeWindows } from 'services';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import { optionFormatter } from 'util/functions';
import THEME from 'util/styledTheme';
import { TimeWindowsCreationProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const validationState = {
  startHour: ['mandatory'],
  endHour: ['mandatory'],
};

const CreateTimeWindows: React.FC<TimeWindowsCreationProps> = ({
  onClose,
  onCreate,
  data,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedOffice = useTypedSelector(
    state => state.organization.selectedBranchOffice
  );
  const [form, onFormChange, validate, errors, reset] = useForm(
    {
      startHour: data ? data.start_hour : '',
      endHour: data ? data.end_hour : '',
    },
    validationState
  );

  // API calls
  const [createdTimeWindows, createTimeWindows, loadingCreate] = useRequest(
    postTimeWindows
  );
  const [branchOffices, fetchBranchOffices, loadingOffices] = useRequest(
    getBranchOffices,
    []
  );
  const [updatedTimeWindow, updateTimeWindow, loadingUpdate] = useRequest(
    patchTimeWindows
  );

  // Values
  const [branchOfficeOptions, setBranchOfficeOptions] = useState([]);
  const [selectedBranchOffices, setSelectedBranchOffices] = useState([]);

  useEffect(() => {
    fetchBranchOffices({
      page_size: 0,
    });
  }, [fetchBranchOffices]);

  useEffect(() => {
    if (branchOffices.length > 0) {
      setBranchOfficeOptions(optionFormatter(branchOffices));
    }
  }, [branchOffices]);

  useEffect(() => {
    if (!isEmpty(data)) {
      setSelectedBranchOffices(optionFormatter(data.branch_offices));
    }
  }, [data]);

  useEffect(() => {
    if (!data) {
      setSelectedBranchOffices(optionFormatter([selectedOffice]));
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(createdTimeWindows)) {
      enqueueSnackbar('La ventana horaria fue creada correctamente', {
        variant: 'success',
      });
      onCreate();
      reset();
      setSelectedBranchOffices([]);
    }
  }, [createdTimeWindows, enqueueSnackbar, onCreate, reset]);

  useEffect(() => {
    if (!isEmpty(updatedTimeWindow)) {
      enqueueSnackbar('Se actualizó la ventana horaria correctamente.', {
        variant: 'success',
      });
      onCreate();
    }
  }, [updatedTimeWindow, enqueueSnackbar, onCreate]);

  const validateForm = () => {
    if (validate()) {
      const body = {
        start_hour: form.startHour,
        end_hour: form.endHour,
        branch_offices: selectedBranchOffices.map(
          branchOffice => branchOffice.id
        ),
      };
      if (data) {
        updateTimeWindow(body, data.id);
      } else {
        createTimeWindows(body);
      }
    }
  };

  return (
    <FlexContainer container direction='column' padding='40px'>
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <FlexContainer container justify='center' margin='20px 0 30px'>
        <Typography color={THEME.colors.primary} fontSize={18}>
          Crear Ventana Horaria
        </Typography>
      </FlexContainer>

      <FlexContainer container direction='column'>
        <FormControl
          label='Hora de Inicio'
          placeholder='Escribe la hora de inicio'
          onChange={value => onFormChange(value, 'startHour')}
          value={form.startHour}
          margin='0 0 20px'
          error={errors.startHour}
          width='100%'
          disabled={loadingCreate || loadingUpdate}
          autofocus
        />

        <FormControl
          label='Hora de Fin'
          placeholder='Escribe la hora de fin'
          onChange={value => onFormChange(value, 'endHour')}
          value={form.endHour}
          margin='0 0 20px'
          error={errors.endHour}
          width='100%'
          disabled={loadingCreate || loadingUpdate}
        />

        <MultipleSelect
          label='Accesos a sucursales'
          placeholder='Seleccione las sucursales'
          options={branchOfficeOptions}
          values={selectedBranchOffices}
          onChange={option => {
            const found = find(selectedBranchOffices, {
              id: option.id,
            });
            if (found) {
              setSelectedBranchOffices(
                selectedBranchOffices.filter(item => item.id !== option.id)
              );
            } else {
              setSelectedBranchOffices(selectedBranchOffices.concat(option));
            }
          }}
          onDelete={option => {
            setSelectedBranchOffices(
              selectedBranchOffices.filter(item => item.id !== option.id)
            );
          }}
          margin='0 0 20px'
          width='100%'
          disabled={loadingCreate || loadingUpdate || loadingOffices}
        />
      </FlexContainer>

      <FlexContainer container justify='center' margin='30px 0 20px'>
        <Button
          variant='contained'
          color='primary'
          padding='5px 30px'
          onClick={() => validateForm()}
          disabled={loadingCreate}
        >
          {data ? 'Guardar' : 'Crear'}
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default CreateTimeWindows;
