import React from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { omit } from 'lodash';
import { InputProps } from './types';

const Input = styled(props => (
  <TextField {...omit(props, ['maxWidth', 'margin', 'borderless'])} />
))<InputProps>`
  margin: ${props => props.margin || '0'};
  background-color: #ffffff;
  ${props => props.width && `width: ${props.width};`}
  ${props =>
    props.maxWidth &&
    `max-width: ${props.maxWidth};`}
  .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: ${props => props.theme.colors.borderColor};
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.colors.borderColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: ${props => props.theme.colors.primary};
  }
  .MuiInputBase-root.Mui-disabled {
    background-color: ${props => props.theme.colors.borderColor};
    .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.colors.borderColor};
    }
  }
  input {
    font-size: ${props => props.fontSize || '13px'};
    padding: ${props => props.padding || 0};
    height: ${props => props.height || '40px'};
    ${props => props.width && `width: ${props.width};`}
    &::placeholder {
      color: ${props => props.theme.colors.placeholderColor};
      opacity: 1;
    }
  }
  .MuiOutlinedInput-multiline {
    padding: 10px 18px;
  }
  textarea {
    font-size: ${props => props.fontSize || '13px'};
    &::placeholder {
      color: ${props => props.theme.colors.placeholderColor};
      opacity: 1;
    }
  }
`;

export default Input;
