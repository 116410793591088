import React, { useState, useEffect } from 'react';
import { FlexContainer, Icon, Pill, Switch } from 'components/atoms';
import Minutes99 from 'assets/images/providers/99minutos.png';
import Glovo from 'assets/images/providers/glovo.svg';
import Olva from 'assets/images/providers/olva.jpg';
import Savar from 'assets/images/providers/savar.png';
import Chazki from 'assets/images/providers/chazki.png';
import Moova from 'assets/images/providers/moova.png';
import Cabify from 'assets/images/providers/cabify.png';
import PedidosYa from 'assets/images/providers/pedidosya.png';
import useRequest from 'hooks/useRequest';
import { getIntegration } from 'services';
import { RowProps } from './types';

const Row: React.FC<RowProps> = ({
  data,
  onClick,
  onActivate,
  onDeactivate,
}) => {
  // API calls
  const [integration, fetchIntegration] = useRequest(getIntegration);
  const [isResell, setIsResell] = useState<boolean>(true);

  useEffect(() => {
    fetchIntegration(data.code);
  }, [data]);

  useEffect(() => {
    setIsResell(integration.is_resell);
  }, [integration]);

  const pickImage = code => {
    switch (code) {
      case '99minutos':
        return Minutes99;
      case 'olva':
        return Olva;
      case 'glovo':
        return Glovo;
      case 'savar':
        return Savar;
      case 'chazki':
        return Chazki;
      case 'moova':
        return Moova;
      case 'cabify':
        return Cabify;
      case 'pedidosya':
        return PedidosYa;
      default:
        return '';
    }
  };
  return (
    <FlexContainer
      container
      alignItems='center'
      justify='space-between'
      padding='50px 0'
    >
      <FlexContainer width='20%'>
        <img
          src={pickImage(data.code)}
          alt='prov_image'
          style={{
            width: '90%',
            height: 'auto',
          }}
        />
      </FlexContainer>
      <FlexContainer width='50%' padding='0 20px'>
        {data.tags.map(tag => (
          <Pill key={tag} margin='2px'>
            {tag}
          </Pill>
        ))}
      </FlexContainer>
      <FlexContainer width='10%'>
        <Switch
          checked={!!(isResell && data.is_active)}
          onChange={event => {
            if (!event.target.checked) {
              onDeactivate();
            } else {
              onActivate();
            }
          }}
        />
      </FlexContainer>
      <FlexContainer onClick={() => onClick()}>
        <Icon icon='info-icon' size={25} />
      </FlexContainer>
    </FlexContainer>
  );
};

export default Row;
