import React, { useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { Avatar } from '@material-ui/core';
import { FlexContainer, Typography, Icon } from 'components/atoms';
import { Search } from 'components/molecules';
import THEME from 'util/styledTheme';
import { SearchOptionContainer, Option } from '../AssignBox';
import { SearchWithResultsProps } from './types';

const SearchWithResults: React.FC<SearchWithResultsProps> = ({
  title,
  onSearch,
  onSelect,
  searchOptions,
  margin,
  placeholder,
}) => {
  const node = useRef(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  useOnClickOutside(node, () => setOpen(false));

  useEffect(() => {
    if (searchOptions.length > 0) {
      setOpen(true);
    }
  }, [searchOptions]);

  return (
    <FlexContainer
      container
      position='relative'
      direction='column'
      backgroundColor={THEME.colors.borderColor}
      margin={margin || '0 0 20px 0'}
      alignItems='center'
      justify='center'
    >
      {title && (
        <Typography
          fontSize={13}
          textAlign='center'
          fontWeight={600}
          margin='0 0 20px 0'
        >
          {title}
        </Typography>
      )}

      <FlexContainer
        ref={node}
        container
        direction='column'
        position='relative'
      >
        <Search
          onChangeValue={value => setSearchValue(value)}
          searchValue={searchValue}
          onSubmit={() => {
            setOpen(false);
            onSearch(searchValue);
          }}
          placeholder={placeholder}
        />
        {open && searchOptions.length > 0 && (
          <SearchOptionContainer
            container
            position='absolute'
            maxHeight='400px'
            direction='column'
          >
            {searchOptions.map(option => (
              <Option
                key={option.id}
                container
                alignItems='center'
                onClick={() => {
                  setOpen(false);
                  onSelect(option.id);
                  setSearchValue(option.name);
                }}
              >
                {option.photo ? (
                  <Avatar src={option.photo} alt='worker_photo' sizes='35' />
                ) : (
                  <Icon icon='person-icon' size='35' />
                )}
                <FlexContainer
                  container
                  margin='0 0 0 20px'
                  justify='space-between'
                  alignItems='center'
                >
                  <FlexContainer direction='column'>
                    <Typography margin='0 0 10px 0'>{option.name}</Typography>
                    <FlexContainer alignItems='center'>
                      <Icon icon='phone' size='20' margin='0 5px 0 0' />
                      <Typography>{option.phone}</Typography>
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
              </Option>
            ))}
          </SearchOptionContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default SearchWithResults;
