import React, { useRef } from 'react';
import styled from 'styled-components';
import { Icon } from 'components/atoms';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/es';
import { DateProps, ContainerProps } from './types';

const DatePickerContainer = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 4px;
  ${props =>
    !props.borderless && `border: 1px solid ${props.theme.colors.borderColor};`}
  ${props =>
    props.background &&
    `background-color: ${
      props.blocked ? props.theme.colors.borderColor : '#ffffff'
    };`}  
  padding: 0 14px;
  svg {
    color: ${props => props.theme.colors.placeholderColor};
    cursor: ${props => (props.blocked ? 'inherit' : 'pointer')};
  }
  .MuiInputBase-root {
    input {
      font-size: 13px;
      cursor: ${props => (props.blocked ? 'inherit' : 'pointer')};
      &::placeholder {
        color: ${props => props.theme.colors.placeholderColor};
        opacity: 1;
      }
    }
  }
  .MuiInput-underline:before {
    display: none;
  }
  .MuiInput-underline:after {
    display: none;
  }
`;

const Date: React.FC<DateProps> = ({
  placeholder,
  value,
  onChange,
  blocked,
  borderless,
  toolbar = true,
  background,
}) => {
  const inputRef = useRef();
  return (
    <DatePickerContainer
      blocked={blocked}
      borderless={borderless}
      background={background}
    >
      <Icon
        size='25px'
        margin='0 10px 0 0'
        icon='date-icon'
        onClick={() => {
          if (inputRef && inputRef.current) {
            // @ts-ignore
            inputRef.current.click();
          }
        }}
      />
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale='es'
      >
        <DatePicker
          disableToolbar={toolbar}
          autoOk
          okLabel='Ok'
          clearLabel='Limpiar'
          cancelLabel='Cancelar'
          label=''
          clearable
          value={value}
          onChange={date => {
            onChange(date);
          }}
          format='DD/MM/YY'
          placeholder={placeholder || 'DD/MM/AA'}
          disabled={blocked}
          InputProps={{ inputRef }}
        />
      </MuiPickersUtilsProvider>
      <Icon
        margin='0 0 0 10px'
        icon='arrow-icon'
        size={15}
        onClick={() => {
          if (inputRef && inputRef.current) {
            // @ts-ignore
            inputRef.current.click();
          }
        }}
      />
    </DatePickerContainer>
  );
};

export default Date;
