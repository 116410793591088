import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { FormControl } from 'components/molecules';
import { postTracking } from 'services';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import { CreateProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const validationState = {
  name: ['mandatory'],
};

const CreateTracking: React.FC<CreateProps> = ({ onClose, onCreate }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors, reset] = useForm(
    {
      name: '',
      description: '',
    },
    validationState
  );

  // API calls
  const [createdTracking, createTracking, loadingCreate] = useRequest(
    postTracking
  );

  useEffect(() => {
    if (!isEmpty(createdTracking)) {
      enqueueSnackbar('El tracking fue creado correctamente.', {
        variant: 'success',
      });
      onCreate();
      reset();
    }
  }, [onCreate, reset, createdTracking, enqueueSnackbar]);

  const validateForm = () => {
    if (validate()) {
      const body = {
        name: form.name,
        description: form.description,
        is_active: true,
      };

      createTracking(body);
    }
  };

  return (
    <FlexContainer container direction='column' padding='20px 40px'>
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <FlexContainer container justify='center' margin='20px 0 30px'>
        <Typography color={THEME.colors.primary} fontSize={18}>
          Crear Tracking
        </Typography>
      </FlexContainer>

      <FlexContainer container direction='column'>
        <FormControl
          label='Nombre'
          placeholder='Escribe el nombre'
          onChange={value => onFormChange(value, 'name')}
          value={form.name}
          margin='0 0 30px'
          error={errors.name}
          width='100%'
          disabled={loadingCreate}
        />
        <FormControl
          control='textarea'
          label='Descripción'
          placeholder='Escribe la decripción'
          onChange={value => onFormChange(value, 'description')}
          value={form.description}
          margin='0 0 20px'
          error={errors.description}
          width='100%'
          disabled={loadingCreate}
        />
      </FlexContainer>

      <FlexContainer container justify='center' margin='30px 0 20px'>
        <Button
          variant='contained'
          color='primary'
          padding='5px 30px'
          onClick={() => validateForm()}
          disabled={loadingCreate}
        >
          Crear
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default CreateTracking;
