import React from 'react';
import styled from 'styled-components';
import { omit } from 'lodash';
import { Button } from '@material-ui/core';
import { ButtonProps } from './types';

const StyledButton = styled(props => (
  <Button disableElevation {...omit(props, ['customColor', 'borderRadius'])} />
))<ButtonProps>`
  height: ${props => props.height || '40px'};
  padding: ${props => props.padding || '0 25px'};
  border-radius: ${props => props.borderRadius || '8px'};
  ${props => props.margin && `margin: ${props.margin};`}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
  ${props => props.alignself && `align-self: ${props.alignself};`}
  white-space: ${({ singleLine = true }) => (singleLine ? 'nowrap' : 'normal')};
  ${props =>
    props.customColor &&
    `
    background-color: ${props.customColor};
    color: #fff;
    &:hover {
      background-color: ${props.customColor};
    }
    &.MuiButton-containedSecondary:hover {
      color: black;
    }
  `}
`;

export default StyledButton;
