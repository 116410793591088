import { useCallback, useState } from 'react';
import { AxiosPromise } from 'axios';
// import { useSnackbar } from 'notistack';

type PageDataType = {
  totalItems: number;
  totalPages: number;
  pageSize: number;
};

const useRequest = (
  requestFunction: (params?: any, id?: any) => AxiosPromise,
  initialValue?: any
) => {
  // const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<any>(
    initialValue === undefined ? {} : initialValue
  );
  const [error, setError] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageData, setPageData] = useState<PageDataType>({
    totalItems: 0,
    totalPages: 0,
    pageSize: 0,
  });
  const [statusRequest, setStatusRequest] = useState(null);

  const getData = useCallback(
    (params, id) => {
      setLoading(true);
      setStatusRequest(null);
      requestFunction(params, id)
        .then(res => {
          if (res.data.num_pages !== undefined) {
            setData(res.data.results);
            setPageData({
              pageSize: res.data.page_size,
              totalItems: res.data.count,
              totalPages: res.data.num_pages,
            });
          } else {
            setData(res.data);
            setStatusRequest(res.status);
          }
        })
        .catch(err => {
          console.log(err.response);
          setError(err.response);
          // enqueueSnackbar(err.response, {
          //   variant: 'error',
          // });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [requestFunction]
  );

  return [data, getData, loading, pageData, error, statusRequest];
};

export default useRequest;
