import React, { useRef, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Grid } from '@material-ui/core';
import {
  getOrganization,
  patchOrganization,
  // getOrderProtocols,
  // getTrackings,
} from 'services';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { FormControl } from 'components/molecules';
import { useTypedSelector } from 'store';
import { selectOrganization } from 'store/organization/actions';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
// import { optionFormatter } from 'util/functions';
import DefaultImage from 'assets/images/DefaultOrgaLogo.png';
import Peru from 'assets/images/peru.svg';
import Mexico from 'assets/images/mexico.svg';

const ActionParagraph = styled(Typography)`
  color: ${props => props.theme.colors.primary};
  font-size: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 0.9;
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom: 1px solid ${props => props.theme.colors.primary};
  }
`;

const validationState = {
  name: ['mandatory'],
  social: ['mandatory'],
  // default_order_protocol: ['mandatory'],
  // default_communication_configuration: ['mandatory'],
};

const Organization: React.FC = () => {
  const windowUrl = window.location.host;
  const organizationRetrieve = useTypedSelector(state => state.organization);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const photoInput = useRef<HTMLInputElement>();
  const selectedOrganization = useTypedSelector(
    state => state.organization.selectedOrganization
  );

  const [form, onFormChange, validate, errors, , updateForm] = useForm(
    {
      name: '',
      description: '',
      social: '',
      ruc: '',
      default_order_protocol: '',
      default_communication_configuration: '',
      range_of_eta_mail: '',
      facebook_link: 'www.facebook.com/',
      instagram_link: 'www.instagram.com/',
    },
    validationState
  );

  // API calls
  // const [protocols, fetchProtocols, loadingProtocols] = useRequest(
  //   getOrderProtocols,
  //   []
  // );
  // const [trackings, fetchTrackings, loadingTrackings] = useRequest(
  //   getTrackings,
  //   []
  // );
  const [organization, fetchOrganization, loadingOrganizations] = useRequest(
    getOrganization
  );
  const [updatedOrganization, updateOrganization, loadingUpdate] = useRequest(
    patchOrganization
  );

  // Values
  const [country, setCountry] = useState({
    name: '',
    code: '',
  });
  const [plan, setPlan] = useState({
    name: '',
    code: '',
  });
  const [canUpdate, setCanUpdate] = useState(false);
  // const [trackingOptions, setTrackingOptions] = useState([]);
  // const [protocolOptions, setProtocolOptions] = useState([]);
  const [newPhoto, setNewPhoto] = useState<boolean>(false);
  const [photo, setPhoto] = useState<string>(
    selectedOrganization && selectedOrganization.photo
  );

  const handleUploadFile = event => {
    setNewPhoto(true);
    if (event.target.files[0]) {
      setPhoto(URL.createObjectURL(event.target.files[0]));
    }
  };

  useEffect(() => {
    fetchOrganization(selectedOrganization.id);
    // fetchProtocols({
    //   page_size: 0,
    // });
    // fetchTrackings({
    //   page_size: 0,
    // });
  }, [fetchOrganization]);

  // useEffect(() => {
  //   if (protocols.length > 0) {
  //     setProtocolOptions(optionFormatter(protocols));
  //   }
  // }, [protocols]);

  // useEffect(() => {
  //   if (trackings.length > 0) {
  //     setTrackingOptions(optionFormatter(trackings));
  //   }
  // }, [trackings]);

  const updateAllOrganizationInformation = (
    organizationData,
    id,
    organizationPhoto = null
  ) => {
    const promises = [updateOrganization(organizationData, id)];
    if (organizationPhoto) {
      promises.push(updateOrganization(organizationPhoto, id));
    }
    Promise.all(promises)
      .then(() => {
        enqueueSnackbar('La organización ha sido actualizada correctamente.', {
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar('Hubo un problema, inténtalo de nuevo.', {
          variant: 'error',
        });
      });
  };

  useEffect(() => {
    if (!isEmpty(organization)) {
      updateForm({
        name: organization.business_name,
        description: organization.description,
        social: organization.tradename,
        ruc: organization.tax_identification,
        photo: organization.photo,
        default_order_protocol: organization.default_order_protocol
          ? organization.default_order_protocol.id.toString()
          : '',
        default_communication_configuration: organization.default_communication_configuration
          ? organization.default_communication_configuration.id.toString()
          : '',
        range_of_eta_mail: organization.range_of_eta_mail,
        facebook_link: `${
          organization.facebook_link !== ''
            ? organization.facebook_link
            : 'www.facebook.com/'
        }`,
        instagram_link: `${
          organization.instagram_link !== ''
            ? organization.instagram_link
            : 'www.instagram.com/'
        }`,
      });
      if (!organization.tax_identification || !organization.business_name) {
        setCanUpdate(true);
      }
      setCountry({
        name: organization.country_name,
        code: organization.country_code,
      });
      setPlan(organization.pricing_plan);
    }
  }, [organization]);

  useEffect(() => {
    if (!isEmpty(updatedOrganization)) {
      dispatch(
        selectOrganization({
          ...selectedOrganization,
          name: form.name,
          photo: updatedOrganization.photo,
        })
      );
    }
  }, [enqueueSnackbar, updatedOrganization]);

  const validateForm = () => {
    if (validate()) {
      const formData = new FormData();
      if (newPhoto) {
        formData.append('photo', photoInput.current.files[0]);
      }
      const editOrganization = {
        tradename: form.social,
        description: form.description,
        default_order_protocol_id: form.default_order_protocol || '',
        default_communication_configuration_id:
          form.default_communication_configuration || '',
        range_of_eta_mail: Number(form.range_of_eta_mail),
        facebook_link: form.facebook_link,
        instagram_link: form.instagram_link,
      };
      if (canUpdate) {
        // @ts-ignore
        editOrganization.tax_identification = form.ruc;
        // @ts-ignore
        editOrganization.business_name = form.name;

        if (windowUrl === 'app.aimo.co') {
          // @ts-ignores
          window.analytics.track('Update organization id', {
            tradename: form.social,
            description: form.description,
            ruc: form.ruc,
            business_name: form.name,
            company_id: organization.id,
            company_name: organization.business_name,
            facebook_link: organization.facebook_link,
            instagram_link: organization.instagram_link,
            created_at: moment().format(),
          });
        }
      }
      if (newPhoto) {
        updateAllOrganizationInformation(
          editOrganization,
          organization.id,
          formData
        );
      } else {
        updateAllOrganizationInformation(editOrganization, organization.id);
      }
    }
  };

  return (
    <FlexContainer container padding='20px 40px' direction='column'>
      <FlexContainer
        container
        padding='40px 50px'
        direction='column'
        borderRadius='5px'
        backgroundColor='#FFFFFF'
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer container alignItems='center' margin='0 0 30px'>
          <img
            src={photo || DefaultImage}
            alt='organization_image'
            height='auto'
            width='auto'
            style={{
              marginRight: '30px',
              maxWidth: '150px',
              maxHeight: '60px',
            }}
          />
          <input
            ref={photoInput}
            type='file'
            accept='image/*'
            onChange={handleUploadFile}
            style={{ display: 'none' }}
          />
          <FlexContainer direction='column'>
            <ActionParagraph onClick={() => photoInput.current.click()}>
              <Icon icon='plus-icon' size={14} margin='0 5px 0 0' />
              Agregar foto
            </ActionParagraph>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer direction='column' width='400px'>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <FlexContainer direction='column' margin='0 0 20px'>
                <Typography fontSize='12px' margin='0 0 10px'>
                  Plan
                </Typography>
                <FlexContainer alignItems='center'>
                  <Typography
                    color='text.secondary'
                    fontSize='13px'
                    margin='0 0 0 10px'
                  >
                    {plan.name}
                  </Typography>
                </FlexContainer>
              </FlexContainer>
            </Grid>
            {country.name && (
              <Grid item xs={12} sm={6}>
                <FlexContainer direction='column' margin='0 0 20px'>
                  <Typography fontSize='12px' margin='0 0 10px'>
                    País
                  </Typography>
                  <FlexContainer alignItems='center'>
                    {country.code === 'PE' && (
                      <img
                        src={Peru}
                        alt='flag'
                        style={{ width: 15, height: 15 }}
                      />
                    )}
                    {country.code === 'MX' && <img src={Mexico} alt='flag' />}
                    <Typography
                      color='text.secondary'
                      fontSize='13px'
                      margin='0 0 0 10px'
                    >
                      {country.name}
                    </Typography>
                  </FlexContainer>
                </FlexContainer>
              </Grid>
            )}
          </Grid>

          <FormControl
            label='Nombre comercial'
            placeholder=''
            onChange={value => onFormChange(value, 'social')}
            value={form.social}
            margin='0 0 20px'
            error={errors.social}
            width='100%'
            disabled={loadingOrganizations || loadingUpdate}
          />
          <FormControl
            label='Descripción'
            placeholder=''
            onChange={value => onFormChange(value, 'description')}
            value={form.description}
            margin='0 0 20px'
            error={errors.description}
            width='100%'
            disabled={loadingOrganizations || loadingUpdate}
          />
          {/* <FormControl
            control='select'
            label='Protocolo'
            placeholder=''
            onChange={value => onFormChange(value, 'default_order_protocol')}
            value={form.default_order_protocol}
            margin='0 0 20px'
            error={errors.default_order_protocol}
            width='100%'
            options={protocolOptions}
            disabled={loadingOrganizations || loadingUpdate || loadingProtocols}
          />
          <FormControl
            control='select'
            label='Tracking'
            placeholder=''
            onChange={value =>
              onFormChange(value, 'default_communication_configuration')
            }
            value={form.default_communication_configuration}
            margin='0 0 20px'
            error={errors.default_communication_configuration}
            width='100%'
            options={trackingOptions}
            disabled={loadingOrganizations || loadingUpdate || loadingTrackings}
          />
          <FormControl
            label='Rango de ETA'
            placeholder=''
            onChange={value => onFormChange(value, 'range_of_eta_mail')}
            value={form.range_of_eta_mail}
            margin='0 0 20px'
            error={errors.range_of_eta_mail}
            width='100%'
            options={trackingOptions}
            disabled={loadingOrganizations || loadingUpdate || loadingTrackings}
          /> */}
          <FormControl
            label='Razón Social / Nombre'
            placeholder=''
            onChange={value => onFormChange(value, 'name')}
            value={form.name}
            margin='0 0 20px'
            error={errors.name}
            width='100%'
            disabled={loadingOrganizations || !canUpdate || loadingUpdate}
          />
          <FormControl
            label='RUC / Tax ID'
            placeholder=''
            onChange={value => onFormChange(value, 'ruc')}
            value={form.ruc}
            margin='0 0 20px'
            error={errors.ruc}
            width='100%'
            disabled={loadingOrganizations || !canUpdate || loadingUpdate}
          />
          <FormControl
            label='Facebook link'
            placeholder=''
            onChange={value => onFormChange(value, 'facebook_link')}
            value={form.facebook_link}
            margin='0 0 20px'
            error={errors.facebook_link}
            width='100%'
            disabled={loadingOrganizations || loadingUpdate}
          />
          <FormControl
            label='Instagram link'
            placeholder=''
            onChange={value => onFormChange(value, 'instagram_link')}
            value={form.instagram_link}
            margin='0 0 40px'
            error={errors.instagram_link}
            width='100%'
            disabled={loadingOrganizations || loadingUpdate}
          />
          <Button
            variant='contained'
            color='primary'
            padding='5px 30px'
            onClick={() => validateForm()}
            disabled={loadingOrganizations || loadingUpdate}
          >
            Guardar
          </Button>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Organization;
