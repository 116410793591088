import React, { useState, useRef, useEffect } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import styled from 'styled-components';
import { find } from 'lodash';
import { Icon, FlexContainer, Input } from 'components/atoms';
import THEME from 'util/styledTheme';
import { SelectProps } from './types';

const Container = styled(FlexContainer)`
  width: 100%;
  position: relative;
`;

const CustomSelect = styled(Input)<{
  disabled: boolean;
  borderless: boolean;
  height?: string;
}>`
  input {
    cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
    text-overflow: ellipsis;
    ${props => props.height && `height: ${props.height};`}
  }
  .MuiInputBase-root {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
  .MuiOutlinedInput-notchedOutline {
    ${props => props.borderless && 'border: none;'}
  }
  svg {
    cursor: ${props => (props.disabled ? 'inherit' : 'pointer')};
  }
`;

const OptionsContainer = styled(FlexContainer)`
  position: absolute;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  border: 1px solid #ccc;
  top: 100%;
  left: 0;
  max-height: 250px;
  overflow-y: auto;
  z-index: 1001;
`;

const Option = styled.div`
  width: 100%;
  font-size: 13px;
  padding: 10px;
  margin: 0;
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const Select: React.FC<SelectProps> = ({
  placeholder,
  options,
  defaultOption,
  onChange,
  width,
  maxWidth,
  height,
  value,
  startIcon,
  disabled,
  variant,
  borderless = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selection, setSelection] = useState<string>('');
  const node = useRef(null);
  useOnClickOutside(node, () => setIsOpen(false));

  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled]);

  useEffect(() => {
    const totalOptions = [...options, defaultOption];
    const selectedValue = find(totalOptions, {
      id: value,
    });
    if (selectedValue) {
      setSelection(selectedValue.name);
    } else {
      setSelection('');
    }
  }, [value, options, defaultOption]);

  return (
    <FlexContainer width={width || '220px'} maxWidth={maxWidth} ref={node}>
      <Container alignItems='center' justify='space-between'>
        <CustomSelect
          fullWidth
          height={height}
          padding='0 0 0 18px'
          value={selection}
          placeholder={placeholder}
          disabled={disabled}
          variant={variant || 'outlined'}
          color='primary'
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Icon
                icon='arrow-icon'
                color={THEME.colors.textSecondary}
                size={15}
                margin='0 0 0 15px'
              />
            ),
            startAdornment: startIcon,
          }}
          onClick={() => {
            if (!disabled) {
              setIsOpen(true);
            }
          }}
          borderless={borderless}
        />
        {isOpen && (
          <OptionsContainer container direction='column'>
            <>
              {defaultOption && (
                <Option
                  onClick={() => {
                    setIsOpen(false);
                    onChange(defaultOption.id, defaultOption.name);
                  }}
                >
                  {defaultOption.name}
                </Option>
              )}
              {options.map(option => (
                <Option
                  key={option.id}
                  onClick={() => {
                    setIsOpen(false);
                    onChange(option.id, option.name);
                  }}
                >
                  {option.name}
                </Option>
              ))}
            </>
          </OptionsContainer>
        )}
      </Container>
    </FlexContainer>
  );
};

export default Select;
