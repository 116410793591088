import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Dialog } from '@material-ui/core';
import { FlexContainer, Icon, Typography } from 'components/atoms';
import { Dropdown, DropdownIconOption } from 'components/molecules';
import { DataTable } from 'components/organisms';
import { getRouteTypes } from 'services';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import EditOrderType from './Edit';

const OrderTypes: React.FC = () => {
  const user = useTypedSelector(state => state.user);
  // API calls
  const [
    routesTypes,
    fetchRoutesTypes,
    loadingRoutesTypes,
    pageData,
  ] = useRequest(getRouteTypes, []);
  // Values
  const [page, setPage] = useState(1);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [typeId, setTypeId] = useState('');

  const requestRoutesTypes = useCallback(() => {
    const params = {
      page,
    };

    fetchRoutesTypes(params);
  }, [fetchRoutesTypes, page]);

  useEffect(() => {
    fetchRoutesTypes();
  }, [fetchRoutesTypes]);

  const settingsOptions = [
    {
      label: 'Editar',
      name: 'edit',
      icon: 'edit-icon',
      onClick: row => {
        setOpenEdit(true);
        setTypeId(row.id);
      },
    },
  ];

  const option = row =>
    settingsOptions.map(setting => (
      <DropdownIconOption
        key={setting.name}
        label={setting.label}
        name={setting.name}
        icon={setting.icon}
        onClick={() => setting.onClick(row)}
      />
    ));

  const headers = useMemo(
    () => [
      {
        label: 'Nombre',
        id: 'name',
        cell: row => row.name,
      },
      {
        label: '',
        id: 'settings',
        cell: row =>
          user.permissions.includes('core.route_type.update') && (
            <Dropdown
              label={<Icon icon='options-icon' size={20} />}
              direction='bottom'
              position='left'
              showCaret={false}
              options={option(row)}
              padding='0 10px'
              hover
            />
          ),
      },
    ],
    []
  );

  return (
    <FlexContainer container padding='30px 40px' direction='column'>
      <FlexContainer
        container
        alignItems='center'
        justify='space-between'
        margin='0 0 30px'
      >
        <Typography fontSize={18} fontWeight={300}>
          Tipos de Rutas
        </Typography>
      </FlexContainer>

      <DataTable
        headers={headers}
        data={routesTypes}
        onChangePage={selectedPage => setPage(selectedPage)}
        totalPages={pageData.totalPages}
        totalItems={pageData.totalItems}
        pageSize={pageData.pageSize}
        page={page}
        loading={loadingRoutesTypes}
      />

      <Dialog
        fullWidth
        maxWidth='sm'
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
        }}
      >
        <EditOrderType
          routeTypeId={typeId}
          onClose={() => setOpenEdit(false)}
          onCreate={useCallback(() => requestRoutesTypes(), [
            requestRoutesTypes,
          ])}
        />
      </Dialog>
    </FlexContainer>
  );
};

export default OrderTypes;
