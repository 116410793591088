import React from 'react';
import styled from 'styled-components';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'store';
import { FlexContainer, Typography } from 'components/atoms';
import { Header } from 'components/organisms';
import evalPermission from 'util/evalPermission';
import List from './List';

const list = [
  {
    name: 'Clientes',
    url: '/clientes',
  },
];

const Option = styled(FlexContainer)<{ active: boolean }>`
  text-decoration: none;
  ${props =>
    props.active && `border-bottom: 2px solid ${props.theme.colors.primary}`}
`;

const ClientNavigator: React.FC = () => {
  const location = useLocation();
  return (
    <FlexContainer
      flex='1'
      height='100%'
      justify='space-between'
      alignItems='center'
    >
      <FlexContainer alignItems='center'>
        {list.map(option => (
          <Link
            key={option.name}
            to={option.url}
            style={{ textDecoration: 'none' }}
          >
            <FlexContainer padding='0 20px'>
              <Option
                alignItems='center'
                padding='14px 0'
                active={location.pathname.includes(option.url)}
              >
                <Typography>{option.name}</Typography>
              </Option>
            </FlexContainer>
          </Link>
        ))}
      </FlexContainer>
    </FlexContainer>
  );
};

const Tracking: React.FC = () => {
  const showMenu = useTypedSelector(state => state.sidebar);
  const user = useTypedSelector(state => state.user);

  return (
    <FlexContainer container height='100%'>
      <Header showMenu={showMenu}>
        <ClientNavigator />
      </Header>
      <FlexContainer container padding='60px 0 0' height='100%'>
        <Switch>
          <Route
            exact
            path='/clientes'
            render={() =>
              evalPermission(user.permissions, 'core.client.list', List)
            }
          />
        </Switch>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Tracking;
