export type MenuType = {
  name: string;
  items: Array<SubMenuType>;
};

export type SubMenuType = {
  name: string;
  url: string;
  permission: string;
};

const fleetMenu: Array<MenuType> = [
  {
    name: 'Flota',
    items: [
      {
        name: 'Mis proveedores',
        url: '/flota/proveedores',
        permission: 'core.provider.list',
      },
      {
        name: 'Conductores',
        url: '/flota/conductores',
        permission: 'core.worker.list',
      },
      {
        name: 'Vehículos',
        url: '/flota/vehiculos',
        permission: 'core.vehicle.list',
      },
    ],
  },
  {
    name: 'Configuración',
    items: [
      {
        name: 'Tracking Público',
        url: '/flota/tracking',
        permission: '',
      },
      {
        name: 'Protocolo Digital',
        url: '/flota/protocolo',
        permission: 'core.order_protocol.list',
      },
      {
        name: 'Ventanas Horarias',
        url: '/flota/ventanas-horarias',
        permission: 'core.time_window.list',
      },
      {
        name: 'Tipos de Ruta',
        url: '/flota/tipos-de-ruta',
        permission: 'core.route_type.list',
      },
      {
        name: 'Tipos de Paquete',
        url: '/flota/tipos-de-paquete',
        permission: 'core.package_type.list',
      },
      {
        name: 'Tipos de Vehículos',
        url: '/flota/tipos-de-vehiculos',
        permission: 'core.vehicle_type.list',
      },
    ],
  },
];

export default fleetMenu;
