import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Grid } from '@material-ui/core';
import { FlexContainer, Typography, Button } from 'components/atoms';
import { FormControl } from 'components/molecules';
import { useTypedSelector } from 'store';
import { optionFormatter } from 'util/functions';
import useRequest from 'hooks/useRequest';
import Party from 'assets/images/party.png';
import { patchOrganization } from 'services';
import { FirstLoginProps } from './types';

const areaOptions = [
  {
    id: 1,
    name: 'Moda',
  },
  {
    id: 2,
    name: 'Joyas y Regalos',
  },
  {
    id: 3,
    name: 'Gourmet',
  },
  {
    id: 4,
    name: 'Hogar',
  },
  {
    id: 5,
    name: 'Saludable',
  },
  {
    id: 6,
    name: 'Tecnología',
  },
  {
    id: 7,
    name: 'Fitness',
  },
  {
    id: 8,
    name: 'Comida',
  },
  {
    id: 9,
    name: 'Orgánico',
  },
  {
    id: 10,
    name: 'Social',
  },
  {
    id: 11,
    name: 'Mascotas',
  },
  {
    id: 12,
    name: 'Marketplace',
  },
  {
    id: 13,
    name: 'Operador logístico',
  },
  {
    id: 14,
    name: 'Otro',
  },
];

const ordersPerDayOptions = [
  {
    id: 1,
    name: '0 - 10',
  },
  {
    id: 2,
    name: '10 - 50',
  },
  {
    id: 3,
    name: '50 - 100',
  },
  {
    id: 4,
    name: '100 - 200',
  },
  {
    id: 5,
    name: '200 - 300',
  },
  {
    id: 6,
    name: '300 - más',
  },
];

const productsDeliveryOptions = [
  {
    id: 1,
    name: 'Flota propia',
  },
  {
    id: 2,
    name: 'Uso aplicativos o servicios de reparto',
  },
  {
    id: 3,
    name: 'Entrego con el delivery del market place',
  },
  {
    id: 4,
    name: 'Aun no hago delivery',
  },
];

const productSalesMeansOptions = [
  {
    id: 1,
    name: 'Aún no vendo',
  },
  {
    id: 2,
    name: 'Solo por WhatsApp',
  },
  {
    id: 3,
    name: 'Por WhatsApp y redes sociales (Instagram, Facebook, etc.)',
  },
  {
    id: 4,
    name: 'Tengo una tienda física',
  },
  {
    id: 5,
    name: 'Ferias / otros',
  },
  {
    id: 6,
    name: 'Marketplaces: Mercado libre, Rappi, otros',
  },
  {
    id: 7,
    name: 'Tengo ecommerce (Shopify y otros)',
  },
];

const producesContentOptions = [
  {
    id: 1,
    name: 'Aún no hago ningún contenido para mi audiencia',
  },
  {
    id: 2,
    name: 'Solo post de Instagram',
  },
  {
    id: 3,
    name: 'Solo comparto contenido por WhatsApp',
  },
  {
    id: 4,
    name: 'Hago post en Instagram y envió promociones/contenido por WhatsApp',
  },
  {
    id: 5,
    name: 'Hago contenido en otra red social (Tiktok, YouTube, blog, etc)',
  },
];

const CustomLink = styled(Link)`
  text-decoration: none;
`;

const FirstLogin: React.FC<FirstLoginProps> = ({ formCompleted }) => {
  const windowUrl = window.location.host;
  const { enqueueSnackbar } = useSnackbar();
  const organization = useTypedSelector(state => state.organization);

  // API calls
  const [, updateOrganization] = useRequest(patchOrganization);

  // Values
  const [areaOptionsL, setAreaOptions] = useState([]);
  const [ordersOptions, setOrdersOptions] = useState([]);
  const [deliveryOptionsL, setDeliveryOptions] = useState([]);
  const [productSalesMeansOptionsL, setProductSalesMeans] = useState([]);
  const [producesContentOptionsL, setProducesContentOptions] = useState([]);
  const [areaId, setAreaId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [deliveryId, setDeliveryId] = useState(null);
  const [productSalesMeansId, setProductSalesMeansId] = useState(null);
  const [producesContentId, setProducesContentId] = useState(null);

  useEffect(() => {
    if (areaOptions.length > 0) {
      setAreaOptions(
        optionFormatter(areaOptions, {
          id: option => option.id,
          name: option => option.name,
        })
      );
    }
  }, [areaOptions]);

  useEffect(() => {
    if (ordersPerDayOptions.length > 0) {
      setOrdersOptions(
        optionFormatter(ordersPerDayOptions, {
          id: option => option.id,
          name: option => option.name,
        })
      );
    }
  }, [ordersPerDayOptions]);

  useEffect(() => {
    if (productsDeliveryOptions.length > 0) {
      setDeliveryOptions(
        optionFormatter(productsDeliveryOptions, {
          id: option => option.id,
          name: option => option.name,
        })
      );
    }
  }, [productsDeliveryOptions]);

  useEffect(() => {
    if (productSalesMeansOptions.length > 0) {
      setProductSalesMeans(
        optionFormatter(productSalesMeansOptions, {
          id: option => option.id,
          name: option => option.name,
        })
      );
    }
  }, [productSalesMeansOptions]);

  useEffect(() => {
    if (producesContentOptions.length > 0) {
      setProducesContentOptions(
        optionFormatter(producesContentOptions, {
          id: option => option.id,
          name: option => option.name,
        })
      );
    }
  }, [producesContentOptions]);

  const validateForms = () => {
    if (
      areaId &&
      orderId &&
      deliveryId &&
      productSalesMeansId &&
      producesContentId
    ) {
      formCompleted();
      if (windowUrl === 'app.aimo.co') {
        // @ts-ignores
        window.analytics.track('Initial form', {
          area: areaOptionsL.find(item => item.id === areaId).name,
          ordersPerDay: ordersOptions.find(item => item.id === orderId).name,
          delivery: deliveryOptionsL.find(item => item.id === deliveryId).name,
          productSalesMeans: productSalesMeansOptionsL.find(
            item => item.id === productSalesMeansId
          ).name,
          producesContent: producesContentOptionsL.find(
            item => item.id === producesContentId
          ).name,
          created_at: moment().format(),
        });
        updateOrganization(
          {
            is_filled: true,
            product_category: areaOptionsL.find(item => item.id === areaId)
              .name,
            delivery: deliveryOptionsL.find(item => item.id === deliveryId)
              .name,
            product_sales_means: productSalesMeansOptionsL.find(
              item => item.id === productSalesMeansId
            ).name,
            orders_per_day: ordersOptions.find(item => item.id === orderId)
              .name,
            produces_content: producesContentOptionsL.find(
              item => item.id === producesContentId
            ).name,
          },
          organization.selectedOrganization.id
        );
      } else {
        updateOrganization(
          {
            is_filled: true,
            product_category: areaOptionsL.find(item => item.id === areaId)
              .name,
            delivery: deliveryOptionsL.find(item => item.id === deliveryId)
              .name,
            product_sales_means: productSalesMeansOptionsL.find(
              item => item.id === productSalesMeansId
            ).name,
            orders_per_day: ordersOptions.find(item => item.id === orderId)
              .name,
            produces_content: producesContentOptionsL.find(
              item => item.id === producesContentId
            ).name,
          },
          organization.selectedOrganization.id
        );
      }
    } else {
      enqueueSnackbar('Todos los campos son requeridos.', {
        variant: 'error',
      });
    }
  };

  return (
    <FlexContainer container direction='column' padding='50px 45px'>
      <FlexContainer
        container
        justify='center'
        alignItems='center'
        margin='0 0 5px'
      >
        <Typography color='text.primary' fontSize='22px' fontWeight={600}>
          Ya casi estamos...
        </Typography>
        {/* <img
          src={Party}
          alt='party'
          style={{
            width: 50,
            height: 36,
          }}
        /> */}
      </FlexContainer>
      <FlexContainer container justify='center'>
        <Typography color='text.secondary' fontSize='13px'>
          Queremos conocerte más, por favor llena este formulario
        </Typography>
      </FlexContainer>
      <FlexContainer
        container
        direction='column'
        margin='30px 0 0'
        padding='0 10px'
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              control='select'
              label='¿A qué rubro pertenece?'
              placeholder='Seleccione rubro'
              value={areaId}
              margin='0 20px 30px 0'
              options={areaOptionsL}
              onChange={value => setAreaId(value)}
              width='100%'
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              control='select'
              label='¿Cuántas órdenes al dia hace en promedio?'
              placeholder='Seleccione promedio'
              value={orderId}
              margin='0 20px 30px 0'
              options={ordersOptions}
              onChange={value => setOrderId(value)}
              width='100%'
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              control='select'
              label='¿Cómo haces la entrega de tus productos?'
              placeholder='Seleccione opción'
              value={deliveryId}
              margin='0 20px 30px 0'
              options={deliveryOptionsL}
              onChange={value => setDeliveryId(value)}
              width='100%'
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              control='select'
              label='¿Actualmente cómo vendes tus productos?'
              placeholder='Seleccione opción'
              value={productSalesMeansId}
              margin='0 20px 30px 0'
              options={productSalesMeansOptionsL}
              onChange={value => setProductSalesMeansId(value)}
              width='100%'
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              control='select'
              label='¿Produces algún contenido/promociones para tu audiencia? '
              placeholder='Seleccione opción'
              value={producesContentId}
              margin='0 20px 30px 0'
              options={producesContentOptionsL}
              onChange={value => setProducesContentId(value)}
              width='100%'
            />
          </Grid>
        </Grid>
        <FlexContainer container justify='center' padding='15px 0 0'>
          <CustomLink to='/inventario'>
            <Button
              variant='contained'
              color='primary'
              onClick={() => validateForms()}
            >
              Enviar
            </Button>
          </CustomLink>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default FirstLogin;
