import {
  Session,
  SessionActionTypes,
  SESSION_LOGIN,
  SESSION_LOGOUT,
} from './types';

const initialState: Session = null;

export function session(
  state = initialState,
  action: SessionActionTypes
): Session {
  switch (action.type) {
    case SESSION_LOGIN:
      return action.payload;
    case SESSION_LOGOUT:
      return null;
    default:
      return state;
  }
}

export default session;
