import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { Checkbox, Collapse } from '@material-ui/core';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { FormControl, Search } from 'components/molecules';
import useWindowSize from 'hooks/useWindowSize';
import THEME from 'util/styledTheme';
import { optionFormatter } from 'util/functions';
import OrderCard from './OrderCard';
import { OrderBlockProps } from './types';

const assignOptions = [
  {
    id: 'assigned',
    name: 'Asignados',
  },
  {
    id: 'unassigned',
    name: 'No asignados',
  },
];

const CardSkeleton: React.FC = () => (
  <FlexContainer
    container
    padding='10px 20px 10px 10px'
    borderColor={THEME.colors.borderColor}
    backgroundColor='#FFFFFF'
    justify='flex-end'
  >
    <FlexContainer direction='column' width='90%'>
      <Skeleton width='80%' height={25} />
      <Skeleton width='60%' height={25} />
      <Skeleton width='70%' height={25} />
    </FlexContainer>
  </FlexContainer>
);

const OrderBlock: React.FC<OrderBlockProps> = ({
  orders,
  loading,
  selectedOrders,
  filterStatus,
  filterType,
  onSelect,
  onDetail,
  onEdit,
  toggleVisibility,
  showingOrders,
  workerFilter,
  resetWorkerFilter,
  pageOrders,
  setPageOrder,
  onRouteDetail,
  loadingAssignOrder,
}) => {
  const size = useWindowSize();
  const [search, setSearch] = useState<string>('');
  const [filteredOrders, setFilteredOrders] = useState<Array<any>>([]);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [statusOptions, setStatusOptions] = useState<Array<any>>([]);
  const [typeOptions, setTypeOptions] = useState<Array<any>>([]);
  const [selectedFilter, setSelectedFilter] = useState({
    status: null,
    assign: null,
    type: null,
  });
  const [loadingFilter, setLoadingFilter] = useState<boolean>(false);

  const filterOrders = () => {
    setLoadingFilter(true);
    let filtered = orders;
    if (search) {
      filtered = orders.filter(order => {
        const aux = {
          code: order.code,
          batch: order.batch,
          status_label: order.status_label,
          contact_person: order.contact_person,
          address: order.address,
          address_detail: order.address_detail,
          id: order.id,
          reference_code: order.reference_code,
          worker: order.worker,
        };
        return JSON.stringify(aux)
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    }
    if (selectedFilter.status) {
      filtered = filtered.filter(
        order => order.status === selectedFilter.status
      );
    }
    if (selectedFilter.assign === 'assigned') {
      filtered = filtered.filter(order => order.route);
    } else if (selectedFilter.assign === 'unassigned') {
      filtered = filtered.filter(order => !order.route);
    }
    if (selectedFilter.type) {
      filtered = filtered.filter(order => order.status === selectedFilter.type);
    }
    setFilteredOrders(filtered);
    setLoadingFilter(false);
  };

  useEffect(() => {
    setFilteredOrders(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(workerFilter)) {
      setSearch(`${workerFilter.first_name} ${workerFilter.last_name}`);
      const newList = orders.filter(
        order => order.worker && order.worker.user_id === workerFilter.user_id
      );
      setFilteredOrders(newList);
    }
  }, [workerFilter]);

  useEffect(() => {
    setStatusOptions(
      optionFormatter(filterStatus, {
        id: option => option.code,
        name: option => option.overwritten_label,
      })
    );
  }, [filterStatus]);

  useEffect(() => {
    setTypeOptions(
      optionFormatter(filterType, {
        id: option => option.code,
        name: option => option.name,
      })
    );
  }, [filterType]);

  useEffect(() => {
    filterOrders();
  }, [selectedFilter]);

  return (
    <FlexContainer
      container
      height='100%'
      overflow='auto'
      direction='column'
      borderColor={THEME.colors.borderColor}
      backgroundColor='#FFFFFF'
      borderRadius='5px'
      style={{ zIndex: 1002 }}
      padding={selectedOrders.length > 0 ? '0 0 50px' : '0'}
    >
      <div style={{ width: '100%' }}>
        <FlexContainer container padding='10px' direction='column'>
          <FlexContainer
            container
            alignItems='center'
            justify='space-between'
            margin='0 0 15px'
          >
            <Search
              onChangeValue={value => {
                setSearch(value);
                resetWorkerFilter();
              }}
              searchValue={search}
              onSubmit={() => filterOrders()}
            />

            <Button
              variant='contained'
              color='secondary'
              margin='0 0 0 10px'
              padding='0 10px'
              onClick={() => setShowFilters(!showFilters)}
            >
              <Icon icon='filter-icon' size={25} />
            </Button>
          </FlexContainer>

          <Collapse in={showFilters} timeout='auto'>
            <FlexContainer margin='0 0 10px' direction='column'>
              <FlexContainer container alignItems='center' margin='0 0 5px'>
                <Typography fontSize={12} width='50%'>
                  Estado de orden
                </Typography>
                <FormControl
                  control='select'
                  value={selectedFilter.status}
                  options={statusOptions}
                  defaultOption={{
                    id: '',
                    name: 'Todos',
                  }}
                  onChange={option =>
                    setSelectedFilter({
                      ...selectedFilter,
                      status: option,
                    })
                  }
                  width='50%'
                />
              </FlexContainer>
              <FlexContainer container alignItems='center' margin='0 0 5px'>
                <Typography fontSize={12} width='50%'>
                  Asignación
                </Typography>
                <FormControl
                  control='select'
                  value={selectedFilter.assign}
                  options={assignOptions}
                  defaultOption={{
                    id: '',
                    name: 'Todos',
                  }}
                  onChange={option =>
                    setSelectedFilter({
                      ...selectedFilter,
                      assign: option,
                    })
                  }
                  width='50%'
                />
              </FlexContainer>
              <FlexContainer container alignItems='center' margin='0 0 5px'>
                <Typography fontSize={12} width='50%'>
                  Tipo de orden
                </Typography>
                <FormControl
                  control='select'
                  value={selectedFilter.type}
                  options={typeOptions}
                  defaultOption={{
                    id: '',
                    name: 'Todos',
                  }}
                  onChange={option =>
                    setSelectedFilter({
                      ...selectedFilter,
                      type: option,
                    })
                  }
                  width='50%'
                />
              </FlexContainer>
            </FlexContainer>
          </Collapse>

          <FlexContainer container alignItems='center' justify='space-between'>
            <FlexContainer alignItems='center' flex='1'>
              <FlexContainer width='10%'>
                <Checkbox
                  checked={
                    filteredOrders.length > 0 &&
                    filteredOrders.every(item =>
                      selectedOrders.includes(item.id)
                    )
                  }
                  disableRipple
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  style={{ padding: 0 }}
                  onChange={e => {
                    onSelect(
                      filteredOrders.map(order => order.id),
                      e.target.checked
                    );
                  }}
                />
              </FlexContainer>
              <Typography color='text.secondary' fontSize={13}>
                {`${filteredOrders.length} órdenes encontradas`}
              </Typography>
            </FlexContainer>
            {size.width > 768 && (
              <FlexContainer
                onClick={() => {
                  toggleVisibility(
                    filteredOrders,
                    !(showingOrders.length === filteredOrders.length)
                  );
                }}
              >
                {showingOrders.length === filteredOrders.length ? (
                  <Icon icon='show-icon' size={20} />
                ) : (
                  <Icon icon='no-show-icon' size={20} />
                )}
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>
      </div>
      {loading || loadingFilter ? (
        <>
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </>
      ) : (
        <>
          {pageOrders.pageSize > 0 &&
            filteredOrders.slice(0, pageOrders.pageSize).map(order => (
              <div key={order.id} style={{ width: '100%' }}>
                <OrderCard
                  data={order}
                  selected={selectedOrders.includes(order.id)}
                  toggleSelect={checked => onSelect([order.id], checked)}
                  onDetail={() => onDetail(order)}
                  onEdit={() => onEdit(order.id)}
                  showRoute={showingOrders.includes(order.id)}
                  toggleVisibility={visibility => {
                    toggleVisibility(
                      [order],
                      visibility || !showingOrders.includes(order.id)
                    );
                  }}
                  onRouteDetail={onRouteDetail}
                  loadingAssignOrder={loadingAssignOrder}
                />
              </div>
            ))}

          {pageOrders.totalPages !== pageOrders.currentPage &&
            filteredOrders.length > 10 && (
              <FlexContainer container justify='center' padding='12px 0'>
                <Button
                  variant='contained'
                  color='secondary'
                  padding='0 10px'
                  onClick={() =>
                    setPageOrder({
                      ...pageOrders,
                      pageSize: pageOrders.pageSize + 10,
                      currentPage: pageOrders.currentPage + 1,
                    })
                  }
                >
                  Mostrar más órdenes
                </Button>
              </FlexContainer>
            )}
        </>
      )}
    </FlexContainer>
  );
};

export default OrderBlock;
