import React, { useState } from 'react';
import { find } from 'lodash';
import { Collapse, Dialog, LinearProgress } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import THEME from 'util/styledTheme';
import { routeColors } from 'util/statusColors';
import { PreviewProps, RouteProps } from './types';

const RouteCard: React.FC<RouteProps> = ({
  data,
  index,
  color,
  onClick,
  onDelete,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Droppable droppableId={data.key} key={data.key}>
      {dropProvided => (
        <FlexContainer
          ref={dropProvided.innerRef}
          {...dropProvided.droppableProps}
          container
          direction='column'
          backgroundColor='#FFFFFF'
        >
          <FlexContainer
            container
            direction='column'
            padding='10px'
            borderColor={THEME.colors.borderColor}
          >
            <FlexContainer
              container
              alignItems='center'
              justify='space-between'
              margin='0 0 8px'
            >
              <FlexContainer
                padding='2px 14px'
                backgroundColor={color}
                borderRadius='4px'
              >
                <Typography fontSize='13px' color='#FFFFFF'>
                  {`Ruta ${index + 1}`}
                </Typography>
              </FlexContainer>

              <FlexContainer alignItems='center'>
                <Typography
                  fontSize='12px'
                  color='text.secondary'
                  margin='0 10px 0 0'
                >
                  {`${data.tasks.length} puntos`}
                </Typography>
                <FlexContainer
                  style={{
                    transform: open ? 'rotate(180deg)' : 'none',
                    transition: 'all 0.3s',
                  }}
                  onClick={() => setOpen(!open)}
                >
                  <Icon icon='arrow-icon' size={13} />
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>

            <FlexContainer container margin='0 0 8px'>
              <Typography fontSize='12px' margin='0 3px 0 0'>
                Vehículo:
              </Typography>
              <Typography fontWeight={600} fontSize='12px'>
                {data.vehicle_type.name}
              </Typography>
            </FlexContainer>

            <FlexContainer
              container
              alignItems='center'
              justify='space-between'
            >
              <Typography fontSize='12px'>
                {data.estimated_weight_in_route_label}
              </Typography>
              <Typography fontSize='12px'>
                {data.estimated_volumen_in_route_label}
              </Typography>
              <Typography fontSize='12px'>
                {data.estimated_time_in_route_label}
              </Typography>
              <Typography fontSize='12px'>
                {data.estimated_distance_in_route_label}
              </Typography>
            </FlexContainer>
          </FlexContainer>

          <Collapse in={open} timeout='auto'>
            {data.tasks.map((task, taskIndex) => (
              <Draggable
                key={task.task_id}
                draggableId={task.task_id.toString()}
                index={taskIndex}
              >
                {provided => (
                  <FlexContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    container
                    padding='10px'
                    direction='column'
                    alignItems='center'
                    backgroundColor='#FFFFFF'
                    onClick={() => onClick(task)}
                  >
                    <FlexContainer
                      container
                      margin='0 0 8px'
                      alignItems='center'
                    >
                      <FlexContainer
                        width='10%'
                        justify='center'
                        borderRadius='30px'
                        borderColor={THEME.colors.borderColor}
                        backgroundColor={THEME.colors.backgroundColor}
                      >
                        {taskIndex + 1}
                      </FlexContainer>
                      <FlexContainer
                        width='80%'
                        alignItems='center'
                        padding='0 10px'
                      >
                        <FlexContainer alignItems='center'>
                          <Typography fontSize='12px' margin='0 10px 0 0'>
                            {task.estimated_arrival_hour}
                          </Typography>
                          <Typography fontSize='12px' color='text.secondary'>
                            {task.reference_code}
                          </Typography>
                        </FlexContainer>
                      </FlexContainer>
                      <FlexContainer width='10%'>
                        <Icon
                          icon='delete-icon'
                          size={20}
                          color={THEME.colors.textSecondary}
                          onClick={() => onDelete(data.key, task.task_id)}
                        />
                      </FlexContainer>
                    </FlexContainer>
                    <FlexContainer
                      width='80%'
                      direction='column'
                      padding='0 10px'
                    >
                      <Typography
                        fontSize='12px'
                        color='text.secondary'
                        margin='0 0 8px'
                      >
                        {task.address}
                      </Typography>
                      <FlexContainer
                        container
                        alignItems='center'
                        justify='space-around'
                      >
                        <Typography fontSize='12px' color='text.secondary'>
                          {`${task.start.slice(0, 5)} -
                           ${task.end.slice(0, 5)}`}
                        </Typography>
                        <Typography fontSize='12px' color='text.secondary'>
                          {`${task.weight} Kg`}
                        </Typography>
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                )}
              </Draggable>
            ))}
          </Collapse>
        </FlexContainer>
      )}
    </Droppable>
  );
};

const Preview: React.FC<PreviewProps> = ({
  routes,
  onRouteChange,
  onTaskClick,
  updateLoader,
  createLoader,
}) => {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState({
    route: null,
    task: null,
  });
  const onDragEnd = (result: any) => {
    const { destination, source, reason } = result;
    if (
      !destination ||
      reason === 'CANCEL' ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    const from = source.droppableId;
    const to = destination.droppableId;
    const originalOrderIndex = parseInt(source.index, 10);
    const newOrderIndex = parseInt(destination.index, 10);
    const orderMoved = find(routes, { key: from }).tasks[source.index];
    const newRoutes = routes.map(route => {
      let newTasks = route.tasks;
      let change = false;
      if (route.key === from) {
        newTasks = newTasks.filter(
          (task, index) => index !== originalOrderIndex
        );
        change = true;
      }
      if (route.key === to) {
        newTasks = [
          ...newTasks.slice(0, newOrderIndex),
          orderMoved,
          ...newTasks.slice(newOrderIndex),
        ];
        change = true;
      }

      if (change) {
        return {
          ...route,
          tasks: newTasks,
        };
      }

      return route;
    });
    onRouteChange(newRoutes);
  };

  const handleDelete = () => {
    const newRoutes = routes.map(route => {
      if (route.key === deleteData.route) {
        const newTasks = route.tasks.filter(
          task => task.task_id !== deleteData.task
        );
        return {
          ...route,
          tasks: newTasks,
        };
      }
      return route;
    });

    onRouteChange(newRoutes);
    setOpenConfirm(false);
  };

  return (
    <FlexContainer
      container
      height='100%'
      overflow='auto'
      direction='column'
      backgroundColor='#FFFFFF'
      style={{ zIndex: 1002 }}
    >
      <FlexContainer
        container
        backgroundColor='rgba(121, 226, 158, 0.2)'
        padding='15px 0'
        alignItems='center'
        justify='center'
      >
        <Icon icon='check-icon' size={18} margin='0 5px 0 0' />
        <Typography fontSize={15} color='#51b575'>
          {`${routes.length} Rutas Previsualizadas`}
        </Typography>
      </FlexContainer>

      <DragDropContext onDragEnd={onDragEnd}>
        {routes.map((route, index) => (
          <RouteCard
            key={route.key}
            data={route}
            index={index}
            color={routeColors[index % routeColors.length]}
            onClick={task => onTaskClick(task)}
            onDelete={(routeId, taskId) => {
              setOpenConfirm(true);
              setDeleteData({
                route: routeId,
                task: taskId,
              });
            }}
          />
        ))}
      </DragDropContext>

      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
        <FlexContainer
          padding='30px'
          direction='column'
          alignItems='center'
          justify='center'
        >
          <Typography
            color={THEME.colors.primary}
            fontSize={15}
            margin='0 0 20px'
          >
            Borrar tarea
          </Typography>
          <Typography margin='0 0 30px' fontSize={13}>
            ¿Está seguro de eliminar la tarea?
          </Typography>
          <FlexContainer container alignItems='center' justify='space-between'>
            <Button
              variant='contained'
              color='primary'
              fontSize='13px'
              margin='0 10px 0 0'
              onClick={() => handleDelete()}
            >
              Eliminar
            </Button>

            <Button fontSize='14px' onClick={() => setOpenConfirm(false)}>
              Cancelar
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Dialog>

      <Dialog
        open={updateLoader || createLoader}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <FlexContainer
          container
          direction='column'
          padding='30px'
          justify='center'
          alignItems='center'
        >
          <FlexContainer
            justify='center'
            alignItems='center'
            borderRadius='100px'
            backgroundColor={THEME.colors.backgroundColor}
            margin='0 0 20px 0'
            height='150px'
            width='150px'
          >
            <Icon icon='wand-icon' size='80px' />
          </FlexContainer>
          <LinearProgress />
          <Typography fontSize={14} margin='20px 0 0 0'>
            {updateLoader && 'Por favor espere, estamos actualizando las rutas'}
            {createLoader && 'Por favor espere, estamos creando las rutas'}
          </Typography>
        </FlexContainer>
      </Dialog>
    </FlexContainer>
  );
};

export default Preview;
