import React from 'react';
import styled from 'styled-components';
import { Link, Switch, useLocation, Route } from 'react-router-dom';
import { useTypedSelector } from 'store';
import { FlexContainer, Typography, Divider } from 'components/atoms';
import { Header } from 'components/organisms';
import evalPermission from 'util/evalPermission';
import Orders from './Orders';
import Reports from './Reports';
import Historical from './Historical';
import HeatMap from './HeatMap';

// const list = [
//   {
//     name: 'Métricas',
//     url: '/reportes/',
//   },
//   {
//     name: 'Histórico',
//     url: '/reportes/historico',
//   },
//   {
//     name: 'Mapa de calor',
//     url: '/reportes/mapa_de_calor',
//   },
//   {
//     name: 'Reportes',
//     url: '/reportes/reportes',
//     permission: 'analytics.report.list',
//   },
// ];

// const Option = styled(FlexContainer)<{ active: boolean }>`
//   text-decoration: none;
//   ${props =>
//     props.active && `border-bottom: 2px solid ${props.theme.colors.primary}`}
// `;

// const MetricsNavigation: React.FC = () => {
//   const location = useLocation();
//   const user = useTypedSelector(store => store.user);
//   return (
//     <FlexContainer
//       flex='1'
//       height='100%'
//       justify='space-between'
//       alignItems='center'
//     >
//       <FlexContainer alignItems='center'>
//         {list.map(option => {
//           if (
//             !option.permission ||
//             user.permissions.includes(option.permission)
//           ) {
//             return (
//               <Link
//                 key={option.name}
//                 to={option.url}
//                 style={{ textDecoration: 'none' }}
//               >
//                 <FlexContainer padding='0 20px'>
//                   <Option
//                     alignItems='center'
//                     padding='14px 0'
//                     active={location.pathname === option.url}
//                   >
//                     <Typography>{option.name}</Typography>
//                   </Option>
//                 </FlexContainer>
//               </Link>
//             );
//           }
//           return null;
//         })}
//       </FlexContainer>
//     </FlexContainer>
//   );
// };

const Statistics: React.FC = () => {
  const showMenu = useTypedSelector(state => state.sidebar);
  const user = useTypedSelector(state => state.user);
  return (
    <FlexContainer container height='100%'>
      <Header showMenu={showMenu} />
      <FlexContainer container padding='50px 0 0'>
        <FlexContainer direction='column' container height='100vh'>
          <FlexContainer direction='column' container padding='35px 40px 0'>
            <FlexContainer
              container
              justify='space-between'
              alignItems='center'
              padding='0 0 20px'
            >
              <FlexContainer alignItems='center'>
                <Typography fontSize={26} fontWeight={600} color='#000929'>
                  Métricas
                </Typography>
              </FlexContainer>
            </FlexContainer>
            <Divider orientation='horizontal' type='solid' color='#E0DEF7' />
          </FlexContainer>
          <Switch>
            <Route exact path='/reportes' component={Orders} />
            <Route exact path='/reportes/historico' component={Historical} />
            <Route
              exact
              path='/reportes/reportes'
              render={() =>
                evalPermission(
                  user.permissions,
                  'analytics.report.list',
                  Reports
                )
              }
            />
            <Route exact path='/reportes/mapa_de_calor' component={HeatMap} />
          </Switch>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Statistics;
