import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import {
  Divider,
  FlexContainer,
  Icon,
  PaymentInput,
  Typography,
} from 'components/atoms';
import { FormControl } from 'components/molecules';
import useForm from 'hooks/useForm';
import THEME from 'util/styledTheme';
import CardIcon from 'assets/images/payment/card.svg';
import UserIcon from 'assets/images/payment/user.svg';
import CalendarIcon from 'assets/images/payment/calendar.svg';
import EmailIcon from 'assets/images/payment/email.svg';
import { EditTypes } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const EditBilling: React.FC<EditTypes> = ({ data, onClose }) => {
  const [form, onFormChange, , errors] = useForm(
    {
      enterprise_name: '',
      last_name: '',
      document: '',
      email: '',
      country: '',
      city: '',
      address: '',
    },
    {}
  );

  return (
    <FlexContainer
      width='800px'
      position='relative'
      padding='40px 50px 50px'
      direction='column'
    >
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <Typography fontSize={18} margin='10px 0 0'>
        {data
          ? 'Modificar dirección de facturación'
          : 'Agregar dirección de facturación'}
      </Typography>
      <Divider orientation='horizontal' margin='15px 0 30px' />

      <FlexContainer
        container
        direction='column'
        padding='30px 30px'
        margin='0 0 30px'
        borderRadius='10px'
        backgroundColor={THEME.colors.backgroundColor}
        borderColor={THEME.colors.borderColor}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl
              label='Nombre de la Empresa*'
              placeholder='Escriba el nombre de la empresa'
              onChange={value => onFormChange(value, 'enterprise_name')}
              value={form.enterprise_name}
              margin='0 0 20px'
              error={errors.enterprise_name}
              width='100%'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              label='Apellidos'
              placeholder='Escriba el apellido'
              onChange={value => onFormChange(value, 'last_name')}
              value={form.last_name}
              margin='0 0 20px'
              error={errors.last_name}
              width='100%'
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl
              label='Documento'
              placeholder='Escriba el documento'
              onChange={value => onFormChange(value, 'document')}
              value={form.document}
              margin='0 0 20px'
              error={errors.document}
              width='100%'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              label='Correo electrónico'
              placeholder='Escriba su correo electrónico'
              onChange={value => onFormChange(value, 'email')}
              value={form.email}
              margin='0 0 20px'
              error={errors.email}
              width='100%'
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl
              control='select'
              label='País'
              placeholder='Seleccione el país'
              onChange={value => onFormChange(value, 'country')}
              value={form.country}
              options={[
                {
                  id: 'per',
                  name: 'Perú',
                },
                {
                  id: 'mex',
                  name: 'México',
                },
              ]}
              margin='0 0 20px'
              error={errors.country}
              width='100%'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              label='Ciudad'
              placeholder='Escriba la ciudad'
              onChange={value => onFormChange(value, 'city')}
              value={form.city}
              margin='0 0 20px'
              error={errors.city}
              width='100%'
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControl
              label='Dirección'
              placeholder='Escriba la dirección'
              onChange={value => onFormChange(value, 'address')}
              value={form.address}
              margin='0 0 20px'
              error={errors.address}
              width='100%'
            />
          </Grid>
        </Grid>
      </FlexContainer>

      <FlexContainer
        container
        direction='column'
        padding='30px 30px'
        margin='0 0 30px'
        borderRadius='10px'
        backgroundColor={THEME.colors.backgroundColor}
        borderColor={THEME.colors.borderColor}
      >
        <Typography fontSize={15} margin='0 0 20px'>
          Detalles de la tarjeta
        </Typography>

        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <PaymentInput icon={CardIcon} placeholder='Número de tarjeta' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PaymentInput icon={UserIcon} placeholder='Nombres y apellidos' />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <PaymentInput icon={CalendarIcon} placeholder='MM/AA' />
            </Grid>
            <Grid item xs={12} sm={3}>
              <PaymentInput icon={CardIcon} placeholder='CVV' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PaymentInput icon={EmailIcon} placeholder='Correo electrónico' />
            </Grid>
          </Grid>
        </form>
      </FlexContainer>
    </FlexContainer>
  );
};

export default EditBilling;
