import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty, find } from 'lodash';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  IconButton,
  Collapse,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Button,
  Divider,
  FlexContainer,
  Icon,
  Typography,
} from 'components/atoms';
import {
  FormControl,
  MultipleSelect,
  MultipleInput,
  Spinner,
} from 'components/molecules';
import DefaultImage from 'assets/images/DefaultProfile.png';
import { optionFormatter } from 'util/functions';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import useForm from 'hooks/useForm';
import THEME from 'util/styledTheme';
import {
  getAdmin,
  patchAdmin,
  getBranchOffices,
  getRoles,
  getProvidersMinimal,
  sendUserInvitation,
  getClients,
} from 'services';
import {
  EditProps,
  EditFormType,
  RoleSelectorProps,
  InvitationFormType,
} from './types';

const CameraIcon = styled(FlexContainer)`
  bottom: -2px;
  right: -6px;
`;

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const validationStateToInvite = {
  role_id: ['mandatory'],
};

const validationStateToUpdate = {
  firstName: ['mandatory'],
  lastName: ['mandatory'],
  email: ['mandatory', 'email'],
  passwordValidation: ['passwordValidation'],
};

const ComponentSkeleton = () => (
  <>
    <FlexContainer container margin='0 0 20px' direction='column'>
      <FlexContainer
        width='700px'
        padding='10px'
        margin='0 0 20px'
        direction='column'
        alignItems='center'
        justify='space-around'
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer
          width='90%'
          height='100px'
          justify='space-around'
          alignItems='center'
        >
          <Skeleton variant='rect' width='45%' height='40px' />
          <Skeleton variant='rect' width='45%' height='40px' />
        </FlexContainer>
        <FlexContainer
          width='90%'
          height='100px'
          justify='space-around'
          alignItems='center'
        >
          <Skeleton variant='rect' width='45%' height='40px' />
          <Skeleton variant='rect' width='45%' height='40px' />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer
        container
        padding='10px'
        margin='0 0 20px'
        direction='column'
        alignItems='center'
        justify='space-around'
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer
          width='90%'
          height='100px'
          justify='space-around'
          alignItems='center'
        >
          <Skeleton variant='rect' width='45%' height='40px' />
          <Skeleton variant='rect' width='45%' height='40px' />
        </FlexContainer>
        <FlexContainer
          width='90%'
          height='100px'
          justify='space-around'
          alignItems='center'
        >
          <Skeleton variant='rect' width='45%' height='40px' />
          <Skeleton variant='rect' width='45%' height='40px' />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  </>
);

const RoleSelector: React.FC<RoleSelectorProps> = ({
  title,
  subtitle,
  listOptions,
  selectValue,
  onSelect,
  placeholder,
  disabled,
}) => (
  <FlexContainer
    container
    padding='30px 30px'
    margin='0 0 30px'
    borderRadius='10px'
    backgroundColor={THEME.colors.backgroundColor}
    borderColor={THEME.colors.borderColor}
  >
    <FlexContainer container direction='column'>
      <Typography
        color={THEME.colors.primary}
        fontWeight={500}
        fontSize={17}
        margin={subtitle ? '0 0 5px' : '0 0 20px'}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography color='text.secondary' fontSize={13} margin='0 0 20px'>
          {subtitle}
        </Typography>
      )}
      <FormControl
        control='select'
        placeholder={placeholder}
        onChange={value => onSelect(value)}
        value={selectValue}
        width='100%'
        options={listOptions}
        disabled={disabled}
      />
    </FlexContainer>
  </FlexContainer>
);

const EditAdmin: React.FC<EditProps> = ({ data, onCreate, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedOffice = useTypedSelector(
    state => state.organization.selectedBranchOffice
  );
  const photoInput = useRef<HTMLInputElement>();
  const [form, onFormChange, validate, errors, , updateForm] = useForm(
    {
      firstName: '',
      lastName: '',
      email: '',
      nationalIdentityDocument: '',
      phone: '',
      phone_country: 'pe', // should be dynamic according to the country where it has logged
      password: '',
      passwordValidation: '',
      role_id: '',
      provider_id: null,
      client_id: null,
    },
    data ? validationStateToUpdate : validationStateToInvite
  );
  // API calls
  const [admin, fetchAdmin, loadingAdmin] = useRequest(getAdmin);
  const [, updateAdmin, loadingUpdate, , , updateStatusRequest] = useRequest(
    patchAdmin
  );
  const [branchOffices, fetchBranchOffices, loadingOffices] = useRequest(
    getBranchOffices,
    []
  );
  const [roles, fetchRoles, loadingRoles] = useRequest(getRoles, []);
  const [providers, fetchProviders, loadingProviders] = useRequest(
    getProvidersMinimal,
    []
  );
  const [sentInvitation, sendInvitation, loadingSend] = useRequest(
    sendUserInvitation
  );
  const [clients, fetchClients, loadingClients] = useRequest(getClients, []);

  // Values
  const [newPhoto, setNewPhoto] = useState<boolean>(false);
  const [photo, setPhoto] = useState<string>(data && data.photo);
  const [branchOfficeOptions, setBranchOfficeOptions] = useState([]);
  const [selectedBranchOffices, setSelectedBranchOffices] = useState([]);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [providerOptions, setProviderOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [emailsInput, setEmailInput] = useState('');
  const [chosenOption, setChosenOption] = useState('');
  const [emptyEmailsError, setEmptyEmailsError] = useState('');

  useEffect(() => {
    if (!isEmpty(data)) {
      fetchAdmin(data.id);
    }
  }, [data]);

  useEffect(() => {
    fetchBranchOffices({
      page_size: 0,
    });
    fetchRoles();
    fetchProviders();
    fetchClients({ page_size: 0 });
  }, [fetchBranchOffices, fetchRoles, fetchProviders, fetchClients]);

  useEffect(() => {
    if (branchOffices.length > 0) {
      setBranchOfficeOptions(optionFormatter(branchOffices));
    }
  }, [branchOffices]);

  useEffect(() => {
    if (providers.length > 0) {
      setProviderOptions(
        optionFormatter(providers, {
          id: option => option.id,
          name: option => option.business_name,
        })
      );
    }
  }, [providers]);

  useEffect(() => {
    if (clients.length > 0) {
      const activeClients = clients.filter(client => client.is_active);
      setClientOptions(
        optionFormatter(activeClients, {
          id: option => option.id,
          name: option => option.name,
        })
      );
    }
  }, [clients]);

  useEffect(() => {
    if (!data) {
      setSelectedBranchOffices([
        {
          id: selectedOffice.id,
          name: selectedOffice.name,
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(admin)) {
      setSelectedBranchOffices(optionFormatter(admin.branch_offices));
      setSelectedRole(admin.role.id.toString());

      if (admin.client) {
        setChosenOption('client');
      }

      if (admin.provider) {
        setChosenOption('provider');
      }

      updateForm({
        firstName: admin.user ? admin.user.first_name : '',
        lastName: admin.user ? admin.user.last_name : '',
        email: admin.user ? admin.user.email : '',
        nationalIdentityDocument: admin.national_identity_document || '',
        phone: admin.phone || '',
        password: '',
        passwordValidation: '',
        role_id: admin.role ? admin.role.id : '',
        provider_id: admin.provider ? admin.provider.id : null,
        client_id: admin.client ? admin.client.id : null,
      });
    }
  }, [admin]);

  useEffect(() => {
    if (updateStatusRequest === 200) {
      enqueueSnackbar('Se actualizó el usuario correctamente.', {
        variant: 'success',
      });
      onCreate();
    } else if (updateStatusRequest && updateStatusRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [updateStatusRequest, enqueueSnackbar]);

  useEffect(() => {
    if (!isEmpty(sentInvitation)) {
      enqueueSnackbar('Las invitaciones fueron enviadas correctamente.', {
        variant: 'success',
      });
      onCreate();
      setEmailList([]);
      onFormChange('', 'role_id');
    }
  }, [sentInvitation, enqueueSnackbar]);

  useEffect(() => {
    if (roles.length > 0 && selectedRole) {
      const filteredRole = roles.filter(
        role => role.id.toString() === selectedRole
      );
      const filteredRoleCode = filteredRole[0].code;
      setChosenOption(filteredRoleCode);
    }
  }, [selectedRole]);

  const handleUploadFile = event => {
    setNewPhoto(true);
    if (event.target.files[0]) {
      setPhoto(URL.createObjectURL(event.target.files[0]));
    }
  };

  const validateForm = () => {
    if (validate()) {
      const providerId = chosenOption === 'provider' ? form.provider_id : null;
      const clientId = chosenOption === 'client' ? form.client_id : null;
      if (data) {
        const formData = new FormData();
        if (newPhoto) {
          formData.append('photo', photoInput.current.files[0]);
        }
        const editAdmin: EditFormType = {
          user: {
            first_name: form.firstName,
            last_name: form.lastName,
            password: form.password,
          },
          national_identity_document: form.nationalIdentityDocument,
          branch_office_ids: selectedBranchOffices.map(office => office.id),
          phone: form.phone,
          role_id: form.role_id,
          provider_id: providerId,
          client_id: clientId,
        };
        if (form.password === '') {
          delete editAdmin.user.password;
        }
        // if (newPhoto) {
        //   updateAllAdminInformation(editAdmin, data.id, formData);
        //   return;
        // }
        updateAdmin(editAdmin, data.id);
      } else if (emailList.length === 0) {
        setEmptyEmailsError('No se han ingresado emails');
      } else {
        const newInvitations: InvitationFormType = {
          emails: emailList,
          role_id: Number(form.role_id),
          provider_id: providerId,
          client_id: clientId,
          branch_office_ids: selectedBranchOffices.map(office =>
            Number(office.id)
          ),
        };
        sendInvitation(newInvitations);
      }
    }
  };

  return (
    <FlexContainer
      width='800px'
      position='relative'
      padding='40px 50px 50px'
      direction='column'
    >
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <Typography fontSize={18} margin='10px 0 0'>
        {data ? 'Perfil de Usuario' : 'Creación de Usuario'}
      </Typography>
      <Divider orientation='horizontal' margin='15px 0 30px' />
      {loadingAdmin ? (
        <FlexContainer>
          <ComponentSkeleton />
        </FlexContainer>
      ) : (
        <>
          <FlexContainer
            container
            direction='column'
            padding='30px 30px'
            margin='0 0 30px'
            borderRadius='10px'
            backgroundColor={THEME.colors.backgroundColor}
            borderColor={THEME.colors.borderColor}
          >
            <FlexContainer container alignItems='center' margin='0 0 20px'>
              {data && (
                <FlexContainer
                  direction='column'
                  alignItems='center'
                  margin='0 50px 0 0'
                  cursor='pointer'
                  onClick={() => photoInput.current.click()}
                >
                  <FlexContainer
                    borderRadius='100px'
                    borderColor={THEME.colors.primary}
                    padding='5px'
                    margin='0 0 15px'
                    position='relative'
                  >
                    <img
                      src={photo || DefaultImage}
                      alt='user_image'
                      height='80px'
                      width='80px'
                      style={{ borderRadius: '100px' }}
                    />
                    <CameraIcon
                      position='absolute'
                      backgroundColor={THEME.colors.primary}
                      borderRadius='40px'
                      padding='5px'
                      justify='center'
                      alignItems='center'
                    >
                      <Icon icon='camera-icon' size={20} />
                    </CameraIcon>
                  </FlexContainer>
                  <input
                    ref={photoInput}
                    type='file'
                    accept='image/*'
                    onChange={handleUploadFile}
                    style={{ display: 'none' }}
                  />
                </FlexContainer>
              )}
              <FlexContainer
                container
                justify='space-between'
                alignItems='center'
              >
                <Typography
                  color={THEME.colors.primary}
                  fontWeight={500}
                  fontSize={17}
                >
                  Invitación de usuarios*
                </Typography>
              </FlexContainer>
            </FlexContainer>

            <FlexContainer container direction='column'>
              {data ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Nombres*'
                        placeholder='Escribe tus nombres'
                        onChange={value => onFormChange(value, 'firstName')}
                        value={form.firstName}
                        margin='0 0 20px'
                        error={errors.firstName}
                        width='100%'
                        disabled={loadingUpdate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Apellidos*'
                        placeholder='Escribe tus Apellidos'
                        onChange={value => onFormChange(value, 'lastName')}
                        value={form.lastName}
                        margin='0 0 20px'
                        error={errors.lastName}
                        width='100%'
                        disabled={loadingUpdate}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Correo electrónico*'
                        placeholder='Escribe correo electrónico'
                        onChange={value => onFormChange(value, 'email')}
                        value={form.email}
                        margin='0 0 20px'
                        error={errors.email}
                        width='100%'
                        disabled={!!data}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Documento de identidad*'
                        placeholder='Escribe tu número de documento'
                        onChange={value =>
                          onFormChange(value, 'nationalIdentityDocument')
                        }
                        value={form.nationalIdentityDocument}
                        margin='0 0 20px'
                        error={errors.nationalIdentityDocument}
                        width='100%'
                        disabled={loadingUpdate}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        control='phone'
                        country={form.phone_country}
                        label='Teléfono'
                        placeholder='Escribe tu número de teléfono*'
                        onChange={value => onFormChange(value, 'phone')}
                        value={form.phone}
                        margin='0 0 20px'
                        error={errors.phone}
                        width='100%'
                        disabled={loadingUpdate}
                      />
                    </Grid>
                  </Grid>

                  <FlexContainer container direction='column'>
                    <FlexContainer
                      container
                      justify='space-between'
                      alignItems='center'
                      margin='0 0 10px 0'
                    >
                      <Typography
                        color={THEME.colors.primary}
                        fontWeight={500}
                        fontSize={17}
                        margin='0 0 5px'
                      >
                        Cambiar Contraseña
                      </Typography>

                      <div
                        style={{
                          transform: changePassword ? 'rotate(180deg)' : 'none',
                          transition: 'all 0.3s',
                        }}
                      >
                        <IconButton
                          onClick={() => setChangePassword(!changePassword)}
                        >
                          <Icon icon='arrow-icon' size={15} />
                        </IconButton>
                      </div>
                    </FlexContainer>

                    <Collapse in={changePassword} timeout='auto'>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            label='Contraseña'
                            placeholder='Escribe tu contraseña'
                            onChange={value => onFormChange(value, 'password')}
                            value={form.password}
                            margin='0 0 20px'
                            type='password'
                            error={errors.password}
                            width='100%'
                            disabled={loadingUpdate}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            label='Confirmación'
                            placeholder='Confirmación de contraseña'
                            onChange={value =>
                              onFormChange(value, 'passwordValidation')
                            }
                            value={form.passwordValidation}
                            margin='0 0 20px'
                            type='password'
                            error={errors.passwordValidation}
                            width='100%'
                            disabled={loadingUpdate}
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </FlexContainer>
                </>
              ) : (
                <MultipleInput
                  values={emailList}
                  placeholder=' Escribe los usuarios a invitar'
                  inputValue={emailsInput}
                  onChangeInput={value => {
                    setEmailInput(value);
                    setEmptyEmailsError('');
                  }}
                  onSubmit={() => {
                    const uniqueEmails = emailList.includes(emailsInput);
                    if (uniqueEmails) {
                      setEmailInput('');
                      setEmptyEmailsError('');
                    } else {
                      setEmailList(emailList.concat(emailsInput));
                      setEmailInput('');
                      setEmptyEmailsError('');
                    }
                  }}
                  onDelete={value => {
                    setEmailList(emailList.filter(item => item !== value));
                  }}
                  disabled={loadingUpdate || loadingSend}
                  error={emptyEmailsError}
                />
              )}
            </FlexContainer>
          </FlexContainer>

          <FlexContainer
            container
            padding='30px 30px'
            margin='0 0 30px'
            borderRadius='10px'
            backgroundColor={THEME.colors.backgroundColor}
            borderColor={THEME.colors.borderColor}
          >
            <FlexContainer container direction='column'>
              <Typography
                color={THEME.colors.primary}
                fontWeight={500}
                fontSize={17}
                margin='0 0 5px'
              >
                Agregar accesos al usuario*
              </Typography>
              <Typography
                color='text.secondary'
                fontSize={13}
                margin='0 0 15px'
              >
                Seleccione los accesos que deseas agregar al usuario
              </Typography>
              {errors.role_id && (
                <FlexContainer container justify='flex-end'>
                  <FlexContainer
                    borderColor={THEME.colors.error}
                    backgroundColor={THEME.colors.errorBg}
                    padding='2px 5px'
                    position='absolute'
                    borderRadius='4px'
                  >
                    <Typography fontSize={11} color={THEME.colors.error}>
                      Campo obligatorio
                    </Typography>
                  </FlexContainer>
                </FlexContainer>
              )}
              <RadioGroup
                aria-label='permissions'
                name='permissions'
                value={form.role_id}
                onChange={({ target }) => {
                  onFormChange(target.value, 'role_id');
                  setSelectedRole(target.value);
                }}
                row
              >
                {roles.map(role => (
                  <FormControlLabel
                    key={role.code}
                    value={role.id.toString()}
                    control={
                      <Radio
                        color='primary'
                        size='small'
                        checked={role.id.toString() === selectedRole}
                      />
                    }
                    label={<Typography fontSize={13}>{role.name}</Typography>}
                    disabled={loadingRoles || loadingUpdate || loadingSend}
                  />
                ))}
              </RadioGroup>
            </FlexContainer>
          </FlexContainer>

          {chosenOption === 'client' && (
            <RoleSelector
              title='Agregar acceso como cliente'
              listOptions={clientOptions}
              selectValue={form.client_id}
              onSelect={value => onFormChange(value, 'client_id')}
              placeholder='Seleccione un cliente'
              disabled={loadingClients || loadingUpdate || loadingSend}
            />
          )}

          {chosenOption === 'provider' && (
            <RoleSelector
              title='Agregar acceso como proveedor'
              subtitle='Seleccione la organización que el usuario tendrá.'
              listOptions={providerOptions}
              selectValue={form.provider_id}
              onSelect={value => onFormChange(value, 'provider_id')}
              placeholder='Seleccione un proveedor'
              disabled={loadingProviders || loadingUpdate || loadingSend}
            />
          )}

          <FlexContainer
            container
            padding='30px 30px'
            margin='0 0 30px'
            borderRadius='10px'
            backgroundColor={THEME.colors.backgroundColor}
            borderColor={THEME.colors.borderColor}
          >
            <FlexContainer container direction='column'>
              <Typography
                color={THEME.colors.primary}
                fontWeight={500}
                fontSize={17}
                margin='0 0 5px'
              >
                Agregar acceso a sedes
              </Typography>

              <Typography
                color='text.secondary'
                fontSize={13}
                margin='0 0 20px'
              >
                Agregue las organizaciones que el usuario tendrá.
              </Typography>

              <MultipleSelect
                options={branchOfficeOptions}
                values={selectedBranchOffices}
                onChange={option => {
                  const found = find(selectedBranchOffices, {
                    id: option.id,
                  });
                  if (found) {
                    setSelectedBranchOffices(
                      selectedBranchOffices.filter(
                        item => item.id !== option.id
                      )
                    );
                  } else {
                    setSelectedBranchOffices(
                      selectedBranchOffices.concat(option)
                    );
                  }
                }}
                onDelete={option => {
                  setSelectedBranchOffices(
                    selectedBranchOffices.filter(item => item.id !== option.id)
                  );
                }}
                margin='0 0 20px'
                width='100%'
                disabled={
                  loadingUpdate || loadingOffices || loadingAdmin || loadingSend
                }
              />
            </FlexContainer>
          </FlexContainer>
        </>
      )}

      <Button
        variant='contained'
        color='primary'
        padding='5px 30px'
        onClick={() => validateForm()}
        disabled={
          loadingRoles || loadingOffices || loadingUpdate || loadingSend
        }
      >
        {loadingUpdate && (
          <Spinner height='15px' spinnerSize={20} margin='0 8px 0 0' />
        )}
        {data ? 'Guardar' : 'Invitar'}
      </Button>
    </FlexContainer>
  );
};

export default EditAdmin;
