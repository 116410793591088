import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'react-leaflet';

const StyledTooltip = styled(Tooltip)`
  color: ${props => props.theme.colors.textPrimary};
  border-radius: 100px;
  border: none;
  box-shadow: none;
  background-color: ${props => props.theme.colors.secondaryBackground};
  display: flex;
  align-items: center;
  height: 20px;
  width: 20px;
  justify-content: center;
  font-size: 10px;

  &:before {
    content: none;
  }
`;

const MapTooltip: React.FC = ({ children }) => {
  return (
    <StyledTooltip permanent className='custom-tooltip'>
      {children}
    </StyledTooltip>
  );
};

export default MapTooltip;
