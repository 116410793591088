import { UserData, USER_SET, USER_CLEAR, USER_ONBOARDING } from './types';

export const setUser = (data: UserData) => {
  return {
    type: USER_SET,
    payload: data,
  };
};

export const toggleOnboarding = (data: boolean) => {
  return {
    type: USER_ONBOARDING,
    payload: data,
  };
};

export const clearUser = () => {
  return {
    type: USER_CLEAR,
  };
};
