import React from 'react';
import styled from 'styled-components';
import { FlexContainer, Icon, Typography } from 'components/atoms';
import { useTypedSelector } from 'store';
import THEME from 'util/styledTheme';
import { GroupProps } from './types';

const CustomButton = styled(FlexContainer)<{ right?: boolean }>`
  height: 100%;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  ${props =>
    props.right && `border-right: 1px solid ${props.theme.colors.borderColor};`}
  border-left: 1px solid ${props => props.theme.colors.borderColor};
  &:hover {
    background-color: ${props => props.theme.colors.backgroundColor};
  }
`;

const GroupButtons: React.FC<GroupProps> = ({ onRoutes }) => {
  const user = useTypedSelector(state => state.user);
  return (
    <FlexContainer height='100%' alignItems='center'>
      {user.permissions.includes('core.route.create') && (
        <CustomButton onClick={() => onRoutes()}>
          <Icon
            icon='route-gray-icon'
            size={14}
            color={THEME.colors.textSecondary}
          />
          <Typography fontSize='12px' color='text.secondary' margin='0 0 0 5px'>
            Hacer ruta
          </Typography>
        </CustomButton>
      )}
    </FlexContainer>
  );
};

export default GroupButtons;
