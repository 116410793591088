import React, { useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { Search } from 'components/molecules';
import useWindowSize from 'hooks/useWindowSize';
import THEME from 'util/styledTheme';
import FleetCard from './FleetCard';
import { FleetBlockProps } from './types';

const CardSkeleton: React.FC = () => (
  <FlexContainer
    container
    padding='10px 20px 10px 10px'
    borderColor={THEME.colors.borderColor}
    backgroundColor='#FFFFFF'
    justify='flex-end'
  >
    <FlexContainer direction='column' width='90%'>
      <Skeleton width='80%' height={25} />
      <Skeleton width='60%' height={25} />
      <Skeleton width='70%' height={25} />
    </FlexContainer>
  </FlexContainer>
);

const FleetBlock: React.FC<FleetBlockProps> = ({
  workers,
  loading,
  selectedWorkers,
  onSelect,
  toggleVisibility,
  showingWorkers,
  filterClick,
  vehicleFilter,
  multipleSelect,
}) => {
  const size = useWindowSize();
  const [search, setSearch] = useState<string>('');
  const [filteredWorkers, setFilteredWorkers] = useState<Array<any>>([]);

  const filterWorkers = () => {
    let filtered = workers;
    if (search) {
      filtered = workers.filter(worker => {
        const aux = {
          id: worker.id,
          first_name: worker.first_name,
          last_name: worker.last_name,
          phone: worker.phone,
          plate: worker.vehicle ? worker.vehicle.plate : '',
          vehicle: worker.vehicle,
        };
        return JSON.stringify(aux)
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    }
    setFilteredWorkers(filtered);
  };

  useEffect(() => {
    if (vehicleFilter) {
      const filtered = workers.filter(
        worker =>
          worker.vehicle &&
          worker.vehicle.vehicle_type &&
          worker.vehicle.vehicle_type.id === vehicleFilter
      );
      setFilteredWorkers(filtered);
    } else {
      setFilteredWorkers(workers);
    }
  }, [workers, vehicleFilter]);

  return (
    <FlexContainer
      container
      height='100%'
      overflow='auto'
      direction='column'
      borderColor={THEME.colors.borderColor}
      backgroundColor='#FFFFFF'
      borderRadius='5px'
      style={{ zIndex: 1002 }}
    >
      <div style={{ width: '100%' }}>
        <FlexContainer container padding='10px' direction='column'>
          <FlexContainer
            container
            alignItems='center'
            justify='space-between'
            margin='0 0 15px'
          >
            <Search
              onChangeValue={value => setSearch(value)}
              searchValue={search}
              onSubmit={() => filterWorkers()}
            />
            <Button
              variant='contained'
              color='secondary'
              margin='0 0 0 10px'
              padding='0 10px'
              onClick={() => {}}
            >
              <Icon icon='filter-icon' size={25} />
            </Button>
          </FlexContainer>
          <FlexContainer container alignItems='center' justify='space-between'>
            <FlexContainer alignItems='center' flex='1'>
              {multipleSelect && (
                <FlexContainer width='10%'>
                  <Checkbox
                    checked={
                      filteredWorkers.length > 0 &&
                      filteredWorkers.every(item =>
                        selectedWorkers.includes(item.id)
                      )
                    }
                    disableRipple
                    color='primary'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    style={{ padding: 0 }}
                    onChange={e => {
                      onSelect(
                        filteredWorkers.map(order => order.id),
                        e.target.checked
                      );
                    }}
                  />
                </FlexContainer>
              )}
              <Typography
                color='text.secondary'
                fontSize={13}
                margin={multipleSelect ? '0' : '0 0 0 29px'}
              >
                {`${filteredWorkers.length} workers encontrados`}
              </Typography>
            </FlexContainer>
            {size.width > 768 && (
              <FlexContainer
                onClick={() => {
                  toggleVisibility(
                    workers,
                    !(showingWorkers.length === workers.length)
                  );
                }}
              >
                {showingWorkers.length === workers.length ? (
                  <Icon icon='show-icon' size={20} />
                ) : (
                  <Icon icon='no-show-icon' size={20} />
                )}
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>
      </div>
      {loading ? (
        <>
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </>
      ) : (
        <>
          {filteredWorkers.map(worker => (
            <div key={worker.id} style={{ width: '100%' }}>
              <FleetCard
                data={worker}
                selected={selectedWorkers.includes(worker.id)}
                toggleSelect={checked => onSelect([worker.id], checked)}
                showWorker={showingWorkers.includes(worker.user_id)}
                toggleVisibility={visibility => {
                  toggleVisibility(
                    [worker],
                    visibility || !showingWorkers.includes(worker.user_id)
                  );
                }}
                onRouteClick={() => filterClick(worker, 'routes')}
                onOrderClick={() => filterClick(worker, 'orders')}
              />
            </div>
          ))}
        </>
      )}
    </FlexContainer>
  );
};

export default FleetBlock;
