import React from 'react';
import { FlexContainer } from 'components/atoms';

const EmailTemplates = ({ showTemplate, emailTemplate }) => {
  return (
    <FlexContainer
      container
      height='100%'
      margin='0 30px'
      alignItems='center'
      justify='center'
      direction='column'
    >
      {showTemplate(emailTemplate)}
    </FlexContainer>
  );
};

export default EmailTemplates;
