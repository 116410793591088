import React from 'react';
import { Redirect } from 'react-router-dom';
import { Typography, Button, FlexContainer } from 'components/atoms';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { useTypedSelector } from 'store';
import OnboardingImage from 'assets/images/OnboardingWelcomeLogo.png';

const Container = styled(FlexContainer)`
  padding: 40px;
  background-color: #f7f7fd;

  @media only screen and (min-width: 768px) {
    padding: 30px;
  }
`;

const InfoContainer = styled(FlexContainer)`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 35px;

  img {
    width: 100%;
    height: auto;
  }

  @media only screen and (min-width: 500px) {
    img {
      width: 350px;
    }
  }
  @media only screen and (min-width: 768px) {
    padding: 45px 55px;
  }
`;

const FirstStep = ({ changeStep }) => {
  const selectedOrganization = useTypedSelector(
    state => state.organization.selectedOrganization
  );

  return (
    <FlexContainer container height='100%'>
      {selectedOrganization ? (
        <Redirect to='/' />
      ) : (
        <Container
          container
          height='100%'
          direction='column'
          alignItems='center'
          justify='center'
        >
          <InfoContainer
            direction='column'
            justify='center'
            alignItems='center'
          >
            <img src={OnboardingImage} alt='logo_full' />
            <Typography
              fontSize='25px'
              fontWeight={600}
              textAlign='center'
              color='#000000'
              margin='40px 0 10px'
            >
              Únete a la revolución Aimo y empieza a operar
            </Typography>
            <Typography
              fontSize='15px'
              textAlign='center'
              color='#6F6F6F'
              lineHeight='30px'
              margin='8px 0 25px'
            >
              Crea tu catálogo digital en 1 minuto y vende online con tu propio
              link
            </Typography>
            <Grid container alignItems='center' direction='column'>
              <Button
                padding='22px 40px'
                borderRadius='16px'
                variant='contained'
                color='primary'
                onClick={() => changeStep(2)}
              >
                <Typography
                  fontWeight='700'
                  fontSize='15px'
                  color='white'
                  letterSpacing='0.5px'
                >
                  Continuar
                </Typography>
              </Button>
            </Grid>
          </InfoContainer>
        </Container>
      )}
    </FlexContainer>
  );
};

export default FirstStep;
