import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Avatar, Grid, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTypedSelector } from 'store';
import {
  Divider,
  FlexContainer,
  Icon,
  Typography,
  Status,
  Feedback,
  Button,
} from 'components/atoms';
import { FormControl } from 'components/molecules';
import { DataTable } from 'components/organisms';
import moment from 'moment';
import DefaultImage from 'assets/images/DefaultProfile.png';
import {
  getOrder,
  getPurchaseOrder,
  changePurchaseOrderStatus,
  getDigitalCatalogue,
  getChannels,
  getStatuses,
  getProvidersMinimal,
  changeOrderStatus,
  assignOrderProvider,
  confirmOrdersProvider,
} from 'services';
import { isEmpty } from 'lodash';
import {
  orderDetailFormatter,
  itemDescriptionFormatter,
  optionFormatter,
  orderDropOffDetailFormatter,
  providerFormatter,
} from 'util/functions';
import THEME from 'util/styledTheme';
import useRequest from 'hooks/useRequest';
import statusColors from 'util/statusColors';
import Minutes99 from 'assets/images/providers/99minutos.png';
import Olva from 'assets/images/providers/olva.jpg';
import Cabify from 'assets/images/providers/cabify.png';
import Glovo from 'assets/images/providers/glovo.svg';
import Savar from 'assets/images/providers/savar.png';
import PedidosYa from 'assets/images/providers/pedidosya.png';
import Logo from 'assets/images/DefaultLogo.png';
import Minutes99Sm from 'assets/images/providers/99minutos_sm.png';
import OlvaSm from 'assets/images/providers/olva_sm.png';
import CabifySm from 'assets/images/providers/cabify_sm.png';
import ShopifySm from 'assets/images/shopify_sm.png';
import PedidosYaSm from 'assets/images/providers/pedidosya_sm.png';
import { ReactComponent as Download } from 'assets/images/download.svg';
import { ReactComponent as ExternalLink } from 'assets/images/externalLink.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import { ReactComponent as ConfirmIcon } from 'assets/images/check-icon.svg';
import ArrowLeft from 'assets/images/arrow-left-icon.svg';
import Whatsapp from 'assets/images/whatsapp-green-icon.svg';
import TaskCollapse from './TaskCollapse';
import {
  EditProps,
  WorkerSectionProps,
  OrderSectionProps,
  FeedbackSectionProps,
  OrderEventProps,
  ProviderSectionProps,
} from './types';

export const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
`;

const StyledLink = styled.a`
  color: ${THEME.colors.primary};
  font-weight: 700;
  margin-left: 4px;
  font-size: 14px;
`;

const WhatsappContainer = styled(FlexContainer)`
  // margin: 5px 0;
  padding: 5px 8px;
  border: 1px solid #f0effb;
  border-radius: 8px;
  width: 100%;
`;

const WhatsappLink = styled.a`
  margin-left: 5px;
  color: #4d5369;
  text-decoration: none;
  line-height: 15px;
`;

const purchaseOrderStatus = [
  {
    id: 1,
    overwritten_label: 'Preparando',
    code: 'preparing',
  },
  {
    id: 2,
    overwritten_label: 'Listo para entregar',
    code: 'ready_to_ship',
  },
  {
    id: 3,
    overwritten_label: 'Entregado',
    code: 'shipped',
  },
  {
    id: 4,
    overwritten_label: 'Completado',
    code: 'completed',
  },
  {
    id: 5,
    overwritten_label: 'Cancelado',
    code: 'cancelled',
  },
  {
    id: 6,
    overwritten_label: 'Devuelto',
    code: 'returned',
  },
];

const currencies = [
  {
    id: 1,
    name: 'Sol (S/)',
    code: 'PEN',
    symbol: 'S/',
  },
  {
    id: 2,
    name: 'Dólar estadounidense ($)',
    code: 'USD',
    symbol: '$',
  },
  {
    id: 3,
    name: 'Peso mexicano ($)',
    code: 'MXN',
    symbol: '$',
  },
  {
    id: 4,
    name: 'Peso chileno ($)',
    code: 'CLP',
    symbol: '$',
  },
  {
    id: 5,
    name: 'Peso argentino ($)',
    code: 'ARS',
    symbol: '$',
  },
  {
    id: 6,
    name: 'Peso colombiano ($)',
    code: 'COP',
    symbol: '$',
  },
  {
    id: 7,
    name: 'Peso uruguayo ($U)',
    code: 'UYU',
    symbol: '$U',
  },
  {
    id: 8,
    name: 'Boliviano (Bs)',
    code: 'BOB',
    symbol: 'Bs',
  },
];

const purchaseOrderStatusName = [
  {
    id: 1,
    name: 'Nuevos',
    value: 'new',
    bg: '#E2F5EA',
    bgSeconndary: '#2DB267',
    color: '#2DB267',
  },
  {
    id: 2,
    name: 'En curso',
    value: 'in_progress',
    bg: '#FFF9CC',
    bgSeconndary: '#FFE000',
    color: '#4D5369',
  },
  {
    id: 3,
    name: 'Finalizados',
    value: 'finished',
    bg: '#FCDDDC',
    bgSeconndary: '#F1564F',
    color: '#F1564F',
  },
];

const paymentStatusOptions = [
  {
    id: 1,
    name: 'Por pagar',
    code: 'to_pay',
  },
  {
    id: 2,
    name: 'Pagado',
    code: 'payed',
  },
];

const salesChannelOptions = [
  {
    id: 1,
    name: 'Cálogo digital',
    code: 'digital_catalog',
  },
  {
    id: 2,
    name: 'Instagram Shop',
    code: 'instagram_shop',
  },
  {
    id: 3,
    name: 'Online',
    code: 'online',
  },
  {
    id: 4,
    name: 'Online',
    code: 'Online',
  },
];

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#100A55',
    color: '#FFF',
    fontSize: 11,
    marginBottom: 7,
    padding: 6,
  },
  arrow: {
    color: '#100A55',
  },
})(Tooltip);

const TasksOrderSkeleton: React.FC = () => (
  <FlexContainer height='400px' direction='column'>
    <FlexContainer
      justify='space-between'
      width='400px'
      direction='column'
      alignItems='center'
      height='200px'
    >
      <Skeleton variant='text' width='300px' height='100px' />
      <Skeleton variant='text' width='300px' height='100px' />
      <Skeleton variant='text' width='300px' height='100px' />
    </FlexContainer>
  </FlexContainer>
);

const OrderDetailSkeleton: React.FC = () => (
  <FlexContainer
    container
    direction='column'
    height='300px'
    justify='space-between'
  >
    <FlexContainer direction='column' justify='space-between' height='280px'>
      <FlexContainer width='1000px' justify='space-between'>
        <Skeleton variant='text' width='200px' height='50px' />
        <Skeleton variant='text' width='200px' height='50px' />
      </FlexContainer>
      <Divider orientation='horizontal' margin='15px 0 30px' />
    </FlexContainer>
    <FlexContainer justify='space-between'>
      <FlexContainer direction='column'>
        <FlexContainer width='300px' height='150px' justify='space-between'>
          <FlexContainer
            direction='column'
            height='100px'
            justify='space-between'
          >
            <Skeleton variant='text' width='200px' height='50px' />
            <FlexContainer width='200px' justify='space-between'>
              <Skeleton variant='text' width='80px' height='50px' />
              <Skeleton variant='text' width='80px' height='50px' />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer width='300px' height='150px' justify='space-between'>
          <FlexContainer
            direction='column'
            height='100px'
            justify='space-between'
          >
            <Skeleton variant='text' width='200px' height='50px' />
            <FlexContainer width='200px' justify='space-between'>
              <Skeleton variant='text' width='80px' height='50px' />
              <Skeleton variant='text' width='80px' height='50px' />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <TasksOrderSkeleton />
      <TasksOrderSkeleton />
    </FlexContainer>
  </FlexContainer>
);

const OrderSection: React.FC<OrderSectionProps> = ({
  orderCode,
  salesChannel,
  contactName,
  orderStatus,
  purchaseColorStatus,
  purchaseBgStatus,
  purchaseNameStatus,
  contactPhone,
  paymentMethod,
  paymentStatus,
  colorStatus,
  // labelStatus,
  // adminName,
  // adminEmail,
  orderType,
  trackingLink,
  orderTypeStatus,
  paymentStatussOptions,
  paymentMethodOptions,
  updatePurchaseStatus,
  purchaseOrderStatuses,
  onEdit,
  orderTypeStatusOptions,
  updateOrderStatus,
}) => {
  const toSelectPurchaseOption = {
    id: 'created',
    name: 'Creado',
  };

  // Values
  const [orderStatusValue, setOrderStatusValue] = useState('');
  const [paymentMethodValue, setPaymentMethodValue] = useState('');
  const [orderTypeStatusValue, setOrderTypeStatusValue] = useState('');

  return (
    <FlexContainer
      container
      direction='column'
      margin='10px 0'
      borderRadius='10px'
      borderColor={THEME.colors.borderColor}
      backgroundColor={THEME.colors.backgroundColor}
    >
      <FlexContainer container padding='15px' direction='column'>
        <Typography fontWeight={700} color='#000929'>
          Información
        </Typography>
        <FlexContainer container margin='10px 0'>
          <FlexContainer container>
            <Typography color='#808494' margin='0 10px 0 0'>
              # Pedido:
            </Typography>
          </FlexContainer>
          <FlexContainer container>
            <Typography color='#4D5369'>{orderCode}</Typography>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer container margin='0 0 12px'>
          <FlexContainer container>
            <Typography color='#808494' margin='0 10px 0 0'>
              Estado de orden:
            </Typography>
          </FlexContainer>
          <FlexContainer container>
            <FlexContainer container direction='column'>
              <FlexContainer
                container
                justify='center'
                backgroundColor={purchaseBgStatus}
                padding='5px 6px'
                borderRadius='8px'
              >
                <Typography fontWeight={600} color={purchaseColorStatus}>
                  {purchaseNameStatus}
                </Typography>
              </FlexContainer>
              <FlexContainer>
                <FormControl
                  control='select'
                  value={orderStatusValue || orderStatus}
                  options={purchaseOrderStatuses}
                  defaultOption={toSelectPurchaseOption}
                  onChange={value => {
                    updatePurchaseStatus({
                      status: value,
                    });
                    setOrderStatusValue(value);
                    onEdit();
                  }}
                  height='30px'
                  width='100%'
                  margin='5px 0 0'
                />
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        {salesChannel && (
          <FlexContainer container margin='0 0 12px'>
            <FlexContainer container>
              <Typography color='#808494' margin='0 10px 0 0'>
                Canal de venta:
              </Typography>
            </FlexContainer>
            <FlexContainer container>
              <Typography color='#4D5369'>{salesChannel}</Typography>
            </FlexContainer>
          </FlexContainer>
        )}
        <FlexContainer container margin='0 0 12px'>
          <FlexContainer container>
            <Typography color='#808494' margin='0 10px 0 0'>
              Contacto:
            </Typography>
          </FlexContainer>
          <FlexContainer container>
            <FlexContainer container direction='column'>
              <Typography color='#4D5369' fontWeight={700} margin='0 0 5px'>
                {contactName}
              </Typography>
              <WhatsappContainer alignItems='center'>
                <FlexContainer container alignItems='center'>
                  <img src={Whatsapp} alt='whatsapp' />
                  <WhatsappLink href={`https://wa.me/${contactPhone}`}>
                    {contactPhone}
                  </WhatsappLink>
                </FlexContainer>
                <img src={ArrowLeft} alt='arrow' />
              </WhatsappContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer container margin='0 0 12px'>
          <FlexContainer container>
            <Typography color='#808494' margin='0 10px 0 0'>
              Tipo de orden:
            </Typography>
          </FlexContainer>
          <FlexContainer container>
            <FlexContainer container direction='column'>
              <Typography color='#4D5369'>{orderType}</Typography>
              <FlexContainer>
                <FormControl
                  control='select'
                  value={orderTypeStatusValue || orderTypeStatus}
                  options={orderTypeStatusOptions}
                  onChange={value => {
                    updateOrderStatus(value);
                    setOrderTypeStatusValue(value);
                  }}
                  height='30px'
                  width='100%'
                  margin='5px 0 0'
                />
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer container margin='0 0 12px'>
          <FlexContainer container>
            <Typography color='#808494' margin='0 10px 0 0'>
              Método de pago:
            </Typography>
          </FlexContainer>
          <FlexContainer container>
            <FlexContainer>
              <FormControl
                control='select'
                value={paymentMethodValue || paymentMethod}
                options={paymentMethodOptions}
                onChange={value => {
                  updatePurchaseStatus({
                    payment_method: value,
                  });
                  setPaymentMethodValue(value);
                  onEdit();
                }}
                height='30px'
                width='100%'
              />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer container margin='0 0 12px'>
          <FlexContainer container>
            <Typography color='#808494' margin='0 10px 0 0'>
              Estado de pago:
            </Typography>
          </FlexContainer>
          <FlexContainer container>
            <FlexContainer>
              <FormControl
                control='select'
                value={paymentStatus}
                options={paymentStatussOptions}
                onChange={value => {
                  updatePurchaseStatus({
                    payment_status: value,
                  });
                  onEdit();
                }}
                height='30px'
                width='100%'
              />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer container alignItems='center' margin='5px 0'>
          <Icon size={15} icon='marker-icon' />
          <StyledLink
            href={trackingLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            Ver Tracking
          </StyledLink>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

const WorkerSection: React.FC<WorkerSectionProps> = ({
  workerPhoto,
  workerName,
  workerNumber,
  vehiclePlate,
  vehicleType,
}) => (
  <FlexContainer
    container
    margin='10px 0'
    borderRadius='10px'
    borderColor={THEME.colors.borderColor}
    backgroundColor={THEME.colors.backgroundColor}
  >
    <FlexContainer padding='15px' direction='column'>
      <Typography fontWeight={700}>Conductor</Typography>
      <FlexContainer margin='10px' alignItems='center'>
        <Avatar alt='worker_photo' src={workerPhoto || DefaultImage} />
        <Typography margin='0 0 0 10px' fontWeight='400'>
          {workerName || 'Sin Asignar'}
        </Typography>
      </FlexContainer>
      {vehicleType && (
        <Typography color={THEME.colors.textSecondary} margin='0 0 5px'>
          {`Tipo de vehículo: ${vehicleType}`}
        </Typography>
      )}
      {vehiclePlate && vehiclePlate !== '-' && (
        <Typography color={THEME.colors.textSecondary} margin='0 0 5px'>
          {`Placa:  ${vehiclePlate || 'Sin Asignar'}`}
        </Typography>
      )}
      {workerNumber && workerNumber !== '-' && (
        <Typography color={THEME.colors.textSecondary} margin='0 0 5px'>
          {`Celular: ${workerNumber || 'Sin Asignar'}`}
        </Typography>
      )}
    </FlexContainer>
  </FlexContainer>
);

const ProviderSection: React.FC<ProviderSectionProps> = ({
  tradename,
  externalCode,
  deliveryPrice,
  deliveryCurrency,
  providerPdf,
  providerTrackLink,
  providerOptions,
  updateProvider,
  orderStatus,
  confirmProvider,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [providerId, setProviderId] = useState();

  const copyToClipboard = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1300);
  };

  useEffect(() => {
    if (providerOptions.length > 0 && tradename !== null) {
      const providerIdentifier =
        providerOptions.find(provider => provider.name === tradename) || null;

      if (providerIdentifier) {
        setProviderId(providerIdentifier.id);
      } else {
        setProviderId(null);
      }
    }
  }, [providerOptions, tradename]);

  return (
    <FlexContainer
      container
      margin='10px 0'
      borderRadius='10px'
      borderColor={THEME.colors.borderColor}
      backgroundColor={THEME.colors.backgroundColor}
    >
      <FlexContainer padding='15px' direction='column'>
        <Typography fontWeight={700} margin='10px 0'>
          Proveedor
        </Typography>
        <FlexContainer container>
          {orderStatus !== 'in_progress' ? (
            <FlexContainer direction='column'>
              <Typography
                color={THEME.colors.textSecondary}
                margin='0 5px 5px 0'
              >
                {tradename}
              </Typography>
              <FlexContainer margin='5px 0 0' alignItems='center'>
                <FlexContainer>
                  <FormControl
                    control='select'
                    value={providerId}
                    options={providerOptions}
                    onChange={value => {
                      updateProvider(value);
                    }}
                    height='30px'
                  />
                </FlexContainer>
                <CustomTooltip
                  arrow
                  placement='top'
                  title='Confirmar proveedor'
                >
                  <div style={{ display: 'inline-block' }}>
                    <FlexContainer
                      width='26px'
                      margin='0 0 0 5px'
                      borderRadius='50%'
                      padding='5px'
                      style={{
                        border: '1px solid #DBD9F5',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        confirmProvider();
                      }}
                    >
                      <ConfirmIcon />
                    </FlexContainer>
                  </div>
                </CustomTooltip>
              </FlexContainer>
            </FlexContainer>
          ) : (
            <Typography color={THEME.colors.textSecondary} margin='0 5px 5px 0'>
              {tradename}
            </Typography>
          )}

          {tradename && tradename.startsWith('Olva') && (
            <StyledLink
              href='https://www.olvacourier.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <ExternalLink />
            </StyledLink>
          )}
        </FlexContainer>

        {externalCode && (
          <FlexContainer alignItems='center' margin='0 0 5px'>
            <Typography color={THEME.colors.textSecondary} margin='0 5px 0 0'>
              {`Código: ${externalCode}`}
            </Typography>
            <CopyToClipboard text={externalCode} onCopy={copyToClipboard}>
              <Button margin='0' padding='0 10px' height='25px'>
                <CopyIcon />
              </Button>
            </CopyToClipboard>
            {isCopied && (
              <Typography
                color={THEME.colors.textSecondary}
                margin='0 5px'
                fontSize={12}
              >
                Copiado!
              </Typography>
            )}
          </FlexContainer>
        )}

        {tradename && (
          <Typography color={THEME.colors.textSecondary} margin='0 0 5px'>
            {`Precio de envío: ${getSymbolFromCurrency(
              deliveryCurrency
            )}${deliveryPrice || '-'}`}
          </Typography>
        )}

        {providerPdf && (
          <FlexContainer container alignItems='center' margin='5px 0'>
            <Download />
            <StyledLink
              href={providerPdf}
              target='_blank'
              rel='noopener noreferrer'
            >
              Descargar Guía
            </StyledLink>
          </FlexContainer>
        )}
        {providerTrackLink && (
          <FlexContainer container alignItems='center' margin='5px 0'>
            <ExternalLink />
            <StyledLink
              href={providerTrackLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              {`Ir al tracking de ${tradename}`}
            </StyledLink>
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

const FeedbackSection: React.FC<FeedbackSectionProps> = ({
  deliveryFeedback,
  productFeedback,
  feedbackComment,
}) => (
  <FlexContainer
    container
    margin='10px 0'
    borderRadius='10px'
    borderColor={THEME.colors.borderColor}
    backgroundColor={THEME.colors.backgroundColor}
  >
    <FlexContainer padding='15px' direction='column'>
      <Typography fontWeight={700}>Feedback</Typography>
      <Typography margin='10px 10px 10px 0' color={THEME.colors.textSecondary}>
        Feedback del Delivery
      </Typography>
      <Feedback feedbackText={productFeedback} />
      <Typography margin='10px 10px 10px 0' color={THEME.colors.textSecondary}>
        Satisfacción del producto
      </Typography>
      <Feedback feedbackText={deliveryFeedback} />
      <Typography margin='10px 10px 10px 0' color={THEME.colors.textSecondary}>
        Feedback del Delivery
      </Typography>
      <Typography>{feedbackComment}</Typography>
    </FlexContainer>
  </FlexContainer>
);

const OrderEvent: React.FC<OrderEventProps> = ({
  orderIndex,
  createdAt,
  userName,
  eventName,
  userType,
}) => (
  <FlexContainer container backgroundColor={THEME.colors.backgroundColor}>
    <FlexContainer direction='column' alignItems='center'>
      <FlexContainer
        height='25px'
        width='25px'
        borderRadius='50px'
        alignItems='center'
        justify='center'
        backgroundColor={THEME.colors.borderColor}
      >
        {orderIndex}
      </FlexContainer>
      <FlexContainer borderColor={THEME.colors.borderColor} height='80px' />
    </FlexContainer>
    <FlexContainer direction='column' margin='0 10px'>
      <Typography fontWeight={700}>{eventName}</Typography>
      <Typography color={THEME.colors.textSecondary}>{createdAt}</Typography>
      <Typography color={THEME.colors.textSecondary}>
        {`${userName} / ${userType}`}
      </Typography>
    </FlexContainer>
  </FlexContainer>
);

const EditOrder: React.FC<EditProps> = ({
  data,
  onClose,
  routeCode,
  view,
  onEdit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const branchOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );

  // API calls
  const [orderDetails, fetchOrderDetails, orderDetailsLoading] = useRequest(
    getPurchaseOrder
  );
  const [, updateStatus, , , , statusRequest] = useRequest(
    changeOrderStatus,
    null
  );
  const [
    ,
    updatePurchaseStatus,
    ,
    ,
    errorPurchaseRequest,
    statusPurchaseRequest,
  ] = useRequest(changePurchaseOrderStatus, null);
  const [
    ,
    providerAssign,
    ,
    ,
    errorProviderAssign,
    statusAssignRequest,
  ] = useRequest(assignOrderProvider, null);
  const [
    ,
    confirmProviders,
    ,
    ,
    errorConfirmProvider,
    statusConfirmRequest,
  ] = useRequest(confirmOrdersProvider, null);
  const [
    digitalCatalogue,
    fetchDigitalCatalogue,
    loadingDigitalCatalogue,
  ] = useRequest(getDigitalCatalogue);
  const [channels, fetchChannels, loadingChannels] = useRequest(getChannels);
  const [allStatus, fetchAllStatus, loadingAllStatus] = useRequest(
    getStatuses,
    []
  );
  const [providers, fetchProviders] = useRequest(getProvidersMinimal, null);
  // Values
  const [assignPurchaseOptions, setAssignPurchaseOptions] = useState([]);
  const [catalogues, setCatalogues] = useState([]);
  const [salesChannelId, setSalesChannelId] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [orderDataDetail, setOrderDataDetail] = useState([]);
  const [taskOrderPoint, setTaskOrderPoint] = useState<any>([]);
  const [itemList, setItemList] = useState([]);
  const [deliveryId, setDeliveryId] = useState(null);
  const [referenceCode, setReferenceCode] = useState('');
  const [route, setRoute] = useState('');
  const [batch, setBatch] = useState('');
  const [paymentStatusesOptions, setPaymentStatusesOptions] = useState([]);
  const [allStatusOptions, setStatusOptions] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);

  useEffect(() => {
    if (view === 'routes') {
      fetchOrderDetails(data.order.id);

      if (data.order.reference_code) {
        setReferenceCode(data.order.reference_code);
      } else {
        setReferenceCode('');
      }

      if (data.order.batch) {
        setBatch(data.order.batch);
      } else {
        setBatch('');
      }

      setRoute(routeCode);
    } else {
      fetchOrderDetails(data.id);

      if (data.reference_code) {
        setReferenceCode(data.reference_code);
      } else {
        setReferenceCode('');
      }

      if (data.route && data.route.code) {
        setRoute(data.route.code);
      } else {
        setRoute('');
      }

      if (data.batch !== '') {
        setBatch(data.batch);
      } else {
        setBatch('');
      }
    }
    fetchChannels();
    fetchAllStatus({
      model_types: 'order',
      status_types: 'manual,automatic',
      page_size: 0,
    });
    fetchProviders({
      organization_branch_office_id: branchOfficeId,
      routes: data.id,
    });
  }, [
    fetchOrderDetails,
    data.id,
    fetchChannels,
    fetchAllStatus,
    fetchProviders,
  ]);

  useEffect(() => {
    if (!isEmpty(orderDetails)) {
      setOrderDataDetail(orderDetails);

      if (!isEmpty(orderDetails.items)) {
        const items = itemDescriptionFormatter([orderDetails]);
        setItemList(items[0]);
      }
    }
  }, [orderDetails]);

  useEffect(() => {
    setAssignPurchaseOptions(
      optionFormatter(purchaseOrderStatus, {
        id: item => item.code,
        name: item => item.overwritten_label,
      })
    );
  }, [purchaseOrderStatus]);

  useEffect(() => {
    setStatusOptions(
      optionFormatter(allStatus, {
        id: item => item.code,
        name: item => item.overwritten_label,
      })
    );
  }, [allStatus]);

  useEffect(() => {
    setTaskOrderPoint(orderDropOffDetailFormatter([orderDataDetail]));
  }, [orderDataDetail]);

  useEffect(() => {
    if (!isEmpty(channels)) {
      setCatalogues(
        channels.filter(channel => channel.platform === 'digital_catalog')
      );
      setSalesChannelId(
        channels.find(channel => channel.platform === 'digital_catalog').id
      );
    } else {
      setCatalogues([]);
    }
  }, [channels]);

  useEffect(() => {
    if (salesChannelId !== '' || salesChannelId === undefined) {
      fetchDigitalCatalogue(salesChannelId);
    }
  }, [salesChannelId]);

  useEffect(() => {
    if (!isEmpty(digitalCatalogue)) {
      if (
        digitalCatalogue.payment_methods &&
        digitalCatalogue.payment_methods.length > 0
      ) {
        setPaymentMethods(
          Array.from(digitalCatalogue.payment_methods).map((method: any) => {
            return {
              id: method.name,
              name: method.name.charAt(0).toUpperCase() + method.name.slice(1),
            };
          })
        );
      }
    }
  }, [digitalCatalogue]);

  useEffect(() => {
    if (!isEmpty(taskOrderPoint)) {
      const taskDelivery = taskOrderPoint.filter(
        task => task.task_name === 'Entrega'
      );
      setDeliveryId(taskDelivery[0].id);
    }
  }, [taskOrderPoint]);

  useEffect(() => {
    setPaymentStatusesOptions(
      optionFormatter(paymentStatusOptions, {
        id: item => item.code,
        name: item => item.name,
      })
    );
  }, [paymentStatusOptions]);

  useEffect(() => {
    if (providers) {
      if (providers.length === 0) {
        enqueueSnackbar('No se encontraron coincidencias para su búsqueda.', {
          variant: 'warning',
        });
      } else {
        const pickImage = code => {
          switch (code) {
            case '99minutos':
              return Minutes99;
            case 'olva':
              return Olva;
            case 'cabify':
              return Cabify;
            case 'glovo':
              return Glovo;
            case 'savar':
              return Savar;
            case 'pedidosya':
              return PedidosYa;
            default:
              return '';
          }
        };
        setProviderOptions(
          providerFormatter(providers, {
            id: option => option.id,
            name: option => option.business_name,
            phone: option => option.phone,
            logo: option =>
              option.code ? pickImage(option.code) : option.logo || Logo,
          })
        );
      }
    }
  }, [providers]);

  useEffect(() => {
    if (statusPurchaseRequest === 200) {
      enqueueSnackbar('Se actualizó el estado de la órden correctamente.', {
        variant: 'success',
      });
      onEdit();
    } else if (statusPurchaseRequest && statusPurchaseRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusPurchaseRequest, enqueueSnackbar]);

  useEffect(() => {
    if (statusRequest === 200) {
      enqueueSnackbar('Se actualizó el estado de las órdenes correctamente.', {
        variant: 'success',
      });
      onEdit();
    } else if (statusRequest && statusRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusRequest, enqueueSnackbar]);

  useEffect(() => {
    if (statusAssignRequest === 200) {
      enqueueSnackbar('Se asignó el provider a las órdenes correctamente.', {
        variant: 'success',
      });
      onEdit();
    } else if (statusRequest && statusRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusAssignRequest]);

  useEffect(() => {
    if (errorProviderAssign && errorProviderAssign.data) {
      if (errorProviderAssign.data.errors.length > 0) {
        errorProviderAssign.data.errors.forEach(error => {
          if (typeof error === 'string') {
            if (
              errorProviderAssign.data.errors[0] ===
              'Sameday deliveries are not available.'
            ) {
              enqueueSnackbar('Sameday no está disponible', {
                variant: 'error',
              });
            } else if (
              errorProviderAssign.data.errors[0] ===
              'Error. Cabify only accepts immediate orders'
            ) {
              enqueueSnackbar('Cabify solo acepta órdenes inmediatas', {
                variant: 'error',
              });
            } else if (
              errorProviderAssign.data.errors[0] ===
              'Error. PedidosYa only accepts immediate orders.'
            ) {
              enqueueSnackbar('PedidosYa solo acepta órdenes inmediatas', {
                variant: 'error',
              });
            } else if (
              errorProviderAssign.data.errors[0].includes(
                'No existe un tipo de servivcio para la orden.'
              )
            ) {
              enqueueSnackbar('No existe un tipo de servivcio para la orden', {
                variant: 'error',
              });
            } else {
              enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
                variant: 'error',
              });
            }
          } else if (error.order_ids) {
            if (error.order_ids[0].includes('Invalid pk')) {
              enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
                variant: 'error',
              });
            }
          } else {
            enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
              variant: 'error',
            });
          }
        });
        // if (
        //   errorProviderAssign.data.errors[0].order_ids &&
        //   errorProviderAssign.data.errors[0].order_ids.includes('Invalid pk')
        // ) {
        //   enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        //     variant: 'error',
        //   });
        // }  else {
        //   enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        //     variant: 'error',
        //   });
        // }
      }
    }
  }, [errorProviderAssign, enqueueSnackbar]);

  useEffect(() => {
    if (errorPurchaseRequest && errorPurchaseRequest.data) {
      if (errorPurchaseRequest.data.detail) {
        enqueueSnackbar('No se encontró la orden de compra', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
          variant: 'error',
        });
      }
    }
  }, [errorPurchaseRequest, enqueueSnackbar]);

  useEffect(() => {
    if (
      errorConfirmProvider &&
      errorConfirmProvider.data &&
      errorConfirmProvider.data.errors
    ) {
      if (errorConfirmProvider.data.errors[0] === 'Insufficient balance.') {
        enqueueSnackbar('Saldo insuficiente.', {
          variant: 'error',
        });
      }
    }
  }, [errorConfirmProvider]);

  useEffect(() => {
    if (statusConfirmRequest === 204) {
      enqueueSnackbar(
        'Se confirmo el proveedor de las órdenes correctamente.',
        {
          variant: 'success',
        }
      );
    } else if (statusConfirmRequest && statusConfirmRequest !== 204) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusConfirmRequest, enqueueSnackbar]);

  const purchaseStatusName = (status: string) => {
    switch (status) {
      case 'created':
        return purchaseOrderStatusName[0];
      case 'preparing':
      case 'ready_to_ship':
      case 'shipped':
        return purchaseOrderStatusName[1];
      case 'completed':
      case 'cancelled':
      case 'returned':
        return purchaseOrderStatusName[2];
      default:
        return null;
    }
  };

  const headers = useMemo(
    () => [
      {
        label: 'Código',
        id: 'code',
        cell: row => row.code,
      },
      {
        label: 'Descripción',
        id: 'description',
        cell: row => row.description,
      },
      {
        label: 'Medidas',
        id: 'measures',
        cell: row => `${row.height}x${row.width}cm`,
      },
      {
        label: 'Peso',
        id: 'weight',
        cell: row => `${row.weight}kg`,
      },
      {
        label: 'Cantidad',
        id: 'quantity',
        cell: row => `${row.quantity}`,
      },
      {
        label: 'Precio',
        id: 'price',
        cell: row => `S/.${row.price ? row.price : '0'}`,
      },
    ],
    [itemList]
  );

  return (
    <FlexContainer
      width='1200px'
      position='relative'
      padding='20px 50px 50px'
      direction='column'
    >
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      {orderDetailsLoading ? (
        <FlexContainer justify='space-between'>
          <OrderDetailSkeleton />
        </FlexContainer>
      ) : (
        <>
          <FlexContainer container justify='space-between'>
            <FlexContainer alignItems='center'>
              <Typography fontSize={18} padding='0 5px'>
                Orden
              </Typography>
              <FlexContainer
                padding='5px'
                borderRadius='5px'
                backgroundColor={THEME.colors.borderColor}
                justify='center'
                width='fit-content'
              >
                <Typography fontWeight={600} color='text.secondary'>
                  {referenceCode}
                </Typography>
              </FlexContainer>
              {route && (
                <FlexContainer
                  padding='5px'
                  justify='center'
                  width='fit-content'
                >
                  <Typography
                    fontWeight={600}
                    color='text.secondary'
                    padding='0 5px'
                  >
                    Ruta:
                  </Typography>
                  <Typography fontWeight={600} color='text.secondary'>
                    {route}
                  </Typography>
                </FlexContainer>
              )}

              {batch && (
                <FlexContainer
                  padding='5px'
                  justify='center'
                  width='fit-content'
                >
                  <Typography
                    fontWeight={600}
                    color='text.secondary'
                    padding='0 5px'
                  >
                    Batch:
                  </Typography>
                  <Typography color='text.secondary'>{batch}</Typography>
                </FlexContainer>
              )}
            </FlexContainer>
            <FlexContainer alignItems='center'>
              <Icon size={24} icon='calendar-icon' />
              <Typography fontWeight='300'>
                {data ? moment(data.created_at).format('dddd, LL') : ''}
              </Typography>
            </FlexContainer>
          </FlexContainer>
          <Divider orientation='horizontal' margin='15px 0 30px' />
          <FlexContainer container justify='space-between'>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FlexContainer container direction='column'>
                  <OrderSection
                    orderCode={orderDetails.code}
                    salesChannel={
                      orderDetails.sales_channel &&
                      salesChannelOptions.find(
                        channel => channel.code === orderDetails.sales_channel
                      ).name
                    }
                    contactName={
                      orderDetails.customer && orderDetails.customer.full_name
                    }
                    contactPhone={
                      orderDetails.customer &&
                      orderDetails.customer.phone_number
                    }
                    paymentMethod={orderDetails.payment_method}
                    paymentStatus={orderDetails.payment_status}
                    purchaseColorStatus={
                      orderDetails.status &&
                      purchaseStatusName(orderDetails.status).color
                    }
                    purchaseBgStatus={
                      orderDetails.status &&
                      purchaseStatusName(orderDetails.status).bg
                    }
                    purchaseNameStatus={
                      orderDetails.status &&
                      purchaseStatusName(orderDetails.status).name
                    }
                    orderStatus={orderDetails.status && orderDetails.status}
                    purchaseOrderStatuses={assignPurchaseOptions}
                    colorStatus={
                      (orderDetails.order &&
                        statusColors[orderDetails.order.last_status.code]) ||
                      statusColors.default
                    }
                    orderType={
                      orderDetails.order_type &&
                      orderDetails.order_type.charAt(0).toUpperCase() +
                        orderDetails.order_type.slice(1)
                    }
                    trackingLink={
                      orderDetails.order && orderDetails.order.tracking_link
                    }
                    orderTypeStatus={
                      orderDetails.order &&
                      orderDetails.order.last_status &&
                      orderDetails.order.last_status.code
                    }
                    paymentStatussOptions={paymentStatusesOptions}
                    paymentMethodOptions={paymentMethods}
                    updatePurchaseStatus={value =>
                      updatePurchaseStatus(value, orderDetails.id)
                    }
                    updateOrderStatus={value => {
                      updateStatus({
                        order_ids: [orderDetails.order.id],
                        status: value,
                        organization_branch_office_id: branchOfficeId,
                      });
                    }}
                    onEdit={() => onEdit()}
                    orderTypeStatusOptions={allStatusOptions}
                  />
                  {orderDetails.status === 'completed' &&
                  !orderDetails.worker &&
                  !orderDetails.worker_name ? null : (
                    <WorkerSection
                      workerPhoto={orderDetails.worker_photo || DefaultImage}
                      workerName={orderDetails.worker_name}
                      vehiclePlate={orderDetails.vehicle_plate}
                      workerNumber={orderDetails.worker_phone}
                      vehicleType={orderDetails.vehicle_type_name}
                    />
                  )}

                  <ProviderSection
                    tradename={
                      orderDetails.order &&
                      orderDetails.order.provider &&
                      orderDetails.order.provider.business_name
                    }
                    externalCode={
                      orderDetails.order &&
                      orderDetails.order.provider &&
                      orderDetails.order.provider
                        .external_provider_reference_code
                    }
                    deliveryPrice={
                      orderDetails.order && orderDetails.order.delivery_price
                    }
                    deliveryCurrency={
                      orderDetails.order &&
                      orderDetails.order.delivery_price_currency
                    }
                    providerPdf={
                      orderDetails.order &&
                      orderDetails.order.external_provider_file
                    }
                    providerTrackLink={
                      orderDetails.order &&
                      orderDetails.order.provider &&
                      orderDetails.order.provider
                        .external_provider_tracking_link
                    }
                    providerOptions={providerOptions}
                    updateProvider={value => {
                      providerAssign({
                        order_ids: [`${orderDetails.order.id}`],
                        provider_id: value,
                      });
                    }}
                    confirmProvider={() => {
                      confirmProviders({
                        order_ids: [orderDetails.order.id],
                      });
                    }}
                    orderStatus={
                      orderDetails.order &&
                      orderDetails.order.last_status &&
                      orderDetails.order.last_status.status_stage
                    }
                  />

                  {orderDetails.delivery_feedback && (
                    <FeedbackSection
                      deliveryFeedback={orderDetails.delivery_feedback}
                      productFeedback={orderDetails.product_feedback}
                      feedbackComment={orderDetails.feedback_comment}
                    />
                  )}
                </FlexContainer>
              </Grid>
              <Grid item xs={6}>
                <FlexContainer container>
                  <FlexContainer container direction='column' margin='10px'>
                    {taskOrderPoint.map(task => (
                      <TaskCollapse
                        key={task.id}
                        taskOrderPoint={taskOrderPoint}
                        orderDataDetail={orderDataDetail}
                        taskId={task.id}
                        deliveryId={deliveryId}
                        externalProviderPhotos={
                          orderDetails.external_provider_evidence_photos
                        }
                      />
                    ))}
                    <FlexContainer
                      container
                      direction='column'
                      margin='5px 0 0'
                    >
                      <Typography fontWeight={700} color='#000929'>
                        Detalle de entrega
                      </Typography>
                      <FlexContainer container direction='column'>
                        {itemList && itemList.length !== 0 ? (
                          <>
                            <DataTable
                              headers={headers.filter(header => header)}
                              data={itemList}
                              onChangePage={() => {}}
                              totalItems={0}
                              totalPages={0}
                              pageSize={0}
                              page={1}
                              showPagination={false}
                            />
                            <FlexContainer container justify='flex-end'>
                              <Typography fontWeight={700}>
                                Total:{' '}
                                {orderDetails.order &&
                                  currencies.find(
                                    currency =>
                                      currency.code ===
                                      orderDetails.order.delivery_price_currency
                                  ).symbol}
                                {orderDetails.items_value}
                              </Typography>
                            </FlexContainer>
                          </>
                        ) : (
                          <Typography
                            color={THEME.colors.textSecondary}
                            margin='10px 0 5px'
                          >
                            Sin paquetes
                          </Typography>
                        )}
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
              </Grid>
            </Grid>
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );
};

export default EditOrder;
