import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { Drawer, Dialog } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import {
  Button,
  FlexContainer,
  Icon,
  Pill,
  Typography,
} from 'components/atoms';
import { Cell } from 'components/molecules';
import {
  FiltersContainer,
  SearchWithFilter,
  DataTable,
} from 'components/organisms';
import {
  getOrganization,
  getAdmins,
  getBranchOffices,
  getRoles,
  deleteAdmin,
  deletePendingInvitations,
} from 'services';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import { optionFormatter } from 'util/functions';
import { ReactComponent as MoreOptions } from 'assets/images/filter_icon.svg';
import EditAdmin from './Edit';

const initialFilters = [
  {
    label: 'Rol',
    placeholder: 'Seleccione rol',
    name: 'role',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
    permission: 'core.role.list',
  },
  {
    label: 'Estado',
    placeholder: 'Seleccione estado',
    name: 'is_active',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [
      {
        id: 'true',
        name: 'Activo',
      },
      {
        id: 'false',
        name: 'Inactivo',
      },
    ],
  },
  {
    label: 'Sucursal',
    placeholder: 'Seleccione una opción',
    name: 'branch_office_id',
    defaultOption: {
      id: '',
      name: 'Todas',
    },
    options: [],
    permission: 'core.branch_office.list',
  },
];

const options = [
  {
    name: 'Nombres',
    id: 'full_name',
  },
  {
    name: 'Doc. de Identidad',
    id: 'identity_document',
  },
];

const MoreOptionIcon = styled(MoreOptions)`
  .cls-2 {
    stroke: #8c8cb1;
  }

  ${({ active }) =>
    active &&
    `
    .cls-2{
      stroke: #404071;
    }
  `}
`;

const Admin: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const user = useTypedSelector(state => state.user);
  const selectedOrganization = useTypedSelector(
    state => state.organization.selectedOrganization
  );

  const [values, setValues] = useState({
    role: null,
    is_active: null,
    branch_office_id: null,
  });
  // API calls
  const [organization, fetchOrganization] = useRequest(getOrganization);
  const [admins, fetchAdmins, loadingAdmins, pageData] = useRequest(
    getAdmins,
    []
  );
  const [branchOffices, fetchBranchOffices, loadingOffices] = useRequest(
    getBranchOffices,
    []
  );
  const [roles, fetchRoles, loadingRoles] = useRequest(getRoles, []);
  const [, adminDelete, , , , statusRequest] = useRequest(deleteAdmin, null);
  const [, deleteInvitation, , , , deleteStatusRequest] = useRequest(
    deletePendingInvitations
  );

  // Values
  const [filters, setFilters] = useState(initialFilters);
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [editData, setEditData] = useState(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [usersList, setUserList] = useState([]);
  const [userDeleted, setUserDeleted] = useState(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openPendingConfirm, setOpenPendingConfirm] = useState<boolean>(false);
  const [openChangePlan, setOpenChangePlan] = useState<boolean>(false);
  const [invitationLink, setInvitationLink] = useState<string>('');

  const [showOptions, setShowOptions] = useState<boolean>(false);

  const scriptURL =
    'https://script.google.com/macros/s/AKfycbxKyOjxjSquJYbnUSFGD5nYUixC2GihoI4FWYLZ3K5A8vBdKlZK5py2EYWXG3mrWdlF/exec';

  const requestAdmins = (currentPage: number) => {
    const params = {
      is_active: values.is_active,
      role: values.role,
      branch_office_id: values.branch_office_id,
      page: currentPage,
    };

    if (searchFilter !== '') {
      params[searchFilter] = searchInput;
    }
    setPage(currentPage);
    fetchAdmins(params);
    fetchOrganization(selectedOrganization.id);
  };

  const handleChangePlan = () => {
    const message = 'Quisiera cambiar de plan por favor.';

    const data = `?callback=ctrlq&Nombre=${user.full_name}&Email=${
      user.email
    }&Telefono=${user.phone}&Organizacion=${organization.tradename}&Pais=${
      organization.country_name
    }&Mensaje=${message}&Fecha=${new Date().toLocaleString()}`;
    fetch(`${scriptURL}${data}`, { method: 'POST', mode: 'no-cors' })
      .then(() => {
        console.log('Successful!');
      })
      .catch(error => console.error('Error!', error.message));

    window.open(
      `https://wa.me/51946565757?text=¡Hola! Soy ${user.full_name} de la organización ${organization.business_name} (${organization.id}) y quisiera cambiar de plan por favor.`
    );
  };

  useEffect(() => {
    fetchBranchOffices({
      page_size: 0,
    });
    fetchRoles({
      page_size: 0,
    });
  }, [fetchBranchOffices, fetchRoles]);

  useEffect(() => {
    requestAdmins(page);
  }, []);

  useEffect(() => {
    setUserList(admins);
  }, [admins]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    if (updatedFilters[2].options.length === 0) {
      updatedFilters[2].options = optionFormatter(branchOffices);
      setFilters(updatedFilters);
    }
  }, [branchOffices, filters]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    if (updatedFilters[0].options.length === 0) {
      updatedFilters[0].options = optionFormatter(roles);
      setFilters(updatedFilters);
    }
  }, [roles, filters]);

  useEffect(() => {
    if (statusRequest === 204) {
      enqueueSnackbar('Se eliminó el usuario correctamente.', {
        variant: 'success',
      });
      const filteredList = usersList.filter(
        userObj => userObj.id !== userDeleted
      );
      setUserList(filteredList);
    } else if (statusRequest && statusRequest !== 204) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusRequest, enqueueSnackbar]);

  useEffect(() => {
    if (deleteStatusRequest === 204) {
      enqueueSnackbar('Se eliminó la invitación pendiente con éxito.', {
        variant: 'success',
      });
    } else if (deleteStatusRequest && deleteStatusRequest !== 204) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [deleteStatusRequest, enqueueSnackbar]);

  const headers = useMemo(
    () => [
      {
        label: <Typography margin='0 0 0 20px'>Nombres</Typography>,
        id: 'full_name',
        cell: row => (
          <FlexContainer margin='0 0 0 20px'>
            {row.user ? (
              <Cell.UserAvatar
                avatar={row.photo}
                active={row.is_active}
                primary={
                  (row.user.first_name !== '' && row.user.last_name !== '') ||
                  row.user.first_name !== '' ||
                  row.user.last_name !== ''
                    ? `${row.user.first_name} ${row.user.last_name}`
                    : 'Aimo usuario'
                }
                onClick={() => {
                  if (user.permissions.includes('core.administrator.update')) {
                    setEditData(row);
                    setOpenEdit(true);
                  }
                }}
              />
            ) : (
              <FlexContainer alignItems='center'>
                <FlexContainer
                  borderRadius='50px'
                  height='42px'
                  width='42px'
                  backgroundColor={THEME.colors.secondaryBackground}
                  alignItems='center'
                  justify='center'
                >
                  <Icon size='30px' icon='person-icon' />
                </FlexContainer>
                <Typography fontWeight='700' margin='0 0 0 15px'>
                  Esperando Confirmación
                </Typography>
              </FlexContainer>
            )}
          </FlexContainer>
        ),
      },
      {
        label: 'Proveedor',
        id: 'provider',
        cell: row =>
          row.provider && (
            <Pill key={row.provider.id} padding='3px 10px'>
              {row.provider.business_name}
            </Pill>
          ),
      },
      {
        label: 'Cliente',
        id: 'client',
        cell: row =>
          row.client && (
            <Pill key={row.client.id} padding='3px 10px'>
              {row.client.name}
            </Pill>
          ),
      },
      {
        label: 'Teléfono',
        id: 'phone',
        cell: row => row.phone,
      },
      {
        label: 'E-mail',
        id: 'email',
        cell: row => (
          <FlexContainer>
            {row.user ? (
              <Typography>{row.user.email}</Typography>
            ) : (
              <Typography>{row.invitation_link.split(':')[2]}</Typography>
            )}
          </FlexContainer>
        ),
      },
      {
        label: 'Rol',
        id: 'roles',
        cell: row =>
          row.role && (
            <Pill key={row.role.id} padding='3px 10px'>
              {row.role.name}
            </Pill>
          ),
      },
      {
        label: '',
        id: 'delete_user',
        cell: row =>
          row.user ? (
            user.permissions.includes('core.administrator.destroy') && (
              <Icon
                icon='delete-icon'
                size={20}
                onClick={() => {
                  setOpenConfirm(true);
                  setUserDeleted(row.id);
                }}
              />
            )
          ) : (
            <Icon
              icon='delete-icon'
              size={20}
              onClick={() => {
                setOpenPendingConfirm(true);
                setUserDeleted(row.id);
                setInvitationLink(row.invitation_link);
              }}
            />
          ),
      },
    ],
    []
  );

  return (
    <FlexContainer container padding='20px 15px' direction='column'>
      <FlexContainer
        container
        direction='column'
        margin='0 0 20px'
        padding='20px'
        backgroundColor='#FFFFFF'
        borderRadius='4px'
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer container justify='space-between'>
          <FlexContainer>
            <FlexContainer container>
              <SearchWithFilter
                filterLabel='Buscar por'
                filterValue={searchFilter}
                onChangeFilterValue={value => setSearchFilter(value)}
                options={options}
                onChangeSearchValue={value => setSearchInput(value)}
                searchValue={searchInput}
                onSubmit={() => requestAdmins(1)}
                maxWidth='410px'
                placeholder='Buscar'
                inputWidth='250px'
              />
              <Button
                variant='contained'
                color='primary'
                margin='0 20px'
                onClick={() => requestAdmins(1)}
              >
                Buscar
              </Button>
            </FlexContainer>

            <FlexContainer container>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => setShowOptions(!showOptions)}
              >
                <MoreOptionIcon
                  active={
                    Object.values(values).some(value => value !== null)
                      ? 'active'
                      : ''
                  }
                />
              </Button>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            {user.permissions.includes('core.administrator.invitations') && (
              <Button
                color='primary'
                margin='0 0 0 20px'
                fontSize='13px'
                startIcon={<Icon icon='plus-icon' size={18} />}
                onClick={() => {
                  if (organization.pricing_plan.code === 'ecommerce_starter') {
                    setOpenChangePlan(true);
                  } else {
                    setOpenEdit(true);
                  }
                }}
              >
                Invitar Usuarios
              </Button>
            )}
          </FlexContainer>
        </FlexContainer>

        {showOptions && (
          <FiltersContainer
            labelWeight={600}
            filters={filters}
            value={values}
            onChange={(value, name) =>
              setValues({
                ...values,
                [name]: value,
              })
            }
            permissions={user.permissions}
            disabled={loadingOffices || loadingRoles}
          />
        )}
      </FlexContainer>

      <DataTable
        headers={headers}
        data={usersList}
        onChangePage={selectedPage => requestAdmins(selectedPage)}
        totalPages={pageData.totalPages}
        totalItems={pageData.totalItems}
        pageSize={pageData.pageSize}
        page={page}
        loading={loadingAdmins}
      />

      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => {
          setEditData(null);
          setOpenEdit(false);
        }}
      >
        <EditAdmin
          data={editData}
          onCreate={() => requestAdmins(page)}
          onClose={() => {
            setEditData(null);
            setOpenEdit(false);
          }}
        />
      </Drawer>

      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
        <FlexContainer
          padding='30px'
          direction='column'
          alignItems='center'
          justify='center'
        >
          <Typography
            color={THEME.colors.primary}
            fontSize={15}
            margin='0 0 20px'
          >
            Eliminar Usuario
          </Typography>
          <Typography margin='0 0 30px' fontSize={13}>
            ¿Estás seguro de eliminar el usuario?
          </Typography>
          <FlexContainer container alignItems='center' justify='space-between'>
            <Button
              variant='contained'
              color='primary'
              fontSize='13px'
              onClick={() => {
                adminDelete(userDeleted);
                setOpenConfirm(false);
              }}
            >
              Eliminar
            </Button>

            <Button fontSize='14px' onClick={() => setOpenConfirm(false)}>
              Cancelar
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Dialog>

      <Dialog
        open={openPendingConfirm}
        onClose={() => setOpenPendingConfirm(false)}
      >
        <FlexContainer
          padding='30px'
          direction='column'
          alignItems='center'
          justify='center'
        >
          <Typography
            color={THEME.colors.primary}
            fontSize={15}
            margin='0 0 20px'
          >
            Eliminar Invitación de Usuario
          </Typography>
          <Typography margin='0 0 30px' fontSize={13}>
            ¿Estás seguro de eliminar la invitación?
          </Typography>
          <FlexContainer container alignItems='center' justify='space-between'>
            <Button
              variant='contained'
              color='primary'
              fontSize='13px'
              onClick={() => {
                if (invitationLink !== '') {
                  deleteInvitation(userDeleted);
                  setOpenPendingConfirm(false);
                }
              }}
            >
              Eliminar
            </Button>

            <Button
              fontSize='14px'
              onClick={() => setOpenPendingConfirm(false)}
            >
              Cancelar
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Dialog>

      <Dialog open={openChangePlan} onClose={() => setOpenChangePlan(false)}>
        <FlexContainer
          padding='30px'
          direction='column'
          alignItems='center'
          justify='center'
        >
          <Typography
            color={THEME.colors.primary}
            fontSize={15}
            margin='0 0 20px'
          >
            Permitido solo en plan Grow o PRO
          </Typography>
          <Typography margin='0 0 30px' fontSize={13}>
            ¿Quiere cambiar de plan?
          </Typography>
          <FlexContainer container alignItems='center' justify='space-between'>
            <Button
              variant='contained'
              color='primary'
              fontSize='13px'
              onClick={() => handleChangePlan()}
            >
              Cambiar
            </Button>

            <Button fontSize='14px' onClick={() => setOpenChangePlan(false)}>
              Cancelar
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Dialog>
    </FlexContainer>
  );
};

export default Admin;
