import React, { useState, useEffect, useMemo } from 'react';
import {
  Dropdown,
  DropdownIconOption,
  FormControl,
  Spinner,
} from 'components/molecules';
import useForm from 'hooks/useForm';
import { useSnackbar } from 'notistack';
import { FlexContainer, Icon, Typography, Button } from 'components/atoms';
import { TableContainer, Dialog } from '@material-ui/core';
import useRequest from 'hooks/useRequest';
import {
  getProducts,
  getBranchOffices,
  postStock,
  getStocks,
  patchStock,
  getProductLogs,
} from 'services';
import { CloseIcon } from 'containers/Webapp/Orders/OrdersList/Detail';
import THEME from 'util/styledTheme';
import { ParamType } from 'services/types';
import NoData from 'assets/images/no_data.svg';
import EmptyBox from 'assets/images/inventory/empty.png';
import { DataTable } from 'components/organisms';
import { getLocalStorageClientId } from 'util/functions';

// #region Styled components

/* const useStyles = makeStyles({
  drawer: {
    width: '720px',
    flexShrink: 0,
  },
  drawerMobile: {
    width: '555px',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '720px',
  },
  drawerPaperMobile: {
    width: '555px',
  },
}); */

// #endregion

const InventoryTab = props => {
  const { clientId, hasClients, isLoadingClients } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [productList, setProductList] = useState([]);
  const [productStocksList, setProductStocksList] = useState([]);
  const [productLogsList, setProductLogsList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openMovementsModal, setOpenMovementsModal] = useState(false);
  const [branchOfficesData, setBranchOfficesData] = useState([]);
  const [selectedBranchOffice, setSelectedBranchOffice] = useState(undefined);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 10,
    total: 0,
  });

  const [products, fetchProducts, loadingProducts] = useRequest(
    getProducts,
    []
  );
  const [branchOffices, fetchBranchOffices] = useRequest(getBranchOffices, []);
  const [createdStock, createStock, loadingCreateStock] = useRequest(postStock);
  const [updatedStock, updateStock] = useRequest(patchStock);
  const [productStocks, fetchProductStocks, loadingProductStocks] = useRequest(
    getStocks,
    []
  );
  const [productLogs, fetchProductLogs, loadingProductLogs] = useRequest(
    getProductLogs,
    []
  );

  const LOG_TYPES = {
    INCREMENT: 'ENTRADA',
    DECREMENT: 'SALIDA',
    RESERVE: 'RESERVA',
  };

  const LOG_REASONS = {
    ORDER_CREATED: 'CREACIÓN DE ORDEN',
    ORDER_COMPLETED: 'ORDER COMPLETADA',
    ORDER_CANCELLED: 'ORDEN CANCELADA',
    ORDER_RETURNED: 'ORDEN DEVUELTA',
  };

  const validationState = {
    branch_office_code: ['mandatory'],
    stock: ['positive'],
    damaged: ['positive'],
    reserved: ['positive'],
    returns: ['positive'],
    reason: ['mandatory'],
  };

  const [form, onFormChange, validate, errors, reset] = useForm(
    {
      branch_office_code: '',
      stock: 0,
      damaged: 0,
      reserved: 0,
      returns: 0,
      reason: '',
    },
    validationState
  );

  const validateForm = (add: boolean) => {
    if (validate()) {
      const data: any = {
        product_id: selectedProduct ? selectedProduct._id : '',
        branch_office_code: form.branch_office_code,
        stock: add ? form.stock : `-${form.stock}`,
        damaged: form.damaged,
        reserved: form.reserved,
        returns: form.returns,
        reason: form.reason,
      };

      const existsStock = productStocksList.find(
        item => item.branch_office_code === form.branch_office_code
      );

      if (existsStock) {
        const productStock = productStocksList.find(
          item => item.branch_office_code === form.branch_office_code
        );
        updateStock(productStock._id, data);
        enqueueSnackbar('Registro de stock actualizado correctamente.', {
          variant: 'success',
        });
      } else {
        createStock(data);
        enqueueSnackbar('Registro de stock creado correctamente.', {
          variant: 'success',
        });
      }
      reset();
    }
  };

  const loadProducts = () => {
    const params = {
      $skip: paginationData.size * paginationData.page,
      $limit: paginationData.size,
      is_active: true,
      $select: ['_id', 'name', 'price', 'photos', 'is_public'],
      client_code: clientId || getLocalStorageClientId(),
    };
    fetchProducts(params, { 'stock-data': 1 });
  };

  const loadProductStocks = () => {
    const params = {
      is_active: true,
      $limit: 50,
      product_id: selectedProduct._id,
    };
    fetchProductStocks(params);
  };

  const loadProductLogs = () => {
    const params = {
      $limit: 50,
      product_id: selectedProduct._id,
    };
    fetchProductLogs(params);
  };

  useEffect(() => {
    const params: ParamType = {
      page_size: 0,
    };
    fetchBranchOffices(params);
  }, []);

  useEffect(() => {
    if (branchOffices === undefined) {
      setBranchOfficesData([]);
      return;
    }

    const data = branchOffices.map((item: any) => {
      return {
        id: item.reference_code,
        name: item.name,
      };
    });

    setBranchOfficesData(data);
  }, [branchOffices]);

  useEffect(() => {
    loadProducts();
  }, [clientId]);

  useEffect(() => {
    if (products.data !== undefined) {
      setProductList(products.data);
    }

    setPaginationData({ ...paginationData, total: products.total });
  }, [products]);

  useEffect(() => {
    if (!openEditModal) {
      reset();
      return;
    }

    loadProductStocks();
  }, [openEditModal]);

  useEffect(() => {
    if (!openMovementsModal) {
      return;
    }

    loadProductLogs();
  }, [openMovementsModal]);

  useEffect(() => {
    if (!createdStock._id && !updatedStock._id) {
      return;
    }

    loadProductStocks();
    loadProducts();
  }, [createdStock, updatedStock]);

  const changePage = (value: number) => {
    setPaginationData({ ...paginationData, page: value - 1 });
    const params = {
      $skip: paginationData.size * (value - 1),
      $limit: paginationData.size,
      is_active: true,
      $select: ['_id', 'name', 'price', 'photos', 'is_public'],
      client_code: clientId || getLocalStorageClientId(),
    };
    fetchProducts(params, { 'stock-data': 1 });
  };

  useEffect(() => {
    if (productStocks.data !== undefined) {
      let data = [...productStocks.data];
      data = data.map(item => {
        const itemCopy = { ...item };
        const branchOffice = branchOfficesData.find(
          office => office.id === item.branch_office_code
        );
        itemCopy.branch_office_name = branchOffice.name;
        return itemCopy;
      });

      setProductStocksList(data);
    }
  }, [productStocks]);

  useEffect(() => {
    if (productLogs.data !== undefined) {
      let data = [...productLogs.data];
      data = data.map(item => {
        const logData: any = {
          createdAt: item.createdAt.split('T')[0],
          type: LOG_TYPES[item.type],
          reason:
            LOG_REASONS[item.reason] !== undefined
              ? LOG_REASONS[item.reason]
              : item.reason,
          quantityStock: item.quantity_stock,
          previousStock: item.previous_stock,
          quantityReserved: item.quantity_reserved,
          previousReserved: item.previous_reserved,
        };

        // switch (item.type) {
        //   case 'DECREMENT':
        //     logData.quantityStock -= item.quantity_stock;
        //     logData.quantityReserved -= item.quantity_reserved;
        //     break;
        //   case 'INCREMENT':
        //     logData.quantityStock += item.quantity_stock;
        //     break;
        //   case 'RESERVE':
        //     logData.quantityReserved += item.quantity_reserved;
        //     break;

        //   default:
        //     break;
        // }

        const branchOffice = branchOfficesData.find(
          office => office.id === item.branch_office_code
        );
        logData.branchOfficeName = branchOffice.name;

        return logData;
      });

      setProductLogsList(data);
    }
  }, [productLogs]);

  const totalPages = () => {
    const residue = paginationData.total % paginationData.size;
    const result = paginationData.total / paginationData.size;
    // eslint-disable-next-line
    const pages = residue === 0 ? result : parseInt(result + '') + 1;
    return pages;
  };

  const settingsOptions = () => {
    const settingsOptionsList: Array<any> = [];

    if (!getLocalStorageClientId()) {
      settingsOptionsList.push({
        label: 'Editar',
        name: 'edit',
        onClick: row => {
          setSelectedProduct(row);
          onFormChange(row._id, 'product_id');
          setOpenEditModal(true);
        },
      });
    }

    settingsOptionsList.push({
      label: 'Movimientos',
      name: 'movements',
      onClick: row => {
        setSelectedProduct(row);
        setOpenMovementsModal(true);
      },
    });

    return settingsOptionsList;
  };

  const option = row =>
    settingsOptions().map(setting => (
      <DropdownIconOption
        key={setting.name}
        label={setting.label}
        name={setting.name}
        icon=''
        onClick={() => setting.onClick(row)}
      />
    ));

  const headers = useMemo(
    () => [
      {
        label: 'Producto',
        id: 'name',
        cell: row => row.name,
      },
      {
        label: 'Stock',
        id: 'sumStock',
        cell: row => row.sumStock,
      },
      {
        label: 'Reservado',
        id: 'sumReserved',
        cell: row => row.sumReserved,
      },
      {
        label: 'Habilitado',
        id: 'is_public',
        cell: row => (row.is_public ? '✔' : ''),
      },
      {
        label: <FlexContainer justify='center' />,
        id: 'actions',
        cell: row => (
          <FlexContainer width='80%' justify='flex-end' margin='0 0 0 40px'>
            <Dropdown
              label={<Icon icon='options-icon' size={20} />}
              direction='bottom'
              position='right'
              showCaret={false}
              options={option(row)}
              padding='0 10px'
              hover
            />
          </FlexContainer>
        ),
      },
    ],
    [productList]
  );

  const stocksHeaders = useMemo(
    () => [
      {
        label: 'Sucursal',
        id: 'branch_office_name',
        cell: row => row.branch_office_name,
      },
      {
        label: 'Stock',
        id: 'stock',
        cell: row => row.stock,
      },
      {
        label: 'Reservado',
        id: 'reserved',
        cell: row => row.reserved,
      },
    ],
    [productStocksList]
  );

  const logsHeaders = useMemo(
    () => [
      {
        label: 'Sucursal',
        id: 'branchOfficeName',
        cell: row => row.branchOfficeName,
      },
      {
        label: 'Fecha',
        id: 'createdAt',
        cell: row => row.createdAt,
      },
      {
        label: 'Stock anterior',
        id: 'previousStock',
        cell: row => row.previousStock,
      },
      {
        label: 'Stock actual',
        id: 'stock',
        cell: row => row.quantityStock,
      },
      {
        label: 'Reservado anterior',
        id: 'previousReserved',
        cell: row => row.previousReserved,
      },
      {
        label: 'Reservado actual',
        id: 'reserved',
        cell: row => row.quantityReserved,
      },
      {
        label: 'Tipo',
        id: 'type',
        cell: row => row.type,
      },
      {
        label: 'Razón - Guía de remisión',
        id: 'reason',
        cell: row => row.reason,
      },
    ],
    [productLogsList]
  );

  const canRenderInventory = () => {
    if (isLoadingClients) {
      return false;
    }

    if (hasClients && !clientId) {
      return false;
    }

    return true;
  };

  return (
    <div style={{ width: '100%' }}>
      <FlexContainer container padding='40px 0' direction='column'>
        {loadingProducts === false && productList.length === 0 ? (
          <FlexContainer
            container
            direction='column'
            justify='center'
            alignItems='center'
            height='60vh'
          >
            <img src={NoData} alt='no_data' />
            <Typography color='text.secondary'>
              No hay productos registrados
            </Typography>
          </FlexContainer>
        ) : canRenderInventory() ? (
          <DataTable
            headers={headers.filter(header => header)}
            data={productList}
            dataIdentifier='_id'
            onChangePage={changePage}
            totalPages={totalPages()}
            totalItems={paginationData.total}
            pageSize={paginationData.size}
            page={paginationData.page + 1}
            showPagination
            loading={loadingProducts}
            marginBottom='0'
          />
        ) : (
          <FlexContainer
            container
            direction='column'
            justify='center'
            alignItems='center'
            height='60vh'
          >
            <img src={NoData} alt='no_data' />
            <Typography color='text.secondary'>
              Seleccione un cliente
            </Typography>
          </FlexContainer>
        )}
      </FlexContainer>

      <Dialog
        fullWidth
        open={openEditModal}
        maxWidth='lg'
        onClose={() => setOpenEditModal(false)}
      >
        <FlexContainer
          container
          direction='column'
          padding='20px 40px'
          height='500px'
        >
          <CloseIcon onClick={() => setOpenEditModal(false)}>
            <Icon
              icon='cancel-icon'
              color={THEME.colors.placeholderColor}
              size={25}
            />
          </CloseIcon>
          <FlexContainer container justify='center' margin='20px 0 40px'>
            <Typography color={THEME.colors.primary} fontSize={18}>
              Administrar Inventarios -{' '}
              {selectedProduct ? selectedProduct.name : ''}
            </Typography>
          </FlexContainer>

          <FlexContainer container direction='row'>
            <FormControl
              label='Cantidad'
              placeholder='Cantidad'
              onChange={value => onFormChange(value, 'stock')}
              value={form.stock}
              margin='0 10px 0'
              error={errors.stock}
              width='100%'
            />
            <FormControl
              label='Razón - Guía de remisión'
              placeholder='Razón - Guía de remisión'
              onChange={value => onFormChange(value, 'reason')}
              value={form.reason}
              margin='0 10px 0'
              error={errors.reason}
              width='100%'
            />
            {/* <FormControl
              label='Razón'
              placeholder='Stock'
              onChange={value => onFormChange(value, 'stock')}
              value={form.stock}
              margin='0 10px 0'
              error={errors.stock}
              width='100%'
            /> */}
            {/* <FormControl
              label='Dañado'
              placeholder='Dañado'
              onChange={value => onFormChange(value, 'damaged')}
              value={form.damaged}
              margin='0 10px 0'
              error={errors.damaged}
              width='100%'
            /> */}
            {/* <FormControl
              label='Reservado'
              placeholder='Reservado'
              onChange={value => onFormChange(value, 'reserved')}
              value={form.reserved}
              margin='0 10px 0'
              error={errors.reserved}
              width='100%'
            /> */}
            {/*  <FormControl
              label='Devuelto'
              placeholder='Devuelto'
              onChange={value => onFormChange(value, 'returns')}
              value={form.returns}
              margin='0 10px 0'
              error={errors.returns}
              width='100%'
            /> */}
            <FormControl
              label='Sucursal'
              control='select'
              onChange={value => onFormChange(value, 'branch_office_code')}
              value={form.branch_office_code}
              margin='0 10px 0'
              error={errors.branch_office_code}
              width='100%'
              options={branchOfficesData}
            />
            <FlexContainer
              container
              justify='center'
              margin='10px 10px 20px'
              alignSelf='flex-end'
              padding='15px 0 0'
            >
              <Button
                variant='contained'
                color='primary'
                margin='0'
                disabled={loadingCreateStock}
                onClick={() => validateForm(true)}
              >
                {loadingCreateStock && (
                  <Spinner
                    height='15px'
                    spinnerSize={20}
                    colorSecondary
                    margin='0 8px 0 0'
                  />
                )}
                Agregar
              </Button>
              <Button
                variant='contained'
                color='primary'
                margin='0 0 0 5px'
                disabled={loadingCreateStock}
                onClick={() => validateForm(false)}
              >
                {loadingCreateStock && (
                  <Spinner
                    height='15px'
                    spinnerSize={20}
                    colorSecondary
                    margin='0 8px 0 0'
                  />
                )}
                Retirar
              </Button>
            </FlexContainer>
            <FlexContainer
              container
              justify='center'
              alignSelf='flex-end'
              padding='0px 0 0'
            >
              <img
                src={
                  selectedProduct?.photos.length > 0
                    ? selectedProduct?.photos[0]
                    : EmptyBox
                }
                alt='product'
                style={{
                  width: 198,
                  height: 110,
                  objectFit: 'contain',
                  marginTop: '-45px',
                }}
              />
            </FlexContainer>
          </FlexContainer>
          <FlexContainer container padding='10px 0'>
            <TableContainer>
              <DataTable
                headers={stocksHeaders.filter(header => header)}
                data={productStocksList}
                dataIdentifier='_id'
                showPagination={false}
                marginBottom='0'
                loading={loadingProductStocks}
                onChangePage={() => {}}
                totalItems={0}
                totalPages={0}
                page={0}
                pageSize={0}
              />
            </TableContainer>
          </FlexContainer>
        </FlexContainer>
      </Dialog>

      <Dialog
        fullWidth
        open={openMovementsModal}
        maxWidth='lg'
        onClose={() => {
          setOpenMovementsModal(false);
          setSelectedBranchOffice(undefined);
        }}
      >
        <FlexContainer
          container
          direction='column'
          padding='20px 40px'
          height='500px'
        >
          <CloseIcon
            onClick={() => {
              setOpenMovementsModal(false);
              setSelectedBranchOffice(undefined);
            }}
          >
            <Icon
              icon='cancel-icon'
              color={THEME.colors.placeholderColor}
              size={25}
            />
          </CloseIcon>
          <FlexContainer container justify='center' margin='20px 0 40px'>
            <Typography color={THEME.colors.primary} fontSize={18}>
              Registro de movimientos -{' '}
              {selectedProduct ? selectedProduct.name : ''}
            </Typography>
          </FlexContainer>

          <FlexContainer container direction='row'>
            <FormControl
              label='Sucursal'
              control='select'
              onChange={value => {
                setSelectedBranchOffice(value);
                const params = {
                  $limit: 50,
                  branch_office_code: value,
                  product_id: selectedProduct._id,
                };
                fetchProductLogs(params);
              }}
              value={selectedBranchOffice}
              margin='0 10px 0'
              width='25%'
              options={[{ id: undefined, name: 'TODAS' }, ...branchOfficesData]}
            />
          </FlexContainer>
          <FlexContainer container padding='10px 0'>
            <TableContainer>
              {productLogsList.length === 0 && !loadingProductLogs ? (
                <FlexContainer
                  container
                  direction='column'
                  justify='center'
                  alignItems='center'
                >
                  <img src={NoData} alt='no_data' />
                  <Typography color='text.secondary'>
                    No hay movientos registrados
                  </Typography>
                </FlexContainer>
              ) : (
                <DataTable
                  headers={logsHeaders.filter(header => header)}
                  data={productLogsList}
                  dataIdentifier='_id'
                  showPagination={false}
                  marginBottom='0'
                  loading={loadingProductLogs}
                  onChangePage={() => {}}
                  totalItems={0}
                  totalPages={0}
                  page={0}
                  pageSize={0}
                />
              )}
            </TableContainer>
          </FlexContainer>
        </FlexContainer>
      </Dialog>
    </div>
  );
};

export default InventoryTab;
