import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Map, TileLayer } from 'react-leaflet';
import { MapProps } from './types';

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  .leaflet-container {
    height: 100%;
    width: 100%;
  }
  .leaflet-tile-pane {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
`;

type Ref = Map;

const LeafLetMap: React.FC<MapProps> = forwardRef<Ref, MapProps>(
  ({ position, bounds, boundsOptions, zoom, children }, ref) => {
    return (
      <MapContainer>
        <Map
          ref={ref}
          maxZoom={18}
          zoom={zoom || 13}
          center={position}
          bounds={bounds}
          boundsOptions={boundsOptions}
        >
          <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {children}
        </Map>
      </MapContainer>
    );
  }
);

export default LeafLetMap;
