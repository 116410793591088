import React from 'react';
import { FlexContainer } from 'components/atoms';
import EditOrder from 'containers/Webapp/Orders/OrdersList/Edit';

const CreateOrder: React.FC = () => {
  return (
    <FlexContainer container height='100%' padding='20px 0'>
      <EditOrder />
    </FlexContainer>
  );
};

export default CreateOrder;
