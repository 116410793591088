import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  FlexContainer,
  Icon,
  // Switch,
  Typography,
} from 'components/atoms';
import { Cell } from 'components/molecules';
import { DataTable } from 'components/organisms';
import { Drawer } from '@material-ui/core';
import { getPackageTypes } from 'services';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import EditPackings from './Edit';

const StatusCircle = styled.div<{ active }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${props =>
    props.active
      ? props.theme.colors.success
      : props.theme.colors.placeholderColor};
  box-shadow: rgb(0 0 0 / 8%) 2px 2px 3px 0px;
  margin: 5px;
`;

const Packings: React.FC = () => {
  const user = useTypedSelector(state => state.user);
  // API calls
  const [
    packingTypes,
    fetchPackingTypes,
    loadingPackingTypes,
    pageData,
  ] = useRequest(getPackageTypes, []);

  // Values
  const [page, setPage] = useState<number>(1);
  const [editData, setEditData] = useState(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const requestPackings = useCallback(() => {
    const params = {
      page,
    };

    fetchPackingTypes(params);
  }, [fetchPackingTypes, page]);

  useEffect(() => {
    fetchPackingTypes();
  }, [page, fetchPackingTypes]);

  const headers = useMemo(
    () => [
      {
        label: (
          <Typography margin='0 0 0 20px' color='#6d7996'>
            Nombre
          </Typography>
        ),
        id: 'name',
        cell: row => (
          <FlexContainer margin='0 0 0 20px'>
            <Cell.Link
              onClick={() => {
                if (user.permissions.includes('core.package_type.update')) {
                  setEditData(row);
                  setOpenEdit(true);
                }
              }}
            >
              {row.name}
            </Cell.Link>
          </FlexContainer>
        ),
      },
      {
        label: 'Código',
        id: 'code',
        cell: row => row.code,
      },
      {
        label: 'Descripción',
        id: 'description',
        cell: row => row.description,
      },
      {
        label: 'Medidas',
        id: 'measures',
        cell: row => `${row.width} x ${row.height} x ${row.depth}`,
      },
      {
        label: 'Peso',
        id: 'weight',
        cell: row => row.weight,
      },
      {
        label: 'Activo',
        id: 'is_active',
        cell: row => <StatusCircle active={row.is_active} />,
      },
      {
        label: 'Unidad de medida',
        id: 'is_a_unit_of_measure',
        cell: row => <StatusCircle active={row.is_a_unit_of_measure} />,
      },
    ],
    []
  );

  return (
    <FlexContainer container padding='30px 40px' direction='column'>
      <FlexContainer
        container
        alignItems='center'
        justify='space-between'
        margin='0 0 30px'
      >
        <Typography fontSize={18} fontWeight={300}>
          Tipos de Paquete
        </Typography>
        {user.permissions.includes('core.package_type.create') && (
          <Button
            color='primary'
            margin='0 0 0 20px'
            fontSize='13px'
            startIcon={<Icon icon='plus-icon' size={18} />}
            onClick={() => setOpenEdit(true)}
          >
            Crear Tipo de Paquete
          </Button>
        )}
      </FlexContainer>

      <DataTable
        headers={headers}
        data={packingTypes}
        onChangePage={selectedPage => setPage(selectedPage)}
        loading={loadingPackingTypes}
        totalPages={pageData.totalPages}
        totalItems={pageData.totalItems}
        pageSize={pageData.pageSize}
        page={page}
      />
      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => {
          setEditData(null);
          setOpenEdit(false);
        }}
      >
        <EditPackings
          data={editData}
          onCreate={useCallback(() => requestPackings(), [requestPackings])}
          onClose={() => {
            setEditData(null);
            setOpenEdit(false);
          }}
        />
      </Drawer>
    </FlexContainer>
  );
};

export default Packings;
