import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';
import THEME from 'util/styledTheme';
import { FlexContainer, Typography, Switch, Button } from 'components/atoms';
import { Tabs, FormControl } from 'components/molecules';
import transportationLogo from 'assets/images/portal/fake-transportation-image.png';
import { useTypedSelector } from 'store';
import { getTrackings, getTracking, patchTracking } from 'services';
import { optionFormatter } from 'util/functions';
import useRequest from 'hooks/useRequest';
import useForm from 'hooks/useForm';
import AssignedOrder from './AssignedOrder';
import TrackingLink from './TrackingLink';
import CompletedOrder from './CompletedOrder';
import EmailNotifications from './EmailTemplates';
import PhoneNotifications from './PhoneTemplates';
import {
  ViewType,
  EmailSectionProps,
  PhoneSectionProps,
  NotificationsFormsProps,
} from './types';

const tabOptions = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'SMS/WhatsApp',
    value: 'sms',
  },
];

const Tab = styled(FlexContainer)<{ active: boolean }>`
  position: relative;
  text-align: center;
  margin: 4px 0;
  ${props => props.active && `color: ${props.theme.colors.primary};`}
`;

const StyledLabel = styled(Typography)<{ selectedOption: boolean }>`
  cursor: pointer;
  ${props =>
    props.selectedOption &&
    `
    text-decoration: underline;
    color: ${props.theme.colors.primary};
    `}

  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.primary};
  }
`;

const CardSkeletonPhone: React.FC = () => (
  <FlexContainer
    width='90%'
    padding='10px 20px 10px 5px'
    backgroundColor='#FFFFFF'
    justify='flex-end'
    margin='0 0 0 10px'
  >
    <FlexContainer container justify='space-between'>
      <Skeleton width='50%' height={25} />

      <FlexContainer width='40%' justify='space-between'>
        <Skeleton width='40%' height={25} />
        <Skeleton width='40%' height={25} />
      </FlexContainer>
    </FlexContainer>
  </FlexContainer>
);

const CardSkeletonEmail: React.FC = () => (
  <FlexContainer
    width='90%'
    padding='10px 20px 10px 10px'
    backgroundColor='#FFFFFF'
    justify='flex-end'
  >
    <FlexContainer direction='column' width='90%'>
      <FlexContainer container justify='space-between'>
        <Skeleton width='70%' height={25} />
        <Skeleton width='20%' height={25} />
      </FlexContainer>
    </FlexContainer>
  </FlexContainer>
);

// const EmailNotificationForms: React.FC<NotificationsFormsProps> = ({
//   data,
//   onChange,
// }) => {
//   return (
//     <FlexContainer container alignItems='center'>
//       <FlexContainer width='70%' direction='column' margin='0 20px'>
//         <Typography margin='5px 0'>{data.label}</Typography>
//         <FormControl
//           placeholder='Escribe mensaje aquí'
//           onChange={value => onChange(value, 'mailMessage')}
//           value={data.mailMessage}
//           margin='0 0 20px'
//           width='100%'
//         />
//       </FlexContainer>
//       <Switch
//         color='primary'
//         checked={data.sendEmail}
//         onChange={event => onChange(event.target.checked, 'sendEmail')}
//       />
//     </FlexContainer>
//   );
// };

const PhoneNotificationsForms: React.FC<NotificationsFormsProps> = ({
  data,
  onChange,
  onWrite,
  onSelect,
  selectedLabel,
}) => {
  return (
    <FlexContainer container alignItems='center' margin='4px 0'>
      <FlexContainer width='70%' direction='column' margin='0 20px'>
        <StyledLabel
          selectedOption={selectedLabel === data.id}
          margin='5px 0'
          onClick={() => {
            onWrite(data);
            onSelect(data.id);
          }}
        >
          {data.label}
        </StyledLabel>
        <FormControl
          placeholder='Escribe mensaje aquí'
          onChange={value => {
            onChange(value, 'notificationMessage');
            onWrite({ ...data, notificationMessage: value });
          }}
          value={data.notificationMessage}
          margin='0 0 20px'
          width='100%'
          control='textarea'
          onFocus={() => onWrite(data)}
        />
      </FlexContainer>
      <FlexContainer width='30%'>
        <Switch
          color='primary'
          checked={data.sendSms}
          onChange={event => onChange(event.target.checked, 'sendSms')}
        />
        <Switch
          color='primary'
          checked={data.sendWspp}
          onChange={event => onChange(event.target.checked, 'sendWspp')}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

const EmailSection: React.FC<EmailSectionProps> = ({
  code,
  showView,
  emailsTemplate,
  onChangeNotifications,
  onFormChange,
  loadingNotifications,
}) => (
  <FlexContainer container direction='column'>
    <FlexContainer container padding='20px' justify='center'>
      <FlexContainer
        padding='10px 30px'
        borderRadius='8px'
        borderColor={THEME.colors.borderColor}
      >
        <Typography color='text.secondary' fontSize='16px' fontWeight='700'>
          Plantillas de Correo
        </Typography>
      </FlexContainer>
    </FlexContainer>

    <FlexContainer container direction='column' margin='0 5px'>
      {loadingNotifications ? (
        <>
          <CardSkeletonEmail />
          <CardSkeletonEmail />
          <CardSkeletonEmail />
          <CardSkeletonEmail />
        </>
      ) : (
        <>
          {emailsTemplate.map(notificationData => (
            <FlexContainer
              width='95%'
              alignItems='center'
              justify='space-between'
              key={notificationData.id}
            >
              <Tab
                key={notificationData.id}
                padding='13px 15px'
                margin='0 35px'
                cursor='pointer'
                active={code === notificationData.code}
                onClick={() => showView(notificationData.code)}
              >
                {notificationData.label} {notificationData.name}
              </Tab>
              <Switch
                color='primary'
                checked={notificationData.sendEmail}
                onChange={event => {
                  onFormChange(
                    emailsTemplate,
                    onChangeNotifications,
                    event.target.checked,
                    'sendEmail',
                    notificationData.formId
                  );

                  if (event.target.checked) {
                    showView(notificationData.code);
                  }
                }}
              />
            </FlexContainer>
          ))}
        </>
      )}
    </FlexContainer>
    {/* <FlexContainer
      container
      direction='column'
      justify='center'
      alignItems='center'
    >
      <FlexContainer
        padding='10px 30px'
        borderRadius='8px'
        borderColor={THEME.colors.borderColor}
        margin='20px 0'
      >
        <Typography color='text.secondary' fontSize='16px' fontWeight='700'>
          Otros estados de orden
        </Typography>
      </FlexContainer>
      {emailsWithoutTemplate.map(notificationData => (
        <EmailNotificationForms
          key={notificationData.id}
          data={notificationData}
          onChange={(value, field) =>
            onFormChange(
              emailsWithoutTemplate,
              onChangeNotifications,
              value,
              field,
              notificationData.formId
            )
          }
        />
      ))}
    </FlexContainer> */}
  </FlexContainer>
);

const PhoneSection: React.FC<PhoneSectionProps> = ({
  notficationEvents,
  onChangeNotifications,
  onFormChange,
  selectedLabel,
  setSelectedInput,
  setSelectedLabel,
  loadingNotifications,
}) => (
  <FlexContainer container direction='column'>
    <FlexContainer container>
      <FlexContainer width='90%' direction='column'>
        <FlexContainer container margin='20px'>
          <FlexContainer width='70%'>
            <Typography fontWeight='700'>Evento</Typography>
          </FlexContainer>
          <FlexContainer width='30%' justify='space-around'>
            <Typography fontWeight='700' margin='0 20px'>
              SMS
            </Typography>
            <Typography fontWeight='700' margin='0 30px 0 0'>
              Whatsapp
            </Typography>
          </FlexContainer>
        </FlexContainer>

        {loadingNotifications ? (
          <>
            <CardSkeletonPhone />
            <CardSkeletonPhone />
            <CardSkeletonPhone />
          </>
        ) : (
          <>
            {notficationEvents.map(notificationData => (
              <PhoneNotificationsForms
                key={notificationData.id}
                data={notificationData}
                onChange={(value, field) =>
                  onFormChange(
                    notficationEvents,
                    onChangeNotifications,
                    value,
                    field,
                    notificationData.formId
                  )
                }
                selectedLabel={selectedLabel}
                onWrite={setSelectedInput}
                onSelect={setSelectedLabel}
              />
            ))}
          </>
        )}
      </FlexContainer>
    </FlexContainer>
  </FlexContainer>
);

const getNotifitacionsForms = data => {
  if (!isEmpty(data)) {
    const notificationEmail = Object.values(data).filter(
      (status: any) => status.customer_notification !== false
    );

    return notificationEmail.map((notification: any) => ({
      formId: notification.id,
      id: notification.id,
      label: notification.label,
      notificationMessage: notification.notification_message,
      mailMessage: notification.mail_message,
      sendEmail: notification.send_email,
      sendSms: notification.send_sms,
      sendWspp: notification.send_whatsapp,
      code:
        notification.label === 'Asignado'
          ? 'orderETA'
          : `${
              notification.label === 'Entregado'
                ? 'completedOrder'
                : 'trackingLink'
            }`,
      name:
        notification.label === 'Asignado'
          ? '(ETA de Orden)'
          : `${
              notification.label === 'Entregado'
                ? '(Orden Completada)'
                : '(Tracking Link)'
            }`,
    }));
  }
  return [];
};

const Notifications: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const organization = useTypedSelector(state => state.organization);
  const [form, onFormChange, validate, , , updateForm] = useForm(
    {
      id: '',
      trackingStatuses: {},
    },
    {}
  );
  const [notificationForms, , , , , updateNotificationForms] = useForm({}, {});

  // API calls
  const [trackings, fetchTrackings, loadingTrackings] = useRequest(
    getTrackings,
    []
  );
  const [
    trackingInformation,
    fetchTrackingInformation,
    loadingTrackingInformation,
  ] = useRequest(getTracking);
  const [updatedTracking, updateTracking] = useRequest(patchTracking);
  // Values
  const [selectedTab, setSelectedTab] = useState<string>('email');
  const [trackingOptions, setTrackingOptions] = useState([]);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<ViewType>(
    'orderETA'
  );
  const [emailsTemplate, setEmailsTemplate] = useState([]);
  const [phoneNotifications, setPhoneNotifications] = useState([]);
  const [selectedInput, setSelectedInput] = useState({
    formId: 291,
    id: 291,
    label: 'En camino a entrega',
    notificationMessage:
      'Tu orden {REFERENCE_CODE} esta en camino, puedes seguirla aqui {TRACKING_LINK}',
    mailMessage: '',
    sendEmail: false,
    sendSms: true,
    sendWspp: false,
    code: 'trackingLink',
    name: '(Tracking Link)',
  });

  const [selectedLabel, setSelectedLabel] = useState(0);
  const [organizationLogo, setOrganizationLogo] = useState<string>('');
  const [organizationName, setOrganizationName] = useState<string>('');

  const showEmailTemplate = (templateName: ViewType) => {
    switch (templateName) {
      case 'orderETA':
        return <AssignedOrder organizationPhoto={organizationLogo} />;
      case 'trackingLink':
        return <TrackingLink organizationPhoto={organizationLogo} />;
      case 'completedOrder':
        return <CompletedOrder organizationPhoto={organizationLogo} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchTrackings();
  }, [fetchTrackings]);

  useEffect(() => {
    if (form.id) {
      fetchTrackingInformation(form.id);
    }
  }, [fetchTrackingInformation, form.id]);

  useEffect(() => {
    if (trackings.length > 0) {
      setTrackingOptions(optionFormatter(trackings));
    }
  }, [trackings]);

  useEffect(() => {
    if (trackingOptions.length > 0) {
      updateForm({
        id: trackingOptions[trackingOptions.length - 1].id,
      });
    }
  }, [trackingOptions]);

  useEffect(() => {
    if (!isEmpty(trackingInformation)) {
      updateForm({
        trackingStatuses: trackingInformation.tracking_statuses,
      });
    }
  }, [trackingInformation]);

  useEffect(() => {
    if (form.trackingStatuses) {
      updateNotificationForms(getNotifitacionsForms(form.trackingStatuses));
    }
  }, [form.trackingStatuses]);

  useEffect(() => {
    if (!isEmpty(notificationForms)) {
      const filteredStatussesPhone = Object.values(notificationForms).filter(
        (status: any) => status.label !== 'Asignado'
      );
      setPhoneNotifications(filteredStatussesPhone);
      const filteredStatusses = Object.values(notificationForms).filter(
        (status: any) => status.label !== 'En camino a recojo'
      );
      setEmailsTemplate(filteredStatusses);
    }
  }, [notificationForms]);

  useEffect(() => {
    if (!isEmpty(updatedTracking)) {
      enqueueSnackbar(
        'Se actualizó la configuración de las notificaciones correctamente.',
        {
          variant: 'success',
        }
      );
    }
  }, [updatedTracking, enqueueSnackbar]);

  useEffect(() => {
    if (
      organization.selectedOrganization.photo !== '' ||
      organization.selectedOrganization.photo !== null
    ) {
      setOrganizationLogo(organization.selectedOrganization.photo);
    } else {
      setOrganizationLogo(transportationLogo);
    }

    if (organization.selectedOrganization.name) {
      setOrganizationName(organization.selectedOrganization.name);
    }
  }, [organization]);

  const onNotificationInputChange = (
    forms: Array<any>,
    setForms: any,
    value: any,
    inputName: string,
    id: number
  ) => {
    if (Array.isArray(forms)) {
      setForms(
        forms.map(notification => {
          if (notification.formId === id) {
            return {
              ...notification,
              [inputName]: value,
            };
          }
          return notification;
        })
      );
    }
  };

  const validateForm = () => {
    if (validate()) {
      const emailStatussesTemplate = emailsTemplate.map(statusForm => ({
        id: statusForm.id,
        label: statusForm.label,
        send_email: statusForm.sendEmail,
        mail_message: statusForm.mailMessage,
      }));
      const phoneStatusses = phoneNotifications.map(statusForm => ({
        id: statusForm.id,
        label: statusForm.label,
        notification_message: statusForm.notificationMessage,
        send_sms: statusForm.sendSms,
        send_whatsapp: statusForm.sendWspp,
      }));
      const updatedForms = Object.values(
        [...emailStatussesTemplate, ...phoneStatusses].reduce(
          (result, { id, ...rest }) => {
            // eslint-disable-next-line no-param-reassign
            result[id] = {
              ...(result[id] || {}),
              id,
              ...rest,
            };
            return result;
          },
          {}
        )
      );
      const body = {
        tracking_statuses: updatedForms,
      };
      updateTracking(body, form.id);
    }
  };

  return (
    <FlexContainer container justify='space-between' padding='30px 20px'>
      <FlexContainer
        width='38%'
        direction='column'
        backgroundColor='#ffffff'
        minWidth='400px'
      >
        <FlexContainer container justify='center' margin='20px 0'>
          <FormControl
            control='select'
            label='Tracking Público'
            placeholder='Seleccione un tracking'
            onChange={id => onFormChange(id, 'id')}
            value={form.id}
            margin='0 0 20px'
            width='80%'
            options={trackingOptions}
            disabled={loadingTrackings}
          />
        </FlexContainer>
        <FlexContainer
          container
          justify='center'
          maxWidth='400px'
          margin='20px 0'
        >
          <FlexContainer width='100%'>
            <Tabs
              selected={selectedTab}
              tabs={tabOptions}
              onChange={value => setSelectedTab(value)}
              tabHeight='40px'
              tabPadding='10px 50px'
              fullWidth
            />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer container justify='center' padding='20px 0'>
          <FlexContainer container>
            {selectedTab === 'email' && (
              <EmailSection
                code={selectedEmailTemplate}
                showView={setSelectedEmailTemplate}
                emailsTemplate={emailsTemplate}
                onChangeNotifications={setEmailsTemplate}
                onFormChange={onNotificationInputChange}
                loadingNotifications={loadingTrackingInformation}
              />
            )}
            {selectedTab === 'sms' && (
              <PhoneSection
                notficationEvents={phoneNotifications}
                onChangeNotifications={setPhoneNotifications}
                onFormChange={onNotificationInputChange}
                selectedLabel={selectedLabel}
                setSelectedLabel={setSelectedLabel}
                setSelectedInput={setSelectedInput}
                loadingNotifications={loadingTrackingInformation}
              />
            )}
          </FlexContainer>
        </FlexContainer>
        <FlexContainer container justify='center' margin='10px 0 30px'>
          <Button
            variant='contained'
            color='primary'
            borderRadius='4px'
            padding='10px 20px'
            onClick={() => validateForm()}
          >
            <Typography fontSize='16px' fontWeight='700' color='white'>
              Guardar
            </Typography>
          </Button>
        </FlexContainer>
      </FlexContainer>

      {selectedTab === 'email' && (
        <EmailNotifications
          showTemplate={showEmailTemplate}
          emailTemplate={selectedEmailTemplate}
        />
      )}
      {selectedTab === 'sms' && (
        <PhoneNotifications
          data={selectedInput}
          setData={setSelectedInput}
          organizationName={organizationName}
        />
      )}
    </FlexContainer>
  );
};

export default Notifications;
