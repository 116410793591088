import React from 'react';
import styled from 'styled-components';
import { FlexContainer, Typography } from 'components/atoms';
import THEME from 'util/styledTheme';
import { TabsProps, TabProp } from './types';

const TabContainer = styled(FlexContainer)`
  background-color: ${props => props.theme.colors.borderColor};
`;

const Tab = styled(FlexContainer)<TabProp>`
  border: 2px solid transparent;
  background-color: ${props =>
    props.active ? props.theme.colors.secondaryBackground : '#FFFFFF'};
  border-bottom-color: ${props =>
    props.active ? props.theme.colors.primary : 'transparent'};
  ${props => props.clickable && 'cursor: pointer;'}
  ${props => props.height && `height: ${props.height};`}
`;

const OrderNumber = styled(FlexContainer)`
  border-radius: 50%;
  font-size: 14px;
`;

const Tabs: React.FC<TabsProps> = ({
  tabs,
  selected,
  onChange,
  fullWidth,
  ordering,
  tabHeight,
  tabPadding,
  freeSelect = true,
  canBack = true,
}) => {
  return (
    <TabContainer container={fullWidth} alignItems='center'>
      {tabs.map((tab, index) => (
        <Tab
          key={tab.value}
          justify='center'
          alignItems='center'
          height={tabHeight}
          padding={tabPadding || '15px 80px'}
          flex={fullWidth ? '1' : '0'}
          active={selected === tab.value}
          onClick={() => {
            if (freeSelect || (canBack && tab.value < selected)) {
              onChange(tab.value);
            }
          }}
          clickable={freeSelect || (canBack && tab.value < selected)}
        >
          {ordering && (
            <OrderNumber
              height='20px'
              width='20px'
              justify='center'
              alignItems='center'
              margin='0 15px 0 0'
              backgroundColor={
                selected === tab.value
                  ? THEME.colors.primary
                  : THEME.colors.textSecondary
              }
            >
              <Typography
                fontSize={14}
                color={selected === tab.value ? '#FFF' : '#F9F9FC'}
              >
                {index + 1}
              </Typography>
            </OrderNumber>
          )}
          <Typography
            color={
              selected === tab.value
                ? THEME.colors.primary
                : THEME.colors.textSecondary
            }
          >
            {tab.label}
          </Typography>
        </Tab>
      ))}
    </TabContainer>
  );
};

export default Tabs;
