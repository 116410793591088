import React from 'react';
import { Link } from 'react-router-dom';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { useTypedSelector } from 'store';
import { SuccessProps } from './types';

const Success: React.FC<SuccessProps> = ({ onNewMassive }) => {
  const user = useTypedSelector(state => state.user);
  return (
    <FlexContainer container height='100%' alignItems='center' justify='center'>
      <FlexContainer
        container
        maxWidth='400px'
        direction='column'
        alignItems='center'
      >
        <Icon icon='check-icon' size='50px' />
        <Typography fontSize={18} margin='30px 0 40px'>
          Se realizó con éxito la carga de órdenes.
        </Typography>
        <FlexContainer
          container
          justify='space-between'
          alignItems='center'
          margin='0 0 30px'
        >
          <Button
            variant='contained'
            fontSize='13px'
            padding='0 40px'
            onClick={() => onNewMassive()}
          >
            Nueva carga masiva
          </Button>
          {user.permissions.includes('core.route.create') ? (
            <Button
              component={Link}
              to='/monitoreo/?crear_ruta=true'
              variant='contained'
              color='primary'
              fontSize='13px'
              padding='0 40px'
            >
              Crear ruta
            </Button>
          ) : (
            <Button
              component={Link}
              to='/ordenes'
              variant='contained'
              color='primary'
              fontSize='13px'
              padding='0 40px'
            >
              Ver órdenes
            </Button>
          )}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Success;
