import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Edit from './Edit';
import List from './List';

const Vehicles: React.FC = () => (
  <Switch>
    <Route exact path='/flota/vehiculos' component={List} />
    <Route exact path='/flota/vehiculos/editar/:id' component={Edit} />
  </Switch>
);

export default Vehicles;
