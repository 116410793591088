import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { FlexContainer, Icon, Typography } from 'components/atoms';
import THEME from 'util/styledTheme';
import Minutes99 from 'assets/images/providers/99minutos.png';
import Olva from 'assets/images/providers/olva.jpg';
import Glovo from 'assets/images/providers/glovo.svg';
import Savar from 'assets/images/providers/savar.png';
import Chazki from 'assets/images/providers/chazki.png';
import Moova from 'assets/images/providers/moova.png';
import Cabify from 'assets/images/providers/cabify.png';
import PedidosYa from 'assets/images/providers/pedidosya.png';
import { DetailProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const Detail: React.FC<DetailProps> = ({ data, onClose }) => {
  const [detailProvider, setDetailProvider] = useState(null);

  useEffect(() => {
    if (!isEmpty(data.object)) {
      switch (data.object.code) {
        case 'olva':
          setDetailProvider({
            cut_off_schedule: ['7pm para el ingreso de envíos.'],
            pickup_schedule: 'Desde las 9am hasta las 5pm.',
            extra_info:
              'Una orden dura hasta 5 días, después se cancela automáticamente.',
          });
          break;
        case '99minutos':
          setDetailProvider({
            cut_off_schedule: [
              'Sameday: 1pm. Si se ingresa un pedido pasada esa hora se recolecta pero se entrega el día siguiente.',
              'Nextday: 12pm (media noche).',
            ],
            extra_info:
              'Medidas máximas del paquete en cualquiera de sus lados: 40cm.',
          });
          break;
        default:
          setDetailProvider({ extra_info: '' });
          break;
      }
    }
  }, [data]);

  const pickImage = code => {
    switch (code) {
      case '99minutos':
        return Minutes99;
      case 'olva':
        return Olva;
      case 'glovo':
        return Glovo;
      case 'savar':
        return Savar;
      case 'chazki':
        return Chazki;
      case 'moova':
        return Moova;
      case 'cabify':
        return Cabify;
      case 'pedidosya':
        return PedidosYa;
      default:
        return '';
    }
  };

  return (
    <FlexContainer
      width='530px'
      minHeight='100%'
      position='relative'
      padding='50px 40px'
    >
      <CloseIcon
        onClick={() => {
          onClose();
        }}
      >
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <FlexContainer
        container
        height='100%'
        direction='column'
        padding='60px 50px'
        borderRadius='10px'
        borderColor={THEME.colors.borderColor}
        backgroundColor={THEME.colors.backgroundColor}
      >
        {data.object && (
          <>
            <FlexContainer container justify='center'>
              <img
                src={pickImage(data.object.code)}
                alt='provider_logo'
                style={{
                  width: 'auto',
                  height: 'auto',
                  marginBottom: '50px',
                  maxWidth: '300px',
                  minWidth: '179px',
                  maxHeight: '65px',
                  minHeight: '55px',
                  objectFit: 'contain',
                }}
              />
            </FlexContainer>
            <Typography fontSize={12} color='text.secondary' margin='0 0 20px'>
              {data.object.description}
            </Typography>
            {detailProvider && (
              <>
                {detailProvider.cut_off_schedule && (
                  <>
                    <FlexContainer
                      direction={
                        detailProvider.cut_off_schedule.length > 1
                          ? 'column'
                          : 'row'
                      }
                      margin='0 0 10px'
                    >
                      <Typography
                        fontSize={12}
                        color='text.secondary'
                        fontWeight={600}
                      >
                        Horario de corte:
                      </Typography>
                      <FlexContainer
                        direction='column'
                        margin={
                          detailProvider.cut_off_schedule.length > 1
                            ? '5px 0 0 10px'
                            : '0 0 0 5px'
                        }
                      >
                        {detailProvider.cut_off_schedule.map(schedule => (
                          <Typography
                            key={schedule}
                            fontSize={12}
                            color='text.secondary'
                            margin='0 0 5px'
                          >
                            {detailProvider.cut_off_schedule.length > 1 && '- '}
                            {schedule}
                          </Typography>
                        ))}
                      </FlexContainer>
                    </FlexContainer>
                    {detailProvider.pickup_schedule && (
                      <FlexContainer margin='0 0 10px'>
                        <Typography
                          fontSize={12}
                          color='text.secondary'
                          fontWeight={600}
                        >
                          Horario de recojo:
                        </Typography>
                        <Typography
                          fontSize={12}
                          color='text.secondary'
                          margin='0 0 0 5px'
                        >
                          {detailProvider.pickup_schedule}
                        </Typography>
                      </FlexContainer>
                    )}
                  </>
                )}

                {detailProvider.extra_info && (
                  <Typography
                    fontSize={12}
                    color='text.secondary'
                    margin='0 0 10px'
                  >
                    {detailProvider.extra_info}
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Detail;
