import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import L from 'leaflet';
import { isEmpty } from 'lodash';
import moment from 'moment';
import THEME from 'util/styledTheme';
import { Grid, IconButton, Collapse, Dialog } from '@material-ui/core';
import {
  FlexContainer,
  Icon,
  Typography,
  Status,
  Marker,
  Button,
} from 'components/atoms';
import { Map } from 'components/molecules';
import statusColors from 'util/statusColors';
import {
  clientInformationFormatter,
  orderPhotoFormatter,
} from 'util/functions';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { ReactComponent as ArrowLeft } from 'assets/images/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/images/arrow-right.svg';
import { TaskCollapseProps } from './types';
import { CloseIcon } from './index';

const PictureOnModelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 650px;
`;

const CarouselContainer = styled(FlexContainer)`
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
`;
const CarousselImage = styled.img`
  width: 500px;
  height: 550px;
  object-fit: contain;
`;

// const PictureOnModelContainer = styled.div<{ img: string }>`
//   background-image: url(${props => props.img});
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: contain;
//   width: 600px;
//   height: 650px;
// `;

const TaskCollapse: React.FC<TaskCollapseProps> = ({
  taskOrderPoint,
  orderDataDetail,
  taskId,
  deliveryId,
  externalProviderPhotos,
}) => {
  const [openTask, setOpenTask] = useState(false);
  const [clientInformation, setClientInformation] = useState([]);
  const [clientPhotos, setClientPhotos] = useState([]);
  const [orderInformation, setOrderInformation] = useState(null);
  const [open, setOpen] = useState<boolean>(false);
  const [imageForModal, setImageForModal] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [bounds, setBounds] = useState();
  const [mainIndex, setMainIndex] = useState(0);
  const [imageName, setImageName] = useState<string>('');

  const getImageForModal = (imgIndex?, pictureList?, imgUrl?) => {
    let value;
    let carouselIndex;

    if (imgIndex && pictureList) {
      setImageName('internal');
      value = pictureList.find(img => imgIndex === img.id);
      carouselIndex = pictureList.findIndex(img => img.id === value.id);
      setImageForModal(value.image);
      setMainIndex(carouselIndex);
    } else {
      setImageName('external');
      carouselIndex = externalProviderPhotos[0].photos.findIndex(
        img => img === imgUrl
      );
      value = imgUrl;
      setImageForModal(value);
      setMainIndex(carouselIndex);
    }
    return imageForModal;
  };

  const slideNext = () => {
    let listPhotosLength;

    if (imageName === 'internal') {
      listPhotosLength = clientPhotos[0].item_pictures.length - 1;
    } else {
      listPhotosLength = externalProviderPhotos[0].photos.length - 1;
    }

    if (mainIndex === listPhotosLength) {
      setMainIndex(0);
    } else {
      setMainIndex(mainIndex + 1);
    }
  };

  const slidePrev = () => {
    let listPhotosLength;

    if (imageName === 'internal') {
      listPhotosLength = clientPhotos[0].item_pictures.length - 1;
    } else {
      listPhotosLength = externalProviderPhotos[0].photos.length - 1;
    }

    if (mainIndex === 0) {
      setMainIndex(listPhotosLength);
    } else {
      setMainIndex(mainIndex - 1);
    }
  };

  useEffect(() => {
    const taskOrder = orderDataDetail.find(task => task.id === taskId);
    const information = taskOrderPoint.find(task => task.id === taskId);
    if (!isEmpty(taskOrder)) {
      setClientInformation(clientInformationFormatter([taskOrder]));
      setClientPhotos(orderPhotoFormatter([taskOrder]));
      setOrderInformation(information);
    }
  }, []);

  useEffect(() => {
    if (
      orderInformation &&
      clientInformation.length !== 0 &&
      clientPhotos.length !== 0
    ) {
      if (orderInformation.final_position) {
        const newBounds = L.latLngBounds(
          clientPhotos[0].marker_coordinates,
          orderInformation.final_position
        );
        setBounds(newBounds);
        setLoading(true);
      } else {
        const modifiedLatitude = clientPhotos[0].marker_coordinates[0];
        const modifiedLongitude = clientPhotos[0].marker_coordinates[1];
        if (clientPhotos[0].marker_coordinates.length) {
          const newBounds = L.latLngBounds(clientPhotos[0].marker_coordinates, [
            modifiedLatitude,
            modifiedLongitude,
          ]);
          setBounds(newBounds);
        }
        setLoading(true);
      }
    }
  }, [orderInformation, clientInformation, clientPhotos]);

  useEffect(() => {
    if (orderInformation) {
      if (deliveryId === orderInformation.id) {
        setOpenTask(true);
      }
    }
  }, [orderInformation]);

  return (
    <>
      <FlexContainer container direction='column'>
        <FlexContainer
          container
          justify='space-between'
          alignItems='center'
          margin='0 0 10px 0'
          backgroundColor={THEME.colors.backgroundColor}
        >
          {orderInformation && (
            <>
              <FlexContainer>
                <Typography fontWeight={700} margin='0 10px'>
                  {orderInformation.task_name}
                </Typography>
                <Typography>
                  {orderInformation.task_date !== '-'
                    ? moment(orderInformation.task_date).format('hh:mm, A')
                    : 'Orden Sin Asignar'}
                </Typography>
              </FlexContainer>
              <FlexContainer alignItems='center'>
                <Typography margin='0 5px' color={THEME.colors.textSecondary}>
                  {orderInformation.original_eta &&
                    `ETA Original ${orderInformation.original_eta}`}
                </Typography>
                <Typography margin='0 5px' color={THEME.colors.textSecondary}>
                  {orderInformation.real_eta &&
                    `ETA Real ${orderInformation.real_eta}`}
                </Typography>
                <div
                  style={{
                    transform: openTask ? 'rotate(180deg)' : 'none',
                    transition: 'all 0.3s',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setOpenTask(!openTask);
                    }}
                  >
                    <Icon icon='arrow-icon' size={15} />
                  </IconButton>
                </div>
              </FlexContainer>
            </>
          )}
        </FlexContainer>
      </FlexContainer>

      {loading && (
        <Collapse in={openTask} timeout='auto'>
          <FlexContainer container direction='column' backgroundColor='#fffff'>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FlexContainer container direction='column' padding='0 10px'>
                  <Typography
                    color={THEME.colors.textSecondary}
                    fontSize='12px'
                  >
                    Estado
                  </Typography>
                  <Status
                    fontSize={12}
                    color={
                      statusColors[orderInformation.task_code] ||
                      statusColors.default
                    }
                    label={orderInformation.task_status}
                    margin='10px 0'
                  />
                  {orderInformation.task_status_detail && (
                    <>
                      <Typography
                        color={THEME.colors.textSecondary}
                        fontSize='12px'
                      >
                        Detalle de estado
                      </Typography>
                      <Typography fontSize='12px' margin='0 0 10px'>
                        {orderInformation.task_status_detail}
                      </Typography>
                    </>
                  )}
                  <Typography
                    color={THEME.colors.textSecondary}
                    fontSize='12px'
                  >
                    Dirección
                  </Typography>
                  <Typography fontSize='12px' margin='0 0 10px'>
                    {orderInformation.task_address}
                  </Typography>
                  <Typography
                    color={THEME.colors.textSecondary}
                    fontSize='12px'
                  >
                    Distrito
                  </Typography>
                  <Typography fontSize='12px' margin='0 0 10px'>
                    {orderInformation.task_district || '-'}
                  </Typography>
                  <Typography
                    color={THEME.colors.textSecondary}
                    fontSize='12px'
                  >
                    Referencia
                  </Typography>
                  <Typography fontSize='12px' margin='0 0 10px'>
                    {orderInformation.task_reference || '-'}
                  </Typography>
                  <Typography
                    color={THEME.colors.textSecondary}
                    fontSize='12px'
                  >
                    Contacto
                  </Typography>
                  <Typography fontSize='12px'>
                    {orderInformation.task_contact}
                  </Typography>
                  <Typography fontSize='12px' margin='0 0 10px'>
                    {orderInformation.task_contact_phone || '-'}
                  </Typography>
                  <Typography
                    color={THEME.colors.textSecondary}
                    fontSize='12px'
                  >
                    DNI
                  </Typography>
                  <Typography fontSize='12px' margin='0 0 10px'>
                    {clientInformation[0].dni || '-'}
                  </Typography>
                  <Typography
                    color={THEME.colors.textSecondary}
                    fontSize='12px'
                  >
                    Recibido por
                  </Typography>
                  <Typography fontSize='12px' margin='0 0 10px'>
                    {clientInformation[0].received_by || '-'}
                  </Typography>
                  <Typography
                    color={THEME.colors.textSecondary}
                    fontSize='12px'
                  >
                    Extra Data
                  </Typography>
                  <Typography fontSize='12px' margin='0 0 10px'>
                    {clientInformation[0].extra_data
                      ? clientInformation[0].extra_data.text
                      : '-'}
                  </Typography>
                  <Typography
                    color={THEME.colors.textSecondary}
                    fontSize='12px'
                  >
                    Comentarios
                  </Typography>
                  <Typography fontSize='12px' margin='0 0 10px'>
                    {clientInformation[0].comments || '-'}
                  </Typography>
                </FlexContainer>
              </Grid>
              <Grid item xs={6}>
                <FlexContainer container direction='column'>
                  <FlexContainer direction='column'>
                    <FlexContainer
                      width='250px'
                      height='265px'
                      margin='20px 0'
                      padding='10px'
                      borderColor={THEME.colors.borderColor}
                    >
                      <Map zoom={13} bounds={bounds}>
                        <Marker
                          icon='colorFlag'
                          position={clientPhotos[0].marker_coordinates}
                        />
                        {orderInformation.final_position && (
                          <Marker
                            icon='completed'
                            position={orderInformation.final_position}
                          />
                        )}
                      </Map>
                    </FlexContainer>
                  </FlexContainer>

                  <FlexContainer>
                    <Typography
                      color={THEME.colors.textSecondary}
                      fontSize='12px'
                    >
                      Firma
                    </Typography>
                    <FlexContainer
                      margin='15px'
                      onClick={() => {
                        setOpen(true);
                        getImageForModal(
                          null,
                          null,
                          clientPhotos[0].sign_photo.image
                        );
                      }}
                    >
                      {clientPhotos[0].sign_photo ? (
                        <img
                          src={clientPhotos[0].sign_photo.image}
                          width='150px'
                          height='80px'
                          alt='signPhoto'
                          style={{
                            margin: '10px 0',
                            border: 'solid 1px #efeff7',
                            borderRadius: '4px',
                          }}
                        />
                      ) : (
                        'Sin Firma'
                      )}
                    </FlexContainer>
                  </FlexContainer>

                  <FlexContainer margin='20px 0' wrap='wrap'>
                    <Typography
                      color={THEME.colors.textSecondary}
                      fontSize='12px'
                    >
                      Fotos
                    </Typography>
                    {clientPhotos[0].item_pictures.length !== 0 && (
                      <FlexContainer margin='15px' wrap='wrap'>
                        {clientPhotos[0].item_pictures.map(photo => (
                          <FlexContainer
                            key={photo.id}
                            maxWidth='230px'
                            margin='0 5px'
                            onClick={() => {
                              setOpen(true);
                              getImageForModal(
                                photo.id,
                                clientPhotos[0].item_pictures
                              );
                            }}
                          >
                            <img
                              src={photo.image}
                              width='50px'
                              height='50px'
                              alt='item_picture'
                              style={{ margin: '10px 0' }}
                            />
                          </FlexContainer>
                        ))}
                      </FlexContainer>
                    )}
                    {externalProviderPhotos &&
                      externalProviderPhotos.length !== 0 &&
                      externalProviderPhotos[0].photos.length !== 0 && (
                        <FlexContainer margin='15px' wrap='wrap'>
                          {externalProviderPhotos[0].photos.map(photo => (
                            <FlexContainer
                              key={photo}
                              maxWidth='230px'
                              margin='0 5px'
                              onClick={() => {
                                setOpen(true);
                                getImageForModal(null, null, photo);
                              }}
                            >
                              <img
                                src={photo}
                                width='50px'
                                height='60px'
                                alt='item_picture'
                                style={{ margin: '10px 0' }}
                              />
                            </FlexContainer>
                          ))}
                        </FlexContainer>
                      )}
                    {(!externalProviderPhotos ||
                      externalProviderPhotos.length === 0) &&
                      clientPhotos[0].item_pictures.length === 0 && (
                        <FlexContainer margin='15px'>Sin Fotos</FlexContainer>
                      )}
                  </FlexContainer>
                </FlexContainer>
              </Grid>
            </Grid>
          </FlexContainer>
        </Collapse>
      )}

      <Dialog open={open} onClose={() => setOpen(false)}>
        <CloseIcon onClick={() => setOpen(false)}>
          <Icon
            icon='cancel-icon'
            color={THEME.colors.placeholderColor}
            size={25}
          />
        </CloseIcon>
        {imageName === 'internal' && (
          <PictureOnModelContainer>
            <AliceCarousel
              activeIndex={mainIndex}
              animationType='fadeout'
              disableButtonsControls
              disableDotsControls
              items={clientPhotos[0].item_pictures.map(item => (
                <CarouselContainer>
                  <FlexContainer
                    direction='column'
                    margin='10px 0'
                    alignItems='center'
                  >
                    {item.description && (
                      <Typography fontWeight={700} fontSize='16px'>
                        {item.description}
                      </Typography>
                    )}
                    {item.text && <Typography>{item.text}</Typography>}
                    <Typography>{`${mainIndex + 1}/${
                      clientPhotos[0].item_pictures.length
                    }`}</Typography>
                  </FlexContainer>
                  <FlexContainer alignItems='center'>
                    <Button
                      variant='contained'
                      color='secondary'
                      padding='6px 7px'
                      margin='0 5px 0 0'
                      onClick={() => slidePrev()}
                    >
                      <ArrowLeft />
                    </Button>
                    <CarousselImage src={item.image} />
                    <Button
                      variant='contained'
                      color='secondary'
                      padding='6px 7px'
                      margin='0 0 0 5px'
                      onClick={() => slideNext()}
                    >
                      <ArrowRight />
                    </Button>
                  </FlexContainer>
                </CarouselContainer>
              ))}
            />
          </PictureOnModelContainer>
        )}

        {imageName === 'external' && (
          <PictureOnModelContainer>
            <AliceCarousel
              activeIndex={mainIndex}
              animationType='fadeout'
              disableButtonsControls
              disableDotsControls
              items={externalProviderPhotos[0].photos.map(item => (
                <CarouselContainer>
                  <FlexContainer
                    direction='column'
                    margin='10px 0'
                    alignItems='center'
                  >
                    {externalProviderPhotos[0].label && (
                      <Typography fontWeight={700} fontSize='16px'>
                        {externalProviderPhotos[0].label}
                      </Typography>
                    )}
                    <Typography>{`${mainIndex + 1}/${
                      externalProviderPhotos[0].photos.length
                    }`}</Typography>
                  </FlexContainer>
                  <FlexContainer alignItems='center'>
                    <Button
                      variant='contained'
                      color='secondary'
                      padding='6px 7px'
                      margin='0 5px 0 0'
                      onClick={() => slidePrev()}
                    >
                      <ArrowLeft />
                    </Button>
                    <CarousselImage src={item} />
                    <Button
                      variant='contained'
                      color='secondary'
                      padding='6px 7px'
                      margin='0 0 0 5px'
                      onClick={() => slideNext()}
                    >
                      <ArrowRight />
                    </Button>
                  </FlexContainer>
                </CarouselContainer>
              ))}
            />
          </PictureOnModelContainer>
        )}
      </Dialog>
    </>
  );
};

export default TaskCollapse;
