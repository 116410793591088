import React, { useState, useEffect } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import moment from 'moment';
import {
  getRouteTypes,
  getOrderTypes,
  getProviders,
  getHeatMap,
} from 'services';
import { useTypedSelector } from 'store';
import { FlexContainer } from 'components/atoms';
import { Map } from 'components/molecules';
import { FiltersContainer } from 'components/organisms';
import useRequest from 'hooks/useRequest';
import { optionFormatter } from 'util/functions';

const initialFilters = [
  {
    label: 'Fecha inicio',
    placeholder: 'Seleccionar fecha',
    name: 'start_date',
    type: 'date',
  },
  {
    label: 'Fecha final',
    placeholder: 'Seleccionar fecha',
    name: 'end_date',
    type: 'date',
  },
  {
    label: 'Provider',
    placeholder: 'Proveedor',
    name: 'provider_id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
    permission: 'core.provider.list',
  },
  {
    label: 'Tipo de ruta',
    placeholder: 'Tipo de ruta',
    name: 'route_type_id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
    permission: 'core.route_type.list',
  },
  {
    label: 'Tipo de orden',
    placeholder: 'Tipo de órden',
    name: 'order_type_id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
  },
];

const Historical: React.FC = () => {
  const selectedOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  const user = useTypedSelector(store => store.user);
  const [heatMapData, setHeatMapData] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [filterValues, setFilterValues] = useState({
    start_date: moment()
      .startOf('year')
      .format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    branch_office_id: null,
    route_type_id: null,
    order_type_id: null,
    provider_id: null,
  });
  // API calls
  const [providers, fetchProviders, loadingProviders] = useRequest(
    getProviders,
    []
  );
  const [routeTypes, fetchRouteTypes, loadingRouteTypes] = useRequest(
    getRouteTypes,
    []
  );
  const [orderTypes, fetchOrderTypes, loadingOrderTypes] = useRequest(
    getOrderTypes,
    []
  );
  const [heatMap, fetchHeatMap] = useRequest(getHeatMap);

  const requestData = (resetProvider?: boolean) => {
    const params = {
      start_date: filterValues.start_date,
      end_date: filterValues.end_date,
      order_type_id: filterValues.order_type_id,
      route_type_id: filterValues.route_type_id,
      branch_office_id: filterValues.branch_office_id,
      provider_id: resetProvider ? null : filterValues.provider_id,
    };
    fetchHeatMap(params);
  };

  useEffect(() => {
    if (!isEmpty(heatMap.results)) {
      const data = heatMap.results.map(point => {
        const coors = point.coordinate.split(',');
        const latitude = coors[0] ? parseFloat(coors[0]) : 0;
        const longitude = coors[1] ? parseFloat(coors[1]) : 0;
        return [latitude, longitude, point.quantity];
      });
      setHeatMapData(data);
    } else {
      setHeatMapData([]);
    }
  }, [heatMap]);

  useEffect(() => {
    fetchOrderTypes({
      page_size: 0,
    });
    fetchRouteTypes({
      page_size: 0,
    });
  }, [fetchOrderTypes, fetchRouteTypes]);

  useEffect(() => {
    setFilterValues({
      start_date: moment()
        .startOf('year')
        .format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      branch_office_id: null,
      route_type_id: null,
      order_type_id: null,
      provider_id: null,
    });
    fetchProviders({
      page_size: 0,
      branchoffices__id: selectedOfficeId,
    });
    requestData(true);
  }, [selectedOfficeId]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[2].options = optionFormatter(providers, {
      id: item => item.id,
      name: item => item.business_name,
    });
    setFilters(updatedFilters);
  }, [providers]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[3].options = optionFormatter(routeTypes);
    setFilters(updatedFilters);
  }, [routeTypes]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[4].options = optionFormatter(orderTypes);
    setFilters(updatedFilters);
  }, [orderTypes]);

  useEffect(() => {
    requestData();
  }, [filterValues]);

  return (
    <FlexContainer
      container
      padding='30px 40px'
      direction='column'
      height='100%'
    >
      <FlexContainer container alignItems='flex-end' margin='0 0 20px'>
        <FiltersContainer
          labelWeight={500}
          filters={filters}
          value={filterValues}
          onChange={(value, name) =>
            setFilterValues({
              ...filterValues,
              [name]: value,
            })
          }
          disabled={loadingRouteTypes || loadingOrderTypes || loadingProviders}
          permissions={user.permissions}
          margin='0 10px 10px 0'
          wrap='wrap'
          showBranchOffice
        />
      </FlexContainer>

      <FlexContainer width='100%' margin='0 0 10px' flex='1'>
        <Map zoom={10} position={[-12.1166362, -77.0138613]}>
          <HeatmapLayer
            fitBoundsOnLoad
            fitBoundsOnUpdate
            points={heatMapData}
            longitudeExtractor={m => m[1]}
            latitudeExtractor={m => m[0]}
            intensityExtractor={m => parseFloat(m[2])}
          />
        </Map>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Historical;
