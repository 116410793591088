import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import axios from 'util/axios';
import moment from 'moment';
import CountUp from 'react-countup';
import { cloneDeep, isEmpty, find } from 'lodash';
import { Grid } from '@material-ui/core';
import { makeVisFlexible, RadialChart, Hint } from 'react-vis';
import { useDispatch } from 'react-redux';
import {
  Button,
  FlexContainer,
  Card,
  Typography,
  Icon,
} from 'components/atoms';
import { Cell, Spinner, FormControl } from 'components/molecules';
import { DataTable, FiltersContainer } from 'components/organisms';
import { useTypedSelector } from 'store';
import { selectBranchOffice } from 'store/organization/actions';
import {
  getProviders,
  getRouteTypes,
  getOrderTypes,
  getOrderByStatus,
  getOrdersGeneral,
  getFailedOrders,
  getOrderByType,
  getOrderByProvider,
  getOrderByWorker,
  getOrganizationFeedback,
  chooseOrganization,
} from 'services';
import useRequest from 'hooks/useRequest';
import { optionFormatter } from 'util/functions';
import THEME from 'util/styledTheme';
import statusColors, { dashboardColors } from 'util/statusColors';
import Box from 'assets/images/box_metrics.svg';
import Star from 'assets/images/estrella_metrics.svg';
import Clock from 'assets/images/reloj_metrics.svg';
import Spin from 'assets/images/spin_metrics.svg';
import NoData from 'assets/images/no_data.svg';

const FlexRadialChart = makeVisFlexible(RadialChart);

const dateOptions = [
  {
    id: '2',
    name: 'Últimos 7 días',
  },
  {
    id: '3',
    name: 'Últimos 14 días',
  },
  {
    id: '4',
    name: 'Personalizar',
  },
];

const customFilters = [
  {
    label: 'Fecha inicial',
    placeholder: 'Seleccionar fecha',
    name: 'start_date',
    type: 'date',
  },
  {
    label: 'Fecha final',
    placeholder: 'Seleccionar fecha',
    name: 'end_date',
    type: 'date',
  },
];

const initialFilters = [
  {
    label: 'Provider',
    placeholder: 'Proveedor',
    name: 'provider_id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
    permission: 'core.provider.list',
  },
  {
    label: 'Tipo de ruta',
    placeholder: 'Tipo de ruta',
    name: 'route_type_id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
    permission: 'core.route_type.list',
  },
  {
    label: 'Tipo de orden',
    placeholder: 'Tipo de órden',
    name: 'order_type_id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
  },
];

const ChartHint = styled.div`
  background-color: ${props => props.theme.colors.textPrimary};
  padding: 5px 10px;
  border-radius: 4px;
  color: #ffffff;
`;

const Orders: React.FC = () => {
  const selectedOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  const organization = useTypedSelector(state => state.organization);
  const user = useTypedSelector(store => store.user);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState(initialFilters);
  const [hint, setHint] = useState<any>(false);
  const [failedHint, setFailedHint] = useState<any>(false);
  const [byRouteHint, setByRouteHint] = useState<any>(false);
  const [byOrderHint, setByOrderHint] = useState<any>(false);
  const [ordersFeedbackHint, setOrdersFeedbackHint] = useState<any>(false);
  const [deliveryFeedbackHint, setDeliveryFeedbackHint] = useState<any>(false);
  const [filterValues, setFilterValues] = useState({
    start_date: moment().format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
    route_type_id: null,
    order_type_id: null,
    provider_id: null,
  });
  const [byStatusChart, setByStatusChart] = useState([
    {
      id: 'id',
      angle: 1,
      quantity: 'Loading ...',
      label: 'Loading',
      color: statusColors.default,
    },
  ]);
  const [failedChart, setFailedChart] = useState([
    {
      angle: 1,
      quantity: 'Loading ...',
      label: 'Loading',
      color: dashboardColors[0],
    },
  ]);
  const [byRouteTypeChart, setByRouteTypeChart] = useState([
    {
      angle: 1,
      quantity: 'Loading ...',
      label: 'Loading',
      color: dashboardColors[0],
    },
  ]);
  const [byOrderTypeChart, setByOrderTypeChart] = useState([
    {
      angle: 1,
      quantity: 'Loading ...',
      label: 'Loading',
      color: dashboardColors[0],
    },
  ]);
  const [byProductsFeedbackChart, setProductsFeedbackChart] = useState([
    {
      angle: 1,
      quantity: 'Loading ...',
      label: 'Loading',
      color: statusColors.default,
    },
  ]);
  const [byDeliveryFeedbackChart, setDeliveryFeedbackChart] = useState([
    {
      angle: 1,
      quantity: 'Loading ...',
      label: 'Loading',
      color: statusColors.default,
    },
  ]);
  const [byStatusTotal, setByStatusTotal] = useState<number>(0);
  const [byRouteTotal, setByRouteTotal] = useState<number>(0);
  const [byOrderTotal, setByOrderTotal] = useState<number>(0);
  const [failedTotal, setFailedTotal] = useState<number>(0);
  const [feedbackDeliveryTotal, setFeedbackDeliveryTotal] = useState<number>(0);
  const [productDeliveryTotal, setProductDeliveryTotal] = useState<number>(0);
  const [ordersByStatusData, setOrdersByStatusData] = useState([]);
  const [selectRange, setSelectRange] = useState<string>('1');
  const [officeOptions, setOfficeOptions] = useState([]);
  const defaultOption = { id: '', name: 'Todas', reference_code: '' };
  const toSelectDefaultOption = {
    id: JSON.stringify(defaultOption),
    name: 'Todas',
  };

  // API calls
  const [providers, fetchProviders, loadingProviders] = useRequest(
    getProviders,
    []
  );
  const [routeTypes, fetchRouteTypes, loadingRouteTypes] = useRequest(
    getRouteTypes,
    []
  );
  const [orderTypes, fetchOrderTypes, loadingOrderTypes] = useRequest(
    getOrderTypes,
    []
  );
  const [
    ordersByStatus,
    fetchOrdersByStatus,
    loadingOrdersByStatus,
  ] = useRequest(getOrderByStatus);
  const [ordersGeneral, fetchOrdersGeneral, loadingGeneral] = useRequest(
    getOrdersGeneral
  );
  const [failedOrders, fetchFailedOrders, loadingFailed] = useRequest(
    getFailedOrders
  );
  const [ordersByType, fetchOrdersByType, loadingByType] = useRequest(
    getOrderByType
  );
  const [ordersByProvider, fetchOrdersByProvider] = useRequest(
    getOrderByProvider,
    []
  );
  const [ordersByWorker, fetchOrdersByWorker] = useRequest(
    getOrderByWorker,
    []
  );
  const [ordersFeedback, fetchOrdersFeedback, loadingByFeedback] = useRequest(
    getOrganizationFeedback
  );

  const requestData = (resetProvider?: boolean) => {
    const params = {
      start_date: filterValues.start_date,
      end_date: filterValues.end_date,
      order_type_id: filterValues.order_type_id,
      route_type_id: filterValues.route_type_id,
      branch_office_id: selectedOfficeId,
      provider_id: resetProvider ? null : filterValues.provider_id,
    };
    fetchOrdersByStatus(params);
    fetchOrdersGeneral(params);
    fetchFailedOrders(params);
    fetchOrdersByType(params);
    fetchOrdersByProvider(params);
    fetchOrdersByWorker(params);
    fetchOrdersFeedback(params);
  };

  const downloadData = (type: string) => {
    const params = {
      start_date: filterValues.start_date,
      end_date: filterValues.end_date,
      order_type_id: filterValues.order_type_id,
      route_type_id: filterValues.route_type_id,
      branch_office_id: selectedOfficeId,
      provider_id: filterValues.provider_id,
    };
    axios({
      url:
        type === 'worker'
          ? '/analytics/report/averages_by_worker'
          : 'analytics/report/averages_by_provider',
      method: 'GET',
      params,
      responseType: 'blob',
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${type}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    fetchOrderTypes({
      page_size: 0,
    });
    fetchRouteTypes({
      page_size: 0,
    });
  }, [fetchOrderTypes, fetchRouteTypes]);

  useEffect(() => {
    setFilterValues({
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      route_type_id: null,
      order_type_id: null,
      provider_id: null,
    });
    fetchProviders({
      page_size: 0,
      branchoffices__id: selectedOfficeId,
    });
    requestData(true);
  }, [selectedOfficeId]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[0].options = optionFormatter(providers, {
      id: item => item.id,
      name: item => item.business_name,
    });
    setFilters(updatedFilters);
  }, [providers]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[1].options = optionFormatter(routeTypes);
    setFilters(updatedFilters);
  }, [routeTypes]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[2].options = optionFormatter(orderTypes);
    setFilters(updatedFilters);
  }, [orderTypes]);

  useEffect(() => {
    if (!isEmpty(ordersByStatus)) {
      const orderData = Object.keys(ordersByStatus).map(key => ({
        id: key,
        label: ordersByStatus[key].label,
        percentage: ordersByStatus[key].percentage,
        quantity: ordersByStatus[key].quantity,
      }));
      const chartData = Object.keys(ordersByStatus).map(key => ({
        id: key,
        angle: ordersByStatus[key].quantity,
        quantity: ordersByStatus[key].quantity,
        label: ordersByStatus[key].label,
        color: statusColors[key] || statusColors.default,
      }));
      setByStatusChart(chartData);
      setByStatusTotal(
        chartData.reduce((prev, next) => prev + next.quantity, 0)
      );
      setOrdersByStatusData(orderData);
    }
  }, [ordersByStatus]);

  useEffect(() => {
    if (!isEmpty(failedOrders) && failedOrders.results) {
      const failedData = failedOrders.results.map((order, index) => ({
        angle: order.percentage,
        quantity: order.quantity,
        label: order.label,
        color: dashboardColors[index % 6],
      }));
      setFailedTotal(
        failedOrders.results.reduce((prev, next) => prev + next.quantity, 0)
      );
      setFailedChart(failedData);
    }
  }, [failedOrders]);

  useEffect(() => {
    if (!isEmpty(ordersFeedback)) {
      setFeedbackDeliveryTotal(ordersFeedback.total_delivery);
      setProductDeliveryTotal(ordersFeedback.total_product);
      const completeFeedbackData = Object.entries(ordersFeedback).map(data => ({
        label: data[0],
        quantity: data[1],
      }));
      const productFeedbackData = completeFeedbackData.filter(
        data => data.label.includes('product') && !data.label.includes('total')
      );
      const filteredProductFeedbackData = productFeedbackData.map(
        (product: any, index: number) => ({
          angle: product.quantity,
          quantity: product.quantity,
          label: product.label,
          color: dashboardColors[index % 6],
        })
      );
      setProductsFeedbackChart(filteredProductFeedbackData);
      const deliveryFeedbackData = completeFeedbackData.filter(
        data => data.label.includes('delivery') && !data.label.includes('total')
      );
      const filteredDeliveryData = deliveryFeedbackData.map(
        (product: any, index: number) => ({
          angle: product.quantity,
          quantity: product.quantity,
          label: product.label,
          color: dashboardColors[index % 6],
        })
      );
      setDeliveryFeedbackChart(filteredDeliveryData);
    }
  }, [ordersFeedback]);

  useEffect(() => {
    if (!isEmpty(ordersByType)) {
      if (ordersByType.route_type) {
        const byRoute = ordersByType.route_type.map((item, index) => ({
          angle: item.percentage,
          quantity: item.quantity,
          label: item.name,
          color: dashboardColors[index % 6],
        }));
        const totalRoute = ordersByType.route_type.reduce(
          (prev, next) => prev + next.quantity,
          0
        );
        setByRouteTypeChart(byRoute);
        setByRouteTotal(totalRoute);
      }
      if (ordersByType.order_type) {
        const byOrder = ordersByType.order_type.map((item, index) => ({
          angle: item.percentage,
          quantity: item.quantity,
          label: item.name,
          color: dashboardColors[index % 6],
        }));
        const totalOrder = ordersByType.order_type.reduce(
          (prev, next) => prev + next.quantity,
          0
        );
        setByOrderTypeChart(byOrder);
        setByOrderTotal(totalOrder);
      }
    }
  }, [ordersByType]);

  useEffect(() => {
    chooseOrganization({
      page_size: 0,
    }).then(res => {
      const organizationObj = find(res.data, {
        id: parseInt(`${organization.selectedOrganization.id}`, 10),
      });

      if (organizationObj) {
        setOfficeOptions(
          optionFormatter(organizationObj.administrator_branch_offices, {
            id: option => JSON.stringify(option),
            name: option => option.name,
          })
        );
      }
    });
  }, []);

  const byStatusHeader = useMemo(
    () => [
      {
        label: 'Status',
        id: 'status',
        cell: row => (
          <Cell.Status color={statusColors[row.id] || statusColors.default}>
            {row.label}
          </Cell.Status>
        ),
      },
      {
        label: '%',
        id: 'percent',
        cell: row => `${row.percentage} %`,
      },
    ],
    [filterValues]
  );

  useEffect(() => {
    requestData();
  }, [filterValues]);

  const byTypeHeader = useMemo(
    () => [
      {
        label: 'Label',
        id: 'label',
        cell: row => <Cell.Status color={row.color}>{row.label}</Cell.Status>,
      },
      {
        label: '%',
        id: 'percent',
        cell: row => (
          <span style={{ whiteSpace: 'nowrap' }}>{`${row.angle} %`}</span>
        ),
      },
    ],
    []
  );

  const byWorkerHeader = useMemo(
    () => [
      {
        label: 'Nombres',
        id: 'names',
        cell: row => row.fullname || row.business_name,
      },
      {
        label: 'Cantidad',
        id: 'quantity',
        cell: row => row.quantity,
      },
      {
        label: 'Entregadas',
        id: 'delivered',
        cell: row => `${row.delivered_orders_percentage} %`,
      },
      {
        label: 'A tiempo',
        id: 'perfect',
        cell: row => `${row.perfect_orders_percentage} %`,
      },
    ],
    []
  );

  return (
    <FlexContainer container padding='30px 36px' direction='column'>
      <FlexContainer container alignItems='flex-end'>
        <FormControl
          control='select'
          placeholder='Seleccione una organización'
          value={JSON.stringify(organization.selectedBranchOffice)}
          margin='20px 20px 0 0'
          options={officeOptions}
          defaultOption={toSelectDefaultOption}
          onChange={value => {
            const parsedValue = JSON.parse(value);
            dispatch(
              selectBranchOffice({
                id: parsedValue.id,
                name: parsedValue.name,
                reference_code: parsedValue.reference_code,
              })
            );
          }}
          disabled={loadingRouteTypes || loadingOrderTypes || loadingProviders}
        />
        <FormControl
          control='select'
          value={selectRange}
          placeholder='Seleccionar rango de fechas'
          defaultOption={{ id: '1', name: 'Hoy' }}
          options={dateOptions}
          onChange={value => {
            if (value === '1') {
              setFilterValues({
                ...filterValues,
                start_date: moment().format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
              });
            } else if (value === '2') {
              setFilterValues({
                ...filterValues,
                start_date: moment()
                  .subtract(7, 'days')
                  .format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
              });
            } else if (value === '3') {
              setFilterValues({
                ...filterValues,
                start_date: moment()
                  .subtract(14, 'days')
                  .format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
              });
            }
            setSelectRange(value);
          }}
          disabled={loadingRouteTypes || loadingOrderTypes || loadingProviders}
          width='190px'
          margin='0 20px 0 0'
        />

        {selectRange === '4' && (
          <FiltersContainer
            labelWeight={500}
            filterWidth='190px'
            filters={customFilters}
            value={filterValues}
            onChange={(value, name) => {
              setFilterValues({
                ...filterValues,
                [name]: value,
              });
            }}
            disabled={
              loadingRouteTypes || loadingOrderTypes || loadingProviders
            }
            permissions={user.permissions}
          />
        )}

        <FiltersContainer
          labelWeight={500}
          filterWidth='190px'
          filters={filters}
          value={filterValues}
          onChange={(value, name) => {
            setFilterValues({
              ...filterValues,
              [name]: value,
            });
          }}
          disabled={loadingRouteTypes || loadingOrderTypes || loadingProviders}
          permissions={user.permissions}
        />
      </FlexContainer>

      <FlexContainer container margin='20px 0'>
        <Grid container spacing={2} alignItems='stretch'>
          <Grid item xs={4}>
            <Card
              shadow
              width='100%'
              height='100%'
              padding='20px'
              display='flex'
            >
              <Typography margin='0 0 10px'>Órdenes por status</Typography>
              <FlexContainer container alignItems='stretch' flex='1'>
                <FlexContainer width='50%' alignItems='center' justify='center'>
                  {loadingOrdersByStatus ? (
                    <Spinner height='100px' />
                  ) : (
                    <>
                      {byStatusTotal === 0 ? (
                        <img src={NoData} alt='no_data' />
                      ) : (
                        <FlexRadialChart
                          animation
                          colorType='literal'
                          data={byStatusChart}
                          radius={70}
                          innerRadius={35}
                          onValueMouseOver={v => setHint(v)}
                          onSeriesMouseOut={() => setHint(false)}
                        >
                          {hint && (
                            <Hint value={hint}>
                              <ChartHint>{`${hint.label}: ${hint.quantity}`}</ChartHint>
                            </Hint>
                          )}
                        </FlexRadialChart>
                      )}
                    </>
                  )}
                </FlexContainer>
                <FlexContainer
                  width='50%'
                  alignItems='center'
                  justify='center'
                  direction='column'
                >
                  <FlexContainer container margin='0 0 10px' justify='center'>
                    <Typography color='text.secondary' margin='0 10px 0 0'>
                      Total
                    </Typography>
                    <Typography fontWeight={600} fontSize={16}>
                      {byStatusTotal}
                    </Typography>
                  </FlexContainer>

                  <FlexContainer container maxHeight='200px' overflow='auto'>
                    {loadingOrdersByStatus ? (
                      <Spinner height='150px' />
                    ) : (
                      <DataTable
                        data={ordersByStatusData}
                        headers={byStatusHeader}
                        onChangePage={() => {}}
                        totalItems={0}
                        totalPages={0}
                        pageSize={0}
                        page={1}
                        showPagination={false}
                        marginBottom='0'
                        showHeader={false}
                        borderless
                      />
                    )}
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <FlexContainer container height='100%'>
              <Grid container spacing={2} style={{ height: '105%' }}>
                <Grid item xs={4} style={{ height: '50%' }}>
                  <Card shadow width='100%' height='100%' padding='20px'>
                    <FlexContainer
                      container
                      flex-direction='row'
                      margin='0 0 15px'
                      justify='center'
                    >
                      <Typography color='text.secondary' fontSize={12}>
                        Órdenes entregadas
                      </Typography>
                    </FlexContainer>
                    <FlexContainer
                      container
                      justify='center'
                      alignItems='center'
                    >
                      <FlexContainer
                        width='40px'
                        height='40px'
                        borderRadius='100px'
                        backgroundColor={THEME.colors.borderColor}
                        padding='10px'
                      >
                        <img src={Box} width='100%' height='auto' alt='box' />
                      </FlexContainer>
                      {!loadingGeneral &&
                      ordersGeneral.delivered_orders_percentage ? (
                        <>
                          <CountUp
                            start={0}
                            end={
                              ordersGeneral.delivered_orders_percentage.value
                            }
                            duration={3}
                            delay={0}
                            separator=''
                            decimal=','
                            suffix='%'
                          >
                            {({ countUpRef }) => (
                              <Typography
                                fontWeight={600}
                                fontSize={50}
                                margin='0 20px'
                              >
                                <span ref={countUpRef} />
                              </Typography>
                            )}
                          </CountUp>
                          <Typography color='text.secondary' fontSize={20}>
                            {`(${ordersGeneral.delivered_orders_percentage.total})`}
                          </Typography>
                        </>
                      ) : (
                        <FlexContainer margin='0 20px' width='100px'>
                          <Spinner spinnerSize={30} />
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Card>
                </Grid>
                <Grid item xs={4} style={{ height: '50%' }}>
                  <Card shadow width='100%' height='100%' padding='20px'>
                    <FlexContainer container margin='0 0 15px' justify='center'>
                      <Typography color='text.secondary' fontSize={12}>
                        Órdenes a tiempo
                      </Typography>
                    </FlexContainer>
                    <FlexContainer
                      container
                      justify='center'
                      alignItems='center'
                    >
                      <FlexContainer
                        width='40px'
                        height='40px'
                        borderRadius='100px'
                        backgroundColor={THEME.colors.borderColor}
                        padding='10px'
                      >
                        <img src={Star} width='100%' height='auto' alt='box' />
                      </FlexContainer>
                      {!loadingGeneral &&
                      ordersGeneral.perfect_orders_percentage ? (
                        <>
                          <CountUp
                            start={0}
                            end={ordersGeneral.perfect_orders_percentage.value}
                            duration={3}
                            delay={0}
                            separator=''
                            decimal=','
                            suffix='%'
                          >
                            {({ countUpRef }) => (
                              <Typography
                                fontWeight={600}
                                fontSize={50}
                                margin='0 20px'
                              >
                                <span ref={countUpRef} />
                              </Typography>
                            )}
                          </CountUp>
                          <Typography color='text.secondary' fontSize={20}>
                            {`(${ordersGeneral.perfect_orders_percentage.total})`}
                          </Typography>
                        </>
                      ) : (
                        <FlexContainer margin='0 20px' width='100px'>
                          <Spinner spinnerSize={30} />
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Card>
                </Grid>
                <Grid item xs={4} style={{ height: '50%' }}>
                  <Card shadow width='100%' height='100%' padding='20px'>
                    <FlexContainer container margin='0 0 15px' justify='center'>
                      <Typography color='text.secondary' fontSize={12}>
                        Órdenes Demoradas
                      </Typography>
                    </FlexContainer>
                    <FlexContainer
                      container
                      justify='center'
                      alignItems='center'
                    >
                      <FlexContainer
                        width='40px'
                        height='40px'
                        borderRadius='100px'
                        backgroundColor={THEME.colors.borderColor}
                        padding='10px'
                      >
                        <img src={Clock} width='100%' height='auto' alt='box' />
                      </FlexContainer>
                      {!loadingGeneral &&
                      ordersGeneral.delayed_orders_percentage ? (
                        <>
                          <CountUp
                            start={0}
                            end={ordersGeneral.delayed_orders_percentage.value}
                            duration={3}
                            delay={0}
                            separator=''
                            decimal=','
                            suffix='%'
                          >
                            {({ countUpRef }) => (
                              <Typography
                                fontWeight={600}
                                fontSize={50}
                                margin='0 20px'
                              >
                                <span ref={countUpRef} />
                              </Typography>
                            )}
                          </CountUp>
                          <Typography color='text.secondary' fontSize={20}>
                            {`(${ordersGeneral.delayed_orders_percentage.total})`}
                          </Typography>
                        </>
                      ) : (
                        <FlexContainer margin='0 20px' width='100px'>
                          <Spinner spinnerSize={30} />
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Card>
                </Grid>
                <Grid item xs={4} style={{ height: '50%' }}>
                  <Card shadow width='100%' height='100%' padding='20px'>
                    <FlexContainer container margin='0 0 15px' justify='center'>
                      <Typography color='text.secondary'>
                        AVG Delivery time
                      </Typography>
                    </FlexContainer>
                    <FlexContainer alignItems='center' justify='center'>
                      {!loadingGeneral &&
                      ordersGeneral.delivery_time_average ? (
                        <FlexContainer
                          direction='column'
                          justify='center'
                          alignItems='center'
                        >
                          <FlexContainer alignItems='center'>
                            <CountUp
                              start={0}
                              end={Math.ceil(
                                ordersGeneral.delivery_time_average.value / 60
                              )}
                              duration={3}
                              delay={0}
                              separator=''
                              decimal=','
                            >
                              {({ countUpRef }) => (
                                <Typography
                                  fontWeight={600}
                                  fontSize={50}
                                  margin='0 5px 0 0'
                                >
                                  <span ref={countUpRef} />
                                </Typography>
                              )}
                            </CountUp>
                            <Typography fontSize={20}>min</Typography>
                          </FlexContainer>
                        </FlexContainer>
                      ) : (
                        <FlexContainer margin='0 20px' width='100px'>
                          <Spinner spinnerSize={30} />
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Card>
                </Grid>
                <Grid item xs={4} style={{ height: '50%' }}>
                  <Card shadow width='100%' height='100%' padding='20px'>
                    <FlexContainer container margin='0 0 15px' justify='center'>
                      <Typography color='text.secondary'>
                        Avg Pickup waiting time
                      </Typography>
                    </FlexContainer>
                    <FlexContainer alignItems='center' justify='center'>
                      {!loadingGeneral &&
                      ordersGeneral.pickup_waiting_time_average ? (
                        <FlexContainer
                          direction='column'
                          justify='center'
                          alignItems='center'
                        >
                          <FlexContainer alignItems='center'>
                            <CountUp
                              start={0}
                              end={Math.ceil(
                                ordersGeneral.pickup_waiting_time_average
                                  .value / 60
                              )}
                              duration={3}
                              delay={0}
                              separator=''
                              decimal=','
                            >
                              {({ countUpRef }) => (
                                <Typography
                                  fontWeight={600}
                                  fontSize={50}
                                  margin='0 5px 0 0'
                                >
                                  <span ref={countUpRef} />
                                </Typography>
                              )}
                            </CountUp>
                            <Typography fontSize={20}>min</Typography>
                          </FlexContainer>
                        </FlexContainer>
                      ) : (
                        <FlexContainer margin='0 20px' width='100px'>
                          <Spinner spinnerSize={30} />
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Card>
                </Grid>
                <Grid item xs={4} style={{ height: '50%' }}>
                  <Card shadow width='100%' height='100%' padding='20px'>
                    <FlexContainer container margin='0 0 15px' justify='center'>
                      <Typography color='text.secondary'>
                        Avg tiempo de servicio
                      </Typography>
                    </FlexContainer>
                    <FlexContainer alignItems='center' justify='center'>
                      {!loadingGeneral && ordersGeneral.service_time_average ? (
                        <FlexContainer
                          direction='column'
                          justify='center'
                          alignItems='center'
                        >
                          <FlexContainer alignItems='center'>
                            <CountUp
                              start={0}
                              end={Math.ceil(
                                ordersGeneral.service_time_average.value / 60
                              )}
                              duration={3}
                              delay={0}
                              separator=''
                              decimal=','
                            >
                              {({ countUpRef }) => (
                                <Typography
                                  fontWeight={600}
                                  fontSize={50}
                                  margin='0 5px 0 0'
                                >
                                  <span ref={countUpRef} />
                                </Typography>
                              )}
                            </CountUp>
                            <Typography fontSize={20}>min</Typography>
                          </FlexContainer>
                        </FlexContainer>
                      ) : (
                        <FlexContainer margin='0 20px' width='100px'>
                          <Spinner spinnerSize={30} />
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Card>
                </Grid>
              </Grid>
            </FlexContainer>
          </Grid>

          <Grid item xs={4}>
            <Card shadow height='100%' padding='20px' display='flex'>
              <FlexContainer container margin='0 0 15px'>
                <Typography>Órdenes finales por tipo de ruta</Typography>
              </FlexContainer>
              <FlexContainer container alignItems='stretch' flex='1'>
                <FlexContainer width='50%' justify='center' alignItems='center'>
                  {loadingByType ? (
                    <Spinner height='100px' />
                  ) : (
                    <>
                      {byRouteTotal === 0 ? (
                        <img src={NoData} alt='no_data' />
                      ) : (
                        <FlexRadialChart
                          animation
                          colorType='literal'
                          data={byRouteTypeChart}
                          radius={60}
                          innerRadius={30}
                          onValueMouseOver={v => setByRouteHint(v)}
                          onSeriesMouseOut={() => setByRouteHint(false)}
                        >
                          {byRouteHint && (
                            <Hint value={byRouteHint}>
                              <ChartHint>{`${byRouteHint.label}: ${byRouteHint.quantity}`}</ChartHint>
                            </Hint>
                          )}
                        </FlexRadialChart>
                      )}
                    </>
                  )}
                </FlexContainer>
                <FlexContainer
                  width='50%'
                  minHeight='150px'
                  justify='center'
                  direction='column'
                >
                  <FlexContainer container margin='0 0 10px' justify='center'>
                    <Typography color='text.secondary' margin='0 10px 0 0'>
                      Total
                    </Typography>
                    <Typography fontWeight={600} fontSize={16}>
                      {byRouteTotal}
                    </Typography>
                  </FlexContainer>

                  <FlexContainer container maxHeight='200px' overflow='auto'>
                    {loadingByType ? (
                      <Spinner height='100px' />
                    ) : (
                      <DataTable
                        data={byRouteTypeChart || []}
                        dataIdentifier='label'
                        headers={byTypeHeader}
                        onChangePage={() => {}}
                        totalItems={0}
                        totalPages={0}
                        pageSize={0}
                        page={1}
                        showPagination={false}
                        marginBottom='0'
                        showHeader={false}
                        borderless
                      />
                    )}
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card shadow height='100%' padding='20px' display='flex'>
              <FlexContainer container margin='0 0 15px'>
                <Typography>Órdenes finales por tipo de orden</Typography>
              </FlexContainer>
              <FlexContainer container alignItems='stretch' flex='1'>
                <FlexContainer width='50%' justify='center' alignItems='center'>
                  {loadingByType ? (
                    <Spinner height='100px' />
                  ) : (
                    <>
                      {byOrderTotal === 0 ? (
                        <img src={NoData} alt='no_data' />
                      ) : (
                        <FlexRadialChart
                          animation
                          colorType='literal'
                          data={byOrderTypeChart}
                          radius={60}
                          innerRadius={30}
                          onValueMouseOver={v => setByOrderHint(v)}
                          onSeriesMouseOut={() => setByOrderHint(false)}
                        >
                          {byOrderHint && (
                            <Hint value={byOrderHint}>
                              <ChartHint>{`${byOrderHint.label}: ${byOrderHint.quantity}`}</ChartHint>
                            </Hint>
                          )}
                        </FlexRadialChart>
                      )}
                    </>
                  )}
                </FlexContainer>
                <FlexContainer
                  width='50%'
                  minHeight='150px'
                  justify='center'
                  direction='column'
                >
                  <FlexContainer container margin='0 0 10px' justify='center'>
                    <Typography color='text.secondary' margin='0 10px 0 0'>
                      Total
                    </Typography>
                    <Typography fontWeight={600} fontSize={16}>
                      {byOrderTotal}
                    </Typography>
                  </FlexContainer>

                  <FlexContainer container maxHeight='200px' overflow='auto'>
                    {loadingByType ? (
                      <Spinner height='100px' />
                    ) : (
                      <DataTable
                        data={byOrderTypeChart || []}
                        dataIdentifier='label'
                        headers={byTypeHeader}
                        onChangePage={() => {}}
                        totalItems={0}
                        totalPages={0}
                        pageSize={0}
                        page={1}
                        showPagination={false}
                        marginBottom='0'
                        showHeader={false}
                        borderless
                      />
                    )}
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card shadow height='100%' padding='20px' display='flex'>
              <FlexContainer container margin='0 0 15px'>
                <Typography>Motivo de visitas fallidas</Typography>
              </FlexContainer>
              <FlexContainer container alignItems='stretch' flex='1'>
                <FlexContainer width='50%' justify='center' alignItems='center'>
                  {loadingFailed ? (
                    <Spinner height='100px' />
                  ) : (
                    <>
                      {failedTotal === 0 ? (
                        <img src={NoData} alt='no_data' />
                      ) : (
                        <FlexRadialChart
                          animation
                          colorType='literal'
                          data={failedChart}
                          radius={60}
                          innerRadius={30}
                          onValueMouseOver={v => setFailedHint(v)}
                          onSeriesMouseOut={() => setFailedHint(false)}
                        >
                          {failedHint && (
                            <Hint value={failedHint}>
                              <ChartHint>{`${failedHint.label}: ${failedHint.quantity}`}</ChartHint>
                            </Hint>
                          )}
                        </FlexRadialChart>
                      )}
                    </>
                  )}
                </FlexContainer>
                <FlexContainer
                  width='50%'
                  minHeight='150px'
                  justify='center'
                  direction='column'
                >
                  <FlexContainer container margin='0 0 10px' justify='center'>
                    <Typography color='text.secondary' margin='0 10px 0 0'>
                      Total
                    </Typography>
                    <Typography fontWeight={600} fontSize={16}>
                      {failedTotal}
                    </Typography>
                  </FlexContainer>

                  <FlexContainer container maxHeight='200px' overflow='auto'>
                    {loadingFailed ? (
                      <Spinner height='100px' />
                    ) : (
                      <DataTable
                        data={failedChart || []}
                        dataIdentifier='label'
                        headers={byTypeHeader}
                        onChangePage={() => {}}
                        totalItems={0}
                        totalPages={0}
                        pageSize={0}
                        page={1}
                        showPagination={false}
                        marginBottom='0'
                        showHeader={false}
                        borderless
                      />
                    )}
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
            </Card>
          </Grid>

          {user.permissions.includes('analytics.provider') && (
            <Grid item xs={5}>
              <Card shadow width='100%' height='100%' padding='20px'>
                <FlexContainer
                  container
                  alignItems='center'
                  justify='space-between'
                  margin='0 0 10px'
                >
                  <Typography>Proveedor</Typography>
                  <Button
                    variant='contained'
                    color='secondary'
                    padding='0 10px'
                    onClick={() => downloadData('provider')}
                  >
                    <Icon icon='download-icon' size={15} />
                  </Button>
                </FlexContainer>
                <DataTable
                  data={ordersByProvider}
                  headers={byWorkerHeader}
                  onChangePage={() => {}}
                  totalItems={0}
                  totalPages={0}
                  pageSize={0}
                  page={1}
                  showPagination={false}
                  marginBottom='0'
                  borderless
                />
              </Card>
            </Grid>
          )}
          {user.permissions.includes('analytics.worker') && (
            <Grid item xs={4}>
              <Card shadow width='100%' height='100%' padding='20px'>
                <FlexContainer
                  container
                  alignItems='center'
                  justify='space-between'
                  margin='0 0 10px'
                >
                  <Typography>Worker</Typography>
                  <Button
                    variant='contained'
                    color='secondary'
                    padding='0 10px'
                    onClick={() => downloadData('worker')}
                  >
                    <Icon icon='download-icon' size={15} />
                  </Button>
                </FlexContainer>
                <DataTable
                  data={ordersByWorker}
                  headers={byWorkerHeader}
                  onChangePage={() => {}}
                  totalItems={0}
                  totalPages={0}
                  pageSize={0}
                  page={1}
                  showPagination={false}
                  marginBottom='0'
                  borderless
                />
              </Card>
            </Grid>
          )}

          <Grid item xs={3}>
            <FlexContainer container>
              <Grid container spacing={2} style={{ height: '105%' }}>
                <Grid item xs={12}>
                  <Card shadow width='100%' padding='20px'>
                    <FlexContainer container margin='0 0 15px' justify='center'>
                      <Typography color='text.secondary'>
                        Avg de Km por vehículo
                      </Typography>
                    </FlexContainer>
                    <FlexContainer
                      container
                      justify='center'
                      alignItems='center'
                    >
                      <FlexContainer
                        width='40px'
                        height='40px'
                        borderRadius='100px'
                        backgroundColor={THEME.colors.borderColor}
                        padding='10px'
                      >
                        <img src={Spin} width='100%' height='auto' alt='box' />
                      </FlexContainer>

                      {!loadingGeneral &&
                      ordersGeneral.estimated_distance_average ? (
                        <>
                          <CountUp
                            start={0}
                            end={ordersGeneral.estimated_distance_average.value}
                            duration={3}
                            delay={0}
                            separator=''
                            decimals={2}
                            decimal=','
                          >
                            {({ countUpRef }) => (
                              <Typography
                                fontWeight={600}
                                fontSize={50}
                                margin='0 20px'
                              >
                                <span ref={countUpRef} />
                              </Typography>
                            )}
                          </CountUp>
                          <Typography fontSize={20}>Kms</Typography>
                        </>
                      ) : (
                        <FlexContainer margin='0 20px' width='100px'>
                          <Spinner spinnerSize={30} />
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card shadow width='100%' padding='20px'>
                    <FlexContainer container margin='0 0 15px' justify='center'>
                      <Typography color='text.secondary'>
                        Vehículos utilizados
                      </Typography>
                    </FlexContainer>
                    <FlexContainer
                      container
                      justify='center'
                      alignItems='center'
                    >
                      {!loadingGeneral && ordersGeneral.used_vehicles ? (
                        <CountUp
                          start={0}
                          end={ordersGeneral.used_vehicles.value}
                          duration={3}
                          delay={0}
                          separator=''
                        >
                          {({ countUpRef }) => (
                            <Typography
                              fontWeight={600}
                              fontSize={50}
                              margin='0 20px'
                            >
                              <span ref={countUpRef} />
                            </Typography>
                          )}
                        </CountUp>
                      ) : (
                        <FlexContainer margin='0 20px' width='100px'>
                          <Spinner spinnerSize={30} />
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card shadow height='100%' padding='20px' display='flex'>
                    <FlexContainer container margin='0 0 15px'>
                      <Typography>Feedback Delivery</Typography>
                    </FlexContainer>
                    <FlexContainer container alignItems='stretch' flex='1'>
                      <FlexContainer
                        width='50%'
                        justify='center'
                        alignItems='center'
                      >
                        {loadingByFeedback ? (
                          <Spinner height='100px' />
                        ) : (
                          <FlexRadialChart
                            animation
                            colorType='literal'
                            data={byDeliveryFeedbackChart}
                            radius={60}
                            innerRadius={30}
                            onValueMouseOver={v => {
                              setDeliveryFeedbackHint(v);
                            }}
                            onSeriesMouseOut={() => {
                              setDeliveryFeedbackHint(false);
                            }}
                          >
                            {deliveryFeedbackHint && (
                              <Hint value={deliveryFeedbackHint}>
                                <ChartHint>{`${deliveryFeedbackHint.label}: ${deliveryFeedbackHint.quantity}`}</ChartHint>
                              </Hint>
                            )}
                          </FlexRadialChart>
                        )}
                      </FlexContainer>
                      <FlexContainer
                        width='50%'
                        minHeight='150px'
                        justify='center'
                        direction='column'
                      >
                        <FlexContainer
                          container
                          margin='0 0 10px'
                          justify='center'
                        >
                          <Typography
                            color='text.secondary'
                            margin='0 10px 0 0'
                          >
                            Total
                          </Typography>
                          <Typography fontWeight={600} fontSize={16}>
                            {feedbackDeliveryTotal}
                          </Typography>
                        </FlexContainer>
                        <FlexContainer
                          container
                          maxHeight='200px'
                          overflow='auto'
                        >
                          {loadingByFeedback ? (
                            <Spinner height='100px' />
                          ) : (
                            <DataTable
                              data={byDeliveryFeedbackChart || []}
                              dataIdentifier='label'
                              headers={byTypeHeader}
                              onChangePage={() => {}}
                              totalItems={0}
                              totalPages={0}
                              pageSize={0}
                              page={1}
                              showPagination={false}
                              marginBottom='0'
                              showHeader={false}
                              borderless
                            />
                          )}
                        </FlexContainer>
                      </FlexContainer>
                    </FlexContainer>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <Card shadow height='100%' padding='20px' display='flex'>
                    <FlexContainer container margin='0 0 15px'>
                      <Typography>Feedback Productos</Typography>
                    </FlexContainer>
                    <FlexContainer container alignItems='stretch' flex='1'>
                      <FlexContainer
                        width='50%'
                        justify='center'
                        alignItems='center'
                      >
                        {loadingByFeedback ? (
                          <Spinner height='100px' />
                        ) : (
                          <FlexRadialChart
                            animation
                            colorType='literal'
                            data={byProductsFeedbackChart}
                            radius={60}
                            innerRadius={30}
                            onValueMouseOver={v => setOrdersFeedbackHint(v)}
                            onSeriesMouseOut={() => {
                              setOrdersFeedbackHint(false);
                            }}
                          >
                            {ordersFeedbackHint && (
                              <Hint value={ordersFeedbackHint}>
                                <ChartHint>{`${ordersFeedbackHint.label}: ${ordersFeedbackHint.quantity}`}</ChartHint>
                              </Hint>
                            )}
                          </FlexRadialChart>
                        )}
                      </FlexContainer>
                      <FlexContainer
                        width='50%'
                        minHeight='150px'
                        justify='center'
                        direction='column'
                      >
                        <FlexContainer
                          container
                          margin='0 0 10px'
                          justify='center'
                        >
                          <Typography
                            color='text.secondary'
                            margin='0 10px 0 0'
                          >
                            Total
                          </Typography>
                          <Typography fontWeight={600} fontSize={16}>
                            {productDeliveryTotal}
                          </Typography>
                        </FlexContainer>
                        <FlexContainer
                          container
                          maxHeight='200px'
                          overflow='auto'
                        >
                          {loadingByFeedback ? (
                            <Spinner height='100px' />
                          ) : (
                            <DataTable
                              data={byProductsFeedbackChart || []}
                              dataIdentifier='label'
                              headers={byTypeHeader}
                              onChangePage={() => {}}
                              totalItems={0}
                              totalPages={0}
                              pageSize={0}
                              page={1}
                              showPagination={false}
                              marginBottom='0'
                              showHeader={false}
                              borderless
                            />
                          )}
                        </FlexContainer>
                      </FlexContainer>
                    </FlexContainer>
                  </Card>
                </Grid>
              </Grid>
            </FlexContainer>
          </Grid>
        </Grid>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Orders;
