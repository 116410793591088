import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty, find } from 'lodash';
import useRequest from 'hooks/useRequest';
import useForm from 'hooks/useForm';
import { Grid, InputAdornment } from '@material-ui/core';
import {
  Button,
  Divider,
  FlexContainer,
  Icon,
  Typography,
} from 'components/atoms';
import { FormControl, MultipleSelect, Spinner } from 'components/molecules';
import THEME from 'util/styledTheme';
import { optionFormatter } from 'util/functions';
import {
  patchVehicleTypes,
  postVehicleTypes,
  getPackageTypes,
  getVehicleTags,
  getVehicleCategories,
} from 'services';
import { EditProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const validationState = {
  name: ['mandatory'],
  estimated_cost: ['mandatory', 'positiveFloat'],
  max_destinations_traveled: ['mandatory', 'number'],
  max_km_traveled: ['mandatory', 'number'],
  width: ['mandatory', 'positiveFloat'],
  height: ['mandatory', 'positiveFloat'],
  depth: ['mandatory', 'positiveFloat'],
  weight: ['mandatory', 'positiveFloat'],
  vehicle_category: ['mandatory'],
  capacity: ['positive'],
};

const EditVehicleType: React.FC<EditProps> = ({ data, onCreate, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors, reset] = useForm(
    {
      name: data ? data.name : '',
      description: data ? data.description : '',
      estimated_cost: data ? data.estimated_cost : '',
      max_destinations_traveled: data ? data.max_destinations_traveled : '',
      max_km_traveled: data ? data.max_km_traveled : '',
      width: data ? data.width : '',
      height: data ? data.height : '',
      depth: data ? data.depth : '',
      weight: data ? data.weight : '',
      capacity: data ? data.capacity : '',
      vehicle_category:
        data && data.vehicle_category ? data.vehicle_category.id : '',
    },
    validationState
  );
  // API calls
  const [tags, fetchTags, loadingTags] = useRequest(getVehicleTags);
  const [packageTypes, fetchPackage, loadingPackage] = useRequest(
    getPackageTypes
  );
  const [updatedType, updateType, loadingUpdate] = useRequest(
    patchVehicleTypes
  );
  const [createdType, createType, loadingCreate] = useRequest(postVehicleTypes);

  const [
    vehicleCategories,
    fetchVehicleCategories,
    loadingCategories,
  ] = useRequest(getVehicleCategories, []);

  // Values
  const [packageOptions, setPackageOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(
    data ? optionFormatter(data.package_types) : []
  );
  const [selectedTag, setSelectedTag] = useState(
    data ? optionFormatter(data.tags) : []
  );

  useEffect(() => {
    fetchPackage({
      page_size: 0,
    });
    fetchTags({
      page_size: 0,
    });
    fetchVehicleCategories({
      page_size: 0,
    });
  }, [fetchPackage, fetchTags, fetchVehicleCategories]);

  useEffect(() => {
    if (packageTypes.length > 0) {
      setPackageOptions(optionFormatter(packageTypes));
    }
  }, [packageTypes]);

  useEffect(() => {
    if (tags.length > 0) {
      setTagOptions(optionFormatter(tags));
    }
  }, [tags]);

  useEffect(() => {
    if (!isEmpty(createdType)) {
      enqueueSnackbar('El tipo de vehículo fue creado correctamente.', {
        variant: 'success',
      });
      onCreate();
      reset();
    }
  }, [createdType, enqueueSnackbar, onCreate, reset]);

  useEffect(() => {
    if (!isEmpty(updatedType)) {
      enqueueSnackbar('Se actualizó el tipo de vehículo correctamente.', {
        variant: 'success',
      });
      onCreate();
    }
  }, [updatedType, enqueueSnackbar, onCreate]);

  const validateForm = () => {
    if (validate()) {
      const body = {
        name: form.name,
        description: form.description,
        width: form.width,
        height: form.height,
        depth: form.depth,
        weight: form.weight,
        capacity: form.capacity,
        max_km_traveled: form.max_km_traveled,
        max_destinations_traveled: form.max_destinations_traveled,
        estimated_cost: form.estimated_cost,
        tags: selectedTag.map(tag => tag.id),
        package_types: selectedPackage.map(packageType => packageType.id),
        vehicle_category: form.vehicle_category,
        is_active: data ? data.is_active : true,
      };

      if (data) {
        updateType(body, data.id);
      } else {
        createType(body);
      }
    }
  };

  return (
    <FlexContainer
      width='800px'
      position='relative'
      padding='40px 50px 50px'
      direction='column'
    >
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <Typography fontSize={18} margin='10px 0 0'>
        {data ? data.name : 'Creación de Tipo de Vehículo'}
      </Typography>
      <Divider orientation='horizontal' margin='15px 0 30px' />

      <FlexContainer
        container
        direction='column'
        padding='30px 30px'
        margin='0 0 30px'
        borderRadius='10px'
        backgroundColor={THEME.colors.backgroundColor}
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer container alignItems='center' margin='0 0 30px'>
          <FlexContainer container justify='space-between' alignItems='center'>
            <Typography
              color={THEME.colors.primary}
              fontWeight={500}
              fontSize={17}
            >
              Información básica
            </Typography>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer container direction='column'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Nombre'
                placeholder='Escribe el nombre'
                onChange={value => onFormChange(value, 'name')}
                value={form.name}
                margin='0 0 20px'
                error={errors.name}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Costo estimado'
                placeholder='Ingrese costo'
                onChange={value => onFormChange(value, 'estimated_cost')}
                value={form.estimated_cost}
                margin='0 0 20px'
                error={errors.estimated_cost}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                label='Descripción'
                placeholder='Escribe la descripción'
                onChange={value => onFormChange(value, 'description')}
                value={form.description}
                margin='0 0 20px'
                error={errors.description}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Kilómetros máximos'
                placeholder='Ingrese la cantidad'
                onChange={value => onFormChange(value, 'max_km_traveled')}
                value={form.max_km_traveled}
                margin='0 0 20px'
                error={errors.max_km_traveled}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Destinos máximos'
                placeholder='Ingrese la cantidad'
                onChange={value =>
                  onFormChange(value, 'max_destinations_traveled')
                }
                value={form.max_destinations_traveled}
                margin='0 0 20px'
                error={errors.max_destinations_traveled}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Ancho'
                placeholder='Ingrese la medida'
                onChange={value => onFormChange(value, 'width')}
                value={form.width}
                margin='0 0 20px'
                error={errors.width}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
                endAdornment={
                  <InputAdornment position='end'>cm</InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Largo'
                placeholder='Ingrese la medida'
                onChange={value => onFormChange(value, 'height')}
                value={form.height}
                margin='0 0 20px'
                error={errors.height}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
                endAdornment={
                  <InputAdornment position='end'>cm</InputAdornment>
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Alto'
                placeholder='Ingrese la medida'
                onChange={value => onFormChange(value, 'depth')}
                value={form.depth}
                margin='0 0 20px'
                error={errors.depth}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
                endAdornment={
                  <InputAdornment position='end'>cm</InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Peso'
                placeholder='Ingrese la cantidad'
                onChange={value => onFormChange(value, 'weight')}
                value={form.weight}
                margin='0 0 20px'
                error={errors.weight}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
                endAdornment={
                  <InputAdornment position='end'>Kg</InputAdornment>
                }
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Capacidad'
                placeholder='Ingrese la medida'
                onChange={value => onFormChange(value, 'capacity')}
                value={form.capacity}
                margin='0 0 20px'
                error={errors.capacity}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MultipleSelect
                label='Tipos de paquete'
                placeholder='Seleccione los tipos'
                options={packageOptions}
                values={selectedPackage}
                onChange={option => {
                  const found = find(selectedPackage, {
                    id: option.id,
                  });
                  if (found) {
                    setSelectedPackage(
                      selectedPackage.filter(item => item.id !== option.id)
                    );
                  } else {
                    setSelectedPackage(selectedPackage.concat(option));
                  }
                }}
                onDelete={option => {
                  setSelectedPackage(
                    selectedPackage.filter(item => item.id !== option.id)
                  );
                }}
                margin='0 0 20px'
                width='100%'
                disabled={loadingCreate || loadingUpdate || loadingPackage}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MultipleSelect
                label='Etiquetas de vehículo'
                placeholder='Seleccione las etiquetas'
                options={tagOptions}
                values={selectedTag}
                onChange={option => {
                  const found = find(selectedTag, {
                    id: option.id,
                  });
                  if (found) {
                    setSelectedTag(
                      selectedTag.filter(item => item.id !== option.id)
                    );
                  } else {
                    setSelectedTag(selectedTag.concat(option));
                  }
                }}
                onDelete={option => {
                  setSelectedTag(
                    selectedTag.filter(item => item.id !== option.id)
                  );
                }}
                margin='0 0 20px'
                width='100%'
                disabled={loadingCreate || loadingUpdate || loadingTags}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl
                control='select'
                label='Categoría del vehículo'
                placeholder='Selecciona la categoría'
                onChange={value => onFormChange(value, 'vehicle_category')}
                value={form.vehicle_category}
                options={vehicleCategories}
                margin='0 0 20px'
                error={errors.vehicle_category}
                width='100%'
                disabled={loadingCreate || loadingUpdate || loadingCategories}
              />
            </Grid>
          </Grid>
        </FlexContainer>
      </FlexContainer>

      <Button
        variant='contained'
        color='primary'
        padding='5px 30px'
        onClick={() => validateForm()}
      >
        {(loadingCreate || loadingUpdate) && (
          <Spinner
            height='15px'
            spinnerSize={20}
            colorSecondary
            margin='0 8px 0 0'
          />
        )}
        {data ? 'Guardar' : 'Crear'}
      </Button>
    </FlexContainer>
  );
};

export default EditVehicleType;
