import {
  SelectedOrganization,
  BranchOffices,
  ORGANIZATION_CLEAR,
  ORGANIZATION_SELECT,
  BRANCH_OFFICE_SELECT,
} from './types';

export const selectOrganization = (selection: SelectedOrganization) => {
  return {
    type: ORGANIZATION_SELECT,
    payload: selection,
  };
};

export const selectBranchOffice = (selection: BranchOffices) => {
  return {
    type: BRANCH_OFFICE_SELECT,
    payload: selection,
  };
};

export const clearOrganization = () => {
  return {
    type: ORGANIZATION_CLEAR,
  };
};
