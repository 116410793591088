import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useTypedSelector } from 'store';
import { FlexContainer } from 'components/atoms';
import { Header, FleetSidebar } from 'components/organisms';
import evalPermission from 'util/evalPermission';
import Workers from './Workers';
import Vehicles from './Vehicles';
import Providers from './Providers';
import OrderProtocol from './OrderProtocol';
import Packings from './Packings';
import RoutesTypes from './RoutesTypes';
import TimeWindows from './TimeWindows';
import Tracking from './Tracking';
import VehicleType from './VehicleTypes';

const Fleet: React.FC = () => {
  const showMenu = useTypedSelector(state => state.sidebar);
  const user = useTypedSelector(state => state.user);
  return (
    <FlexContainer container height='100%'>
      <FleetSidebar />
      <FlexContainer container margin='0 0 0 200px'>
        <Header settingBar showMenu={showMenu} />
        <FlexContainer container padding='60px 0 0' height='100%'>
          <Switch>
            <Route
              exact
              path='/flota'
              render={() => <Redirect to='/flota/proveedores' />}
            />
            <Route
              exact
              path='/flota/conductores'
              render={() =>
                evalPermission(user.permissions, 'core.worker.list', Workers)
              }
            />
            <Route
              exact
              path='/flota/vehiculos'
              render={() =>
                evalPermission(user.permissions, 'core.vehicle.list', Vehicles)
              }
            />
            <Route
              exact
              path='/flota/tracking'
              render={() =>
                evalPermission(user.permissions, 'core.vehicle.list', Tracking)
              }
            />
            <Route
              exact
              path='/flota/protocolo'
              render={() =>
                evalPermission(
                  user.permissions,
                  'core.vehicle.list',
                  OrderProtocol
                )
              }
            />
            <Route
              exact
              path='/flota/ventanas-horarias'
              render={() =>
                evalPermission(
                  user.permissions,
                  'core.vehicle.list',
                  TimeWindows
                )
              }
            />
            <Route
              exact
              path='/flota/tipos-de-ruta'
              render={() =>
                evalPermission(
                  user.permissions,
                  'core.vehicle.list',
                  RoutesTypes
                )
              }
            />
            <Route
              exact
              path='/flota/tipos-de-paquete'
              render={() =>
                evalPermission(user.permissions, 'core.vehicle.list', Packings)
              }
            />
            <Route
              exact
              path='/flota/tipos-de-vehiculos'
              render={() =>
                evalPermission(
                  user.permissions,
                  'core.vehicle.list',
                  VehicleType
                )
              }
            />
            <Route exact path='/flota/proveedores' component={Providers} />
          </Switch>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Fleet;
