import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { Dialog, Drawer } from '@material-ui/core';
import {
  Button,
  Divider,
  FlexContainer,
  Icon,
  Typography,
} from 'components/atoms';
import { Spinner } from 'components/molecules';
import useRequest from 'hooks/useRequest';
import { useTypedSelector } from 'store';
import { getProviders, patchProvider } from 'services';
import ProviderImage from 'assets/images/ProviderImage.png';
import THEME from 'util/styledTheme';
import Row from './Row';
import EditView from './Edit';

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
  background-color: transparent;
  border: 1px dashed ${props => props.theme.colors.textSecondary};
  padding: 30px;
  color: ${props => props.theme.colors.textSecondary};
  cursor: pointer;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`;

const Logistic: React.FC = () => {
  const branchOffice = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  const user = useTypedSelector(store => store.user);
  const { enqueueSnackbar } = useSnackbar();
  // Values
  const [providers, setProviders] = useState([]);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [drawerData, setDrawerData] = useState(null);
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const [toggleId, setToggleId] = useState<number>(0);
  // API calls
  const [providersResponse, fetchProviders, loadingProviders] = useRequest(
    getProviders,
    []
  );
  const [updatedProvider, updateProvider, loadingUpdate] = useRequest(
    patchProvider
  );

  useEffect(() => {
    setProviders(providersResponse);
    if (firstLoading) {
      setFirstLoading(false);
    }
  }, [providersResponse]);

  useEffect(() => {
    const params = {
      branchoffices__id: branchOffice,
    };

    fetchProviders(params);
  }, [fetchProviders]);

  const updateList = (updated, isNew?: boolean) => {
    if (isNew) {
      setProviders([updated].concat(providers));
    } else {
      setProviders(
        providers.map(provider => {
          if (provider.id === updated.id) {
            return updated;
          }
          return provider;
        })
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(updatedProvider)) {
      const message = updatedProvider.is_active
        ? 'El proveedor fue activado correctamente.'
        : 'El proveedor fue desactivado correctamente.';
      enqueueSnackbar(message, {
        variant: 'success',
      });
      updateList(updatedProvider);
      setToggleId(0);
      setOpenConfirm(false);
    }
  }, [updatedProvider, enqueueSnackbar]);

  const handleToggle = (id, isActive) => {
    updateProvider(
      {
        is_active: isActive,
      },
      id
    );
  };

  return (
    <FlexContainer container direction='column' margin='50px 0 0'>
      {!firstLoading && !loadingProviders ? (
        <>
          {providers.length > 0 && (
            <Typography fontSize={13} color='text.secondary'>
              Agrega proveedores con los que trabajas.
            </Typography>
          )}
          {providers.map((provider, index) => (
            <React.Fragment key={provider.id}>
              <Row
                data={provider}
                onClick={() => {
                  setOpenDrawer(true);
                  setDrawerData(provider);
                }}
                onToggle={() => {
                  if (provider.is_active) {
                    setOpenConfirm(true);
                    setToggleId(provider.id);
                  } else {
                    handleToggle(provider.id, true);
                  }
                }}
              />
              {providers[index + 1] && <Divider orientation='horizontal' />}
            </React.Fragment>
          ))}
          {providers.length === 0 && (
            <FlexContainer
              container
              margin='10px 0 60px'
              direction='column'
              alignItems='center'
            >
              <img
                src={ProviderImage}
                alt='provider_img'
                style={{
                  width: '100px',
                  height: 'auto',
                  marginBottom: '20px',
                }}
              />
              <FlexContainer container maxWidth='500px'>
                <Typography
                  fontSize={13}
                  color='text.secondary'
                  textAlign='center'
                >
                  Agrega proveedores con los que trabajas, los transportistas
                  podrán descargar la app e integrarse a tu flujo de trabajo
                </Typography>
              </FlexContainer>
            </FlexContainer>
          )}
        </>
      ) : (
        <Spinner height='300px' />
      )}
      {user.permissions.includes('core.provider.create') && (
        <AddButton
          onClick={() => {
            setDrawerData(null);
            setOpenDrawer(true);
          }}
        >
          <Icon icon='plus-icon' margin='0 10px' size={20} />
          Agregar proveedor
        </AddButton>
      )}

      <Drawer anchor='right' open={openDrawer}>
        <EditView
          data={drawerData}
          onClose={() => {
            setDrawerData(null);
            setOpenDrawer(false);
          }}
          onEdit={provider => updateList(provider)}
          onCreate={provider => updateList(provider, true)}
        />
      </Drawer>

      <Dialog
        disableBackdropClick={loadingUpdate}
        disableEscapeKeyDown={loadingUpdate}
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
          setToggleId(0);
        }}
      >
        <FlexContainer
          padding='30px'
          direction='column'
          alignItems='center'
          justify='center'
        >
          <Typography
            color={THEME.colors.primary}
            fontSize={15}
            margin='0 0 20px'
          >
            Desactivar proveedor
          </Typography>
          <Typography margin='0 0 30px' fontSize={13}>
            ¿Estás seguro de desactivar el proveedor?
          </Typography>
          <FlexContainer container alignItems='center' justify='space-between'>
            <Button
              variant='contained'
              color='primary'
              fontSize='13px'
              onClick={() => handleToggle(toggleId, false)}
              disabled={loadingUpdate}
            >
              Desactivar
            </Button>

            <Button
              fontSize='13px'
              onClick={() => {
                setOpenConfirm(false);
                setToggleId(0);
              }}
              disabled={loadingUpdate}
            >
              Cancelar
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Dialog>
    </FlexContainer>
  );
};

export default Logistic;
