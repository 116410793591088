import React from 'react';
import { Icon } from 'components/atoms';
import styled from 'styled-components';
import { MassiveButtonProps, ContainerProps } from './types';

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.backgroundColor};
  width: ${props => props.size};
  height: ${props => props.size};
  margin: ${props => props.margin || '0'};
  &:hover {
    background-color: ${props => props.theme.colors.primary};
    color: #fff;
  }
`;

const IconContainer = styled.div`
  background-color: #fff;
  border-radius: 100px;
  overflow: hidden;
  padding: 20px;
  margin-bottom: 20px;
`;

const MassiveButton: React.FC<MassiveButtonProps> = ({
  size,
  iconSize,
  icon,
  onClick,
  label,
  margin,
}) => (
  <Container
    size={size}
    margin={margin}
    onClick={() => {
      if (onClick) {
        onClick();
      }
    }}
  >
    {icon && (
      <IconContainer>
        <Icon icon={icon} size={iconSize} />
      </IconContainer>
    )}
    {label}
  </Container>
);

export default MassiveButton;
