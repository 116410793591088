import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useHistory } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { FlexContainer, Typography } from 'components/atoms';
import useRequest from 'hooks/useRequest';
import { importShopifyOrders } from 'services';
import THEME from 'util/styledTheme';
import NoData from 'assets/images/no_data.svg';

const ImportOrderPlatforms: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  // API calls
  const [, importOrders, , , , statusRequest] = useRequest(importShopifyOrders);
  // Values
  const [orderIds, setOrderIds] = useState(null);
  const [shopName, setShopName] = useState('');
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    // Getting shop name base on query string
    const searchParams = new URLSearchParams(location.search);
    const shop = searchParams.get('shop');
    // Decoding url to get order ids
    const decodedUrl = decodeURIComponent(location.search);
    const splitted = decodedUrl.split('&ids[]=');
    const orders = splitted.slice(1);
    if (shop && orders) {
      setShopName(shop);
      setOrderIds(orders);
    } else {
      setShowError(true);
    }
  }, [location]);

  useEffect(() => {
    if (orderIds && shopName !== '') {
      const data = {
        store: shopName,
        ids: orderIds,
      };
      importOrders(data);
    }
  }, [orderIds, shopName]);

  useEffect(() => {
    if (statusRequest === 204) {
      enqueueSnackbar('Las órdenes han sido importadas con éxito.', {
        variant: 'success',
      });
      history.push('/ordenes');
    } else if (statusRequest && statusRequest !== 204) {
      setShowError(true);
    }
  }, [statusRequest, enqueueSnackbar, history]);

  return (
    <FlexContainer
      container
      height='100%'
      direction='column'
      padding='30px'
      justify='center'
      alignItems='center'
    >
      {showError ? (
        <FlexContainer
          direction='column'
          width='400px'
          height='400px'
          alignItems='center'
          justify='center'
        >
          <Typography
            fontSize={14}
            margin='20px 0 0 0'
            color={THEME.colors.error}
          >
            No se han podido importar ordenes de Shopify
          </Typography>
          <img src={NoData} alt='no_data' />
        </FlexContainer>
      ) : (
        <FlexContainer
          direction='column'
          width='400px'
          height='400px'
          alignItems='center'
          justify='center'
        >
          <LinearProgress />
          {orderIds && (
            <Typography fontSize={14} margin='20px 0 0 0'>
              {`Estamos importando ${orderIds.length} ordenes a la plataforma.`}
            </Typography>
          )}
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

export default ImportOrderPlatforms;
