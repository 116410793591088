import React from 'react';
import styled from 'styled-components';
import THEME from 'util/styledTheme';
import { FlexContainer, Typography } from '../index';
import { ComponentProps, InputProps } from './types';

const InputError = styled(FlexContainer)`
  top: -12px;
  right: -15px;
  z-index: 1;
`;

const PaymentInput = styled.input<InputProps>`
  font-size: ${props => props.fontSize || '13px'};
  height: ${props => props.height || '35px'};
  color: ${props => props.theme.colors.textSecondary};
  background-color: #ffffff;
  border: 1px solid ${props => props.theme.colors.borderColor};
  width: 100%;
  padding: 0 18px 0 35px;
  border-radius: 4px;
  background-image: url('${props => props.icon}');
  background-repeat: no-repeat;
  background-position: 5px center;
  margin: ${props => props.margin || 0};

  &:hover {
    border-color: ${props => props.theme.colors.borderColor};
  }

  &:focus {
    outline: none;
    border: 1px solid ${props => props.theme.colors.primary};
  }

  &::placeholder {
    color: ${props => props.theme.colors.placeholderColor};
    opacity: 1;
  }

  &:disabled {
    background-color: ${props => props.theme.colors.borderColor};
    border-color: ${props => props.theme.colors.borderColor};
  }
`;

const PaymentInputComponent: React.FC<ComponentProps> = ({
  error,
  ...props
}) => (
  <div
    style={{
      position: 'relative',
    }}
  >
    <PaymentInput {...props} />
    {error && (
      <InputError
        borderColor={THEME.colors.error}
        backgroundColor={THEME.colors.errorBg}
        padding='2px 5px'
        position='absolute'
        borderRadius='4px'
      >
        <Typography fontSize={11} color={THEME.colors.error}>
          {error}
        </Typography>
      </InputError>
    )}
  </div>
);

export default PaymentInputComponent;
