import styled from 'styled-components';
import { Switch } from '@material-ui/core';

const CustomSwitch = styled(Switch)`
  .Mui-checked {
    color: ${props => props.theme.colors.success};
  }
  .MuiSwitch-track {
    background-color: ${props => props.theme.colors.placeholderColor};
  }
  .Mui-checked + .MuiSwitch-track {
    opacity: 0.5;
    background-color: ${props => props.theme.colors.success};
  }
`;

export default CustomSwitch;
