import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import useRequest from 'hooks/useRequest';
import useForm from 'hooks/useForm';
import { Grid } from '@material-ui/core';
import {
  Button,
  Divider,
  FlexContainer,
  Icon,
  Switch,
  Typography,
  Input,
} from 'components/atoms';
import THEME from 'util/styledTheme';
import { patchTracking } from 'services';
import { FormControl } from 'components/molecules';
import { EditProps, SwitchRowProps, FormsContentProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const validationState = {
  name: ['mandatory'],
  workerLabel: ['mandatory'],
};

const configurationValidationState = {
  notification_message: ['mandatory'],
};

const StyledInput = styled(Input)`
  margin-bottom: 4px;

  input {
    font-size: 14px;
    font-weight: 700;
    padding: 0 0 0 10px;
    background-color: ${THEME.colors.backgroundColor};
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    display: none;
  }
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:after {
    transition: none;
    border-bottom: none;
  }
`;

const FieldSwitch: React.FC<SwitchRowProps> = ({
  data,
  onChange,
  loading,
  // errors,
}) => {
  return (
    <FlexContainer container margin='10px 0 10px' alignItems='center'>
      <Grid container spacing={3} alignItems='center'>
        <Grid item xs={6}>
          <StyledInput
            placeholder='Escribe el label del estado de la orden'
            onChange={e => onChange(e.target.value, 'labelField')}
            value={data.labelField}
            width='100%'
            disabled={loading}
          />
        </Grid>
        <Grid item xs={3}>
          <FlexContainer container justify='center'>
            <Switch
              checked={data.showTracking}
              onChange={event => onChange(event.target.checked, 'showTracking')}
            />
          </FlexContainer>
        </Grid>
      </Grid>
    </FlexContainer>
  );
};

const FormsContent: React.FC<FormsContentProps> = ({
  forms,
  onFormChange,
  errors,
  loading,
}) => (
  <FlexContainer container direction='column' margin='20px 0 0'>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography fontWeight={700} fontSize='15px' margin='0 0 20px 0'>
          Estados de la orden
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography color='text.secondary' textAlign='center'>
          Mostrar en Tracking
        </Typography>
      </Grid>
    </Grid>
    {forms.map(formRow => (
      <FieldSwitch
        key={formRow.id}
        data={formRow}
        onChange={(value, field) => onFormChange(value, field, formRow.formId)}
        loading={loading}
        errors={errors[formRow.id] || {}}
      />
    ))}
  </FlexContainer>
);

const getStatusForms = data => {
  if (data && data.tracking_statuses) {
    return data.tracking_statuses.map(statusFormField => ({
      formId: statusFormField.id,
      id: statusFormField.id,
      labelField: statusFormField.label,
      sendNotification: statusFormField.send_notification,
      notificationMessage: statusFormField.notification_message,
      showTracking: statusFormField.show_in_tracking,
    }));
  }
  return [];
};

const EditTracking: React.FC<EditProps> = ({ onClose, onCreate, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors] = useForm(
    {
      name: data ? data.name : '',
      description: data ? data.description : '',
      workerLabel: data ? data.worker_label : '',
      sendSms: data ? data.send_sms : '',
      sendEmail: data ? data.send_email : '',
      sendWspp: data ? data.send_whatsapp : '',
      isActive: data ? data.is_active : '',
      trackingPhoneNumber: data ? data.tracking_phone_number : '',
    },
    validationState
  );

  const [
    statusForms,
    onStatusFormChange,
    validateStatusForms,
    statusFormsErrors,
  ] = useForm(
    useCallback(() => getStatusForms(data), [getStatusForms]),
    configurationValidationState
  );

  // API calls
  const [updatedTracking, updateTracking, loadingUpdate] = useRequest(
    patchTracking
  );

  // Values
  const [notificationErrors, setNotificationErrors] = useState<boolean>(false);
  const [channelsError, setChannelsError] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(updatedTracking)) {
      enqueueSnackbar(
        'Se actualizó la configuración de tracking correctamente.',
        {
          variant: 'success',
        }
      );
      onCreate();
    }
  }, [updatedTracking, enqueueSnackbar, onCreate]);

  useEffect(() => {
    const notificationForms = statusForms.some(
      status => status.sendNotification === true
    );
    if (notificationForms) {
      setNotificationErrors(true);
    } else {
      setNotificationErrors(false);
    }
  }, [statusForms]);

  useEffect(() => {
    const { sendSms, sendEmail, sendWspp } = form;
    const channelValues = [sendSms, sendEmail, sendWspp];
    const disabledChannels = channelValues.every(value => value === false);
    if (disabledChannels) {
      setChannelsError(true);
    } else {
      setChannelsError(false);
    }
  }, [form]);

  const validateForm = () => {
    if (validate() && validateStatusForms()) {
      const trackingStatuses = statusForms.map(statusForm => ({
        id: statusForm.id,
        label: statusForm.labelField,
        send_notification: statusForm.sendNotification,
        notification_message: statusForm.notificationMessage,
        show_in_tracking: statusForm.showTracking,
      }));
      const body = {
        name: form.name,
        description: form.description,
        is_active: form.isActive,
        worker_label: form.workerLabel,
        send_email: form.sendEmail,
        send_whatsapp: form.sendWspp,
        send_sms: form.sendSms,
        tracking_phone_number: form.trackingPhoneNumber,
        tracking_statuses: [...trackingStatuses],
      };

      updateTracking(body, data.id);
    }
  };

  return (
    <FlexContainer
      container
      minWidth='750px'
      position='relative'
      padding='40px 50px 50px'
      direction='column'
    >
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <Typography fontSize={18} padding='10px 0 0 0'>
        Tracking Público
      </Typography>
      <Divider orientation='horizontal' margin='15px 0 30px' />

      <FlexContainer
        container
        direction='column'
        padding='30px'
        borderRadius='10px'
        borderColor={THEME.colors.borderColor}
        backgroundColor={THEME.colors.backgroundColor}
      >
        <FlexContainer container direction='column'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FlexContainer alignItems='center' margin='0 0 20px 0'>
                <Typography fontSize={12}>Activo:</Typography>
                <Switch
                  checked={form.isActive}
                  onChange={event =>
                    onFormChange(event.target.checked, 'isActive')
                  }
                />
              </FlexContainer>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormControl
                label='Nombre'
                placeholder='Escribe el nombre'
                onChange={value => onFormChange(value, 'name')}
                value={form.name}
                margin='0 0 10px'
                error={errors.name}
                width='100%'
                disabled={loadingUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                control='textarea'
                label='Descripción'
                onChange={value => onFormChange(value, 'description')}
                value={form.description}
                margin='0 0 10px'
                width='100%'
                disabled={loadingUpdate}
                rows={3}
              />
            </Grid>
          </Grid>
        </FlexContainer>

        <FormsContent
          forms={statusForms}
          errors={statusFormsErrors}
          onFormChange={onStatusFormChange}
          loading={loadingUpdate}
        />

        <Divider orientation='horizontal' margin='15px 0 30px' />

        <FlexContainer container direction='column'>
          <FormControl
            label='Alias Worker'
            placeholder='Repartidor'
            onChange={value => onFormChange(value, 'workerLabel')}
            value={form.workerLabel}
            error={errors.workerLabel}
            width='60%'
            margin='0 0 20px 10px'
            disabled={loadingUpdate}
            labelWeight='700'
            labelSize='14px'
            inline
          />
          <FormControl
            label='Número de Call Center'
            placeholder='Teléfono del call center'
            onChange={value => onFormChange(value, 'trackingPhoneNumber')}
            value={form.trackingPhoneNumber}
            width='60%'
            margin='0 0 0 10px'
            disabled={loadingUpdate}
            labelWeight='700'
            labelSize='14px'
            inline
          />
        </FlexContainer>

        <Button
          variant='contained'
          color='primary'
          margin='30px 0 0 0'
          padding='5px 30px'
          onClick={() => validateForm()}
          disabled={notificationErrors && channelsError}
        >
          Guardar
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default EditTracking;
