import React from 'react';
import moment from 'moment';
import THEME from 'util/styledTheme';
import { FlexContainer, Typography, Icon } from 'components/atoms';
import { ResponsiveContainer } from './ProductInformation';
import { TaskEventsProps } from './types';

const TaskEvents: React.FC<TaskEventsProps> = ({ orderEvents }) => {
  return (
    <ResponsiveContainer
      container
      direction='column'
      resWidth='90%'
      resAlignItems='center'
      resJustify='center'
    >
      <FlexContainer
        direction='column'
        padding='20px'
        margin='60px 0 20px 50px'
      >
        {orderEvents.length > 0 ? (
          orderEvents.map(event => (
            <React.Fragment key={event.label}>
              <FlexContainer alignItems='center' justify='center'>
                <FlexContainer
                  width='50px'
                  height='50px'
                  alignItems='center'
                  justify='center'
                  borderRadius='100px'
                  borderColor='#51cd72'
                  backgroundColor={event.containerColor}
                >
                  <img
                    src={event.svgImage}
                    alt={event.currentTask ? 'waiting' : 'finished'}
                    style={{ width: '20px', height: '25px' }}
                  />
                </FlexContainer>
                <FlexContainer margin='0 0 0 10px' direction='column'>
                  <Typography margin='0' fontSize='20px'>
                    {event.label}
                  </Typography>
                  <FlexContainer margin='5px 0 0 0'>
                    <Typography fontSize='15px' color='text.secondary'>
                      {moment(event.timestamp).format('L')}
                    </Typography>
                    <div
                      style={{
                        width: '1px',
                        height: '15px',
                        margin: '0 10px',
                        backgroundColor: THEME.colors.textSecondary,
                      }}
                    />
                    <Typography fontSize='15px' color='text.secondary'>
                      {moment(event.timestamp).format('LT A')}
                    </Typography>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              {event.id !== orderEvents.length && (
                <FlexContainer
                  height='85px'
                  style={{
                    border: event.currentTask
                      ? '1px dashed #51cd72'
                      : '1px solid #51cd72',
                    marginLeft: '24px',
                  }}
                />
              )}
            </React.Fragment>
          ))
        ) : (
          <ResponsiveContainer
            container
            height='100%'
            direction='column'
            resWidth='90%'
            resAlignItems='center'
            resJustify='center'
          >
            <FlexContainer container direction='column' alignItems='center'>
              <Typography margin='60px 0 0 30px'>
                Esperando eventos de la orden
              </Typography>
              <Icon icon='route-icon' margin='15px 0 0 0' />
            </FlexContainer>
          </ResponsiveContainer>
        )}
      </FlexContainer>
    </ResponsiveContainer>
  );
};

export default TaskEvents;
