import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import { FlexContainer, Typography, Button } from 'components/atoms';
import { FormControl } from 'components/molecules';
import { requestNewPassword } from 'services';
import AimoLogoFull from 'assets/images/AimoLogoFull.svg';
import AimoLogo from 'assets/images/login/login-image.png';
import BackIcon from 'assets/images/back-icon.svg';

const validationState = {
  email: ['mandatory', 'email'],
};

const FullGrid = styled(Grid)`
  height: 100vh;
  background-color: #ffffff;
`;

const FullGridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1.2fr 1fr;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 768px) and (max-width: 820px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 821px) and (max-width: 1190px) {
    grid-template-columns: 1.3fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 1191px) {
    grid-template-columns: 1.1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const Container = styled(FlexContainer)`
  height: 100%;
  padding: 25px;
  @media only screen and (min-width: 768px) {
    padding: 30px;
  }
`;

const FormContainer = styled(FlexContainer)`
  width: 100%;
  padding: 40px 0;

  @media only screen and (min-width: 560px) and (max-width: 767px) {
    width: 500px;
  }

  @media only screen and (min-width: 768px) and (max-width: 959px) {
    width: 350px;
    padding: 0;
  }

  @media only screen and (min-width: 960px) {
    width: 480px;
    padding: 0;
  }
`;

const AimoBackground = styled(FlexContainer)`
  background-color: #f1564f;
  border-radius: 0;
  padding: 50px 0;

  @media only screen and (min-width: 768px) {
    padding: 0;
    border-radius: 48px 0 0 48px;
  }
`;

const LogoContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  img {
    padding-top: 50px;
    width: 85%;
    height: auto;
  }

  @media only screen and (min-width: 768px) {
    img {
      padding-top: 50px;
      max-width: 550px;
      height: auto;
    }
  }
`;

const HeaderContainer = styled(Grid)`
  align-self: flex-start;
`;

const RightSide: React.FC = () => (
  <AimoBackground container justify='center' height='100%'>
    <LogoContainer>
      <img src={AimoLogo} alt='aimo_logo' />
    </LogoContainer>
  </AimoBackground>
);

const RestorePassword: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors, reset] = useForm(
    {
      email: '',
    },
    validationState
  );

  // API calls
  const [, sendEmail, loading, , errorRequest, statusRequest] = useRequest(
    requestNewPassword
  );

  useEffect(() => {
    if (statusRequest === 200) {
      enqueueSnackbar('Se le ha enviado un correo correctamente.', {
        variant: 'success',
      });
      reset();
    }
  }, [reset, statusRequest, enqueueSnackbar]);

  useEffect(() => {
    if (errorRequest && errorRequest.status === 400) {
      enqueueSnackbar('El correo no esta registrado en la base de datos.', {
        variant: 'warning',
      });
    }
  }, [errorRequest, enqueueSnackbar]);

  const validateForm = () => {
    if (validate()) {
      const data = {
        email: form.email,
      };

      sendEmail(data);
    }
  };

  return (
    <FullGrid container>
      <FullGridContainer>
        <FlexContainer container height='100%'>
          <Container container direction='column' alignItems='center'>
            <HeaderContainer item>
              <a href='https://aimo.co'>
                <img src={AimoLogoFull} alt='logo_full' />
              </a>
            </HeaderContainer>
            <FlexContainer
              container
              width='100%'
              height='100%'
              justify='center'
              alignItems='center'
            >
              <FormContainer container direction='column'>
                <Link to='/login'>
                  <FlexContainer margin='0 0 30px'>
                    <img src={BackIcon} alt='back' />
                  </FlexContainer>
                </Link>
                <Typography
                  fontWeight='700'
                  fontSize='27px'
                  margin='20px 0 0'
                  color='#000000'
                >
                  Recuperar Contraseña
                </Typography>
                <Typography fontSize='16px' margin='15px 0' color='#6F6F6F'>
                  Escriba su correo electrónico para recibir el link
                </Typography>
                <FormControl
                  width='100%'
                  label='Correo electrónico*'
                  placeholder='Escribe tu correo'
                  onChange={value => onFormChange(value, 'email')}
                  value={form.email}
                  margin='70px 0 80px'
                  error={errors.email}
                  disabled={loading}
                  variant='standard'
                />
                <FlexContainer container justify='center'>
                  <Button
                    variant='contained'
                    color='primary'
                    padding='25px 50px'
                    borderRadius='20px'
                    onClick={() => validateForm()}
                    disabled={loading}
                  >
                    <Typography
                      fontSize='15px'
                      fontWeight='700'
                      color='white'
                      letterSpacing='0.5px'
                    >
                      Enviar
                    </Typography>
                  </Button>
                </FlexContainer>
              </FormContainer>
            </FlexContainer>
          </Container>
        </FlexContainer>
        <RightSide />
      </FullGridContainer>
    </FullGrid>
  );
};

export default RestorePassword;
