import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useTypedSelector } from 'store';
import { FlexContainer, Typography } from 'components/atoms';
import { Search } from 'components/molecules';
import doneIcon from 'assets/images/portal/done.svg';
import aimoLogo from 'assets/images/AimoLogoIcon.svg';
import openPageImage from 'assets/images/portal/open-page-icon.png';
import DefaultLogoOrganization from 'assets/images/DefaultLogo.png';
import ProductInformation, { ResponsiveContainer } from './ProductInformation';
import TaskEvents from './TaskEvents';
import { fakeOrderInfoData } from './data';

const CustomButtom = styled.button<{
  width?: string;
  margin?: string;
  resWidth?: string;
  resMargin?: string;
  bgColor?: string;
}>`
  height: 50px;
  width: ${props => props.width || '120px'};
  margin: ${props => props.margin || '0px'};
  background-color: #51cd72;
  color: #ffffff;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #45d66b;
  }

  &:focus {
    outline: 0;
  }

  @media screen and (max-width: 768px) and (min-width: 360px) {
    ${props => props.resWidth && `width: ${props.resWidth};`}
    ${props => props.resMargin && `margin: ${props.resMargin};`}
  }
`;

const CustomTypography = styled(Typography)<{
  ressize?: string;
  resweight?: string;
  resmargin?: string;
}>`
  @media screen and (max-width: 768px) and (min-width: 360px) {
    ${props => props.ressize && `font-size: ${props.ressize} !important;`}
    ${props => props.resweight && `font-weight: ${props.resweight} !important;`}
    ${props => props.resmargin && `margin: ${props.resmargin} !important;`}
  }
`;

const CustomDivider = styled(ResponsiveContainer)`
  display: none;
`;

const PortalTracking: React.FC = () => {
  const organizationSlug = useTypedSelector(
    state => state.organization.selectedOrganization.slug
  );
  const organizationInfo = useTypedSelector(
    state => state.organization.selectedOrganization
  );
  // Values
  const [inputValue, setInputValue] = useState('A1B2C3D4');
  const [orderInfo, setOrderInfo] = useState(fakeOrderInfoData);
  const [orderEvents, setOrderEvents] = useState([]);
  const [productInfo, setProductInfo] = useState(null);
  const [existItems, setExitItems] = useState<boolean>(true);

  useEffect(() => {
    setOrderInfo(fakeOrderInfoData);
  }, []);

  useEffect(() => {
    if (orderInfo) {
      const formattedEvents = orderInfo.events.map((event, index) => {
        const eventId = index + 1;
        const eventsQuantity = orderInfo.events.length - 1;
        const lastOrderEventLabel = orderInfo.events[eventsQuantity].label;
        const isFinishedOrder = orderInfo.current_status.stage === 'final';
        let isCurrentTask;
        let svgToShow;
        let colorToShow;

        if (isFinishedOrder) {
          svgToShow = doneIcon;
          colorToShow = '#51cd72';
          isCurrentTask = false;
        } else {
          isCurrentTask = event.label === lastOrderEventLabel;
        }

        return {
          id: eventId,
          label: event.label,
          detail: event.detail,
          timestamp: event.timestamp,
          currentTask: isCurrentTask,
          isOrderCompleted: isFinishedOrder,
          svgImage: svgToShow,
          containerColor: colorToShow,
        };
      });
      setOrderEvents(formattedEvents);
    }
  }, [orderInfo]);

  useEffect(() => {
    if (orderInfo) {
      const { tasks } = orderInfo;
      const formattedInfo = tasks.map(info => {
        const orderItems = info.items[0];
        if (!orderItems) {
          setExitItems(false);
        }
        return {
          itemsInformation: {
            itemDescription: orderItems && orderItems.description,
            itemQuantity: orderItems && orderItems.quantity,
            itemCode: orderItems && orderItems.code,
          },
          contactPerson: info.contact_person,
          destination: info.address,
          destinationDetail: info.address_detail,
        };
      });
      setProductInfo(formattedInfo[0]);
    }
  }, [orderInfo]);

  return (
    <FlexContainer container padding='30px 20px' justify='space-between'>
      <FlexContainer
        width='30%'
        height='100vh'
        backgroundColor='#ffffff'
        justify='center'
      >
        <FlexContainer width='80%' direction='column' alignItems='center'>
          <FlexContainer
            container
            justify='space-around'
            alignItems='center'
            margin='20px 0'
          >
            <Typography fontWeight='700' fontSize='16px' color='black'>
              Abrir Portal
            </Typography>
            <FlexContainer
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_TRACKING_URL}/organizations/${organizationSlug}`,
                  '_blank'
                );
              }}
            >
              <img
                src={openPageImage}
                alt='icon-view'
                style={{ width: '25px', cursor: 'pointer' }}
              />
            </FlexContainer>
          </FlexContainer>
          <Typography textAlign='center' margin='20px 0' fontSize='16px'>
            Personaliza tu portal de seguimiento
          </Typography>
          <Typography textAlign='center' color='text.secondary'>
            En este portal tus clientes pueden buscar una orden por el codigo de
            referencia que prefieras: factura, guia, orden, etc.
          </Typography>
          <Typography textAlign='center' margin='20px 0' fontSize='16px'>
            Agrega tu portal de tracking a tu landing page
          </Typography>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        container
        height='100%'
        margin='0 0 0 20px'
        alignItems='center'
        justify='center'
        direction='column'
      >
        <FlexContainer
          container
          backgroundColor='#ffffff'
          justify='center'
          alignItems='center'
          height='70px'
        >
          <img
            src={organizationInfo.photo || DefaultLogoOrganization}
            alt='ecommerce logo'
            style={{ width: 'auto', height: '65px' }}
          />
          <Typography margin='5px 0 0 10px'>{organizationInfo.name}</Typography>
        </FlexContainer>

        <FlexContainer
          container
          direction='column'
          alignItems='center'
          backgroundColor='#f5f7fa'
        >
          <ResponsiveContainer
            width='80%'
            direction='column'
            margin='20px 0'
            padding='20px'
            borderColor='#efeff7'
            backgroundColor='#ffffff'
            resWidth='95%'
            borderRadius='10px'
          >
            <ResponsiveContainer
              container
              direction='column'
              margin='0 0 0 25px'
              resAlignItems='center'
              resMargin='0'
            >
              <Typography
                fontWeight='700'
                fontSize='18px'
                margin='10px 0 10px 5px'
              >
                Sigue tu orden
              </Typography>
              <Typography
                margin='5px 0 15px 5px'
                color='#8c8cb1'
                textAlign='center'
              >
                Escribe tu número de orden de compra online
              </Typography>
            </ResponsiveContainer>
            <ResponsiveContainer
              container
              padding='20px 0'
              resDirection='column'
              resAlignItems='center'
            >
              <ResponsiveContainer container margin='0 0 0 30px' resMargin='0'>
                <Search
                  searchValue={inputValue}
                  placeholder='Ingresa el código de tu orden'
                  width='100%'
                  height='50px'
                  onChangeValue={value => setInputValue(value)}
                  onSubmit={() => {}}
                />
              </ResponsiveContainer>
              <CustomButtom
                width='180px'
                margin='0 35px 0 0'
                resWidth='120px'
                resMargin='20px 0 0 0'
                onClick={() => {}}
                style={{
                  borderBottomRightRadius: '6px',
                  borderTopRightRadius: '6px',
                }}
              >
                Consulta ahora
              </CustomButtom>
            </ResponsiveContainer>
          </ResponsiveContainer>
        </FlexContainer>

        <ResponsiveContainer
          width='80%'
          direction='column'
          borderColor='#efeff7'
          style={{ boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.04)' }}
          resWidth='95%'
          borderRadius='10px'
        >
          <FlexContainer
            container
            direction='column'
            alignItems='center'
            padding='30px 0'
            backgroundColor='#d9d9e2'
            style={{
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            <CustomTypography
              margin='5px 0'
              fontWeight='700'
              fontSize='26px'
              textAlign='center'
              ressize='20px'
            >
              {moment(orderInfo.delivery_programmed_date).format('LL')}
            </CustomTypography>
            <ResponsiveContainer resDirection='column'>
              <Typography fontSize='20px'>Código de Referencia:</Typography>
              <CustomTypography
                margin='0 0 0 5px'
                fontSize='20px'
                fontWeight='700'
                ressize='16px'
                resmargin='10px'
              >
                {orderInfo.reference_code}
              </CustomTypography>
            </ResponsiveContainer>
          </FlexContainer>
          <FlexContainer
            container
            direction='column'
            backgroundColor='#ffffff'
            style={{
              boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.04)',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
            }}
          >
            <ResponsiveContainer container resDirection='column'>
              <ResponsiveContainer width='50%' resWidth='100%'>
                <TaskEvents orderEvents={orderEvents} />
              </ResponsiveContainer>

              <CustomDivider
                container
                resDisplay='block'
                borderColor='#efeff7'
                margin='20px 0 0 0'
              />

              {orderInfo && productInfo && (
                <FlexContainer width='50%'>
                  <ProductInformation
                    transportationLogo={
                      orderInfo.provider && orderInfo.provider.logo
                    }
                    externalCode={orderInfo.external_provider_reference_code}
                    contactPerson={productInfo.contactPerson}
                    destination={productInfo.destination}
                    destinationDetail={productInfo.destinationDetail}
                    itemsInformation={productInfo.itemsInformation}
                    trackingPhoneNumber={orderInfo.tracking_phone_number}
                    existItems={existItems}
                  />
                </FlexContainer>
              )}
            </ResponsiveContainer>
            <FlexContainer container margin=' 30px 0' justify='center'>
              <CustomButtom
                width='250px'
                onClick={() => {}}
                style={{ borderRadius: '4px' }}
              >
                Ver Tracking
              </CustomButtom>
            </FlexContainer>
          </FlexContainer>
        </ResponsiveContainer>
        <FlexContainer
          container
          padding='10px'
          margin='30px 0 0 0'
          justify='center'
          alignItems='center'
        >
          <img src={aimoLogo} alt='aimo logo' style={{ width: '20px' }} />
          <Typography margin='0 0 0 5px' fontSize='14px' color='#8c8cb1'>
            Powered by aimo
          </Typography>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default PortalTracking;
