import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { isEmpty, uniq } from 'lodash';
import { Dialog, Drawer } from '@material-ui/core';
import {
  Button,
  Divider,
  FlexContainer,
  Pill,
  Typography,
  Card,
} from 'components/atoms';
import { Spinner } from 'components/molecules';
import useRequest from 'hooks/useRequest';
import { getIntegrations, patchIntegration } from 'services';
import THEME from 'util/styledTheme';
import Row from './Row';
import Detail from './Detail';

const AimoAccounts: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  // Values
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [deactivateObj, setDeactivateObj] = useState(null);
  const [drawerData, setDrawerData] = useState({
    object: null,
  });
  const [integrations, setIntegrations] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [activeTags, setActiveTags] = useState([]);
  // API calls
  const [integrationsResponse, fetchIntegrations, loading] = useRequest(
    getIntegrations,
    []
  );
  const [updatedIntegration, updateIntegration, loadingUpdate] = useRequest(
    patchIntegration
  );

  useEffect(() => {
    fetchIntegrations();
  }, [fetchIntegrations]);

  useEffect(() => {
    const filteredWorkThroughAimo = Object.values(integrationsResponse).filter(
      (integration: any) =>
        integration.can_work_through_aimo &&
        (integration.code === 'olva' ||
          integration.code === '99minutos' ||
          integration.code === 'cabify' ||
          integration.code === 'pedidosya')
    );

    setIntegrations(filteredWorkThroughAimo);
    const tags = [];
    integrationsResponse.forEach(integration => {
      tags.push(...integration.tags);
    });
    setTagList(uniq(tags));
    setActiveTags(uniq(tags));
  }, [integrationsResponse]);

  const updateIntegrationList = (object, isActive) => {
    setIntegrations(
      integrations.map(integration => {
        if (integration.code === object.code) {
          return {
            ...integration,
            is_active: isActive,
          };
        }
        return integration;
      })
    );
  };

  useEffect(() => {
    if (!isEmpty(updatedIntegration)) {
      const message = updatedIntegration.is_active
        ? 'El proveedor fue activado correctamente.'
        : 'El proveedor fue desactivado correctamente.';
      enqueueSnackbar(message, {
        variant: 'success',
      });
      updateIntegrationList(updatedIntegration, updatedIntegration.is_active);
      setDeactivateObj(null);
      setOpenConfirm(false);
    }
  }, [updatedIntegration, enqueueSnackbar]);

  const handleToggle = (provider, toggle) => {
    updateIntegration({
      code: provider.code,
      is_resell: true,
      is_active: toggle,
      field_values: provider.field_configurations.map(field => ({
        field_configuration_id: field.id,
        text: field.registered_value || '',
      })),
    });
  };

  return (
    <FlexContainer container height='100%' justify='center' padding='30px'>
      <FlexContainer width='100%' maxWidth='980px' height='100%'>
        <Card width='100%' height='100%' padding='40px 80px'>
          <FlexContainer container direction='column' margin='20px 0 0'>
            <FlexContainer container justify='center'>
              <Typography
                fontSize={14}
                color='text.secondary'
                margin='0 0 10px'
              >
                Usa estas cuentas con tarifas especiales Aimo y una facturación
                consolidada para tus entregas.
              </Typography>
            </FlexContainer>

            <FlexContainer container margin='15px 0'>
              {tagList.map(tag => (
                <Pill
                  key={tag}
                  clickable
                  margin='2px'
                  backgroundColor={
                    activeTags.includes(tag) ? THEME.colors.primary : null
                  }
                  textColor={activeTags.includes(tag) ? '#FFFFFF' : null}
                  onClick={() => {
                    if (activeTags.includes(tag)) {
                      setActiveTags(
                        activeTags.filter(active => active !== tag)
                      );
                    } else {
                      setActiveTags(activeTags.concat(tag));
                    }
                  }}
                >
                  {tag}
                </Pill>
              ))}
            </FlexContainer>

            {loading ? (
              <Spinner height='300px' />
            ) : (
              <>
                {integrations
                  .filter(item =>
                    item.tags.some(tag => activeTags.includes(tag))
                  )
                  .map((item, index) => (
                    <React.Fragment key={item.code}>
                      <Row
                        data={item}
                        onClick={() => {
                          setDrawerData({
                            object: item,
                          });
                          setOpenDrawer(true);
                        }}
                        onActivate={() => {
                          handleToggle(item, true);
                        }}
                        onDeactivate={() => {
                          setDeactivateObj(item);
                          setOpenConfirm(true);
                        }}
                      />
                      {integrations[index + 1] && (
                        <Divider orientation='horizontal' />
                      )}
                    </React.Fragment>
                  ))}
              </>
            )}
            <Drawer
              anchor='right'
              open={openDrawer}
              onClose={() => {
                setDrawerData({
                  object: null,
                });
                setOpenDrawer(false);
              }}
            >
              <Detail
                data={drawerData}
                onClose={() => {
                  setDrawerData({
                    object: null,
                  });
                  setOpenDrawer(false);
                }}
              />
            </Drawer>

            <Dialog
              disableBackdropClick={loadingUpdate}
              disableEscapeKeyDown={loadingUpdate}
              open={openConfirm}
              onClose={() => setOpenConfirm(false)}
            >
              <FlexContainer
                padding='30px'
                direction='column'
                alignItems='center'
                justify='center'
              >
                <Typography
                  color={THEME.colors.primary}
                  fontSize={15}
                  margin='0 0 20px'
                >
                  Desactivar proveedor
                </Typography>
                <Typography margin='0 0 30px' fontSize={13}>
                  ¿Estás seguro de desactivar el proveedor?
                </Typography>
                <FlexContainer
                  container
                  alignItems='center'
                  justify='space-between'
                >
                  <Button
                    variant='contained'
                    color='primary'
                    fontSize='13px'
                    onClick={() => handleToggle(deactivateObj, false)}
                    disabled={loadingUpdate}
                  >
                    Desactivar
                  </Button>

                  <Button
                    fontSize='13px'
                    onClick={() => {
                      setOpenConfirm(false);
                      setDeactivateObj(null);
                    }}
                    disabled={loadingUpdate}
                  >
                    Cancelar
                  </Button>
                </FlexContainer>
              </FlexContainer>
            </Dialog>
          </FlexContainer>
        </Card>
      </FlexContainer>
    </FlexContainer>
  );
};

export default AimoAccounts;
