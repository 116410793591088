import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { cloneDeep, find, isEmpty } from 'lodash';
import { Drawer, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Button,
  FlexContainer,
  Typography,
  Icon,
  Status,
  Select,
} from 'components/atoms';
import {
  Dropdown,
  DropdownIconOption,
  Cell,
  Spinner,
  Search,
  FormControl,
} from 'components/molecules';
import { DataTable, AssignBox } from 'components/organisms';
import { useTypedSelector } from 'store';
import { selectBranchOffice } from 'store/organization/actions';
import {
  getOrders,
  getPurchaseOrders,
  getStatuses,
  changeOrderStatus,
  changePurchaseOrderStatus,
  releaseOrdersRoute,
  confirmOrdersProvider,
  getProvidersMinimal,
  assignOrderProvider,
  downloadShippingLabels,
  chooseOrganization,
  getClients,
  getDigitalCatalogue,
  getChannels,
} from 'services';
import { optionFormatter, providerFormatter } from 'util/functions';
import THEME from 'util/styledTheme';
import statusColors from 'util/statusColors';
import useRequest from 'hooks/useRequest';
import Minutes99 from 'assets/images/providers/99minutos.png';
import Olva from 'assets/images/providers/olva.jpg';
import Cabify from 'assets/images/providers/cabify.png';
import Glovo from 'assets/images/providers/glovo.svg';
import Savar from 'assets/images/providers/savar.png';
import PedidosYa from 'assets/images/providers/pedidosya.png';
import Logo from 'assets/images/DefaultLogo.png';
import Minutes99Sm from 'assets/images/providers/99minutos_sm.png';
import OlvaSm from 'assets/images/providers/olva_sm.png';
import CabifySm from 'assets/images/providers/cabify_sm.png';
import PedidosYaSm from 'assets/images/providers/pedidosya_sm.png';
import { ReactComponent as FilterOptions } from 'assets/images/FilterIcon.svg';
import NoData from 'assets/images/no_data.svg';
import Timer from 'assets/images/timer-icon.svg';
import Calendar from 'assets/images/calendar-icon.svg';
import ArrowLeft from 'assets/images/arrow-left-icon.svg';
import { ReactComponent as MoreIcon } from 'assets/images/more-icon.svg';
import { ReactComponent as ConfirmIcon } from 'assets/images/check-icon.svg';
import { ReactComponent as WhatsappIcon } from 'assets/images/whatsapp-green-icon.svg';
import { ReactComponent as DocumentText } from 'assets/images/document_text.svg';
import DetailOrder from '../Detail';
import Edit from '../Edit';
import { TabsProps } from './types';

const purchaseOrderStatus = [
  {
    id: 1,
    overwritten_label: 'Preparando',
    code: 'preparing',
  },
  {
    id: 2,
    overwritten_label: 'Listo para entregar',
    code: 'ready_to_ship',
  },
  {
    id: 3,
    overwritten_label: 'Entregado',
    code: 'shipped',
  },
  {
    id: 4,
    overwritten_label: 'Completado',
    code: 'completed',
  },
  {
    id: 5,
    overwritten_label: 'Cancelado',
    code: 'cancelled',
  },
  {
    id: 6,
    overwritten_label: 'Devuelto',
    code: 'returned',
  },
];

const statusOptions = [
  {
    id: 1,
    name: 'Órden',
    code: 'order',
  },
  {
    id: 2,
    name: 'Órden de compra',
    code: 'purchase-order',
  },
];

const orderTypeOptions = [
  {
    id: 1,
    name: 'En tienda',
    code: 'in_store',
  },
  {
    id: 2,
    name: 'Delivery',
    code: 'delivery',
  },
  {
    id: 3,
    name: 'Devolución',
    code: 'return',
  },
];

const paymentStatusOptions = [
  {
    id: 1,
    name: 'Por pagar',
    code: 'to_pay',
  },
  {
    id: 2,
    name: 'Pagado',
    code: 'payed',
  },
];

const salesChannelOptions = [
  {
    id: 1,
    name: 'Catálogo digital',
    code: 'digital_catalog',
  },
  {
    id: 2,
    name: 'Instagram shop',
    code: 'instagram_shop',
  },
  {
    id: 3,
    name: 'Online',
    code: 'online',
  },
  {
    id: 4,
    name: 'Online',
    code: 'Online',
  },
];

const currencies = [
  {
    id: 1,
    name: 'Sol (S/)',
    code: 'PEN',
    symbol: 'S/',
  },
  {
    id: 2,
    name: 'Dólar estadounidense ($)',
    code: 'USD',
    symbol: '$',
  },
  {
    id: 3,
    name: 'Peso mexicano ($)',
    code: 'MXN',
    symbol: '$',
  },
  {
    id: 4,
    name: 'Peso chileno ($)',
    code: 'CLP',
    symbol: '$',
  },
  {
    id: 5,
    name: 'Peso argentino ($)',
    code: 'ARS',
    symbol: '$',
  },
  {
    id: 6,
    name: 'Peso colombiano ($)',
    code: 'COP',
    symbol: '$',
  },
  {
    id: 7,
    name: 'Peso uruguayo ($U)',
    code: 'UYU',
    symbol: '$U',
  },
  {
    id: 8,
    name: 'Boliviano (Bs)',
    code: 'BOB',
    symbol: 'Bs',
  },
  {
    id: 9,
    name: 'Euro',
    code: 'EUR',
    symbol: '€',
  },
];

const purchaseOrderStatusName = [
  {
    id: 1,
    name: 'Nuevos',
    value: 'new',
    bg: '#E2F5EA',
    bgSeconndary: '#2DB267',
    color: '#2DB267',
  },
  {
    id: 2,
    name: 'En curso',
    value: 'in_progress',
    bg: '#FFF9CC',
    bgSeconndary: '#FFE000',
    color: '#4D5369',
  },
  {
    id: 3,
    name: 'Finalizados',
    value: 'finished',
    bg: '#FCDDDC',
    bgSeconndary: '#F1564F',
    color: '#F1564F',
  },
];

const pickProviderIcon = (code, sm) => {
  if (sm) {
    switch (code) {
      case '99minutos':
        return Minutes99Sm;
      case 'olva':
        return OlvaSm;
      case 'cabify':
        return CabifySm;
      case 'PedidosYa':
        return PedidosYaSm;
      default:
        return '';
    }
  } else {
    switch (code) {
      case '99minutos':
        return Minutes99;
      case 'olva':
        return Olva;
      case 'cabify':
        return Cabify;
      case 'savar':
        return Savar;
      case 'PedidosYa':
        return PedidosYa;
      default:
        return '';
    }
  }
};

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#100A55',
    color: '#FFF',
    fontSize: 11,
    marginBottom: 7,
    padding: 6,
  },
  arrow: {
    color: '#100A55',
  },
})(Tooltip);

const TabOption = styled(FlexContainer)<{ selected: boolean; tabId: number }>`
  height: 64px;
`;

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 15px;
`;

type ProviderDetailsProps = {
  cursor?: string;
};
const ProviderButton = styled.button<ProviderDetailsProps>`
  background-color: transparent;
  border: 1px solid transparent;
  ${props => props.cursor && `cursor: ${props.cursor};`}
  padding: 0;
  color: #404071;
`;

const ProviderFileButton = styled(Button)`
  padding: 5px 6px;
`;

const FiltersButton = styled(Button)`
  color: #7065f0;
  font-weight: 700;
  padding: 0 15px;
  margin-right: 20px;
`;

const ShopifyContainer = styled(FlexContainer)`
  width: 43px;
  height: 43px;
  justify-content: center;
  align-items: center;
  background-color: #8db542;
  border-radius: 50%;
  img {
    width: 28px;
    height: auto;
    padding: 1px;
  }
`;

const WhatsappContainer = styled(FlexContainer)`
  // margin: 5px 0;
  padding: 5px 8px;
  border: 1px solid #f0effb;
  border-radius: 8px;
  width: 100%;
`;

const WhatsappLink = styled.a`
  margin-left: 5px;
  color: #4d5369;
  text-decoration: none;
  line-height: 15px;
`;

const options = [
  {
    name: 'Administrador',
    id: 'administrator_name',
  },
  {
    name: 'Provider',
    id: 'provider_name',
  },
  {
    name: 'Worker',
    id: 'worker_name',
  },
  {
    name: 'Cod. Referencia',
    id: 'reference_code',
  },
  {
    name: 'Contacto',
    id: 'contact_person',
  },
  {
    name: 'Placa',
    id: 'vehicle__plate',
  },
];

const NavTab: React.FC<TabsProps> = ({
  selected,
  purchaseOrders,
  loadingFilters,
  onChange,
  newFilter,
  inProgressFilter,
  finishedFilter,
}) => {
  const purchaseOrderQuantity = (value: string) => {
    if (!isEmpty(purchaseOrders)) {
      switch (value) {
        case 'new':
          return purchaseOrders.filter(order => order.status === 'created')
            .length;
        case 'in_progress':
          return purchaseOrders.filter(
            order =>
              order.status === 'preparing' ||
              order.status === 'ready_to_ship' ||
              order.status === 'shipped'
          ).length;
        case 'finished':
          return purchaseOrders.filter(
            order =>
              order.status === 'completed' ||
              order.status === 'cancelled' ||
              order.status === 'returned'
          ).length;
        default:
          return '';
      }
    }
  };

  return (
    <FlexContainer container alignItems='center'>
      <FlexContainer
        container
        height='64px'
        flex='1'
        justify='center'
        alignItems='center'
        padding='6px 0'
        backgroundColor='#ffffff'
        borderColor='#E0DEF7'
        borderRadius='8px'
      >
        {loadingFilters && purchaseOrders.length < 1 ? (
          <FlexContainer
            container
            height='64px'
            justify='center'
            alignItems='center'
          >
            <Spinner spinnerSize={25} />
          </FlexContainer>
        ) : (
          <>
            {purchaseOrderStatusName.map(tab => (
              <TabOption
                key={tab.value}
                flex='1'
                justify='center'
                alignItems='center'
                cursor='pointer'
                selected={selected === tab.value}
                onClick={() => {
                  onChange(tab.value);

                  // switch (tab.value) {
                  //   case 'new':
                  //     newFilter();
                  //     break;
                  //   case 'in_progress':
                  //     inProgressFilter();
                  //     break;
                  //   case 'finished':
                  //     finishedFilter();
                  //     break;
                  //   default:
                  //     break;
                  // }
                }}
                tabId={tab.id}
              >
                <FlexContainer
                  container
                  height='100%'
                  direction='column'
                  alignItems='center'
                >
                  <FlexContainer
                    container
                    height='95%'
                    justify='center'
                    alignItems='center'
                  >
                    <FlexContainer alignItems='center'>
                      <FlexContainer
                        width='calc(70% - 30px)'
                        height='calc(100% - 30px)'
                        backgroundColor={tab.bgSeconndary}
                        padding='2px 7px'
                        margin='0 5px 0 0'
                        borderRadius='10px'
                        justify='center'
                        style={{ minWidth: 33 }}
                      >
                        <Typography
                          color={
                            tab.value === 'in_progress' ? '#4D5369' : '#ffffff'
                          }
                        >
                          {purchaseOrderQuantity(tab.value)}
                        </Typography>
                      </FlexContainer>
                      <Typography
                        color={
                          selected === tab.value ? '#4D5369' : 'text.secondary'
                        }
                        fontWeight={selected === tab.value ? 700 : 400}
                      >
                        {tab.name}
                      </Typography>
                    </FlexContainer>
                  </FlexContainer>
                  {selected === tab.value && (
                    <FlexContainer
                      container
                      height='5%'
                      backgroundColor='#5641F0'
                      borderRadius='8px'
                    />
                  )}
                </FlexContainer>
              </TabOption>
            ))}
          </>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

const OrderList: React.FC<{
  handleOpenFilters: () => void;
  filterValuess: any;
  filterData: boolean;
  setFilterValuess: any;
  resetFilters: boolean;
}> = ({
  handleOpenFilters,
  filterValuess,
  filterData,
  setFilterValuess,
  resetFilters,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const branchOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  const organization = useTypedSelector(state => state.organization);
  const dispatch = useDispatch();
  const user = useTypedSelector(state => state.user);
  // API calls
  const [purchaseOrders, fetchOrders, loadingOrders, pageData] = useRequest(
    getPurchaseOrders,
    []
  );
  const [allPurchaseOrders, fetchAllOrders, loadingAllOrders] = useRequest(
    getPurchaseOrders,
    []
  );
  const [allStatus, fetchAllStatus, loadingAllStatus] = useRequest(
    getStatuses,
    []
  );
  const [
    digitalCatalogue,
    fetchDigitalCatalogue,
    loadingDigitalCatalogue,
  ] = useRequest(getDigitalCatalogue);
  const [channels, fetchChannels, loadingChannels] = useRequest(getChannels);
  const [manualStatus, fetchManualStatus, loadingManualStatus] = useRequest(
    getStatuses,
    []
  );
  const [providers, fetchProviders] = useRequest(getProvidersMinimal, null);
  const [, updateStatus, , , , statusRequest] = useRequest(
    changeOrderStatus,
    null
  );
  const [
    ,
    updatePurchaseStatus,
    ,
    ,
    errorPurchaseRequest,
    statusPurchaseRequest,
  ] = useRequest(changePurchaseOrderStatus, null);
  const [
    ,
    providerAssign,
    ,
    ,
    errorProviderAssign,
    statusAssignRequest,
  ] = useRequest(assignOrderProvider, null);
  const [successReleaseOrderOnRoute, releaseOrderOnRoute] = useRequest(
    releaseOrdersRoute,
    null
  );
  const [
    ,
    confirmProviders,
    ,
    ,
    errorConfirmProvider,
    statusConfirmRequest,
  ] = useRequest(confirmOrdersProvider, null);
  const [clients, fetchClients, loadingClients] = useRequest(getClients, []);
  // Values
  const [isLoading, setIsLoading] = useState(false);
  const [catalogues, setCatalogues] = useState([]);
  const [salesChannelId, setSalesChannelId] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [allStatusOptions, setStatusOptions] = useState([]);
  const [paymentStatusesOptions, setPaymentStatusesOptions] = useState([]);
  const [selectedTab, setSelectedTab] = useState<string>('new');
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [page, setPage] = useState<number>(1);
  const [assignOption, setAssignOption] = useState<string>('');
  const [assignStatus, setAssignStatus] = useState<number>(1);
  const [assignSelectOptions, setAssignSelectOptions] = useState([]);
  const [assignSearchOptions, setAssignSearchOptions] = useState([]);
  const [assignPurchaseOptions, setAssignPurchaseOptions] = useState([]);
  const [detailData, setDetailData] = useState({});
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openProviderDetail, setOpenProviderDetail] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [reprogram, setReprogram] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [firstLoadingOrders, setFirstLoadingOrders] = useState<boolean>(false);
  const [firstOrderRender, setFirstOrderRender] = useState<boolean>(false);
  const [searchWithFilter, setSearchWithFilter] = useState<boolean>(false);
  const [selectedProvider, setSelectedProvider] = useState<string>('');
  const [officeOptions, setOfficeOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [assignOrderTypeOptions, setAssignOrderTypeOptions] = useState([]);
  const [providersOptions, setProviderOptions] = useState([]);

  const defaultOption = { id: '', name: 'Todas', reference_code: '' };

  const toSelectDefaultOption = {
    id: JSON.stringify(defaultOption),
    name: 'Todas',
  };

  const toSelectPurchaseOption = {
    id: 'created',
    name: 'Creado',
  };

  const requestOrders = (
    keepRows?: boolean,
    isSearch?: boolean,
    purchaseFilter?: string
  ) => {
    if (!keepRows) {
      setSelectedRows([]);
    }

    const params = {
      last_status__id: filterValuess.last_status__id,
      order_type_id: filterValuess.order_type_id,
      delivery_programmed_date: filterValuess.delivery_programmed_date,
      created_at: filterValuess.created_at,
      time_window: filterValuess.time_window,
      organization_branch_office__id: branchOfficeId,
      client_id: filterValuess.client_id,
      current_status: purchaseFilter,
      page_size: 20,
      page: isSearch ? 1 : page,
    };

    if (searchInput !== '') {
      const paramsearch = 'search';
      params[paramsearch] = searchInput;
    }

    if (isSearch) {
      setSearchWithFilter(true);
    }

    fetchOrders(params);
    fetchAllOrders({
      page_size: 0,
    });
    fetchProviders({
      organization_branch_office_id: branchOfficeId,
      // routes: data.id,
    });
    setFirstOrderRender(false);
  };

  useEffect(() => {
    if (providers) {
      if (providers.length === 0) {
        enqueueSnackbar('No se encontraron coincidencias para su búsqueda.', {
          variant: 'warning',
        });
      } else {
        const pickImage = code => {
          switch (code) {
            case '99minutos':
              return Minutes99;
            case 'olva':
              return Olva;
            case 'cabify':
              return Cabify;
            case 'glovo':
              return Glovo;
            case 'savar':
              return Savar;
            case 'pedidosya':
              return PedidosYa;
            default:
              return '';
          }
        };
        setProviderOptions(
          providerFormatter(providers, {
            id: option => option.id,
            name: option => option.business_name,
            phone: option => option.phone,
            logo: option =>
              option.code ? pickImage(option.code) : option.logo || Logo,
          })
        );
      }
    }
  }, [providers]);

  const handleAssign = (id: string) => {
    if (selectedRows.length === 0) {
      enqueueSnackbar('Seleccione al menos una orden para asignar.', {
        variant: 'error',
      });
      return;
    }

    if (assignOption === 'status') {
      if (assignStatus === 1) {
        updateStatus({
          order_ids: selectedRows,
          status: id,
          organization_branch_office_id: branchOfficeId,
        });
      } else {
        selectedRows.forEach(rowId => {
          updatePurchaseStatus(
            {
              status: id,
            },
            rowId
          );
        });
      }
    } else {
      providerAssign({
        order_ids: selectedRows,
        provider_id: id,
      });
    }
  };

  const releaseOrders = ids => {
    releaseOrderOnRoute({
      order_ids: ids,
    });
  };

  const handleProviderSearch = search => {
    if (selectedRows.length === 0) {
      enqueueSnackbar('Seleccione al menos una orden para asignar.', {
        variant: 'error',
      });
      return;
    }

    fetchProviders({
      organization_branch_office_id: branchOfficeId,
      search,
      routes: selectedRows.join(','),
    });
  };

  const handleProviderConfirm = () => {
    if (selectedRows.length === 0) {
      enqueueSnackbar('Seleccione al menos una orden para confirmar.', {
        variant: 'error',
      });
      return;
    }
    const orderArray = selectedRows.map(orderId =>
      find(purchaseOrders, { id: +orderId })
    );

    const canConfirm = orderArray.every(
      order =>
        order.provider_name !== '-' && order.last_status.code === 'created'
    );

    if (canConfirm) {
      confirmProviders({
        order_ids: selectedRows,
      });
    } else {
      enqueueSnackbar('Una o varias órdenes no cuentan con proveedor.', {
        variant: 'error',
      });
    }
  };

  const handleDownload = async () => {
    const orderIds = purchaseOrders
      .filter(order => order.external_provider_file)
      .map(order => order.id);

    if (selectedRows.length !== 0) {
      const selectedOrderId = orderIds.filter(
        id => selectedRows.indexOf(id) !== -1
      );

      if (selectedOrderId.length !== 0) {
        const response = await downloadShippingLabels(selectedOrderId);

        if (response && response.data.url) {
          window.open(response.data.url);
        }
      } else {
        enqueueSnackbar('Órdenes sin guías.', {
          variant: 'error',
        });
      }
    } else if (orderIds.length !== 0) {
      const response = await downloadShippingLabels(orderIds);

      if (response && response.data.url) {
        window.open(response.data.url);
      }
    } else {
      enqueueSnackbar('Órdenes sin guías.', {
        variant: 'error',
      });
    }
  };

  const OverwrittenPurchaseLabel = status => {
    switch (status) {
      case 'created':
        return 'Creado';
      case 'preparing':
        return 'Preparando';
      case 'ready_to_ship':
        return 'Listo para entregar';
      case 'shipped':
        return 'Entregado';
      case 'completed':
        return 'Completado';
      case 'cancelled':
        return 'Cancelado';
      case 'returned':
        return 'Devuelto';
      default:
        return '-';
    }
  };

  const settingsOptions = [
    user.permissions.includes('core.order.update')
      ? {
          label: 'Editar',
          name: 'edit',
          icon: 'edit-icon',
          onClick: row => {
            setEditId(row.order.id);
            setOpenEdit(true);
          },
        }
      : null,
    // {
    //   label: 'Liberar',
    //   name: 'release',
    //   icon: 'send-icon',
    //   onClick: () => {
    //     releaseOrders(selectedRows);
    //   },
    // },
    user.permissions.includes('core.order.create')
      ? {
          label: 'Reprogramar',
          name: 'reprogram',
          icon: 'reassign-icon',
          onClick: row => {
            setReprogram(true);
            setEditId(row.order.id);
            setOpenEdit(true);
          },
        }
      : null,
  ];

  useEffect(() => {
    switch (selectedTab) {
      case 'new':
        requestOrders(false, false, 'created');
        break;
      case 'in_progress':
        requestOrders(false, false, 'preparing,ready_to_ship,shipped');
        break;
      case 'finished':
        requestOrders(false, false, 'completed,cancelled,returned');
        break;
      default:
        break;
    }
  }, [selectedTab, page]);

  useEffect(() => {
    fetchManualStatus({
      model_types: 'order',
      status_types: 'manual',
      page_size: 0,
    });
    fetchClients({
      page_size: 0,
    });
    fetchAllStatus({
      model_types: 'order',
      status_types: 'manual,automatic',
      page_size: 0,
    });
    fetchChannels();
  }, [fetchManualStatus, fetchClients, fetchAllStatus, fetchChannels]);

  useEffect(() => {
    if (filterValuess.client_id) {
      requestOrders(false, true);
    }
  }, [filterValuess]);

  useEffect(() => {
    setAssignSelectOptions(
      optionFormatter(manualStatus, {
        id: item => item.code,
        name: item => item.overwritten_label,
      })
    );
  }, [manualStatus]);

  // useEffect(() => {
  //   setStatusOptions(
  //     optionFormatter(allStatus, {
  //       id: item => item.code,
  //       name: item => item.overwritten_label,
  //     })
  //   );
  // }, [allStatus]);

  useEffect(() => {
    setPaymentStatusesOptions(
      optionFormatter(paymentStatusOptions, {
        id: item => item.code,
        name: item => item.name,
      })
    );
  }, [paymentStatusOptions]);

  useEffect(() => {
    setAssignPurchaseOptions(
      optionFormatter(purchaseOrderStatus, {
        id: item => item.code,
        name: item => item.overwritten_label,
      })
    );
  }, [purchaseOrderStatus]);

  useEffect(() => {
    setAssignOrderTypeOptions(
      optionFormatter(orderTypeOptions, {
        id: item => item.code,
        name: item => item.name,
      })
    );
  }, [orderTypeOptions]);

  useEffect(() => {
    // requestOrders();
    setFirstOrderRender(true);
  }, [page, branchOfficeId]);

  useEffect(() => {
    if (resetFilters) {
      requestOrders();
    }
  }, [resetFilters]);

  // useEffect(() => {
  //   if (providers) {
  //     if (providers.length === 0) {
  //       enqueueSnackbar('No se encontraron coincidencias para su búsqueda.', {
  //         variant: 'warning',
  //       });
  //     } else {
  //       const pickImage = code => {
  //         switch (code) {
  //           case '99minutos':
  //             return Minutes99;
  //           case 'olva':
  //             return Olva;
  //           case 'cabify':
  //             return Cabify;
  //           case 'glovo':
  //             return Glovo;
  //           case 'savar':
  //             return Savar;
  //           case 'PedidosYa':
  //             return PedidosYa;
  //           default:
  //             return '';
  //         }
  //       };
  //       setAssignSearchOptions(
  //         providerFormatter(providers, {
  //           id: option => option.id,
  //           name: option => option.business_name,
  //           phone: option => option.phone,
  //           logo: option =>
  //             option.code ? pickImage(option.code) : option.logo || Logo,
  //         })
  //       );
  //     }
  //   }
  // }, [providers]);

  useEffect(() => {
    if (!isEmpty(channels)) {
      setIsLoading(true);
      setCatalogues(
        channels.filter(channel => channel.platform === 'digital_catalog')
      );
      setSalesChannelId(
        channels.find(channel => channel.platform === 'digital_catalog').id
      );
    } else {
      setCatalogues([]);
    }
    setIsLoading(false);
  }, [channels]);

  useEffect(() => {
    if (salesChannelId !== '' || salesChannelId === undefined) {
      fetchDigitalCatalogue(salesChannelId);
    }
  }, [salesChannelId]);

  useEffect(() => {
    setIsLoading(true);
    if (!isEmpty(digitalCatalogue)) {
      if (
        digitalCatalogue.payment_methods &&
        digitalCatalogue.payment_methods.length > 0
      ) {
        setPaymentMethods(
          Array.from(digitalCatalogue.payment_methods).map((method: any) => {
            return {
              id: method.name,
              name: method.name.charAt(0).toUpperCase() + method.name.slice(1),
            };
          })
        );
      }
      setIsLoading(false);
    }
  }, [digitalCatalogue]);

  useEffect(() => {
    if (statusRequest === 200) {
      enqueueSnackbar('Se actualizó el estado de las órdenes correctamente.', {
        variant: 'success',
      });
      switch (selectedTab) {
        case 'new':
          requestOrders(false, false, 'created');
          break;
        case 'in_progress':
          requestOrders(false, false, 'preparing,ready_to_ship,shipped');
          break;
        case 'finished':
          requestOrders(false, false, 'completed,cancelled,returned');
          break;
        default:
          break;
      }
      setAssignOption('');
      setAssignStatus(1);
    } else if (statusRequest && statusRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusRequest, enqueueSnackbar]);

  useEffect(() => {
    if (statusPurchaseRequest === 200) {
      enqueueSnackbar('Se actualizó el estado de las órdenes correctamente.', {
        variant: 'success',
      });
      if (selectedTab !== '') {
        switch (selectedTab) {
          case 'new':
            requestOrders(false, false, 'created');
            break;
          case 'in_progress':
            requestOrders(false, false, 'preparing,ready_to_ship,shipped');
            break;
          case 'finished':
            requestOrders(false, false, 'completed,cancelled,returned');
            break;
          default:
            break;
        }
      } else {
        requestOrders();
      }
      setAssignOption('');
      setAssignStatus(1);
    } else if (statusPurchaseRequest && statusPurchaseRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusPurchaseRequest, enqueueSnackbar]);

  useEffect(() => {
    if (statusConfirmRequest === 204) {
      enqueueSnackbar(
        'Se confirmo el proveedor de las órdenes correctamente.',
        {
          variant: 'success',
        }
      );
      switch (selectedTab) {
        case 'new':
          requestOrders(false, false, 'created');
          break;
        case 'in_progress':
          requestOrders(false, false, 'preparing,ready_to_ship,shipped');
          break;
        case 'finished':
          requestOrders(false, false, 'completed,cancelled,returned');
          break;
        default:
          break;
      }
    } else if (statusConfirmRequest && statusConfirmRequest !== 204) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusConfirmRequest, enqueueSnackbar]);

  useEffect(() => {
    if (errorPurchaseRequest && errorPurchaseRequest.data) {
      if (errorPurchaseRequest.data.detail) {
        enqueueSnackbar('No se encontró la orden de compra', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
          variant: 'error',
        });
      }
    }
  }, [errorPurchaseRequest, enqueueSnackbar]);

  useEffect(() => {
    if (
      errorConfirmProvider &&
      errorConfirmProvider.data &&
      errorConfirmProvider.data.errors
    ) {
      if (errorConfirmProvider.data.errors[0] === 'Insufficient balance.') {
        enqueueSnackbar('Saldo insuficiente.', {
          variant: 'error',
        });
      }
    }
  }, [errorConfirmProvider]);

  useEffect(() => {
    if (
      errorProviderAssign &&
      errorProviderAssign.data &&
      errorProviderAssign.data.errors
    ) {
      if (errorProviderAssign.data.errors[0] === "{'title': 'Sin cobertura'}") {
        enqueueSnackbar('Sin cobertura', {
          variant: 'error',
        });
      } else if (
        errorProviderAssign.data.errors[0] ===
        'Error. PedidosYa only accepts immediate orders.'
      ) {
        enqueueSnackbar('PedidosYa solo acepta órdenes inmediatas', {
          variant: 'error',
        });
      } else if (!errorProviderAssign.data.errors[0].order_ids) {
        enqueueSnackbar(errorProviderAssign.data.errors[0], {
          variant: 'error',
        });
      }
    }
  }, [errorProviderAssign]);

  useEffect(() => {
    if (statusAssignRequest === 200) {
      enqueueSnackbar('Se asignó el provider a las órdenes correctamente.', {
        variant: 'success',
      });
      switch (selectedTab) {
        case 'new':
          requestOrders(false, false, 'created');
          break;
        case 'in_progress':
          requestOrders(false, false, 'preparing,ready_to_ship,shipped');
          break;
        case 'finished':
          requestOrders(false, false, 'completed,cancelled,returned');
          break;
        default:
          break;
      }
      setAssignOption('');
      setSearchInput('');
      setAssignSearchOptions([]);
    } else if (statusRequest && statusRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusAssignRequest]);

  useEffect(() => {
    if (successReleaseOrderOnRoute) {
      enqueueSnackbar('Se liberaron las órdenes seleccionadas correctamente.', {
        variant: 'success',
      });
      requestOrders();
    }
  }, [successReleaseOrderOnRoute, enqueueSnackbar]);

  useEffect(() => {
    if (searchWithFilter) {
      setFirstOrderRender(false);
    } else if (firstOrderRender && loadingOrders && page === 1) {
      setFirstLoadingOrders(true);
    } else {
      setFirstLoadingOrders(false);
    }
  }, [firstOrderRender, loadingOrders]);

  useEffect(() => {
    if (clients.length > 0) {
      const activeClients = clients.filter(client => client.is_active);
      setClientOptions(
        optionFormatter(activeClients, {
          id: option => option.id,
          name: option => option.name,
        })
      );
    }
  }, [clients]);

  useEffect(() => {
    chooseOrganization({
      page_size: 0,
    }).then(res => {
      const organizationObj = find(res.data, {
        id: parseInt(`${organization.selectedOrganization.id}`, 10),
      });

      if (organizationObj) {
        setOfficeOptions(
          optionFormatter(organizationObj.administrator_branch_offices, {
            id: option => JSON.stringify(option),
            name: option => option.name,
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    if (filterData) {
      requestOrders(false, true);
    }
  }, [filterData]);

  const option = row =>
    settingsOptions
      .filter(setting => setting)
      .filter(
        setting =>
          row.can_rescheduled ||
          (!row.can_rescheduled && setting.name !== 'reprogram')
      )
      // .filter(
      //   setting =>
      //     row.last_status.code === 'created' ||
      //     row.last_status.code === 'pending' ||
      //     ((row.last_status.code !== 'created' ||
      //       row.last_status.code !== 'pending') &&
      //       setting.name !== 'edit')
      // )
      // .filter(setting => {
      //   let prueba = {};

      //   if (row.provider && row.provider.service_type !== 'internal') {
      //     prueba = setting.name !== 'release';
      //   } else if (
      //     row.provider &&
      //     row.provider.service_type === 'internal' &&
      //     (row.last_status.code === 'cancelled' ||
      //       row.last_status.code === 'completed' ||
      //       row.last_status.code === 'failed')
      //   ) {
      //     prueba = setting.name !== 'release';
      //   }
      //   return prueba;
      // })
      .map(setting => (
        <DropdownIconOption
          key={setting.name}
          label={setting.label}
          name={setting.name}
          icon={setting.icon}
          onClick={() => setting.onClick(row)}
        />
      ));

  const purchaseStatusName = (status: string) => {
    switch (status) {
      case 'created':
        return purchaseOrderStatusName[0];
      case 'preparing':
      case 'ready_to_ship':
      case 'shipped':
        return purchaseOrderStatusName[1];
      case 'completed':
      case 'cancelled':
      case 'returned':
        return purchaseOrderStatusName[2];
      default:
        return null;
    }
  };

  const headers = useMemo(
    () => [
      {
        label: 'Fecha',
        id: 'purchase_date',
        cell: row => (
          <FlexContainer direction='column'>
            <FlexContainer margin='0 0 8px'>
              <img src={Calendar} alt='calendar' style={{ width: 17 }} />
              <Typography color='#4D5369' margin='0 0 0 5px'>
                {row.order && row.order.programmed_date
                  ? moment(row.order.programmed_date).format('DD/MM/YYYY')
                  : ''}
              </Typography>
            </FlexContainer>
            <FlexContainer>
              <img src={Timer} alt='timer' style={{ width: 15 }} />
              <Cell.Link
                onClick={() => {
                  if (user.permissions.includes('core.order.retrieve')) {
                    setDetailData(row);
                    setOpenDetail(true);
                  }
                }}
              >
                <Typography fontWeight={700} color='#4D5369' margin='0 0 0 5px'>
                  {row.code || '-'}
                </Typography>
              </Cell.Link>
            </FlexContainer>
          </FlexContainer>
        ),
      },
      {
        label: 'Estado de orden',
        id: 'order_status',
        cell: row => (
          <FlexContainer container direction='column'>
            <FlexContainer
              width='90%'
              justify='center'
              backgroundColor={purchaseStatusName(row.status).bg}
              padding='5px 13px'
              borderRadius='8px'
            >
              <Typography
                fontWeight={600}
                color={purchaseStatusName(row.status).color}
              >
                {purchaseStatusName(row.status).name}
              </Typography>
            </FlexContainer>
            <FlexContainer container margin='5px 0 0'>
              {row.status !== 'completed' &&
              row.status !== 'cancelled' &&
              row.status !== 'returned' ? (
                <FormControl
                  control='select'
                  value={row.status}
                  defaultOption={toSelectPurchaseOption}
                  options={assignPurchaseOptions}
                  onChange={value => {
                    updatePurchaseStatus(
                      {
                        status: value,
                      },
                      row.id
                    );
                  }}
                  height='30px'
                  width='90%'
                />
              ) : (
                <FlexContainer margin='0 0 0 8px'>
                  <Typography fontSize={14} color='#8c8cb1'>
                    {
                      assignPurchaseOptions.find(
                        statusOption => statusOption.id === row.status
                      ).name
                    }
                  </Typography>
                </FlexContainer>
              )}
            </FlexContainer>
          </FlexContainer>
        ),
      },
      {
        label: 'Canal de venta',
        id: 'sales_chanel',
        cell: row => (
          <FlexContainer margin='0'>
            {row.sales_channel !== null && row.sales_channel !== '' ? (
              <Typography color='#4D5369'>
                {
                  salesChannelOptions.find(
                    channelOption => channelOption.code === row.sales_channel
                  ).name
                }
              </Typography>
            ) : (
              <Typography color='#4D5369'>-</Typography>
            )}
          </FlexContainer>
        ),
      },
      {
        label: 'Contacto',
        id: 'contact',
        cell: row => (
          <FlexContainer direction='column'>
            <Typography fontWeight={700} color='#4D5369'>
              {row.customer ? row.customer.full_name : '-'}
            </Typography>
            {row.customer && row.customer.phone_number && (
              <CustomTooltip arrow placement='top' title='Enviar mensaje'>
                <WhatsappContainer alignItems='center'>
                  <FlexContainer container alignItems='center'>
                    <WhatsappIcon />
                    <WhatsappLink
                      href={`https://wa.me/${row.customer.phone_number}`}
                    >
                      {row.customer.phone_number}
                    </WhatsappLink>
                  </FlexContainer>
                  <img src={ArrowLeft} alt='arrow' />
                </WhatsappContainer>
              </CustomTooltip>
            )}
          </FlexContainer>
        ),
      },
      {
        label: 'Tipo de orden',
        id: 'order_type',
        cell: row => (
          <FlexContainer direction='column'>
            <FlexContainer>
              {row.order && row.order_type !== null && row.order_type !== '' ? (
                <>
                  {row.order.last_status.code === 'pending' ? (
                    <CustomTooltip
                      arrow
                      placement='top'
                      title='No se encontró la dirección, click aquí para resolverlo.'
                    >
                      <div style={{ display: 'inline-block' }}>
                        <Cell.Link
                          onClick={() => {
                            if (
                              user.permissions.includes('core.order.update')
                            ) {
                              setEditId(row.order.id);
                              setOpenEdit(true);
                            }
                          }}
                        >
                          <Cell.Icon icon='error-icon'>
                            <Typography color={THEME.colors.error}>
                              {
                                orderTypeOptions.find(
                                  orderType => orderType.code === row.order_type
                                ).name
                              }
                            </Typography>
                          </Cell.Icon>
                        </Cell.Link>
                      </div>
                    </CustomTooltip>
                  ) : (
                    <Typography color='#4D5369'>
                      {
                        orderTypeOptions.find(
                          orderType => orderType.code === row.order_type
                        ).name
                      }
                      {row.order.last_status.code === 'searching' &&
                        ' - Buscando'}
                    </Typography>
                  )}
                </>
              ) : (
                <Typography color='#4D5369'>-</Typography>
              )}
            </FlexContainer>
            {selectedTab === 'in_progress' && (
              <FlexContainer margin='5px 0 0'>
                {row.order !== null ? (
                  <FormControl
                    control='select'
                    value={row.order.last_status.code}
                    options={assignSelectOptions}
                    onChange={value => {
                      updateStatus({
                        order_ids: [row.order.id],
                        status: value,
                        organization_branch_office_id: branchOfficeId,
                      });
                    }}
                    height='30px'
                    width='90%'
                    disabled={row.order.last_status.code === 'pending'}
                  />
                ) : (
                  <Typography>-</Typography>
                )}
              </FlexContainer>
            )}
          </FlexContainer>
        ),
      },
      selectedTab !== 'new' &&
      (user.permissions.includes('core.provider.list') ||
        user.permissions.includes('core.provider_integration.list'))
        ? {
            label: 'Proveedor',
            id: 'provider_name',
            cell: row => (
              <FlexContainer direction='column'>
                <FlexContainer>
                  <FlexContainer container direction='column'>
                    <FlexContainer>
                      <>
                        {/* {selectedTab === 'in_progress' ? (
                          <FlexContainer alignItems='center'>
                            <FormControl
                              control='select'
                              value={
                                row.order &&
                                row.order.provider &&
                                providersOptions.find(
                                  provider =>
                                    provider.name ===
                                    row.order.provider.business_name
                                ).id
                              }
                              placeholder='Selecciona proveedor'
                              options={providersOptions}
                              onChange={value => {
                                providerAssign({
                                  order_ids: [`${row.order.id}`],
                                  provider_id: value,
                                });
                              }}
                              height='30px'
                              width='90%'
                            />
                            <CustomTooltip
                              arrow
                              placement='top'
                              title='Confirmar proveedor'
                            >
                              <div style={{ display: 'inline-block' }}>
                                <FlexContainer
                                  width='26px'
                                  margin='0 0 0 5px'
                                  borderRadius='50%'
                                  padding='5px'
                                  style={{
                                    border: '1px solid #DBD9F5',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    confirmProviders({
                                      order_ids: [row.order.id],
                                    });
                                  }}
                                >
                                  <ConfirmIcon />
                                </FlexContainer>
                              </div>
                            </CustomTooltip>
                          </FlexContainer>
                        ) : ( */}
                        <>
                          {row.order !== null && row.order.provider ? (
                            <>
                              {pickProviderIcon(
                                row.order.provider.business_name,
                                true
                              ) && (
                                <img
                                  src={pickProviderIcon(
                                    row.order.provider.business_name,
                                    true
                                  )}
                                  alt={row.order.provider.tradename}
                                  style={{
                                    width: '17px',
                                    height: 'auto',
                                    margin: '0 5px 0 0',
                                  }}
                                />
                              )}
                              {row.order.provider.business_name}
                            </>
                          ) : (
                            <Typography>-</Typography>
                          )}
                        </>
                        {/* )} */}
                      </>
                    </FlexContainer>
                    <FlexContainer margin='5px 0 0'>
                      {row.order !== null && row.order.delivery_price && (
                        <Typography>
                          {
                            currencies.find(
                              currency =>
                                currency.code ===
                                row.order.delivery_price_currency
                            ).symbol
                          }{' '}
                          {row.order.delivery_price}
                        </Typography>
                      )}
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
                {row.worker && (
                  <Typography>
                    {row.worker.first_name} {row.worker.last_name}
                  </Typography>
                )}
              </FlexContainer>
            ),
          }
        : null,
      {
        label: 'Métodos de pago',
        id: 'payment_method',
        cell: row => (
          <FlexContainer direction='column'>
            <FlexContainer margin='0 0 5px'>
              <Typography color='#4D5369'>
                {row.payment_method !== ''
                  ? row.payment_method.charAt(0).toUpperCase() +
                    row.payment_method.slice(1)
                  : '-'}
              </Typography>
            </FlexContainer>
            <FlexContainer>
              {row.order &&
              row.delivery_price_currency !== null &&
              row.delivery_price_currency !== '' ? (
                <Typography color='#4D5369'>
                  {
                    currencies.find(
                      currency =>
                        currency.code === row.order.delivery_price_currency
                    ).symbol
                  }{' '}
                  {row.items_value}
                </Typography>
              ) : (
                <Typography color='#4D5369'>-</Typography>
              )}
            </FlexContainer>
          </FlexContainer>
        ),
      },
      {
        label: 'Estado de pago',
        id: 'payment_status',
        cell: row => (
          <FlexContainer direction='column'>
            <FormControl
              control='select'
              value={row.payment_status}
              options={paymentStatusesOptions}
              onChange={value => {
                updatePurchaseStatus(
                  {
                    payment_status: value,
                  },
                  row.id
                );
              }}
              width='90%'
              height='30px'
            />
          </FlexContainer>
        ),
      },
      selectedTab !== 'new'
        ? {
            label: 'Guía',
            id: 'provider_file',
            cell: row => (
              <>
                {row.order !== null && row.order.external_provider_file ? (
                  <ProviderFileButton
                    onClick={() =>
                      window.open(row.order.external_provider_file)
                    }
                  >
                    <DocumentText />
                  </ProviderFileButton>
                ) : (
                  <Typography>-</Typography>
                )}
              </>
            ),
          }
        : null,
      selectedTab !== 'new' &&
      user.permissions.includes('core.order.shipping_documents')
        ? {
            label: '',
            id: 'download_all',
            cell: () => <></>,
          }
        : null,
      {
        label: '',
        id: 'settings',
        cell: row => (
          <Dropdown
            label={<Icon icon='options-icon' size={20} />}
            direction='bottom'
            position='right'
            showCaret={false}
            options={option(row)}
            padding='0 10px'
            hover
            disabled={isEmpty(option(row))}
          />
        ),
      },
    ],
    [selectedRows]
  );

  return (
    <>
      {firstLoadingOrders && firstOrderRender ? (
        <FlexContainer container height='100vh'>
          <FlexContainer
            container
            padding='30px 40px'
            direction='column'
            justify='center'
            alignItems='center'
            height='100vh'
          >
            <Spinner height='350px' />
          </FlexContainer>
        </FlexContainer>
      ) : (
        <FlexContainer container padding='30px 40px' direction='column'>
          <FlexContainer container direction='column'>
            <FlexContainer
              container
              padding='10px 0 20px'
              justify='space-between'
            >
              <FlexContainer>
                <FiltersButton
                  variant='contained'
                  color='secondary'
                  onClick={() => handleOpenFilters()}
                >
                  <FilterOptions style={{ marginRight: 6 }} />
                  Filtrar
                </FiltersButton>
                <Select
                  placeholder='Seleccione una organización'
                  value={JSON.stringify(organization.selectedBranchOffice)}
                  options={officeOptions}
                  defaultOption={toSelectDefaultOption}
                  onChange={value => {
                    const parsedValue = JSON.parse(value);
                    dispatch(
                      selectBranchOffice({
                        id: parsedValue.id,
                        name: parsedValue.name,
                        reference_code: parsedValue.reference_code,
                      })
                    );
                    setSearchWithFilter(true);
                  }}
                  borderless
                />
                {(organization.selectedOrganization.plan === 'full' ||
                  organization.selectedOrganization.plan ===
                    'logistic_operator') && (
                  <FlexContainer margin='0 20px 0'>
                    <Select
                      placeholder='Seleccione un cliente'
                      value={filterValuess.client_id}
                      options={clientOptions}
                      onChange={value => {
                        setFilterValuess({
                          ...filterValuess,
                          client_id: value,
                        });
                        setSearchWithFilter(true);
                      }}
                      borderless
                      disabled={loadingClients}
                    />
                  </FlexContainer>
                )}
              </FlexContainer>
              <FlexContainer container justify='flex-end'>
                <Search
                  onChangeValue={value => setSearchInput(value)}
                  searchValue={searchInput}
                  onSubmit={() => requestOrders(false, true)}
                  maxWidth='300px'
                  placeholder='Buscar por código, cliente ...'
                />
              </FlexContainer>
            </FlexContainer>

            <FlexContainer container margin='0 0 12px'>
              <NavTab
                selected={selectedTab}
                purchaseOrders={allPurchaseOrders}
                loadingFilters={loadingAllOrders}
                onChange={value => {
                  setSelectedTab(value);
                  setPage(1);
                }}
                newFilter={() => requestOrders(false, false, 'created')}
                inProgressFilter={() =>
                  requestOrders(false, false, 'preparing,ready_to_ship,shipped')
                }
                finishedFilter={() =>
                  requestOrders(false, false, 'completed,cancelled,returned')
                }
              />
            </FlexContainer>

            {firstOrderRender &&
            !searchWithFilter &&
            purchaseOrders.length === 0 &&
            !loadingAllStatus ? (
              <FlexContainer
                container
                direction='column'
                justify='center'
                alignItems='center'
                height='70vh'
              >
                <img src={NoData} alt='no_data' />
                <Typography color='text.secondary'>
                  No hay órdenes registradas
                </Typography>
              </FlexContainer>
            ) : (
              <FlexContainer container direction='column'>
                {assignOption !== '' && (
                  <AssignBox
                    title={
                      assignOption === 'status'
                        ? 'Selecciona el estado para cambiar.'
                        : `Busca al ${assignOption} que desees asignar`
                    }
                    type={assignOption === 'status' ? 'select' : 'search'}
                    onSearch={search => {
                      setAssignSearchOptions([]);
                      handleProviderSearch(search);
                    }}
                    onSelect={optionId => handleAssign(optionId)}
                    onClose={() => {
                      setAssignOption('');
                      setAssignSearchOptions([]);
                    }}
                    selectOptions={assignSelectOptions}
                    selectPurchaseOptions={assignPurchaseOptions}
                    searchOptions={assignSearchOptions}
                    statusOptions={statusOptions}
                    onChangeAssignStatus={value => setAssignStatus(value)}
                    onChangeOrderOption={() => setSelectedRows([])}
                    assignOption={assignOption}
                    orderAssign
                  />
                )}

                <DataTable
                  headers={headers.filter(header => header)}
                  data={purchaseOrders}
                  selectedRows={selectedRows}
                  onSelectRow={rows => setSelectedRows(rows)}
                  onChangePage={selectedPage => setPage(selectedPage)}
                  onDownloadAll={() => handleDownload()}
                  totalPages={pageData.totalPages}
                  totalItems={pageData.totalItems}
                  pageSize={pageData.pageSize}
                  page={page}
                  loading={loadingOrders || loadingAllStatus}
                  dataType='orders'
                  assignStatus={assignStatus}
                  downloadToolTipText='Descargar todas las guías'
                />

                <Drawer
                  anchor='right'
                  open={openDetail}
                  onClose={() => {
                    setOpenDetail(false);
                  }}
                >
                  <DetailOrder
                    data={detailData}
                    onClose={() => {
                      setOpenDetail(false);
                    }}
                    onEdit={() => {
                      requestOrders(true);
                    }}
                  />
                </Drawer>

                <Drawer
                  anchor='right'
                  open={openEdit}
                  onClose={() => {
                    setReprogram(false);
                    setEditId(null);
                    setOpenEdit(false);
                  }}
                >
                  <FlexContainer
                    width='1300px'
                    position='relative'
                    padding='20px 30px 50px'
                    direction='column'
                    height='100%'
                  >
                    <CloseIcon
                      onClick={() => {
                        setEditId(null);
                        setOpenEdit(false);
                      }}
                    >
                      <Icon
                        icon='cancel-icon'
                        color={THEME.colors.placeholderColor}
                        size={25}
                      />
                    </CloseIcon>
                    <Edit
                      reprogram={reprogram}
                      editId={editId}
                      onReprogram={() => {
                        setReprogram(false);
                        setEditId(null);
                        setOpenEdit(false);
                        requestOrders(true);
                      }}
                      onEdit={() => {
                        switch (selectedTab) {
                          case 'new':
                            requestOrders(true, false, 'created');
                            break;
                          case 'in_progress':
                            requestOrders(
                              true,
                              false,
                              'preparing,ready_to_ship,shipped'
                            );
                            break;
                          case 'finished':
                            requestOrders(
                              true,
                              false,
                              'completed,cancelled,returned'
                            );
                            break;
                          default:
                            break;
                        }
                      }}
                    />
                  </FlexContainer>
                </Drawer>
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>
      )}
    </>
  );
};

export default OrderList;
