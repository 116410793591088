import transportationLogo from 'assets/images/portal/fake-transportation-image.png';

export const fakeOrderInfoData = {
  code: '9BMQF0Y4',
  reference_code: 'CODE-TEST-01',
  organization: {
    tradename: 'Aimo Logistics Perú',
    photo:
      'https://cdn.dev.aimo.co/core/media/organizations/b6e90e1f-1828-4d28-89c3-4888730211f7.png',
    worker_label: 'Repartidor',
  },
  eta: '19:06:41.855221',
  tracking_phone_number: '+51993456778',
  tasks: [
    {
      items: [
        {
          description: 'Iphone 12 Pro',
          quantity: 1,
          code: '0123-iphone-pro-max',
        },
      ],
      contact_person: 'John Doe',
      address: 'Av. Alfredo Benavides 1944, Santiago de Surco 15023',
      address_detail: 'Piso 9',
    },
  ],
  current_status: {
    code: 'completed',
    stage: 'final',
  },
  provider: {
    logo: transportationLogo,
  },
  external_provider_reference_code: '3505V',
  delivery_programmed_date: '2020-11-17',
  events: [
    {
      label: 'En recojo',
      detail: '',
      timestamp: '2020-11-18T00:06:13.004470Z',
    },
    {
      label: 'Recogido',
      detail: 'Completado correctamente',
      timestamp: '2020-11-18T00:06:29.065063Z',
    },
    {
      label: 'En camino a entrega',
      detail: '',
      timestamp: '2020-11-18T00:06:37.314917Z',
    },
    {
      label: 'En entrega',
      detail: '',
      timestamp: '2020-11-18T00:06:41.888249Z',
    },
    {
      label: 'Entregado',
      detail: 'Completado correctamente',
      timestamp: '2020-11-18T00:07:01.353822Z',
    },
  ],
  last_event: {
    label: 'Entregado',
    detail: 'Completado correctamente',
    timestamp: '2020-11-18T00:07:01.353822Z',
  },
};
