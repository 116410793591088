import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Drawer } from '@material-ui/core';
import {
  Button,
  FlexContainer,
  Typography,
  Icon,
  // Switch,
} from 'components/atoms';
import { Cell, Spinner, Search } from 'components/molecules';
import { DataTable } from 'components/organisms';
import { useTypedSelector } from 'store';
import { getClients, getSearchClient } from 'services';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import NoData from 'assets/images/no_data.svg';
import EditClient from '../Edit';

const StatusCircle = styled.div<{ active }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${props =>
    props.active
      ? props.theme.colors.success
      : props.theme.colors.placeholderColor};
  box-shadow: rgb(0 0 0 / 8%) 2px 2px 3px 0px;
  margin: 5px;
`;

const ClientList: React.FC = () => {
  const branchOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  const user = useTypedSelector(state => state.user);
  // API calls
  const [clients, fetchClients, loadingClients, pageData] = useRequest(
    getClients,
    []
  );
  const [client, fetchClient, loadingClient] = useRequest(getSearchClient, []);
  // Values
  const [page, setPage] = useState<number>(1);
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [editData, setEditData] = useState(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [firstLoadingClients, setFirstLoadingClients] = useState<boolean>(
    false
  );
  const [firstClientsRender, setFirstClientsRender] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');

  const requestClients = (currentPage: number) => {
    setSelectedRows([]);
    const params = {
      organization_branch_office__id: branchOfficeId,
      page_size: 20,
      page: currentPage,
    };
    setPage(currentPage);
    fetchClients(params);
    setFirstClientsRender(false);
  };

  useEffect(() => {
    requestClients(page);
    setFirstClientsRender(true);
  }, []);

  const handleSearch = () => {
    if (searchInput.length !== 0) {
      fetchClient(searchInput);
    } else {
      fetchClient('');
    }
  };

  useEffect(() => {
    if (firstClientsRender && loadingClients && page === 1) {
      setFirstLoadingClients(true);
    } else {
      setFirstLoadingClients(false);
    }
  }, [firstClientsRender, loadingClients]);

  const headers = useMemo(
    () => [
      {
        label: 'Nombre',
        id: 'name',
        cell: row => (
          <Cell.Link
            onClick={() => {
              if (user.permissions.includes('core.client.update')) {
                setEditData(row);
                setOpenEdit(true);
              }
            }}
          >
            <Typography fontWeight={700}>{row.name}</Typography>
          </Cell.Link>
        ),
      },
      {
        label: 'RUC',
        id: 'tax_id',
        cell: row => row.tax_id,
      },
      {
        label: 'Dirección',
        id: 'address',
        cell: row => row.address,
      },
      {
        label: 'Activo',
        id: 'status',
        cell: row => <StatusCircle active={row.is_active} />,
      },
    ],
    [selectedRows]
  );

  return (
    <>
      {firstLoadingClients ? (
        <FlexContainer
          container
          padding='30px 40px'
          direction='column'
          justify='center'
          alignItems='center'
          height='80vh'
        >
          <Spinner height='350px' />
        </FlexContainer>
      ) : (
        <FlexContainer container padding='30px 40px' direction='column'>
          {firstClientsRender && clients.length === 0 ? (
            <FlexContainer
              container
              direction='column'
              justify='center'
              alignItems='center'
              height='70vh'
            >
              <img src={NoData} alt='no_data' />
              <Typography color='text.secondary'>
                No hay clientes registrados
              </Typography>
              <FlexContainer>
                {user.permissions.includes('core.client.create') && (
                  <Button
                    variant='contained'
                    color='primary'
                    margin='20px 0 0 20px'
                    padding='0 40px'
                    fontSize='13px'
                    onClick={() => setOpenEdit(true)}
                  >
                    Crear cliente
                  </Button>
                )}
              </FlexContainer>
            </FlexContainer>
          ) : (
            <>
              <FlexContainer
                container
                direction='column'
                margin='0 0 20px'
                padding='20px'
                backgroundColor='#FFFFFF'
                borderRadius='4px'
                borderColor={THEME.colors.borderColor}
              >
                <FlexContainer container justify='center'>
                  <FlexContainer container>
                    <Search
                      onChangeValue={value => setSearchInput(value)}
                      searchValue={searchInput}
                      onSubmit={() => handleSearch()}
                      maxWidth='550px'
                      placeholder='Buscar por Nombre o RUC'
                    />
                    <Button
                      variant='contained'
                      color='primary'
                      margin='0 20px'
                      fontSize='13px'
                      onClick={() => handleSearch()}
                    >
                      Buscar
                    </Button>
                  </FlexContainer>
                  <FlexContainer container justify='flex-end'>
                    {user.permissions.includes('core.client.create') && (
                      <Button
                        color='primary'
                        margin='0 0 0 20px'
                        fontSize='13px'
                        startIcon={<Icon icon='plus-icon' size={18} />}
                        onClick={() => setOpenEdit(true)}
                      >
                        Crear Cliente
                      </Button>
                    )}
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>

              <DataTable
                headers={headers}
                data={
                  client.length > 0 ? client : clients.filter(c => c.is_active)
                }
                selectedRows={selectedRows}
                onSelectRow={rows => setSelectedRows(rows)}
                onChangePage={selectedPage => requestClients(selectedPage)}
                totalPages={pageData.totalPages}
                totalItems={pageData.totalItems}
                pageSize={pageData.pageSize}
                page={page}
                loading={loadingClient || loadingClients}
                selectable
              />
            </>
          )}
          <Drawer
            anchor='right'
            open={openEdit}
            onClose={() => {
              setEditData(null);
              setOpenEdit(false);
            }}
          >
            <FlexContainer
              width='1300px'
              position='relative'
              padding='20px 40px 50px'
              direction='column'
            >
              <EditClient
                data={editData}
                onCreate={() => requestClients(page)}
                onClose={() => {
                  setEditData(null);
                  setOpenEdit(false);
                }}
              />
            </FlexContainer>
          </Drawer>
        </FlexContainer>
      )}
    </>
  );
};

export default ClientList;
