import React, { cloneElement } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { useTransition, animated } from 'react-spring';
import styled from 'styled-components';
import { Icon, Button } from 'components/atoms';
import { omit } from 'lodash';

import {
  DropdownProps,
  DropdownButtonProps,
  ContainerOptionProps,
  OptionProps,
} from './types';

const DropdownOptionContainer = styled.div<ContainerOptionProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fff;
  z-index: 401;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  ${props => {
    if (props.position) {
      return `
        width: auto;
        min-width: 160px;
      `;
    }

    return `width: ${props.width ? `${props.width}px` : '100%'};`;
  }}
  ${props => {
    switch (props.direction) {
      case 'top':
        if (props.position === 'right') {
          return `
            bottom: 100%;
            right: 0;
          `;
        }
        return `
          bottom: 100%;
          left: 0;
        `;
      case 'right':
        return `
          top: 0;
          left: 100%;
        `;
      case 'bottom':
        if (props.position === 'right') {
          return `
            top: 100%;
            right: 0;
          `;
        }
        return `
          top: 100%;
          left: 0;
        `;
      case 'left':
        return `
          top: 0;
          right: 100%;
        `;
      default:
        return '';
    }
  }}
`;

const DropdownButton = styled(props => (
  <Button
    {...omit(props, [
      'customColor',
      'textColor',
      'asSelect',
      'isOpen',
      'hover',
    ])}
  />
))<DropdownButtonProps>`
  height: ${props => props.height || '35px'};
  padding: ${props => props.padding || '0 25px'};
  margin: ${props => props.margin || '0'};
  ${props => props.customColor && `background-color: ${props.customColor};`}
  ${props =>
    props.textColor &&
    `
    color: ${props.textColor};
      svg {
        color: ${props.textColor};
      }
    `}
  ${props =>
    !props.hover &&
    `
    &:hover {
      background-color: ${props.customColor || 'inherit'};
    }
  `}
  span {
    white-space: nowrap;
  }
  ${props =>
    props.asSelect &&
    `
    background-color: #fff;
    border: 1px solid ${
      props.isOpen ? props.theme.colors.primary : props.theme.colors.borderColor
    };
    svg {
      color: ${props.theme.colors.placeholderColor};
    }
    &:hover {
      background-color: #fff;
      border-color: ${
        props.isOpen ? props.theme.colors.primary : 'rgba(0, 0, 0, 0.87)'
      };
    }
  `}
`;

const Option: React.FC<OptionProps> = ({ label, icon, onClick }) => {
  return (
    <Button fontSize='13px' onClick={() => onClick()} startIcon={icon}>
      {label}
    </Button>
  );
};

const Dropdown: React.FC<DropdownProps> = ({
  padding,
  height,
  margin,
  options,
  direction,
  label,
  color,
  disabled,
  variant,
  customColor,
  textColor,
  width,
  position,
  hasAnimation,
  showText,
  startIcon,
  closeOnClick = true,
  showCaret = true,
  asSelect = false,
  hover = false,
}) => {
  const node = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  useOnClickOutside(node, () => setOpen(false));

  const transitions = useTransition(showText, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 },
  });

  return (
    <div ref={node} style={{ position: 'relative' }}>
      <DropdownButton
        height={height}
        padding={padding}
        margin={margin}
        disabled={disabled}
        variant={variant}
        onClick={() => {
          setOpen(!open);
        }}
        disableRipple
        disableFocusRipple
        color={color || 'default'}
        endIcon={showCaret && <Icon icon='arrow-icon' size={12} />}
        startIcon={startIcon}
        customColor={customColor}
        textColor={textColor}
        asSelect={asSelect}
        isOpen={open}
        hover={hover}
      >
        {hasAnimation ? (
          transitions.map(
            ({ item, key, props }) =>
              item && (
                <animated.div
                  key={key}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '0 0 0 10px',
                    width: '97px',
                    ...props,
                  }}
                >
                  {label}
                </animated.div>
              )
          )
        ) : (
          <>{label}</>
        )}
      </DropdownButton>
      {open && (
        <DropdownOptionContainer
          direction={direction}
          position={position}
          width={width}
        >
          {options.map(value => {
            if (value.label) {
              return (
                <Option
                  key={value.key}
                  onClick={() => {
                    if (value.onClick) {
                      value.onClick();
                    }
                    if (closeOnClick) {
                      setOpen(false);
                    }
                  }}
                  label={value.label}
                  icon={value.icon}
                />
              );
            }

            return cloneElement(value, {
              onClick: () => {
                if (closeOnClick) {
                  setOpen(false);
                }
                if (value.props.onClick) {
                  value.props.onClick();
                }
              },
            });
          })}
        </DropdownOptionContainer>
      )}
    </div>
  );
};

export default Dropdown;
