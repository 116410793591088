import React, { useEffect, useState } from 'react';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Spinner } from 'components/molecules';
import { FlexContainer, Typography, Divider } from 'components/atoms';
import { getOrganization } from 'services';
import SubscriptionImage from 'assets/images/subscription.png';
import Bubbles from 'assets/images/Bubbles.png';
import Hourglass from 'assets/images/Hourglass.png';
import { PlanType } from './types';

const plans = [
  {
    title: 'Free',
    cost: 'Gratis',
    benefits: [
      // 'Hasta 150 órdenes mensuales',
      // 'Hasta 1 usuario Admin',
      // 'Geocoding',
      // 'Notificaciones via Email, WhatsApp y SMS',
      // 'API integration',
      // 'Analytics',
    ],
  },
  {
    title: 'Grow',
    cost: 'Desde $20',
    benefits: [
      'Usa cuentas propias de operadores logísticos',
      'Usuarios Admin ilimitados',
      'Geocoding',
      'Manejo de inventario y Sincronización',
      'Notificaciones via Email, WhatsApp y SMS',
      'Feedback del cliente',
      'API integration',
      'Analytics',
    ],
  },
  {
    title: 'PRO',
    cost: '',
    benefits: [
      'Órdenes mensuales ilimitadas',
      'Usuarios Admin ilimitados',
      'Geocoding',
      'Manejo de inventario y Sincronización',
      'Notificaciones via Email, WhatsApp y SMS',
      'Integra tu propia flota para envíos inmediatos o programados',
      'Tracking de flota en tiempo real',
      'Feedback del cliente',
      'API integration',
      'Analytics',
    ],
  },
  {
    title: 'Logistic Operator',
    cost: '',
    benefits: [],
  },
];

const BenefitList = styled.ul`
  list-style: none;
  padding: 0;
`;

const Benefit = styled.li`
  position: relative;
  font-size: 16px;
  color: #0a131a;
  vertical-align: middle;
  line-height: 20px;
  margin: 20px 0 20px 50px;

  @media only screen and (min-width: 768px) {
    margin: 22px 0 22px 40px;
  }
`;

const Check = styled.span`
  position: absolute;
  top: -6px;
  left: -50px;
  display: inline-block;
  height: 28px;
  width: 28px;
  line-height: 30px;
  vertical-align: middle;
  border: 1.5px solid #9ed077;
  border-radius: 50%;

  :before {
    height: 1px;
    background-color: #9ed077;
    content: '';
    position: absolute;
    width: 6px;
    transform: rotate(45deg);
    top: 14px;
    left: 6px;
  }

  :after {
    height: 1px;
    background-color: #9ed077;
    content: '';
    position: absolute;
    width: 11px;
    transform: rotate(-45deg);
    top: 12px;
    left: 10px;
  }

  @media only screen and (min-width: 768px) {
    left: -40px;
  }
`;

const ChangePlanButton = styled.span`
  text-decoration: none;
  cursor: pointer;
  color: white;
  padding: 12px 25px;
  border-radius: 5px;
  background-color: #4131d3;
  white-space: nowrap;
`;

const PremiumButton = styled.span`
  text-decoration: none;
  cursor: pointer;
  color: white;
  padding: 12px 25px;
  border-radius: 5px;
  background-color: #f1564f;
  white-space: nowrap;
`;

const BubbleBackground = styled(FlexContainer)`
  width: 100%;
  max-width: 890px;
  background: url('${Bubbles}') center center;
  margin-top: 50px;
  border-radius: 10px;
  overflow: hidden;
  height: 370px;
  background-size: cover;
  flex-wrap: wrap;
  justify: flex-start;
  padding: 70px 50px;
  @media only screen and (min-width: 768px) {
    height: auto;
    flex-wrap: nowrap;
    justify: space-between;
    padding: 20px 60px;
  }
`;

const BannerText = styled(Typography)`
  margin: 0;
  @media only screen and (min-width: 768px) {
    margin: 0 80px 0 40px;
  }
`;

const Subscription: React.FC = () => {
  const branchOfficeId = useTypedSelector(
    state => state.organization.selectedOrganization.id
  );

  const user = useTypedSelector(state => state.user);

  const [organization, fetchOrganization, loadingOrganizations] = useRequest(
    getOrganization
  );

  const [plan, setPlan] = useState<PlanType>({
    title: '',
    cost: '',
    benefits: [],
  });
  const [loadingPlan, setLoadingPlan] = useState<boolean>(false);

  useEffect(() => {
    fetchOrganization(branchOfficeId);
    setLoadingPlan(true);
  }, [branchOfficeId]);

  useEffect(() => {
    if (!isEmpty(organization)) {
      switch (organization.pricing_plan.code) {
        case 'free':
          setPlan(plans[0]);
          break;
        case 'ecommerce_grow':
          setPlan(plans[1]);
          break;
        case 'full':
        case 'ecommerce_pro':
          setPlan(plans[2]);
          break;
        case 'logistic_operator':
          setPlan(plans[3]);
          break;
        default:
          setPlan({ title: '', cost: '', benefits: [] });
          break;
      }
      setLoadingPlan(false);
    }
  }, [organization]);

  return (
    <FlexContainer container padding='40px 80px' justify='center'>
      <FlexContainer direction='column'>
        {loadingOrganizations && loadingPlan ? (
          <FlexContainer
            container
            padding='30px 40px'
            direction='column'
            justify='center'
            alignItems='center'
            height='80vh'
          >
            <Spinner height='350px' />
          </FlexContainer>
        ) : (
          <>
            <FlexContainer container justify='center'>
              <FlexContainer direction='column'>
                <FlexContainer
                  container
                  padding='40px 50px'
                  direction='column'
                  borderRadius='5px'
                  backgroundColor='#FFFFFF'
                  borderColor={THEME.colors.borderColor}
                >
                  <FlexContainer
                    width='100%'
                    justify='space-around'
                    margin='0 0 40px'
                  >
                    <img
                      src={SubscriptionImage}
                      alt='suscription'
                      style={{ width: '210px' }}
                    />

                    <FlexContainer
                      direction='column'
                      margin='0 0 0 20px'
                      alignItems='center'
                    >
                      {plan.cost && (
                        <FlexContainer alignItems='center' margin='0 0 20px'>
                          <Typography fontSize={30}>{plan.cost}</Typography>
                          <Typography fontSize={18} color='text.secondary'>
                            /mes
                          </Typography>
                        </FlexContainer>
                      )}

                      <FlexContainer alignItems='center' margin='0 0 20px'>
                        <Typography
                          fontSize={18}
                          color='text.secondary'
                          margin='0 5px 0 0'
                        >
                          Plan
                        </Typography>
                        <Typography
                          fontSize={25}
                          color='text.secondary'
                          fontWeight={600}
                        >
                          {plan.title}
                        </Typography>
                      </FlexContainer>

                      <ChangePlanButton
                        onClick={() => {
                          window.open(
                            `https://wa.me/51946565757?text=¡Hola! Soy ${user.full_name} de la organización ${organization.business_name} (${organization.id}) y quisiera cambiar de plan por favor.`
                          );
                        }}
                      >
                        Cambiar de plan
                      </ChangePlanButton>
                    </FlexContainer>
                  </FlexContainer>

                  {plan.benefits.length > 0 && (
                    <>
                      <Divider orientation='horizontal' />

                      <FlexContainer
                        container
                        margin='30px 0 0'
                        justify='center'
                      >
                        <BenefitList>
                          {plan.benefits.map(benefit => (
                            <Benefit key={benefit}>
                              <Check />
                              {benefit}
                            </Benefit>
                          ))}
                        </BenefitList>
                      </FlexContainer>
                    </>
                  )}
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>

            {(plan.title === 'Free' || plan.title === 'Grow') && (
              <BubbleBackground alignItems='center'>
                <img src={Hourglass} alt='time' />
                <BannerText color='#FFFFFF' fontSize={14}>
                  Buscas un plan con gestion de inventarios , sucursales o
                  manejo de flota propia? Consulta nuestros planes desde $12/mes
                </BannerText>
                <PremiumButton
                  onClick={() => {
                    window.open(
                      `https://wa.me/51946565757?text=¡Hola! Soy ${user.full_name} de la organización ${organization.business_name} (${organization.id}) y quisiera información sobre el plan Pro por favor.`
                    );
                  }}
                >
                  Contáctanos
                </PremiumButton>
              </BubbleBackground>
            )}
          </>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Subscription;
