import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import axios from 'axios';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useLocation, useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import THEME from 'util/styledTheme';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import { FlexContainer, Typography, Button } from 'components/atoms';
import { FormControl } from 'components/molecules';
import { invitationToRegister, invitationToAccept } from 'services';
import AimoLogoImage from 'assets/images/AimoLogoFull.svg';
import SignUpImage from 'assets/images/signup/SingUpImage.png';
import InvalidLinkImage from 'assets/images/feedback/feedback-bad.svg';

const validationState = {
  email: ['mandatory', 'email'],
  password: ['mandatory'],
};

const SignupContainer = styled(Grid)`
  height: 100vh;
  background-color: white;
`;

const UserInvitationAcceptance = ({ onSubmit, loading }) => (
  <FlexContainer container alignItems='center' justify='center'>
    <FlexContainer direction='column' alignItems='center'>
      <Typography fontSize='18px' fontWeight='700' textAlign='center'>
        Has sido invitado para una organización
      </Typography>
      <Button
        variant='contained'
        color='primary'
        padding='5px 30px'
        margin='20px 0'
        onClick={() => onSubmit()}
        disabled={loading}
      >
        Aceptar Invitación
      </Button>
    </FlexContainer>
  </FlexContainer>
);

const UserInvitationForm = ({
  form,
  onFormChange,
  errors,
  loading,
  onSubmit,
}) => (
  <Grid container spacing={1}>
    <Grid item xs={6}>
      <FlexContainer
        height='100%'
        direction='column'
        alignItems='center'
        borderRadius='10px'
        backgroundColor='#E8E8E8 '
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer
          direction='column'
          justify='center'
          alignItems='center'
          height='100%'
        >
          <img
            src={AimoLogoImage}
            alt='aimo-logo'
            width='160px'
            height='28px'
            style={{ margin: '40px 0' }}
          />
          <img
            src={SignUpImage}
            alt='signup'
            style={{ margin: '20px 0 40px 0' }}
          />
          <Typography fontSize='12px' margin='50px 0'>
            © Aimo Privacidad y condiciones
          </Typography>
        </FlexContainer>
      </FlexContainer>
    </Grid>
    <Grid item xs={6}>
      <FlexContainer
        container
        height='100%'
        direction='column'
        alignItems='center'
        justify='center'
        padding='40px 20px'
      >
        <FormControl
          label='Correo electrónico*'
          placeholder='Escribe su correo electrónico'
          onChange={value => onFormChange(value, 'email')}
          value={form.email}
          margin='0 0px 20px'
          width='70%'
          variant='standard'
          disabled
        />
        <FormControl
          label='Contraseña*'
          placeholder='Escriba su contraseña'
          onChange={value => onFormChange(value, 'password')}
          value={form.password}
          type='password'
          margin='20px 0px 20px'
          error={errors.password}
          width='70%'
          disabled={loading}
          variant='standard'
        />

        <FlexContainer
          width='70%'
          justify='center'
          padding='20px 0'
          direction='column'
          alignItems='center'
        >
          <Button
            fullWidth
            height='45px'
            borderRadius='16px'
            variant='contained'
            color='primary'
            onClick={() => onSubmit()}
            margin='50px 0 0'
          >
            <Typography
              fontWeight='700'
              fontSize='15px'
              color='white'
              letterSpacing='0.5px'
            >
              Enviar
            </Typography>
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Grid>
  </Grid>
);

const ErrorScreen: React.FC = () => (
  <FlexContainer container padding='30px' justify='center' alignItems='center'>
    <FlexContainer
      direction='column'
      width='400px'
      height='400px'
      alignItems='center'
      justify='center'
    >
      <Typography fontSize={14} margin='20px 0' color={THEME.colors.error}>
        El link ingresado no es válido
      </Typography>
      <img
        src={InvalidLinkImage}
        alt='sad face'
        style={{ marginRight: '10px' }}
      />
    </FlexContainer>
  </FlexContainer>
);

const UserInvitationRegistration: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors, , updateForm] = useForm(
    {
      email: '',
      password: '',
    },
    validationState
  );
  // API calls
  const [
    registeredUser,
    registerUser,
    loadingRegister,
    ,
    errorRegisteredUser,
  ] = useRequest(invitationToRegister);
  const [
    acceptedInvitation,
    acceptInvitation,
    loadingAccept,
    ,
    errorResponseAccept,
  ] = useRequest(invitationToAccept);
  // Values
  const [registerToken, setRegisterToken] = useState('');
  const [showForm, setShowForm] = useState<null | boolean>(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const isNewUser = searchParams.get('new');
    const parseShowForm = JSON.parse(isNewUser);
    setRegisterToken(token);
    setShowForm(parseShowForm);
  }, [location]);

  useEffect(() => {
    if (!isEmpty(registerToken)) {
      const splittedToken = registerToken.split(':');
      axios
        .get('https://ipapi.co/json/')
        .then(response => {
          const { data } = response;
          updateForm({
            country_id: data.country_code_iso3.toLowerCase(),
            phone_country: data.country_code.toLowerCase(),
            email: splittedToken[1],
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [registerToken]);

  useEffect(() => {
    if (!isEmpty(registeredUser)) {
      enqueueSnackbar('El usuario ha sido registrado satisfactoriamente.', {
        variant: 'success',
      });
      history.push('/login');
    }
  }, [registeredUser, enqueueSnackbar]);

  useEffect(() => {
    if (!isEmpty(acceptedInvitation)) {
      enqueueSnackbar('El usuario ha sido registrado satisfactoriamente.', {
        variant: 'success',
      });
    }
  }, [acceptedInvitation, enqueueSnackbar]);

  useEffect(() => {
    if (errorResponseAccept) {
      const apiError = errorResponseAccept.data.errors[0];
      enqueueSnackbar(apiError, {
        variant: 'error',
      });
    }
  }, [errorResponseAccept, enqueueSnackbar]);

  useEffect(() => {
    if (!isEmpty(errorRegisteredUser)) {
      if (errorRegisteredUser.data.errors[0] === 'The token not exists') {
        enqueueSnackbar(
          'El link de invitación ha expirado, tiene que volver a solicitarlo',
          {
            variant: 'error',
          }
        );
      }
    }
  }, [errorRegisteredUser]);

  const validateForm = () => {
    if (validate()) {
      const newUser = {
        token: registerToken,
        password: form.password,
      };
      registerUser(newUser);
    }
  };

  const handleAcceptInvitation = () => {
    acceptInvitation({
      token: registerToken,
    });
  };

  const showInvitation = (invitation: null | boolean) => {
    switch (invitation) {
      case true:
        return (
          <UserInvitationForm
            form={form}
            onFormChange={onFormChange}
            errors={errors}
            onSubmit={validateForm}
            loading={loadingRegister}
          />
        );
      case false:
        return (
          <UserInvitationAcceptance
            onSubmit={handleAcceptInvitation}
            loading={loadingAccept}
          />
        );
      default:
        return <ErrorScreen />;
    }
  };

  return (
    <SignupContainer container>{showInvitation(showForm)}</SignupContainer>
  );
};

export default UserInvitationRegistration;
