import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { Dialog } from '@material-ui/core';
import { Button, FlexContainer, Typography, Icon } from 'components/atoms';
import { Dropdown, DropdownIconOption } from 'components/molecules';
import { DataTable } from 'components/organisms';
import { getTimeWindows } from 'services';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import CreateTimeWindows from './Create';

const TimeWindowsList: React.FC = () => {
  const user = useTypedSelector(state => state.user);
  // API calls
  const [
    timeWindows,
    fetchTimeWindows,
    loadingTimeWindows,
    pageData,
  ] = useRequest(getTimeWindows, []);

  // Values
  const [page, setPage] = useState<number>(1);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [timeWindowsList, setTimeWindowsList] = useState([]);
  const [editData, setEditData] = useState(null);

  const requestTimeWindows = useCallback(() => {
    const params = {
      page,
    };

    fetchTimeWindows(params);
  }, [fetchTimeWindows, page]);

  const settingsOptions = [
    {
      label: 'Editar',
      name: 'edit',
      icon: 'edit-icon',
      onClick: row => {
        setEditData(row);
        setOpenEdit(true);
      },
    },
  ];

  useEffect(() => {
    requestTimeWindows();
  }, [page]);

  useEffect(() => {
    if (!isEmpty(timeWindows)) {
      setTimeWindowsList(timeWindows);
    }
  }, [timeWindows]);

  const option = row =>
    settingsOptions.map(setting => (
      <DropdownIconOption
        key={setting.name}
        label={setting.label}
        name={setting.name}
        icon={setting.icon}
        onClick={() => setting.onClick(row)}
      />
    ));

  const headers = useMemo(
    () => [
      {
        label: <FlexContainer justify='center'>Hora de Inicio</FlexContainer>,
        id: 'start_hour',
        cell: row => (
          <FlexContainer justify='center'>{row.start_hour || ''}</FlexContainer>
        ),
      },
      {
        label: <FlexContainer justify='center'>Hora de Fin</FlexContainer>,
        id: 'end_hour',
        cell: row => (
          <FlexContainer justify='center'>{row.end_hour || ''}</FlexContainer>
        ),
      },
      {
        label: <FlexContainer justify='center' />,
        id: 'settings',
        cell: row =>
          user.permissions.includes('core.time_window.update') && (
            <FlexContainer width='40%' justify='center' margin='0 0 0 40px'>
              <Dropdown
                label={<Icon icon='options-icon' size={20} />}
                direction='bottom'
                position='left'
                showCaret={false}
                options={option(row)}
                padding='0 10px'
                hover
              />
            </FlexContainer>
          ),
      },
    ],
    []
  );

  return (
    <FlexContainer container padding='30px 40px' direction='column'>
      <FlexContainer
        container
        alignItems='center'
        justify='space-between'
        margin='0 0 30px'
      >
        <Typography fontSize={18} fontWeight={300}>
          Ventanas Horarias
        </Typography>
        {user.permissions.includes('core.time_window.create') && (
          <Button
            color='primary'
            margin='0 0 0 20px'
            fontSize='13px'
            startIcon={<Icon icon='plus-icon' size={18} />}
            onClick={() => setOpenCreate(true)}
          >
            Crear Ventana Horaria
          </Button>
        )}
      </FlexContainer>

      <FlexContainer container justify='center'>
        <FlexContainer
          width='70%'
          direction='column'
          alignItems='center'
          margin='0 40px 0 0'
        >
          <DataTable
            headers={headers}
            data={timeWindowsList}
            onChangePage={selectedPage => setPage(selectedPage)}
            totalPages={pageData.totalPages}
            totalItems={pageData.totalItems}
            pageSize={pageData.pageSize}
            page={page}
            loading={loadingTimeWindows}
          />
        </FlexContainer>
      </FlexContainer>

      <Dialog
        fullWidth
        maxWidth='sm'
        open={openCreate || openEdit}
        onClose={() => {
          setOpenCreate(false);
          setOpenEdit(false);
          setEditData(null);
        }}
      >
        <CreateTimeWindows
          onCreate={useCallback(() => requestTimeWindows(), [
            requestTimeWindows,
          ])}
          onClose={() => {
            setOpenCreate(false);
            setOpenEdit(false);
            setEditData(null);
          }}
          data={editData}
        />
      </Dialog>
    </FlexContainer>
  );
};

export default TimeWindowsList;
