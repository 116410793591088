import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import useRequest from 'hooks/useRequest';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@material-ui/core';
import { Button, FlexContainer, Typography, Switch } from 'components/atoms';
import { FormControl } from 'components/molecules';
import THEME from 'util/styledTheme';
import { useTypedSelector } from 'store';
import {
  getWebHooksEvents,
  getOrganization,
  patchOrganization,
  getClient,
  patchClient,
} from 'services';
import { getLocalStorageClientId } from 'util/functions';

const WebhooksListSkeleton: React.FC = () => (
  <FlexContainer
    direction='column'
    height='180px'
    width='400px'
    justify='space-between'
  >
    <Skeleton variant='text' width='200px' height='50px' />
    <Skeleton variant='text' width='200px' height='50px' />
    <Skeleton variant='text' width='200px' height='50px' />
  </FlexContainer>
);
getLocalStorageClientId();
const WebhooksForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedOrganization = useTypedSelector(
    state => state.organization.selectedOrganization
  );
  // API calls
  const [webhooks, fetchWebhooks, loading] = useRequest(getWebHooksEvents, []);
  const [organization, fetchOrganization, loadingOrganization] = useRequest(
    getOrganization
  );
  const [client, fetchClient, loadingClient] = useRequest(getClient);
  const [
    updatedOrganization,
    updateOrganization,
    loadingUpdate,
    ,
    errorResponse,
  ] = useRequest(patchOrganization);
  const [
    updatedClient,
    updateClient,
    loadingUpdateClient,
    ,
    errorResponseClient,
  ] = useRequest(patchClient);
  // Values
  const [eventList, setEventList] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState<Array<string>>([]);
  const [url, setUrl] = useState<string>('');
  const [secret, setSecret] = useState<string>('');
  const [secretValue, setSecretValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    fetchWebhooks();

    const clientId: number | null = getLocalStorageClientId();

    if (clientId !== null) {
      fetchClient(getLocalStorageClientId());
    }

    fetchOrganization(selectedOrganization.id);
  }, [fetchWebhooks, fetchOrganization, fetchClient]);

  useEffect(() => {
    if (!isEmpty(webhooks)) {
      setEventList(webhooks);
    }
  }, [webhooks]);

  useEffect(() => {
    if (!isEmpty(organization) && isEmpty(client)) {
      setUrl(organization.webhook_url);
      setSecret(organization.webhook_secret);
      setSelectedEvents(organization.webhook_events);
    }
  }, [organization]);

  useEffect(() => {
    if (!isEmpty(client)) {
      setUrl(client.webhook_url);
      setSecret(client.webhook_secret);
      setSelectedEvents(client.webhook_events);
    }
  }, [client]);

  useEffect(() => {
    if (!isEmpty(updatedOrganization) || !isEmpty(updatedClient)) {
      enqueueSnackbar('Se actualizaron los eventos', {
        variant: 'success',
      });
    }
  }, [updatedOrganization, updatedClient]);

  useEffect(() => {
    if (errorResponse) {
      const apiError = errorResponse.data.errors[0];
      const firstError = Object.keys(apiError);
      const errorMessage = apiError[`${firstError}`][0];
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    } else if (errorResponseClient) {
      const apiError = errorResponseClient.data.errors[0];
      const firstError = Object.keys(apiError);
      const errorMessage = apiError[`${firstError}`][0];
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    }
  }, [errorResponse, errorResponseClient, enqueueSnackbar]);

  const validateForm = () => {
    if (url !== '') {
      if (secretValue) {
        const withSecretBody = {
          webhook_url: url,
          webhook_secret: secretValue,
          webhook_events: selectedEvents,
        };
        if (!isEmpty(client)) {
          updateClient(withSecretBody, client.id);
        } else {
          updateOrganization(withSecretBody, organization.id);
        }
      } else {
        const body = {
          webhook_url: url,
          webhook_events: selectedEvents,
        };
        if (!isEmpty(client)) {
          updateClient(body, client.id);
        } else {
          updateOrganization(body, organization.id);
        }
      }
    } else {
      setError('Campo obligatorio');
    }
  };

  const handleSwitchChange = value => {
    if (selectedEvents.includes(value)) {
      const newSelected = selectedEvents.filter(event => value !== event);
      setSelectedEvents(newSelected);
    } else {
      const newSelected = selectedEvents.concat(value);
      setSelectedEvents(newSelected);
    }
  };

  return (
    <FlexContainer container margin='10px 20px'>
      <FlexContainer
        container
        // padding={user.onboarding ? '95px 0 0' : '50px 0 0'}
        height='100%'
      >
        <FlexContainer container padding='30px 40px' direction='column'>
          <Typography fontSize={18} fontWeight={300} margin='0 0 30px'>
            Webhooks
          </Typography>
          <FlexContainer
            container
            padding='40px 50px'
            direction='column'
            borderRadius='5px'
            backgroundColor='#FFFFFF'
            borderColor={THEME.colors.borderColor}
          >
            <FlexContainer direction='column' width='400px'>
              <FormControl
                label='Webhook URL*'
                placeholder='Escriba la webhook url'
                onChange={value => {
                  setUrl(value);
                  setError('');
                }}
                value={url}
                margin='0 0 20px'
                width='100%'
                disabled={
                  loading ||
                  loadingUpdate ||
                  loadingOrganization ||
                  loadingClient
                }
                labelSize={14}
                labelWeight={700}
                error={error}
              />
              <FormControl
                label='Webhook Secret (optional)'
                placeholder={secret || 'Escriba el webhook secret'}
                onChange={value => setSecretValue(value)}
                value={secretValue}
                margin='0 0 20px'
                width='100%'
                disabled={
                  loading ||
                  loadingUpdate ||
                  loadingOrganization ||
                  loadingClient
                }
                labelSize={14}
                labelWeight={700}
              />
              <Typography fontSize={14} fontWeight={700} margin='10px 0'>
                Webhooks Events
              </Typography>
              {loading ? (
                <WebhooksListSkeleton />
              ) : (
                eventList.map(event => (
                  <Grid container spacing={3} key={event.name}>
                    <FlexContainer
                      container
                      margin='15px'
                      alignItems='space-around'
                    >
                      <Grid
                        container
                        alignItems='center'
                        justify='space-between'
                      >
                        <Typography
                          color={THEME.colors.textSecondary}
                          margin='0 0 0 10px'
                        >
                          {event.description}
                        </Typography>
                        <Switch
                          value={event.name}
                          checked={
                            selectedEvents &&
                            selectedEvents.includes(event.name)
                          }
                          onChange={e => handleSwitchChange(e.target.value)}
                          disabled={loading || loadingUpdate}
                        />
                      </Grid>
                    </FlexContainer>
                  </Grid>
                ))
              )}
            </FlexContainer>
            <Button
              variant='contained'
              color='primary'
              padding='5px 30px'
              margin='20px 0 0 0'
              onClick={() => validateForm()}
              disabled={loading}
            >
              Guardar
            </Button>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default WebhooksForm;
