import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useTypedSelector } from 'store';
import { FlexContainer } from 'components/atoms';
import { Header, SettingSidebar } from 'components/organisms';
import evalPermission from 'util/evalPermission';
import YourAccount from './YourAccount';
import Tokens from './Tokens';
import LogisticOperators from './LogisticOperators';
import BranchOffice from './BranchOffice';
import ClientExperience from './ClientExperience';
import Webhooks from './Webhooks';
import Payments from './Payments';
import Channels from './Channels';

const Settings: React.FC = () => {
  const showMenu = useTypedSelector(state => state.sidebar);
  const user = useTypedSelector(state => state.user);

  return (
    <FlexContainer container height='100%'>
      <SettingSidebar />
      <FlexContainer container margin='0 0 0 200px'>
        <Header settingBar showMenu={showMenu} />
        <FlexContainer container margin='60px 0 0'>
          <Switch>
            <Route
              exact
              path='/configuracion'
              render={() => {
                if (user.role === 'client') {
                  return <Redirect to='/configuracion/experiencia' />;
                }
                return <Redirect to='/configuracion/cuenta' />;
              }}
            />

            <Route path='/configuracion/cuenta' component={YourAccount} />
            <Route path='/configuracion/tokens' component={Tokens} />
            <Route
              path='/configuracion/entregas'
              component={LogisticOperators}
            />
            <Route path='/configuracion/sucursales' component={BranchOffice} />
            <Route
              path='/configuracion/experiencia'
              component={ClientExperience}
            />

            <Route
              exact
              path='/configuracion/webhooks'
              render={() =>
                evalPermission(
                  user.permissions,
                  'core.organization.retrieve',
                  Webhooks
                )
              }
            />

            <Route exact path='/configuracion/pagos' component={Payments} />
            {/* <Route
              exact
              path='/configuracion/canales-de-venta'
              render={() =>
                evalPermission(
                  user.permissions,
                  'core.sales_channel.retrieve',
                  Channels
                )
              }
            /> */}
          </Switch>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Settings;
