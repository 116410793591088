import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexContainer, Typography } from 'components/atoms';
import bubbleMessage from 'assets/images/bubble-message.png';

const PhoneImage = styled.div<{ image: any }>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-image: url(${props => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 360px;
  height: 600px;
  align-items: center;
`;

const TextContainer = styled(FlexContainer)`
  display: flex;
  flex-wrap: wrap;
  width: 290px;
  height: 163px;
`;

const StyledText = styled(Typography)`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  width: 240px;
  word-break: break-word;
`;

// const OrganizationName = styled(StyledText)`
//   font-weight: bold;
// `;

// const LinkText = styled(Typography)`
//   text-decoration: underline;
//   color: #4131D3;
// `;

const PhoneTemplates: React.FC<{
  data?: any;
  setData?: any;
  organizationName?: string;
}> = ({ data, organizationName }) => {
  const [notificationText, setNotificationText] = useState('');
  // const [trackingLink, setTrackingLink] = useState('');
  // const [organizationNameText, setOrganizationNameText] = useState('');

  const replaceWordsWith = {
    '{TRACKING_LINK}': 'https://tracking.aimo.co/M0tGUEMzRzE=',
    '{CLIENT_NAME}': organizationName,
    '{CODE}': 'BV48DK0U',
    '{REFERENCE_CODE}': `1000001`,
    '{FEEDBACK_LINK}': 'https://tracking.aimo.co/feedback/RUZNR05TRVg=',
  };

  useEffect(() => {
    if (data.notificationMessage) {
      if (
        data.notificationMessage.match(
          /{TRACKING_LINK}|{CLIENT_NAME}|{CODE}|{REFERENCE_CODE}|{FEEDBACK_LINK}/g
        )
      ) {
        const newNotificationText = data.notificationMessage.replace(
          /{TRACKING_LINK}|{CLIENT_NAME}|{CODE}|{REFERENCE_CODE}|{FEEDBACK_LINK}/g,
          matched => {
            return replaceWordsWith[matched];
          }
        );
        setNotificationText(newNotificationText);
      } else {
        setNotificationText(data.notificationMessage);
      }
    }
  }, [data]);

  return (
    <FlexContainer
      container
      height='100%'
      margin='0 30px'
      alignItems='center'
      justify='center'
      direction='column'
      backgroundColor='#ffffff'
    >
      <FlexContainer justify='center'>
        <FlexContainer position='absolute' width='360px'>
          <PhoneImage image={bubbleMessage}>
            <TextContainer padding='10px'>
              <FlexContainer
                container
                direction='column'
                alignItems='center'
                wrap='wrap'
              >
                <StyledText margin='10px 0'>
                  {notificationText}
                  {/* {trackingLink && (
                    <LinkText>{trackingLink}</LinkText>
                  )} */}
                  {/* {organizationNameText && (
                    <OrganizationName>{organizationNameText}</OrganizationName>
                  )} */}
                </StyledText>
              </FlexContainer>
            </TextContainer>
          </PhoneImage>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default PhoneTemplates;
