import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { patchOrganization } from 'services';
import {
  Button,
  FlexContainer,
  Icon,
  Typography,
  Switch,
} from 'components/atoms';
import { FormControl, Spinner } from 'components/molecules';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const validationState = {
  name: ['mandatory'],
  description: ['description', 'mandatory'],
};

const EditMethod: React.FC<{
  onUpdate: () => void;
  onClose: () => void;
  method: any;
  organizationId: number;
}> = ({ onClose, onUpdate, method, organizationId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors] = useForm(
    {
      name: method.name,
      description: method.description,
      isActive: method.isActive,
    },
    validationState
  );

  // API calls
  const [
    updatedOrganization,
    updateOrganization,
    loadinngUpdate,
    ,
    errorUpdate,
  ] = useRequest(patchOrganization);

  useEffect(() => {
    if (!isEmpty(updatedOrganization)) {
      enqueueSnackbar('La descripción se actualizó correctamente', {
        variant: 'success',
      });
      onUpdate();
    }
  }, [updatedOrganization, enqueueSnackbar]);

  useEffect(() => {
    if (!isEmpty(errorUpdate)) {
      if (!isEmpty(errorUpdate.data.errors)) {
        errorUpdate.data.errors.forEach(error => {
          if (
            error.immediate_description &&
            error.immediate_description[0] === 'This field may not be blank.'
          ) {
            enqueueSnackbar('Descripción no puede estar vacío', {
              variant: 'error',
            });
          }
        });
      }
    }
  }, [errorUpdate, enqueueSnackbar]);

  const validateForm = () => {
    if (validate()) {
      let data = {};

      switch (method.id) {
        case 1:
          data = {
            immediate_description: form.description,
            is_immediate_active: form.isActive,
          };
          break;
        case 2:
          data = {
            programmed_description: form.description,
            is_programmed_active: form.isActive,
          };
          break;
        default:
          data = null;
          break;
      }
      updateOrganization(data, organizationId);
    }
  };

  return (
    <FlexContainer container direction='column' padding='20px 40px'>
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <FlexContainer container justify='center' margin='20px 0 30px'>
        <Typography color={THEME.colors.primary} fontSize={18}>
          Editar método de delivery
        </Typography>
      </FlexContainer>

      <FlexContainer container direction='column'>
        <FormControl
          label='Nombre'
          placeholder='Escribe el nombre'
          onChange={value => onFormChange(value, 'name')}
          value={form.name}
          margin='0 0 30px'
          error={errors.name}
          width='100%'
          disabled
        />
        <FormControl
          control='textarea'
          label='Descripción'
          placeholder='Escribe la decripción'
          onChange={value => onFormChange(value, 'description')}
          value={form.description}
          margin='0 0 20px'
          error={errors.description}
          width='100%'
          disabled={loadinngUpdate}
        />
        <FlexContainer alignItems='center'>
          <Typography>Activado:</Typography>
          <Switch
            color='primary'
            checked={form.isActive}
            onChange={event => onFormChange(event.target.checked, 'isActive')}
          />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer container justify='center' margin='30px 0 20px'>
        <Button
          variant='contained'
          color='primary'
          padding='5px 30px'
          onClick={() => validateForm()}
          disabled={loadinngUpdate}
        >
          {loadinngUpdate && (
            <Spinner height='15px' spinnerSize={12} margin='0 5px 0 0' />
          )}
          Guardar
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default EditMethod;
