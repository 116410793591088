import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import THEME from 'util/styledTheme';
import { FlexContainer, Icon, Typography, Input } from 'components/atoms';
import { MultipleInputProps, PillProps } from './types';

const CustomInputContainer = styled(FlexContainer)`
  width: 100%;
  .MuiOutlinedInput-root {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const InputError = styled(FlexContainer)`
  top: -12px;
  right: -15px;
  z-index: 1;
`;

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,9})+$/;

const DeletablePill: React.FC<PillProps> = ({ onDelete, item }) => {
  return (
    <FlexContainer
      justify='center'
      alignItems='center'
      backgroundColor={THEME.colors.primary}
      padding='0 10px'
      margin='8px 10px 2px 0'
      borderRadius='5px'
    >
      {item.name ? (
        <Typography color='#FFFFFF' margin='0 15px 4px 0' fontSize='13px'>
          {item.name}
        </Typography>
      ) : (
        <Typography color='#FFFFFF' margin='0 15px 4px 0' fontSize='13px'>
          {item}
        </Typography>
      )}
      <div style={{ transform: 'rotate(45deg)' }}>
        <Icon
          icon='plus-icon'
          size={20}
          onClick={e => {
            e.stopPropagation();
            onDelete(item);
          }}
          color='#FFFFFF'
        />
      </div>
    </FlexContainer>
  );
};

const MultipleInput: React.FC<MultipleInputProps> = ({
  inputValue,
  onChangeInput,
  onSubmit,
  disabled,
  values,
  placeholder,
  onDelete,
  error,
}) => {
  const node = useRef(null);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  const handleEmailValidation = () => {
    if (!emailRegex.test(inputValue)) {
      setErrorMessage(true);
    } else {
      onSubmit();
    }
  };

  return (
    <FlexContainer
      container
      position='relative'
      direction='column'
      backgroundColor={disabled ? THEME.colors.borderColor : '#FFFFFF'}
      margin='0 0 20px 0'
      alignItems='center'
      justify='center'
    >
      <FlexContainer
        ref={node}
        container
        direction='column'
        position='relative'
      >
        <Input
          borderless
          width='100%'
          color='primary'
          placeholder={placeholder}
          value={inputValue}
          onChange={({ target }) => {
            onChangeInput(target.value);
            setErrorMessage(false);
          }}
          margin='0'
          padding='0 12px'
          onKeyUp={event => {
            if (event.keyCode === 13) {
              handleEmailValidation();
            }
          }}
        />
        <CustomInputContainer
          container
          flex='1'
          wrap='wrap'
          alignItems='center'
          backgroundColor={THEME.colors.backgroundColor}
        >
          <Typography margin='8px 10px 0 0 '>Emails:</Typography>
          {values.map(item => (
            <DeletablePill
              key={item.id || item}
              onDelete={onDelete}
              item={item}
            />
          ))}
        </CustomInputContainer>
      </FlexContainer>
      {errorMessage && (
        <InputError
          borderColor={THEME.colors.error}
          backgroundColor={THEME.colors.errorBg}
          padding='2px 5px'
          position='absolute'
          borderRadius='4px'
        >
          <Typography fontSize={11} color={THEME.colors.error}>
            Email Inválido
          </Typography>
        </InputError>
      )}
      {error && (
        <InputError
          borderColor={THEME.colors.error}
          backgroundColor={THEME.colors.errorBg}
          padding='2px 5px'
          position='absolute'
          borderRadius='4px'
        >
          <Typography fontSize={11} color={THEME.colors.error}>
            {error}
          </Typography>
        </InputError>
      )}
    </FlexContainer>
  );
};

export default MultipleInput;
