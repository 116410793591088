import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  // Button,
  Card,
  FlexContainer,
  Typography,
  Icon,
  Status,
} from 'components/atoms';
import { Spinner } from 'components/molecules';
import { DataTable } from 'components/organisms';
import { Drawer } from '@material-ui/core';
import useRequest from 'hooks/useRequest';
import { getInvoices } from 'services';
import statusColors from 'util/statusColors';
import NoData from 'assets/images/order-not-found.png';
import EditBilling from './Edit';

const StyledLink = styled.a`
  color: #8c8cb1;
  font-size: 13px;
  text-decoration: none;
`;

const Invoice: React.FC = () => {
  // API calls
  const [invoices, fetchInvoices, loadingInvoices, pageData] = useRequest(
    getInvoices,
    []
  );
  // Values
  const [page, setPage] = useState<number>(1);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const getCurrency = currency => {
    switch (currency) {
      case 'PEN':
        return 'S/';
      case 'USD':
        return '$';
      default:
        return '';
    }
  };

  const paymentType = type => {
    switch (type) {
      case 'wire_transfer':
        return 'Transferencia bancaria';
      default:
        return '-';
    }
  };

  const getStatus = status => {
    switch (status) {
      case 'payed':
        return 'Pagado';
      case 'pending':
        return 'Pendiente';
      case 'cacelled':
        return 'Cancelado';
      case 'in_progress':
        return 'En progreso';
      default:
        return '';
    }
  };

  const headers = useMemo(
    () => [
      {
        label: 'Número',
        id: 'number',
        cell: row => row.number,
      },
      {
        label: 'Fecha de Facturación',
        id: 'billing_date',
        cell: row => moment(row.created_at).format('DD/MM/YYYY'),
      },
      {
        label: 'Monto',
        id: 'amount',
        cell: row => `${getCurrency(row.amount_currency)} ${row.amount}`,
      },
      {
        label: 'Tipo de pago',
        id: 'payment_type',
        cell: row => `${paymentType(row.payment.payment_type)}`,
      },
      {
        label: 'Estado',
        id: 'status',
        cell: row => (
          <Status
            color={statusColors[row.status] || statusColors.default}
            label={getStatus(row.status)}
          />
        ),
      },
      {
        label: '',
        id: 'export',
        cell: row => (
          <FlexContainer container alignItems='center' margin='5px 0'>
            <StyledLink
              href={row.url}
              target='_blank'
              rel='noopener noreferrer'
            >
              Exportar
            </StyledLink>
            <Icon icon='download-icon' size={12} margin='0 0 0 10px' />
          </FlexContainer>
        ),
      },
    ],
    [invoices]
  );

  return (
    <FlexContainer container justify='center' padding='50px 0'>
      <FlexContainer
        width='100%'
        maxWidth={invoices.length > 0 ? '900px' : '540px'}
        direction='column'
      >
        {loadingInvoices ? (
          <FlexContainer
            container
            padding='30px 40px'
            direction='column'
            justify='center'
            alignItems='center'
            height='80vh'
          >
            <Spinner height='350px' />
          </FlexContainer>
        ) : (
          <>
            {invoices.length > 0 ? (
              <DataTable
                headers={headers.filter(header => header)}
                data={invoices}
                onChangePage={selectedPage => setPage(selectedPage)}
                totalPages={pageData.totalPages}
                totalItems={pageData.totalItems}
                pageSize={pageData.pageSize}
                page={page}
                loading={loadingInvoices}
              />
            ) : (
              <Card shadow width='100%' padding='60px'>
                <FlexContainer container alignItems='center' direction='column'>
                  <Typography fontSize={20} margin='0 0 20px'>
                    No hay facturas archivadas.
                  </Typography>
                  <img src={NoData} alt='no_data' style={{ width: 280 }} />
                </FlexContainer>
              </Card>
            )}
          </>
        )}
      </FlexContainer>

      <Drawer
        anchor='right'
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <EditBilling
          data={null}
          onClose={() => setOpenDrawer(false)}
          onCreate={() => {}}
        />
      </Drawer>
    </FlexContainer>
  );
};

export default Invoice;
