import styled from 'styled-components';
import { CardProps } from './types';

const Card = styled.div<CardProps>`
  ${props =>
    props.display &&
    `display: ${props.display};
    flex-direction: column;`}
  position: relative;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  ${props => props.shadow && 'box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08);'}
  ${props => props.zIndex && `z-index: ${props.zIndex};`}
  ${props => props.flex && `flex: ${props.flex};`}
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  padding: ${props => props.padding || '0'};
  margin: ${props => props.margin || '0'};
`;

export default Card;
