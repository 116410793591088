import React, { useState } from 'react';
import styled from 'styled-components';
import { FlexContainer, Typography } from 'components/atoms';
import { useTypedSelector } from 'store';
import AimoAccounts from './AimoAccounts';
import Others from './Others';

type ViewType = 'aimo' | 'others';

type TabProps = {
  view: ViewType;
  onClick: (view: ViewType) => void;
  user: any;
};

const list = [
  {
    name: 'Cuentas Aimo',
    value: 'aimo' as const,
    permission: 'core.provider_integration.list',
  },
  {
    name: 'Otras integraciones',
    value: 'others' as const,
    permission: 'core.provider_integration.list',
  },
];

const Option = styled(FlexContainer)<{ active: boolean }>`
  text-decoration: none;
  cursor: pointer;
  ${props =>
    props.active && `border-bottom: 2px solid ${props.theme.colors.primary}`}
`;

const IntegrationsNavigator: React.FC<TabProps> = ({ user, view, onClick }) => {
  return (
    <FlexContainer
      container
      height='100%'
      justify='space-between'
      alignItems='center'
    >
      <FlexContainer alignItems='center'>
        {list.map(option => {
          if (user.permissions.includes(option.permission)) {
            return (
              <FlexContainer
                key={option.name}
                padding='0 30px 0 0'
                onClick={() => onClick(option.value)}
              >
                <Option
                  alignItems='center'
                  padding='14px 0'
                  active={view === option.value}
                >
                  <Typography>{option.name}</Typography>
                </Option>
              </FlexContainer>
            );
          }
          return null;
        })}
      </FlexContainer>
    </FlexContainer>
  );
};

const LogisticOperators: React.FC = () => {
  const [view, setView] = useState<'aimo' | 'others'>('aimo');

  const user = useTypedSelector(state => state.user);

  return (
    <FlexContainer container direction='column' padding='30px 40px'>
      <Typography fontSize={19} fontWeight={600} margin='0 0 10px'>
        Operadores Logísticos
      </Typography>
      <IntegrationsNavigator
        user={user}
        view={view}
        onClick={newView => setView(newView)}
      />
      <FlexContainer
        container
        // padding={user.onboarding ? '95px 0 0' : '50px 0 0'}
        height='100%'
      >
        {view === 'aimo' && <AimoAccounts />}
        {view === 'others' && <Others changeTab={() => setView('aimo')} />}
      </FlexContainer>
    </FlexContainer>
  );
};

export default LogisticOperators;
