import React from 'react';
import styled from 'styled-components';
import { omit } from 'lodash';
import { Avatar, Badge } from '@material-ui/core';
import { FlexContainer, Icon as IconCell, Typography } from 'components/atoms';
import THEME from 'util/styledTheme';
import {
  Active,
  StatusProps,
  IconProps,
  ImageProps,
  AssignedProps,
  LinkProps,
  PillProps,
  OrderProps,
  AvatarProps,
  PillStatusProps,
} from './types';

const StyledLink = styled.a`
  color: inherit;
  font-weight: 600;
`;

const StyledBadge = styled(props => <Badge {...omit(props, ['active'])} />)<
  Active
>`
  .MuiBadge-badge {
    background-color: ${props =>
      props.active ? props.theme.colors.success : props.theme.colors.disabled};
    color: ${props =>
      props.active ? props.theme.colors.success : props.theme.colors.disabled};
    height: 12px;
    width: 12px;
    border-radius: 10px;
  }
`;

export const SingleLine = styled.span`
  white-space: nowrap;
`;

export const LinkeableSingleLine = styled.span`
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const Status: React.FC<StatusProps> = ({ color, children }) => (
  <FlexContainer alignItems='center'>
    <FlexContainer
      width='5px'
      height='5px'
      margin='0 10px 0 0'
      backgroundColor={color || THEME.colors.success}
      borderRadius='20px'
    />
    {children}
  </FlexContainer>
);

export const Icon: React.FC<IconProps> = ({ icon, color, children, size }) => (
  <FlexContainer alignItems='center'>
    <IconCell
      icon={icon}
      size={size || 20}
      margin={children ? '0 10px 0 0' : '0'}
      color={color}
    />
    <SingleLine>{children}</SingleLine>
  </FlexContainer>
);

export const Image: React.FC<ImageProps> = ({
  src,
  size,
  children,
  onClick,
}) => (
  <FlexContainer
    alignItems='center'
    justify={children ? 'flex-start' : 'center'}
  >
    <img
      src={src}
      alt='img_cell'
      height={size || '22'}
      width='auto'
      style={{ marginRight: children ? 10 : 0 }}
    />
    {onClick ? (
      <LinkeableSingleLine onClick={() => onClick()}>
        {children}
      </LinkeableSingleLine>
    ) : (
      <SingleLine>{children}</SingleLine>
    )}
  </FlexContainer>
);

export const Assigned: React.FC<AssignedProps> = ({
  unassignedString,
  message,
  value,
}) => (
  <FlexContainer>
    {value === unassignedString ? (
      <SingleLine>
        <Typography color={THEME.colors.error}>{message}</Typography>
      </SingleLine>
    ) : (
      <>{value}</>
    )}
  </FlexContainer>
);

export const Order: React.FC<OrderProps> = ({
  children,
  backgroundColor,
  colorText,
  borderColor,
}) => (
  <FlexContainer
    height='20px'
    width='20px'
    alignItems='center'
    justify='center'
    backgroundColor={backgroundColor || '#fff'}
    borderColor={borderColor}
    borderRadius='20px'
  >
    <Typography color={colorText}>{children}</Typography>
  </FlexContainer>
);

export const Link: React.FC<LinkProps> = ({ url, children, onClick }) => (
  <SingleLine>
    {onClick ? (
      <LinkeableSingleLine onClick={() => onClick()}>
        {children}
      </LinkeableSingleLine>
    ) : (
      <StyledLink href={url} target='_blank' rel='noopener noreferrer'>
        {children}
      </StyledLink>
    )}
  </SingleLine>
);

export const Pill: React.FC<PillProps> = ({
  backgroundColor,
  textColor,
  children,
}) => (
  <FlexContainer
    backgroundColor={backgroundColor || THEME.colors.secondaryBackground}
    alignItems='center'
    justify='center'
    padding='2px 10px'
    margin='0 5px 0 0'
    borderRadius='5px'
  >
    <SingleLine>
      <Typography color={textColor || '#fff'}>{children}</Typography>
    </SingleLine>
  </FlexContainer>
);

export const UserAvatar: React.FC<AvatarProps> = ({
  avatar,
  active,
  primary,
  secondary,
  onClick,
}) => (
  <FlexContainer alignItems='center'>
    <FlexContainer margin='0 15px 0 0'>
      <StyledBadge
        active={active}
        overlap='circle'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant='dot'
      >
        <Avatar alt='user_img' src={avatar} />
      </StyledBadge>
    </FlexContainer>
    <FlexContainer direction='column'>
      <Typography fontWeight={600} margin={secondary ? '0 0 5px' : '0'}>
        {onClick ? (
          <LinkeableSingleLine onClick={() => onClick()}>
            {primary}
          </LinkeableSingleLine>
        ) : (
          <SingleLine>{primary}</SingleLine>
        )}
      </Typography>
      {secondary && <Typography color='text.secondary'>{secondary}</Typography>}
    </FlexContainer>
  </FlexContainer>
);

export const PillStatus: React.FC<PillStatusProps> = ({
  borderColor,
  circleColor,
  textColor,
  children,
}) => (
  <FlexContainer
    alignItems='center'
    borderColor={borderColor || THEME.colors.primary}
    borderRadius='50px'
    padding='2px 8px'
  >
    <FlexContainer
      width='5px'
      height='5px'
      margin='0 10px 0 0'
      backgroundColor={circleColor || THEME.colors.primary}
      borderColor={THEME.colors.primary}
      borderRadius='100px'
    />
    <Typography
      color={textColor || THEME.colors.primary}
      fontWeight='300'
      fontSize='12px'
    >
      {children}
    </Typography>
  </FlexContainer>
);
