import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import useRequest from 'hooks/useRequest';
import { useTypedSelector } from 'store';
import { getOrganization, getChannels } from 'services';
import { Icon, FlexContainer, Divider, Typography } from 'components/atoms';
import { Dropdown, DropdownIconOption, Spinner } from 'components/molecules';
import useWindowSize from 'hooks/useWindowSize';
import THEME from 'util/styledTheme';
import { clearOrganization } from 'store/organization/actions';
import { logout } from 'store/session/actions';
import { clearUser } from 'store/user/actions';
import toggleMenu from 'store/sidebar/actions';
// import Info from 'assets/images/info_icon.png';
import DefaultLogo from 'assets/images/DefaultLogo.png';
import ViewStore from 'assets/images/view-store-icon.svg';
import HelpIcon from 'assets/images/help-icon.svg';
import PlanIcon from 'assets/images/plan-icon.svg';
import { HeaderProps } from './types';

const HeaderContainer = styled(FlexContainer)<{
  showMenu: boolean;
  settings: boolean;
}>`
  width: ${props =>
    props.showMenu
      ? `calc(100% - ${props.settings ? '200px' : '0px'})`
      : `calc(100% - ${props.settings ? '200px' : '0px'})`};
  position: fixed;
  z-index: 1003;
  transition: all 0.3s;
  overflow: visible;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  @media only screen and (min-width: 768px) {
    width: ${props =>
      props.showMenu
        ? `calc(100% - ${props.settings ? '370px' : '170px'})`
        : `calc(100% - ${props.settings ? '270px' : '70px'})`};
  }
`;

const UserDropdownContainer = styled(FlexContainer)`
  margin: 0 8px 0 0;

  & > div {
    display: flex;
    justify-content: flex-end;
  }
`;

const StoreLink = styled.a`
  color: #7065f0;
  text-decoration: none;
  font-size: 13px;
  line-height: 24px;
`;

const Header: React.FC<HeaderProps> = ({
  showMenu,
  settingBar = false,
  children,
}) => {
  const windowPathname = window.location.pathname;
  const size = useWindowSize();
  const organization = useTypedSelector(state => state.organization);
  const user = useTypedSelector(state => state.user);
  const dispatch = useDispatch();

  const [orgaRetrieve, fetchOrganization] = useRequest(getOrganization);
  const [channels, fetchChannels, loadingChannels] = useRequest(getChannels);

  // Values
  const [storeUrl, setStoreUrl] = useState<string>('');

  useEffect(() => {
    fetchOrganization(organization.selectedOrganization.id);
    fetchChannels();
  }, []);

  useEffect(() => {
    if (!isEmpty(channels)) {
      const digitalCatalog = channels.filter(
        channel => channel.platform === 'digital_catalog'
      );
      setStoreUrl(
        digitalCatalog && digitalCatalog.length > 0
          ? digitalCatalog[0].store
          : ''
      );
    }
  }, [channels]);

  const sessionOptions = [
    {
      label: 'Mi cuenta',
      secondLabel: user.email,
      name: 'account',
      icon: 'user-icon',
      onClick: () => {
        window.open('/configuracion/cuenta', '_self');
      },
    },
    {
      label: 'Ayuda',
      name: 'help',
      icon: 'help-icon',
      onClick: () => {
        window.open(
          'https://www.notion.so/aimoinc/Help-Center-AIMO-88758c8c5335445c8c7088ff052b0e6c'
        );
      },
    },
    {
      label: 'Soporte',
      name: 'support',
      icon: 'whatsapp-icon',
      onClick: () => {
        window.open(
          `https://wa.me/51946565757?text=¡Hola! Soy ${user.full_name} de la organización ${orgaRetrieve.tradename} y requiero soporte`
        );
      },
    },
    {
      label: 'Cerrar sesión',
      name: 'logout',
      icon: 'log-out-icon',
      onClick: () => {
        localStorage.clear();
        dispatch(clearUser());
        dispatch(clearOrganization());
        dispatch(logout());
      },
    },
  ];

  const option = () =>
    sessionOptions.map(session => (
      <>
        <DropdownIconOption
          label={session.label}
          secondLabel={session.secondLabel && session.secondLabel}
          name={session.name}
          icon={session.icon}
          onClick={() => session.onClick()}
        />
      </>
    ));

  return (
    <HeaderContainer
      position='relative'
      settings={settingBar}
      showMenu={showMenu}
      direction='column'
      justify='center'
      backgroundColor='#FFFFFF'
    >
      <FlexContainer
        container
        height='50px'
        // justify={size.width < 768 ? 'space-between' : 'flex-end'}
        justify='space-between'
        alignItems='center'
        padding={size.width > 768 ? '0 20px' : '5px 0'}
        style={{
          borderBottom:
            size.width > 768 ? 'none' : `1px solid ${THEME.colors.borderColor}`,
        }}
      >
        {size.width > 768 ? (
          children
        ) : (
          <FlexContainer width='40px'>
            <Icon
              icon='bars-icon'
              color={THEME.colors.textSecondary}
              size={20}
              margin='0 0 0 20px'
              onClick={() => dispatch(toggleMenu(!showMenu))}
            />
          </FlexContainer>
        )}

        <FlexContainer
          container
          justify='flex-end'
          height='100%'
          alignItems='center'
        >
          {!windowPathname.includes('/monitoreo') && (
            <>
              {loadingChannels ? (
                <FlexContainer margin='0 15px 0 0'>
                  <Spinner spinnerSize={18} />
                </FlexContainer>
              ) : (
                <FlexContainer
                  container
                  justify='flex-end'
                  alignItems='center'
                  margin='0 10px 0 0'
                >
                  {storeUrl !== '' && (
                    <StoreLink href={`https://${storeUrl}`} target='_blank'>
                      <FlexContainer
                        backgroundColor='#F9FAFB'
                        padding='5px 10px'
                        borderRadius='8px'
                      >
                        <img
                          src={ViewStore}
                          alt='view store'
                          style={{ width: 21, marginRight: 5 }}
                        />
                        Ver tienda
                      </FlexContainer>
                    </StoreLink>
                  )}
                  {size.width > 768 && (
                    <FlexContainer alignItems='center' margin='0 0 0 10px'>
                      <img src={PlanIcon} alt='plan' />
                      <Typography margin='0 5px 0 4px' color='#808494'>
                        Tu plan:
                      </Typography>
                      <Typography color='#000929' fontWeight={500}>
                        {organization.selectedOrganization.planName}
                      </Typography>
                    </FlexContainer>
                  )}
                </FlexContainer>
              )}
            </>
          )}
          <UserDropdownContainer height='100%' alignItems='center'>
            <Dropdown
              label={
                <img
                  src={organization.selectedOrganization.photo || DefaultLogo}
                  height='35px'
                  width='auto'
                  alt='enterprise_logo'
                  style={{
                    marginRight: '7px',
                  }}
                />
              }
              direction='bottom'
              showCaret
              options={option()}
              padding='0 10px'
              position='right'
            />
          </UserDropdownContainer>
          <Divider
            orientation='vertical'
            color='#E4E8F7'
            style={{ height: '60%' }}
          />
          <FlexContainer height='100%' alignItems='center'>
            <a
              rel='noopener noreferrer'
              href='https://www.notion.so/aimoinc/Help-Center-AIMO-88758c8c5335445c8c7088ff052b0e6c'
              target='_blank'
            >
              <img
                src={HelpIcon}
                alt='help'
                style={{ margin: '4px 0 0 10px', width: 18 }}
              />
            </a>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      {size.width <= 768 && children}

      {/* {user.onboarding && (
        <FlexContainer
          container
          alignItems='center'
          justify='center'
          backgroundColor='#e7e7fd'
          padding='5px 0'
        >
          <img
            src={Info}
            alt='info_icon'
            style={{
              width: 35,
              height: 35,
            }}
          />
          <Typography color='text.primary' fontSize='13px' margin='0 10px'>
            {`¡Hey! Aún no haz completado la configuración de tu cuenta. Revisa y
            actualiza todos los datos para empezar. `}
            <Link
              to={{
                pathname: '/onboarding',
                state: {
                  fromLink: true,
                },
              }}
            >
              Terminar ahora
            </Link>
          </Typography>
        </FlexContainer>
      )} */}
    </HeaderContainer>
  );
};

export default Header;
