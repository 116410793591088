import React, { useEffect, useState } from 'react';
import { FlexContainer, Button, Icon } from 'components/atoms';
import useWindowSize from 'hooks/useWindowSize';
import { MenuProps } from './types';

const Menu: React.FC<MenuProps> = ({ selected, step, toStep, hasChanges }) => {
  const size = useWindowSize();
  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    switch (step) {
      case 'orders':
        setLabel('Siguiente');
        break;
      case 'parameters':
        setLabel('Optimizar');
        break;
      case 'preview':
        setLabel(hasChanges ? 'Actualizar Rutas' : 'Confirmar Rutas');
        break;
      default:
        break;
    }
  }, [step, hasChanges]);

  const handleChangeStep = (goBack?: boolean) => {
    switch (step) {
      case 'orders':
        toStep(goBack ? '' : 'parameters');
        break;
      case 'parameters':
        toStep(goBack ? 'orders' : 'preview');
        break;
      case 'preview':
        // eslint-disable-next-line no-nested-ternary
        toStep(goBack ? 'parameters' : hasChanges ? 'update' : 'create');
        break;
      default:
        break;
    }
  };

  return (
    <FlexContainer
      container
      height='100%'
      alignItems='center'
      justify='space-between'
    >
      <FlexContainer
        alignItems='center'
        padding={size.width > 768 ? '10px 40px' : '10px'}
      >
        <Icon
          icon='left-arrow-icon'
          size={18}
          margin='0 10px 0 0'
          onClick={() => handleChangeStep(true)}
        />
        {`${selected} órdenes seleccionadas`}
      </FlexContainer>
      <Button
        height='100%'
        variant='contained'
        color='primary'
        fontSize='16px'
        borderRadius='0'
        padding='0 40px'
        onClick={() => handleChangeStep()}
      >
        {label}
      </Button>
    </FlexContainer>
  );
};

export default Menu;
