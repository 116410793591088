import React from 'react';
import { FlexContainer } from 'components/atoms';
import { Header } from 'components/organisms';
import { useTypedSelector } from 'store';
import { Route, Switch } from 'react-router-dom';
import ImportOrders from './ImportOrders';

const Shopify: React.FC = () => {
  const showMenu = useTypedSelector(state => state.sidebar);
  const user = useTypedSelector(state => state.user);
  return (
    <FlexContainer container height='100%'>
      <Header showMenu={showMenu} />
      <FlexContainer container padding='60px 0 0' height='100vh'>
        <Switch>
          <Route exact path='/shopify/import_orders' component={ImportOrders} />
        </Switch>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Shopify;
