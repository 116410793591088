/* eslint-disable camelcase */
export interface BranchOffices {
  id: number | string;
  name: string;
  reference_code?: string;
}

export interface TypeUsers {
  id: string;
  name: string;
  branchOffices: Array<BranchOffices>;
}

export interface SelectedOrganization {
  id: number;
  name: string;
  photo: string;
  typeUser: TypeUsers;
  slug: string;
  plan?: string;
  planName?: string;
}

export const ORGANIZATION_CLEAR = 'ORGANIZATION_CLEAR';
export const ORGANIZATION_SELECT = 'ORGANIZATION_SELECT';
export const BRANCH_OFFICE_SELECT = 'BRANCH_OFFICE_SELECT';

interface OrganizationClear {
  type: typeof ORGANIZATION_CLEAR;
}

interface OrganizationSelect {
  type: typeof ORGANIZATION_SELECT;
  payload: SelectedOrganization;
}

interface BranchOfficeSelect {
  type: typeof BRANCH_OFFICE_SELECT;
  payload: BranchOffices;
}

export type OrganizationActionTypes =
  | BranchOfficeSelect
  | OrganizationClear
  | OrganizationSelect;
