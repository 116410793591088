import React, { useEffect, useRef, useState } from 'react';
import { find } from 'lodash';
import useOnClickOutside from 'use-onclickoutside';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import { FlexContainer, Icon, Typography } from 'components/atoms';
import THEME from 'util/styledTheme';
import { LabelProps, SelectProps, PillProps, ContainerProps } from './types';

const Container = styled.div<ContainerProps>`
  display: flex;
  width: ${props => props.width || '220px'};
  align-items: ${props => (props.inline ? 'center' : 'flex-start')};
  flex-direction: ${props => (props.inline ? 'row' : 'column')};
  margin: ${props => props.margin || '0'};
  label {
    margin-bottom: ${props => (props.inline ? '0' : '10px')};
  }
`;

const Label = styled.label<LabelProps>`
  color: ${props => props.theme.colors.textPrimary};
  font-weight: ${props => props.labelWeight || '500'};
  font-size: 12px;
  margin: 0 15px 10px 0;
  white-space: nowrap;
`;

const CustomSelect = styled(FlexContainer)`
  padding: 4px 18px;
  cursor: pointer;
`;

const OptionsContainer = styled(FlexContainer)`
  position: absolute;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  border: 1px solid #ccc;
  top: 100%;
  left: 0;
  max-height: 250px;
  overflow-y: auto;
  z-index: 2;
`;

const Option = styled(FlexContainer)`
  width: 100%;
  font-size: 13px;
  padding: 10px;
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const DeletablePill: React.FC<PillProps> = ({ onDelete, item }) => {
  return (
    <FlexContainer
      alignItems='center'
      backgroundColor={THEME.colors.primary}
      padding='0 10px'
      margin='0 10px 2px 0'
      borderRadius='5px'
    >
      <Typography color='#FFFFFF' margin='0 15px 0 0' fontSize='13px'>
        {item.name}
      </Typography>
      <div style={{ transform: 'rotate(45deg)' }}>
        <Icon
          icon='plus-icon'
          size={20}
          onClick={e => {
            e.stopPropagation();
            onDelete(item);
          }}
          color='#FFFFFF'
        />
      </div>
    </FlexContainer>
  );
};

const MultipleSelect: React.FC<SelectProps> = ({
  labelWeight,
  label,
  placeholder,
  options,
  values,
  onChange,
  onDelete,
  margin,
  width,
  disabled,
  inline,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const node = useRef(null);
  useOnClickOutside(node, () => setIsOpen(false));

  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled]);

  return (
    <Container inline={inline} margin={margin} width={width}>
      {label && <Label labelWeight={labelWeight}>{label}</Label>}
      <FlexContainer container position='relative' ref={node}>
        <CustomSelect
          container
          backgroundColor={disabled ? THEME.colors.borderColor : '#FFFFFF'}
          borderColor={THEME.colors.borderColor}
          justify='space-between'
          alignItems='center'
          minHeight='35px'
          onClick={() => setIsOpen(true)}
          padding='4px 18px'
          cursor='pointer'
        >
          <FlexContainer container flex='1' wrap='wrap' alignItems='center'>
            {values.length === 0 ? (
              <Typography fontSize='13px' color={THEME.colors.placeholderColor}>
                {placeholder}
              </Typography>
            ) : (
              <>
                {values.map(item => (
                  <DeletablePill
                    key={item.id}
                    onDelete={onDelete}
                    item={item}
                  />
                ))}
              </>
            )}
          </FlexContainer>
          <Icon
            icon='arrow-icon'
            color={THEME.colors.textSecondary}
            size={15}
            margin='0 0 0 15px'
          />
        </CustomSelect>
        {isOpen && (
          <OptionsContainer container direction='column'>
            {options.map(option => (
              <Option
                key={option.id}
                container
                justify='space-between'
                alignItems='center'
                padding='10px 15px'
                onClick={() => {
                  onChange(option);
                }}
              >
                {option.name}
                <Checkbox
                  checked={!!find(values, { id: option.id })}
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Option>
            ))}
          </OptionsContainer>
        )}
      </FlexContainer>
    </Container>
  );
};

export default MultipleSelect;
