import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { cloneDeep, find, isEmpty } from 'lodash';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Drawer, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Button,
  FlexContainer,
  Typography,
  Icon,
  Status,
  Select,
} from 'components/atoms';
import {
  Dropdown,
  DropdownIconOption,
  Cell,
  Spinner,
} from 'components/molecules';
import {
  DataTable,
  FiltersContainer,
  SearchWithFilter,
  AssignBox,
} from 'components/organisms';
import { useTypedSelector } from 'store';
import { selectBranchOffice } from 'store/organization/actions';
import {
  getOrders,
  getOrderTypes,
  getStatuses,
  changeOrderStatus,
  changePurchaseOrderStatus,
  releaseOrdersRoute,
  confirmOrdersProvider,
  getProvidersMinimal,
  assignOrderProvider,
  getTimeWindows,
  downloadShippingLabels,
  chooseOrganization,
  getClients,
  getBranchOffice,
} from 'services';
import { optionFormatter, providerFormatter } from 'util/functions';
import THEME from 'util/styledTheme';
import statusColors from 'util/statusColors';
import useRequest from 'hooks/useRequest';
import Minutes99 from 'assets/images/providers/99minutos.png';
import Olva from 'assets/images/providers/olva.jpg';
import Cabify from 'assets/images/providers/cabify.png';
import Glovo from 'assets/images/providers/glovo.svg';
import Savar from 'assets/images/providers/savar.png';
import PedidosYa from 'assets/images/providers/pedidosya.png';
import Logo from 'assets/images/DefaultLogo.png';
import Minutes99Sm from 'assets/images/providers/99minutos_sm.png';
import OlvaSm from 'assets/images/providers/olva_sm.png';
import CabifySm from 'assets/images/providers/cabify_sm.png';
import ShopifySm from 'assets/images/shopify_sm.png';
import PedidosYaSm from 'assets/images/providers/pedidosya_sm.png';
import { ReactComponent as MoreOptions } from 'assets/images/filter_icon.svg';
import { ReactComponent as DocumentText } from 'assets/images/document_text.svg';
import NoData from 'assets/images/no_data.svg';
import DetailOrder from '../Detail';
import Edit from '../Edit';

const purchaseOrderStatus = [
  {
    id: 1,
    overwritten_label: 'Preparando',
    code: 'preparing',
  },
  {
    id: 2,
    overwritten_label: 'Listo para entregar',
    code: 'ready_to_ship',
  },
  {
    id: 3,
    overwritten_label: 'Entregado',
    code: 'shipped',
  },
  {
    id: 4,
    overwritten_label: 'Completado',
    code: 'completed',
  },
  {
    id: 5,
    overwritten_label: 'Cancelado',
    code: 'cancelled',
  },
  {
    id: 6,
    overwritten_label: 'Devuelto',
    code: 'returned',
  },
];

const statusOptions = [
  {
    id: 1,
    name: 'Órden',
    code: 'order',
  },
  {
    id: 2,
    name: 'Órden de compra',
    code: 'purchase-order',
  },
];

const pickProviderIcon = (code, sm) => {
  if (sm) {
    switch (code) {
      case '99minutos':
        return Minutes99Sm;
      case 'olva':
        return OlvaSm;
      case 'cabify':
        return CabifySm;
      case 'pedidosya':
        return PedidosYaSm;
      default:
        return '';
    }
  } else {
    switch (code) {
      case '99minutos':
        return Minutes99;
      case 'olva':
        return Olva;
      case 'cabify':
        return Cabify;
      case 'savar':
        return Savar;
      case 'pedidosya':
        return PedidosYa;
      default:
        return '';
    }
  }
};

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: THEME.colors.error,
    color: '#FFF',
    fontSize: 12,
    marginBottom: 7,
    padding: 8,
  },
  arrow: {
    color: THEME.colors.error,
  },
})(Tooltip);

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 15px;
`;

const MoreOptionIcon = styled(MoreOptions)`
  .cls-2 {
    stroke: #8c8cb1;
  }
  ${({ active }) =>
    active &&
    `
    .cls-2{
      stroke: #404071;
    }
  `}
`;

type ProviderDetailsProps = {
  cursor?: string;
};
const ProviderButton = styled.button<ProviderDetailsProps>`
  background-color: transparent;
  border: 1px solid transparent;
  ${props => props.cursor && `cursor: ${props.cursor};`}
  padding: 0;
  color: #404071;
`;

const ProviderFileButton = styled(Button)`
  padding: 5px 6px;
`;

const ShopifyContainer = styled(FlexContainer)`
  width: 43px;
  height: 43px;
  justify-content: center;
  align-items: center;
  background-color: #8db542;
  border-radius: 50%;
  img {
    width: 28px;
    height: auto;
    padding: 1px;
  }
`;

const olvaData = {
  description:
    'Brindamos servicios de logística integral a través de courier, transporte de encomiendas y servicios colaterales con calidad y eficiencia.',
  cut_off_schedule: ['7pm para el ingreso de envíos.'],
  pickup_schedule: 'Desde las 9am hasta las 5pm.',
  extra_info:
    'Una orden dura hasta 5 días, después se cancela automáticamente.',
};

const minutesData = {
  code: '99minutos',
  name: '99 Minutos',
  description:
    'Nos encargamos de toda la logística para llevar tu producto hasta las manos de tu cliente en menos de 99 minutos en México, Chile, Colombia y Perú.',
  cut_off_schedule: [
    'Sameday: 1pm. Si se ingresa un pedido pasada esa hora se recolecta pero se entrega el día siguiente.',
    'Nextday: 12pm (media noche).',
  ],
  extra_info: 'Medidas máximas del paquete en cualquiera de sus lados: 40cm.',
};

const initialFilters = [
  {
    label: 'Tipo de orden',
    placeholder: 'Tipo de orden',
    name: 'order_type_id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
  },
  {
    label: 'Fecha creada',
    placeholder: 'Fecha creada',
    name: 'created_at',
    type: 'date',
  },
  {
    label: 'Fecha programada',
    placeholder: 'Fecha programada',
    name: 'delivery_programmed_date',
    type: 'date',
  },
  {
    label: 'Tipo de estado',
    placeholder: 'Tipo de estado',
    name: 'last_status__id',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
  },
  {
    label: 'Ventanas Horarias',
    placeholder: 'Seleccione ventana horaria',
    name: 'time_window',
    defaultOption: {
      id: '',
      name: 'Todos',
    },
    options: [],
    permission: 'core.time_window.list',
  },
];

const options = [
  {
    name: 'Administrador',
    id: 'administrator_name',
  },
  {
    name: 'Provider',
    id: 'provider_name',
  },
  {
    name: 'Worker',
    id: 'worker_name',
  },
  {
    name: 'Cod. Referencia',
    id: 'reference_code',
  },
  {
    name: 'Contacto',
    id: 'contact_person',
  },
  {
    name: 'Placa',
    id: 'vehicle__plate',
  },
];

const OrderList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const branchOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  const organization = useTypedSelector(state => state.organization);
  const dispatch = useDispatch();
  const user = useTypedSelector(state => state.user);
  // API calls
  const [orders, fetchOrders, loadingOrders, pageData] = useRequest(
    getOrders,
    []
  );
  const [orderTypes, fetchOrderTypes] = useRequest(getOrderTypes, []);
  const [allStatus, fetchAllStatus] = useRequest(getStatuses, []);
  const [manualStatus, fetchManualStatus] = useRequest(getStatuses, []);
  const [providers, fetchProviders] = useRequest(getProvidersMinimal, null);
  const [timeWindows, fetchTimeWindows, loadingTimeWindows] = useRequest(
    getTimeWindows,
    null
  );
  const [, updateStatus, , , , statusRequest] = useRequest(
    changeOrderStatus,
    null
  );
  const [
    ,
    updatePurchaseStatus,
    ,
    ,
    errorPurchaseRequest,
    statusPurchaseRequest,
  ] = useRequest(changePurchaseOrderStatus, null);
  const [
    ,
    providerAssign,
    ,
    ,
    errorProviderAssign,
    statusAssignRequest,
  ] = useRequest(assignOrderProvider, null);
  const [successReleaseOrderOnRoute, releaseOrderOnRoute] = useRequest(
    releaseOrdersRoute,
    null
  );
  const [
    ,
    confirmProviders,
    ,
    ,
    errorConfirmProvider,
    statusConfirmRequest,
  ] = useRequest(confirmOrdersProvider, null);
  const [clients, fetchClients, loadingClients] = useRequest(getClients, []);
  const [office, fetchBranchOffice, loadingOffice] = useRequest(
    getBranchOffice
  );
  // Values
  const [selectedBranchOffice, setSelectedBranchOffice] = useState<any>('');
  const [filters, setFilters] = useState(initialFilters);
  const [filterValues, setFilterValues] = useState({
    order_type_id: null,
    last_status__id: null,
    delivery_programmed_date: null,
    created_at: null,
    time_window: null,
    client_id: null,
  });
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [page, setPage] = useState<number>(1);
  const [assignOption, setAssignOption] = useState<string>('');
  const [assignStatus, setAssignStatus] = useState<number>(1);
  const [assignSelectOptions, setAssignSelectOptions] = useState([]);
  const [assignSearchOptions, setAssignSearchOptions] = useState([]);
  const [assignPurchaseOptions, setAssignPurchaseOptions] = useState([]);
  const [detailData, setDetailData] = useState({});
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openProviderDetail, setOpenProviderDetail] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);
  const [reprogram, setReprogram] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [firstLoadingOrders, setFirstLoadingOrders] = useState<boolean>(false);
  const [firstOrderRender, setFirstOrderRender] = useState<boolean>(false);
  const [searchWithFilter, setSearchWithFilter] = useState<boolean>(false);
  const [selectedProvider, setSelectedProvider] = useState<string>('');
  const [officeOptions, setOfficeOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const defaultOption = { id: '', name: 'Todas', reference_code: '' };
  const toSelectDefaultOption = {
    id: JSON.stringify(defaultOption),
    name: 'Todas',
  };

  const requestOrders = (keepRows?: boolean, isSearch?: boolean) => {
    if (!keepRows) {
      setSelectedRows([]);
    }

    const params = {
      last_status__id: filterValues.last_status__id,
      order_type_id: filterValues.order_type_id,
      delivery_programmed_date: filterValues.delivery_programmed_date,
      created_at: filterValues.created_at,
      time_window: filterValues.time_window,
      organization_branch_office__id: branchOfficeId,
      client_id: filterValues.client_id,
      page_size: 20,
      page: isSearch ? 1 : page,
    };

    if (searchFilter !== '') {
      params[searchFilter] = searchInput;
    }

    if (isSearch) {
      setSearchWithFilter(true);
    }

    fetchOrders(params);
    setFirstOrderRender(false);
  };

  const handleAssign = (id: string) => {
    if (selectedRows.length === 0) {
      enqueueSnackbar('Seleccione al menos una orden para asignar.', {
        variant: 'error',
      });
      return;
    }

    if (assignOption === 'status') {
      if (assignStatus === 1) {
        updateStatus({
          order_ids: selectedRows,
          status: id,
          organization_branch_office_id: branchOfficeId,
        });
      } else {
        selectedRows.forEach(rowId => {
          updatePurchaseStatus(
            {
              status: id,
            },
            rowId
          );
        });
      }
    } else {
      providerAssign({
        order_ids: selectedRows,
        provider_id: id,
      });
    }
  };

  const releaseOrders = ids => {
    releaseOrderOnRoute({
      order_ids: ids,
    });
  };

  const handleProviderSearch = search => {
    if (selectedRows.length === 0) {
      enqueueSnackbar('Seleccione al menos una orden para asignar.', {
        variant: 'error',
      });
      return;
    }

    fetchProviders({
      organization_branch_office_id: branchOfficeId,
      search,
      routes: selectedRows.join(','),
    });
  };

  const handleProviderConfirm = () => {
    if (selectedRows.length === 0) {
      enqueueSnackbar('Seleccione al menos una orden para confirmar.', {
        variant: 'error',
      });
      return;
    }
    const orderArray = selectedRows.map(orderId =>
      find(orders, { id: +orderId })
    );

    const canConfirm = orderArray.every(
      order =>
        order.provider_name !== '-' && order.last_status.code === 'created'
    );

    if (canConfirm) {
      confirmProviders({
        order_ids: selectedRows,
      });
    } else {
      enqueueSnackbar('Una o varias órdenes no cuentan con proveedor.', {
        variant: 'error',
      });
    }
  };

  const handleDownload = async () => {
    const orderIds = orders
      .filter(order => order.external_provider_file)
      .map(order => order.id);

    if (selectedRows.length !== 0) {
      const selectedOrderId = orderIds.filter(
        id => selectedRows.indexOf(`${id}`) !== -1
      );

      if (selectedOrderId.length !== 0) {
        const response = await downloadShippingLabels(selectedOrderId);

        if (response && response.data.url) {
          window.open(response.data.url);
        }
      } else {
        enqueueSnackbar('Órdenes sin guías.', {
          variant: 'error',
        });
      }
    } else if (orderIds.length !== 0) {
      const response = await downloadShippingLabels(orderIds);

      if (response && response.data.url) {
        window.open(response.data.url);
      }
    } else {
      enqueueSnackbar('Órdenes sin guías.', {
        variant: 'error',
      });
    }
  };

  const OverwrittenPurchaseLabel = status => {
    switch (status) {
      case 'created':
        return 'Creado';
      case 'preparing':
        return 'Preparando';
      case 'ready_to_ship':
        return 'Listo para entregar';
      case 'shipped':
        return 'Entregado';
      case 'completed':
        return 'Completado';
      case 'cancelled':
        return 'Cancelado';
      case 'returned':
        return 'Devuelto';
      default:
        return '-';
    }
  };

  const settingsOptions = [
    user.permissions.includes('core.order.update')
      ? {
          label: 'Editar',
          name: 'edit',
          icon: 'edit-icon',
          onClick: row => {
            setEditId(row.id);
            setOpenEdit(true);
          },
        }
      : null,
    // {
    //   label: 'Liberar',
    //   name: 'release',
    //   icon: 'send-icon',
    //   onClick: () => {
    //     releaseOrders(selectedRows);
    //   },
    // },
    user.permissions.includes('core.order.create')
      ? {
          label: 'Reprogramar',
          name: 'reprogram',
          icon: 'reassign-icon',
          onClick: row => {
            setReprogram(true);
            setEditId(row.id);
            setOpenEdit(true);
          },
        }
      : null,
  ];

  useEffect(() => {
    fetchOrderTypes({
      page_size: 0,
    });
    fetchAllStatus({
      model_types: 'order',
      status_types: 'manual,automatic',
      page_size: 0,
    });
    fetchManualStatus({
      model_types: 'order',
      status_types: 'manual',
      page_size: 0,
    });
    fetchTimeWindows({
      page_size: 0,
    });
    fetchClients({
      page_size: 0,
    });
    fetchBranchOffice(branchOfficeId);
  }, [
    fetchOrderTypes,
    fetchAllStatus,
    fetchManualStatus,
    fetchTimeWindows,
    fetchClients,
    fetchBranchOffice,
  ]);

  useEffect(() => {
    if (filterValues.client_id) {
      requestOrders(false, true);
    }
  }, [filterValues]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[0].options = optionFormatter(orderTypes);
    setFilters(updatedFilters);
  }, [orderTypes]);

  useEffect(() => {
    const updatedFilters = cloneDeep(filters);
    updatedFilters[3].options = optionFormatter(allStatus, {
      id: item => item.id,
      name: item => item.overwritten_label,
    });
    setFilters(updatedFilters);
  }, [allStatus]);

  useEffect(() => {
    if (timeWindows && timeWindows.length > 0) {
      const updatedFilters = cloneDeep(filters);
      if (updatedFilters[4].options.length === 0) {
        updatedFilters[4].options = optionFormatter(timeWindows, {
          id: item => item.id,
          name: item => `${item.start_hour} - ${item.end_hour}`,
        });
        setFilters(updatedFilters);
      }
    }
  }, [timeWindows, filters]);

  useEffect(() => {
    setAssignSelectOptions(
      optionFormatter(manualStatus, {
        id: item => item.code,
        name: item => item.overwritten_label,
      })
    );
  }, [manualStatus]);

  useEffect(() => {
    setAssignPurchaseOptions(
      optionFormatter(purchaseOrderStatus, {
        id: item => item.code,
        name: item => item.overwritten_label,
      })
    );
  }, [purchaseOrderStatus]);

  useEffect(() => {
    requestOrders();
    setFirstOrderRender(true);
    setSelectedBranchOffice(branchOfficeId);
  }, [page, branchOfficeId]);

  useEffect(() => {
    if (providers) {
      if (providers.length === 0) {
        enqueueSnackbar('No se encontraron coincidencias para su búsqueda.', {
          variant: 'warning',
        });
      } else {
        const pickImage = code => {
          switch (code) {
            case '99minutos':
              return Minutes99;
            case 'olva':
              return Olva;
            case 'cabify':
              return Cabify;
            case 'glovo':
              return Glovo;
            case 'savar':
              return Savar;
            case 'pedidosya':
              return PedidosYa;
            default:
              return '';
          }
        };

        let providersBranchOffice = [];

        if (office.provider && selectedBranchOffice !== '') {
          providersBranchOffice = providers.filter(
            provider => provider.code === office.provider.service_type
          );
        } else {
          providersBranchOffice = providers;
        }

        setAssignSearchOptions(
          providerFormatter(providersBranchOffice, {
            id: option => option.id,
            name: option => option.business_name,
            phone: option => option.phone,
            logo: option =>
              option.code ? pickImage(option.code) : option.logo || Logo,
          })
        );
      }
    }
  }, [providers]);

  useEffect(() => {
    if (statusRequest === 200) {
      enqueueSnackbar('Se actualizó el estado de las órdenes correctamente.', {
        variant: 'success',
      });
      requestOrders();
      setAssignOption('');
      setAssignStatus(1);
    } else if (statusRequest && statusRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusRequest, enqueueSnackbar]);

  useEffect(() => {
    if (statusPurchaseRequest === 200) {
      enqueueSnackbar('Se actualizó el estado de las órdenes correctamente.', {
        variant: 'success',
      });
      requestOrders();
      setAssignOption('');
      setAssignStatus(1);
    } else if (statusPurchaseRequest && statusPurchaseRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusPurchaseRequest, enqueueSnackbar]);

  useEffect(() => {
    if (statusConfirmRequest === 204) {
      enqueueSnackbar(
        'Se confirmo el proveedor de las órdenes correctamente.',
        {
          variant: 'success',
        }
      );
      requestOrders();
    } else if (statusConfirmRequest && statusConfirmRequest !== 204) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusConfirmRequest, enqueueSnackbar]);

  useEffect(() => {
    if (errorPurchaseRequest && errorPurchaseRequest.data) {
      if (errorPurchaseRequest.data.detail) {
        enqueueSnackbar('No se encontró la orden de compra', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
          variant: 'error',
        });
      }
    }
  }, [errorPurchaseRequest, enqueueSnackbar]);

  useEffect(() => {
    if (
      errorConfirmProvider &&
      errorConfirmProvider.data &&
      errorConfirmProvider.data.errors
    ) {
      if (errorConfirmProvider.data.errors[0] === 'Insufficient balance.') {
        enqueueSnackbar('Saldo insuficiente.', {
          variant: 'error',
        });
      } else if (
        errorConfirmProvider.data.errors[0] ===
        'It was not possible send the order to the provider.'
      ) {
        enqueueSnackbar(
          'Error con el proveedor, intenta dentro unos minutos.',
          {
            variant: 'error',
          }
        );
      } else if (errorConfirmProvider.data.errors[0]) {
        const errors = errorConfirmProvider.data.errors[0];
        Object.values(errors).forEach(error => {
          enqueueSnackbar(error, {
            variant: 'error',
          });
        });
      }
    }
  }, [errorConfirmProvider]);

  useEffect(() => {
    if (
      errorProviderAssign &&
      errorProviderAssign.data &&
      errorProviderAssign.data.errors
    ) {
      if (errorProviderAssign.data.errors[0] === "{'title': 'Sin cobertura'}") {
        enqueueSnackbar('Sin cobertura', {
          variant: 'error',
        });
      } else if (errorProviderAssign.data.errors[0] === 'Zone not available.') {
        enqueueSnackbar('Zona de cobertura no disponible', {
          variant: 'error',
        });
      } else if (
        errorProviderAssign.data.errors[0] ===
        'Error. PedidosYa only accepts immediate orders.'
      ) {
        enqueueSnackbar('PedidosYa solo acepta órdenes inmediatas', {
          variant: 'error',
        });
      } else if (!errorProviderAssign.data.errors[0].order_ids) {
        enqueueSnackbar(errorProviderAssign.data.errors[0], {
          variant: 'error',
        });
      }
    }
  }, [errorProviderAssign]);

  useEffect(() => {
    if (statusAssignRequest === 200) {
      enqueueSnackbar('Se asignó el provider a las órdenes correctamente.', {
        variant: 'success',
      });
      requestOrders();
      setAssignOption('');
      setSearchInput('');
      setAssignSearchOptions([]);
    } else if (statusRequest && statusRequest !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusAssignRequest]);

  useEffect(() => {
    if (successReleaseOrderOnRoute) {
      enqueueSnackbar('Se liberaron las órdenes seleccionadas correctamente.', {
        variant: 'success',
      });
      requestOrders();
    }
  }, [successReleaseOrderOnRoute, enqueueSnackbar]);

  useEffect(() => {
    if (searchWithFilter) {
      setFirstOrderRender(false);
    } else if (firstOrderRender && loadingOrders && page === 1) {
      setFirstLoadingOrders(true);
    } else {
      setFirstLoadingOrders(false);
    }
  }, [firstOrderRender, loadingOrders]);

  useEffect(() => {
    if (clients.length > 0) {
      const activeClients = clients.filter(client => client.is_active);
      setClientOptions(
        optionFormatter(activeClients, {
          id: option => option.id,
          name: option => option.name,
        })
      );
    }
  }, [clients]);

  useEffect(() => {
    chooseOrganization({
      page_size: 0,
    }).then(res => {
      const organizationObj = find(res.data, {
        id: parseInt(`${organization.selectedOrganization.id}`, 10),
      });

      if (organizationObj) {
        setOfficeOptions(
          optionFormatter(organizationObj.administrator_branch_offices, {
            id: option => JSON.stringify(option),
            name: option => option.name,
          })
        );
      }
    });
  }, []);

  const option = row =>
    settingsOptions
      .filter(setting => setting)
      .filter(
        setting =>
          row.can_rescheduled ||
          (!row.can_rescheduled && setting.name !== 'reprogram')
      )
      .filter(
        setting =>
          row.last_status.code === 'created' ||
          row.last_status.code === 'pending' ||
          ((row.last_status.code !== 'created' ||
            row.last_status.code !== 'pending') &&
            setting.name !== 'edit')
      )
      .filter(setting => {
        let prueba = {};

        if (row.provider && row.provider.service_type !== 'internal') {
          prueba = setting.name !== 'release';
        } else if (
          row.provider &&
          row.provider.service_type === 'internal' &&
          (row.last_status.code === 'cancelled' ||
            row.last_status.code === 'completed' ||
            row.last_status.code === 'failed')
        ) {
          prueba = setting.name !== 'release';
        }
        return prueba;
      })
      .map(setting => (
        <DropdownIconOption
          key={setting.name}
          label={setting.label}
          name={setting.name}
          icon={setting.icon}
          onClick={() => setting.onClick(row)}
        />
      ));

  const headers = useMemo(
    () => [
      {
        label: 'Estado',
        id: 'purchase_order_status',
        cell: row => (
          <>
            {row.purchase_order ? (
              <Status
                color={
                  statusColors[row.purchase_order.status] ||
                  statusColors.default
                }
                label={OverwrittenPurchaseLabel(row.purchase_order.status)}
              />
            ) : (
              <Typography>-</Typography>
            )}
          </>
        ),
      },
      {
        label: 'Código',
        id: 'reference_code',
        cell: row => (
          <Cell.Link
            onClick={() => {
              if (user.permissions.includes('core.order.retrieve')) {
                setDetailData(row);
                setOpenDetail(true);
              }
            }}
          >
            <Typography fontWeight={600}>{row.code}</Typography>
            {row.reference_code !== '' && (
              <FlexContainer
                margin='5px 0 0'
                padding='5px'
                borderRadius='5px'
                backgroundColor={THEME.colors.borderColor}
                justify='center'
                width='fit-content'
              >
                <Typography fontWeight={600} color='text.secondary'>
                  {row.reference_code}
                </Typography>
              </FlexContainer>
            )}
          </Cell.Link>
        ),
      },
      organization.selectedOrganization.name === 'VÄV Basics'
        ? {
            label: 'Canal de venta',
            id: 'sales_chanel',
            cell: () => (
              <FlexContainer
                container
                justify='center'
                alignItems='center'
                margin='0'
              >
                <ShopifyContainer>
                  <img src={ShopifySm} alt='Shopify' />
                </ShopifyContainer>
              </FlexContainer>
            ),
          }
        : null,
      {
        label: 'Fecha de creación',
        id: 'created_at',
        cell: row => moment(row.created_at).format('DD/MM/YYYY'),
      },
      {
        label: 'Fecha programada',
        id: 'delivery_programmed_date',
        cell: row => moment(row.delivery_programmed_date).format('DD/MM/YYYY'),
      },
      organization.selectedOrganization.plan === 'full' ||
      organization.selectedOrganization.plan === 'logistic_operator'
        ? {
            label: 'Cliente',
            id: 'client',
            cell: row => (
              <Typography>{row.client ? row.client.name : '-'}</Typography>
            ),
          }
        : null,
      {
        label: 'Destino',
        id: 'first_address',
        cell: row => (
          <FlexContainer direction='column'>
            <Typography>{row.contact_person}</Typography>
            {!row.all_coordinates_found && row.pending_message !== '' ? (
              <CustomTooltip
                arrow
                placement='top'
                title={`${row.pending_message}, click aquí para resolverlo.`}
              >
                <div style={{ display: 'inline-block' }}>
                  <Cell.Link
                    onClick={() => {
                      if (user.permissions.includes('core.order.update')) {
                        setEditId(row.id);
                        setOpenEdit(true);
                      }
                    }}
                  >
                    <Cell.Icon icon='error-icon'>
                      <Typography color={THEME.colors.error}>
                        {row.first_address}
                      </Typography>
                    </Cell.Icon>
                  </Cell.Link>
                </div>
              </CustomTooltip>
            ) : (
              <Typography color='text.secondary'>
                {row.first_address}
              </Typography>
            )}
          </FlexContainer>
        ),
      },
      {
        label: 'Delivery',
        id: 'last_status',
        cell: row => (
          <Status
            color={statusColors[row.last_status.code] || statusColors.default}
            label={row.last_status.overwritten_label}
          />
        ),
      },
      user.permissions.includes('core.provider.list') ||
      user.permissions.includes('core.provider_integration.list')
        ? {
            label: 'Proveedor',
            id: 'provider_name',
            cell: row => (
              <FlexContainer direction='column'>
                <FlexContainer>
                  {row.provider ? (
                    <ProviderButton
                      cursor={
                        row.provider.service_type === 'olva' ||
                        row.provider.service_type === '99minutos'
                          ? 'pointer'
                          : 'initial'
                      }
                      onClick={() => {
                        if (
                          row.provider.service_type === 'olva' ||
                          row.provider.service_type === '99minutos'
                        ) {
                          setOpenProviderDetail(true);
                        }
                        setSelectedProvider(row.provider.service_type);
                      }}
                    >
                      <FlexContainer alignItems='center'>
                        {pickProviderIcon(row.provider.service_type, true) && (
                          <img
                            src={pickProviderIcon(
                              row.provider.service_type,
                              true
                            )}
                            alt={row.provider.tradename}
                            style={{
                              width: '17px',
                              height: 'auto',
                              margin: '0 5px 0 0',
                            }}
                          />
                        )}
                        {row.provider.tradename}
                      </FlexContainer>
                    </ProviderButton>
                  ) : (
                    <Typography>-</Typography>
                  )}
                </FlexContainer>
                {row.worker && (
                  <Typography>
                    {row.worker.first_name} {row.worker.last_name}
                  </Typography>
                )}
              </FlexContainer>
            ),
          }
        : null,
      {
        label: 'Precio Delivery',
        id: 'delivery_price',
        cell: row => (
          <Typography>
            {`${getSymbolFromCurrency(row.delivery_price_currency)} 
            ${row.delivery_price}`}
          </Typography>
        ),
      },
      {
        label: 'Precio Items',
        id: 'items_price',
        cell: row => (
          <Typography>
            {`${getSymbolFromCurrency(row.delivery_price_currency)} 
            ${row.items_value}`}
          </Typography>
        ),
      },
      user.permissions.includes('core.route.list')
        ? {
            label: 'Ruta',
            id: 'route_code',
            cell: row =>
              row.route ? (
                <Typography>{row.route.code}</Typography>
              ) : (
                <Typography>-</Typography>
              ),
          }
        : null,
      {
        label: 'Guía',
        id: 'provider_file',
        cell: row => (
          <>
            {row.external_provider_file ? (
              <ProviderFileButton
                onClick={() => window.open(row.external_provider_file)}
              >
                <DocumentText />
              </ProviderFileButton>
            ) : (
              <Typography>-</Typography>
            )}
          </>
        ),
      },
      user.permissions.includes('core.order.shipping_documents')
        ? {
            label: '',
            id: 'download_all',
            cell: () => <></>,
          }
        : null,
      {
        label: '',
        id: 'settings',
        cell: row => (
          <Dropdown
            label={<Icon icon='options-icon' size={20} />}
            direction='bottom'
            position='right'
            showCaret={false}
            options={option(row)}
            padding='0 10px'
            hover
            disabled={isEmpty(option(row))}
          />
        ),
      },
    ],
    [selectedRows]
  );

  return (
    <>
      {firstLoadingOrders && firstOrderRender && !searchWithFilter ? (
        <FlexContainer container height='100vh'>
          <FlexContainer
            container
            padding='30px 40px'
            direction='column'
            justify='center'
            alignItems='center'
            height='100vh'
          >
            <Spinner height='350px' />
          </FlexContainer>
        </FlexContainer>
      ) : (
        <FlexContainer container padding='30px 40px' direction='column'>
          <FlexContainer container direction='column'>
            <FlexContainer
              container
              padding='10px 0 20px'
              justify='space-between'
            >
              <FlexContainer>
                <Select
                  placeholder='Seleccione una organización'
                  value={JSON.stringify(organization.selectedBranchOffice)}
                  options={officeOptions}
                  defaultOption={toSelectDefaultOption}
                  onChange={value => {
                    const parsedValue = JSON.parse(value);
                    dispatch(
                      selectBranchOffice({
                        id: parsedValue.id,
                        name: parsedValue.name,
                        reference_code: parsedValue.reference_code,
                      })
                    );
                    setSelectedBranchOffice(parsedValue.id);
                    setSearchWithFilter(true);
                    fetchBranchOffice(parsedValue.id);
                  }}
                  borderless
                />
                {(organization.selectedOrganization.plan === 'full' ||
                  organization.selectedOrganization.plan ===
                    'logistic_operator') && (
                  <FlexContainer margin='0 20px 0'>
                    <Select
                      placeholder='Seleccione un cliente'
                      value={filterValues.client_id}
                      options={clientOptions}
                      onChange={value => {
                        setFilterValues({
                          ...filterValues,
                          client_id: value,
                        });
                        setSearchWithFilter(true);
                      }}
                      borderless
                      disabled={loadingClients}
                    />
                  </FlexContainer>
                )}
              </FlexContainer>
            </FlexContainer>

            {firstOrderRender && !searchWithFilter && orders.length === 0 ? (
              <FlexContainer
                container
                direction='column'
                justify='center'
                alignItems='center'
                height='70vh'
              >
                <img src={NoData} alt='no_data' />
                <Typography color='text.secondary'>
                  No hay órdenes registradas
                </Typography>
              </FlexContainer>
            ) : (
              <FlexContainer container direction='column'>
                <FlexContainer
                  container
                  direction='column'
                  margin='0 0 20px'
                  padding='20px'
                  backgroundColor='#FFFFFF'
                  borderRadius='4px'
                  borderColor={THEME.colors.borderColor}
                >
                  <FlexContainer container justify='space-between'>
                    <FlexContainer container>
                      <SearchWithFilter
                        filterLabel='Buscar por'
                        filterValue={searchFilter}
                        onChangeFilterValue={value => setSearchFilter(value)}
                        options={options}
                        onChangeSearchValue={value => setSearchInput(value)}
                        searchValue={searchInput}
                        onSubmit={() => requestOrders(false, true)}
                        maxWidth='500px'
                        placeholder='Buscar'
                        inputWidth='312px'
                      />
                      <Button
                        variant='contained'
                        color='primary'
                        margin='0'
                        onClick={() => requestOrders(false, true)}
                      >
                        Buscar
                      </Button>
                    </FlexContainer>

                    <FlexContainer container>
                      <Button
                        variant='contained'
                        color='secondary'
                        margin='0 0 0 5px'
                        onClick={() => setShowOptions(!showOptions)}
                      >
                        <MoreOptionIcon
                          active={
                            Object.values(filterValues).some(
                              value => value !== null
                            )
                              ? 'active'
                              : ''
                          }
                        />
                      </Button>
                    </FlexContainer>
                    <FlexContainer justify='flex-end'>
                      {user.permissions.includes(
                        'core.order.confirm_provider'
                      ) && (
                        <Button
                          variant='contained'
                          color='primary'
                          fontSize='13px'
                          margin='0 0 0 20px'
                          onClick={() => handleProviderConfirm()}
                        >
                          Confirmar Proveedor
                        </Button>
                      )}
                      {user.permissions.includes(
                        'core.order.assign_provider'
                      ) && (
                        <Button
                          variant='contained'
                          color='secondary'
                          fontSize='13px'
                          margin='0 0 0 20px'
                          onClick={() => {
                            setAssignOption('provider');

                            if (assignStatus === 2) {
                              setSelectedRows([]);
                            }
                          }}
                        >
                          Asignar Proveedor
                        </Button>
                      )}
                      {user.permissions.includes(
                        'core.order.update_status'
                      ) && (
                        <Button
                          variant='contained'
                          color='secondary'
                          fontSize='13px'
                          margin='0 0 0 20px'
                          onClick={() => {
                            setAssignOption('status');

                            if (assignStatus === 2) {
                              setSelectedRows([]);
                            }
                          }}
                        >
                          Cambiar de Estado
                        </Button>
                      )}
                    </FlexContainer>
                  </FlexContainer>

                  {showOptions && (
                    <FiltersContainer
                      labelWeight={600}
                      filters={filters}
                      value={filterValues}
                      onChange={(value, name) => {
                        setFilterValues({
                          ...filterValues,
                          [name]: value,
                        });
                      }}
                      disabled={loadingTimeWindows}
                      permissions={user.permissions}
                      margin='0 7px 10px 0'
                    />
                  )}
                </FlexContainer>

                {assignOption !== '' && (
                  <AssignBox
                    title={
                      assignOption === 'status'
                        ? 'Selecciona el estado para cambiar.'
                        : `Busca al ${assignOption} que desees asignar`
                    }
                    type={assignOption === 'status' ? 'select' : 'search'}
                    onSearch={search => {
                      setAssignSearchOptions([]);
                      handleProviderSearch(search);
                    }}
                    onSelect={optionId => handleAssign(optionId)}
                    onClose={() => {
                      setAssignOption('');
                      setAssignSearchOptions([]);
                    }}
                    selectOptions={assignSelectOptions}
                    selectPurchaseOptions={assignPurchaseOptions}
                    searchOptions={assignSearchOptions}
                    statusOptions={statusOptions}
                    onChangeAssignStatus={value => setAssignStatus(value)}
                    onChangeOrderOption={() => setSelectedRows([])}
                    assignOption={assignOption}
                    orderAssign
                  />
                )}

                <DataTable
                  headers={headers.filter(header => header)}
                  data={orders}
                  selectedRows={selectedRows}
                  onSelectRow={rows => setSelectedRows(rows)}
                  onChangePage={selectedPage => setPage(selectedPage)}
                  onDownloadAll={() => handleDownload()}
                  totalPages={pageData.totalPages}
                  totalItems={pageData.totalItems}
                  pageSize={pageData.pageSize}
                  page={page}
                  loading={loadingOrders}
                  selectable
                  downloadToolTipText='Descargar todas las guías'
                  dataType='orders'
                  assignStatus={assignStatus}
                />

                <Drawer
                  anchor='right'
                  open={openDetail}
                  onClose={() => {
                    setOpenDetail(false);
                  }}
                >
                  <DetailOrder
                    data={detailData}
                    onClose={() => {
                      setOpenDetail(false);
                    }}
                  />
                </Drawer>

                <Drawer
                  anchor='right'
                  open={openEdit}
                  onClose={() => {
                    setReprogram(false);
                    setEditId(null);
                    setOpenEdit(false);
                  }}
                >
                  <FlexContainer
                    width='1300px'
                    position='relative'
                    padding='20px 30px 50px'
                    direction='column'
                    height='100%'
                  >
                    <CloseIcon
                      onClick={() => {
                        setEditId(null);
                        setOpenEdit(false);
                      }}
                    >
                      <Icon
                        icon='cancel-icon'
                        color={THEME.colors.placeholderColor}
                        size={25}
                      />
                    </CloseIcon>
                    <Edit
                      reprogram={reprogram}
                      editId={editId}
                      onReprogram={() => {
                        setReprogram(false);
                        setEditId(null);
                        setOpenEdit(false);
                        requestOrders(true);
                      }}
                      onEdit={() => {
                        requestOrders(true);
                      }}
                    />
                  </FlexContainer>
                </Drawer>

                <Drawer
                  anchor='right'
                  open={openProviderDetail}
                  onClose={() => {
                    setOpenProviderDetail(false);
                  }}
                >
                  <FlexContainer
                    width='530px'
                    minHeight='100%'
                    position='relative'
                    padding='50px 40px'
                  >
                    <CloseIcon
                      onClick={() => {
                        setOpenProviderDetail(false);
                      }}
                    >
                      <Icon
                        icon='cancel-icon'
                        color={THEME.colors.placeholderColor}
                        size={25}
                      />
                    </CloseIcon>
                    <FlexContainer
                      container
                      height='100%'
                      direction='column'
                      padding='60px 50px'
                      borderRadius='10px'
                      borderColor={THEME.colors.borderColor}
                      backgroundColor={THEME.colors.backgroundColor}
                    >
                      <FlexContainer container justify='center'>
                        <img
                          src={pickProviderIcon(selectedProvider, false)}
                          alt='provider_logo'
                          style={{
                            width: 'auto',
                            height: 'auto',
                            marginBottom: '50px',
                            maxWidth: '300px',
                            minWidth: '179px',
                            maxHeight: '65px',
                            minHeight: '55px',
                          }}
                        />
                      </FlexContainer>
                      {selectedProvider === 'olva' && (
                        <>
                          <Typography
                            fontSize={12}
                            color='text.secondary'
                            margin='0 0 20px'
                          >
                            {olvaData.description}
                          </Typography>
                          <FlexContainer margin='0 0 10px'>
                            <Typography
                              fontSize={12}
                              color='text.secondary'
                              fontWeight={600}
                            >
                              Horario de corte:
                            </Typography>
                            <FlexContainer
                              direction='column'
                              margin='0 0 0 5px'
                            >
                              {olvaData.cut_off_schedule.map(schedule => (
                                <Typography
                                  fontSize={12}
                                  color='text.secondary'
                                  margin='0 0 5px'
                                >
                                  {schedule}
                                </Typography>
                              ))}
                            </FlexContainer>
                          </FlexContainer>
                          <FlexContainer margin='0 0 10px'>
                            <Typography
                              fontSize={12}
                              color='text.secondary'
                              fontWeight={600}
                            >
                              Horario de recojo:
                            </Typography>
                            <Typography
                              fontSize={12}
                              color='text.secondary'
                              margin='0 0 0 5px'
                            >
                              {olvaData.pickup_schedule}
                            </Typography>
                          </FlexContainer>
                          <Typography
                            fontSize={12}
                            color='text.secondary'
                            margin='0 0 10px'
                          >
                            {olvaData.extra_info}
                          </Typography>
                        </>
                      )}
                      {selectedProvider === '99minutos' && (
                        <>
                          <Typography
                            fontSize={12}
                            color='text.secondary'
                            margin='0 0 20px'
                          >
                            {minutesData.description}
                          </Typography>
                          <FlexContainer direction='column' margin='0 0 10px'>
                            <Typography
                              fontSize={12}
                              color='text.secondary'
                              fontWeight={600}
                            >
                              Horario de corte:
                            </Typography>
                            <FlexContainer
                              direction='column'
                              margin='5px 0 0 10px'
                            >
                              {minutesData.cut_off_schedule.map(schedule => (
                                <Typography
                                  fontSize={12}
                                  color='text.secondary'
                                  margin='0 0 5px'
                                >
                                  - {schedule}
                                </Typography>
                              ))}
                            </FlexContainer>
                          </FlexContainer>
                          <Typography
                            fontSize={12}
                            color='text.secondary'
                            margin='0 0 10px'
                          >
                            {minutesData.extra_info}
                          </Typography>
                        </>
                      )}
                    </FlexContainer>
                  </FlexContainer>
                </Drawer>
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>
      )}
    </>
  );
};

export default OrderList;
