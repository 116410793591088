import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List';

const Orders: React.FC<{
  handleOpenFilters: () => void;
  filterValues: any;
  filterData: boolean;
  setFilterValues: any;
  resetFilters: boolean;
}> = ({
  handleOpenFilters,
  filterValues,
  filterData,
  setFilterValues,
  resetFilters,
}) => (
  <Switch>
    <Route
      exact
      path='/ordenes-de-compra'
      render={() => (
        <List
          handleOpenFilters={() => handleOpenFilters()}
          filterValuess={filterValues}
          filterData={filterData}
          setFilterValuess={setFilterValues}
          resetFilters={resetFilters}
        />
      )}
    />
  </Switch>
);

export default Orders;
