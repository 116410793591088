import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { useTypedSelector } from 'store';
import Login from './Login';
import Webapp from './Webapp';
import Signup from './Signup';
import Verify from './Verify';
import ShopIntegration from './Webapp/Shopify/ShopIntegration';
import RequestPassword from './RequestPassword';
import NewPassword from './NewPassword';
import UserInvitation from './UserInvitation';
import Onboarding from './Onboarding';

const Router = () => {
  const session = useTypedSelector(state => state.session);
  const selectedOrganization = useTypedSelector(
    state => state.organization.selectedOrganization
  );
  const authorize = session && selectedOrganization;
  const first_login = session
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Switch>
        <Route
          exact
          path='/sign-up'
          render={() => {
            if (authorize) {
              return <Redirect to='/' />;
            }
            else if (first_login){
              return <Redirect to='/onboarding' />;
            }
            return <Signup />;
          }}
        />
        <Route
          exact
          path='/user-invite'
          render={() => {
            if (authorize) {
              return <Redirect to='/' />;
            }
            else if (first_login){
              return <Redirect to='/onboarding' />;
            }
            return <UserInvitation />;
          }}
        />
        <Route
          exact
          path='/signup'
          render={() => {
            if (authorize) {
              return <Redirect to='/' />;
            }
            else if (first_login){
              return <Redirect to='/onboarding' />;
            }
            return <Verify />;
          }}
        />
        <Route
          exact
          path='/request-password'
          render={() => {
            if (authorize) {
              return <Redirect to='/' />;
            }
            else if (first_login){
              return <Redirect to='/onboarding' />;
            }
            return <RequestPassword />;
          }}
        />
        <Route
          exact
          path='/onboarding'
          render={() => {
            if (!first_login) {
              return <Redirect to='/login' />;
            }
            return <Onboarding />;
          }}
        />
        <Route
          exact
          path='/password-recovery'
          render={() => {
            if (authorize) {
              return <Redirect to='/' />;
            }
            else if (first_login){
              return <Redirect to='/onboarding' />;
            }
            return <NewPassword />;
          }}
        />
        <Route
          exact
          path='/shopify'
          render={() => {
            return <ShopIntegration />;
          }}
        />
        <Route
          exact
          path='/login'
          render={() => {
            if (authorize) {
              const shopify = window.localStorage.getItem('shopify');
              if (shopify) {
                const shopifyData = JSON.parse(shopify);
                if (shopifyData.shop && shopifyData.state) {
                  return (
                    <Redirect
                      to={`/shopify?shop=${shopifyData.shop}&state=${shopifyData.state}`}
                    />
                  );
                }
                return <Redirect to='/' />;
              }
              return <Redirect to='/' />;
            }
            return <Login />;
          }}
        />
        <Route
          path='/'
          render={() => {
            if (!authorize) {
              return <Redirect to='/login' />;
            }
            else if (authorize){
              return <Webapp />;
            }
            return <Redirect to='/onboarding' />;
          }}
        />
      </Switch>
    </SnackbarProvider>
  );
};

export default Router;
