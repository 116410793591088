import React from 'react';
import styled from 'styled-components';
import { FlexContainer, Icon, Typography } from 'components/atoms';
import { OptionProps } from './types';

const OptionContainer = styled(FlexContainer)`
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.backgroundColor};
    color: ${props => props.theme.colors.primary};
  }
`;

const DropdownIconOption: React.FC<OptionProps> = ({
  label,
  name,
  icon,
  secondLabel,
  onClick,
}) => (
  <OptionContainer
    key={name}
    container
    alignItems='center'
    padding='15px 15px'
    onClick={() => onClick()}
  >
    <Icon icon={icon} size={20} margin='0 15px 0 0' />
    <FlexContainer direction='column'>
      <Typography color='text.secondary' fontSize={13}>
        {label}
      </Typography>
      {secondLabel && (
        <Typography color='text.secondary' fontSize={13}>
          {secondLabel}
        </Typography>
      )}
    </FlexContainer>
  </OptionContainer>
);

export default DropdownIconOption;
