import React from 'react';
import THEME from 'util/styledTheme';
import { FlexContainer, Typography } from 'components/atoms';
import aimoLogo from 'assets/images/AimoLogoIcon.svg';
import orderEtaImage from 'assets/images/emails/mailing-eta.png';
import calendarImage from 'assets/images/emails/mailing-calendar-image.png';
import { EmailTemplateProps } from './types';

const AssignedOrder: React.FC<EmailTemplateProps> = ({ organizationPhoto }) => {
  return (
    <FlexContainer container direction='column' alignItems='center'>
      <FlexContainer
        container
        direction='column'
        alignItems='center'
        backgroundColor='#FFFFFFFF'
      >
        <img
          src={organizationPhoto}
          alt='organization-logo'
          style={{
            height: 'auto',
            width: 'auto',
            maxHeight: '150px',
            maxWidth: '200px',
            paddingTop: '1.4rem',
          }}
        />
        <img src={orderEtaImage} alt='order-eta' />

        <Typography fontSize='38px' fontWeight='700' margin='20px 0'>
          ¡Hola John Doe!
        </Typography>
        <Typography fontSize='22px'>
          Tu Orden N° CODE-TEST-01 se entregará:
        </Typography>

        <FlexContainer
          margin='20px 0'
          borderColor='#e2e2e2'
          padding='20px'
          alignItems='center'
          justify='center'
        >
          <img
            src={calendarImage}
            alt='calendar'
            style={{ height: '30px', margin: '0 20px' }}
          />
          <FlexContainer direction='column'>
            <Typography>Lunes 14 de Diciembre.</Typography>
            <Typography fontWeight='700'>11:00 am y 1:00 pm</Typography>
          </FlexContainer>
        </FlexContainer>

        <Typography fontSize='18px' fontWeight='700' margin='20px 0 10px 0'>
          Entrega a:
        </Typography>
        <Typography fontSize='18px'>John Doe</Typography>
        <Typography fontSize='18px' fontWeight='700' margin='20px 0 10px 0'>
          Dirección:
        </Typography>
        <Typography fontSize='18px' margin='0 0 30px 0'>
          Av. Alfredo Benavides 1944, Santiago de Surco
        </Typography>
      </FlexContainer>

      <FlexContainer alignItems='center' margin='30px 0 0 0'>
        <FlexContainer
          backgroundColor='#FFFFFF'
          alignItems='center'
          justify='center'
          borderColor={THEME.colors.borderColor}
          padding='5px'
          borderRadius='8px'
          style={{ boxShadow: '0 3px 6px 0 #e7e7f0' }}
        >
          <img src={aimoLogo} alt='aimo-logo' style={{ height: '18px' }} />
        </FlexContainer>
        <Typography
          color='text.primary'
          fontWeight='700'
          fontSize='16px'
          margin='0 4px'
        >
          Powered by Aimo
        </Typography>
      </FlexContainer>
    </FlexContainer>
  );
};

export default AssignedOrder;
