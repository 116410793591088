import { Box } from '@material-ui/core';
import styled from 'styled-components';

const Typography = styled(Box)`
  color: ${props => props.color || props.theme.colors.textPrimary};
  margin: ${props => props.margin || '0'};
  width: ${props => props.width || 'auto'};
  ${props => props.textAlign && `text-align: ${props.textAlign};`}
`;

export default Typography;
