import React from 'react';
import styled from 'styled-components';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Icon } from 'components/atoms';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { TimeProps } from './types';

moment.locale('es');

const TimePickerContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #efeff5;
  border-image: initial;
  background-color: #fff;
  padding: 0 21px;
  input {
    font-size: 13px;
  }
  .MuiInputAdornment-root {
    display: none;
  }
  .MuiFormHelperText-root {
    display: none;
  }
  .MuiInput-underline:before {
    display: none;
  }
  .MuiInput-underline:after {
    display: none;
  }
`;

const Time: React.FC<TimeProps> = ({
  placeholder,
  value,
  onChange,
  show,
  icon,
  format = 'HH:mm',
}) => {
  return (
    <TimePickerContainer>
      {icon && <Icon icon='time-icon' size='30px' margin='0 10px 0 0' />}
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale='es'
      >
        <KeyboardTimePicker
          disableToolbar
          label=''
          mask='__:__'
          views={show || ['hours', 'minutes']}
          format={format}
          clearable
          value={value}
          onChange={parseTime => onChange(parseTime)}
          ampm={false}
          placeholder={placeholder || 'HH:MM'}
        />
      </MuiPickersUtilsProvider>
    </TimePickerContainer>
  );
};

export default Time;
