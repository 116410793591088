import React, { useEffect, useState } from 'react';
import { FlexContainer } from 'components/atoms';
import { useTypedSelector } from 'store';
import { getMeasurementUnits, getRouteTypes } from 'services';
import useRequest from 'hooks/useRequest';
import useWindowSize from 'hooks/useWindowSize';
import THEME from 'util/styledTheme';
import Parameters from './Parameters';
import Orders from './Orders';
import Preview from './Preview';
import { CreateProps } from './types';

const CreateView: React.FC<CreateProps> = ({
  step,
  onRequestOrders,
  loadingOrders,
  orders,
  focusOrder,
  origin,
  onOriginChange,
  timeWindow,
  onWindowChange,
  routeOrigins,
  windowOptions,
  vehicles,
  selectedOrders,
  onSelectOrder,
  paramValues,
  onParamChange,
  selectedVehicles,
  onVehicleChange,
  previewLoader,
  previewRoutes,
  onRouteChange,
  onTaskClick,
  updateLoader,
  createLoader,
}) => {
  const size = useWindowSize();
  const branchOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  // Values
  const [blockWidth, setBlockWidth] = useState<string>('320px');
  // API calls
  const [measurementUnits, fetchMeasurementUnits] = useRequest(
    getMeasurementUnits
  );
  const [routeTypes, fetchRouteTypes] = useRequest(getRouteTypes, []);

  useEffect(() => {
    if (size.width < 768) {
      setBlockWidth('100%');
    } else {
      switch (step) {
        case 'orders':
          setBlockWidth('320px');
          break;
        case 'parameters':
          setBlockWidth('600px');
          break;
        case 'preview':
          setBlockWidth('320px');
          break;
        default:
          break;
      }
    }
  }, [step, size]);

  useEffect(() => {
    fetchMeasurementUnits();
    fetchRouteTypes();
  }, [fetchMeasurementUnits, fetchRouteTypes, branchOfficeId]);

  return (
    <FlexContainer
      width={blockWidth}
      height='100%'
      backgroundColor='#FFFFFF'
      borderColor={THEME.colors.borderColor}
      direction='column'
      overflow='auto'
      padding='0 0 50px'
    >
      {step === 'orders' && (
        <Orders
          orders={orders}
          loading={loadingOrders}
          focusOrder={focusOrder}
          selectedOrders={selectedOrders}
          onSelectOrder={(selectedRows, checked) =>
            onSelectOrder(selectedRows, checked)
          }
          originOptions={routeOrigins}
          windowOptions={windowOptions}
          origin={origin}
          onOriginChange={(value, keep) => {
            onOriginChange(value);
            onRequestOrders(value, keep);
          }}
          timeWindow={timeWindow}
          onWindowChange={value => onWindowChange(value)}
        />
      )}
      {step === 'parameters' && (
        <Parameters
          routeTypes={routeTypes}
          measurementUnits={measurementUnits.results || []}
          vehicles={vehicles}
          values={paramValues}
          onChange={(value, name) => {
            onParamChange(value, name);
          }}
          selectedVehicles={selectedVehicles}
          onSelectVehicle={selectedRows => onVehicleChange(selectedRows)}
          showLoader={previewLoader}
        />
      )}
      {step === 'preview' && (
        <Preview
          routes={previewRoutes}
          onTaskClick={task => onTaskClick(task)}
          onRouteChange={newRoutes => onRouteChange(newRoutes)}
          updateLoader={updateLoader}
          createLoader={createLoader}
        />
      )}
    </FlexContainer>
  );
};

export default CreateView;
