import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import { isEmpty } from 'lodash';
import { Drawer, Dialog } from '@material-ui/core';
import { Button, FlexContainer, Typography, Icon } from 'components/atoms';
import { Dropdown, DropdownIconOption } from 'components/molecules';
import { Header, DataTable, SearchWithFilter } from 'components/organisms';
import { getCustomers, deleteCustomer } from 'services';
import THEME from 'util/styledTheme';
import Whatsapp from 'assets/images/whatsapp-green-icon.svg';
import EditCustomer from './Edit';

const options = [
  {
    name: 'Nombre',
    id: 'full_name',
  },
  {
    name: 'Doc. Identidad',
    id: 'id_document',
  },
  {
    name: 'Correo',
    id: 'email',
  },
  {
    name: 'Télefono',
    id: 'phone_number',
  },
  {
    name: 'Dirección',
    id: 'address',
  },
  {
    name: 'Detalle de dirección',
    id: 'address_detail',
  },
];

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 15px;
`;

const WhatsappContainer = styled(FlexContainer)`
  margin: 5px 0;
  padding: 5px 8px;
  border: 1px solid #f0effb;
  border-radius: 8px;
`;

const WhatsappLink = styled.a`
  margin-left: 5px;
  color: #4d5369;
  text-decoration: none;
`;

const Clients: React.FC = () => {
  const showMenu = useTypedSelector(state => state.sidebar);
  const { enqueueSnackbar } = useSnackbar();

  // API calls
  const [customers, fetchCustomers, loadingCustomers, pageData] = useRequest(
    getCustomers,
    []
  );

  const [, fetchDeleteCustomer, , , , statusDelete] = useRequest(
    deleteCustomer
  );

  // Values
  const [page, setPage] = useState<number>(1);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>('');
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState(null);
  const [customerDeleteId, setCustomerDeleteId] = useState(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const requestCustomers = (keepRows?: boolean, isSearch?: boolean) => {
    const params = {
      page_size: 20,
      page: isSearch ? 1 : page,
    };

    if (searchFilter !== '') {
      params[searchFilter] = searchInput;
    }

    fetchCustomers(params);
  };

  useEffect(() => {
    requestCustomers();
  }, [page, fetchCustomers]);

  useEffect(() => {
    if (statusDelete === 204) {
      setCustomerDeleteId(null);
      enqueueSnackbar('El cliente se eliminó correctamente.', {
        variant: 'success',
      });
      requestCustomers();
    }
  }, [statusDelete]);

  const settingsOptions = [
    {
      label: 'Editar',
      name: 'edit',
      icon: 'edit-icon',
      onClick: row => {
        setOpenEdit(true);
        setCustomerId(row.id);
      },
    },
    {
      label: 'Eliminar',
      name: 'delete',
      icon: 'delete-icon-2',
      onClick: row => {
        setOpenConfirm(true);
        setCustomerDeleteId(row.id);
      },
    },
  ];

  const option = row =>
    settingsOptions
      .filter(setting => setting)
      .map(setting => (
        <DropdownIconOption
          key={setting.name}
          label={setting.label}
          name={setting.name}
          icon={setting.icon}
          onClick={() => setting.onClick(row)}
        />
      ));

  const headers = useMemo(
    () => [
      {
        label: 'Contacto',
        id: 'name',
        cell: row => (
          <FlexContainer direction='column'>
            <Typography color='#4D5369' fontWeight='700'>
              {row.full_name}
            </Typography>
            {(row.phone_number || row.phone_number !== '') && (
              <WhatsappContainer>
                <img src={Whatsapp} alt='whatsapp' />
                <WhatsappLink href={`https://wa.me/${row.phone_number}`}>
                  {row.phone_number}
                </WhatsappLink>
              </WhatsappContainer>
            )}
          </FlexContainer>
        ),
      },
      {
        label: 'Email',
        id: 'email',
        cell: row => <Typography>{row.email ? row.email : '-'}</Typography>,
      },
      {
        label: 'Documento de Identidad',
        id: 'id_document',
        cell: row => (
          <Typography>{row.id_document ? row.id_document : '-'}</Typography>
        ),
      },
      {
        label: 'Dirección',
        id: 'address',
        cell: row => <Typography>{row.address ? row.address : '-'}</Typography>,
      },
      {
        label: '',
        id: 'settings',
        cell: row => (
          <Dropdown
            label={<Icon icon='options-icon' size={20} />}
            direction='bottom'
            position='right'
            showCaret={false}
            options={option(row)}
            padding='0 10px'
            hover
            disabled={isEmpty(option(row))}
          />
        ),
      },
    ],
    [customers]
  );

  return (
    <FlexContainer container height='100%'>
      <Header showMenu={showMenu} />
      <FlexContainer
        direction='column'
        container
        padding='50px 0 0'
        height='100vh'
      >
        <Typography fontSize={19} fontWeight={600} padding='35px 40px 0'>
          Mis clientes
        </Typography>
        <FlexContainer container padding='30px 40px' direction='column'>
          <FlexContainer
            container
            justify='space-between'
            margin='0 0 20px'
            padding='20px'
            backgroundColor='#FFFFFF'
            borderRadius='4px'
            borderColor={THEME.colors.borderColor}
          >
            <FlexContainer container>
              <SearchWithFilter
                filterLabel='Buscar por'
                filterValue={searchFilter}
                onChangeFilterValue={value => setSearchFilter(value)}
                options={options}
                onChangeSearchValue={value => setSearchInput(value)}
                searchValue={searchInput}
                onSubmit={() => requestCustomers(false, true)}
                maxWidth='500px'
                placeholder='Buscar'
                inputWidth='330px'
              />
              <Button
                variant='contained'
                color='primary'
                margin='0'
                onClick={() => requestCustomers(false, true)}
              >
                Buscar
              </Button>
            </FlexContainer>
            <FlexContainer container justify='flex-end'>
              <Button
                variant='outlined'
                color='primary'
                margin='0'
                onClick={() => setOpenCreate(true)}
              >
                Crear cliente
              </Button>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer container direction='column'>
            <DataTable
              headers={headers.filter(header => header)}
              data={customers}
              // selectedRows={selectedRows}
              // onSelectRow={rows => setSelectedRows(rows)}
              onChangePage={selectedPage => setPage(selectedPage)}
              totalPages={pageData.totalPages}
              totalItems={pageData.totalItems}
              pageSize={pageData.pageSize}
              page={page}
              loading={loadingCustomers}
              // selectable
            />
          </FlexContainer>
        </FlexContainer>

        <Drawer
          anchor='right'
          open={openCreate || openEdit}
          onClose={() => {
            setOpenCreate(false);
            setOpenEdit(false);
            setCustomerId(null);
          }}
        >
          <FlexContainer
            width='700px'
            height='100%'
            position='relative'
            padding='40px 50px 50px'
            direction='column'
          >
            <CloseIcon
              onClick={() => {
                setCustomerId(null);
                setOpenEdit(false);
                setOpenCreate(false);
              }}
            >
              <Icon
                icon='cancel-icon'
                color={THEME.colors.placeholderColor}
                size={25}
              />
            </CloseIcon>
            <EditCustomer
              customerId={customerId}
              onRequest={() => requestCustomers()}
            />
          </FlexContainer>
        </Drawer>
      </FlexContainer>
      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
        <FlexContainer
          padding='30px'
          direction='column'
          alignItems='center'
          justify='center'
        >
          <Typography color='#4131D3' fontSize={15} margin='0 0 20px'>
            Eliminar Cliente
          </Typography>
          <Typography margin='0 0 30px' fontSize={13}>
            ¿Estás seguro de eliminar este cliente?
          </Typography>
          <FlexContainer container alignItems='center' justify='space-between'>
            <Button
              variant='contained'
              color='primary'
              fontSize='13px'
              onClick={() => {
                fetchDeleteCustomer(customerDeleteId);
                setOpenConfirm(false);
              }}
            >
              Eliminar
            </Button>

            <Button fontSize='14px' onClick={() => setOpenConfirm(false)}>
              Cancelar
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Dialog>
    </FlexContainer>
  );
};

export default Clients;
