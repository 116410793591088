import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Checkbox, InputAdornment, IconButton, Grid } from '@material-ui/core';
import { FlexContainer, Typography, Button, Icon } from 'components/atoms';
import { FormControl } from 'components/molecules';
import AimoLogoFull from 'assets/images/AimoLogoFull.svg';

const Container = styled(FlexContainer)`
  padding: 25px;
  @media only screen and (min-width: 768px) {
    padding: 30px;
  }
`;

const FormContainer = styled(FlexContainer)`
  width: 100%;

  @media only screen and (min-width: 560px) and (max-width: 767px) {
    width: 500px;
  }

  @media only screen and (min-width: 768px) and (max-width: 959px) {
    width: 350px;
  }

  @media only screen and (min-width: 960px) {
    width: 480px;
  }
`;

const HeaderContainer = styled(Grid)`
  align-self: flex-start;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-left: 3px;
  color: ${props => props.theme.colors.primary};
  &:hover {
    color: blue;
  }
`;

const ConditionsLink = styled.a`
  text-decoration: none;
  margin-left: 3px;
  color: ${props => props.theme.colors.primary};
  &:hover {
    color: blue;
  }
`;

const FirstStep = ({
  onSend,
  form,
  onFormChange,
  validate,
  errors,
  loading,
  referralCode,
}) => {
  const [isAgree, setIsAgree] = useState(false);
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [canSignUp, setCanSignUp] = useState<boolean>(false);

  const validateForm = () => {
    form.email = form.email.trim().toLowerCase();
    if (validate()) {
      onSend();
    }
  };

  useEffect(() => {
    if (form.email !== '' && form.password !== '' && !isAgree) {
      setCanSignUp(true);
    } else {
      setCanSignUp(false);
    }
  }, [form, isAgree]);

  return (
    <Container container direction='column' height='100%'>
      <HeaderContainer item>
        <a href='https://aimo.co'>
          <img src={AimoLogoFull} alt='logo_full' />
        </a>
      </HeaderContainer>
      <FlexContainer
        container
        width='100%'
        height='100%'
        justify='center'
        alignItems='center'
      >
        <FormContainer direction='column'>
          <Typography
            fontWeight={600}
            fontSize='27px'
            margin='50px 0 0'
            color='#000000'
          >
            Regístrame
          </Typography>

          <FormControl
            placeholder='Correo electrónico*'
            onChange={value => onFormChange(value, 'email')}
            value={form.email}
            margin='50px 0px 40px'
            error={errors.email}
            width='100%'
            disabled={loading}
            variant='standard'
          />
          <FormControl
            placeholder='Contraseña*'
            onChange={value => onFormChange(value, 'password')}
            value={form.password}
            type={viewPassword ? 'text' : 'password'}
            margin='0 0px 50px'
            error={errors.password}
            width='100%'
            disabled={loading}
            variant='standard'
            endAdornment={
              <InputAdornment position='end'>
                <IconButton
                  size='small'
                  onClick={() => setViewPassword(!viewPassword)}
                >
                  <Icon
                    size='16px'
                    icon={viewPassword ? 'no-show-icon' : 'show-icon'}
                  />
                </IconButton>
              </InputAdornment>
            }
          />

          <FlexContainer container margin='5px 0 0' alignItems='center'>
            <Checkbox
              color='primary'
              onChange={e => setIsAgree(!e.target.checked)}
              checked={!isAgree}
            />
            <FlexContainer direction='column' margin='5px 0 0 10px'>
              <Typography fontSize='12px' color='#0000000'>
                Al registrar una cuenta confirmas que conoces y aceptas nuestros
                <ConditionsLink
                  href='https://aimo.co/terms-conditions.html'
                  target='_blank'
                >
                  Términos y condiciones{' '}
                </ConditionsLink>
                y
                <ConditionsLink
                  href={
                    referralCode
                      ? 'https://aimo.co/privacy-policy-crece.html'
                      : 'https://aimo.co/privacy-policy.html'
                  }
                  target='_blank'
                >
                  Políticas de privacidad
                </ConditionsLink>
              </Typography>
            </FlexContainer>
          </FlexContainer>

          <Grid container alignItems='center' direction='column'>
            <Button
              height='45px'
              width='177px'
              borderRadius='16px'
              variant='contained'
              color='primary'
              onClick={() => validateForm()}
              disabled={!canSignUp}
              margin='50px 0 0'
            >
              <Typography
                fontWeight='700'
                fontSize='15px'
                color='white'
                letterSpacing='0.5px'
              >
                Registrarme
              </Typography>
            </Button>
          </Grid>

          <FlexContainer container margin='50px 0 0'>
            <FlexContainer container justify='center' padding='0 30px'>
              <Typography color='#0000000' textAlign='center' fontSize='15px'>
                ¿Tienes una cuenta?
                <StyledLink to='/login'>Inicia sesión.</StyledLink>
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </FormContainer>
      </FlexContainer>
    </Container>
  );
};

export default FirstStep;
