import React from 'react';
import styled from 'styled-components';
import THEME from 'util/styledTheme';
import { FlexContainer, Typography } from 'components/atoms';
import aimoLogo from 'assets/images/AimoLogoIcon.svg';
import trackingMapImage from 'assets/images/emails/mailing-tracking.png';
import truckImage from 'assets/images/emails/mailing-truck-image.png';
import { EmailTemplateProps } from './types';

const CustomButton = styled.button`
  background-color: #404071;
  border-radius: 5px;
  width: 320px;
  margin-bottom: 40px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;

const TrackingLink: React.FC<EmailTemplateProps> = ({ organizationPhoto }) => {
  return (
    <FlexContainer container direction='column' alignItems='center'>
      <FlexContainer
        container
        direction='column'
        alignItems='center'
        backgroundColor='#FFFFFFFF'
      >
        <img
          src={organizationPhoto}
          alt='organization-logo'
          style={{
            height: 'auto',
            width: 'auto',
            maxHeight: '150px',
            maxWidth: '200px',
            paddingTop: '1.4rem',
          }}
        />
        <img src={trackingMapImage} alt='order-eta' />

        <Typography fontSize='38px' fontWeight='700' margin='20px 0'>
          Haz seguimiento a tu pedido
        </Typography>
        <Typography fontSize='22px' margin='0 0 30px 0'>
          Sigue tu despacho desde este enlace:
        </Typography>

        <CustomButton>
          <FlexContainer>
            <img
              src={truckImage}
              alt='truck'
              style={{ width: '42px', height: '32px', margin: 'auto' }}
            />
            <div style={{ borderLeft: '2px solid white', height: '60px' }} />
            <FlexContainer
              alignItems='center'
              height='100%'
              style={{ margin: 'auto' }}
            >
              <Typography fontSize='16px' fontWeight='700' color='white'>
                Seguimiento de mi Pedido
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </CustomButton>
      </FlexContainer>

      <FlexContainer alignItems='center' margin='30px 0 0 0'>
        <FlexContainer
          backgroundColor='#FFFFFF'
          alignItems='center'
          justify='center'
          borderColor={THEME.colors.borderColor}
          padding='5px'
          borderRadius='8px'
          style={{ boxShadow: '0 3px 6px 0 #e7e7f0' }}
        >
          <img src={aimoLogo} alt='aimo-logo' style={{ height: '18px' }} />
        </FlexContainer>
        <Typography
          color='text.primary'
          fontWeight='700'
          fontSize='16px'
          margin='0 4px'
        >
          Powered by Aimo
        </Typography>
      </FlexContainer>
    </FlexContainer>
  );
};

export default TrackingLink;
