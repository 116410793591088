import React, { useState, useEffect } from 'react';
import { Grid, InputAdornment, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import THEME from 'util/styledTheme';
import { Button, FlexContainer, Typography, Icon } from 'components/atoms';
import { FormControl } from 'components/molecules';
import AimoLogoFull from 'assets/images/AimoLogoFull.svg';
import {
  LoginContainerProps,
  LoginControlProps,
  OrganizationControlProps,
} from './types';

const Container = styled(FlexContainer)`
  height: 100%;
  padding: 25px;
  @media only screen and (min-width: 768px) {
    padding: 30px;
  }
`;

const FormContainer = styled(FlexContainer)`
  width: 100%;
  padding: 40px 0;

  @media only screen and (min-width: 560px) and (max-width: 767px) {
    width: 500px;
  }

  @media only screen and (min-width: 768px) and (max-width: 959px) {
    width: 350px;
    padding: 0;
  }

  @media only screen and (min-width: 960px) {
    width: 480px;
    padding: 0;
  }
`;

const HeaderContainer = styled(Grid)`
  align-self: flex-start;
`;

const OrganizationsContainer = styled(FlexContainer)`
  padding: 70px 0;

  @media only screen and (min-width: 960px) {
    padding: 0;
  }
`;

const OrganizationContainer = styled(FlexContainer)`
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.primary};
  background-color: #fffff;
  color: ${props => props.theme.colors.primary};
  font-size: 13px;
  &:hover {
    background-color: ${props => props.theme.colors.primaryHover};
    color: #fff;
  }
  transition: all 0.2s;
`;

const StyledTypography = styled(Typography)`
  cursor: pointer;
  color: ${THEME.colors.primary};
  &:hover {
    color: blue;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-left: 3px;
  color: ${props => props.theme.colors.primary};
  &:hover {
    color: blue;
  }
`;

const LoginControl: React.FC<LoginControlProps> = ({
  form,
  onChange,
  loading,
  onLoginSubmit,
}) => {
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [canLogin, setCanLogin] = useState<boolean>(false);

  useEffect(() => {
    if (form.email !== '' && form.password !== '' && !loading) {
      setCanLogin(true);
    } else {
      setCanLogin(false);
    }
  }, [form, loading]);

  return (
    <FlexContainer
      container
      width='100%'
      height='100%'
      justify='center'
      alignItems='center'
    >
      <FormContainer direction='column'>
        <Typography
          fontWeight={600}
          fontSize='27px'
          margin='50px 0 15px'
          color='#000000'
        >
          Ingresar
        </Typography>
        <FormControl
          width='100%'
          placeholder='Correo electrónico*'
          value={form.email}
          onChange={value => onChange(value, 'email')}
          margin='50px 0px'
          disabled={loading}
          variant='standard'
        />
        <FormControl
          width='100%'
          placeholder='Contraseña*'
          type={viewPassword ? 'text' : 'password'}
          value={form.password}
          onChange={value => onChange(value, 'password')}
          disabled={loading}
          variant='standard'
          onPressEnter={() => onLoginSubmit()}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                size='small'
                onClick={() => setViewPassword(!viewPassword)}
              >
                <Icon
                  size='16px'
                  icon={viewPassword ? 'no-show-icon' : 'show-icon'}
                />
              </IconButton>
            </InputAdornment>
          }
        />
        <FlexContainer container margin='15px 0px 50px' justify='flex-end'>
          <Link to='/request-password'>
            <StyledTypography fontSize='13px'>
              ¿Olvidaste tu contraseña?
            </StyledTypography>
          </Link>
        </FlexContainer>
        <FlexContainer container alignItems='center' direction='column'>
          <Button
            height='45px'
            width='177px'
            borderRadius='16px'
            variant='contained'
            color='primary'
            onClick={() => onLoginSubmit()}
            disabled={!canLogin}
          >
            Iniciar sesión
          </Button>
        </FlexContainer>
        <FlexContainer container margin='50px 0 0' justify='center'>
          <Typography color='#000000' fontSize='15px'>
            ¿No tienes cuenta?
            <StyledLink to='/sign-up'>Registrarme</StyledLink>
          </Typography>
        </FlexContainer>
      </FormContainer>
    </FlexContainer>
  );
};

const OrganizationControl: React.FC<OrganizationControlProps> = ({
  organizationOptions,
  onOrganizationSelect,
}) => {
  return (
    <OrganizationsContainer
      container
      direction='column'
      height='100%'
      justify='center'
      alignItems='center'
    >
      <Typography
        fontSize={16}
        fontWeight={600}
        margin='0 0 5px 0'
        color='#000000'
      >
        Organizaciones
      </Typography>
      <Typography
        fontSize={14}
        fontWeight={300}
        margin='0 0 45px 0'
        color='#000000'
      >
        Seleccione la organización a la que perteneces
      </Typography>
      {organizationOptions.map(organization => (
        <React.Fragment key={organization.id}>
          <OrganizationContainer
            container
            alignItems='center'
            justify='center'
            height='45px'
            margin='5px 0 0'
            onClick={() => {
              onOrganizationSelect(organization, organization.typeOfUsers[0]);
            }}
          >
            {organization.name}
          </OrganizationContainer>
        </React.Fragment>
      ))}
    </OrganizationsContainer>
  );
};

const LoginContainer: React.FC<LoginContainerProps> = ({
  form,
  onChange,
  onLoginSubmit,
  loading,
  showOrganizationSelect,
  organizationOptions,
  onOrganizationSelect,
}) => {
  return (
    <Container container direction='column' alignItems='center'>
      <HeaderContainer item>
        <a href='https://aimo.co'>
          <img src={AimoLogoFull} alt='logo_full' />
        </a>
      </HeaderContainer>
      {showOrganizationSelect ? (
        <OrganizationControl
          organizationOptions={organizationOptions}
          onOrganizationSelect={onOrganizationSelect}
        />
      ) : (
        <LoginControl
          onChange={onChange}
          form={form}
          loading={loading}
          onLoginSubmit={onLoginSubmit}
        />
      )}
    </Container>
  );
};

export default LoginContainer;
