export type MenuType = {
  name: string;
  items: Array<SubMenuType>;
};

export type SubMenuType = {
  name: string;
  url: string;
  permission?: Array<string>;
};

const settingsMenu: Array<MenuType> = [
  {
    name: 'Configuración',
    items: [
      {
        name: 'Tu cuenta',
        url: '/configuracion/cuenta',
        permission: ['core.organization.retrieve', 'core.administrator.list'],
      },
      {
        name: 'Entregas',
        url: '/configuracion/entregas',
        permission: ['core.provider_integration.list'],
      },
      {
        name: 'Sucursales',
        url: '/configuracion/sucursales',
        permission: ['core.branch_office.list', 'core.coverage.list'],
      },
      {
        name: 'Experiencia del cliente',
        url: '/configuracion/experiencia',
      },
      // {
      //   name: 'Canales de venta',
      //   url: '/configuracion/canales-de-venta',
      //   permission: ['core.sales_channel.retrieve'],
      // },
    ],
  },
  {
    name: 'Developers',
    items: [
      {
        name: 'Acceso API',
        url: '/configuracion/tokens',
      },
      {
        name: 'Webhooks',
        url: '/configuracion/webhooks',
        permission: ['core.organization.retrieve'],
      },
      // {
      //   name: 'API Docs',
      //   url: 'https://developers.aimo.co/',
      // },
    ],
  },
];

export default settingsMenu;
