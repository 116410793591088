import React, { useEffect, useRef, useState, useCallback } from 'react';
import _, { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useTypedSelector } from 'store';
import Cropper from 'react-easy-crop';
import styled from 'styled-components';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { FormControl, Spinner } from 'components/molecules';
import {
  postProduct,
  getInstagramImages,
  getChannels,
  getInstagramOauthUrl,
} from 'services';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import { MenuItem, Select } from '@material-ui/core';
import { validateNumbersInput } from 'util/functions';
import UploadIcon from 'assets/images/upload-icon.svg';
import { ReactComponent as ArrowLeft } from 'assets/images/arrow-left.svg';
import {
  CreateProps,
  ComputerOptionProps,
  InstagramOptionProps,
} from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const DeleteIcon = styled(FlexContainer)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const SelectIcon = styled(FlexContainer)`
  position: absolute;
  top: 5px;
  left: 5px;
`;

const AddImage = styled(FlexContainer)`
  border-style: dotted;
`;

const validationState = {
  name: ['mandatory'],
  price: ['priceFloat', 'mandatory'],
};

const SelectDisabled = styled(FlexContainer)`
  .MuiSelect-select {
    cursor: not-allowed;
  }
  .Mui-disabled {
    background: white;
  }
`;

const ColoredLine = styled.hr`
  border: 1px solid #ebebed;
  width: 100%;
`;

const CropContainer = styled.div`
  position: relative;
  margin: 40px auto;
  width: 100%;
  height: 350px;

  @media only screen and (min-width: 1250px) {
    width: 90%;
    height: 400px;
  }
`;

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

async function getCroppedImg(imageSrc, pixelCrop) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob(file => {
      resolve({ file, cropped: URL.createObjectURL(file) });
    }, 'image/jpeg');
  });
}

const ComputerOption: React.FC<ComputerOptionProps> = ({
  handlePhotoInput,
  photoInput,
  handleUpload,
}) => (
  <FlexContainer container justify='center' margin='0 0 30px' height='500px'>
    <FlexContainer
      container
      justify='center'
      alignItems='center'
      direction='column'
      borderColor={THEME.colors.primary}
      height='450px'
      style={{
        borderStyle: 'dashed',
        borderWidth: '3px',
      }}
    >
      <FlexContainer
        container
        width='100%'
        height='100%'
        direction='column'
        alignItems='center'
        justify='center'
        cursor='pointer'
        onClick={() => handlePhotoInput()}
      >
        <FlexContainer
          direction='column'
          width='50%'
          justify='center'
          alignItems='center'
        >
          <img src={UploadIcon} alt='upload' />
          <Typography fontSize={15} margin='15px 0 0' textAlign='center'>
            Selecciona una imagen desde tu computadora
          </Typography>
          <FlexContainer
            direction='column'
            alignItems='center'
            margin='15px 0 0'
          >
            <Typography fontSize={12} textAlign='center' color='#8c8cb1'>
              Imagen cuadrada recomendada de 500 x 500 pixeles.
            </Typography>
            <Typography
              fontSize={12}
              margin='5px 0 0'
              textAlign='center'
              color='#8c8cb1'
            >
              Tamaño máximo del archivo 3MB MB.
            </Typography>
            <Typography
              fontSize={12}
              margin='5px 0 0'
              textAlign='center'
              color='#8c8cb1'
            >
              Tipos de imágenes incluidas: JPG o PNG.
            </Typography>
          </FlexContainer>
          {/* <Typography
            fontSize={11}
            color={THEME.colors.textSecondary}
            margin='5px 0 0'
          >
            (Máximo 3 imágenes)
          </Typography> */}
        </FlexContainer>
        <input
          ref={photoInput}
          type='file'
          accept='image/*'
          onChange={handleUpload}
          style={{ display: 'none' }}
          multiple
        />
      </FlexContainer>
    </FlexContainer>
  </FlexContainer>
);

const InstagramOption: React.FC<InstagramOptionProps> = ({
  channels,
  instagramImages,
  handleSelection,
  selectedImages,
  finishSelection,
  openPopUp,
  loadingChannels,
  loadingImages,
}) => (
  <FlexContainer container justify='center' height='100%' alignItems='center'>
    {loadingChannels ? (
      <FlexContainer container height='100%' alignItems='center'>
        <Spinner height='20px' spinnerSize={25} margin='0 0 50px' />
      </FlexContainer>
    ) : (
      <>
        {channels.length > 0 &&
        channels.filter(channel => channel.platform === 'instagram_shop')
          .length > 0 ? (
          <FlexContainer container justify='center' direction='column'>
            <FlexContainer
              container
              borderColor={THEME.colors.textPrimary}
              padding='10px 0 0'
              overflow='scroll'
              direction='column'
              height='450px'
            >
              {loadingImages ? (
                <FlexContainer container height='100%' alignItems='center'>
                  <Spinner height='20px' spinnerSize={25} margin='0 0 50px' />
                </FlexContainer>
              ) : (
                <>
                  {_.chunk(instagramImages, 5).map((images, index) => (
                    <FlexContainer container>
                      {images.map((image, pointer) => (
                        <FlexContainer
                          key={image}
                          position='relative'
                          width='150px'
                          height='150px'
                          margin='0 0px 20px 20px'
                          onClick={() => handleSelection(image)}
                        >
                          <img
                            src={image}
                            alt='product'
                            style={{
                              width: 150,
                              height: 150,
                              cursor: 'pointer',
                            }}
                          />
                          {selectedImages.includes(image) && (
                            <SelectIcon>
                              <Icon
                                icon='check-icon'
                                color={THEME.colors.placeholderColor}
                                size={20}
                              />
                            </SelectIcon>
                          )}
                        </FlexContainer>
                      ))}
                    </FlexContainer>
                  ))}
                </>
              )}
            </FlexContainer>
            <Button
              variant='contained'
              color='primary'
              onClick={() => finishSelection()}
              alignself='flex-end'
              style={{
                margin: '30px 0 0',
              }}
            >
              Continuar
            </Button>
          </FlexContainer>
        ) : (
          <FlexContainer
            container
            justify='center'
            alignItems='center'
            direction='column'
            borderColor={THEME.colors.primary}
            padding='10px'
            height='450px'
            style={{
              borderStyle: 'dashed',
              borderWidth: '3px',
            }}
          >
            <Typography
              fontSize={12}
              color={THEME.colors.textSecondary}
              margin='0 0 10px 0'
              fontWeight={600}
            >
              Utilizar imágenes de tu cuenta de Instagram
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() => openPopUp()}
            >
              Conectar con Instagram
            </Button>
          </FlexContainer>
        )}
      </>
    )}
  </FlexContainer>
);

const Create: React.FC<CreateProps> = ({
  onCreate,
  onClose,
  setDialogMaxWidth,
  clientId,
}) => {
  const photoInput = useRef<HTMLInputElement>();
  const user = useTypedSelector(state => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors, reset] = useForm(
    {
      name: '',
      price: '',
    },
    validationState
  );

  // API calls
  const [createdProduct, createProduct, loadingCreate] = useRequest(
    postProduct
  );
  const [
    instagramImagesList,
    fetchInstagramImages,
    loadingInstagramImages,
    ,
    statusRequest,
  ] = useRequest(getInstagramImages);
  const [channels, fetchChannels, loadingChannels] = useRequest(getChannels);

  // Values
  const [productImages, setProductImages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [instagramImages, setInstagramImages] = useState([]);
  const [activeUploadImages, setActiveUploadImages] = useState(true);
  const [activeInstagramImages, setActiveInstagramImages] = useState(false);
  const [activeFinishCreation, setActiveFinishCreation] = useState(false);
  const [selectedInstagramImages, setSelectedInstagramImages] = useState([]);
  const [instagramPopUp, setInstagramPopUp] = useState<any>(null);
  const [uploadStep, setUploadStep] = useState('1');

  // crop image
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const requestChannels = useCallback(() => {
    fetchChannels();
  }, [fetchChannels]);

  const requestInstagramImages = useCallback(() => {
    fetchInstagramImages();
  }, [fetchInstagramImages]);

  const list = [
    {
      id: '1',
      title: 'Mi equipo',
      icon: 'computer-icon',
      color: THEME.colors.primary,
      active: activeUploadImages,
      function: setActiveUploadImages,
    },
    {
      id: '2',
      title: 'Instagram',
      icon: 'instagram-icon',
      color: THEME.colors.primary,
      active: activeInstagramImages,
      function: setActiveInstagramImages,
    },
  ];

  // Screen values
  const screeWidth: number = window.screen.width;
  const screenHeight: number = window.screen.height;
  const popUpHeight: number = 720;
  const popUpWidth: number = 500;
  const popUpLocationLeft: number = (screeWidth - popUpWidth) / 2;
  const popUpLocationTop: number = (screenHeight - popUpHeight) / 2;

  const windowHost = window.location.host;

  useEffect(() => {
    if (!isEmpty(createdProduct)) {
      if (windowHost === 'app.aimo.co') {
        // @ts-ignores
        window.analytics.identify(user.email, {
          created_at: moment().format(),
        });

        // @ts-ignores
        window.analytics.track('Product created', {
          product_name: form.name,
          created_at: moment().format(),
        });
      }
      enqueueSnackbar('El producto fue creado correctamente.', {
        variant: 'success',
      });
      onCreate();
      reset();
      setProductImages([]);
      setFileList([]);
      setDialogMaxWidth(true);
    }
  }, [createdProduct]);

  useEffect(() => {
    if (!isEmpty(instagramImagesList)) {
      setInstagramImages(JSON.parse(instagramImagesList));
    }
  }, [instagramImagesList]);

  useEffect(() => {
    if (statusRequest && statusRequest.status === 400) {
      enqueueSnackbar('Ha ocurrido un error en la conexión con instagram.', {
        variant: 'error',
      });
    }
    setActiveUploadImages(true);
    setActiveInstagramImages(false);
  }, [statusRequest]);

  useEffect(() => {
    const instagramPopUpTick = setInterval(() => {
      if (instagramPopUp != null && instagramPopUp.closed) {
        clearInterval(instagramPopUpTick);
        setInstagramPopUp(null);
        requestChannels();
      }
    }, 500);
  });

  useEffect(() => {
    if (
      channels.length > 0 &&
      channels.filter(channel => channel.platform === 'instagram_shop').length >
        0 &&
      isEmpty(instagramImagesList)
    ) {
      requestInstagramImages();
    }
  }, [channels]);

  const validateForm = () => {
    if (validate()) {
      if (fileList.length > 0) {
        const formData = new FormData();

        Array.from(fileList).forEach(file => {
          formData.append('photo', file);
        });
        formData.append('name', form.name);
        formData.append('price', form.price);
        if (clientId) {
          formData.append('client_code', clientId.toString());
        }

        // formData.append('is_active', true);
        createProduct(formData);
      } else {
        const data = {
          name: form.name,
          price: form.price,
          is_active: true,
          client_code: clientId,
        };
        createProduct(data);
      }
    }
  };

  const handleImageDelete = (imageURL: string, index: number) => {
    const newImages = productImages.filter(item => item !== imageURL);
    const newFileImages = fileList;
    newFileImages.splice(index, 1);

    setProductImages(newImages);
    setFileList(newFileImages);
  };

  // const handleUploadFile = event => {
  //   if (event.target.files) {
  //     const fileArray = Array.from(event.target.files).map((file: any) =>
  //       URL.createObjectURL(file)
  //     );

  //     const fileListArray = Array.from(event.target.files);

  //     if (productImages.length + fileArray.length > 3) {
  //       enqueueSnackbar('Máximo 3 imágenes', {
  //         variant: 'error',
  //       });

  //       fileArray.length = 3 - productImages.length;
  //       fileListArray.length = 3 - productImages.length;
  //       setProductImages(prevImages => prevImages.concat(fileArray));
  //       setFileList(prevImages => prevImages.concat(fileListArray));
  //     } else {
  //       setProductImages(prevImages => prevImages.concat(fileArray));
  //       setFileList(prevImages => prevImages.concat(fileListArray));
  //     }

  //     setUploadStep('2');
  //     setActiveFinishCreation(true);
  //     setDialogMaxWidth(false);
  //   }
  // };

  const handleUploadImageFile = event => {
    setActiveFinishCreation(false);
    if (event.target.files) {
      const fileArray = Array.from(event.target.files).map((file: any) =>
        URL.createObjectURL(file)
      );
      setImageSrc(fileArray[0]);

      if (productImages.length + fileArray.length > 3) {
        enqueueSnackbar('Máximo 3 imágenes', {
          variant: 'error',
        });
      } else {
        setUploadStep('2');
      }
    }
  };

  const selectUploadOption = (element: any) => {
    if (!element.active) {
      setProductImages([]);
      setFileList([]);
      setSelectedInstagramImages([]);
    }
    if (element.title === 'Instagram' && isEmpty(channels)) {
      requestChannels();
    }
    setActiveInstagramImages(false);
    setActiveUploadImages(false);
    element.function(true);
  };

  const handleInstagramImagesSelection = (image: string) => {
    if (fileList.length >= 3 && !selectedInstagramImages.includes(image)) {
      enqueueSnackbar('Máximo 3 imágenes', {
        variant: 'error',
      });
    } else if (selectedInstagramImages.includes(image)) {
      const index = selectedInstagramImages.indexOf(image);

      setProductImages(images =>
        productImages.filter((product, i) => i !== index)
      );
      setFileList(fileL => fileList.filter((imageFL, i) => i !== index));
      setSelectedInstagramImages(selectedImages =>
        selectedInstagramImages.filter((imageSII, i) => i !== index)
      );
    } else {
      fetch(image, { method: 'GET' })
        .then(response => response.blob())
        .then(blob => {
          const fileArray = [URL.createObjectURL(new Blob([blob]))];
          const fileListArray = [new Blob([blob])];

          setProductImages(prevImages => prevImages.concat(fileArray));
          setFileList(prevImages => prevImages.concat(fileListArray));
          setSelectedInstagramImages(prevImages => prevImages.concat([image]));
        });
    }
  };

  // Instagram PopUp
  const openNewPopUp = () => {
    if (instagramPopUp == null || instagramPopUp.closed) {
      getInstagramOauthUrl().then(response => {
        const popUp: any = window.open(
          response.data.oauth_url,
          '',
          `popup,width=${popUpWidth},height=${popUpHeight},left=${popUpLocationLeft},top=${popUpLocationTop}`
        );
        setInstagramPopUp(popUp);
      });
    } else {
      instagramPopUp.focus();
    }
  };

  const finishInstagramSelection = () => {
    if (selectedInstagramImages.length === 0) {
      enqueueSnackbar('Es necesario seleccionar al menos una imagen.', {
        variant: 'warning',
      });
    } else {
      setActiveFinishCreation(true);
      setDialogMaxWidth(false);
    }
  };

  // crop image
  const onCropComplete = useCallback((croppedArea, croppedAreaPixel) => {
    setCroppedAreaPixels(croppedAreaPixel);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedData: any = await getCroppedImg(imageSrc, croppedAreaPixels);
      const fileListArray = new File([croppedData.file], 'croppedImage.jpg', {
        type: 'image/jpeg',
      });

      setProductImages(prevImages => prevImages.concat(croppedData.cropped));
      setFileList(prevImages => prevImages.concat(fileListArray));
      setActiveFinishCreation(true);
      setDialogMaxWidth(false);
      setImageSrc(null);
      setUploadStep('1');
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <FlexContainer
      container
      direction='column'
      padding='20px 40px'
      height={activeFinishCreation ? '600px' : '700px'}
    >
      <CloseIcon
        onClick={() => {
          onClose();
          setDialogMaxWidth(true);
        }}
      >
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      {activeFinishCreation ? (
        <FlexContainer container direction='column' margin='50px 0 0'>
          <FlexContainer
            container
            borderColor={THEME.colors.textPrimary}
            padding='10px'
          >
            <>
              {productImages.map((image, index) => (
                <FlexContainer
                  key={image}
                  position='relative'
                  width='150px'
                  height='150px'
                  margin='0 10px 0 0'
                >
                  <DeleteIcon onClick={() => handleImageDelete(image, index)}>
                    <Icon
                      icon='cancel-icon'
                      color={THEME.colors.placeholderColor}
                      size={20}
                    />
                  </DeleteIcon>
                  <img
                    src={image}
                    alt='product'
                    style={{ width: 150, height: 150 }}
                  />
                </FlexContainer>
              ))}

              {productImages.length < 3 && (
                <FlexContainer
                  direction='column'
                  alignItems='center'
                  cursor='pointer'
                  onClick={() => photoInput.current.click()}
                >
                  <AddImage
                    direction='column'
                    width='150px'
                    height='150px'
                    justify='center'
                    alignItems='center'
                    borderColor={THEME.colors.textPrimary}
                    borderRadius='5px'
                    padding='8px'
                  >
                    <Typography fontSize={35} fontWeight={600}>
                      +
                    </Typography>
                    <Typography>Subir imágenes</Typography>
                  </AddImage>
                  <input
                    ref={photoInput}
                    type='file'
                    accept='image/*'
                    onChange={handleUploadImageFile}
                    style={{ display: 'none' }}
                  />
                </FlexContainer>
              )}
            </>
          </FlexContainer>
          <FlexContainer container direction='column' margin='30px 0 30px'>
            <FormControl
              label='Nombre del producto'
              placeholder='Escribe el nombre'
              onChange={value => onFormChange(value, 'name')}
              value={form.name}
              margin='0 0 10px'
              error={errors.name}
              width='100%'
              disabled={loadingCreate}
            />
            <Typography fontSize={11} color='#8c8cb1'>
              Recuerda no utilizar caracteres especiales &%$·/()=
            </Typography>
          </FlexContainer>
          <FormControl
            label='Precio'
            placeholder='Escribe el precio'
            onChange={value =>
              onFormChange(validateNumbersInput(value), 'price')
            }
            value={form.price}
            margin='0 0 20px'
            error={errors.price}
            width='100%'
            disabled={loadingCreate}
            startAdornment={
              <SelectDisabled>
                <Select value='S' variant='standard' disableUnderline disabled>
                  <MenuItem value='S'>S./</MenuItem>
                  <MenuItem value='D'>$</MenuItem>
                </Select>
              </SelectDisabled>
            }
          />
          <FlexContainer container justify='center' margin='30px 0 20px'>
            <Button
              variant='contained'
              color='primary'
              padding='5px 30px'
              onClick={() => validateForm()}
              disabled={loadingCreate}
            >
              {loadingCreate && (
                <Spinner
                  height='15px'
                  spinnerSize={20}
                  colorSecondary
                  margin='0 8px 0 0'
                />
              )}
              Crear
            </Button>
          </FlexContainer>
        </FlexContainer>
      ) : (
        <>
          {uploadStep === '1' ? (
            <FlexContainer container direction='column' height='100%'>
              <FlexContainer container margin='20px 0 20px'>
                {list.map(uploadOption => (
                  <FlexContainer
                    key={uploadOption.title}
                    justify='center'
                    alignItems='center'
                    direction='column'
                    cursor='pointer'
                    onClick={() => selectUploadOption(uploadOption)}
                    margin='0 30px 0 0'
                  >
                    <Icon
                      icon={uploadOption.icon}
                      color={
                        uploadOption.active
                          ? uploadOption.color
                          : THEME.colors.placeholderColor
                      }
                      size={28}
                    />
                    <Typography
                      fontSize={13}
                      fontWeight={600}
                      lineHeight='15px'
                      margin='5px 0 0'
                      color={
                        uploadOption.active
                          ? uploadOption.color
                          : THEME.colors.placeholderColor
                      }
                    >
                      {uploadOption.title}
                    </Typography>
                  </FlexContainer>
                ))}
              </FlexContainer>
              <ColoredLine />
              <FlexContainer
                container
                direction='column'
                justify='center'
                margin='30px 0 0 0'
                height='100%'
              >
                {activeUploadImages && (
                  <ComputerOption
                    handlePhotoInput={() => photoInput.current.click()}
                    photoInput={photoInput}
                    handleUpload={handleUploadImageFile}
                  />
                )}
                {activeInstagramImages && (
                  <InstagramOption
                    channels={channels}
                    instagramImages={instagramImages}
                    handleSelection={value =>
                      handleInstagramImagesSelection(value)
                    }
                    selectedImages={selectedInstagramImages}
                    finishSelection={() => finishInstagramSelection()}
                    openPopUp={() => openNewPopUp()}
                    loadingChannels={loadingChannels}
                    loadingImages={loadingInstagramImages}
                  />
                )}
              </FlexContainer>
            </FlexContainer>
          ) : (
            <FlexContainer container direction='column'>
              <FlexContainer margin='25px 0 20px' alignItems='center'>
                <Button
                  padding='0 10px'
                  onClick={() => {
                    setUploadStep('1');
                    setCroppedAreaPixels(null);
                    setProductImages([]);
                  }}
                >
                  <ArrowLeft />
                </Button>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  lineHeight='15px'
                  margin='0 0 0 10px'
                >
                  Recortar
                </Typography>
              </FlexContainer>
              <ColoredLine />
              <CropContainer>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  aspect={3 / 3}
                  zoom={zoom}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </CropContainer>
              <FlexContainer container justify='flex-end' margin='20px 0 0'>
                <Button
                  variant='contained'
                  color='primary'
                  padding='5px 30px'
                  onClick={() => showCroppedImage()}
                >
                  Continuar
                </Button>
              </FlexContainer>
            </FlexContainer>
          )}
        </>
      )}
    </FlexContainer>
  );
};

export default Create;
