const styled = {
  colors: {
    primary: '#5641F0', // Primary
    secondary: '#E0E0FF', // Secondary
    textPrimary: '#4D5369', // color text
    textSecondary: '#8C8CB1', // color text 2
    borderColor: '#E0DEF7',
    placeholderColor: '#C8C8D3',
    backgroundColor: '#FCFCFF',
    secondaryBackground: '#E0E0FF',
    purpleBackground: '#8F8FE6',
    success: '#79E29E',
    error: '#F1564F',
    errorBg: '#ffd9d9',
    primaryHover: '#4C3BE2',
    disabled: '#C8C8D3',

    eleven: '#ffd9d9', // error BK
    twelve: '#f4f4ff', // secondary optional
  },
};

export default styled;
