import React from 'react';
import styled from 'styled-components';
import ReactPhoneInput from 'react-phone-input-mui';
import { Input } from 'components/atoms';
import { PhoneProps } from './types';

const Wrapper = styled.div`
  width: 100%;
  .MuiInputBase-root {
    input {
      padding: 0 0 0 58px;
    }
  }
`;

const SimplePhoneInput: React.FC<PhoneProps> = ({
  value,
  placeholder,
  onChange,
  country,
  disabled = false,
}) => (
  <Wrapper>
    <ReactPhoneInput
      inputStyle={{
        width: '100%',
        border: 'none',
        padding: '0',
      }}
      countryCodeEditable={false}
      placeholder={placeholder}
      onChange={(phone, countryData, e, formatted) =>
        onChange(phone, formatted)
      }
      value={value}
      defaultCountry={country}
      disabled={disabled}
      component={Input}
      inputProps={{ variant: 'standard', centered: true }}
    />
  </Wrapper>
);

export default SimplePhoneInput;
