import React from 'react';

const evalPermission = (permissions, permission, component) => {
  const Default = component;
  if (permissions.includes(permission)) {
    return <Default />;
  }
  return <div>no tienes permisos aqui</div>;
};

export default evalPermission;
