import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { FlexContainer, Typography, Switch, Button } from 'components/atoms';
import { FormControl } from 'components/molecules';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import {
  getTracking,
  getTrackings,
  patchTracking,
  getOrganizationFeedback,
} from 'services';
import FeedbackTemplate from './FeedbackTemplate';

const Feedback: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, , , , updateForm] = useForm(
    {
      id: '',
      isActive: true,
    },
    {}
  );
  // API calls
  const [trackingInformation, fetchTrackingInformation] = useRequest(
    getTracking
  );
  const [trackings, fetchTrackings, loadingTrackings] = useRequest(
    getTrackings,
    []
  );
  const [updatedTracking, updateTracking] = useRequest(patchTracking);
  const [, fetchOrganizationFeedback] = useRequest(getOrganizationFeedback);

  useEffect(() => {
    if (form.id) {
      fetchTrackingInformation(form.id);
    }
  }, [fetchTrackingInformation, form.id]);

  useEffect(() => {
    fetchTrackings();
    fetchOrganizationFeedback();
  }, [fetchTrackings]);

  useEffect(() => {
    if (!isEmpty(trackingInformation)) {
      updateForm({
        id: trackingInformation.id,
        isActive: trackingInformation.feedback_is_active,
      });
    }
  }, [trackingInformation]);

  const handleToggleFeedback = (id, isActive) => {
    updateTracking(
      {
        feedback_is_active: isActive,
      },
      id
    );
  };

  useEffect(() => {
    if (trackings.length > 0) {
      updateForm({
        id: trackings[trackings.length - 1].id,
      });
    }
  }, [trackings]);

  useEffect(() => {
    if (!isEmpty(updatedTracking)) {
      const notificationMessage = form.isActive
        ? 'El feedback ha sido activado correctamente.'
        : 'El feedback ha sido desactivado correctamente.';
      enqueueSnackbar(notificationMessage, {
        variant: 'success',
      });
    }
  }, [updatedTracking, enqueueSnackbar]);

  return (
    <FlexContainer
      container
      justify='space-between'
      padding='30px 20px'
      borderRadius='4px'
    >
      <FlexContainer
        width='30%'
        direction='column'
        backgroundColor='#ffffff'
        justify='center'
        padding='40px 10px'
      >
        <FlexContainer container margin='20px'>
          <FormControl
            control='select'
            options={trackings}
            label='Tracking Público'
            placeholder='Seleccione un tracking'
            onChange={id => onFormChange(id, 'id')}
            value={form.id}
            margin='0 0px 20px'
            disabled={loadingTrackings}
          />
        </FlexContainer>

        <FlexContainer
          container
          padding='15px'
          justify='center'
          alignItems='center'
        >
          <Typography fontSize='16px' fontWeight='700' margin='0 5px 0 0'>
            Feedback de Clientes
          </Typography>
          <Switch
            checked={form.isActive}
            onChange={event => onFormChange(event.target.checked, 'isActive')}
          />
        </FlexContainer>
        <FlexContainer width='90%' justify='center' margin='5px 10px'>
          <Typography textAlign='center'>
            Recibe feedback de tu operación logística así como de los productos
            entregados
          </Typography>
        </FlexContainer>

        <FlexContainer container justify='center' margin='30px 0'>
          <Button
            variant='contained'
            color='primary'
            borderRadius='4px'
            padding='10px 20px'
            onClick={() => handleToggleFeedback(form.id, form.isActive)}
          >
            <Typography fontSize='16px' fontWeight='700' color='white'>
              Guardar
            </Typography>
          </Button>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        container
        height='100%'
        margin='0 30px'
        alignItems='center'
        justify='center'
        direction='column'
      >
        <FeedbackTemplate />
      </FlexContainer>
    </FlexContainer>
  );
};

export default Feedback;
