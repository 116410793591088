import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';
import { FlexContainer, Typography, Button, Icon } from 'components/atoms';
import { FormControl } from 'components/molecules';
import { useTypedSelector } from 'store';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import { postReports, getReportJob } from 'services';
import THEME from 'util/styledTheme';
import { CreateReportProps } from './types';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const validationState = {
  reportType: ['mandatory'],
};

const olvaOptions = [
  {
    id: 'order',
    name: 'Ordenes',
  },
  {
    id: 'purchase_order',
    name: 'Ordenes de compra',
  },
  {
    id: 'feedback_order',
    name: 'Feedback',
  },
  {
    id: 'kardex',
    name: 'Kardex',
  },
  {
    id: 'route',
    name: 'Rutas',
  },
];

const options = [
  {
    id: 'order',
    name: 'Ordenes',
  },
  {
    id: 'feedback_order',
    name: 'Feedback',
  },
  {
    id: 'route',
    name: 'Rutas',
  },
];

const CreateReport: React.FC<CreateReportProps> = ({ onCreate, onClose }) => {
  const organizationID = useTypedSelector(
    state => state.organization.selectedOrganization.id
  );
  const organizationSlug = useTypedSelector(
    state => state.organization.selectedOrganization.slug
  );

  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors, reset] = useForm(
    {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      reportType: 'order',
    },
    validationState
  );

  // API calls
  const [
    reportJobId,
    createReport,
    loadingCreate,
    ,
    ,
    statusRequest,
  ] = useRequest(postReports);
  const [createdReportJob, fetchReportJobConsult] = useRequest(getReportJob);
  // Values
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(reportJobId)) {
      fetchReportJobConsult(reportJobId.job_id);
      setLoading(true);
    }
  }, [reportJobId]);

  useEffect(() => {
    if (!isEmpty(createdReportJob)) {
      switch (createdReportJob.status) {
        case 'PENDING': {
          setTimeout(() => {
            fetchReportJobConsult(reportJobId.job_id);
          }, 1000);
          break;
        }
        case 'FAILURE': {
          setLoading(false);
          enqueueSnackbar('Ocurrió un error, vuelva a intentarlo.', {
            variant: 'error',
          });
          break;
        }
        case 'SUCCESS': {
          setTimeout(() => {
            onCreate();
            onClose();
            setLoading(false);
          }, 1000);
          break;
        }
        default:
          break;
      }
    }
  }, [createdReportJob]);

  const validateForm = () => {
    if (validate()) {
      if (form.endDate < form.startDate) {
        enqueueSnackbar(
          'La fecha final, debe ser después de la fecha inicial.',
          {
            variant: 'warning',
          }
        );
        return;
      }
      if (form.endDate === null || form.startDate === null) {
        enqueueSnackbar('La fecha inicial y final deben estar definidas.', {
          variant: 'warning',
        });
        return;
      }
      const body = {
        start_date: form.startDate,
        end_date: form.endDate,
        report_type: form.reportType,
      };
      createReport(body);
    }
  };

  useEffect(() => {
    if (statusRequest === 200) {
      enqueueSnackbar('El reporte se está generando correctamente', {
        variant: 'success',
      });
      onCreate();
      reset();
    } else if (statusRequest && statusRequest !== 201) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusRequest, enqueueSnackbar]);

  return (
    <FlexContainer container direction='column' padding='60px'>
      {loading ? (
        <FlexContainer
          container
          direction='column'
          padding='30px'
          justify='center'
          alignItems='center'
        >
          <LinearProgress />
          <Typography fontSize={14} margin='20px 0 0 0'>
            Por favor espere, estamos generando el reporte.
          </Typography>
        </FlexContainer>
      ) : (
        <>
          <CloseIcon onClick={() => onClose()}>
            <Icon
              icon='cancel-icon'
              color={THEME.colors.placeholderColor}
              size={25}
            />
          </CloseIcon>
          <FlexContainer container justify='center' margin='20px 0 30px'>
            <Typography color={THEME.colors.primary} fontSize={18}>
              Crear Reporte
            </Typography>
          </FlexContainer>

          <FlexContainer container direction='column'>
            <FlexContainer>
              <FormControl
                label='De'
                placeholder='Fecha de Inicio'
                onChange={value => onFormChange(value, 'startDate')}
                value={form.startDate}
                margin='10px 10px 30px'
                error={errors.startDate}
                control='date'
                disabled={loadingCreate}
              />
              <FormControl
                label='Hasta'
                placeholder='Fecha de Fin'
                onChange={value => {
                  onFormChange(value, 'endDate');
                }}
                value={form.endDate}
                margin='10px 10px 30px'
                error={errors.endDate}
                control='date'
                disabled={loadingCreate}
              />
            </FlexContainer>
            <FormControl
              label='Tipo de Reporte'
              placeholder='Seleccione Tipo de Reporte'
              onChange={value => onFormChange(value, 'reportType')}
              value={form.reportType}
              margin='0 0 20px 10px'
              error={errors.reportType}
              width='90%'
              options={organizationID === 128 ? olvaOptions : options}
              // defaultOption={{ id: 'order', name: 'Ordenes' }}
              control='select'
              disabled={loadingCreate}
            />
          </FlexContainer>

          <FlexContainer container justify='center'>
            <Button
              variant='contained'
              color='primary'
              fontSize='13px'
              onClick={() => validateForm()}
            >
              Crear
            </Button>
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );
};

export default CreateReport;
