import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useTypedSelector } from 'store';
import {
  FlexContainer,
  Typography,
  Button,
  Icon,
  Select,
} from 'components/atoms';
import { Header } from 'components/organisms';
import { getChannels, getClients } from 'services';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import { Dialog, List, ListItem, ListItemText } from '@material-ui/core';
import { Spinner } from 'components/molecules';
import { getLocalStorageClientId } from 'util/functions';
import Products from './Products';
import Categories from './Categories';
import InventoryTab from './Inventory';
import MassiveProductCharge from './MassiveProductCharge';

type TabProps = {
  view: string;
  onClick: (view: string) => void;
};

const list = [
  {
    name: 'Productos',
    value: 'products',
  },
  {
    name: 'Categorías',
    value: 'categories',
  },
  {
    name: 'Inventario',
    value: 'inventory',
  },
  {
    name: 'Carga Masiva',
    value: 'massive-charge',
  },
];

const Option = styled(FlexContainer)<{ active: boolean }>`
  text-decoration: none;
  cursor: pointer;
  ${props =>
    props.active && `border-bottom: 2px solid ${props.theme.colors.primary}`}
`;

const InventoryNavigation: React.FC<TabProps> = ({ view, onClick }) => {
  return (
    <FlexContainer
      container
      height='100%'
      justify='space-between'
      alignItems='center'
    >
      <FlexContainer alignItems='center'>
        {list.map(option => {
          return (
            <FlexContainer
              key={option.name}
              padding='0 30px 0 0'
              onClick={() => onClick(option.value)}
            >
              <Option
                alignItems='center'
                padding='14px 0'
                active={view === option.value}
              >
                <Typography>{option.name}</Typography>
              </Option>
            </FlexContainer>
          );
        })}
      </FlexContainer>
    </FlexContainer>
  );
};

const Inventory: React.FC = () => {
  const showMenu = useTypedSelector(state => state.sidebar);

  const [channels, fetchChannels, loadingChannels] = useRequest(getChannels);
  const [clients, fetchClients, isLoadingClients] = useRequest(getClients, []);

  const [view, setView] = useState<string>('products');
  const [storeName, setStoreName] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState(undefined);
  const [showClientDialog, setShowClientDialog] = useState(false);
  const [clientsList, setClientList] = useState([]);
  const [hasClients, setHasClients] = useState(false);
  const [metaStore, setMetaStore] = useState(null);

  useEffect(() => {
    fetchClients({ page_size: 0 });
    fetchChannels();
  }, []);

  useEffect(() => {
    if (!clients || clients.length === 0) {
      setClientList([]);
      setHasClients(false);
      setSelectedClient(getLocalStorageClientId());
      return;
    }

    const activeClients = clients.filter(client => client.is_active);
    const data = activeClients.map(item => {
      return {
        id: item.id,
        name: item.name,
      };
    });

    setClientList(data);
    setHasClients(true);
  }, [clients]);

  useEffect(() => {
    if (!isEmpty(channels)) {
      const digitalCatalog = channels.filter(
        channel =>
          channel.platform === 'digital_catalog' &&
          channel.client === selectedClient
      );
      setStoreName(
        digitalCatalog && digitalCatalog.length > 0
          ? digitalCatalog[0].store
          : ''
      );
      const metaStoreChannel = channels.filter(
        channel => channel.platform === 'meta_store'
      );
      setMetaStore(
        metaStoreChannel && metaStoreChannel.length > 0
          ? metaStoreChannel[0]
          : null
      );
    }
  }, [channels]);

  const canRenderStoreButton = () => {
    if (loadingChannels) {
      return false;
    }

    if (hasClients && !selectedClient) {
      return false;
    }

    return true;
  };

  return (
    <FlexContainer container height='100%'>
      <Header showMenu={showMenu} />
      <FlexContainer container padding='50px 0 0' height='100vh'>
        <FlexContainer container padding='30px 40px' direction='column'>
          <FlexContainer container justify='space-between' alignItems='center'>
            <FlexContainer margin='0 0 0'>
              <Typography fontSize={19} fontWeight={600} margin='0 0 10px'>
                Mis productos
              </Typography>
              {!clients || clients.length === 0 ? null : (
                <FlexContainer margin='0 10px 10px'>
                  <Select
                    placeholder='Seleccione un cliente'
                    value={selectedClient}
                    options={clientsList}
                    onChange={value => {
                      setSelectedClient(value);
                      fetchChannels();
                    }}
                    borderless
                    disabled={false}
                  />
                </FlexContainer>
              )}
            </FlexContainer>
            {canRenderStoreButton() ? (
              <Button
                variant='outlined'
                color='primary'
                margin='0 0 0 20px'
                fontSize='13px'
                startIcon={
                  <Icon
                    icon={storeName === '' ? 'plus-icon' : 'external-link-icon'}
                    size={storeName === '' ? 18 : 13}
                  />
                }
                onClick={() =>
                  window.open(
                    storeName === ''
                      ? '/canales-de-venta'
                      : `https://${storeName}`
                  )
                }
              >
                {storeName === '' ? 'Crear Tienda' : 'Ver tienda'}
              </Button>
            ) : null}
          </FlexContainer>
          <Typography fontSize={13} color='#8c8cb1' margin='8px 0 10px'>
            Para mejorar tu experiencia con el catalogo aimo conéctalo a Google
            Analytics, aplícalo mirando el tutorial en Aimo Academy.
          </Typography>
          <InventoryNavigation
            view={view}
            onClick={newView => setView(newView)}
          />
          <FlexContainer container>
            {view === 'products' && (
              <Products
                clientId={selectedClient}
                hasClients={hasClients}
                isLoadingClients={isLoadingClients}
                storeName={storeName === '' ? undefined : storeName}
              />
            )}
            {view === 'categories' && (
              <Categories
                clientId={selectedClient}
                hasClients={hasClients}
                isLoadingClients={isLoadingClients}
              />
            )}
            {view === 'inventory' && (
              <InventoryTab
                clientId={selectedClient}
                hasClients={hasClients}
                isLoadingClients={isLoadingClients}
              />
            )}
            {view === 'massive-charge' && (
              <MassiveProductCharge
                clientId={selectedClient}
                hasClients={hasClients}
              />
            )}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      <Dialog
        fullWidth
        open={showClientDialog}
        maxWidth='sm'
        onClose={() => {}}
      >
        <FlexContainer
          container
          direction='column'
          padding='20px 40px'
          height='auto'
        >
          <FlexContainer
            container
            direction='column'
            alignItems='center'
            justify='center'
          >
            <Typography fontSize={13}>Seleccione un cliente</Typography>
            {isLoadingClients ? (
              <Spinner height='15px' spinnerSize={25} margin='50px 0 30px 0' />
            ) : (
              <List component='nav'>
                {clientsList.map(client => (
                  <ListItem button divider>
                    <ListItemText
                      primary={client.name}
                      color={THEME.colors.primary}
                      onClick={() => {
                        setSelectedClient(client.id);
                        setShowClientDialog(false);
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </FlexContainer>
        </FlexContainer>
      </Dialog>
    </FlexContainer>
  );
};

export default Inventory;
