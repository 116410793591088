import React, { useState } from 'react';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { FlexContainer, Icon } from 'components/atoms';
import {
  AimoContainer,
  ExpandCell,
  MarginCell,
} from 'components/organisms/DataTable';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import THEME from 'util/styledTheme';
import { TableProps } from './types';

const TableContainer = styled(AimoContainer)`
  overflow-x: initial;
`;

const OrderRow = styled(TableRow)`
  background-color: #f9f9fc;
  &:hover {
    background-color: #f4f4ff;
  }
  td {
    background-color: inherit;
  }
`;

const DraggableTable: React.FC<TableProps> = ({
  headers,
  expandedHeaders,
  data,
  onChange,
  onRowClick,
}) => {
  const [showedOrders, toggleOrder] = useState<Array<number>>([]);

  const onDragEnd = (result: any) => {
    const { destination, source, reason } = result;

    if (
      !destination ||
      reason === 'CANCEL' ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    const orderFrom = parseInt(source.droppableId, 10);
    const orderTo = parseInt(destination.droppableId, 10);
    const originalOrderIndex = parseInt(source.index, 10);
    const newOrderIndex = parseInt(destination.index, 10);
    const newRoutes = cloneDeep(data);
    const orderMoved = cloneDeep(data[orderFrom].tasks[source.index]);

    newRoutes[orderFrom].tasks.splice(originalOrderIndex, 1);
    newRoutes[orderTo].tasks.splice(newOrderIndex, 0, orderMoved);

    onChange(newRoutes);
  };

  return (
    <TableContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map(header => (
                <TableCell key={header.label} colSpan={header.colSpan}>
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {data.map((row: any, index: number) => {
            return (
              <Droppable droppableId={`${index}`} key={row.key || row.centroid}>
                {dropProvided => (
                  <TableBody
                    ref={dropProvided.innerRef}
                    {...dropProvided.droppableProps}
                  >
                    <TableRow>
                      <MarginCell
                        colSpan={headers.reduce((prev, next) => {
                          return prev + (next.colSpan || 1);
                        }, 0)}
                      />
                    </TableRow>

                    <TableRow
                      onClick={() => {
                        let aux;
                        if (showedOrders.includes(index)) {
                          aux = showedOrders.filter(order => order !== index);
                        } else {
                          aux = showedOrders.concat(index);
                        }
                        toggleOrder(aux);
                      }}
                    >
                      {headers.map(head => (
                        <TableCell key={head.id} colSpan={head.colSpan}>
                          {head.cell(row, index)}
                        </TableCell>
                      ))}
                    </TableRow>

                    {showedOrders.includes(index) &&
                      row.tasks.map((order: any, orderIndex: number) => {
                        return (
                          <Draggable
                            key={order.task_id}
                            draggableId={order.task_id.toString()}
                            index={orderIndex}
                          >
                            {provided => (
                              <OrderRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {expandedHeaders.map(header => (
                                  <TableCell
                                    key={header.id}
                                    colSpan={header.colSpan || 1}
                                    onClick={() => {
                                      if (header.id !== 'delete_item') {
                                        onRowClick(order);
                                      }
                                    }}
                                  >
                                    {header.cell(order, orderIndex, index)}
                                  </TableCell>
                                ))}
                              </OrderRow>
                            )}
                          </Draggable>
                        );
                      })}
                    <TableRow>
                      <ExpandCell
                        colSpan={headers.reduce((prev, next) => {
                          return prev + (next.colSpan || 1);
                        }, 0)}
                      >
                        <FlexContainer
                          container
                          justify='center'
                          backgroundColor={THEME.colors.borderColor}
                          padding='2px'
                          onClick={() => {
                            let aux;
                            if (showedOrders.includes(index)) {
                              aux = showedOrders.filter(
                                order => order !== index
                              );
                            } else {
                              aux = showedOrders.concat(index);
                            }
                            toggleOrder(aux);
                          }}
                        >
                          {showedOrders.includes(index) ? (
                            <div style={{ transform: 'rotate(180deg)' }}>
                              <Icon icon='arrow-icon' size={15} />
                            </div>
                          ) : (
                            <Icon icon='arrow-icon' size={15} />
                          )}
                        </FlexContainer>
                      </ExpandCell>
                    </TableRow>
                  </TableBody>
                )}
              </Droppable>
            );
          })}
        </Table>
      </DragDropContext>
    </TableContainer>
  );
};

export default DraggableTable;
