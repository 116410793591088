/* eslint-disable camelcase */
export interface Session {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
  refresh_token: string;
}

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';

interface SessionLogin {
  type: typeof SESSION_LOGIN;
  payload: Session;
}

interface SessionLogout {
  type: typeof SESSION_LOGOUT;
}

export type SessionActionTypes = SessionLogin | SessionLogout;
