import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import { FlexContainer, Typography, Button } from 'components/atoms';
import { FormControl } from 'components/molecules';
import { changePassword } from 'services';
import AimoLogoFull from 'assets/images/AimoLogoFull.svg';
import AimoLogo from 'assets/images/login/login-image.png';

const validationState = {
  new_password: ['mandatory'],
};

const FullGrid = styled(Grid)`
  height: 100vh;
  background-color: #ffffff;
`;

const FullGridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1.2fr 1fr;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 768px) and (max-width: 820px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 821px) and (max-width: 1190px) {
    grid-template-columns: 1.3fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 1191px) {
    grid-template-columns: 1.1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const Container = styled(FlexContainer)`
  height: 100%;
  padding: 25px;
  @media only screen and (min-width: 768px) {
    padding: 30px;
  }
`;

const FormContainer = styled(FlexContainer)`
  width: 100%;
  padding: 40px 0;

  @media only screen and (min-width: 560px) and (max-width: 767px) {
    width: 500px;
  }

  @media only screen and (min-width: 768px) and (max-width: 959px) {
    width: 350px;
    padding: 0;
  }

  @media only screen and (min-width: 960px) {
    width: 480px;
    padding: 0;
  }
`;

const AimoBackground = styled(FlexContainer)`
  background-color: #f1564f;
  border-radius: 0;
  padding: 50px 0;

  @media only screen and (min-width: 768px) {
    padding: 0;
    border-radius: 48px 0 0 48px;
  }
`;

const LogoContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  img {
    padding-top: 50px;
    width: 85%;
    height: auto;
  }

  @media only screen and (min-width: 768px) {
    img {
      padding-top: 50px;
      max-width: 550px;
      height: auto;
    }
  }
`;

const HeaderContainer = styled(Grid)`
  align-self: flex-start;
`;

const RightSide: React.FC = () => (
  <AimoBackground container justify='center' height='100%'>
    <LogoContainer>
      <img src={AimoLogo} alt='aimo_logo' />
    </LogoContainer>
  </AimoBackground>
);

const NewPassword: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [form, onFormChange, validate, errors] = useForm(
    {
      new_password: '',
    },
    validationState
  );
  // API calls
  const [
    createdNewPassword,
    createNewPassword,
    loadingCreate,
    ,
    ,
    statusRequest,
  ] = useRequest(changePassword);

  // Values
  const [recoveryToken, setRecoveryToken] = useState<string>('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    setRecoveryToken(token);
  }, [location]);

  useEffect(() => {
    if (statusRequest === 200) {
      enqueueSnackbar('La clave ha sido cambiada correctamente.', {
        variant: 'success',
      });
      history.push('/login');
    }
  }, [createdNewPassword, statusRequest, enqueueSnackbar]);

  const validateForm = () => {
    if (validate()) {
      const data = {
        recovery_token: recoveryToken,
        new_password: form.new_password,
      };
      createNewPassword(data);
    }
  };

  return (
    <FullGrid container>
      <FullGridContainer>
        <FlexContainer container height='100%'>
          <Container container direction='column' alignItems='center'>
            <HeaderContainer item>
              <a href='https://aimo.co'>
                <img src={AimoLogoFull} alt='logo_full' />
              </a>
            </HeaderContainer>
            <FlexContainer
              container
              width='100%'
              height='100%'
              justify='center'
              alignItems='center'
            >
              <FormContainer container direction='column'>
                <Typography
                  fontWeight='700'
                  fontSize='27px'
                  margin='20px 0 0'
                  color='#000000'
                >
                  Cambio de contraseña
                </Typography>
                <FormControl
                  width='100%'
                  type='password'
                  label='Nueva contraseña*'
                  placeholder='Escriba su nueva contraseña'
                  onChange={value => onFormChange(value, 'new_password')}
                  value={form.new_password}
                  margin='70px 0 80px'
                  error={errors.new_password}
                  disabled={loadingCreate}
                  variant='standard'
                />
                <FlexContainer container justify='center' padding='20px 0'>
                  <Button
                    variant='contained'
                    color='primary'
                    margin='30px 0 0 0'
                    padding='25px 50px'
                    borderRadius='20px'
                    onClick={() => validateForm()}
                    disabled={loadingCreate}
                  >
                    Continuar
                  </Button>
                </FlexContainer>
              </FormContainer>
            </FlexContainer>
          </Container>
        </FlexContainer>
        <RightSide />
      </FullGridContainer>
    </FullGrid>
  );
};

export default NewPassword;
