import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Dialog, Grid } from '@material-ui/core';
import {
  Button,
  Card,
  FlexContainer,
  Icon,
  // PaymentInput,
  Typography,
} from 'components/atoms';
import { Header } from 'components/organisms';
import { FormControl, Spinner, Tabs, Pagination } from 'components/molecules';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
// import useForm from 'hooks/useForm';
import THEME from 'util/styledTheme';
import { getBalance, getTransactions } from 'services';
// import CardIcon from 'assets/images/payment/card.svg';
// import UserIcon from 'assets/images/payment/user.svg';
// import CalendarIcon from 'assets/images/payment/calendar.svg';
// import EmailIcon from 'assets/images/payment/email.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import CodigoQR from 'assets/images/codigo_qr.png';

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  right: 12px;
`;

type MovementTypes = {
  transaction: any;
};

const depositInfo = {
  bankName: 'BANCO CONTINENTAL SOLES',
  accountNumber: '0011-0367-0100023713-09',
  cci: '011-367-000100023713-09',
};

const tabOptions = [
  {
    label: 'Depósito',
    value: 'deposit',
  },
  {
    label: 'Código QR',
    value: 'qr',
  },
];

const FooterDialog: React.FC = () => (
  <FlexContainer
    container
    direction='column'
    alignItems='flex-end'
    margin='10px 0'
  >
    <Typography
      color='text.secondary'
      fontSize={13}
      fontWeight={500}
      margin='0 0 5px'
    >
      AIMO PERU SAC
    </Typography>
    <FlexContainer margin='0 0 5px'>
      <Typography
        color='text.secondary'
        fontSize={13}
        fontWeight={500}
        margin='0 5px 0 0'
      >
        RUC:
      </Typography>
      <Typography color='text.secondary' fontSize={13}>
        20605551701
      </Typography>
    </FlexContainer>
    <Typography as='a' href='mailto:hola@aimo.co' color='#4131D3'>
      hola@aimo.co
    </Typography>
  </FlexContainer>
);

const QRSection: React.FC = () => (
  <>
    <FlexContainer container direction='column' alignItems='center'>
      <Typography fontSize={20}>Escanea y recarga</Typography>
      <Typography fontSize={13} color='text.secondary' margin='5px 0 0'>
        Monto mínimo S/20
      </Typography>
    </FlexContainer>
    <FlexContainer container justify='center' margin='8px 0 10px'>
      <img src={CodigoQR} alt='qr' />
    </FlexContainer>
    <FooterDialog />
  </>
);

const DepositSection: React.FC = () => (
  <>
    <Typography color='text.secondary' fontSize={13}>
      En cuenta como medio de pago.
    </Typography>

    {depositInfo && (
      <FlexContainer container direction='column' margin='25px 0 15px'>
        <FlexContainer container justify='space-between' margin='0 0 12px'>
          <Typography color='text.secondary' fontSize={14} fontWeight={500}>
            Cuenta destino:
          </Typography>
          <Typography color='text.secondary' fontSize={14}>
            {depositInfo.bankName}
          </Typography>
        </FlexContainer>

        <FlexContainer
          container
          justify='space-between'
          margin='0 0 10px'
          alignItems='center'
        >
          <Typography color='text.secondary' fontSize={14} fontWeight={500}>
            Número de cuenta:
          </Typography>
          <FlexContainer alignItems='center'>
            <Typography color='text.secondary' fontSize={14}>
              {depositInfo.accountNumber}
            </Typography>
            <CopyToClipboard text={depositInfo.accountNumber}>
              <Button margin='0 0 0 5px' padding='0 10px' height='25px'>
                <CopyIcon />
              </Button>
            </CopyToClipboard>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer
          container
          justify='space-between'
          margin='0 0 10px'
          alignItems='center'
        >
          <Typography color='text.secondary' fontSize={14} fontWeight={500}>
            CCI:
          </Typography>
          <FlexContainer alignItems='center'>
            <Typography color='text.secondary' fontSize={14}>
              {depositInfo.cci}
            </Typography>
            <CopyToClipboard text={depositInfo.cci}>
              <Button margin='0 0 0 5px' padding='0 10px' height='25px'>
                <CopyIcon />
              </Button>
            </CopyToClipboard>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    )}
    <FooterDialog />
  </>
);

const MovementRow: React.FC<MovementTypes> = ({ transaction }) => {
  const [transactionDescription, setTransactionDescription] = useState('');

  useEffect(() => {
    switch (transaction.transaction_type) {
      case 'debit':
        setTransactionDescription(`Pago de órden ${transaction.reference}`);
        break;
      case 'credit':
        setTransactionDescription('Recarga');
        break;
      default:
        setTransactionDescription('');
        break;
    }
  }, [transaction]);

  const getCurrency = currency => {
    switch (currency) {
      case 'PEN':
        return 'S/';
      case 'USD':
        return '$';
      default:
        return '';
    }
  };

  return (
    <FlexContainer container direction='column' margin='0 0 18px'>
      <Typography
        fontSize={10}
        color={THEME.colors.placeholderColor}
        margin='0 0 5px'
      >
        {moment(transaction.created_at).format('LLL')}
      </Typography>
      <FlexContainer container justify='space-between' alignItems='center'>
        <Typography fontSize={15} color='text.secondary'>
          {transactionDescription}
        </Typography>
        <Typography fontSize={15} color='text.secondary'>
          {getCurrency(transaction.amount_currency)} {transaction.amount}
        </Typography>
      </FlexContainer>
    </FlexContainer>
  );
};

const Purse: React.FC = () => {
  const user = useTypedSelector(state => state.user);
  const showMenu = useTypedSelector(state => state.sidebar);

  // Values
  const [selectedTab, setSelectedTab] = useState<string>('deposit');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  // const [month, setMonth] = useState<string>('');
  // const [year, setYear] = useState<string>('');
  // const [dateError, setDateError] = useState<string>('');
  // const [form, onFormChange, validate, errors] = useForm(
  //   {
  //     cardNumber: '',
  //     cardholderName: '',
  //     expirationDate: '',
  //     securityCode: '',
  //     amount: '',
  //     email: '',
  //   },
  //   validationState
  // );

  // API calls
  const [balanceWallet, fetchBalance, loadingBalance] = useRequest(
    getBalance,
    {}
  );
  const [
    transactions,
    fetchTransactions,
    loadingTransactions,
    pageData,
  ] = useRequest(getTransactions, []);

  const getCurrency = currency => {
    switch (currency) {
      case 'PEN':
        return 'S/';
      case 'USD':
        return '$';
      default:
        return '';
    }
  };

  useEffect(() => {
    // TODO: Usar variable de entorno
    // @ts-ignore
    window.Mercadopago.setPublishableKey(
      'TEST-e2711b19-ccde-4684-b7dd-d995fc69cb0c'
    );
    // @ts-ignore
    window.Mercadopago.getIdentificationTypes();

    fetchBalance();
  }, []);

  useEffect(() => {
    fetchTransactions({ page });
  }, [page]);

  // const handleExpirationDate = rawDate => {
  //   // console.log(rawDate);
  //   const date = rawDate.split('/');
  //   let formatterMonth = '';
  //   let formatterYear = '';
  //   let inputValue = '';
  //   // console.log(date);
  //   if (date[0]) {
  //     const rawMonth = parseInt(date[0], 10);
  //     if (rawMonth || rawMonth === 0) {
  //       if (rawMonth >= 2 && rawMonth < 10) {
  //         formatterMonth = `0${rawMonth}`;
  //       } else if (rawMonth > 12) {
  //         formatterMonth = '12';
  //       } else {
  //         formatterMonth = `${rawMonth}`;
  //       }
  //     }
  //   }

  //   if (date[1]) {
  //     const rawYear = parseInt(date[1], 10);
  //     if (rawYear) {
  //       formatterYear = `${rawYear}`;
  //     }
  //   }

  //   if (date[1]) {
  //     inputValue = `${formatterMonth}/${formatterYear}`;
  //   } else if (formatterMonth.length === 2) {
  //     inputValue = `${formatterMonth}/`;
  //   } else {
  //     inputValue = `${formatterMonth}`;
  //   }
  //   setDateError('');
  //   setMonth(formatterMonth);
  //   setYear(formatterYear);
  //   onFormChange(inputValue, 'expirationDate');
  // };

  // 4009175332806176
  // function setCardTokenAndPay(status, response) {
  //   if (status === 200 || status === 201) {
  //     const formElement = document.getElementById('paymentForm');
  //     const card = document.createElement('input');
  //     card.setAttribute('name', 'token');
  //     card.setAttribute('type', 'hidden');
  //     card.setAttribute('value', response.id);
  //     formElement.appendChild(card);
  //     // console.log(response);
  //     // doSubmit=true;
  //     // form.submit();
  //   } else {
  //     console.log(`Verify filled data!\n${JSON.stringify(response, null, 4)}`);
  //   }
  // }

  // const handlePayment = () => {
  //   let error = false;
  //   const monthRegex = /^(([0][1-9])|([1][0-2]))$/;
  //   const yearRegex = /^([0-9]){2}$/;
  //   const date = form.expirationDate.split('/');
  //   // console.log(date);
  //   if (
  //     date.length !== 2 ||
  //     !monthRegex.test(date[0]) ||
  //     !yearRegex.test(date[1])
  //   ) {
  //     error = true;
  //     setDateError('Formato inválido');
  //   }
  //   if (validate() || !error) {
  //     const $form = document.getElementById('paymentForm');
  //     // @ts-ignore
  //     window.Mercadopago.createToken($form, setCardTokenAndPay);
  //   }
  // };

  return (
    <FlexContainer container height='100%'>
      <Header showMenu={showMenu} />
      <FlexContainer container padding='50px 0 0' height='100%'>
        <FlexContainer container height='100%' justify='center' padding='60px'>
          <FlexContainer
            direction='column'
            width='100%'
            maxWidth='980px'
            height='100%'
          >
            <Typography fontSize={18} margin='0 0 35px'>
              Agrega fondos a tu cuenta
            </Typography>

            <Grid container spacing={5}>
              <Grid item xs={12} sm={7}>
                <Card shadow width='100%' padding='23px' margin='0 0 30px'>
                  <Typography
                    fontSize={13}
                    color='text.secondary'
                    margin='0 0 15px'
                  >
                    Crédito
                  </Typography>
                  <FlexContainer
                    container
                    justify='space-between'
                    alignItems='center'
                  >
                    <FlexContainer margin='0 0 0 10px'>
                      {!loadingBalance ? (
                        <Typography fontSize={20}>
                          {getCurrency(balanceWallet.balance_currency)}{' '}
                          {balanceWallet.balance}
                        </Typography>
                      ) : (
                        <Spinner height='15px' spinnerSize={12} margin='0' />
                      )}
                    </FlexContainer>

                    <Button
                      variant='contained'
                      color='primary'
                      padding='10px 15px'
                      onClick={() => setOpenDialog(true)}
                    >
                      Recargar crédito
                    </Button>
                  </FlexContainer>
                </Card>

                {/* <Card shadow width='100%' padding='23px' margin='0 0 30px'>
                  <Typography fontSize={15} margin='0 0 15px'>
                    Métodos de pago
                  </Typography>
                  <FlexContainer
                    container
                    justify='space-between'
                    alignItems='center'
                  >
                    <Typography fontSize={15}>*** *** *** 5329</Typography>
                    <Button
                      variant='contained'
                      color='secondary'
                      padding='10px 15px'
                      onClick={() => {}}
                      startIcon={<Icon icon='delete-icon' size={15} />}
                      disabled
                    >
                      Remover tarjeta
                    </Button>
                  </FlexContainer>
                </Card> */}

                <Card shadow width='100%' padding='23px' margin='0 0 30px'>
                  <Typography fontSize={15} margin='0 0 15px'>
                    Cupón de descuento
                  </Typography>
                  <FlexContainer
                    container
                    justify='space-between'
                    alignItems='center'
                  >
                    <FormControl
                      placeholder='Ingresa Código de descuento'
                      onChange={() => {}}
                      value=''
                      width='70%'
                    />
                    <Button
                      variant='contained'
                      color='primary'
                      padding='10px 15px'
                      onClick={() => {}}
                      disabled
                    >
                      Agregar cupón
                    </Button>
                  </FlexContainer>
                </Card>
              </Grid>

              <Grid item xs={12} sm={5}>
                <Card shadow width='100%' padding='30px'>
                  <FlexContainer
                    container
                    justify='space-between'
                    alignItems='center'
                    margin='0 0 30px'
                  >
                    <Typography fontSize={13} color='text.secondary'>
                      Ver Movimientos
                    </Typography>
                    <Icon
                      icon='date-icon'
                      color={THEME.colors.textSecondary}
                      size={30}
                    />
                  </FlexContainer>
                  {!loadingTransactions ? (
                    <>
                      {transactions.map(transaction => (
                        <MovementRow
                          key={transaction.id}
                          transaction={transaction}
                        />
                      ))}

                      {pageData.totalPages > 1 && (
                        <FlexContainer container margin='20px 0'>
                          <Pagination
                            onPageChange={selectedPage => setPage(selectedPage)}
                            totalPages={pageData.totalPages}
                            totalItems={pageData.totalItems}
                            pageSize={pageData.pageSize}
                            currentPage={page}
                            onlyPagination
                          />
                        </FlexContainer>
                      )}
                    </>
                  ) : (
                    <Spinner height='15px' spinnerSize={12} margin='0' />
                  )}
                </Card>
              </Grid>
            </Grid>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      <Dialog open={openDialog} disableBackdropClick disableEscapeKeyDown>
        <FlexContainer
          width='450px'
          padding='30px 30px 40px'
          direction='column'
        >
          <CloseIcon onClick={() => setOpenDialog(false)}>
            <Icon
              icon='cancel-icon'
              color={THEME.colors.placeholderColor}
              size={20}
            />
          </CloseIcon>

          <FlexContainer
            container
            justify='center'
            maxWidth='400px'
            margin='20px 0'
          >
            <FlexContainer width='100%'>
              <Tabs
                selected={selectedTab}
                tabs={tabOptions}
                onChange={value => setSelectedTab(value)}
                tabHeight='40px'
                tabPadding='10px 50px'
                fullWidth
              />
            </FlexContainer>
          </FlexContainer>

          {selectedTab === 'deposit' && <DepositSection />}

          {selectedTab === 'qr' && <QRSection />}
          {/* <Typography color='text.secondary' fontSize={13} margin='0 0 20px'>
            Detalles de la tarjeta
          </Typography>

          <form id='paymentForm'>
            <PaymentInput
              icon={CardIcon}
              placeholder='Número de tarjeta'
              margin='0 0 20px'
              data-checkout='cardNumber'
              value={form.cardNumber}
              onChange={({ target }) =>
                onFormChange(target.value, 'cardNumber')
              }
              error={errors.cardNumber}
            />

            <PaymentInput
              icon={UserIcon}
              placeholder='Nombres y apellidos'
              margin='0 0 20px'
              data-checkout='cardholderName'
              value={form.cardholderName}
              onChange={({ target }) =>
                onFormChange(target.value, 'cardholderName')
              }
              error={errors.cardholderName}
            />

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <PaymentInput
                  icon={CalendarIcon}
                  placeholder='MM/AA'
                  margin='0 0 20px'
                  maxLength={5}
                  value={form.expirationDate}
                  onChange={({ target }) => handleExpirationDate(target.value)}
                  error={errors.expirationDate || dateError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PaymentInput
                  icon={CardIcon}
                  placeholder='CVV'
                  margin='0 0 20px'
                  data-checkout='securityCode'
                  value={form.securityCode}
                  onChange={({ target }) =>
                    onFormChange(target.value, 'securityCode')
                  }
                  error={errors.securityCode}
                />
              </Grid>
            </Grid>
            <input
              value='DNI'
              data-checkout='docType'
              style={{
                display: 'none',
              }}
            />
            <input
              value='98965454'
              data-checkout='docNumber'
              style={{
                display: 'none',
              }}
            />
            <input
              value={month}
              data-checkout='cardExpirationMonth'
              style={{
                display: 'none',
              }}
            />
            <input
              value={year}
              data-checkout='cardExpirationYear'
              style={{
                display: 'none',
              }}
            />

            <PaymentInput
              icon={EmailIcon}
              placeholder='Correo electrónico'
              margin='0 0 25px'
              value={form.email}
              onChange={({ target }) => onFormChange(target.value, 'email')}
              error={errors.email}
            />
          </form>

          <Typography color='text.secondary' fontSize={13} margin='0 0 15px'>
            Monto a agregar
          </Typography>

          <FormControl
            control='select'
            options={[
              {
                name: '20.00',
                id: '20',
              },
            ]}
            placeholder='0.00'
            value={form.amount}
            onChange={value => onFormChange(value, 'amount')}
            margin='0 0 20px'
            width='100%'
            error={errors.amount}
          />

          <Button
            fullWidth
            variant='contained'
            color='primary'
            padding='10px 15px'
            onClick={() => handlePayment()}
          >
            Recargar crédito
          </Button> */}
        </FlexContainer>
      </Dialog>
    </FlexContainer>
  );
};

export default Purse;
