import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import L from 'leaflet';
import { useSnackbar } from 'notistack';
import useRequest from 'hooks/useRequest';
import useForm from 'hooks/useForm';
import { isEmpty } from 'lodash';
import { Grid } from '@material-ui/core';
import { Button, FlexContainer, Typography, Marker } from 'components/atoms';
import { FormControl, Map, Spinner } from 'components/molecules';
import { AddressSuggestion } from 'components/organisms';
import { getCustomer, patchCustomer, postCustomer } from 'services';
import THEME from 'util/styledTheme';

const Container = styled(FlexContainer)`
  overflow: auto;
`;

const validationState = {
  full_name: ['mandatory'],
  phone_number: ['mandatory'],
  address: ['mandatory'],
};

const EditCustomer: React.FC<{ customerId?: any; onRequest?: () => void }> = ({
  customerId,
  onRequest,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [
    customerForm,
    onCustomerChange,
    validateCustomer,
    customerErrors,
    resetCustomer,
    updateCustomerForm,
  ] = useForm(
    [
      {
        formId: 1,
        id: 1,
        id_document: '',
        full_name: '',
        email: '',
        phone_country: 'pe',
        phone_number: '',
        address: '',
        address_detail: '',
        special_instructions: '',
        latitude: '',
        longitude: '',
      },
    ],
    validationState
  );

  // API calls
  const [
    createdCustomer,
    createCustomer,
    loadingCreate,
    ,
    errorCreate,
  ] = useRequest(postCustomer);
  const [customer, fetchCustomer, loadingCustomer] = useRequest(getCustomer);
  const [
    updatedCustomer,
    updateCustomer,
    loadingUpdate,
    ,
    errorUpdate,
  ] = useRequest(patchCustomer);

  // Values
  const [markers, setMarkers] = useState([]);
  const [bounds, setBounds] = useState();
  const [resetAddress, setResetAddress] = useState<number>(1);

  useEffect(() => {
    if (customerId !== null) {
      fetchCustomer(customerId);
    }
  }, [customerId, fetchCustomer]);

  useEffect(() => {
    if (!isEmpty(customer)) {
      if (customer.address_coordinates) {
        setMarkers([
          {
            id: 0,
            coordinate: customer.address_coordinates,
          },
        ]);
      }

      updateCustomerForm({
        formId: 1,
        id_document: customer.id_document,
        full_name: customer.full_name,
        email: customer.email,
        phone_number: customer.phone_number,
        address: customer.address,
        address_detail: customer.address_detail,
        special_instructions: customer.special_instructions,
        latitude:
          customer.address_coordinates && customer.address_coordinates.latitude,
        longitude:
          customer.address_coordinates &&
          customer.address_coordinates.longitude,
      });
    }
  }, [customer]);

  useEffect(() => {
    if (markers.length > 0) {
      const newBounds = L.latLngBounds(
        markers.map(marker => [
          marker.coordinate.latitude,
          marker.coordinate.longitude,
        ])
      );
      setBounds(newBounds);
    }
  }, [markers]);

  useEffect(() => {
    if (!isEmpty(createdCustomer)) {
      enqueueSnackbar('El cliente se creó correctamente.', {
        variant: 'success',
      });
      resetCustomer();
      setResetAddress(resetAddress + 1);
      setMarkers([]);
      onRequest();
    }
  }, [enqueueSnackbar, createdCustomer]);

  useEffect(() => {
    if (!isEmpty(updatedCustomer)) {
      enqueueSnackbar('El cliente se actualizó correctamente', {
        variant: 'success',
      });
      onRequest();
    }
  }, [enqueueSnackbar, updatedCustomer]);

  useEffect(() => {
    if (!isEmpty(errorCreate)) {
      enqueueSnackbar('Ocurrió un error, vuelve a intentarlo', {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, errorCreate]);

  useEffect(() => {
    if (!isEmpty(errorUpdate)) {
      enqueueSnackbar('Ocurrió un error, vuelve a intentarlo', {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, errorUpdate]);

  const handleAddressUpdate = async (
    location,
    formId?: number,
    orderId?: string,
    drag?: boolean
  ) => {
    let updateValues;

    let newMarkers = [];

    if (location.place_id) {
      // if is google maps autocomplete service
      const geocoder = new window.google.maps.Geocoder();
      updateValues = await geocoder
        .geocode({ placeId: location.place_id })
        .then(({ results }) => {
          return (updateValues = {
            destiny: location.title,
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          });
        });
    } else {
      updateValues = {
        destiny: location.description || location.formatted_address,
        latitude: location.geometry.location.lat,
        longitude: location.geometry.location.lng,
      };
    }

    if (formId) {
      onCustomerChange(location.title, 'address', formId);

      updateCustomerForm({
        ...updateValues,
        formId,
      });

      if (drag) {
        newMarkers = markers.filter(marker => marker.id !== orderId);
      }

      newMarkers = [
        {
          id: orderId,
          coordinate: {
            latitude: updateValues.latitude,
            longitude: updateValues.longitude,
          },
        },
      ];
    }
    setMarkers(newMarkers);
  };

  const handleResetAddress = (formId?: number, orderId?: string) => {
    const resetValues = {
      destiny: '',
      latitude: null,
      longitude: null,
    };
    let newMarkers = [];

    if (formId) {
      updateCustomerForm({
        ...resetValues,
        formId,
      });

      newMarkers = markers.filter(marker => marker.id !== orderId);
    }
    setMarkers(newMarkers);
  };

  const handleMarkerDrag = (marker, markerId) => {
    const coors = marker.getLatLng();
    const params = {
      apiKey: process.env.REACT_APP_HERE_API_KEY,
      limit: 5,
      at: `${coors.lat},${coors.lng}`,
    };
    axios({
      method: 'get',
      url: `https://revgeocode.search.hereapi.com/v1/revgeocode`,
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(results => {
        if (results.data.items.length > 0) {
          handleAddressUpdate(results.data.items[0], 1, markerId, true);
        }
      })
      .catch(() => {
        enqueueSnackbar('Ocurrio un error con la dirección.', {
          variant: 'error',
        });
      });
  };

  const validateForm = () => {
    if (validateCustomer()) {
      const data = {
        id_document: customerForm[0].id_document,
        full_name: customerForm[0].full_name,
        email: customerForm[0].email,
        phone_number: `+${customerForm[0].phone_number}`,
        address: customerForm[0].address,
        address_detail: customerForm[0].address_detail,
        address_coordinates: {
          latitude: customerForm[0].latitude,
          longitude: customerForm[0].longitude,
        },
        special_instructions: customerForm[0].special_instructions,
      };

      if (customerId && customer) {
        updateCustomer(data, customerId);
      } else {
        createCustomer(data);
      }
    }
  };

  return (
    <>
      <Typography fontSize={18} margin='10px 0 0'>
        {customerId && customer ? 'Editar cliente' : 'Crear cliente'}
      </Typography>
      <FlexContainer container height='100%'>
        <FlexContainer
          container
          height='100%'
          margin='0 5px 0 0'
          style={{ overflow: 'auto' }}
        >
          <Container container height='100%' direction='column' padding='25px'>
            <FlexContainer
              container
              height='100%'
              direction='column'
              alignItems='center'
              padding='20px'
              margin='0 0 20px'
              backgroundColor='#FFFFFF'
              borderColor={THEME.colors.borderColor}
            >
              {customerForm.map(form => (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Nombre'
                        placeholder='Ingrese nombre'
                        onChange={value =>
                          onCustomerChange(value, 'full_name', form.formId)
                        }
                        value={form.full_name}
                        margin='0 0 20px'
                        width='100%'
                        disabled={
                          loadingUpdate || loadingCreate || loadingCustomer
                        }
                        error={
                          customerErrors[form.formId] &&
                          customerErrors[form.formId].full_name
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Documento de Identidad'
                        placeholder='Ingrese documento de identidad'
                        onChange={value =>
                          onCustomerChange(value, 'id_document', form.formId)
                        }
                        value={form.id_document}
                        margin='0 0 20px'
                        width='100%'
                        disabled={
                          loadingUpdate || loadingCreate || loadingCustomer
                        }
                        optional
                        error={
                          customerErrors[form.formId] &&
                          customerErrors[form.formId].id_document
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        control='phone'
                        country={form.phone_country}
                        label='Teléfono'
                        placeholder='Ingrese número teléfono'
                        onChange={value =>
                          onCustomerChange(value, 'phone_number', form.formId)
                        }
                        value={form.phone_number}
                        margin='0 0 20px'
                        width='100%'
                        disabled={
                          loadingUpdate || loadingCreate || loadingCustomer
                        }
                        error={
                          customerErrors[form.formId] &&
                          customerErrors[form.formId].phone_number
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Correo'
                        placeholder='Ingrese correo'
                        onChange={value =>
                          onCustomerChange(value, 'email', form.formId)
                        }
                        value={form.email}
                        margin='0 0 20px'
                        width='100%'
                        disabled={
                          loadingUpdate || loadingCreate || loadingCustomer
                        }
                        optional
                        error={
                          customerErrors[form.formId] &&
                          customerErrors[form.formId].email
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <AddressSuggestion
                        label='Dirección'
                        placeholder='Ingrese dirección'
                        onChange={() =>
                          handleResetAddress(form.formId, form.id)
                        }
                        onSelect={location =>
                          handleAddressUpdate(location, form.formId, form.id)
                        }
                        value={form.address}
                        margin='0 0 20px'
                        disabled={
                          loadingUpdate || loadingCreate || loadingCustomer
                        }
                        reset={resetAddress}
                        error={
                          customerErrors[form.formId] &&
                          customerErrors[form.formId].address
                        }
                        zIndex='3000'
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Detalle de la dirección'
                        placeholder='Ingrese detalle'
                        onChange={value =>
                          onCustomerChange(value, 'address_detail', form.formId)
                        }
                        value={form.address_detail}
                        margin='0 0 20px'
                        width='100%'
                        disabled={
                          loadingUpdate || loadingCreate || loadingCustomer
                        }
                        optional
                        error={
                          customerErrors[form.formId] &&
                          customerErrors[form.formId].address_detail
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Instrucciones especiales'
                        placeholder='Ingrese instrucciones especiales'
                        onChange={value =>
                          onCustomerChange(
                            value,
                            'special_instructions',
                            form.formId
                          )
                        }
                        value={form.special_instructions}
                        margin='0 0 20px'
                        width='100%'
                        disabled={
                          loadingUpdate || loadingCreate || loadingCustomer
                        }
                        optional
                        error={
                          customerErrors[form.formId] &&
                          customerErrors[form.formId].special_instructions
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              ))}
              <FlexContainer width='100%' height='100%' margin='0 0 0 5px'>
                <Map
                  bounds={bounds}
                  zoom={10}
                  position={[-12.1166362, -77.0138613]}
                >
                  {markers.map((marker, index) => (
                    <Marker
                      key={marker.id}
                      position={[
                        marker.coordinate.latitude,
                        marker.coordinate.longitude,
                      ]}
                      icon='colorFlag'
                      draggable
                      onDragEnd={e => handleMarkerDrag(e.target, markers[0].id)}
                    />
                  ))}
                </Map>
              </FlexContainer>
              <FlexContainer container justify='flex-end' margin='30px 0 0'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => validateForm()}
                  disabled={loadingUpdate || loadingCreate}
                >
                  {(loadingCreate || loadingUpdate) && (
                    <Spinner
                      height='15px'
                      spinnerSize={20}
                      colorSecondary
                      margin='0 8px 0 0'
                    />
                  )}
                  {customerId && customer ? 'Guardar cambios' : 'Crear cliente'}
                </Button>
              </FlexContainer>
            </FlexContainer>
          </Container>
        </FlexContainer>
      </FlexContainer>
    </>
  );
};

export default EditCustomer;
