import React, { useState, useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { FlexContainer, Button, Typography, Icon } from 'components/atoms';
import { Dropdown, DropdownIconOption, Spinner } from 'components/molecules';
import { Dialog } from '@material-ui/core';
import useRequest from 'hooks/useRequest';
import { getProductCategories, patchProductCategory } from 'services';
import NoData from 'assets/images/no_data.svg';
import { DataTable } from 'components/organisms';
import { getLocalStorageClientId } from 'util/functions';
import CreateCategory from './Create';

const Categories = props => {
  const { clientId, hasClients, isLoadingClients } = props;
  const [categoriesList, setCategoriesList] = useState([]);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  // API calls
  const [categories, fetchCategories, loadingCategories] = useRequest(
    getProductCategories,
    []
  );
  const [updatedCategory, updateCategory] = useRequest(patchProductCategory);

  const [paginationData, setPaginationData] = useState({
    page: 0,
    size: 10,
    total: 0,
  });

  const settingsOptions = [
    {
      label: 'Editar',
      name: 'edit',
      icon: 'edit-icon',
      onClick: row => {
        setEditData(row);
        setOpenEdit(true);
      },
    },
    {
      label: 'Eliminar',
      name: 'delete',
      icon: 'delete-icon-2',
      onClick: row => {
        setCategoryId(row._id);
        setOpenConfirm(true);
      },
    },
  ];

  const loadCategories = () => {
    const params = {
      $skip: paginationData.size * paginationData.page,
      $limit: paginationData.size,
      is_active: true,
      client_code: clientId || getLocalStorageClientId(),
    };
    fetchCategories(params);
  };

  useEffect(() => {
    loadCategories();
  }, [clientId]);

  useEffect(() => {
    if (!isEmpty(updatedCategory)) {
      loadCategories();
    }
  }, [updatedCategory]);

  useEffect(() => {
    if (!isEmpty(categories)) {
      setCategoriesList(categories.data);
    }

    setPaginationData({ ...paginationData, total: categories.total });
  }, [categories]);

  const totalPages = () => {
    const residue = paginationData.total % paginationData.size;
    const result = paginationData.total / paginationData.size;
    // eslint-disable-next-line
    const pages = residue === 0 ? result : parseInt(result + '') + 1;
    return pages;
  };

  const changePage = (value: number) => {
    setPaginationData({ ...paginationData, page: value - 1 });
    const params = {
      $skip: paginationData.size * (value - 1),
      $limit: paginationData.size,
      is_active: true,
      client_code: clientId || getLocalStorageClientId(),
    };
    fetchCategories(params);
  };

  const option = row =>
    settingsOptions.map(setting => (
      <DropdownIconOption
        key={setting.name}
        label={setting.label}
        name={setting.name}
        icon={setting.icon}
        onClick={() => setting.onClick(row)}
      />
    ));

  const headers = useMemo(
    () => [
      {
        label: 'Nombre',
        id: 'name',
        cell: row => row.name,
      },
      {
        label: <FlexContainer justify='center' />,
        id: 'actions',
        cell: row => (
          <FlexContainer width='80%' justify='flex-end' margin='0 0 0 40px'>
            <Dropdown
              label={<Icon icon='options-icon' size={20} />}
              direction='bottom'
              position='right'
              showCaret={false}
              options={option(row)}
              padding='0 10px'
              hover
            />
          </FlexContainer>
        ),
      },
    ],
    [categoriesList]
  );

  const canRenderCategories = () => {
    if (isLoadingClients) {
      return false;
    }

    if (hasClients && !clientId) {
      return false;
    }

    return true;
  };

  return (
    <div style={{ width: '100%' }}>
      {loadingCategories ? (
        <FlexContainer
          container
          padding='30px 40px'
          direction='column'
          justify='center'
          alignItems='center'
          height='80vh'
        >
          <Spinner height='350px' />
        </FlexContainer>
      ) : (
        <FlexContainer container padding='40px 0'>
          {categoriesList.length === 0 ? (
            <FlexContainer
              container
              direction='column'
              justify='center'
              alignItems='center'
              height='60vh'
            >
              <img src={NoData} alt='no_data' />
              <Typography color='text.secondary'>
                No hay categorías registradas
              </Typography>
              <Button
                variant='contained'
                color='primary'
                margin='20px 0 0'
                onClick={() => setOpenCreate(true)}
              >
                Crear categoría
              </Button>
            </FlexContainer>
          ) : canRenderCategories() ? (
            <FlexContainer container direction='column'>
              <FlexContainer container justify='flex-end'>
                <Button
                  color='primary'
                  fontSize='13px'
                  startIcon={<Icon icon='plus-icon' size={18} />}
                  onClick={() => setOpenCreate(true)}
                >
                  Crear Categoría
                </Button>
              </FlexContainer>

              <FlexContainer container justify='center'>
                <FlexContainer
                  width='50%'
                  direction='column'
                  alignItems='center'
                  margin='40px 0'
                >
                  <DataTable
                    headers={headers.filter(header => header)}
                    data={categoriesList}
                    dataIdentifier='_id'
                    onChangePage={changePage}
                    totalPages={totalPages()}
                    totalItems={paginationData.total}
                    pageSize={paginationData.size}
                    page={paginationData.page + 1}
                    showPagination
                    marginBottom='0'
                  />
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          ) : (
            <FlexContainer
              container
              direction='column'
              justify='center'
              alignItems='center'
              height='60vh'
            >
              <img src={NoData} alt='no_data' />
              <Typography color='text.secondary'>
                Seleccione un cliente
              </Typography>
            </FlexContainer>
          )}
        </FlexContainer>
      )}

      <Dialog
        fullWidth
        open={openCreate || openEdit}
        maxWidth='xs'
        onClose={() => {
          setOpenCreate(false);
          setOpenEdit(false);
          setEditData(null);
        }}
      >
        <CreateCategory
          clientId={clientId}
          onCreate={() => loadCategories()}
          onClose={() => {
            setOpenCreate(false);
            setOpenEdit(false);
            setEditData(null);
          }}
          data={editData}
        />
      </Dialog>

      <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
        <FlexContainer
          padding='30px'
          direction='column'
          alignItems='center'
          justify='center'
        >
          <Typography color='#4131D3' fontSize={15} margin='0 0 20px'>
            Eliminar Categoría
          </Typography>
          <Typography margin='0 0 30px' fontSize={13}>
            ¿Estás seguro de eliminar la categoría?
          </Typography>
          <FlexContainer container alignItems='center' justify='space-between'>
            <Button
              variant='contained'
              color='primary'
              fontSize='13px'
              onClick={() => {
                updateCategory({ is_active: false }, categoryId);
                setOpenConfirm(false);
              }}
            >
              Eliminar
            </Button>

            <Button fontSize='14px' onClick={() => setOpenConfirm(false)}>
              Cancelar
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Dialog>
    </div>
  );
};

export default Categories;
