import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { Grid } from '@material-ui/core';
import useForm from 'hooks/useForm';
import { setSession } from 'store/session/actions';
import useRequest from 'hooks/useRequest';
import { signUp, login } from 'services';
import { Typography, FlexContainer } from 'components/atoms';
import SignUpImage from 'assets/images/signup/signup-image.png';
import FirstStep from './FirstStep';
import ThirdStep from './ThirdStep';

const FullGrid = styled(Grid)`
  height: 100vh;
  background-color: #ffffff;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1.2fr 1fr;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 768px) and (max-width: 820px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 821px) and (max-width: 1190px) {
    grid-template-columns: 1.3fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 1191px) {
    grid-template-columns: 1.1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const AimoBackground = styled(FlexContainer)`
  background-color: #5641f0;
  border-radius: 0;
  padding: 50px 0;

  @media only screen and (min-width: 768px) {
    padding: 0;
    border-radius: 48px 0 0 48px;
  }
`;

const LogoContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
  }

  & > div {
    width: 85%;
  }

  @media only screen and (min-width: 768px) and (max-width: 900px) {
    padding: 0;
    & > div {
      width: 250px;
    }
  }

  @media only screen and (min-width: 901px) {
    padding: 0;
    & > div {
      width: 480px;
    }
  }
`;

const OptionsContaioer = styled.ul`
  li {
    color: #ffffff;
    font-size: 13px;
    line-height: 25px;
  }
`;

const RightSide: React.FC = () => (
  <AimoBackground container justify='center' height='100%'>
    <LogoContainer>
      <img src={SignUpImage} alt='aimo_logo' />
      <FlexContainer direction='column' alignItems='center' margin='40px 0 0'>
        <Typography
          fontWeight={700}
          fontSize={21}
          color='#ffffff'
          lineHeight='32px'
          margin='0 0 10px'
          textAlign='center'
        >
          Haz crecer tu negocio con Aimo
        </Typography>
        <OptionsContaioer>
          <li>Crea tu cuenta y obtén tu catálogo digital gratuito.</li>
          <li>
            Recibe pedidos 24/7 por WhatsApp y atiende a todos tus clientes.
          </li>
          <li>
            Reduce tus costos de envío y accede a nuestros aliados logísticos de
            confianza.
          </li>
          <li>Programa tus entregas y mejora tu experiencia de cliente.</li>
        </OptionsContaioer>
      </FlexContainer>
    </LogoContainer>
  </AimoBackground>
);

const validationStateStep1 = {
  email: ['mandatory', 'email'],
  password: ['mandatory'],
};

const SignUpForm: React.FC = () => {
  const windowUrl = window.location.search;
  const windowHost = window.location.host;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // Values
  const [step, setStep] = useState(1);
  const [referralCode, setReferralCode] = useState(null);
  const [form, onFormChange, validate, errors, , updateForm] = useForm(
    {
      email: '',
      password: '',
    },
    step === 1 && validationStateStep1
  );
  // API calls
  const [createdUser, createUser, loadingCreate, , errorRequest] = useRequest(
    signUp
  );

  useEffect(() => {
    if (!isEmpty(createdUser)) {
      if (windowHost === 'app.aimo.co') {
        // @ts-ignores
        window.analytics.reset();
        // @ts-ignores
        window.analytics.identify(form.email, {
          created_at: moment().format(),
        });
        // @ts-ignores
        window.analytics.track('Form Sign up', {
          full_name: `Admin`,
          email: form.email,
          country: form.country_id,
          referral_code: referralCode,
          created_at: moment().format(),
        });
        // setStep(3);
        loginUser();
      }
      // setStep(3);
      loginUser();
    }
  }, [createdUser]);

  useEffect(() => {
    if (!isEmpty(windowUrl)) {
      if (windowUrl.includes('reference_code=bcpcrece')) {
        setReferralCode('bcpcrece');
      }
    }
  }, [windowUrl]);

  useEffect(() => {
    if (errorRequest && errorRequest.status === 400) {
      if (errorRequest.data && errorRequest.data.errors) {
        errorRequest.data.errors.forEach(error => {
          if (
            error.non_field_errors &&
            error.non_field_errors[0] === 'User exists'
          ) {
            enqueueSnackbar('El correo ya está registrado en el sistema', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(
              'El correo usado está esperando verificación de la cuenta.',
              {
                variant: 'error',
              }
            );
          }
        });
      }
    }
  }, [errorRequest, enqueueSnackbar]);

  useEffect(() => {
    axios
      .get('https://ipapi.co/json/')
      .then(response => {
        const { data } = response;
        updateForm({
          country_id: data.country_code,
          phone_country: data.country_code.toLowerCase(),
        });
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handleRegister = () => {
    createUser({
      first_name: 'Admin',
      email: form.email,
      password: form.password,
      referral_code: referralCode,
    });
  };

  const loginUser = () => {
    login({ email: form.email, password: form.password })
      .then(res => {
        localStorage.setItem('AIMO_API_TOKEN', res.data.access_token);
        dispatch(setSession(res.data));
        localStorage.setItem('user_email', form.email);
      })
      .catch(() => {
        enqueueSnackbar('Los datos ingresados no coinciden con una cuenta.', {
          variant: 'error',
        });
      });
  };

  const showStep = (current: number) => {
    switch (current) {
      case 1:
        return (
          <FirstStep
            onSend={() => handleRegister()}
            form={form}
            onFormChange={onFormChange}
            validate={validate}
            errors={errors}
            loading={loadingCreate}
            referralCode={referralCode}
          />
        );
      default:
        return null;
    }
  };

  return (
    <FullGrid container>
      {step === 3 ? (
        <ThirdStep />
      ) : (
        <Container>
          <FlexContainer>{showStep(step)}</FlexContainer>
          <RightSide />
        </Container>
      )}
    </FullGrid>
  );
};

export default SignUpForm;
