import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { FlexContainer, Icon, Input, Typography } from 'components/atoms';
import { AddressSuggestionProps } from './types';
import THEME from '../../../util/styledTheme';
import { loaderGoogleApi } from '../../../util/google';

const Label = styled.label`
  color: ${props => props.theme.colors.textPrimary};
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
  white-space: nowrap;
`;

const OptionsContainer = styled(FlexContainer)<{ zIndex?: string }>`
  position: absolute;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  border: 1px solid #ccc;
  top: 100%;
  left: 0;
  max-height: 250px;
  overflow-y: auto;
  z-index: ${props => (props.zIndex ? props.zIndex : '2')};
`;

const Option = styled.div`
  width: 100%;
  font-size: 13px;
  padding: 10px;
  margin: 0;
  cursor: pointer;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const InputError = styled(FlexContainer)`
  top: -12px;
  right: -15px;
  z-index: 1;
`;

const AddressSuggestionOrders: React.FC<AddressSuggestionProps> = ({
  value,
  placeholder,
  onChange,
  onSelect,
  label,
  margin,
  disabled,
  error,
  reset,
  variant,
  zIndex,
  city,
  district,
  postalCode,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [address, setAddress] = useState<string>(value);
  const [options, setOptions] = useState([]);
  const [noSuggestionMessage, setNoSuggestionMessage] = useState<string>('');
  const [noSuggestionDistrict, setNoSuggestionDistrict] = useState([]);

  useEffect(() => {
    (async () => {
      loaderGoogleApi();
    })();
  }, []);

  useEffect(() => {
    setAddress('');
    setAddress(value);
  }, [reset]);

  useEffect(() => {
    if (value !== '') {
      setNoSuggestionMessage('');
      setAddress(value);
    }
    // setAddress(value);
  }, [value]);

  const getSuggestion = useCallback(
    _.debounce((text: string) => {
      if (value !== '') {
        onChange();
      }

      // setAddress(text);
      if (text.length <= 4) return;

      const coors = text.split(',');

      if (
        coors.length === 2 &&
        !Number.isNaN(parseInt(coors[0], 10)) &&
        !Number.isNaN(parseInt(coors[1], 10))
      ) {
        const reverseParams = {
          apiKey: process.env.REACT_APP_HERE_API_KEY,
          limit: 5,
          at: text,
        };
        axios({
          method: 'get',
          url: `https://revgeocode.search.hereapi.com/v1/revgeocode`,
          params: reverseParams,
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(results => {
            setOptions(results.data.items);
          })
          .catch(() => {
            enqueueSnackbar('Ocurrio un error con la dirección.', {
              variant: 'error',
            });
          });
      } else {
        const autocomplete = new window.google.maps.places.AutocompleteService();

        let queryText = text;
        if (district && city) {
          queryText += `, ${district}, ${city}`;
        } else if (postalCode) {
          queryText += `, ${postalCode}`;
        } else if (district && city && postalCode) {
          queryText += `, ${postalCode} , ${district}, ${city}`;
        }

        const query = {
          input: queryText,
          componentRestrictions: { country: ['pe'] },
        };

        autocomplete.getPlacePredictions(query, async (predictions, status) => {
          if (status === 'OK') {
            const suggestions = [];
            let lastSuggestion;

            await Promise.all(
              predictions.map(async item => {
                const geocoder = new window.google.maps.Geocoder();
                const { results } = await geocoder.geocode({
                  address: queryText,
                  region: 'pe',
                });

                const addressComponents = results[0].address_components;

                if (addressComponents) {
                  // Find the component that contains the district
                  for (let i = 0; i < addressComponents.length; i++) {
                    if (addressComponents[i].types.includes('locality')) {
                      setNoSuggestionMessage('');
                      setNoSuggestionDistrict(prevState => [
                        ...prevState,
                        {
                          id: results[0].place_id,
                          district: addressComponents[i].long_name,
                        },
                      ]);
                    }
                  }
                } else {
                  setNoSuggestionMessage(
                    'No hay coincidencias con la dirección ingresada'
                  );
                }

                // lower case and remove sensitive characters
                const formattedAddress = results[0].formatted_address
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f\s.]/g, '')
                  .toLowerCase();

                if (district) {
                  if (
                    formattedAddress.includes(
                      district.replace(/[\u0300-\u036f\s.]/g, '').toLowerCase()
                    )
                  ) {
                    // when there are suggestions with the address entered in the input and the district selected
                    setNoSuggestionMessage('');
                    suggestions.push({
                      ...item,
                      id: item.place_id,
                      title: item.description,
                    });
                  } else if (
                    suggestions.length > 0 &&
                    suggestions.every(
                      addressSuggestion =>
                        !addressSuggestion.title
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f\s.]/g, '')
                          .toLowerCase()
                          .includes(
                            district
                              .replace(/[\u0300-\u036f\s.]/g, '')
                              .toLowerCase()
                          )
                    )
                  ) {
                    // when none of the suggestions in the array have the district selected
                    // when an address is entered and 3 suggestions are returned, 2 have the district and 1 does not have it; Verify that if there are suggestions in the array that include the district, the one that does not have it is no longer added.
                    setNoSuggestionMessage(
                      'No hay coincidencias con la dirección ingresada'
                    );
                    suggestions.push({
                      ...item,
                      id: item.place_id,
                      title: item.description,
                    });
                  }
                } else if (postalCode) {
                  if (formattedAddress.includes(postalCode)) {
                    setNoSuggestionMessage('');
                    suggestions.push({
                      ...item,
                      id: item.place_id,
                      title: item.description,
                    });
                  } else if (
                    suggestions.length > 0 &&
                    suggestions.every(
                      addressSuggestion =>
                        !addressSuggestion.description.includes(postalCode)
                    )
                  ) {
                    // when none of the suggestions in the array have the postal code
                    // when an address is entered and 3 suggestions are returned, 2 have the postal code and 1 does not have it; Verify that if there are suggestions in the array that include the postal code, the one that does not have it is no longer added.
                    setNoSuggestionMessage(
                      'No hay coincidencias con la dirección ingresada'
                    );
                    suggestions.push({
                      ...item,
                      id: item.place_id,
                      title: item.description,
                    });
                  }
                }
                lastSuggestion = item;
              })
            );

            if (suggestions.length === 0) {
              // when there are not suggestions
              setNoSuggestionMessage(
                'No hay coincidencias con la dirección ingresada'
              );
              suggestions.push({
                ...lastSuggestion,
                id: lastSuggestion.place_id,
                title: lastSuggestion.description,
              });
            }

            setOptions(suggestions);
          } else if (status === 'ZERO_RESULTS') {
            setNoSuggestionMessage(
              'No hay coincidencias con la dirección ingresada ni similares'
            );
          }
        });
      }
    }, 500),
    [city, district, postalCode]
  );

  const handleOnBlur = () => {
    if (value === '') {
      setAddress('');
    }

    setTimeout(() => {
      setOptions([]);
    }, 200);
  };

  return (
    <FlexContainer container direction='column' margin={margin}>
      {label && <Label>{label}</Label>}
      <FlexContainer
        container
        wrap='nowrap'
        alignItems='center'
        position='relative'
      >
        <Input
          autoComplete='off'
          variant={variant || 'outlined'}
          color='primary'
          placeholder={placeholder}
          value={address}
          onChange={e => {
            setAddress(e.target.value);
            getSuggestion(e.target.value);
          }}
          onBlur={() => handleOnBlur()}
          disabled={disabled}
          fullWidth
          margin='0'
          padding='0 18px'
          InputProps={{
            startAdornment: <Icon icon='marker-icon' size={15} />,
          }}
        />
        {(options.length > 0 || noSuggestionMessage) && (
          <OptionsContainer container direction='column' zIndex={zIndex}>
            {noSuggestionMessage !== '' && (
              <FlexContainer direction='column' padding='10px 10px 6px 10px'>
                <Typography
                  fontSize={12}
                  color={THEME.colors.error}
                  padding='0 0 8px 0'
                >
                  {noSuggestionMessage}
                </Typography>
                {!noSuggestionMessage.includes('ni similares') && (
                  <Typography fontSize={12}>Sugerencias:</Typography>
                )}
              </FlexContainer>
            )}
            {options.length > 0 &&
              options.map((option, i) => (
                <Option
                  key={option.id || option.title}
                  onClick={() => {
                    onSelect(option);
                    setOptions([]);
                  }}
                >
                  {option.title}{' '}
                </Option>
              ))}
          </OptionsContainer>
        )}
        {error && (
          <InputError
            borderColor={THEME.colors.error}
            backgroundColor={THEME.colors.errorBg}
            padding='2px 5px'
            position='absolute'
            borderRadius='4px'
          >
            <Typography fontSize={11} color={THEME.colors.error}>
              {error}
            </Typography>
          </InputError>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default AddressSuggestionOrders;
