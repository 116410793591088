import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import L from 'leaflet';
import polyUtil from 'polyline-encoded';
import { Polyline, Popup, Map as LeafMap } from 'react-leaflet';
import { isEmpty, cloneDeep, find, uniqBy, uniq, omit } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { Collapse, Dialog, Drawer, Tooltip } from '@material-ui/core';
import {
  Button,
  FlexContainer,
  Icon,
  MapTooltip,
  Marker,
  Status,
  Typography,
  Select,
  Switch,
} from 'components/atoms';
import { FormControl, HeaderButtons, Map } from 'components/molecules';
import { Header } from 'components/organisms';
import { useTypedSelector } from 'store';
import { selectBranchOffice } from 'store/organization/actions';
import {
  getDashboardRoutes,
  getDashboardOrders,
  getDashboardWorkers,
  getWorkerPositions,
  getStatuses,
  patchRouteTasks,
  getOrderTypes,
  getRouteTypes,
  assignRouteWorkerVehicle,
  releaseRoutes,
  changeRouteStatus,
  getOrdersToSelect,
  getRouteOrigins,
  getVehicleTypes,
  getRoutesVrp,
  getJobConsult,
  updateRoutesPreview,
  createRoutes,
  updateRouteConfig,
  getRouteConfig,
  getTimeWindows,
  chooseOrganization,
} from 'services';
import statusColors, { routeColors } from 'util/statusColors';
import THEME from 'util/styledTheme';
import { optionFormatter } from 'util/functions';
import useWindowSize from 'hooks/useWindowSize';
import useRequest from 'hooks/useRequest';
import { NavProps, DemandType } from './types';
import RouteBlock from './RouteBlock';
import DetailBlock from './DetailBlock';
import FleetBlock from './FleetBlock';
import BottomMenu from './BottomMenu';
import OrderBlock from './OrderBlock';
import CreateView from './CreateView';
import CreateBottomMenu from './CreateBottomMenu';
import DetailOrder from '../Orders/OrdersList/Detail';
import Edit from '../Orders/OrdersList/Edit';

export const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: THEME.colors.purpleBackground,
    color: '#FFF',
    fontSize: 12,
    marginBottom: 7,
    padding: 8,
  },
  arrow: {
    color: THEME.colors.purpleBackground,
  },
})(Tooltip);

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  right: 12px;
  z-index: 1000;
`;

const AnimateBlock = styled(FlexContainer)<{ show: boolean }>`
  z-index: 1002;
  transition: 0.3s all;
  left: ${props => (props.show ? '0' : '100%')};
  @media only screen and (min-width: 768px) {
    z-index: 1001;
    right: ${props => (props.show ? '-450px' : '0')};
    left: auto;
  }
`;

const AnimateMenu = styled(FlexContainer)<{ show: boolean }>`
  z-index: 1003;
  transition: 0.5s all;
  box-shadow: 2px -9px 28px 0 rgba(0, 0, 0, 0.11);
  bottom: ${props => (props.show ? '0' : '-100px')};
`;

const ResponsiveContainer = styled(FlexContainer)`
  width: 100%;
  @media only screen and (min-width: 768px) {
    width: 330px;
  }
`;

const MobileMap = styled(FlexContainer)<{ show: boolean }>`
  position: absolute;
  animation: 0.3s all;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1010;
  bottom: ${props => (props.show ? '0' : '-100%')};
`;

const TabOption = styled(FlexContainer)<{ selected: boolean }>`
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-bottom: 2px solid ${props => props.theme.colors.borderColor};
  ${props =>
    props.selected && `border-bottom: 2px solid ${props.theme.colors.primary};`}
  &:hover {
    > div {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const PickMessage = styled(FlexContainer)<{ show: boolean }>`
  position: absolute;
  animation: 0.3s all;
  color: #ffffff;
  z-index: 1002;
  top: ${props => (props.show ? '0' : '-35px')};
  left: 0;
  right: 0;
`;

const ResumeContainer = styled(FlexContainer)<{ big?: boolean }>`
  position: absolute;
  top: 20px;
  right: 15px;
  z-index: 500;
  height: ${props => (props.big ? 'auto' : '32px')};
  width: ${props => (props.big ? '370px' : '280px')};
  background-color: #ffffff;
  align-items: center;
  padding: ${props => (props.big ? '15px 25px' : '0 20px')};
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  justify-content: space-between;
`;

const SwitchContainer = styled(FlexContainer)`
  position: absolute;
  top: 20px;
  left: 45px;
  z-index: 500;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 0 0 0 12px;
  border-radius: 4px;
`;

const reorderOptions = ['confirmReorder', 'cancelReorder'];
const routeOptions = [
  'showMapRoute',
  // 'providerAssign',
  'workerAssign',
  'freeRoute',
  'changeStatus',
  // 'deleteRoute',
];
const routeSingleOptions = [
  'showDetail',
  'showMapRoute',
  // 'providerAssign',
  'workerAssign',
  'freeRoute',
  'changeStatus',
  // 'deleteRoute',
];
// const routeOrderOptions = ['assignOrders'];
const orderOptions = ['showMapOrder', 'assignOrders'];
const workerOptions = ['showMapWorker'];
const taskOptions = ['freeTask'];

const tabOptions = [
  {
    label: 'Órdenes',
    value: 'orders',
    permission: 'core.order.list',
  },
  {
    label: 'Rutas',
    value: 'routes',
    permission: 'core.route.list',
  },
  {
    label: 'Flota',
    value: 'fleet',
    permission: 'core.worker.list',
  },
];

const CreateResume: React.FC<{ quantity: number; summary: any }> = ({
  quantity,
  summary,
}) => {
  const [open, setOpen] = useState<boolean>(true);
  return (
    <ResumeContainer big>
      <FlexContainer container direction='column'>
        <FlexContainer container alignItems='center' justify='space-between'>
          <FlexContainer alignItems='center'>
            <Icon icon='route-gray-icon' size={18} />
            <Typography margin='0 0 0 5px'>{`${quantity} Rutas`}</Typography>
          </FlexContainer>
          <FlexContainer
            style={{
              transform: open ? 'rotate(180deg)' : 'none',
              transition: 'all 0.3s',
            }}
            onClick={() => setOpen(!open)}
          >
            <Icon icon='arrow-icon' size={13} />
          </FlexContainer>
        </FlexContainer>

        <Collapse in={open} timeout='auto'>
          <FlexContainer container margin='20px 0 0'>
            <FlexContainer width='50%'>
              <Typography fontWeight={600} fontSize='12px' margin='0 5px 0 0'>
                {summary.quantity_vehicle_types}
              </Typography>
              <Typography fontSize='12px'>Vehículos</Typography>
            </FlexContainer>
            <FlexContainer width='50%'>
              <Typography fontWeight={600} fontSize='12px' margin='0 5px 0 0'>
                {summary.average_weight_in_posibility}
              </Typography>
              <Typography fontSize='12px'>Carga promedio</Typography>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer container margin='10px 0 0'>
            <FlexContainer width='50%'>
              <Typography fontWeight={600} fontSize='12px' margin='0 5px 0 0'>
                {summary.average_distance_in_posibility}
              </Typography>
              <Typography fontSize='12px'>Promedio de ruta</Typography>
            </FlexContainer>
            <FlexContainer width='50%'>
              <Typography fontWeight={600} fontSize='12px' margin='0 5px 0 0'>
                {summary.average_time_in_posibility}
              </Typography>
              <Typography fontSize='12px'>Promedio de ruta</Typography>
            </FlexContainer>
          </FlexContainer>
        </Collapse>
      </FlexContainer>
    </ResumeContainer>
  );
};

const RouteResume: React.FC<{ orders: Array<any> }> = ({ orders }) => {
  const [completed, setCompleted] = useState<number>(0);
  const [failed, setFailed] = useState<number>(0);
  const [rest, setRest] = useState<number>(0);

  useEffect(() => {
    let completedQuantity = 0;
    let failedQuantity = 0;
    let restQuantity = 0;

    orders.forEach(order => {
      if (order.status === 'completed') {
        completedQuantity += 1;
      } else if (order.status === 'failed') {
        failedQuantity += 1;
      } else {
        restQuantity += 1;
      }
    });

    setCompleted(completedQuantity);
    setFailed(failedQuantity);
    setRest(restQuantity);
  }, [orders]);

  return (
    <ResumeContainer>
      <FlexContainer
        position='relative'
        height='4px'
        backgroundColor='#dedede'
        flex='1'
      >
        {orders.length > 0 && (
          <FlexContainer container position='relative'>
            <CustomTooltip
              arrow
              placement='top'
              title={`${completed} Ord. Completadas`}
            >
              <FlexContainer
                height='4px'
                width={`${Math.floor((completed / orders.length) * 100)}%`}
                backgroundColor={statusColors.completed}
                style={{ zIndex: 2 }}
              />
            </CustomTooltip>
            <CustomTooltip
              arrow
              interactive
              placement='top'
              title={`${completed} Ord. Falladas`}
            >
              <FlexContainer
                height='4px'
                width={`${Math.floor((failed / orders.length) * 100)}%`}
                backgroundColor={statusColors.failed}
              />
            </CustomTooltip>
            <CustomTooltip
              arrow
              interactive
              placement='top'
              title={`${rest} Ord. Restantes`}
            >
              <FlexContainer
                height='4px'
                width={`${Math.floor((rest / orders.length) * 100)}%`}
                backgroundColor='#dedede'
              />
            </CustomTooltip>
          </FlexContainer>
        )}
      </FlexContainer>
      <FlexContainer alignItems='center' margin='0 0 0 5px'>
        <Icon icon='delivery-icon' size={15} />
        <Typography fontSize={13} margin='0 0 0 5px'>
          {orders.length > 0
            ? `${Math.floor((completed / orders.length) * 100)} %`
            : '0 %'}
        </Typography>
      </FlexContainer>
    </ResumeContainer>
  );
};

const NavTab: React.FC<NavProps> = ({ selected, onChange }) => {
  const user = useTypedSelector(store => store.user);
  return (
    <FlexContainer container alignItems='center' style={{ zIndex: 1002 }}>
      {tabOptions.map(tab => {
        if (user.permissions.includes(tab.permission)) {
          return (
            <TabOption
              key={tab.value}
              flex='1'
              justify='center'
              padding='6px 0'
              backgroundColor={
                selected === tab.value
                  ? THEME.colors.backgroundColor
                  : '#FFFFFF'
              }
              cursor='pointer'
              selected={selected === tab.value}
              onClick={() => onChange(tab.value)}
            >
              <Typography
                color={
                  selected === tab.value
                    ? THEME.colors.primary
                    : 'text.secondary'
                }
              >
                {tab.label}
              </Typography>
            </TabOption>
          );
        }
        return null;
      })}
    </FlexContainer>
  );
};

const token = localStorage.getItem('AIMO_API_TOKEN');
const organization = localStorage.getItem('AIMO_ADMIN_ORGANIZATION_ID');

const Dashboard: React.FC = () => {
  const [socket, setSocket] = useState<any>();
  const size = useWindowSize();
  const pageLocation = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const branchOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  const organizationOptions = useTypedSelector(state => state.organization);
  const showMenu = useTypedSelector(state => state.sidebar);
  const user = useTypedSelector(state => state.user);
  const dispatch = useDispatch();
  const mapRef = useRef<LeafMap>();
  const mobileMapRef = useRef<LeafMap>();
  const [bounds, setBounds] = useState();
  const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [selectedTab, setSelectedTab] = useState<string>('orders');
  const [timer, setTimer] = useState<any>(null);
  const [showMobileMap, setShowMobileMap] = useState<boolean>(false);
  const [isPicking, setIsPicking] = useState<boolean>(false);
  const [originalTab, setOriginalTab] = useState<string>('');
  const [allowMultiple, setAllowMultiple] = useState<boolean>(true);
  const [statusOptions, setStatusOptions] = useState<Array<any>>([]);
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>(null);
  const [statusError, setStatusError] = useState<string>('');
  // Create view
  const [createView, setCreateView] = useState<boolean>(false);
  const [createStep, setCreateStep] = useState<string>('');
  const [newRequest, setNewRequest] = useState<boolean>(true);
  const [origin, setOrigin] = useState<string>('');
  const [timeWindow, setTimeWindow] = useState<string | null>(null);
  const [routeOrders, setRouteOrders] = useState<Array<string>>([]);
  const [focusOrder, setFocusOrder] = useState<string>('');
  const [routeParams, setRouteParams] = useState({
    route_type_id: '',
    start_hour: moment('09:00', 'HH:mm'),
    origin_estimated_duration: moment('00:00', 'HH:mm'),
    destination_estimated_duration: moment('00:00', 'HH:mm'),
    vehicle_type: '',
    criteria_list: [],
    measurement_unit: '',
  });
  const [selectedVehicles, setSelectedVehicles] = useState<Array<any>>([]);
  const [polylineCoors, setPolylineCoors] = useState([]);
  const [previewLoader, setPreviewLoader] = useState<boolean>(false);
  const [previewResponse, setPreviewResponse] = useState();
  const [previewRoutes, setPreviewRoutes] = useState([]);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  // Route block
  const [routes, setRoutes] = useState<Array<any>>([]);
  const routesRef = useRef(routes);
  const [selectedRoutes, setSelectedRoutes] = useState<Array<any>>([]);
  const [showingRoutes, setShowingRoutes] = useState<Array<any>>([]);
  const [routeFilter, setRouteFilter] = useState<any>({});
  // Detail block
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [routeId, setRouteId] = useState<number>(0);
  const detailRef = useRef({
    show: showDetail,
    id: routeId,
  });
  const [detailData, setDetailData] = useState({});
  const [originalTasks, setOriginalTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  // Order detail
  const [taskDetail, setTaskDetail] = useState({});
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  // Order edit
  const [openOrderEdit, setOrderEdit] = useState<boolean>(false);
  const [editId, setEditId] = useState<string | null>(null);
  // Order block
  const [orders, setOrders] = useState<Array<any>>([]);
  const ordersRef = useRef(orders);
  const [selectedOrders, setSelectedOrders] = useState<Array<any>>([]);
  const [showingOrders, setShowingOrders] = useState<Array<any>>([]);
  const [orderFilter, setOrderFilter] = useState<any>({});
  // Fleet block
  // const [workers, setWorkers] = useState<Array<any>>([]);
  const [selectedWorkers, setSelectedWorkers] = useState<Array<any>>([]);
  const [showingWorkers, setShowingWorkers] = useState<Array<any>>([]);
  const [vehicleFilter, setVehicleFilter] = useState<number | null>(null);
  const [showOnlineWorkers, setShowOnlineWorkers] = useState<boolean>(true);
  // Menu block
  const [showOptionMenu, setShowOptionMenu] = useState<boolean>(false);
  const [availableOptions, setAvailableOptions] = useState<Array<string>>([]);
  // Create API calls
  const [routeConfig, fetchRouteConfig] = useRequest(getRouteConfig);
  const [routeOrigins, fetchRouteOrigins] = useRequest(getRouteOrigins, []);
  const [timeWindows, fetchTimeWindows] = useRequest(getTimeWindows, []);
  const [vehicles, fetchVehicles] = useRequest(getVehicleTypes, []);
  const [ordersToRoute, fetchOrdersToRoute, loadingToRoute] = useRequest(
    getOrdersToSelect,
    []
  );
  const [jobId, fetchJobId] = useRequest(getRoutesVrp);
  const [preview, fetchPreview] = useRequest(getJobConsult);
  const [updatedPreview, updatePreview, updateLoading] = useRequest(
    updateRoutesPreview
  );
  const [createdRoutes, postRoutes, createLoading] = useRequest(createRoutes);
  const [, patchConfig] = useRequest(updateRouteConfig);
  // API calls
  const [requestedRoutes, fetchRoutes, loadingRoutes] = useRequest(
    getDashboardRoutes,
    []
  );
  const [requestedOrders, fetchOrders, loadingOrders] = useRequest(
    getDashboardOrders,
    []
  );
  const [workers, fetchWorkers, loadingWorkers] = useRequest(
    getDashboardWorkers,
    []
  );
  const [workerPositions, fetchWorkerPositions, , , positionError] = useRequest(
    getWorkerPositions,
    {}
  );
  const [updatedTasks, updateTask, loadingUpdateTask] = useRequest(
    patchRouteTasks
  );
  const [
    assignedOrders,
    assignOrders,
    loadingAssignOrders,
    ,
    errorsAssignOrders,
  ] = useRequest(patchRouteTasks);
  const [routeStatus, fetchRouteStatus] = useRequest(getStatuses, []);
  const [orderStatus, fetchOrderStatus] = useRequest(getStatuses, []);
  const [manualStatus, fetchManualStatus] = useRequest(getStatuses, []);
  const [orderTypes, fetchOrderTypes] = useRequest(getOrderTypes, []);
  const [routeTypes, fetchRouteTypes] = useRequest(getRouteTypes, []);
  const [, workerAssign, , , , assignStatus] = useRequest(
    assignRouteWorkerVehicle,
    null
  );
  const [, routesRelease, , , , releaseRouteStatus] = useRequest(
    releaseRoutes,
    null
  );
  const [, updateStatus, , , , updateRequestStatus] = useRequest(
    changeRouteStatus,
    null
  );

  const [pageOrders, setPageOrder] = useState({
    pageSize: 0,
    totalPages: 0,
    currentPage: 0,
  });

  const [officeOptions, setOfficeOptions] = useState([]);
  const defaultOption = { id: '', name: 'Todas', reference_code: '' };
  const toSelectDefaultOption = {
    id: JSON.stringify(defaultOption),
    name: 'Todas',
  };

  const requestRoutes = useCallback(() => {
    const params = {
      branch_office: branchOfficeId,
      page_size: 0,
    };
    fetchRoutes(params, date);
  }, [date, branchOfficeId]);

  const requestOrders = useCallback(() => {
    const params = {
      branch_office: branchOfficeId,
      page_size: 0,
    };
    fetchOrders(params, date);
  }, [date, branchOfficeId]);

  const requestWorkers = useCallback(() => {
    const params = {
      branch_office: branchOfficeId,
      page_size: 0,
    };
    fetchWorkers(params, date);
  }, [date, branchOfficeId]);

  const requestPositions = () => {
    const entities = workers.map(worker => worker.user_id);
    fetchWorkerPositions({
      entity_ids: entities,
    });
  };

  const unsetBounds = () => {
    setBounds(null);
  };

  const handleMessage = useCallback(
    message => {
      const parsed = JSON.parse(message.data);
      if (parsed.id) {
        switch (parsed.event) {
          case 'core.order.update_status': {
            const updatedOrders = ordersRef.current.map(order => {
              if (order.id === parsed.id) {
                return {
                  ...order,
                  status: parsed.data.status,
                  status_label: parsed.data.status_label,
                };
              }
              return order;
            });
            setOrders(updatedOrders);
            break;
          }
          case 'core.route.update_status': {
            const updatedRoutes = routesRef.current.map(route => {
              if (route.id === parsed.id) {
                return {
                  ...route,
                  status: parsed.data.status,
                  status_label: parsed.data.status_label,
                };
              }
              return route;
            });
            setRoutes(updatedRoutes);
            break;
          }
          case 'core.task.update_status': {
            const updatedRoutes = routesRef.current.map(route => {
              const found = find(route.tasks, {
                id: parsed.id,
              });
              if (found) {
                const upTasks = route.tasks.map(task => {
                  if (task.id === parsed.id) {
                    return {
                      ...task,
                      status: parsed.data.status,
                      status_label: parsed.data.status_label,
                    };
                  }
                  return task;
                });

                if (
                  route.id === detailRef.current.id &&
                  detailRef.current.show
                ) {
                  setTasks(upTasks);
                  setOriginalTasks(upTasks);
                }

                return {
                  ...route,
                  tasks: upTasks,
                };
              }
              return route;
            });
            setRoutes(updatedRoutes);
            break;
          }
          case 'core.route.update_tasks': {
            const updatedRoutes = routesRef.current.map(route => {
              if (route.id === parsed.id) {
                const upTasks = parsed.data.task_ids.map(id => {
                  return find(route.tasks, {
                    id,
                  });
                });
                if (
                  route.id === detailRef.current.id &&
                  detailRef.current.show
                ) {
                  setTasks(upTasks);
                  setOriginalTasks(upTasks);
                }
                return {
                  ...route,
                  tasks: upTasks,
                };
              }
              return route;
            });
            setRoutes(updatedRoutes);
            break;
          }
          default:
            break;
        }
      }
    },
    [orders, routes]
  );

  useEffect(() => {
    const connection = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET}?token=${token}&organization=${organization}`
    );
    setSocket(connection);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.onmessage = message => handleMessage(message);
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  useEffect(() => {
    routesRef.current = routes;
  }, [routes]);

  useEffect(() => {
    ordersRef.current = orders;
  }, [orders]);

  useEffect(() => {
    detailRef.current = {
      show: showDetail,
      id: routeId,
    };
  }, [showDetail, routeId]);

  useEffect(() => {
    if (pageLocation.search && pageLocation.search === '?crear_ruta=true') {
      fetchVehicles({
        organization_branch_office: branchOfficeId,
        page_size: 0,
      });
      fetchRouteOrigins({
        page_size: 0,
      });
      fetchTimeWindows({
        organization_branch_office: branchOfficeId,
        page_size: 0,
      });
      fetchRouteConfig();
      setCreateView(true);
      setCreateStep('orders');
    }
  }, [pageLocation]);

  useLayoutEffect(() => {
    if (mapRef && mapRef.current) {
      mapRef.current.leafletElement.on('zoomstart', unsetBounds);
      mapRef.current.leafletElement.on('movestart', unsetBounds);
    }
    if (mobileMapRef && mobileMapRef.current) {
      mobileMapRef.current.leafletElement.on('zoomstart', unsetBounds);
      mobileMapRef.current.leafletElement.on('movestart', unsetBounds);
    }

    return () => {
      if (mapRef && mapRef.current) {
        mapRef.current.leafletElement.off('zoomstart', unsetBounds);
        mapRef.current.leafletElement.off('movestart', unsetBounds);
      }
      if (mobileMapRef && mobileMapRef.current) {
        mobileMapRef.current.leafletElement.off('zoomstart', unsetBounds);
        mobileMapRef.current.leafletElement.off('movestart', unsetBounds);
      }
    };
  }, [bounds]); // TODO: fix dependency

  useEffect(() => {
    if (!isEmpty(jobId)) {
      fetchPreview(jobId.job_id);
    }
  }, [jobId]);

  useEffect(() => {
    if (!isEmpty(previewRoutes)) {
      const poly = [];
      previewRoutes.forEach(route => {
        if (route.overview_polyline) {
          const decode = polyUtil.decode(route.overview_polyline);
          poly.push(decode);
        }
      });
      if (poly.length > 0) {
        const newBounds = L.latLngBounds(poly);
        setBounds(newBounds);
        setPolylineCoors(poly);
      }
    }
  }, [previewRoutes]);

  useEffect(() => {
    if (!isEmpty(preview)) {
      switch (preview.status) {
        case 'PENDING': {
          setTimeout(() => {
            fetchPreview(jobId.job_id);
          }, Math.ceil(routeOrders.length / 200) * 1000);
          break;
        }
        case 'FAILURE': {
          setPreviewLoader(false);
          enqueueSnackbar('No se encontraron rutas.', {
            variant: 'error',
          });
          break;
        }
        case 'SUCCESS': {
          let totalWeight = 0;
          let totalCost = 0;
          if (!preview.result.routes) {
            setPreviewLoader(false);
            enqueueSnackbar('No se encontraron rutas.', {
              variant: 'error',
            });
            break;
          }
          const possibleRoutes = Object.keys(preview.result.routes).map(
            routeKey => {
              let routeWeight = 0;
              const tasksObj = preview.result.routes[routeKey].locations
                .filter(location =>
                  find(ordersToRoute, { task_id: parseInt(location.id, 10) })
                )
                .map(location => {
                  const locationObj = find(ordersToRoute, {
                    task_id: parseInt(location.id, 10),
                  });
                  routeWeight += parseFloat(locationObj.total_weight || 0);

                  return {
                    task_id: location.id,
                    coordinate: {
                      latitude: location.latitude,
                      longitude: location.longitude,
                    },
                    address: locationObj.address,
                    contact_person: locationObj.contact_person,
                    reference_code: locationObj.reference_code,
                    duration: {
                      text: '0',
                      value: 0.0,
                    },
                    distance: {
                      text: '0',
                      value: 0.0,
                    },
                    weight: locationObj.total_weight || 0,
                    is_start: false,
                    is_end: false,
                    estimated_arrival_hour: location.eta,
                    start: locationObj.start,
                    end: locationObj.end,
                  };
                });

              const vehicleType = find(vehicles, {
                id: parseInt(routeKey.split('_')[0], 10),
              });

              const minutes =
                preview.result.routes[routeKey].time_in_route % 60 >= 10
                  ? preview.result.routes[routeKey].time_in_route % 60
                  : `0${preview.result.routes[routeKey].time_in_route % 60}`;

              const timeRouteLabel = `${Math.floor(
                preview.result.routes[routeKey].time_in_route / 60
              )}:${minutes} hrs`;

              const distanceRouteLabel = parseFloat(
                (
                  preview.result.routes[routeKey].distance_in_route / 1000
                ).toFixed(2)
              );

              totalCost += parseFloat(vehicleType.estimated_cost);
              totalWeight += routeWeight;

              return {
                tasks: tasksObj,
                vehicle_type: vehicleType,
                key: routeKey,
                overview_polyline:
                  preview.result.routes[routeKey].overview_polyline,
                centroid: `${preview.result.routes[routeKey].locations[0].latitude}, ${preview.result.routes[routeKey].locations[0].longitude}`,
                total_points: preview.result.routes[routeKey].locations.length,
                estimated_time_in_route:
                  preview.result.routes[routeKey].time_in_route,
                estimated_time_in_route_label: timeRouteLabel,
                estimated_distance_in_route:
                  preview.result.routes[routeKey].distance_in_route,
                estimated_distance_in_route_label: `${distanceRouteLabel} kms`,
                estimated_weight_in_route: routeWeight,
                estimated_weight_in_route_label: `${routeWeight} kgs`,
                estimated_volumen_in_route: 0, // no hay
                estimated_volumen_in_route_label: '0 m3',
              };
            }
          );

          const timeAverage =
            (preview.result.total_time * 60) /
            Object.keys(preview.result.routes).length;

          const timeAverageMinutes = Math.floor((timeAverage % 3600) / 60);
          const minuteLabel =
            timeAverageMinutes >= 10
              ? timeAverageMinutes
              : `0${timeAverageMinutes}`;

          const distanceAverage = Math.floor(
            preview.result.total_distance /
              1000 /
              Object.keys(preview.result.routes).length
          );

          setPreviewResponse({
            total_cost_per_posibility: totalCost,
            total_time_per_posibility: preview.result.total_time,
            possible_route: possibleRoutes,
            summary: {
              quantity_vehicle_types: uniq(
                Object.keys(preview.result.routes).map(key => key.split('_')[0])
              ).length,
              average_time_in_posibility: `${Math.floor(
                timeAverage / 3600
              )}:${minuteLabel} hrs`,
              average_distance_in_posibility: `${distanceAverage} kms`,
              average_weight_in_posibility: `${parseFloat(
                (
                  totalWeight / Object.keys(preview.result.routes).length
                ).toFixed(2)
              )} kgs`,
              average_volumen_in_posibility: '0 m3', // no hay
            },
          });
          setPreviewRoutes(possibleRoutes);
          setPreviewLoader(false);
          setCreateStep('preview');
          break;
        }
        default:
          break;
      }
    }
  }, [preview]);

  useEffect(() => {
    setRoutes(requestedRoutes);
  }, [requestedRoutes]);

  useEffect(() => {
    setOrders(requestedOrders);
  }, [requestedOrders]);

  useEffect(() => {
    fetchRouteStatus({
      model_types: 'route',
      status_types: 'manual,automatic',
      page_size: 0,
    });
    fetchManualStatus({
      model_types: 'route',
      status_types: 'manual',
      page_size: 0,
    });
    fetchOrderStatus({
      model_types: 'order',
      status_types: 'manual,automatic',
      page_size: 0,
    });
    fetchOrderTypes({
      page_size: 0,
    });
    fetchRouteTypes({
      page_size: 0,
    });
  }, [
    fetchRouteStatus,
    fetchManualStatus,
    fetchOrderStatus,
    fetchOrderTypes,
    fetchRouteTypes,
  ]);

  useEffect(() => {
    requestRoutes();
    requestOrders();
    requestWorkers();
  }, [requestRoutes, requestOrders, requestWorkers]);

  useEffect(() => {
    if (orders.length > 0) {
      setShowingOrders(orders);
    }
  }, [orders]);

  useEffect(() => {
    requestPositions();
  }, [workers]);

  useEffect(() => {
    setStatusOptions(
      optionFormatter(manualStatus, {
        id: item => item.id,
        name: item => item.overwritten_label,
      })
    );
  }, [manualStatus]);

  useEffect(() => {
    if (selectedTasks.length > 0) {
      setAvailableOptions(taskOptions);
      setShowOptionMenu(true);
    } else if (selectedRoutes.length > 0) {
      if (selectedRoutes.length === 1) {
        setAvailableOptions(routeSingleOptions);
      } else {
        setAvailableOptions(routeOptions);
      }
      setShowOptionMenu(true);
    } else if (selectedOrders.length > 0) {
      setAvailableOptions(orderOptions);
      setShowOptionMenu(true);
    } else if (selectedWorkers.length > 0) {
      setAvailableOptions(workerOptions);
      setShowOptionMenu(true);
    } else {
      setShowOptionMenu(false);
      setTimeout(() => {
        setAvailableOptions([]);
      }, 100);
    }
  }, [selectedOrders, selectedTasks, selectedRoutes, selectedWorkers]);

  useEffect(() => {
    if (!isEmpty(workerPositions.results)) {
      setTimer(
        setTimeout(() => {
          requestPositions();
        }, 15000)
      );
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [workerPositions, positionError]);

  useEffect(() => {
    if (!createView) {
      let positions = [];
      const orderPositions = showingOrders
        .filter(order => order.coordinates)
        .map(order => [
          order.coordinates.latitude,
          order.coordinates.longitude,
        ]);
      const routePositions = showingRoutes.map(route => route.path);
      if (workerPositions.results && !isEmpty(showingWorkers)) {
        positions = workerPositions.results
          .filter(pos => find(showingWorkers, { id: pos.entity_id }))
          .map(pos => [pos.latitude, pos.longitude]);
      }
      if (
        routePositions.length > 0 ||
        positions.length > 0 ||
        orderPositions.length > 0
      ) {
        const newBounds = L.latLngBounds(
          routePositions.concat(positions, orderPositions)
        );
        setBounds(newBounds);
      }
    }
  }, [showingRoutes, showingOrders, showingWorkers, createView]);

  useEffect(() => {
    if (createView && routeOrders.length > 0) {
      const positions = routeOrders
        .map(id => {
          return find(ordersToRoute, {
            order_id: id,
          });
        })
        .filter(order => order.coordinate)
        .map(order => [order.coordinate.latitude, order.coordinate.longitude]);
      if (positions.length > 0) {
        const newBounds = L.latLngBounds(positions);
        setBounds(newBounds);
      }
    }
  }, [createView, routeOrders]);

  useEffect(() => {
    if (!isEmpty(updatedTasks)) {
      enqueueSnackbar('Las tareas fueron actualizadas correctamente.', {
        variant: 'success',
      });
      setTasks(updatedTasks.tasks);
      setOriginalTasks(updatedTasks.tasks);
      setAvailableOptions([]);
      setShowOptionMenu(false);
      requestOrders();
      requestRoutes();
    }
  }, [updatedTasks, requestOrders]);

  useEffect(() => {
    if (!isEmpty(assignedOrders)) {
      enqueueSnackbar('Las órdenes fueron asignadas correctamente.', {
        variant: 'success',
      });
      requestRoutes();
      requestOrders();
      setSelectedRoutes([]);
      setSelectedOrders([]);
      setAvailableOptions([]);
      setIsPicking(false);
      setSelectedTab(originalTab);
      setOriginalTab('');
      setAllowMultiple(true);
      setShowOptionMenu(false);
    }
  }, [assignedOrders]);

  useEffect(() => {
    if (assignStatus === 200) {
      enqueueSnackbar('Las rutas fueron asignadas correctamente.', {
        variant: 'success',
      });
      requestRoutes();
      requestWorkers();
      setSelectedRoutes([]);
      setSelectedWorkers([]);
      setAvailableOptions([]);
      setIsPicking(false);
      setSelectedTab(originalTab);
      setOriginalTab('');
      setVehicleFilter(null);
      setAllowMultiple(true);
      setShowOptionMenu(false);
    } else if (assignStatus && assignStatus !== 204) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [assignStatus]);

  useEffect(() => {
    if (releaseRouteStatus === 200) {
      enqueueSnackbar('Se liberaron las rutas correctamente.', {
        variant: 'success',
      });
      requestRoutes();
    } else if (releaseRouteStatus && releaseRouteStatus !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [releaseRouteStatus, enqueueSnackbar]);

  useEffect(() => {
    if (updateRequestStatus === 200) {
      enqueueSnackbar('Se cambio el estado de las rutas correctamente.', {
        variant: 'success',
      });
      setStatusError('');
      setSelectedStatus(null);
      setStatusModal(false);
      requestRoutes();
    } else if (updateRequestStatus && updateRequestStatus !== 200) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [updateRequestStatus, enqueueSnackbar]);

  useEffect(() => {
    if (!isEmpty(routeConfig)) {
      const criteriaList = [];
      if (routeConfig.use_weight_for_routes) {
        criteriaList.push('max_weight_per_vehicle');
      }
      if (routeConfig.use_max_points_for_routes) {
        criteriaList.push('max_points_per_vehicle');
      }

      setRouteParams({
        ...routeParams,
        criteria_list: criteriaList,
        measurement_unit: routeConfig.default_measurement_unit,
        origin_estimated_duration: moment(
          routeConfig.default_origin_estimated_duration,
          'HH:mm:ss'
        ),
        destination_estimated_duration: moment(
          routeConfig.default_destination_estimated_duration,
          'HH:mm:ss'
        ),
      });
    }
  }, [routeConfig]);

  useEffect(() => {
    if (!isEmpty(updatedPreview)) {
      setPreviewResponse(updatedPreview);
      setPreviewRoutes(updatedPreview.possible_route);
      setHasChanges(false);
    }
  }, [updatedPreview]);

  useEffect(() => {
    if (!isEmpty(errorsAssignOrders)) {
      if (errorsAssignOrders.data.errors) {
        if (
          errorsAssignOrders.data.errors[0] ===
          'Can not modify tasks of a route in final status.'
        ) {
          enqueueSnackbar(
            'No se puede asignar ordenes en una ruta finalizada',
            {
              variant: 'error',
            }
          );
        } else if (errorsAssignOrders.data.errors[0].non_field_errors) {
          enqueueSnackbar(errorsAssignOrders.data.errors[0].non_field_errors, {
            variant: 'error',
          });
        }
      }
    }
  }, [errorsAssignOrders]);

  useEffect(() => {
    chooseOrganization({
      page_size: 0,
    }).then(res => {
      const organizationObj = find(res.data, {
        id: parseInt(`${organizationOptions.selectedOrganization.id}`, 10),
      });

      if (organizationObj) {
        setOfficeOptions(
          optionFormatter(organizationObj.administrator_branch_offices, {
            id: option => JSON.stringify(option),
            name: option => option.name,
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    if (!showOnlineWorkers) {
      setShowingWorkers(showingWorkers);
    } else {
      setShowingWorkers(showingWorkers.filter(worker => worker.online));
    }
  }, [showOnlineWorkers]);

  const resetCreateView = () => {
    setNewRequest(true);
    setCreateView(false);
    setCreateStep('orders');
    setOrigin('');
    setTimeWindow(null);
    setRouteOrders([]);
    setRouteParams({
      route_type_id: '',
      start_hour: moment('09:00', 'HH:mm'),
      origin_estimated_duration: moment('00:00', 'HH:mm'),
      destination_estimated_duration: moment('00:00', 'HH:mm'),
      vehicle_type: '',
      criteria_list: [],
      measurement_unit: '',
    });
    setSelectedVehicles([]);
  };

  useEffect(() => {
    if (!isEmpty(createdRoutes)) {
      patchConfig({
        use_weight_for_routes: routeParams.criteria_list.includes(
          'max_weight_per_vehicle'
        ),
        use_max_points_for_routes: routeParams.criteria_list.includes(
          'max_points_per_vehicle'
        ),
        use_max_distance_for_routes: routeParams.criteria_list.includes(
          'max_km_per_vehicle'
        ),
        use_tags_for_routes: routeParams.criteria_list.includes(
          'use_order_tags'
        ),
        default_measurement_unit: routeParams.measurement_unit,
        default_packing_type_for_routes: 'all_same',
        default_origin_estimated_duration: moment(
          routeParams.origin_estimated_duration
        ).format('HH:mm:ss'),
        default_destination_estimated_duration: moment(
          routeParams.destination_estimated_duration
        ).format('HH:mm:ss'),
      });

      enqueueSnackbar('Se crearon las rutas correctamente.', {
        variant: 'success',
      });

      resetCreateView();
      requestRoutes();
      requestOrders();
    }
  }, [createdRoutes]);

  const onDragEnd = (result: any) => {
    const { destination, source, reason } = result;
    if (
      !destination ||
      reason === 'CANCEL' ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    const newTasks = cloneDeep(tasks);
    const destinationIndex = parseInt(destination.index, 10);
    const originIndex = parseInt(source.index, 10);
    const taskMoved = cloneDeep(tasks[originIndex]);

    newTasks.splice(originIndex, 1);
    newTasks.splice(destinationIndex, 0, taskMoved);
    setTasks(newTasks);
    setShowOptionMenu(true);
    setAvailableOptions(reorderOptions);
  };

  const handleMenuOptions = (event: string) => {
    switch (event) {
      // Orders actions
      case 'showMapOrder': {
        const ordersToShow = selectedOrders.map(id => {
          const order = find(orders, {
            id,
          });
          return {
            id: order.id,
            coordinates: order.coordinates,
            code: order.reference_code || order.code,
            address: order.address,
            status: order.status,
            status_label: order.status_label,
            contact_person: order.contact_person,
          };
        });
        setShowingRoutes([]);
        setShowingWorkers([]);
        setShowingOrders(ordersToShow);
        setShowMobileMap(true);
        break;
      }
      case 'assignOrders': {
        if (isPicking) {
          if (selectedOrders.length <= 0 || selectedRoutes.length !== 1) {
            enqueueSnackbar('Seleccione una ruta.', {
              variant: 'error',
            });
            return;
          }
          const route = find(routes, {
            id: selectedRoutes[0],
          });
          const tasksToAssign = selectedOrders
            .map(order => find(orders, { id: order }))
            .reduce((prev, next) => prev.concat(next.task_id), []);
          const taskIds = route.tasks
            .filter(task => !task.is_start)
            .map(task => task.id)
            .concat(tasksToAssign);
          const body = {
            task_ids: taskIds,
          };

          assignOrders(body, route.id);
        } else {
          setIsPicking(true);
          setOriginalTab(selectedTab);
          if (selectedTab === 'orders') {
            setSelectedTab('routes');
            setAllowMultiple(false);
          } else {
            setSelectedTab('orders');
            setAllowMultiple(true);
          }
        }
        break;
      }
      // Routes actions
      case 'showMapRoute': {
        const toggleRoutes = selectedRoutes.map(id =>
          find(routes, {
            id,
          })
        );
        const workersToAdd = toggleRoutes
          .filter(route => route.worker)
          .map(route => ({
            id: route.worker.user_id,
            name: `${route.worker.first_name} ${route.worker.last_name}`,
            phone: route.worker.phone,
            plate: route.vehicle ? route.vehicle.plate : 'No disponible',
          }));

        const routesToAdd = toggleRoutes.map(route => {
          const path = polyUtil.decode(route.polyline);
          const routeTasks = route.tasks.map(task => {
            let icon = task.status;
            if (task.status !== 'completed' && task.status !== 'failed') {
              icon = 'task';
            }
            return {
              id: task.id,
              coordinates: task.coordinates,
              address: task.address,
              address_detail: task.address_detail,
              contact_person: task.contact_person,
              status: task.status,
              status_label: task.status_label,
              original_eta: task.original_eta,
              current_eta: task.current_eta,
              is_start: task.is_start,
              code: task.order
                ? task.order.reference_code || task.order.code
                : null,
              icon,
            };
          });
          return {
            id: route.id,
            worker: route.worker ? route.worker.user_id : null,
            tasks: routeTasks,
            path,
          };
        });
        setShowingOrders([]);
        setShowingWorkers(workersToAdd);
        setShowingRoutes(routesToAdd);
        setShowMobileMap(true);
        break;
      }
      case 'showDetail': {
        const route = find(routes, {
          id: selectedRoutes[0],
        });
        setOriginalTasks(route.tasks);
        setTasks(route.tasks);
        setRouteId(route.id);
        setDetailData(route);
        setShowDetail(true);
        break;
      }
      case 'workerAssign': {
        if (isPicking) {
          if (selectedRoutes.length <= 0 || selectedWorkers.length !== 1) {
            enqueueSnackbar('Seleccione un worker.', {
              variant: 'error',
            });
            return;
          }
          const worker = find(workers, {
            id: selectedWorkers[0],
          });
          workerAssign({
            route_ids: selectedRoutes,
            vehicle: worker.vehicle.plate,
          });
        } else {
          setIsPicking(true);
          setOriginalTab(selectedTab);
          if (selectedTab === 'routes') {
            const routeObjs = selectedRoutes
              .map(id =>
                find(routes, {
                  id,
                })
              )
              .filter(route => !!route.vehicle_type);
            const vehicleType =
              routeObjs.length > 0 ? routeObjs[0].vehicle_type.id : null;
            const isSameType = routeObjs.every(
              route => route.vehicle_type.id === vehicleType
            );
            if (!isSameType) {
              enqueueSnackbar(
                'Seleccione rutas con el mismo tipo de vehiculo.',
                {
                  variant: 'error',
                }
              );
              return;
            }
            setVehicleFilter(vehicleType);
            setSelectedTab('fleet');
            setAllowMultiple(false);
          } else {
            setSelectedTab('routes');
            setAllowMultiple(true);
          }
        }
        break;
      }
      case 'freeRoute':
        routesRelease({
          route_ids: selectedRoutes,
          organization_branch_office_id: branchOfficeId,
        });
        break;
      case 'changeStatus':
        setStatusModal(true);
        break;
      // Workers actions
      case 'showMapWorker': {
        const workersToShow = selectedWorkers.map(id => {
          const worker = find(workers, {
            id,
          });
          return {
            id: worker.user_id,
            name: `${worker.first_name} ${worker.last_name}`,
            phone: worker.phone,
            plate: worker.vehicle ? worker.vehicle.plate : 'No disponible',
            online: worker.is_online,
          };
        });
        setShowingOrders([]);
        setShowingRoutes([]);
        setShowingWorkers(workersToShow);
        setShowMobileMap(true);
        break;
      }
      // Tasks actions
      case 'confirmReorder': {
        const taskIds = tasks
          .filter(task => !task.is_start)
          .map(task => task.id);
        const body = {
          task_ids: taskIds,
        };
        updateTask(body, routeId);
        break;
      }
      case 'cancelReorder':
        setTasks(originalTasks);
        setAvailableOptions([]);
        setShowOptionMenu(false);
        break;
      case 'freeTask': {
        const taskIds = tasks
          .filter(task => !task.is_start)
          .filter(task => !selectedTasks.includes(task.id))
          .map(task => task.id);
        const body = {
          task_ids: taskIds,
        };
        updateTask(body, routeId);
        break;
      }
      // Common actions
      case 'cancelOption': {
        switch (originalTab) {
          case 'orders':
            setSelectedRoutes([]);
            break;
          case 'routes':
            setVehicleFilter(null);
            setSelectedOrders([]);
            setSelectedWorkers([]);
            break;
          case 'workers':
            setSelectedRoutes([]);
            break;
          default:
            break;
        }
        setSelectedTab(originalTab);
        setAllowMultiple(true);
        setIsPicking(false);
        break;
      }
      case 'providerAssign':
        break;
      case 'deleteRoute':
        break;
      default:
        break;
    }
  };

  const handleDeselect = () => {
    setSelectedOrders([]);
    setSelectedRoutes([]);
    setSelectedTasks([]);
    setSelectedWorkers([]);
  };

  const handleRequestOrders = (newOrigin, keepOrders?: boolean) => {
    if (!keepOrders) {
      setRouteOrders([]);
    }
    let timeWindowsId = '';
    const parsedOrigin = JSON.parse(newOrigin);
    const originCoors = `${parsedOrigin.coordinate.latitude},${parsedOrigin.coordinate.longitude}`;
    if (timeWindow) {
      timeWindowsId = JSON.parse(timeWindow).id;
    }
    fetchOrdersToRoute({
      delivery_programmed_date: date,
      delivery_time_window: timeWindowsId,
      origin: originCoors,
      page_size: 0,
      branch_office_id: branchOfficeId,
    });
  };

  const handleRequestPreview = () => {
    let startHour = null;
    let endHour = null;
    const parsedOrigin = JSON.parse(origin);
    if (timeWindow) {
      const parsedTimeWindows = JSON.parse(timeWindow);
      startHour = parsedTimeWindows.start_hour.slice(0, 5);
      endHour = parsedTimeWindows.end_hour.slice(0, 5);
    }
    const visits = routeOrders.map(id => {
      const order = find(ordersToRoute, {
        order_id: id,
      });
      const demands: DemandType = {};
      if (routeParams.criteria_list.includes('max_points_per_vehicle')) {
        demands.points = 1;
      }
      if (routeParams.criteria_list.includes('max_weight_per_vehicle')) {
        demands.weight = order.total_weight;
      }
      if (routeParams.measurement_unit) {
        const unit = find(order.items, {
          code: routeParams.measurement_unit,
        });
        demands[routeParams.measurement_unit] = unit ? unit.quantity : 0;
      }

      return {
        id: order.task_id,
        location: {
          latitude: order.coordinate.latitude,
          longitude: order.coordinate.longitude,
        },
        demands,
        duration:
          parseInt(
            moment(routeParams.destination_estimated_duration)
              .format('HH:mm')
              .split(':')[0],
            10
          ) *
            60 +
          parseInt(
            moment(routeParams.destination_estimated_duration)
              .format('HH:mm')
              .split(':')[1],
            10
          ),
        start: startHour || order.start.slice(0, 5),
        end: endHour || order.end.slice(0, 5),
      };
    });

    const vehiclesObj = selectedVehicles.map(vehicle => {
      const vehicleObj = find(vehicles, { id: vehicle });
      const capacities: DemandType = {};

      if (routeParams.criteria_list.includes('max_points_per_vehicle')) {
        capacities.points = parseInt(vehicleObj.max_destinations_traveled, 10);
      }
      if (routeParams.criteria_list.includes('max_weight_per_vehicle')) {
        capacities.weight = parseFloat(vehicleObj.weight);
      }
      if (routeParams.measurement_unit) {
        const unit = find(vehicleObj.package_types, {
          code: routeParams.measurement_unit,
        });
        capacities[routeParams.measurement_unit] = unit ? unit.quantity : 0;
      }
      return {
        id: vehicleObj.id,
        start_location: {
          latitude: parsedOrigin.coordinate.latitude,
          longitude: parsedOrigin.coordinate.longitude,
        },
        capacities,
        quantity: 50,
        cost: vehicleObj.estimated_cost,
      };
    });
    setPreviewLoader(true);
    fetchJobId({
      visits,
      vehicles: vehiclesObj,
      options: {
        start_hour: moment(routeParams.start_hour).format('HH:mm'),
        origin_estimated_duration:
          parseInt(
            moment(routeParams.origin_estimated_duration)
              .format('HH:mm')
              .split(':')[0],
            10
          ) *
            60 +
          parseInt(
            moment(routeParams.origin_estimated_duration)
              .format('HH:mm')
              .split(':')[1],
            10
          ),
      },
    });
  };

  const handleUpdatePreview = () => {
    let timeWindowsId = '';
    const validRoutes = previewRoutes.filter(route => route.tasks.length > 0);
    if (timeWindow) {
      timeWindowsId = JSON.parse(timeWindow).id;
    }

    let data = {
      possible_route: validRoutes,
      criteria_list: routeParams.criteria_list.filter(
        criteria => criteria !== 'use_order_tags'
      ),
      use_order_tags: routeParams.criteria_list.includes('use_order_tags'),
      measurement_unit: routeParams.measurement_unit,
      start_hour: moment(routeParams.start_hour).format('HH:mm'),
      origin_estimated_duration: moment(
        routeParams.origin_estimated_duration
      ).format('HH:mm'),
      destination_estimated_duration: moment(
        routeParams.destination_estimated_duration
      ).format('HH:mm'),
      delivery_time_window_id: timeWindowsId,
    };

    if (!timeWindow) {
      data = omit(data, ['delivery_time_window_id']);
    }

    updatePreview(
      routeParams.measurement_unit === ''
        ? omit(data, 'measurement_unit')
        : data
    );
  };

  const handleCreateRoutes = () => {
    let timeWindowsId = null;
    if (timeWindow) {
      timeWindowsId = JSON.parse(timeWindow).id;
    }
    const validRoutes = previewRoutes.filter(route => route.tasks.length > 0);

    const data = {
      ...previewResponse,
      possible_route: validRoutes,
      delivery_programmed_date: date,
      route_type_id: routeParams.route_type_id,
      start_hour: moment(routeParams.start_hour).format('HH:mm'),
      origin_estimated_duration: moment(
        routeParams.origin_estimated_duration
      ).format('HH:mm:ss'),
      destination_estimated_duration: moment(
        routeParams.destination_estimated_duration
      ).format('HH:mm:ss'),
      delivery_time_window_id: timeWindowsId,
      branch_office: branchOfficeId,
    };

    postRoutes(timeWindowsId ? data : omit(data, ['delivery_time_window_id']));
  };

  const handleCreateStep = step => {
    if (step === '') {
      resetCreateView();
      setCreateView(false);
      return;
    }
    switch (step) {
      case 'orders':
        setCreateStep(step);
        break;
      case 'parameters': {
        const everyCoordinates = routeOrders
          .map(item =>
            find(ordersToRoute, {
              order_id: item,
            })
          )
          .every(item => item.coordinate);
        if (routeOrders.length === 0) {
          enqueueSnackbar('Seleccione al menos una orden para continuar.', {
            variant: 'error',
          });
        } else if (!everyCoordinates) {
          enqueueSnackbar(
            'Una o más órdenes no cuentan con una dirección válida.',
            {
              variant: 'error',
            }
          );
        } else {
          setCreateStep(step);
        }
        break;
      }
      case 'preview': {
        if (!routeParams.route_type_id) {
          enqueueSnackbar('Seleccione el tipo de ruta.', {
            variant: 'error',
          });
          return;
        }

        if (
          !routeParams.start_hour ||
          !moment(routeParams.start_hour).isValid()
        ) {
          enqueueSnackbar('Ingrese una hora de inicio válida.', {
            variant: 'error',
          });
          return;
        }

        if (
          !routeParams.origin_estimated_duration ||
          !moment(routeParams.origin_estimated_duration).isValid() ||
          moment(routeParams.origin_estimated_duration).format('HH:mm') ===
            '00:00'
        ) {
          enqueueSnackbar('Ingrese un tiempo de duración en origen válido.', {
            variant: 'error',
          });
          return;
        }

        if (
          !routeParams.destination_estimated_duration ||
          !moment(routeParams.destination_estimated_duration).isValid() ||
          moment(routeParams.destination_estimated_duration).format('HH:mm') ===
            '00:00'
        ) {
          enqueueSnackbar('Ingrese un tiempo de duración por tarea válido.', {
            variant: 'error',
          });
          return;
        }

        if (selectedVehicles.length === 0) {
          enqueueSnackbar('Seleccione al menos un vehículo.', {
            variant: 'error',
          });
          return;
        }

        handleRequestPreview();
        break;
      }
      case 'update':
        handleUpdatePreview();
        break;
      case 'create':
        handleCreateRoutes();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (focusOrder) {
      setTimeout(() => {
        setFocusOrder('');
      }, 5000);
    }
  }, [focusOrder]);

  const handleScrollTable = orderId => {
    setFocusOrder(orderId);
    const elm = document.getElementById(orderId);
    elm.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  useEffect(() => {
    setOrderFilter({});
    setRouteFilter({});
    setSelectedRoutes([]);
    setShowingRoutes([]);
    setShowDetail(false);
    setOriginalTasks([]);
    setTasks([]);
    setSelectedTasks([]);
    setSelectedOrders([]);
    setShowingOrders([]);
    setSelectedWorkers([]);
    setShowingWorkers([]);
    setIsPicking(false);
    setSelectedTab('orders');
    if (createView) {
      setCreateStep('orders');
      if (origin) {
        handleRequestOrders(origin);
      }
    }
  }, [date, branchOfficeId]);

  useEffect(() => {
    if (origin) {
      handleRequestOrders(origin);
    }
  }, [timeWindow]);

  useEffect(() => {
    if (orders.length > 0) {
      setPageOrder({
        pageSize: 10,
        totalPages: Math.ceil(orders.length / 10),
        currentPage: 1,
      });
    }
  }, [orders]);

  return (
    <FlexContainer
      width='100%'
      height='100vh'
      position='relative'
      overflow='hidden'
    >
      <Header showMenu={showMenu}>
        <FlexContainer
          container={size.width <= 768}
          height={size.width > 768 ? '100%' : '43px'}
          flex='1'
          justify='space-between'
          alignItems='center'
          backgroundColor={
            size.width > 768 ? '#FFFFFF' : THEME.colors.borderColor
          }
        >
          <FlexContainer>
            <FormControl
              control='date'
              onChange={value => setDate(value)}
              value={date}
              toolbar={false}
              margin={size.width <= 768 ? '0' : '0 5px 0 20px'}
              width='160px'
              borderless
              background={false}
            />
            <Select
              placeholder='Seleccione una organización'
              value={JSON.stringify(organizationOptions.selectedBranchOffice)}
              options={officeOptions}
              defaultOption={toSelectDefaultOption}
              onChange={value => {
                const parsedValue = JSON.parse(value);
                dispatch(
                  selectBranchOffice({
                    id: parsedValue.id,
                    name: parsedValue.name,
                    reference_code: parsedValue.reference_code,
                  })
                );
              }}
              borderless
            />
          </FlexContainer>
          <HeaderButtons
            onRoutes={() => {
              fetchVehicles({
                organization_branch_office: branchOfficeId,
                page_size: 0,
              });
              fetchRouteOrigins({
                page_size: 0,
              });
              fetchTimeWindows({
                organization_branch_office: branchOfficeId,
                page_size: 0,
              });
              fetchRouteConfig();
              setCreateView(true);
              setCreateStep('orders');
            }}
          />
        </FlexContainer>
      </Header>
      <FlexContainer
        container
        padding={
          // eslint-disable-next-line no-nested-ternary
          size.width > 768 ? '50px 0 0' : '90px 0 0'
        }
        height='100%'
        direction='column'
        overflow='hidden'
      >
        <FlexContainer container height='100%'>
          <FlexContainer
            container
            height='100%'
            overflow='hidden'
            position='relative'
            direction={size.width > 768 ? 'row' : 'column'}
          >
            {createView ? (
              <CreateView
                step={createStep}
                onRequestOrders={(newOrigin, keepOrders) => {
                  setNewRequest(false);
                  handleRequestOrders(newOrigin, keepOrders);
                }}
                orders={newRequest ? [] : ordersToRoute}
                focusOrder={focusOrder}
                loadingOrders={loadingToRoute}
                origin={origin}
                onOriginChange={value => setOrigin(value)}
                timeWindow={timeWindow}
                onWindowChange={window => setTimeWindow(window)}
                vehicles={vehicles}
                routeOrigins={routeOrigins}
                windowOptions={timeWindows}
                selectedOrders={routeOrders}
                onSelectOrder={(selected, checked) => {
                  let arr = [];
                  if (checked) {
                    arr = uniq(routeOrders.concat(selected));
                  } else {
                    arr = routeOrders.filter(
                      order => !selected.includes(order)
                    );
                  }
                  setRouteOrders(arr);
                }}
                paramValues={routeParams}
                onParamChange={(value, name) => {
                  setRouteParams({
                    ...routeParams,
                    [name]: value,
                  });
                }}
                selectedVehicles={selectedVehicles}
                onVehicleChange={selected => setSelectedVehicles(selected)}
                previewLoader={previewLoader}
                previewRoutes={previewRoutes}
                onRouteChange={newRoutes => {
                  setHasChanges(true);
                  setPreviewRoutes(newRoutes);
                }}
                onTaskClick={task => {
                  const latLngs = [
                    task.coordinate.latitude,
                    task.coordinate.longitude,
                  ];
                  const markerBounds = L.latLngBounds(latLngs, latLngs);
                  setBounds(markerBounds);
                }}
                updateLoader={updateLoading}
                createLoader={createLoading}
              />
            ) : (
              <ResponsiveContainer
                height='100%'
                position='relative'
                direction='column'
              >
                <NavTab
                  selected={selectedTab}
                  onChange={value => {
                    setSelectedOrders([]);
                    setSelectedRoutes([]);
                    setSelectedWorkers([]);
                    setSelectedTasks([]);
                    setShowDetail(false);
                    setDetailData({});
                    setRouteId(0);
                    setSelectedTab(value);
                  }}
                />
                <PickMessage
                  show={isPicking}
                  alignItems='center'
                  justify='center'
                  height='35px'
                  backgroundColor={THEME.colors.success}
                >
                  Selecciona un elemento de la lista
                </PickMessage>

                {selectedTab === 'orders' && (
                  <OrderBlock
                    orders={orders}
                    loading={loadingOrders}
                    selectedOrders={selectedOrders}
                    filterStatus={orderStatus}
                    filterType={orderTypes}
                    onSelect={(selected, isChecked) => {
                      let arr = [];
                      if (isChecked) {
                        arr = uniq(selectedOrders.concat(selected));
                      } else {
                        arr = selectedOrders.filter(
                          order => !selected.includes(order)
                        );
                      }
                      setSelectedOrders(arr);
                    }}
                    onDetail={orderData => {
                      setTaskDetail(orderData);
                      setOpenDetail(true);
                    }}
                    onEdit={orderId => {
                      setEditId(orderId);
                      setOrderEdit(true);
                    }}
                    showingOrders={showingOrders.map(order => order.id)}
                    toggleVisibility={(toggleOrders, show) => {
                      let ordersToShow;
                      if (show) {
                        const ordersToAdd = toggleOrders.map(order => ({
                          id: order.id,
                          coordinates: order.coordinates,
                          code: order.reference_code || order.code,
                          address: order.address,
                          status: order.status,
                          status_label: order.status_label,
                          contact_person: order.contact_person,
                        }));
                        ordersToShow = uniqBy(
                          showingOrders.concat(ordersToAdd),
                          'id'
                        );
                      } else {
                        ordersToShow = showingOrders.filter(
                          order =>
                            !find(toggleOrders, {
                              id: order.id,
                            })
                        );
                      }
                      setShowingOrders(ordersToShow);
                    }}
                    workerFilter={orderFilter}
                    resetWorkerFilter={() => setOrderFilter({})}
                    pageOrders={pageOrders}
                    setPageOrder={setPageOrder}
                    onRouteDetail={routeDetailId => {
                      setSelectedTab('routes');
                      setOriginalTasks(
                        routes.find(route => route.id === routeDetailId).tasks
                      );
                      setTasks(
                        routes.find(route => route.id === routeDetailId).tasks
                      );
                      setRouteId(routeDetailId);
                      setDetailData(
                        routes.find(route => route.id === routeDetailId)
                      );
                      setShowDetail(true);
                    }}
                    loadingAssignOrder={loadingAssignOrders}
                  />
                )}
                {selectedTab === 'routes' && (
                  <RouteBlock
                    routes={routes}
                    loading={loadingRoutes}
                    selectedRoutes={selectedRoutes}
                    selectedDetail={routeId}
                    filterStatus={routeStatus}
                    filterType={routeTypes}
                    onSelect={(selected, isChecked) => {
                      let arr = [];
                      if (allowMultiple) {
                        if (isChecked) {
                          arr = uniq(selectedRoutes.concat(selected));
                        } else {
                          arr = selectedRoutes.filter(
                            route => !selected.includes(route)
                          );
                        }
                        setSelectedRoutes(arr);
                      } else {
                        setSelectedRoutes(selected);
                      }
                    }}
                    onDetail={route => {
                      setOriginalTasks(route.tasks);
                      setTasks(route.tasks);
                      setRouteId(route.id);
                      setDetailData(route);
                      setShowDetail(true);
                    }}
                    showingRoutes={showingRoutes.map(route => route.id)}
                    toggleVisibility={(toggleRoutes, show) => {
                      let workersToShow = [];
                      let routesToShow;
                      const workersToAdd = toggleRoutes
                        .filter(route => route.worker)
                        .map(route => ({
                          id: route.worker.user_id,
                          name: `${route.worker.first_name} ${route.worker.last_name}`,
                          phone: route.worker.phone,
                          plate: route.vehicle
                            ? route.vehicle.plate
                            : 'No disponible',
                        }));
                      if (show) {
                        const routesToAdd = toggleRoutes.map(route => {
                          const path = polyUtil.decode(route.polyline);
                          const routeTasks = route.tasks.map(task => {
                            let icon = task.status;
                            if (
                              task.status !== 'completed' &&
                              task.status !== 'failed'
                            ) {
                              icon = 'task';
                            }
                            return {
                              id: task.id,
                              coordinates: task.coordinates,
                              address: task.address,
                              address_detail: task.address_detail,
                              contact_person: task.contact_person,
                              status: task.status,
                              status_label: task.status_label,
                              current_eta: task.current_eta,
                              original_eta: task.original_eta,
                              is_start: task.is_start,
                              code: task.order
                                ? task.order.reference_code || task.order.code
                                : null,
                              icon,
                            };
                          });
                          return {
                            id: route.id,
                            worker: route.worker ? route.worker.user_id : null,
                            tasks: routeTasks,
                            path,
                          };
                        });
                        routesToShow = uniqBy(
                          showingRoutes.concat(routesToAdd),
                          'id'
                        );
                        workersToShow = uniqBy(
                          showingWorkers.concat(workersToAdd),
                          'id'
                        );
                      } else {
                        routesToShow = showingRoutes.filter(
                          route =>
                            !find(toggleRoutes, {
                              id: route.id,
                            })
                        );
                        workersToShow = showingWorkers.filter(
                          worker =>
                            !find(workersToAdd, {
                              id: worker.id,
                            })
                        );
                      }

                      setShowingWorkers(workersToShow);
                      setShowingRoutes(routesToShow);
                    }}
                    workerFilter={routeFilter}
                    resetWorkerFilter={() => setRouteFilter({})}
                    multipleSelect={allowMultiple}
                  />
                )}
                {selectedTab === 'fleet' && (
                  <FleetBlock
                    workers={workers}
                    loading={loadingWorkers}
                    selectedWorkers={selectedWorkers}
                    onSelect={(selected, isChecked) => {
                      let arr = [];
                      if (allowMultiple) {
                        if (isChecked) {
                          arr = uniq(selectedWorkers.concat(selected));
                        } else {
                          arr = selectedWorkers.filter(
                            worker => !selected.includes(worker)
                          );
                        }
                        setSelectedWorkers(arr);
                      } else {
                        setSelectedWorkers(selected);
                      }
                    }}
                    showingWorkers={showingWorkers.map(worker => worker.id)}
                    toggleVisibility={(toggleWorkers, show) => {
                      let workersToShow;
                      if (show) {
                        const workersToAdd = toggleWorkers.map(worker => ({
                          id: worker.user_id,
                          name: `${worker.first_name} ${worker.last_name}`,
                          phone: worker.phone,
                          plate: worker.vehicle
                            ? worker.vehicle.plate
                            : 'No disponible',
                          online: worker.is_online,
                        }));

                        if (showOnlineWorkers) {
                          workersToShow = uniqBy(
                            showingWorkers.concat(workersToAdd),
                            'id'
                          );

                          workersToShow = workersToShow.filter(
                            worker => worker.online
                          );
                        } else {
                          workersToShow = uniqBy(
                            showingWorkers.concat(workersToAdd),
                            'id'
                          );
                        }
                      } else {
                        workersToShow = showingWorkers.filter(
                          worker =>
                            !find(toggleWorkers, {
                              user_id: worker.id,
                            })
                        );
                      }
                      setShowingWorkers(workersToShow);
                    }}
                    filterClick={(workerId, type) => {
                      if (type === 'routes') {
                        setRouteFilter(workerId);
                      } else if (type === 'orders') {
                        setOrderFilter(workerId);
                      }
                      setSelectedTab(type);
                    }}
                    vehicleFilter={vehicleFilter}
                    multipleSelect={allowMultiple}
                  />
                )}

                <AnimateBlock
                  show={showDetail}
                  width={size.width > 768 ? '450px' : '100%'}
                  height='100%'
                  overflow='auto'
                  direction='column'
                  borderColor={THEME.colors.borderColor}
                  backgroundColor='#FFFFFF'
                  borderRadius='5px'
                  position='absolute'
                >
                  <DetailBlock
                    data={detailData}
                    tasks={tasks}
                    onDrag={result => onDragEnd(result)}
                    onSelect={(selectedTask, isChecked) => {
                      let arr = [];
                      if (isChecked) {
                        arr = selectedTasks.concat(selectedTask);
                      } else {
                        arr = selectedTasks.filter(
                          order => order !== selectedTask
                        );
                      }
                      setSelectedTasks(arr);
                    }}
                    onClose={() => {
                      setShowDetail(false);
                      setDetailData({});
                      setRouteId(0);
                    }}
                    onOrderDetail={taskData => {
                      setTaskDetail(taskData);
                      setOpenDetail(true);
                    }}
                    onTaskClick={taskData => setOpenTooltip(taskData.id)}
                    loadingUpdateTask={loadingUpdateTask}
                    selectedTasksLength={selectedTasks.length}
                  />
                </AnimateBlock>
              </ResponsiveContainer>
            )}
            {size.width > 768 && (
              <FlexContainer
                flex='1'
                height='100%'
                direction='column'
                position='relative'
              >
                {!createView && <RouteResume orders={orders} />}
                {createView && createStep === 'preview' && (
                  <CreateResume
                    quantity={routeOrders.length}
                    summary={previewResponse.summary || {}}
                  />
                )}
                {selectedTab === 'fleet' && (
                  <SwitchContainer alignItems='center'>
                    <Typography margin='0 5px 0 0'>Ver conectados</Typography>
                    <Switch
                      checked={showOnlineWorkers}
                      onChange={() => setShowOnlineWorkers(!showOnlineWorkers)}
                    />
                  </SwitchContainer>
                )}
                <Map
                  ref={mapRef}
                  zoom={10}
                  bounds={bounds}
                  position={[-12.1166362, -77.0138613]}
                  boundsOptions={showDetail ? { paddingTopLeft: [450, 0] } : {}}
                >
                  {createView ? (
                    <>
                      {createStep !== 'preview' &&
                        routeOrders
                          .map(id => {
                            return find(ordersToRoute, {
                              order_id: id,
                            });
                          })
                          .filter(order => order.coordinate)
                          .map(order => (
                            <Marker
                              key={order.order_id}
                              position={[
                                order.coordinate.latitude,
                                order.coordinate.longitude,
                              ]}
                              icon='colorFlag'
                              onClick={() => handleScrollTable(order.order_id)}
                            />
                          ))}
                      {createStep === 'preview' &&
                        polylineCoors.map((coors, index) => (
                          <Polyline
                            key={coors}
                            color={routeColors[index % routeColors.length]}
                            positions={coors}
                          />
                        ))}
                      {createStep === 'preview' &&
                        previewRoutes.map(route =>
                          route.tasks.map((order, index) => (
                            <Marker
                              key={order.task_id}
                              position={[
                                order.coordinate.latitude,
                                order.coordinate.longitude,
                              ]}
                              icon='flag'
                            >
                              <MapTooltip>{index + 1}</MapTooltip>
                            </Marker>
                          ))
                        )}
                      {createStep === 'preview' && origin && (
                        <Marker
                          position={[
                            JSON.parse(origin).coordinate.latitude,
                            JSON.parse(origin).coordinate.longitude,
                          ]}
                          icon='origin'
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {showingRoutes.map(route => (
                        <React.Fragment key={route.id}>
                          {route.path && route.path.length > 0 && (
                            <Polyline color='#8F8FE6' positions={route.path} />
                          )}
                          {route.tasks.map(task => (
                            <Marker
                              key={task.id}
                              position={[
                                task.coordinates.latitude,
                                task.coordinates.longitude,
                              ]}
                              icon={task.icon}
                              openTooltip={task.id === openTooltip}
                            >
                              <Popup offset={[0, -30]}>
                                {!task.is_start && (
                                  <Typography
                                    fontSize={12}
                                    fontWeight={600}
                                    margin='0 0 3px'
                                  >
                                    {task.code}
                                  </Typography>
                                )}
                                <FlexContainer margin='0 0 3px'>
                                  <Typography
                                    fontSize={11}
                                    color='text.secondary'
                                    margin='0 5px 0 0'
                                  >
                                    Actual:
                                  </Typography>
                                  <Typography fontSize={11}>
                                    {` ${task.current_eta}`}
                                  </Typography>
                                  <Typography
                                    fontSize={11}
                                    color='text.secondary'
                                    margin='0 7px'
                                  >
                                    -
                                  </Typography>
                                  <Typography
                                    fontSize={11}
                                    color='text.secondary'
                                    margin='0 5px 0 0'
                                  >
                                    Original:
                                  </Typography>
                                  <Typography fontSize={11}>
                                    {` ${task.original_eta}`}
                                  </Typography>
                                </FlexContainer>
                                <Typography
                                  fontSize={11}
                                  color='text.secondary'
                                >
                                  {task.address}
                                </Typography>
                                <Typography
                                  fontSize={11}
                                  color='text.secondary'
                                  margin='0 0 5px'
                                >
                                  {task.address_detail}
                                </Typography>
                                <Status
                                  color={
                                    statusColors[task.status] ||
                                    statusColors.default
                                  }
                                  label={task.status_label}
                                />
                              </Popup>
                            </Marker>
                          ))}
                        </React.Fragment>
                      ))}
                      {showingOrders
                        .filter(order => order.coordinates)
                        .map(order => (
                          <Marker
                            key={order.id}
                            position={[
                              order.coordinates.latitude,
                              order.coordinates.longitude,
                            ]}
                            icon={
                              order.status !== 'completed' &&
                              order.status !== 'failed'
                                ? 'task'
                                : order.status
                            }
                          >
                            <Popup offset={[0, -30]}>
                              <FlexContainer
                                container
                                justify='space-between'
                                alignItems='center'
                                margin='0 0 3px'
                              >
                                <FlexContainer
                                  onClick={() => {
                                    setTaskDetail(
                                      orders.find(item => item.id === order.id)
                                    );
                                    setOpenDetail(true);
                                  }}
                                  cursor='pointer'
                                >
                                  <Typography fontSize={12} fontWeight={600}>
                                    {order.reference_code || order.code}
                                  </Typography>
                                </FlexContainer>

                                {order.status !== 'completed' && (
                                  <FlexContainer
                                    margin='0 5px 0 0'
                                    onClick={() => {
                                      setEditId(order.id);
                                      setOrderEdit(true);
                                    }}
                                  >
                                    <Icon icon='edit-icon' size={18} />
                                  </FlexContainer>
                                )}
                              </FlexContainer>

                              <Typography fontSize={11} color='text.secondary'>
                                {order.address}
                              </Typography>
                              <Typography
                                fontSize={11}
                                color='text.secondary'
                                margin='0 0 5px'
                              >
                                {order.address_detail}
                              </Typography>
                              <Status
                                color={
                                  statusColors[order.status] ||
                                  statusColors.default
                                }
                                label={order.status_label}
                              />
                            </Popup>
                          </Marker>
                        ))}
                      {!isEmpty(workerPositions) &&
                        workerPositions.results &&
                        workerPositions.results
                          .filter(position =>
                            find(showingWorkers, {
                              id: position.entity_id,
                            })
                          )
                          .map(positions => {
                            const worker = find(workers, {
                              user_id: positions.entity_id,
                            });
                            const vehicleCategory =
                              worker &&
                              worker.vehicle &&
                              worker.vehicle.vehicle_type
                                ? worker.vehicle.vehicle_type.category
                                : 'moto';
                            return (
                              <Marker
                                key={positions.entity_id}
                                position={[
                                  positions.latitude,
                                  positions.longitude,
                                ]}
                                icon={
                                  vehicleCategory === 'truck'
                                    ? 'truck'
                                    : 'worker'
                                }
                              >
                                <Popup offset={[0, -30]}>
                                  <Typography
                                    fontSize={12}
                                    fontWeight={600}
                                    margin='0 0 3px'
                                  >
                                    {worker.vehicle
                                      ? worker.vehicle.plate
                                      : 'No disponible'}
                                  </Typography>
                                  <Typography
                                    fontSize={11}
                                    color='text.secondary'
                                  >
                                    {`${worker.first_name} ${worker.last_name}`}
                                  </Typography>
                                  <Typography
                                    fontSize={11}
                                    color='text.secondary'
                                    margin='0 0 5px'
                                  >
                                    {worker.phone}
                                  </Typography>
                                </Popup>
                              </Marker>
                            );
                          })}
                    </>
                  )}
                </Map>
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      {size.width <= 768 && (
        <MobileMap position='absolute' show={showMobileMap}>
          <CloseIcon onClick={() => setShowMobileMap(false)}>
            <Icon
              icon='cancel-icon'
              color={THEME.colors.placeholderColor}
              size={25}
            />
          </CloseIcon>
          <Map
            ref={mobileMapRef}
            zoom={10}
            bounds={bounds}
            position={[-12.1166362, -77.0138613]}
            boundsOptions={showDetail ? { paddingTopLeft: [450, 0] } : {}}
          >
            {showingRoutes.map(route => (
              <React.Fragment key={route.id}>
                {route.path && route.path.length > 0 && (
                  <Polyline color='#8F8FE6' positions={route.path} />
                )}
                {route.tasks.map(task => (
                  <Marker
                    key={task.id}
                    position={[
                      task.coordinates.latitude,
                      task.coordinates.longitude,
                    ]}
                    icon={task.icon}
                  >
                    <Popup offset={[0, -30]}>
                      <Typography
                        fontSize={12}
                        fontWeight={600}
                        margin='0 0 3px'
                      >
                        {task.code}
                      </Typography>
                      <Typography fontSize={11} color='text.secondary'>
                        {task.address}
                      </Typography>
                      <Typography
                        fontSize={11}
                        color='text.secondary'
                        margin='0 0 5px'
                      >
                        {task.address_detail}
                      </Typography>
                      <Status
                        color={
                          statusColors[task.status] || statusColors.default
                        }
                        label={task.status_label}
                      />
                    </Popup>
                  </Marker>
                ))}
              </React.Fragment>
            ))}
            {showingOrders
              .filter(order => order.coordinates)
              .map(order => (
                <Marker
                  key={order.id}
                  position={[
                    order.coordinates.latitude,
                    order.coordinates.longitude,
                  ]}
                  icon={
                    order.status !== 'completed' && order.status !== 'failed'
                      ? 'task'
                      : order.status
                  }
                >
                  <Popup offset={[0, -30]}>
                    <Typography fontSize={12} fontWeight={600} margin='0 0 3px'>
                      {order.code}
                    </Typography>
                    <Typography fontSize={11} color='text.secondary'>
                      {order.address}
                    </Typography>
                    <Typography
                      fontSize={11}
                      color='text.secondary'
                      margin='0 0 5px'
                    >
                      {order.address_detail}
                    </Typography>
                    <Status
                      color={statusColors[order.status] || statusColors.default}
                      label={order.status_label}
                    />
                  </Popup>
                </Marker>
              ))}
            {!isEmpty(workerPositions) &&
              workerPositions.results &&
              workerPositions.results
                .filter(position =>
                  find(showingWorkers, {
                    id: position.entity_id,
                  })
                )
                .map(positions => {
                  const worker = find(workers, {
                    user_id: positions.entity_id,
                  });
                  const vehicleCategory =
                    worker && worker.vehicle && worker.vehicle.vehicle_type
                      ? worker.vehicle.vehicle_type.category
                      : 'moto';
                  return (
                    <Marker
                      key={positions.entity_id}
                      position={[positions.latitude, positions.longitude]}
                      icon={vehicleCategory === 'truck' ? 'truck' : 'worker'}
                    >
                      <Popup offset={[0, -30]}>
                        <Typography
                          fontSize={12}
                          fontWeight={600}
                          margin='0 0 3px'
                        >
                          {worker.vehicle
                            ? worker.vehicle.plate
                            : 'No disponible'}
                        </Typography>
                        <Typography fontSize={11} color='text.secondary'>
                          {`${worker.first_name} ${worker.last_name}`}
                        </Typography>
                        <Typography
                          fontSize={11}
                          color='text.secondary'
                          margin='0 0 5px'
                        >
                          {worker.phone}
                        </Typography>
                      </Popup>
                    </Marker>
                  );
                })}
          </Map>
        </MobileMap>
      )}

      {createView ? (
        <AnimateMenu
          show={createView}
          width='100%'
          height='50px'
          direction='column'
          backgroundColor='#FFFFFF'
          borderRadius='5px'
          position='absolute'
        >
          <CreateBottomMenu
            selected={routeOrders.length}
            step={createStep}
            toStep={step => handleCreateStep(step)}
            hasChanges={hasChanges}
          />
        </AnimateMenu>
      ) : (
        <AnimateMenu
          show={showOptionMenu}
          width='100%'
          height='50px'
          padding={size.width > 768 ? '10px 40px' : '10px'}
          direction='column'
          backgroundColor='#FFFFFF'
          borderRadius='5px'
          position='absolute'
        >
          <BottomMenu
            orderLength={selectedOrders.length}
            routeLength={selectedRoutes.length}
            taskLength={selectedTasks.length}
            workerLength={selectedWorkers.length}
            onClick={event => handleMenuOptions(event)}
            availableOptions={availableOptions}
            deselectAll={() => handleDeselect()}
            isPicking={isPicking}
          />
        </AnimateMenu>
      )}

      <Drawer
        anchor='right'
        open={openDetail}
        onClose={() => {
          setOpenDetail(false);
        }}
      >
        <DetailOrder
          data={taskDetail}
          onClose={() => {
            setOpenDetail(false);
          }}
        />
      </Drawer>

      <Drawer
        anchor='right'
        open={openOrderEdit}
        onClose={() => {
          setEditId(null);
          setOrderEdit(false);
        }}
      >
        <FlexContainer
          width='1300px'
          position='relative'
          padding='20px 50px 50px'
          direction='column'
          height='100%'
        >
          <CloseIcon
            onClick={() => {
              setEditId(null);
              setOrderEdit(false);
            }}
          >
            <Icon
              icon='cancel-icon'
              color={THEME.colors.placeholderColor}
              size={25}
            />
          </CloseIcon>
          <Edit
            editId={editId}
            onEdit={() => {
              requestOrders();
            }}
          />
        </FlexContainer>
      </Drawer>

      <Dialog open={statusModal} onClose={() => setStatusModal(false)}>
        <FlexContainer
          padding='40px'
          direction='column'
          alignItems='center'
          justify='space-around'
          minWidth={size.width > 768 ? '400px' : ''}
          minHeight='400px'
        >
          <Typography fontSize={17} margin='0 0 40px'>
            Cambiar estado de ruta
          </Typography>
          <Typography margin='0 0 10px'>Seleccione un estado</Typography>
          <FormControl
            control='select'
            placeholder='Seleccione estado'
            onChange={value => setSelectedStatus(value)}
            value={selectedStatus}
            options={statusOptions}
            margin='0 0 60px'
            error={statusError}
            width='100%'
          />
          <FlexContainer container alignItems='center' justify='space-between'>
            <Button
              variant='contained'
              color='primary'
              fontSize='13px'
              onClick={() => {
                if (!selectedStatus) {
                  setStatusError('Selecciona una opción');
                } else {
                  updateStatus({
                    route_ids: selectedRoutes,
                    status_id: selectedStatus,
                    organization_branch_office_id: branchOfficeId,
                  });
                }
              }}
            >
              Aceptar
            </Button>

            <Button
              fontSize='14px'
              onClick={() => {
                setStatusError('');
                setSelectedStatus(null);
                setStatusModal(false);
              }}
            >
              Cancelar
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Dialog>
    </FlexContainer>
  );
};

export default Dashboard;
