import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FlexContainer, Typography, Button } from 'components/atoms';
import { Cell } from 'components/molecules';
import { DataTable, CollapsibleTable } from 'components/organisms';
import THEME from 'util/styledTheme';
import { ConfirmProps } from './types';

const Container = styled(FlexContainer)`
  overflow: auto;
`;

const Confirm: React.FC<ConfirmProps> = ({ data, onNewMassive }) => {
  const [expandedData, setExpandedData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);

  return (
    <Container container direction='column'>
      <Typography fontSize={15} margin='0 0 20px'>
        Parece que hay problemas con algunos datos:
      </Typography>
      <CollapsibleTable errors={data} />
      <FlexContainer container justify='flex-end' margin='20px 0 0'>
        <Button
          variant='contained'
          color='primary'
          fontSize='13px'
          padding='0 40px'
          onClick={() => onNewMassive()}
        >
          Volver a cargar
        </Button>
      </FlexContainer>
    </Container>
  );
};

export default Confirm;
