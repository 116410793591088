import React, { useState } from 'react';
import { omit } from 'lodash';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Checkbox,
  Tooltip,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Pagination, Spinner } from 'components/molecules';
import { FlexContainer, Icon } from 'components/atoms';
import THEME from 'util/styledTheme';
import { TableProps } from './types';

export const AimoContainer = styled(props => (
  <TableContainer {...omit(props, ['borderless'])} />
))<{
  margin?: string;
  borderless?: boolean;
}>`
  margin-bottom: ${props => props.margin || '20px'};
  overflow-x: inherit;
  &:hover::-webkit-scrollbar-thumb {
    background-color: #bebed3;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 5px;
    width: 100%;
    background-color: #fff;
    thead {
      tr {
        background-color: #ffffff;
        th {
          border-top: 2px solid ${props => props.theme.colors.borderColor};
          ${props => props.borderless && 'border: none'};
          color: #808494;
          font-size: 11px;
          opacity: 0.8;
          font-weight: bold;
          font-stretch: normal;
          text-transform: uppercase;
          border-bottom: none;
          line-height: 16px;
          padding: 8px 10px;
          &:first-child {
            border-top-left-radius: 5px;
            border-left: 2px solid ${props => props.theme.colors.borderColor};
          }
          &:last-child {
            border-top-right-radius: 5px;
            border-right: 2px solid ${props => props.theme.colors.borderColor};
          }
        }
      }
    }
    tbody {
      tr {
        td {
          color: ${props => props.theme.colors.textSecondary};
          padding: 8px 10px;
          font-size: 13px;
          border-bottom: 1px solid ${props => props.theme.colors.borderColor};
          ${props => props.borderless && 'border: none'};
          &:first-child {
            border-left: 1px solid ${props => props.theme.colors.borderColor};
            ${props => props.borderless && 'border: none'};
          }
          &:last-child {
            border-right: 1px solid ${props => props.theme.colors.borderColor};
            ${props => props.borderless && 'border: none'};
          }
        }
      }
      tr:first-child {
        td {
          border-top: 2px solid ${props => props.theme.colors.borderColor};
          ${props => props.borderless && 'border: none'};
        }
      }
      tr:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 5px;
          }
          &:last-child {
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
  }
`;

export const FocusRow = styled(props => (
  <TableRow {...omit(props, ['focus'])} />
))<{ focus?: boolean }>`
  background-color: ${props => (props.focus ? '#f4f4ff' : 'inherit')};
`;

export const MarginCell = styled(TableCell)`
  padding: 10px;
  background-color: ${props => props.theme.colors.backgroundColor};
  border-left: 1px solid ${props => props.theme.colors.backgroundColor} !important;
  border-right: 1px solid ${props => props.theme.colors.backgroundColor} !important;
`;

export const ExpandCell = styled(TableCell)`
  padding: 0 !important;
  cursor: pointer;
  &:hover {
    background-color: inherit !important;
  }
`;

const DownloadButton = styled.button`
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0;
  color: #404071;
  cursor: pointer;
`;

const CustomDownloadTooltip = withStyles({
  tooltip: {
    backgroundColor: THEME.colors.purpleBackground,
    color: '#FFF',
    fontSize: 12,
    marginBottom: 7,
    padding: 8,
  },
  arrow: {
    color: THEME.colors.purpleBackground,
  },
})(Tooltip);

const AimoTable: React.FC<TableProps> = ({
  data,
  search,
  dataIdentifier,
  expandedData,
  headers,
  expandedHeaders,
  selectable,
  selectObject,
  expandable,
  stickyHeader,
  onExpand,
  loading,
  expandLoading,
  selectedRows,
  onSelectRow,
  onChangePage,
  onDownloadAll,
  totalPages,
  totalItems,
  pageSize,
  page,
  showPagination = true,
  marginBottom,
  focused,
  showHeader = true,
  borderless,
  downloadToolTipText,
  dataType,
  assignStatus,
}) => {
  const [expanded, setExpanded] = useState<string>('');

  const filterData = () => {
    const toSearch = search.split(',');
    if (toSearch.length > 0) {
      return data.filter(obj => {
        let fil = false;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < toSearch.length; i++) {
          if (
            toSearch[i].toLowerCase().trim() &&
            JSON.stringify(obj)
              .toLowerCase()
              .indexOf(toSearch[i].toLowerCase().trim()) !== -1
          ) {
            fil = true;
            break;
          }
        }
        return fil;
      });
    }
    return data.filter(
      obj =>
        JSON.stringify(obj)
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
    );
  };

  return (
    <>
      <AimoContainer margin={marginBottom} borderless={borderless}>
        <Table stickyHeader={stickyHeader}>
          {showHeader && (
            <TableHead>
              <TableRow>
                {selectable && (
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={
                        search
                          ? data.length > 0 &&
                            data
                              .filter(
                                obj =>
                                  JSON.stringify(obj)
                                    .toLowerCase()
                                    .indexOf(search.toLowerCase()) !== -1
                              )
                              .map(obj =>
                                selectObject
                                  ? JSON.stringify(obj)
                                  : obj[dataIdentifier || 'id']
                              )
                              .every(item => selectedRows.includes(item))
                          : data.length > 0 &&
                            data.length === selectedRows.length
                      }
                      color='primary'
                      onChange={({ target }) => {
                        if (target.checked) {
                          onSelectRow(
                            search
                              ? selectedRows.concat(
                                  data
                                    .filter(
                                      obj =>
                                        JSON.stringify(obj)
                                          .toLowerCase()
                                          .indexOf(search.toLowerCase()) !== -1
                                    )
                                    .map(obj =>
                                      selectObject
                                        ? JSON.stringify(obj)
                                        : obj[dataIdentifier || 'id']
                                    )
                                )
                              : data.map(obj =>
                                  selectObject
                                    ? JSON.stringify(obj)
                                    : obj[dataIdentifier || 'id']
                                )
                          );
                        } else {
                          onSelectRow(
                            search
                              ? selectedRows.filter(
                                  selected =>
                                    !data
                                      .filter(
                                        obj =>
                                          JSON.stringify(obj)
                                            .toLowerCase()
                                            .indexOf(search.toLowerCase()) !==
                                          -1
                                      )
                                      .map(obj => obj[dataIdentifier || 'id'])
                                      .includes(selected)
                                )
                              : []
                          );
                        }
                      }}
                    />
                  </TableCell>
                )}
                {headers.map(header => (
                  <TableCell key={header.id}>
                    {header.id === 'download_all' ? (
                      <CustomDownloadTooltip
                        arrow
                        placement='bottom'
                        title={downloadToolTipText}
                      >
                        <div style={{ display: 'inline-block' }}>
                          <DownloadButton
                            onClick={() => {
                              onDownloadAll();
                            }}
                          >
                            <Icon icon='download-icon' size={15} />
                          </DownloadButton>
                        </div>
                      </CustomDownloadTooltip>
                    ) : (
                      header.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  colSpan={selectable ? headers.length + 1 : headers.length}
                >
                  <Spinner height='350px' />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {(search ? filterData() : data).map((item, dataIndex) => (
                  <React.Fragment
                    key={dataIdentifier ? item[dataIdentifier] : item.id}
                  >
                    {expandable && (
                      <TableRow>
                        <MarginCell
                          colSpan={
                            selectable ? headers.length + 1 : headers.length
                          }
                        />
                      </TableRow>
                    )}
                    <FocusRow
                      id={item.id || item[dataIdentifier]}
                      focus={
                        focused &&
                        (focused === item.id ||
                          focused === item[dataIdentifier])
                      }
                    >
                      {selectable && (
                        <TableCell padding='checkbox'>
                          {dataType === 'orders' && (
                            <Checkbox
                              color='primary'
                              checked={selectedRows.includes(
                                selectObject
                                  ? JSON.stringify(item)
                                  : `${
                                      assignStatus &&
                                      assignStatus === 2 &&
                                      item.purchase_order
                                        ? item.purchase_order.id
                                        : item[dataIdentifier || 'id']
                                    }`
                              )}
                              onChange={({ target }) => {
                                if (target.checked) {
                                  onSelectRow(
                                    selectedRows.concat(
                                      selectObject
                                        ? JSON.stringify(item)
                                        : `${
                                            assignStatus &&
                                            assignStatus === 2 &&
                                            item.purchase_order
                                              ? item.purchase_order.id
                                              : item[dataIdentifier || 'id']
                                          }`
                                    )
                                  );
                                } else {
                                  onSelectRow(
                                    selectedRows.filter(row =>
                                      selectObject
                                        ? row !== JSON.stringify(item)
                                        : row !==
                                          `${
                                            assignStatus && assignStatus === 2
                                              ? item.purchase_order.id
                                              : item[dataIdentifier || 'id']
                                          }`
                                    )
                                  );
                                }
                              }}
                            />
                          )}

                          {dataType !== 'orders' && (
                            <Checkbox
                              color='primary'
                              checked={selectedRows.includes(
                                selectObject
                                  ? JSON.stringify(item)
                                  : item[dataIdentifier || 'id']
                              )}
                              onChange={({ target }) => {
                                if (target.checked) {
                                  onSelectRow(
                                    selectedRows.concat(
                                      selectObject
                                        ? JSON.stringify(item)
                                        : item[dataIdentifier || 'id']
                                    )
                                  );
                                } else {
                                  onSelectRow(
                                    selectedRows.filter(row =>
                                      selectObject
                                        ? row !== JSON.stringify(item)
                                        : row !== item[dataIdentifier || 'id']
                                    )
                                  );
                                }
                              }}
                            />
                          )}
                        </TableCell>
                      )}
                      {headers.map(head => (
                        <TableCell key={head.id}>{head.cell(item)}</TableCell>
                      ))}
                    </FocusRow>
                    {(item.id
                      ? expanded === item.id
                      : expanded === dataIndex) && (
                      <>
                        {expandLoading ? (
                          <TableRow>
                            <TableCell
                              colSpan={
                                selectable ? headers.length + 1 : headers.length
                              }
                            >
                              <Spinner height='200px' />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {expandedData.map(child => (
                              <TableRow key={child.id}>
                                {expandedHeaders.map(expHeader => (
                                  <TableCell
                                    key={expHeader.id}
                                    colSpan={expHeader.colSpan || 1}
                                    style={{ backgroundColor: '#F4F4F9' }}
                                  >
                                    {expHeader.cell(child)}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </>
                        )}
                      </>
                    )}
                    {expandable && !item.unexpandable && (
                      <TableRow>
                        <ExpandCell
                          colSpan={
                            selectable ? headers.length + 1 : headers.length
                          }
                        >
                          <FlexContainer
                            container
                            justify='center'
                            backgroundColor={THEME.colors.borderColor}
                            padding='2px'
                            onClick={() => {
                              if (
                                item.id
                                  ? expanded === item.id
                                  : expanded === dataIndex
                              ) {
                                setExpanded('');
                              } else {
                                setExpanded(item.id || dataIndex);
                                onExpand(item.id || dataIndex);
                              }
                            }}
                          >
                            {(item.id ? (
                              expanded === item.id
                            ) : (
                              expanded === dataIndex
                            )) ? (
                              <div style={{ transform: 'rotate(180deg)' }}>
                                <Icon icon='arrow-icon' size={15} />
                              </div>
                            ) : (
                              <Icon icon='arrow-icon' size={15} />
                            )}
                          </FlexContainer>
                        </ExpandCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </AimoContainer>

      {showPagination && data.length > 0 && (
        <FlexContainer container margin='20px 0'>
          <Pagination
            onPageChange={onChangePage}
            totalPages={totalPages}
            totalItems={totalItems}
            pageSize={pageSize}
            currentPage={page}
          />
        </FlexContainer>
      )}
    </>
  );
};

export default AimoTable;
