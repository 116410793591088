import React, { useEffect, useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import styled from 'styled-components';
import { Drawer, Dialog } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { Cell, Spinner } from 'components/molecules';
import {
  DataTable,
  FiltersContainer,
  SearchWithFilter,
  SearchWithResults,
} from 'components/organisms';
import THEME from 'util/styledTheme';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import { getWorkers, getProvidersMinimal, deleteWorker } from 'services';
import { providerFormatter } from 'util/functions';
import { ReactComponent as MoreOptions } from 'assets/images/filter_icon.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import GoogleIcon from 'assets/images/google-play.svg';
import NoData from 'assets/images/no_data.svg';
import EditWorker from '../Edit';

const GooglePlayIcon = styled.img`
  width: 15px;
  margin-right: 5px;
`;

const MoreOptionIcon = styled(MoreOptions)`
  .cls-2 {
    stroke: #8c8cb1;
  }

  ${({ active }) =>
    active &&
    `
    .cls-2{
      stroke: #404071;
    }
  `}
`;

const StatusCircle = styled.div<{ active }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${props =>
    props.active
      ? props.theme.colors.success
      : props.theme.colors.placeholderColor};
  box-shadow: rgb(0 0 0 / 8%) 2px 2px 3px 0px;
  margin: 5px;
`;

const initialFilters = [
  {
    label: 'Estado del worker',
    placeholder: 'Seleccione estado',
    name: 'is_online',
    options: [
      {
        id: 'online',
        name: 'Online',
      },
      {
        id: 'offline',
        name: 'Offline',
      },
    ],
  },
];

const options = [
  {
    name: 'Nombres',
    id: 'full_name',
  },
  {
    name: 'Celular',
    id: 'worker_phone',
  },
  {
    name: 'Doc. de Identidad',
    id: 'worker_document_document',
  },
  {
    name: 'Placa',
    id: 'vehicle_plate',
  },
  {
    name: 'Email',
    id: 'worker_email',
  },
];

const WorkerList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const branchOfficeId = useTypedSelector(
    state => state.organization.selectedBranchOffice.id
  );
  const user = useTypedSelector(state => state.user);
  // API calls
  const [workers, fetchWorkers, loadingWorkers, pageData] = useRequest(
    getWorkers,
    []
  );
  const [providers, fetchProviders] = useRequest(getProvidersMinimal, null);
  const [, workerDelete, , , , statusRequest] = useRequest(deleteWorker, null);

  // Values
  const filters = initialFilters;
  // const [filters, setFilters] = useState(initialFilters);
  const [filterValues, setFilterValues] = useState({
    is_online: null,
    search_provider: '',
    delivery_programmed_date: null,
    created_at: null,
  });
  const [searchProvider, setSearchProvider] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [page, setPage] = useState<number>(1);

  const [providerOptions, setProviderOptions] = useState([]);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [workerList, setWorkerList] = useState([]);
  const [workerDeleted, setWorkerDeleted] = useState(null);

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [firstLoadingWorkers, setFirstLoadingWorkers] = useState<boolean>(
    false
  );
  const [firstWorkersRender, setFirstWorkersRender] = useState<boolean>(false);
  const [searchWithFilter, setSearchWithFilter] = useState<boolean>(false);

  const requestWorkers = (currentPage: number) => {
    setSelectedRows([]);
    const params = {
      is_online: filterValues.is_online
        ? filterValues.is_online === 'online'
        : '',
      search_provider: searchProvider,
      delivery_programmed_date: filterValues.delivery_programmed_date,
      created_at: filterValues.created_at,
      branchoffices__id: branchOfficeId,
      page: currentPage,
    };

    if (searchFilter !== '') {
      params[searchFilter] = searchInput;
    }

    setPage(currentPage);
    fetchWorkers(params);
    setFirstWorkersRender(false);
  };

  const handleSearchProvider = (search: string) => {
    fetchProviders({
      search,
    });
  };

  useEffect(() => {
    requestWorkers(page);
    setFirstWorkersRender(true);
  }, [branchOfficeId]);

  useEffect(() => {
    setWorkerList(workers);
  }, [workers]);

  useEffect(() => {
    if (providers) {
      if (providers.length === 0) {
        enqueueSnackbar('No se encontraron coincidencias para su búsqueda.', {
          variant: 'warning',
        });
      } else {
        setProviderOptions(
          providerFormatter(providers, {
            id: option => option.tradename,
            name: option => option.business_name,
            phone: option => option.phone,
          })
        );
      }
    }
  }, [providers, enqueueSnackbar]);

  useEffect(() => {
    if (statusRequest === 204) {
      enqueueSnackbar('Se eliminó el worker correctamente.', {
        variant: 'success',
      });
      const filteredList = workerList.filter(
        worker => worker.id !== workerDeleted
      );
      setWorkerList(filteredList);
    } else if (statusRequest && statusRequest !== 204) {
      enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
        variant: 'error',
      });
    }
  }, [statusRequest, enqueueSnackbar]);

  // const settingsOptions = [
  //   {
  //     label: 'Editar',
  //     name: 'edit',
  //     icon: 'edit-icon',
  //     onClick: row => {
  //       setEditData(row);
  //       setOpenEdit(true);
  //     },
  //   },
  // ];

  // const option = row =>
  //   settingsOptions.map(setting => (
  //     <DropdownIconOption
  //       key={setting.name}
  //       label={setting.label}
  //       name={setting.name}
  //       icon={setting.icon}
  //       onClick={() => setting.onClick(row)}
  //     />
  //   ));

  useEffect(() => {
    if (searchWithFilter) {
      setFirstWorkersRender(false);
    } else if (firstWorkersRender && loadingWorkers && page === 1) {
      setFirstLoadingWorkers(true);
    } else {
      setFirstLoadingWorkers(false);
    }
  }, [firstWorkersRender, loadingWorkers]);

  const headers = useMemo(
    () => [
      {
        label: 'Nombre',
        id: 'names',
        cell: row => (
          <Cell.UserAvatar
            avatar={row.worker.photo}
            active={row.is_online}
            primary={`${row.worker.first_name} ${row.worker.last_name}`}
            secondary={row.worker.email}
            onClick={() => {
              if (user.permissions.includes('core.worker.update')) {
                setEditData(row);
                setOpenEdit(true);
              }
            }}
          />
        ),
      },
      {
        label: 'Fecha',
        id: 'delivery_programmed_date',
        cell: row => moment(row.created_at).format('DD/MM/YYYY'),
      },
      {
        label: 'Documento',
        id: 'document',
        cell: row => row.worker.national_identity_document,
      },
      {
        label: 'Teléfono',
        id: 'phone',
        cell: row => row.worker.phone,
      },
      {
        label: 'Vehículo actual',
        id: 'actualVehicle',
        cell: row => (row.selected_vehicle ? row.selected_vehicle.name : '-'),
      },
      {
        label: 'Proveedor',
        id: 'provider',
        cell: row => (row.provider ? row.provider.tradename : '-'),
      },
      {
        label: 'Activo',
        id: 'is_active',
        cell: row => <StatusCircle active={row.is_active} />,
      },
      {
        label: '',
        id: 'delete_worker',
        cell: row =>
          user.permissions.includes('core.worker.destroy') && (
            <Icon
              icon='delete-icon'
              size={20}
              onClick={() => {
                setOpenConfirm(true);
                setWorkerDeleted(row.id);
              }}
            />
          ),
      },
      // {
      //   label: '',
      //   id: 'settings',
      //   cell: row => (
      //     <Dropdown
      //       padding='0 10px'
      //       label={<Icon icon='options-icon' size={20} />}
      //       direction='bottom'
      //       position='right'
      //       showCaret={false}
      //       options={option(row)}
      //       color='default'
      //     />
      //   ),
      // },
    ],
    [selectedRows]
  );

  return (
    <>
      {firstLoadingWorkers ? (
        <FlexContainer
          container
          padding='30px 40px'
          direction='column'
          justify='center'
          alignItems='center'
          height='80vh'
        >
          <Spinner height='350px' />
        </FlexContainer>
      ) : (
        <FlexContainer container padding='30px 40px' direction='column'>
          {firstWorkersRender && !searchWithFilter && workers.length === 0 ? (
            <FlexContainer
              container
              direction='column'
              justify='center'
              alignItems='center'
              height='70vh'
            >
              <img src={NoData} alt='no_data' />
              <Typography color='text.secondary'>
                No hay conductores registrados
              </Typography>
              <FlexContainer>
                {user.permissions.includes('core.worker.create') && (
                  <Button
                    variant='contained'
                    color='primary'
                    margin='20px 0 0 20px'
                    padding='0 40px'
                    fontSize='13px'
                    onClick={() => setOpenEdit(true)}
                  >
                    Crear conductor
                  </Button>
                )}
              </FlexContainer>
            </FlexContainer>
          ) : (
            <>
              <FlexContainer
                container
                alignItems='center'
                justify='space-between'
                margin='0 0 30px'
              >
                <Typography fontSize={18} fontWeight={300}>
                  Conductores
                </Typography>
              </FlexContainer>
              <FlexContainer
                container
                direction='column'
                margin='0 0 20px'
                padding='20px'
                backgroundColor='#FFFFFF'
                borderRadius='4px'
                borderColor={THEME.colors.borderColor}
              >
                <FlexContainer container justify='space-between'>
                  <FlexContainer container>
                    <SearchWithFilter
                      filterLabel='Buscar por'
                      filterValue={searchFilter}
                      onChangeFilterValue={value => setSearchFilter(value)}
                      options={options}
                      onChangeSearchValue={value => setSearchInput(value)}
                      searchValue={searchInput}
                      onSubmit={() => requestWorkers(1)}
                      inputWidth='250px'
                      maxWidth='550px'
                      placeholder='Buscar'
                    />
                    <Button
                      variant='contained'
                      color='primary'
                      margin='0 10px 0 5px'
                      onClick={() => {
                        requestWorkers(1);
                        setSearchWithFilter(true);
                      }}
                    >
                      Buscar
                    </Button>
                  </FlexContainer>

                  <FlexContainer container>
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={() => setShowOptions(!showOptions)}
                    >
                      <MoreOptionIcon
                        active={
                          Object.values(filterValues).some(
                            value => value !== null && value !== ''
                          )
                            ? 'active'
                            : ''
                        }
                      />
                    </Button>
                  </FlexContainer>

                  <FlexContainer alignItems='center'>
                    {user.permissions.includes('core.worker.create') && (
                      <Button
                        color='primary'
                        margin='0 0 0 20px'
                        fontSize='13px'
                        startIcon={<Icon icon='plus-icon' size={18} />}
                        onClick={() => setOpenEdit(true)}
                      >
                        Crear Worker
                      </Button>
                    )}

                    <Button
                      color='primary'
                      margin='0 0 0 20px'
                      fontSize='13px'
                      onClick={() =>
                        window.open(
                          'https://play.google.com/store/apps/details?id=co.aimo.mobile.worker&hl=en&gl=US'
                        )
                      }
                    >
                      <GooglePlayIcon src={GoogleIcon} />
                      Descargar App de Driver
                    </Button>
                    <CopyToClipboard text='https://play.google.com/store/apps/details?id=co.aimo.mobile.worker&hl=en&gl=US'>
                      <Button margin='0' padding='0 10px' height='25px'>
                        <CopyIcon />
                      </Button>
                    </CopyToClipboard>
                  </FlexContainer>
                </FlexContainer>

                {showOptions && (
                  <FlexContainer container>
                    <FiltersContainer
                      labelWeight={600}
                      filters={filters}
                      value={filterValues}
                      onChange={(value, name) =>
                        setFilterValues({
                          ...filterValues,
                          [name]: value,
                        })
                      }
                    />
                    <FlexContainer direction='column' width='400px'>
                      <SearchWithResults
                        onSearch={search => {
                          setProviderOptions([]);
                          handleSearchProvider(search);
                        }}
                        onSelect={value => setSearchProvider(value)}
                        searchOptions={providerOptions}
                        margin='20px 0 0 0'
                        placeholder='Buscar proveedores'
                      />
                    </FlexContainer>
                  </FlexContainer>
                )}
              </FlexContainer>

              <DataTable
                headers={headers}
                data={workerList}
                selectedRows={selectedRows}
                onSelectRow={rows => setSelectedRows(rows)}
                onChangePage={selectedPage => requestWorkers(selectedPage)}
                totalPages={pageData.totalPages}
                totalItems={pageData.totalItems}
                pageSize={pageData.pageSize}
                page={page}
                loading={loadingWorkers}
                selectable
              />

              <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
                <FlexContainer
                  padding='30px'
                  direction='column'
                  alignItems='center'
                  justify='center'
                >
                  <Typography
                    color={THEME.colors.primary}
                    fontSize={15}
                    margin='0 0 20px'
                  >
                    Eliminar Worker
                  </Typography>
                  <Typography margin='0 0 30px' fontSize={13}>
                    ¿Estás seguro de eliminar el worker?
                  </Typography>
                  <FlexContainer
                    container
                    alignItems='center'
                    justify='space-between'
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      fontSize='13px'
                      onClick={() => {
                        workerDelete(workerDeleted);
                        setOpenConfirm(false);
                      }}
                    >
                      Eliminar
                    </Button>

                    <Button
                      fontSize='14px'
                      onClick={() => setOpenConfirm(false)}
                    >
                      Cancelar
                    </Button>
                  </FlexContainer>
                </FlexContainer>
              </Dialog>
            </>
          )}
          <Drawer
            anchor='right'
            open={openEdit}
            onClose={() => {
              setEditData(null);
              setOpenEdit(false);
            }}
          >
            <EditWorker
              data={editData}
              onCreate={() => requestWorkers(page)}
              onClose={() => {
                setEditData(null);
                setOpenEdit(false);
              }}
              onEdit={() => requestWorkers(page)}
            />
          </Drawer>
        </FlexContainer>
      )}
    </>
  );
};

export default WorkerList;
