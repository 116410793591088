import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTransition, useSpring, animated } from 'react-spring';
import { withStyles } from '@material-ui/core/styles';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import {
  Logo,
  FlexContainer,
  Icon,
  Typography,
  Button,
} from 'components/atoms';
import { Spinner, Dropdown, DropdownIconOption } from 'components/molecules';
import { useTypedSelector } from 'store';
import menu from 'util/menu';
import THEME from 'util/styledTheme';
import { clearOrganization } from 'store/organization/actions';
import { logout } from 'store/session/actions';
import { clearUser } from 'store/user/actions';
import useWindowSize from 'hooks/useWindowSize';
import AimoLogoText from 'assets/images/AimoTextWhite.svg';
import AimoLogoIcon from 'assets/images/AimoLogoWhite.svg';
import DefaultProfile from 'assets/images/DefaultProfile.png';
import { Tooltip } from '@material-ui/core';
import { getBalance } from 'services';
import useRequest from 'hooks/useRequest';
import {
  SidebarProps,
  MenuOptionProps,
  OptionProps,
  SubMenuOptionProps,
} from './types';

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: THEME.colors.error,
    color: '#FFF',
    fontSize: 10,
    margin: 0,
    padding: '3px 6px',
  },
})(Tooltip);

const SidebarContainer = styled.div<{ showMenu: boolean }>`
  width: ${props => (props.showMenu ? '180px' : '0')};
  position: fixed;
  z-index: 1010;
  transition: all 0.3s;
  @media only screen and (min-width: 768px) {
    width: ${props => (props.showMenu ? '180px' : '65px')};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  padding: 5px 0;
  position: relative;
  box-sizing: border-box;
  z-index: 0;
  background-color: ${props => props.theme.colors.primary};
  overflow-y: auto;
  overflow-x: hidden;
  &:hover::-webkit-scrollbar-thumb {
    background-color: #bebed3;
  }
`;

const ToggleButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50px;
  height: 28px;
  width: 28px;
  right: -14px;
  top: 6%;
  cursor: pointer;
`;

const Section = styled.div<{ padding?: string; marginBottom?: string }>`
  width: 100%;
  z-index: 0;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '32px')};
  padding: ${props => (props.padding ? props.padding : '0')};
`;

const Divider = styled.div`
  width: 100%;
  margin: auto;
  border-bottom: 1px solid #4a38ed;
`;

const LogoContainer = styled.div`
  display: flex;
  margin: 0 auto 2px;
  position: relative;
  height: 42px;
  overflow: hidden;
`;

const OptionContainer = styled(FlexContainer)<OptionProps>`
  position: relative;
  width: 100% !important;
  a,
  .menu-option {
    color: ${props => (props.active ? '#5641F0' : '#ffffff')};
    opacity: ${props => (props.active || props.currency ? '1' : '0.5')};
    width: 100%;
    justify-content: ${props => (props.showMenu ? 'flex-start' : 'center')};
    background-color: transparent;
    &:hover {
      background-color: ${props => (props.active ? 'transparent' : '#4A38ED')};
    }

    & > svg > path {
      stroke: ${props => (props.active ? '#5641F0' : '#ffffff')};
    }
  }
  ${props =>
    props.active &&
    `
      border-radius: 8px;
      background-color: #ffffff;
  `}
`;

const StyledLink = styled(Link)<{ padding?: string }>`
  display: flex;
  height: 100%;
  padding: ${props => (props.padding ? props.padding : '10px')};
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  border-radius: 5px;
  color: inherit;
`;

const MenuOptionText = styled.div`
  display: flex;
  height: 100%;
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  border-radius: 5px;
  color: inherit;
  cursor: pointer;
`;

const ProfileContainer = styled.div<{ showMenu: boolean }>`
  margin: 20px auto 0;
  display: grid;
  width: 90% !important;
  grid-template-columns: ${props => (props.showMenu ? '1fr 2fr 1fr' : '1fr')};
  grid-template-rows: repeat(2, 1fr);
  position: relative;

  .profile-image {
    grid-area: 1/1/3/2;
    align-self: center;
    justify-self: ${props => (props.showMenu ? 'start' : 'center')};
  }

  .profile-full-name {
    color: #ffffff;
    display: ${props => (props.showMenu ? 'block' : 'none')};
    grid-area: 1/2/2/3;
  }

  .profile-email {
    display: ${props => (props.showMenu ? 'block' : 'none')};
    grid-area: 2/2/3/3;
  }

  .profile-dropdown {
    display: ${props => (props.showMenu ? 'block' : 'none')};
    grid-area: 1/3/3/4;
  }
`;
const ProfileButton = styled(Link)`
  border-radius: 8px;
`;

const SubMenuButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  margin: 0 0 0 5px;
`;

const SubMenuOption: React.FC<SubMenuOptionProps> = ({ option, showMenu }) => {
  const match = useRouteMatch({
    path: option.url,
    exact: option.path,
  });

  return (
    <FlexContainer direction='column'>
      <OptionContainer
        container
        justify='center'
        alignItems='center'
        active={!!match}
        showMenu={showMenu}
        margin='0 0 10px 0'
      >
        <StyledLink to={option.url} padding='10px 35px'>
          {showMenu && option.name}
        </StyledLink>
      </OptionContainer>
    </FlexContainer>
  );
};

const MenuOption: React.FC<MenuOptionProps> = ({ option, showMenu, user }) => {
  const match = useRouteMatch({
    path: option.url,
    exact: option.path,
  });

  // Values
  const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!showMenu) {
      setSubmenuOpen(false);
    }
  }, [showMenu]);

  return (
    <CustomTooltip title={showMenu ? '' : option.name} placement='right'>
      <FlexContainer direction='column'>
        <OptionContainer
          container
          justify='center'
          alignItems='center'
          active={!!match}
          showMenu={showMenu}
          margin={option.extraMargin ? '0 0 40px 0' : '0 0 20px 0'}
        >
          {option.submenu ? (
            <MenuOptionText
              className='menu-option'
              onClick={() => setSubmenuOpen(!submenuOpen)}
            >
              <>
                <Icon
                  icon={option.img}
                  size='21'
                  margin={showMenu ? '0 11px 0 0' : '0'}
                  color='#5641F0'
                />
                {showMenu && option.name}
              </>
              {showMenu && (
                <SubMenuButton
                  style={{ transform: submenuOpen ? 'rotate(180deg)' : 'none' }}
                >
                  <Icon
                    icon='arrow-icon'
                    size={10}
                    color={match ? '#5641F0' : '#ffffff'}
                  />
                </SubMenuButton>
              )}
            </MenuOptionText>
          ) : (
            <StyledLink to={option.url}>
              <Icon
                icon={option.img}
                size='21'
                margin={showMenu ? '0 11px 0 0' : '0'}
                color='#5641F0'
              />
              {showMenu && option.name}
            </StyledLink>
          )}
        </OptionContainer>
        {option.submenu && submenuOpen && (
          <Section padding='0 8px' marginBottom='20px'>
            {option.submenu.map(suboption => {
              if (
                !suboption.permissions ||
                suboption.permissions.some(permission =>
                  user.permissions.includes(permission)
                )
              ) {
                return (
                  <SubMenuOption
                    key={suboption.name}
                    option={suboption}
                    showMenu={showMenu}
                  />
                );
              }
              return null;
            })}
          </Section>
        )}
      </FlexContainer>
    </CustomTooltip>
  );
};

const WalletOption: React.FC<MenuOptionProps> = ({ option, showMenu }) => {
  const match = useRouteMatch({
    path: option.url,
    exact: option.path,
  });

  const [balanceWallet, fetchBalanceWallet, loadingBalance] = useRequest(
    getBalance,
    {}
  );

  const getCurrency = currency => {
    switch (currency) {
      case 'PEN':
        return 'S/';
      case 'USD':
        return '$';
      default:
        return '';
    }
  };

  useEffect(() => {
    fetchBalanceWallet();
  }, [showMenu]);

  return (
    <CustomTooltip title={showMenu ? '' : option.name} placement='right'>
      <OptionContainer
        container
        justify='center'
        alignItems='center'
        active={!!match}
        showMenu={showMenu}
        margin={option.extraMargin ? '0 0 40px 0' : '0 0 20px 0'}
        currency
      >
        <StyledLink to={option.url}>
          <Icon
            icon={option.img}
            size='21'
            margin={showMenu ? '0 11px 0 0' : '0'}
            color={match ? '#5641F0' : '#ffffff'}
          />
          <FlexContainer
            backgroundColor='#2fbc52'
            padding={showMenu ? '5px 10px' : '0'}
            borderRadius='6px'
          >
            {showMenu && (
              <>
                {!loadingBalance ? (
                  <Typography color='#ffffff'>
                    {getCurrency(balanceWallet.balance_currency)}{' '}
                    {balanceWallet.balance}
                  </Typography>
                ) : (
                  <Spinner
                    height='15px'
                    spinnerSize={12}
                    colorSecondary
                    margin='0'
                  />
                )}
              </>
            )}
          </FlexContainer>
        </StyledLink>
      </OptionContainer>
    </CustomTooltip>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ showMenu, setShowMenu }) => {
  const size = useWindowSize();
  const user = useTypedSelector(state => state.user);
  const dispatch = useDispatch();
  const [logoProps, setLogoProps] = useSpring(() => ({
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }));
  const transitions2 = useTransition(showMenu, null, {
    from: {
      opacity: 0,
      top: '50%',
      left: '140%',
      transform: 'translate(-50%, -50%)',
    },
    enter: {
      opacity: 1,
      top: '50%',
      left: '60%',
      transform: 'translate(-50%, -50%)',
    },
    leave: {
      opacity: 0,
      top: '50%',
      left: '140%',
      transform: 'translate(-50%, -50%)',
    },
  });

  React.useEffect(() => {
    setLogoProps(
      showMenu
        ? {
            top: '20%',
            left: '15%',
            transform: 'translate(-10%, -10%)',
          }
        : {
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }
    );
  }, [setLogoProps, showMenu]);

  const sessionOptions = [
    {
      label: 'Mi cuenta',
      secondLabel: user.email,
      name: 'account',
      icon: 'user-icon',
      onClick: () => {
        window.open('/configuracion/cuenta', '_self');
      },
    },
    {
      label: 'Ayuda',
      name: 'help',
      icon: 'help-icon',
      onClick: () => {
        window.open(
          'https://www.notion.so/aimoinc/Help-Center-AIMO-88758c8c5335445c8c7088ff052b0e6c'
        );
      },
    },
    // {
    //   label: 'Soporte',
    //   name: 'support',
    //   icon: 'whatsapp-icon',
    //   onClick: () => {
    //     window.open(
    //       `https://wa.me/51946565757?text=¡Hola! Soy ${user.full_name} de la organización ${orgaRetrieve.tradename} y requiero soporte`
    //     );
    //   },
    // },
    {
      label: 'Cerrar sesión',
      name: 'logout',
      icon: 'log-out-icon',
      onClick: () => {
        localStorage.clear();
        dispatch(clearUser());
        dispatch(clearOrganization());
        dispatch(logout());
      },
    },
  ];

  const optionSession = () =>
    sessionOptions.map(session => (
      <>
        <DropdownIconOption
          label={session.label}
          secondLabel={session.secondLabel && session.secondLabel}
          name={session.name}
          icon={session.icon}
          onClick={() => session.onClick()}
        />
      </>
    ));

  return (
    <SidebarContainer showMenu={showMenu}>
      <Container>
        <Section>
          <LogoContainer>
            <animated.div
              style={{
                ...logoProps,
                position: 'absolute',
                zIndex: 1,
              }}
            >
              <Logo width='33' height='33' img={AimoLogoIcon} />
            </animated.div>
            {transitions2.map(
              ({ item, key, props }) =>
                item && (
                  <animated.div
                    key={key}
                    style={{
                      ...props,
                      position: 'absolute',
                    }}
                  >
                    <Logo width='70' height='20' img={AimoLogoText} />
                  </animated.div>
                )
            )}
          </LogoContainer>
          {/* <Divider /> */}
        </Section>
        <FlexContainer direction='column' height='100%' justify='space-between'>
          <Section padding='0 8px'>
            {menu.map(option => {
              if (
                option.name === 'Monedero' &&
                user.permissions.includes('core.wallet.retrieve')
              ) {
                return (
                  <WalletOption
                    key={option.name}
                    option={option}
                    showMenu={showMenu}
                  />
                );
              }
              if (
                !option.permissions ||
                option.permissions.some(permission =>
                  user.permissions.includes(permission)
                )
              ) {
                return (
                  // <>
                  //   {option.name === 'Monedero' ? (
                  //     <WalletOption
                  //       key={option.name}
                  //       option={option}
                  //       showMenu={showMenu}
                  //     />
                  //   ) : (
                  <MenuOption
                    key={option.name}
                    option={option}
                    showMenu={showMenu}
                    user={user}
                  />
                  //   )}
                  // </>
                );
              }

              return null;
            })}
          </Section>
        </FlexContainer>
      </Container>
      {size.width > 768 && (
        <ToggleButton
          onClick={() => setShowMenu(!showMenu)}
          style={{ transform: showMenu ? 'rotate(180deg)' : 'none' }}
        >
          <Icon icon='right-arrow-icon' size={10} color='#FFFFFF' />
        </ToggleButton>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;
