import React from 'react';
import styled from 'styled-components';
import THEME from 'util/styledTheme';
import { FlexContainer, Typography } from 'components/atoms';
import aimoLogo from 'assets/images/AimoLogoIcon.svg';
import packageImage from 'assets/images/emails/mailing-delivered-order.png';
import envelopeImage from 'assets/images/emails/mailing-envelope-image.svg';
import { EmailTemplateProps } from './types';

const CustomButton = styled.button`
  background-color: #404071;
  border-radius: 5px;
  width: 320px;
  margin-bottom: 40px;
  cursor: pointer;
  margin: 20x 0 40px 0;

  &:focus {
    outline: 0;
  }
`;

const CompletedOrder: React.FC<EmailTemplateProps> = ({
  organizationPhoto,
}) => {
  return (
    <FlexContainer container direction='column' alignItems='center'>
      <FlexContainer
        container
        direction='column'
        alignItems='center'
        backgroundColor='#FFFFFFFF'
      >
        <img
          src={organizationPhoto}
          alt='organization-logo'
          style={{
            height: 'auto',
            width: 'auto',
            maxHeight: '150px',
            maxWidth: '200px',
            paddingTop: '1.4rem',
          }}
        />
        <img src={packageImage} alt='package-of-order' />

        <Typography fontSize='38px' fontWeight='700' margin='20px 0'>
          ¡Hola John Doe!
        </Typography>
        <Typography fontSize='22px' textAlign='center'>
          Te informamos que tu pedido CODE-TEST-01 ha sido entregado.
        </Typography>

        <FlexContainer
          width='70%'
          padding='20px'
          justify='center'
          margin='20px 0'
          backgroundColor='rgba(242, 242, 247, 0.64)'
        >
          <Typography fontWeight='700' fontSize='24px'>
            Datos de entrega
          </Typography>
        </FlexContainer>

        <Typography fontSize='18px' fontWeight='700' margin='20px 0 10px 0'>
          Entrega a:
        </Typography>
        <Typography fontSize='18px'>John Doe</Typography>
        <Typography fontSize='18px' fontWeight='700' margin='20px 0 10px 0'>
          Dirección:
        </Typography>
        <Typography fontSize='18px' margin='0 0 30px 0'>
          Av. Alfredo Benavides 1944, Santiago de Surco
        </Typography>

        <CustomButton>
          <FlexContainer>
            <img
              src={envelopeImage}
              alt='truck'
              style={{ width: '32px', height: '30px', margin: 'auto' }}
            />
            <div style={{ borderLeft: '2px solid white', height: '60px' }} />
            <FlexContainer
              alignItems='center'
              height='100%'
              style={{ margin: 'auto' }}
            >
              <Typography fontSize='16px' fontWeight='700' color='white'>
                Envía tu feedback
              </Typography>
            </FlexContainer>
          </FlexContainer>
        </CustomButton>
      </FlexContainer>

      <FlexContainer alignItems='center' margin='30px 0 0 0'>
        <FlexContainer
          backgroundColor='#FFFFFF'
          alignItems='center'
          justify='center'
          borderColor={THEME.colors.borderColor}
          padding='5px'
          borderRadius='8px'
          style={{ boxShadow: '0 3px 6px 0 #e7e7f0' }}
        >
          <img src={aimoLogo} alt='aimo-logo' style={{ height: '18px' }} />
        </FlexContainer>
        <Typography
          color='text.primary'
          fontWeight='700'
          fontSize='16px'
          margin='0 4px'
        >
          Powered by Aimo
        </Typography>
      </FlexContainer>
    </FlexContainer>
  );
};

export default CompletedOrder;
