import React from 'react';
import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';
import { FlexContainer, Typography } from 'components/atoms';
import { PaginationProps } from './types';

const CustomPaginator = styled(Pagination)`
  button {
    border: 1px solid #e8e9ec;
    color: ${props => props.theme.colors.textSecondary};
    &:hover {
      background-color: ${props => props.theme.colors.borderColor};
    }
  }
  button[class^='PaginationItem-selected'],
  button[class*=' PaginationItem-selected'] {
    color: ${props => props.theme.colors.textSecondary};
    background-color: ${props => props.theme.colors.borderColor};
    &:hover {
      background-color: ${props => props.theme.colors.borderColor};
    }
    &:focus {
      background-color: ${props => props.theme.colors.borderColor};
    }
  }
`;

const Paginator: React.FC<PaginationProps> = ({
  onPageChange,
  currentPage,
  totalPages,
  pageSize,
  totalItems,
  onlyPagination = false,
}) => {
  return (
    <FlexContainer
      container
      alignItems='center'
      justify='space-between'
      margin='0 0 30px'
    >
      {!onlyPagination && (
        <Typography color='text.secondary'>
          {`Mostrando registros del ${pageSize * (currentPage - 1) + 1} al ${
            pageSize * currentPage > totalItems
              ? totalItems
              : pageSize * currentPage
          } de un total de ${totalItems}`}
        </Typography>
      )}

      {totalPages > 1 && (
        <CustomPaginator
          page={currentPage}
          count={totalPages}
          variant='outlined'
          shape='rounded'
          onChange={(e, page) => onPageChange(page)}
        />
      )}
    </FlexContainer>
  );
};

export default Paginator;
