import React, { useRef, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import Cropper from 'react-easy-crop';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import { useTypedSelector } from 'store';
import { postDigitalCatalogue, patchOrganization } from 'services';
import { Grid, InputAdornment, Dialog } from '@material-ui/core';
import THEME from 'util/styledTheme';
import { optionFormatter } from 'util/functions';
import { FormControl } from 'components/molecules';
import {
  Typography,
  Button,
  FlexContainer,
  Select,
  Icon,
} from 'components/atoms';
import LogoOrganizationPlaceholder from 'assets/images/LogoOrganizationPlaceholder.svg';
import DemoStore from 'assets/images/DemoStore.png';
import StoreBackground from 'assets/images/StoreBackground.png';
import DemoStoreBackground from 'assets/images/demostore/DemoBackground.png';
import RefreshIcon from 'assets/images/demostore/RefreshIcon.svg';
import LockIcon from 'assets/images/demostore/LockIcon.svg';
import Product1 from 'assets/images/demostore/Product1.png';
import Product2 from 'assets/images/demostore/Product2.png';
import Product3 from 'assets/images/demostore/Product3.png';
import Product4 from 'assets/images/demostore/Product4.png';
import WhatsappIcon from 'assets/images/demostore/WhatsappIcon.svg';
import ShoppingBagIcon from 'assets/images/demostore/shopping-bag.svg';
import { ReactComponent as HomeIcon } from 'assets/images/demostore/home.svg';

const categories = ['Todo', 'Vestidos', 'Camisas', 'Remeras', 'Pantalones'];

const productList = [
  {
    id: 1,
    name: 'Bolso Fashion II, ecocuero',
    price: '70',
    img: Product1,
  },
  {
    id: 2,
    name: 'Vestido Friza',
    price: '70',
    img: Product2,
  },
  {
    id: 3,
    name: 'Zapato tacón Every',
    price: '70',
    img: Product3,
  },
  {
    id: 4,
    name: 'Zapatilla urbanas Squad',
    price: '70',
    img: Product4,
  },
];

const currencies = [
  {
    id: 1,
    name: 'Sol (S/)',
    code: 'PEN',
    symbol: 'S/',
  },
  {
    id: 2,
    name: 'Dólar estadounidense ($)',
    code: 'USD',
    symbol: '$',
  },
  {
    id: 3,
    name: 'Peso mexicano ($)',
    code: 'MXN',
    symbol: '$',
  },
  {
    id: 4,
    name: 'Peso chileno ($)',
    code: 'CLP',
    symbol: '$',
  },
  {
    id: 5,
    name: 'Peso argentino ($)',
    code: 'ARS',
    symbol: '$',
  },
  {
    id: 6,
    name: 'Peso colombiano ($)',
    code: 'COP',
    symbol: '$',
  },
  {
    id: 7,
    name: 'Peso uruguayo ($U)',
    code: 'UYU',
    symbol: '$U',
  },
  {
    id: 8,
    name: 'Boliviano (Bs)',
    code: 'BOB',
    symbol: 'Bs',
  },
];

const ThridStepContainer = styled(Grid)`
  height: auto;
  background-color: #ffffff;

  @media only screen and (min-width: 960px) {
    height: 100vh;
  }
`;

const Container = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-rows: 1.2fr 1fr;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 768px) and (max-width: 955px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 956px) and (max-width: 1190px) {
    grid-template-columns: 1.3fr 1fr;
    grid-template-rows: 1fr;
  }

  @media only screen and (min-width: 1191px) {
    grid-template-columns: 1.1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const FormContainer = styled(FlexContainer)`
  width: 80%;
  padding: 50px 0;

  @media only screen and (min-width: 560px) and (max-width: 767px) {
    width: 500px;
  }

  @media only screen and (min-width: 768px) and (max-width: 959px) {
    width: 330px;
  }

  @media only screen and (min-width: 960px) {
    width: 460px;
  }
`;

const AddImage = styled(FlexContainer)<{ border: string }>`
  border-style: ${props => props.border};
  align-self: center;
`;

const AimoBackground = styled(FlexContainer)`
  background-color: #dbd9f5;
  background-image: url(${StoreBackground});
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 380px 350px;
  border-radius: 0;
  padding: 50px 0;

  @media only screen and (min-width: 768px) {
    padding: 30px 0;
    border-radius: 48px 0 0 48px;
    background-size: 461px 397px;
  }
`;

const LogoContainer = styled(FlexContainer)`
  img {
    width: 70%;
    height: auto;
  }

  @media only screen and (min-width: 450px) and (max-width: 767px) {
    img {
      width: 45%;
      height: auto;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 955px) {
    img {
      width: 50%;
      height: auto;
    }
  }

  @media only screen and (min-width: 956px) and (max-width: 1190px) {
    img {
      width: 55%;
      height: auto;
    }
  }

  @media only screen and (min-width: 1191px) {
    img {
      max-width: 280px;
      height: auto;
    }
  }
`;

const CustomButton = styled.button<{ fontSize?: string; margin?: string }>`
  color: #5641f0;
  background-color: transparent;
  border: none;
  font-size: ${props => (props.fontSize ? props.fontSize : '15px')};
  font-weight: 500;
  margin: ${props => (props.margin ? props.margin : '0')};
  cursor: pointer;
`;

const ChangeDeleteContainer = styled(FlexContainer)`
  justify-content: center;
  margin: 10px 0 30px 15px;

  @media only screen and (min-width: 768px) {
    justify-content: flex-start;
    margin: 10px 0 40px 15px;
  }
`;

const DemoStoreContainer = styled(FlexContainer)`
  border: 4px solid #000000;
  background-color: #f7f7f7;
  border-radius: 29px;
  background-image: url(${DemoStoreBackground});
  background-repeat: no-repeat;
  background-size: cover;
  // padding: 0 7px;
  width: 290px;
`;

const DemoStoreHeader = styled(FlexContainer)`
  box-shadow: 0px 1.45813px 2.91626px rgba(0, 0, 0, 0.08);
`;

const DemoStoreFooter = styled(FlexContainer)`
  border: 0.729064px solid #d1cfd5;
  height: 55px;
`;

const DemoStoreBottomContainer = styled(FlexContainer)`
  position: absolute;
  bottom: 43px;
`;

const CategoryContainer = styled(FlexContainer)<{ bg: string }>`
  padding: 5px 10px;
  background-color: ${props => props.bg};
  border: 0.729064px solid ${props => props.bg};
  border-radius: 20px;
`;

const DemoProductsContainer = styled(FlexContainer)`
  flex-wrap: wrap;
`;

const CustomHomeIcon = styled(HomeIcon)<{ bg: string }>`
  width: 19px;

  path {
    stroke: ${props => props.bg};
  }
`;

const CurrencyColorContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: flex-start;

  @media only screen and (min-width: 960px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ColorInput = styled(FormControl)`
  div > input {
    width: 35px !important;
  }
`;

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const ColoredLine = styled.hr`
  border: 1px solid #ebebed;
  width: 100%;
`;

const CropContainer = styled.div`
  position: relative;
  margin: 40px auto;
  width: 100%;
  height: 350px;

  @media only screen and (min-width: 1250px) {
    width: 90%;
    height: 400px;
  }
`;

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

async function getCroppedImg(imageSrc, pixelCrop) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob(file => {
      resolve({ file, cropped: URL.createObjectURL(file) });
    }, 'image/jpeg');
  });
}

const RightSide: React.FC<{
  storeName: string;
  bgColor: string;
  selectedCurrency: string;
  storeLogo: any;
}> = ({ storeName, bgColor, selectedCurrency, storeLogo }) => {
  const [backgroundC, setBackgroundC] = useState<string>('#5641F0');
  const [currency, setCurrency] = useState<string>('S/');

  useEffect(() => {
    if (!bgColor || bgColor === '' || bgColor === '#') {
      setBackgroundC('#5641F0');
    } else if (bgColor.length < 4) {
      setBackgroundC('#5641F0');
    } else if (bgColor.length === 6) {
      setBackgroundC(bgColor.slice(0, bgColor.length - 1));
    } else {
      setBackgroundC(bgColor);
    }
  }, [bgColor]);

  useEffect(() => {
    if (selectedCurrency === '') {
      setCurrency('S/');
    } else {
      setCurrency(
        currencies.find(item => item.code === selectedCurrency).symbol
      );
    }
  }, [selectedCurrency]);

  return (
    <AimoBackground container height='100%' alignItems='center'>
      <FlexContainer container direction='column' alignItems='center'>
        <FlexContainer container justify='center' margin='0 0 20px'>
          <Typography color='#181818' fontSize={18}>
            Así quedaría tu tienda
          </Typography>
        </FlexContainer>
        <LogoContainer container justify='center'>
          <DemoStoreContainer
            direction='column'
            position='relative'
            height='617px'
          >
            <FlexContainer
              width='95%'
              backgroundColor='#E3E3E3'
              borderRadius='7px'
              padding='4px 8px'
              alignItems='center'
              margin='40px 7px 10px'
              justify='space-between'
            >
              <FlexContainer alignItems='baseline'>
                <Typography fontSize={11} color='#202124' fontWeight={600}>
                  A
                </Typography>
                <Typography fontSize={13} color='#202124' fontWeight={600}>
                  A
                </Typography>
              </FlexContainer>
              <FlexContainer alignItems='center'>
                <img src={LockIcon} alt='lock' />
                <Typography
                  margin='0 0 0 5px'
                  fontSize={13}
                  lineHeight='15px'
                  color='#202124'
                >
                  {storeName !== '' ? storeName : 'teencloset'}.aimo.site
                </Typography>
              </FlexContainer>
              <img src={RefreshIcon} alt='refresh' style={{ width: 11 }} />
            </FlexContainer>
            <FlexContainer
              container
              direction='column'
              backgroundColor='#ffffff'
            >
              <DemoStoreHeader
                container
                justify='center'
                alignItems='center'
                padding={storeLogo.length > 0 ? '7px 8px' : '12px 8px'}
              >
                {storeLogo.length > 0 ? (
                  <img
                    src={storeLogo[0]}
                    alt='store'
                    style={{ height: 45, width: 'auto' }}
                  />
                ) : (
                  <Typography
                    color='#181818'
                    fontWeight={600}
                    fontSize={16}
                    margin='2px 0'
                  >
                    {storeName !== '' ? storeName : `Teen's Closet`}
                  </Typography>
                )}
              </DemoStoreHeader>
              <FlexContainer container direction='column'>
                <FlexContainer container overflow='hidden' margin='20px 0 15px'>
                  {categories.map(category => (
                    <CategoryContainer
                      bg={category === 'Todo' ? backgroundC : '#eff4f7'}
                      margin={category === 'Todo' ? '0 0 0 15px' : '0 0 0 3px'}
                    >
                      <Typography
                        fontSize={11}
                        color={category === 'Todo' ? '#ffffff' : backgroundC}
                      >
                        {category}
                      </Typography>
                    </CategoryContainer>
                  ))}
                </FlexContainer>
                <FlexContainer
                  direction='column'
                  padding='0 15px'
                  margin='0 0 15px'
                >
                  <Typography
                    color='#181818'
                    fontSize={13}
                    fontWeight={600}
                    lineHeight='17px'
                  >
                    Productos
                  </Typography>
                </FlexContainer>
                <FlexContainer container direction='column' padding='0 15px'>
                  <DemoProductsContainer container justify='space-between'>
                    {productList.map(product => (
                      <FlexContainer
                        width='115px'
                        height={
                          product.id === 1 || product.id === 2
                            ? '185px'
                            : 'auto'
                        }
                        direction='column'
                        backgroundColor='#ffffff'
                        borderColor='#EBF0FF'
                        padding='6px 7px'
                        borderRadius='3px'
                        margin='0 0 15px'
                      >
                        <FlexContainer
                          justify='center'
                          backgroundColor='#F6F6F6'
                          borderRadius='3px'
                        >
                          <img
                            src={product.img}
                            alt='product1'
                            style={{ width: 100 }}
                          />
                        </FlexContainer>

                        {(product.id === 1 || product.id === 2) && (
                          <FlexContainer
                            container
                            direction='column'
                            height='100%'
                            justify='space-between'
                          >
                            <Typography
                              color='#181818'
                              fontSize={11}
                              margin='10px 0 0'
                            >
                              {product.name}
                            </Typography>
                            <FlexContainer
                              container
                              justify='space-between'
                              alignItems='center'
                            >
                              <Typography fontSize={15} fontWeight={600}>
                                {currency} {product.price}
                              </Typography>
                              <FlexContainer
                                width='20px'
                                height='20px'
                                backgroundColor={backgroundC}
                                borderRadius='50%'
                                justify='center'
                                alignItems='center'
                              >
                                <Typography color='#ffffff' fontSize={14}>
                                  +
                                </Typography>
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                        )}
                      </FlexContainer>
                    ))}
                  </DemoProductsContainer>
                </FlexContainer>
                <DemoStoreBottomContainer container direction='column'>
                  <FlexContainer margin='0 0 0 12px'>
                    <img
                      src={WhatsappIcon}
                      alt='whatssap'
                      style={{ width: 50 }}
                    />
                  </FlexContainer>
                  <DemoStoreFooter
                    container
                    justify='center'
                    alignItems='center'
                    backgroundColor='#ffffff'
                  >
                    <FlexContainer width='35%' justify='space-between'>
                      <FlexContainer direction='column' alignItems='center'>
                        <CustomHomeIcon bg={backgroundC} />
                        <Typography
                          color={backgroundC}
                          fontSize={10}
                          lineHeight='12px'
                          margin='1px 0 0'
                        >
                          Home
                        </Typography>
                      </FlexContainer>
                      <FlexContainer direction='column' alignItems='center'>
                        <img
                          src={ShoppingBagIcon}
                          alt='shoppingbag'
                          style={{ width: 19 }}
                        />
                        <Typography
                          color='#858D91'
                          fontSize={9}
                          lineHeight='12px'
                          margin='4px 0 0'
                        >
                          Tu bolsa
                        </Typography>
                      </FlexContainer>
                    </FlexContainer>
                  </DemoStoreFooter>
                </DemoStoreBottomContainer>
              </FlexContainer>
            </FlexContainer>
          </DemoStoreContainer>
        </LogoContainer>
      </FlexContainer>
    </AimoBackground>
  );
};

const ThirdStep = ({ changeStep, setProgress }) => {
  const photoInput = useRef<HTMLInputElement>();
  const windowHost = window.location.host;
  const [fileList, setFileList] = useState([]);
  const [organizationLogo, setOrganizationLogo] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectCurrency, setSelectCurrency] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const selectedOrganization = useTypedSelector(
    state => state.organization.selectedOrganization
  );
  // API calls
  const [createdStore, fetchCreate, , , createError] = useRequest(
    postDigitalCatalogue
  );
  const [, updateOrganization] = useRequest(patchOrganization);

  const validationStateStep = {
    phone_number: ['mandatory'],
    name: ['mandatory'],
  };

  const [form, onFormChange, validate, errors, ,] = useForm(
    {
      name: localStorage.getItem('name') ? localStorage.getItem('name') : '',
      phone_number: '',
      store_color: '#5641F0',
      facebook_link: 'www.facebook.com/',
      instagram_link: 'www.instagram.com/',
    },
    validationStateStep
  );

  // Values
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [dialogMaxWidth, setDialogMaxWidth] = useState(true);

  // crop image
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    if (!isEmpty(createdStore)) {
      if (fileList.length > 0) {
        const formData = new FormData();

        Array.from(fileList).forEach(file => {
          formData.append('photo', file);
        });

        updateOrganization(formData, selectedOrganization.id);
      }

      if (windowHost === 'app.aimo.co') {
        // @ts-ignores
        window.analytics.track('Store created', {
          store_name: `${form.name.toLowerCase()}${
            windowHost === 'app.aimo.co' ? '.aimo.site' : '.dev.aimo.site'
          }`,
          organization_id: selectedOrganization.id,
          organization_name: selectedOrganization.name,
          created_at: moment().format(),
        });
      }

      localStorage.removeItem('name');
      localStorage.removeItem('country');

      setProgress(100);
      changeStep(4);
    } else if (!isEmpty(createError)) {
      if (createError.data.status_code === 400) {
        createError.data.errors.forEach(error => {
          if (error.phone_number) {
            enqueueSnackbar('El número de teléfono ingresado no es válido.', {
              variant: 'error',
            });
          }
          if (error.store) {
            enqueueSnackbar('El nombre de la tienda ya existe.', {
              variant: 'error',
            });
          }
        });
      }
    }
  }, [createError, createdStore]);

  useEffect(() => {
    setCurrencyOptions(
      optionFormatter(currencies, {
        id: option => option.code,
        name: option => option.name,
      })
    );
  }, [currencies]);

  const validateForm = () => {
    if (validate()) {
      const data: any = {
        phone_number: form.phone_number.includes('+')
          ? form.phone_number
          : `+${form.phone_number}`,
        name: form.name.toLowerCase(),
        store: `${form.name.toLowerCase()}${
          windowHost === 'app.aimo.co' ? '.aimo.site' : '.dev.aimo.site'
        }`,
        currency: selectCurrency,
        store_color: form.store_color,
      };

      const dataOrganization: any = {
        instagram_link:
          form.instagram_link !== 'www.facebook.com/'
            ? form.instagram_link
            : '',
        facebook_link:
          form.facebook_link !== 'www.instagram.com/' ? form.facebook_link : '',
      };

      fetchCreate(data);
      updateOrganization(dataOrganization, selectedOrganization.id);
    }
  };

  const handleUploadFile = event => {
    setOpenCreate(true);

    if (event.target.files) {
      const fileArray = Array.from(event.target.files).map(file =>
        URL.createObjectURL(file)
      );

      // setFileList(fileListArray);
      setImageSrc(fileArray);
      // setOrganizationLogo(fileArray);
    }
  };

  const handleDeleteFile = () => {
    setFileList([]);
    setOrganizationLogo([]);
    setImageSrc(null);
  };

  // crop logo
  const onCropComplete = useCallback((croppedArea, croppedAreaPixel) => {
    setCroppedAreaPixels(croppedAreaPixel);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedData: any = await getCroppedImg(imageSrc, croppedAreaPixels);
      const fileListArray = new File([croppedData.file], 'croppedImage.jpg', {
        type: 'image/jpeg',
      });

      setOrganizationLogo([croppedData.cropped]);
      setFileList([fileListArray]);
      setDialogMaxWidth(false);
      setImageSrc(null);
      setOpenCreate(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <ThridStepContainer container>
      <Container>
        <FlexContainer
          container
          width='100%'
          height='100%'
          justify='center'
          alignItems='center'
        >
          <FormContainer direction='column'>
            <Typography fontSize='27px' color='#181818' fontWeight={600}>
              Crea tu catálogo digital en 1 minuto.
              <span style={{ fontWeight: 400 }}>
                {' '}
                Centraliza todo tu inventario en Aimo
              </span>
            </Typography>

            <FormControl
              label='A qué número quieres que lleguen tus pedidos*'
              placeholder='Escribe tu número de celular'
              onChange={value => onFormChange(value, 'phone_number')}
              value={form.phone_number}
              margin='50px 0px 30px'
              width='100%'
              control='simplePhone'
              error={errors.phone_number}
              variant='standard'
              country={
                localStorage.getItem('country')
                  ? localStorage.getItem('country').toLowerCase()
                  : 'pe'
              }
            />

            <FormControl
              label='Tu URL de tienda será'
              placeholder='Escribe el nombre de la URL del negocio'
              onChange={value => {
                const name = value.replace(/ /g, '').replace(/[^a-z0-9]/gi, '');
                if (name.length > 40) {
                  return;
                }

                onFormChange(name.toLowerCase(), 'name');
              }}
              value={form.name}
              margin='0 0 30px'
              width='100%'
              control='input'
              variant='standard'
              error={errors.name}
              endAdornment={
                <InputAdornment position='end'>
                  {windowHost === 'app.aimo.co'
                    ? '.aimo.site'
                    : '.dev.aimo.site'}
                </InputAdornment>
              }
            />
            <CurrencyColorContainer container margin='0 0 20px'>
              <FlexContainer
                direction='column'
                margin='0 10px 0 0'
                width='100%'
              >
                <Typography fontSize={12} margin='0 15px 10px 0'>
                  Seleccione una moneda
                </Typography>
                <Select
                  placeholder='Seleccione moneda'
                  value={selectCurrency}
                  options={currencyOptions}
                  onChange={value => setSelectCurrency(value)}
                  borderless
                />
              </FlexContainer>

              <ColorInput
                label='El color de tu tienda'
                type='color'
                placeholder='#000000'
                onChange={value => {
                  if (value.length < 8) {
                    onFormChange(value.toLowerCase(), 'store_color');
                  }
                }}
                value={form.store_color}
                width='50%'
                control='input'
                variant='standard'
                error={errors.store_color}
                margin='0'
              />
            </CurrencyColorContainer>
            <FormControl
              label='Link de tu cuenta de Facebook'
              placeholder='Escribe el link de tu cuenta'
              onChange={value => {
                onFormChange(value, 'facebook_link');
              }}
              value={form.facebook_link}
              margin='0 0 30px'
              width='100%'
              control='input'
              variant='standard'
              error={errors.facebook_link}
              optional
            />
            <FormControl
              label='Link de tu cuenta de Instagram'
              placeholder='Escribe el link de tu cuenta'
              onChange={value => {
                onFormChange(value, 'instagram_link');
              }}
              value={form.instagram_link}
              margin='0 0 50px'
              width='100%'
              control='input'
              variant='standard'
              error={errors.instagram_link}
              optional
            />

            <Grid container justify='center'>
              <Grid
                item
                xs={12}
                md={organizationLogo.length > 0 ? 4 : 3}
                container
                justify='center'
                onClick={() => photoInput.current.click()}
                style={{ cursor: 'pointer' }}
              >
                <AddImage
                  width={organizationLogo.length > 0 ? '100%' : '88px'}
                  height='88px'
                  justify='center'
                  alignItems='center'
                  borderColor={THEME.colors.textPrimary}
                  borderRadius='50%'
                  margin='0 0 50px'
                  backgroundColor={organizationLogo.length > 0 ? '' : '#EEECFD'}
                  border={organizationLogo.length > 0 ? 'none' : 'dotted'}
                >
                  <img
                    src={
                      organizationLogo.length > 0
                        ? organizationLogo[0]
                        : LogoOrganizationPlaceholder
                    }
                    style={{
                      width: organizationLogo.length > 0 ? '100%' : '37px',
                      height: organizationLogo.length > 0 ? '125px' : '37px',
                    }}
                    alt='logo_full'
                  />
                </AddImage>
                <input
                  ref={photoInput}
                  type='file'
                  accept='image/*'
                  onChange={handleUploadFile}
                  style={{ display: 'none' }}
                />
              </Grid>
              <Grid item xs={12} md={organizationLogo.length > 0 ? 8 : 7}>
                <Typography
                  fontSize='16px'
                  margin='20px 0 0 20px'
                  color='#181818'
                  fontWeight={600}
                >
                  Logo de tu empresa
                  <span style={{ fontWeight: 400, color: '#c5c4c4' }}>
                    {' '}
                    (Opcional)
                  </span>
                </Typography>
                <ChangeDeleteContainer>
                  <CustomButton onClick={() => photoInput.current.click()}>
                    Cambiar
                  </CustomButton>
                  <Typography margin='0 15px' color='#D7E4EB'>
                    |
                  </Typography>
                  <CustomButton onClick={() => handleDeleteFile()}>
                    Eliminar
                  </CustomButton>
                </ChangeDeleteContainer>
              </Grid>
            </Grid>
            <FlexContainer
              container
              direction='column'
              justify='center'
              alignItems='center'
            >
              <Grid container alignItems='center' direction='column'>
                <Button
                  fullWidth
                  height='55px'
                  borderRadius='28px'
                  padding='20px 20px'
                  variant='contained'
                  color='primary'
                  onClick={() => validateForm()}
                >
                  <Typography
                    fontWeight='700'
                    fontSize='15px'
                    color='white'
                    letterSpacing='0.5px'
                  >
                    Continuar
                  </Typography>
                </Button>
              </Grid>

              {/* <CustomButton
                fontSize='18px'
                margin='25px 0 0'
                onClick={() => changeStep(4)}
              >
                Saltar este paso
              </CustomButton> */}
            </FlexContainer>
          </FormContainer>
        </FlexContainer>
        <RightSide
          storeName={form.name}
          bgColor={form.store_color}
          selectedCurrency={selectCurrency}
          storeLogo={organizationLogo}
        />
      </Container>
      <Dialog
        fullWidth
        open={openCreate}
        maxWidth={dialogMaxWidth ? 'md' : 'sm'}
        onClose={() => {
          setOpenCreate(false);
          setDialogMaxWidth(true);
        }}
      >
        <FlexContainer
          container
          direction='column'
          padding='20px 40px'
          height='700px'
        >
          <CloseIcon
            onClick={() => {
              setOpenCreate(false);
              setDialogMaxWidth(true);
            }}
          >
            <Icon
              icon='cancel-icon'
              color={THEME.colors.placeholderColor}
              size={25}
            />
          </CloseIcon>
          <FlexContainer container direction='column'>
            <FlexContainer margin='25px 0 20px' alignItems='center'>
              <Typography
                fontSize={18}
                fontWeight={500}
                lineHeight='15px'
                margin='0 0 0 10px'
              >
                Recortar
              </Typography>
            </FlexContainer>
            <ColoredLine />
            <CropContainer>
              <Cropper
                image={imageSrc}
                crop={crop}
                aspect={5 / 3}
                zoom={zoom}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </CropContainer>
            <FlexContainer container justify='flex-end' margin='20px 0 0'>
              <Button
                variant='contained'
                color='primary'
                padding='5px 30px'
                onClick={() => showCroppedImage()}
              >
                Continuar
              </Button>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </Dialog>
    </ThridStepContainer>
  );
};

export default ThirdStep;
