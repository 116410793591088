import React, { useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { Avatar, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import {
  FlexContainer,
  Typography,
  Select,
  Icon,
  Button,
} from 'components/atoms';
import { Search } from 'components/molecules';
import THEME from 'util/styledTheme';
import { AssignBoxProps } from './types';

export const SearchOptionContainer = styled(FlexContainer)`
  top: 100%;
  left: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  border: 1px solid #ccc;
  overflow-y: auto;
`;

export const Option = styled(FlexContainer)`
  z-index: 100;
  background-color: #fff;
  border-radius: 5px;
  padding: 15px 20px;
  cursor: pointer;
  &:hover {
    background-color: ${props => props.theme.colors.borderColor};
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
`;

const AssignBox: React.FC<AssignBoxProps> = ({
  title,
  type,
  onSearch,
  onSelect,
  onClose,
  searchOptions,
  selectPurchaseOptions,
  selectOptions,
  statusOptions,
  onChangeAssignStatus,
  orderAssign,
  assignOption,
  onChangeOrderOption,
}) => {
  const node = useRef(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  useOnClickOutside(node, () => setOpen(false));

  useEffect(() => {
    if (searchOptions.length > 0) {
      setOpen(true);
    }
  }, [searchOptions]);

  return (
    <FlexContainer
      container
      position='relative'
      direction='column'
      backgroundColor={THEME.colors.borderColor}
      padding='20px'
      margin='0 0 20px 0'
      alignItems='center'
      justify='center'
    >
      <CloseIcon>
        <Icon icon='cancel-icon' size='20' onClick={() => onClose()} />
      </CloseIcon>

      {orderAssign && assignOption === 'status' ? (
        <>
          <Typography
            fontSize={13}
            textAlign='center'
            fontWeight={600}
            margin='0 0 20px 0'
          >
            Escoge que estado quieres cambiar
          </Typography>

          <RadioGroup
            aria-label='permissions'
            name='permissions'
            value={selectedStatus}
            onChange={({ target }) => {
              onChangeAssignStatus(+target.value);
              setSelectedStatus(target.value);
              onChangeOrderOption();
            }}
            row
          >
            {statusOptions.map(option => (
              <FormControlLabel
                key={option.code}
                value={option.id.toString()}
                control={
                  <Radio
                    color='primary'
                    size='small'
                    checked={option.id.toString() === selectedStatus}
                  />
                }
                label={<Typography fontSize={13}>{option.name}</Typography>}
              />
            ))}
          </RadioGroup>

          {selectedStatus !== '' && (
            <FlexContainer
              direction='column'
              margin='20px 0 0'
              alignItems='center'
            >
              {title && (
                <Typography
                  fontSize={13}
                  textAlign='center'
                  fontWeight={600}
                  margin='0 0 20px 0'
                >
                  {title}
                </Typography>
              )}

              <>
                {selectedStatus === '1' && (
                  <Select
                    placeholder='Selecciona una opción'
                    width='100%'
                    maxWidth='450px'
                    value={selectedOption}
                    options={selectOptions}
                    onChange={value => setSelectedOption(value)}
                  />
                )}
                {selectedStatus === '2' && (
                  <Select
                    placeholder='Selecciona una opción'
                    width='100%'
                    maxWidth='450px'
                    value={selectedOption}
                    options={selectPurchaseOptions}
                    onChange={value => setSelectedOption(value)}
                  />
                )}

                <Button
                  color='primary'
                  variant='contained'
                  margin='20px 0 0 0'
                  onClick={() => onSelect(selectedOption)}
                >
                  Aceptar
                </Button>
              </>
            </FlexContainer>
          )}
        </>
      ) : (
        <>
          {title && (
            <Typography
              fontSize={13}
              textAlign='center'
              fontWeight={600}
              margin='0 0 20px 0'
            >
              {title}
            </Typography>
          )}
          {type === 'search' && (
            <FlexContainer
              ref={node}
              container
              maxWidth='450px'
              direction='column'
              position='relative'
            >
              <Search
                onChangeValue={value => setSearchValue(value)}
                searchValue={searchValue}
                onSubmit={() => {
                  setOpen(false);
                  onSearch(searchValue);
                }}
              />
              {open && searchOptions.length > 0 && (
                <SearchOptionContainer
                  container
                  position='absolute'
                  maxHeight='400px'
                  direction='column'
                >
                  {searchOptions.map(option => (
                    <Option
                      key={option.id}
                      container
                      alignItems='center'
                      onClick={() => {
                        setOpen(false);
                        onSelect(option.id, option.plate);
                      }}
                    >
                      {option.logo && (
                        <FlexContainer width='100px'>
                          <img
                            src={option.logo}
                            alt='provider_logo'
                            style={{
                              maxWidth: 100,
                              height: 'auto',
                            }}
                          />
                        </FlexContainer>
                      )}
                      {option.photo && (
                        <Avatar
                          src={option.photo}
                          alt='worker_photo'
                          sizes='35'
                        />
                      )}
                      <FlexContainer
                        container
                        margin='0 0 0 20px'
                        justify='space-between'
                        alignItems='center'
                      >
                        <FlexContainer direction='column'>
                          <Typography>{option.name}</Typography>
                          {option.phone && (
                            <FlexContainer
                              alignItems='center'
                              margin='10px 0 0'
                            >
                              <Icon icon='phone' size='20' margin='0 5px 0 0' />
                              <Typography>{option.phone}</Typography>
                            </FlexContainer>
                          )}
                        </FlexContainer>
                        {option.plate && (
                          <FlexContainer
                            backgroundColor={THEME.colors.backgroundColor}
                            padding='3px 5px'
                          >
                            {`Placa: ${option.plate}`}
                          </FlexContainer>
                        )}
                      </FlexContainer>
                    </Option>
                  ))}
                </SearchOptionContainer>
              )}
            </FlexContainer>
          )}
          {type === 'select' && (
            <>
              <Select
                placeholder='Selecciona una opción'
                width='100%'
                maxWidth='450px'
                value={selectedOption}
                options={selectOptions}
                onChange={value => setSelectedOption(value)}
              />
              <Button
                color='primary'
                variant='contained'
                margin='20px 0 0 0'
                onClick={() => onSelect(selectedOption)}
              >
                Aceptar
              </Button>
            </>
          )}
        </>
      )}
    </FlexContainer>
  );
};

export default AssignBox;
