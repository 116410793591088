import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { Grid } from '@material-ui/core';
import {
  Button,
  Divider,
  FlexContainer,
  Icon,
  // Icon,
  Typography,
} from 'components/atoms';
import { FormControl, Spinner } from 'components/molecules';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import { postProvider, patchProvider } from 'services';
import DefaultImage from 'assets/images/DefaultProfile.png';
import { EditProps } from './types';

const CameraIcon = styled(FlexContainer)`
  bottom: 0;
  right: 0;
`;

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 15px;
`;

const validationState = {
  businessName: ['mandatory'],
  tradeName: ['mandatory'],
};

const EditProvider: React.FC<EditProps> = ({
  data,
  onClose,
  onEdit,
  onCreate,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const photoInput = useRef<HTMLInputElement>();
  const [form, onFormChange, validate, errors] = useForm(
    {
      businessName: data ? data.business_name : '',
      tradeName: data ? data.tradename : '',
      ruc: data && data.ruc ? data.ruc : '',
      phone: data && data.phone ? data.phone : '',
      phone_country: 'pe',
    },
    validationState
  );

  // Values
  const [newPhoto, setNewPhoto] = useState<boolean>(false);
  const [photo, setPhoto] = useState(data && data.logo);
  // API calls
  const [updatedProvider, updateProvider, loadingUpdate] = useRequest(
    patchProvider
  );

  const [
    createdProvider,
    createProvider,
    loadingCreate,
    ,
    errorCreate,
  ] = useRequest(postProvider);

  useEffect(() => {
    if (!isEmpty(createdProvider)) {
      enqueueSnackbar('El proveedor fue creado correctamente', {
        variant: 'success',
      });
      onClose();
      onCreate(createdProvider);
    }
  }, [createdProvider, enqueueSnackbar]);

  useEffect(() => {
    if (!isEmpty(updatedProvider)) {
      enqueueSnackbar('El proveedor ha sido actualizado correctamente', {
        variant: 'success',
      });
      onEdit(updatedProvider);
    }
  }, [updatedProvider, enqueueSnackbar]);

  useEffect(() => {
    if (!isEmpty(errorCreate)) {
      if (errorCreate.data.errors) {
        errorCreate.data.errors.forEach(error => {
          if (error.tradename && error.tradename[0]) {
            if (error.tradename[0] === 'This field may not be blank.') {
              enqueueSnackbar('Razón social es requerido', {
                variant: 'error',
              });
            }
          } else if (error.phone && error.phone[0]) {
            if (error.phone[0] === 'This field may not be blank.') {
              enqueueSnackbar('Télefono es requerido', {
                variant: 'error',
              });
            }
          }
        });
      }
    }
  }, [errorCreate, enqueueSnackbar]);

  const handleUploadFile = event => {
    if (event.target.files[0]) {
      setNewPhoto(true);
      setPhoto(URL.createObjectURL(event.target.files[0]));
    }
  };

  const validateForm = () => {
    if (validate()) {
      const formData = new FormData();
      if (newPhoto) {
        formData.append('logo', photoInput.current.files[0]);
      }
      formData.append('tradename', form.tradeName);
      formData.append('business_name', form.businessName);
      formData.append('ruc', form.ruc);
      formData.append('phone', form.phone);
      if (data) {
        updateProvider(formData, data.id);
      } else {
        formData.append('is_active', 'true');
        createProvider(formData);
      }
    }
  };

  return (
    <FlexContainer
      container
      minWidth='800px'
      position='relative'
      padding='40px 50px 50px'
      direction='column'
    >
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <Typography fontSize={17} margin='10px 0 0'>
        {data ? 'Perfil del Proveedor' : 'Proveedor'}
      </Typography>
      <Divider orientation='horizontal' margin='20px 0 30px' />

      <FlexContainer
        container
        direction='column'
        padding='30px 30px'
        margin='0 0 30px'
        borderRadius='10px'
        backgroundColor={THEME.colors.backgroundColor}
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer container alignItems='center' margin='0 0 30px'>
          <FlexContainer
            direction='column'
            alignItems='center'
            cursor='pointer'
            onClick={() => photoInput.current.click()}
          >
            <FlexContainer
              position='relative'
              borderRadius='100px'
              borderColor={THEME.colors.borderColor}
              padding='8px'
              margin='0 0 15px'
            >
              <img
                src={photo || DefaultImage}
                alt='user_image'
                height='100px'
                width='100px'
                style={{ borderRadius: '100px' }}
              />
              <CameraIcon
                position='absolute'
                backgroundColor={THEME.colors.primary}
                borderRadius='40px'
                padding='7px'
                justify='center'
                alignItems='center'
              >
                <Icon icon='camera-icon' size={20} />
              </CameraIcon>
            </FlexContainer>
            <input
              ref={photoInput}
              type='file'
              accept='image/*'
              onChange={handleUploadFile}
              style={{ display: 'none' }}
            />
          </FlexContainer>

          <Button
            variant='contained'
            color='secondary'
            fontSize='12px'
            margin='0 0 0 20px'
            onClick={() => photoInput.current.click()}
          >
            Agregar Foto
          </Button>
        </FlexContainer>

        <FlexContainer container direction='column' margin='0 0 30px'>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Nombre de la Empresa'
                placeholder='Escriba nombre comercial'
                onChange={value => onFormChange(value, 'businessName')}
                value={form.businessName}
                margin='0 0 20px'
                error={errors.businessName}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='RUC'
                placeholder='Escriba el ruc'
                onChange={value => onFormChange(value, 'ruc')}
                value={form.ruc}
                margin='0 0 20px'
                width='100%'
                disabled={loadingCreate || loadingUpdate}
                optional
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                label='Razón Social'
                placeholder='Escriba razón social'
                onChange={value => onFormChange(value, 'tradeName')}
                value={form.tradeName}
                margin='0 0 20px'
                error={errors.tradeName}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                control='phone'
                country={form.phone_country}
                label='Teléfono'
                placeholder='Escriba número de contacto'
                onChange={value => onFormChange(value, 'phone')}
                value={form.phone}
                margin='0 0 20px'
                error={errors.phone}
                width='100%'
                disabled={loadingCreate || loadingUpdate}
              />
            </Grid>
          </Grid>
        </FlexContainer>
      </FlexContainer>

      <Button
        variant='contained'
        color='primary'
        padding='5px 30px'
        onClick={() => validateForm()}
      >
        {(loadingCreate || loadingUpdate) && (
          <Spinner
            height='15px'
            spinnerSize={20}
            colorSecondary
            margin='0 8px 0 0'
          />
        )}
        {data ? 'Guardar' : 'Crear'}
      </Button>
    </FlexContainer>
  );
};

export default EditProvider;
