import React, { useState } from 'react';
import styled from 'styled-components';
import { FlexContainer, Icon, Typography } from 'components/atoms';
import { Dropdown, DropdownIconOption } from 'components/molecules';
import { Checkbox } from '@material-ui/core';
import useWindowSize from 'hooks/useWindowSize';
import { useTypedSelector } from 'store';
import THEME from 'util/styledTheme';
import { MenuProps, OptionProp } from './types';

const OptionContainer = styled(FlexContainer)`
  :hover {
    div,
    svg {
      color: ${props => props.theme.colors.primary};
    }
  }
`;

const options = [
  // Orders
  {
    label: 'Ver en mapa',
    icon: 'route-gray-icon',
    event: 'showMapOrder',
    mobile: true,
  },
  {
    label: 'Asignar a ruta',
    icon: 'reassign-icon',
    event: 'assignOrders',
    permission: 'core.route.list',
  },
  // Ruta + Tasks
  {
    label: 'Reasignar ruta',
    icon: 'reassign-icon',
    event: 'reassignTask',
  },
  // Tasks
  {
    label: 'Liberar',
    icon: 'free-icon',
    event: 'freeTask',
  },
  // Rutas
  {
    label: 'Ver detalle',
    icon: 'route-gray-icon',
    event: 'showDetail',
    mobile: true,
  },
  {
    label: 'Ver en mapa',
    icon: 'marker-gray-icon',
    event: 'showMapRoute',
    mobile: true,
  },
  // {
  //   label: 'Asignar Provider',
  //   icon: 'assign-prov-icon',
  //   event: 'providerAssign',
  // },
  {
    label: 'Asignar Worker',
    icon: 'assign-icon',
    event: 'workerAssign',
  },
  {
    label: 'Liberar ruta',
    icon: 'free-icon',
    event: 'freeRoute',
  },
  {
    label: 'Cambiar de estado',
    icon: 'change-icon',
    event: 'changeStatus',
  },
  // {
  //   label: 'Eliminar ruta',
  //   icon: 'trash-icon',
  //   event: 'deleteRoute',
  // },
  // Reordenar Tasks
  {
    label: 'Confirmar',
    icon: 'trash-icon',
    event: 'confirmReorder',
  },
  {
    label: 'Cancelar',
    icon: 'trash-icon',
    event: 'cancelReorder',
  },
  // Worker
  {
    label: 'Ver en mapa',
    icon: 'route-gray-icon',
    event: 'showMapWorker',
    mobile: true,
  },
];

const Option: React.FC<OptionProp> = ({ data }) => (
  <OptionContainer alignItems='center' margin='0 25px 0 0' cursor='pointer'>
    <Icon icon={data.icon} size={20} color={THEME.colors.textSecondary} />
    <Typography fontSize={12} color='text.secondary' margin='0 0 0 10px'>
      {data.label}
    </Typography>
  </OptionContainer>
);

const Menu: React.FC<MenuProps> = ({
  availableOptions,
  onClick,
  deselectAll,
  routeLength,
  orderLength,
  taskLength,
  workerLength,
  isPicking,
}) => {
  const size = useWindowSize();
  const user = useTypedSelector(store => store.user);
  const [activeOption, setActiveOption] = useState<string>('');
  return (
    <FlexContainer
      container
      height='100%'
      alignItems='center'
      justify={size.width > 768 ? 'flex-start' : 'space-between'}
    >
      <FlexContainer
        alignItems='center'
        margin={size.width > 768 ? '0 100px 0 0' : '0'}
      >
        <Checkbox
          checked
          disableRipple
          color='primary'
          inputProps={{ 'aria-label': 'primary checkbox' }}
          style={{ padding: 0 }}
          onClick={() => deselectAll()}
        />
        <Typography margin='0 0 0 10px' fontSize={12}>
          {routeLength > 0 && `${routeLength} ruta(s)`}
          {routeLength > 0 &&
            (taskLength > 0 || orderLength > 0 || workerLength > 0) &&
            ' y '}
          {taskLength > 0 && `${taskLength} tarea(s)`}
          {orderLength > 0 && `${orderLength} órden(es)`}
          {workerLength > 0 && `${workerLength} worker(s)`}
          &nbsp;selecionada(s)
        </Typography>
      </FlexContainer>
      <FlexContainer alignItems='center'>
        {size.width > 768 ? (
          <>
            {isPicking ? (
              <>
                <FlexContainer
                  onClick={() => {
                    onClick(activeOption);
                    setActiveOption('');
                  }}
                >
                  <Option
                    data={{
                      label: 'Confirmar',
                      icon: 'trash-icon',
                    }}
                  />
                </FlexContainer>
                <FlexContainer
                  onClick={() => {
                    onClick('cancelOption');
                    setActiveOption('');
                  }}
                >
                  <Option
                    data={{
                      label: 'Cancelar',
                      icon: 'trash-icon',
                    }}
                  />
                </FlexContainer>
              </>
            ) : (
              <>
                {options
                  .filter(
                    option =>
                      availableOptions.includes(option.event) && !option.mobile
                  )
                  .filter(
                    option =>
                      !option.permission ||
                      user.permissions.includes(option.permission)
                  )
                  .map(option => (
                    <FlexContainer
                      key={option.event}
                      onClick={() => {
                        setActiveOption(option.event);
                        onClick(option.event);
                      }}
                    >
                      <Option data={option} />
                    </FlexContainer>
                  ))}
              </>
            )}
          </>
        ) : (
          <Dropdown
            label={<Icon icon='options-icon' size={20} />}
            direction='top'
            position='right'
            showCaret={false}
            options={
              isPicking
                ? [
                    <DropdownIconOption
                      key='confirm'
                      label='Confirmar'
                      name='confirm'
                      icon='check-blue-icon'
                      onClick={() => {
                        onClick(activeOption);
                        setActiveOption('');
                      }}
                    />,
                    <DropdownIconOption
                      key='cancel'
                      label='Cancelar'
                      name='cancel'
                      icon='trash-icon'
                      onClick={() => {
                        onClick('cancelOption');
                        setActiveOption('');
                      }}
                    />,
                  ]
                : options
                    .filter(option => availableOptions.includes(option.event))
                    .map(option => (
                      <DropdownIconOption
                        key={option.event}
                        label={option.label}
                        name={option.event}
                        icon={option.icon}
                        onClick={() => {
                          setActiveOption(option.event);
                          onClick(option.event);
                        }}
                      />
                    ))
            }
            padding='0 10px'
            hover
          />
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Menu;
