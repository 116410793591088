import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FlexContainer, Typography, Button } from 'components/atoms';
import { Cell } from 'components/molecules';
import { DataTable } from 'components/organisms';
import THEME from 'util/styledTheme';
import { ConfirmProps } from './types';

const Container = styled(FlexContainer)`
  overflow: auto;
`;

const Confirm: React.FC<ConfirmProps> = ({ data, onNewMassive }) => {
  const [expandedData, setExpandedData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    setFormattedData(
      data.map(item => ({
        ...item,
        unexpandable: item.errors.length === 0,
      }))
    );
  }, [data]);

  const headers = useMemo(
    () => [
      {
        label: '',
        id: 'status',
        cell: row => {
          if (row.errors.length > 0) {
            return <Cell.Icon icon='cancel-icon' color={THEME.colors.error} />;
          }
          return <Cell.Icon icon='check-icon' />;
        },
      },
      {
        label: 'Tipo de tarea',
        id: 'work_type',
        cell: () => '',
      },
      {
        label: 'Cód.Ref..',
        id: 'reference_code',
        cell: row =>
          row.ORDER_reference_code ||
          row.reference_code ||
          'Sin cód. referencia',
      },
      {
        label: 'Dirección',
        id: 'address',
        cell: row => row.PU_address,
      },
      {
        label: 'Contacto',
        id: 'contact_name',
        cell: row => row.PU_contact_person,
      },
    ],
    []
  );

  const expandedHeaders = useMemo(
    () => [
      {
        colSpan: 5,
        label: 'Errores',
        id: 'errors_parse',
        cell: row => (
          <Typography
            fontSize={11}
            color={THEME.colors.error}
            textAlign='center'
          >
            {row}
          </Typography>
        ),
      },
    ],
    []
  );

  return (
    <Container container direction='column'>
      <Typography fontSize={15} margin='0 0 20px'>
        Parece que hay problemas con algunos datos:
      </Typography>
      <DataTable
        data={formattedData}
        dataIdentifier='ORDER_reference_code'
        headers={headers}
        expandedHeaders={expandedHeaders}
        expandedData={expandedData}
        onChangePage={() => {}}
        totalItems={0}
        totalPages={0}
        pageSize={0}
        page={1}
        showPagination={false}
        onExpand={index => {
          setExpandedData(data[index].errors);
        }}
        expandable
      />
      <FlexContainer container justify='flex-end' margin='20px 0 0'>
        <Button
          variant='contained'
          color='primary'
          fontSize='13px'
          padding='0 40px'
          onClick={() => onNewMassive()}
        >
          Volver a cargar
        </Button>
      </FlexContainer>
    </Container>
  );
};

export default Confirm;
