import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTypedSelector } from 'store';
import {
  FlexContainer,
  Typography,
  Divider,
  Button,
  Icon,
} from 'components/atoms';
import { Header } from 'components/organisms';
import evalPermission from 'util/evalPermission';
import Routes from './Routes';

const Tracking: React.FC = () => {
  const showMenu = useTypedSelector(state => state.sidebar);
  const user = useTypedSelector(state => state.user);
  return (
    <FlexContainer container height='100%'>
      <Header showMenu={showMenu} />
      <FlexContainer
        direction='column'
        container
        padding='50px 0 0'
        height='100vh'
      >
        <FlexContainer container direction='column' padding='35px 40px 0'>
          <FlexContainer
            container
            justify='space-between'
            alignItems='center'
            padding='0 0 20px'
          >
            <Typography fontSize={26} fontWeight={600} color='#000929'>
              Rutas
            </Typography>
            <FlexContainer>
              <Button
                variant='contained'
                color='primary'
                margin='0'
                padding='12px 20px'
                onClick={() =>
                  window.open('/monitoreo/?crear_ruta=true', '_self')
                }
              >
                <Icon icon='plus-icon' size={22} margin='0 5px 0 0' />
                Crear
              </Button>
            </FlexContainer>
          </FlexContainer>
          <Divider orientation='horizontal' type='solid' color='#E0DEF7' />
        </FlexContainer>
        <FlexContainer container height='100%'>
          <Switch>
            <Route
              exact
              path='/rutas'
              render={() =>
                evalPermission(user.permissions, 'core.route.list', Routes)
              }
            />
          </Switch>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Tracking;
