import React, { useState } from 'react';
// import styled from 'styled-components';
// import { omit } from 'lodash';
import { Checkbox } from '@material-ui/core';
import { FlexContainer, Icon, Typography } from 'components/atoms';
import useWindowSize from 'hooks/useWindowSize';
import THEME from 'util/styledTheme';
import { FleetCardProps } from './types';
import { CustomTooltip } from '../RouteBlock/RouteCard';

// const TextLink = styled(props => <Typography {...omit(props, ['isLink'])} />)<{
//   isLink: boolean;
// }>`
//   color: ${props => props.theme.colors.textSecondary};
//   ${props =>
//     props.isLink &&
//     `cursor: pointer;
//     &:hover {
//       color: ${props.theme.colors.primary};
//     }`}
// `;

const FleetCard: React.FC<FleetCardProps> = ({
  data,
  selected,
  toggleSelect,
  toggleVisibility,
  showWorker,
  // onRouteClick,
  // onOrderClick,
}) => {
  const size = useWindowSize();
  const [showCheckbox, setShowCheckbox] = useState<boolean>(false);
  return (
    <FlexContainer
      container
      padding='10px 20px 10px 10px'
      borderColor={THEME.colors.borderColor}
      direction='column'
      alignItems='flex-end'
      backgroundColor='#FFFFFF'
      onMouseEnter={() => setShowCheckbox(true)}
      onMouseLeave={() => setShowCheckbox(false)}
    >
      <FlexContainer container alignItems='center'>
        <FlexContainer width='10%'>
          {(showCheckbox || selected || size.width <= 768) && (
            <Checkbox
              checked={selected}
              disableRipple
              color='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
              style={{ padding: 0 }}
              onClick={e => e.stopPropagation()}
              onChange={e => toggleSelect(e.target.checked)}
            />
          )}
        </FlexContainer>
        <FlexContainer
          width='90%'
          alignItems='center'
          justify='space-between'
          height='24px'
        >
          <FlexContainer alignItems='center'>
            <CustomTooltip arrow interactive placement='top' title={data.phone}>
              <div style={{ display: 'inline-block' }}>
                <Icon icon='phone' size={15} />
              </div>
            </CustomTooltip>
            <Typography fontSize={12} fontWeight={600} margin='0 0 0 8px'>
              {`${data.first_name} ${data.last_name}`}
            </Typography>
          </FlexContainer>
          {size.width > 768 && (
            <FlexContainer
              onClick={e => {
                e.stopPropagation();
                toggleVisibility();
              }}
            >
              {showWorker ? (
                <Icon icon='show-icon' size={20} />
              ) : (
                <Icon icon='no-show-icon' size={20} />
              )}
            </FlexContainer>
          )}
        </FlexContainer>
      </FlexContainer>

      <FlexContainer width='90%' direction='column'>
        <FlexContainer container alignItems='center' justify='space-between'>
          {data.vehicle && (
            <FlexContainer alignItems='center'>
              <Typography
                color='text.secondary'
                fontSize={12}
                margin='0 0 0 5px'
              >
                {data.vehicle.vehicle_type &&
                  `${data.vehicle.vehicle_type.name}: `}
                {data.vehicle.plate}
              </Typography>
            </FlexContainer>
          )}

          {data.provider && (
            <FlexContainer
              backgroundColor={THEME.colors.borderColor}
              alignItems='center'
              justify='center'
              padding='2px 10px'
              borderRadius='5px'
            >
              <Typography color='text.secondary' fontSize='13px'>
                Flecha Veloz
              </Typography>
            </FlexContainer>
          )}
        </FlexContainer>
        {/* <FlexContainer container alignItems='baseline' margin='5px 0 0'>
          <FlexContainer
            width='7px'
            height='7px'
            borderRadius='20px'
            backgroundColor={THEME.colors.purpleBackground}
            margin='0 8px 0 0'
          />

          <TextLink
            fontSize={12}
            isLink={data.number_of_routes > 0}
            onClick={() => {
              if (data.number_of_routes > 0) {
                onRouteClick();
              }
            }}
          >
            {`${data.number_of_routes} rutas`}
          </TextLink>
          <Typography fontSize={12} color='text.secondary' margin='0 8px'>
            /
          </Typography>
          <TextLink
            fontSize={12}
            isLink={data.number_of_orders > 0}
            onClick={() => {
              if (data.number_of_orders > 0) {
                onOrderClick();
              }
            }}
          >
            {`${data.number_of_orders} órdenes`}
          </TextLink>
        </FlexContainer> */}
      </FlexContainer>
    </FlexContainer>
  );
};

export default FleetCard;
