import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Avatar, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useLocation } from 'react-router-dom';
import {
  Divider,
  FlexContainer,
  Icon,
  Typography,
  Status,
  Feedback,
  Button,
} from 'components/atoms';
import { DataTable } from 'components/organisms';
import moment from 'moment';
import DefaultImage from 'assets/images/DefaultProfile.png';
import { getOrder } from 'services';
import { isEmpty } from 'lodash';
import { orderDetailFormatter, itemDescriptionFormatter } from 'util/functions';
import THEME from 'util/styledTheme';
import useRequest from 'hooks/useRequest';
import statusColors from 'util/statusColors';
import { ReactComponent as Download } from 'assets/images/download.svg';
import { ReactComponent as ExternalLink } from 'assets/images/externalLink.svg';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import TaskCollapse from './TaskCollapse';
import {
  EditProps,
  WorkerSectionProps,
  OrderSectionProps,
  FeedbackSectionProps,
  OrderEventProps,
  ProviderSectionProps,
} from './types';

export const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;
`;

const StyledLink = styled.a`
  color: ${THEME.colors.primary};
  font-weight: 700;
  margin-left: 4px;
  font-size: 14px;
`;

const TasksOrderSkeleton: React.FC = () => (
  <FlexContainer height='400px' direction='column'>
    <FlexContainer
      justify='space-between'
      width='400px'
      direction='column'
      alignItems='center'
      height='200px'
    >
      <Skeleton variant='text' width='300px' height='100px' />
      <Skeleton variant='text' width='300px' height='100px' />
      <Skeleton variant='text' width='300px' height='100px' />
    </FlexContainer>
  </FlexContainer>
);

const OrderDetailSkeleton: React.FC = () => (
  <FlexContainer
    container
    direction='column'
    height='300px'
    justify='space-between'
  >
    <FlexContainer direction='column' justify='space-between' height='280px'>
      <FlexContainer width='1000px' justify='space-between'>
        <Skeleton variant='text' width='200px' height='50px' />
        <Skeleton variant='text' width='200px' height='50px' />
      </FlexContainer>
      <Divider orientation='horizontal' margin='15px 0 30px' />
    </FlexContainer>
    <FlexContainer justify='space-between'>
      <FlexContainer direction='column'>
        <FlexContainer width='300px' height='150px' justify='space-between'>
          <FlexContainer
            direction='column'
            height='100px'
            justify='space-between'
          >
            <Skeleton variant='text' width='200px' height='50px' />
            <FlexContainer width='200px' justify='space-between'>
              <Skeleton variant='text' width='80px' height='50px' />
              <Skeleton variant='text' width='80px' height='50px' />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer width='300px' height='150px' justify='space-between'>
          <FlexContainer
            direction='column'
            height='100px'
            justify='space-between'
          >
            <Skeleton variant='text' width='200px' height='50px' />
            <FlexContainer width='200px' justify='space-between'>
              <Skeleton variant='text' width='80px' height='50px' />
              <Skeleton variant='text' width='80px' height='50px' />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <TasksOrderSkeleton />
      <TasksOrderSkeleton />
    </FlexContainer>
  </FlexContainer>
);

const OrderSection: React.FC<OrderSectionProps> = ({
  colorStatus,
  labelStatus,
  adminName,
  adminEmail,
  orderType,
  trackingLink,
  isImmedate,
}) => (
  <FlexContainer
    container
    direction='column'
    margin='10px 0'
    borderRadius='10px'
    borderColor={THEME.colors.borderColor}
    backgroundColor={THEME.colors.backgroundColor}
  >
    <FlexContainer padding='15px' direction='column'>
      <Typography fontWeight={700}>Tipo de Orden</Typography>
      <FlexContainer alignItems='center' margin='10px 0'>
        <Typography>{orderType}</Typography>
        <Status color={colorStatus} label={labelStatus} margin='0 0 0 10px' />
      </FlexContainer>
      <Typography fontWeight={700} margin='0 0 5px'>
        Creado por
      </Typography>
      <Typography color={THEME.colors.textSecondary} margin='0 0 5px'>
        {adminName}
      </Typography>
      <Typography color={THEME.colors.textSecondary} margin='0 0 5px'>
        {adminEmail}
      </Typography>
      <Typography color='#808494' margin='0 0 5px'>
        {isImmedate ? 'Órden inmediata' : 'Órden programada'}
      </Typography>
      <FlexContainer container alignItems='center' margin='5px 0'>
        <Icon size={15} icon='marker-icon' />
        <StyledLink
          href={trackingLink}
          target='_blank'
          rel='noopener noreferrer'
        >
          Ver Tracking
        </StyledLink>
      </FlexContainer>
    </FlexContainer>
  </FlexContainer>
);

const WorkerSection: React.FC<WorkerSectionProps> = ({
  workerPhoto,
  workerName,
  workerNumber,
  vehiclePlate,
  vehicleType,
}) => (
  <FlexContainer
    container
    margin='10px 0'
    borderRadius='10px'
    borderColor={THEME.colors.borderColor}
    backgroundColor={THEME.colors.backgroundColor}
  >
    <FlexContainer padding='15px' direction='column'>
      <Typography fontWeight={700}>Conductor</Typography>
      <FlexContainer margin='10px' alignItems='center'>
        <Avatar alt='worker_photo' src={workerPhoto || DefaultImage} />
        <Typography margin='0 0 0 10px' fontWeight='400'>
          {workerName || 'Sin Asignar'}
        </Typography>
      </FlexContainer>
      {vehicleType && (
        <Typography color={THEME.colors.textSecondary} margin='0 0 5px'>
          {`Tipo de vehículo: ${vehicleType}`}
        </Typography>
      )}
      {vehiclePlate && vehiclePlate !== '-' && (
        <Typography color={THEME.colors.textSecondary} margin='0 0 5px'>
          {`Placa:  ${vehiclePlate || 'Sin Asignar'}`}
        </Typography>
      )}
      {workerNumber && workerNumber !== '-' && (
        <Typography color={THEME.colors.textSecondary} margin='0 0 5px'>
          {`Celular: ${workerNumber || 'Sin Asignar'}`}
        </Typography>
      )}
    </FlexContainer>
  </FlexContainer>
);

const ProviderSection: React.FC<ProviderSectionProps> = ({
  tradename,
  externalCode,
  deliveryPrice,
  deliveryCurrency,
  providerPdf,
  providerTrackLink,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1300);
  };

  return (
    <FlexContainer
      container
      margin='10px 0'
      borderRadius='10px'
      borderColor={THEME.colors.borderColor}
      backgroundColor={THEME.colors.backgroundColor}
    >
      <FlexContainer padding='15px' direction='column'>
        <Typography fontWeight={700} margin='10px 0'>
          Proveedor
        </Typography>
        <FlexContainer container>
          <Typography color={THEME.colors.textSecondary} margin='0 5px 5px 0'>
            {tradename || 'Sin Asignar'}
          </Typography>

          {tradename && tradename.startsWith('Olva') && (
            <StyledLink
              href='https://www.olvacourier.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <ExternalLink />
            </StyledLink>
          )}
        </FlexContainer>

        {externalCode && (
          <FlexContainer alignItems='center' margin='0 0 5px'>
            <Typography color={THEME.colors.textSecondary} margin='0 5px 0 0'>
              {`Código: ${externalCode}`}
            </Typography>
            <CopyToClipboard text={externalCode} onCopy={copyToClipboard}>
              <Button margin='0' padding='0 10px' height='25px'>
                <CopyIcon />
              </Button>
            </CopyToClipboard>
            {isCopied && (
              <Typography
                color={THEME.colors.textSecondary}
                margin='0 5px'
                fontSize={12}
              >
                Copiado!
              </Typography>
            )}
          </FlexContainer>
        )}

        {tradename && (
          <Typography color={THEME.colors.textSecondary} margin='0 0 5px'>
            {`Precio de envío: ${getSymbolFromCurrency(
              deliveryCurrency
            )}${deliveryPrice || '-'}`}
          </Typography>
        )}

        {providerPdf && (
          <FlexContainer container alignItems='center' margin='5px 0'>
            <Download />
            <StyledLink
              href={providerPdf}
              target='_blank'
              rel='noopener noreferrer'
            >
              Descargar Guía
            </StyledLink>
          </FlexContainer>
        )}
        {providerTrackLink && tradename && !tradename.startsWith('Cabify') && (
          <FlexContainer container alignItems='center' margin='5px 0'>
            <ExternalLink />
            <StyledLink
              href={providerTrackLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              {`Ir al tracking de ${tradename}`}
            </StyledLink>
          </FlexContainer>
        )}
        {providerTrackLink &&
          externalCode &&
          tradename &&
          tradename.startsWith('Cabify') && (
            <FlexContainer container alignItems='center' margin='5px 0'>
              <ExternalLink />
              <StyledLink
                href={`${providerTrackLink}app/journeys/${externalCode}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                {`Ir al tracking de ${tradename}`}
              </StyledLink>
            </FlexContainer>
          )}
      </FlexContainer>
    </FlexContainer>
  );
};

const FeedbackSection: React.FC<FeedbackSectionProps> = ({
  deliveryFeedback,
  productFeedback,
  feedbackComment,
}) => (
  <FlexContainer
    container
    margin='10px 0'
    borderRadius='10px'
    borderColor={THEME.colors.borderColor}
    backgroundColor={THEME.colors.backgroundColor}
  >
    <FlexContainer padding='15px' direction='column'>
      <Typography fontWeight={700}>Feedback</Typography>
      <Typography margin='10px 10px 10px 0' color={THEME.colors.textSecondary}>
        Feedback del Delivery
      </Typography>
      <Feedback feedbackText={productFeedback} />
      <Typography margin='10px 10px 10px 0' color={THEME.colors.textSecondary}>
        Satisfacción del producto
      </Typography>
      <Feedback feedbackText={deliveryFeedback} />
      <Typography margin='10px 10px 10px 0' color={THEME.colors.textSecondary}>
        Feedback del Delivery
      </Typography>
      <Typography>{feedbackComment}</Typography>
    </FlexContainer>
  </FlexContainer>
);

const OrderEvent: React.FC<OrderEventProps> = ({
  orderIndex,
  createdAt,
  userName,
  eventName,
  userType,
}) => (
  <FlexContainer container backgroundColor={THEME.colors.backgroundColor}>
    <FlexContainer direction='column' alignItems='center'>
      <FlexContainer
        height='25px'
        width='25px'
        borderRadius='50px'
        alignItems='center'
        justify='center'
        backgroundColor={THEME.colors.borderColor}
      >
        {orderIndex}
      </FlexContainer>
      <FlexContainer borderColor={THEME.colors.borderColor} height='80px' />
    </FlexContainer>
    <FlexContainer direction='column' margin='0 10px'>
      <Typography fontWeight={700}>{eventName}</Typography>
      <Typography color={THEME.colors.textSecondary}>{createdAt}</Typography>
      <Typography color={THEME.colors.textSecondary}>
        {`${userName} / ${userType}`}
      </Typography>
    </FlexContainer>
  </FlexContainer>
);

const EditOrder: React.FC<EditProps> = ({ data, onClose, routeCode }) => {
  const location = useLocation();

  // API calls
  const [orderDetails, fetchOrderDetails, orderDetailsLoading] = useRequest(
    getOrder
  );
  // Values
  const [orderDataDetail, setOrderDataDetail] = useState([]);
  const [taskOrderPoint, setTaskOrderPoint] = useState<any>([]);
  const [itemList, setItemList] = useState([]);
  const [deliveryId, setDeliveryId] = useState(null);
  const [referenceCode, setReferenceCode] = useState('');
  const [route, setRoute] = useState('');
  const [batch, setBatch] = useState('');

  useEffect(() => {
    if (location.pathname === '/rutas') {
      fetchOrderDetails(data.order.id);

      if (data.order.reference_code) {
        setReferenceCode(data.order.reference_code);
      } else {
        setReferenceCode('');
      }

      if (data.order.batch) {
        setBatch(data.order.batch);
      } else {
        setBatch('');
      }

      setRoute(routeCode);
    } else {
      fetchOrderDetails(data.id);

      if (data.reference_code) {
        setReferenceCode(data.reference_code);
      } else {
        setReferenceCode('');
      }

      if (data.route && data.route.code) {
        setRoute(data.route.code);
      } else {
        setRoute('');
      }

      if (data.batch !== '') {
        setBatch(data.batch);
      } else {
        setBatch('');
      }
    }
  }, [fetchOrderDetails, data.id]);

  useEffect(() => {
    if (!isEmpty(orderDetails)) {
      setOrderDataDetail(orderDetails.order_points);

      if (!isEmpty(orderDetails.purchase_order)) {
        const items = itemDescriptionFormatter([orderDetails.purchase_order]);
        setItemList(items[0]);
      }
    }
  }, [orderDetails]);

  useEffect(() => {
    setTaskOrderPoint(orderDetailFormatter(orderDataDetail));
  }, [orderDataDetail]);

  useEffect(() => {
    if (!isEmpty(taskOrderPoint)) {
      const taskDelivery = taskOrderPoint.filter(
        task => task.task_name === 'Entrega'
      );
      setDeliveryId(taskDelivery[0].id);
    }
  }, [taskOrderPoint]);

  const headers = useMemo(
    () => [
      {
        label: 'Código',
        id: 'code',
        cell: row => row.code,
      },
      {
        label: 'Descripción',
        id: 'description',
        cell: row => row.description,
      },
      {
        label: 'Medidas',
        id: 'measures',
        cell: row => `${row.height}x${row.width}cm`,
      },
      {
        label: 'Peso',
        id: 'weight',
        cell: row => `${row.weight}kg`,
      },
      {
        label: 'Cantidad',
        id: 'quantity',
        cell: row => `${row.quantity}`,
      },
      {
        label: 'Precio',
        id: 'price',
        cell: row => `S/.${row.price ? row.price : '0'}`,
      },
    ],
    [itemList]
  );

  return (
    <FlexContainer
      width='1200px'
      position='relative'
      padding='20px 50px 50px'
      direction='column'
    >
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      {orderDetailsLoading ? (
        <FlexContainer justify='space-between'>
          <OrderDetailSkeleton />
        </FlexContainer>
      ) : (
        <>
          <FlexContainer container justify='space-between'>
            <FlexContainer alignItems='center'>
              <Typography fontSize={18} padding='0 5px'>
                Orden
              </Typography>
              <FlexContainer
                padding='5px'
                borderRadius='5px'
                backgroundColor={THEME.colors.borderColor}
                justify='center'
                width='fit-content'
              >
                <Typography fontWeight={600} color='text.secondary'>
                  {referenceCode}
                </Typography>
              </FlexContainer>
              {route && (
                <FlexContainer
                  padding='5px'
                  justify='center'
                  width='fit-content'
                >
                  <Typography
                    fontWeight={600}
                    color='text.secondary'
                    padding='0 5px'
                  >
                    Ruta:
                  </Typography>
                  <Typography fontWeight={600} color='text.secondary'>
                    {route}
                  </Typography>
                </FlexContainer>
              )}

              {batch && (
                <FlexContainer
                  padding='5px'
                  justify='center'
                  width='fit-content'
                >
                  <Typography
                    fontWeight={600}
                    color='text.secondary'
                    padding='0 5px'
                  >
                    Batch:
                  </Typography>
                  <Typography color='text.secondary'>{batch}</Typography>
                </FlexContainer>
              )}
            </FlexContainer>
            <FlexContainer alignItems='center'>
              <Icon size={24} icon='calendar-icon' />
              <Typography fontWeight='300'>
                {moment(data.created_at).format('dddd, LL')}
              </Typography>
            </FlexContainer>
          </FlexContainer>
          <Divider orientation='horizontal' margin='15px 0 30px' />
          <FlexContainer container justify='space-between'>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <FlexContainer container direction='column'>
                  <OrderSection
                    colorStatus={
                      statusColors[orderDetails.last_status] ||
                      statusColors.default
                    }
                    labelStatus={orderDetails.last_status_label}
                    adminName={orderDetails.administrator_name}
                    adminEmail={orderDetails.administrator_email}
                    orderType={
                      orderDetails.order_type_code &&
                      orderDetails.order_type_code.charAt(0).toUpperCase() +
                        orderDetails.order_type_code.slice(1)
                    }
                    trackingLink={orderDetails.tracking_link}
                    isImmedate={orderDetails.is_immediate}
                  />
                  {orderDetails.last_status === 'completed' &&
                  !orderDetails.worker &&
                  !orderDetails.worker_name ? null : (
                    <WorkerSection
                      workerPhoto={orderDetails.worker_photo || DefaultImage}
                      workerName={orderDetails.worker_name}
                      vehiclePlate={orderDetails.vehicle_plate}
                      workerNumber={orderDetails.worker_phone}
                      vehicleType={orderDetails.vehicle_type_name}
                    />
                  )}

                  {orderDetails.last_status === 'completed' &&
                  !orderDetails.provider &&
                  !orderDetails.provider_name ? null : (
                    <ProviderSection
                      tradename={orderDetails.provider_name}
                      externalCode={
                        orderDetails.external_provider_reference_code
                      }
                      deliveryPrice={orderDetails.delivery_price}
                      deliveryCurrency={orderDetails.delivery_price_currency}
                      providerPdf={orderDetails.external_provider_file}
                      providerTrackLink={
                        orderDetails.external_provider_tracking_link
                      }
                    />
                  )}

                  {orderDetails.delivery_feedback && (
                    <FeedbackSection
                      deliveryFeedback={orderDetails.delivery_feedback}
                      productFeedback={orderDetails.product_feedback}
                      feedbackComment={orderDetails.feedback_comment}
                    />
                  )}
                </FlexContainer>
              </Grid>
              <Grid item xs={6}>
                <FlexContainer container>
                  <FlexContainer container direction='column' margin='10px'>
                    {taskOrderPoint.map(task => (
                      <TaskCollapse
                        key={task.id}
                        taskOrderPoint={taskOrderPoint}
                        orderDataDetail={orderDataDetail}
                        taskId={task.id}
                        deliveryId={deliveryId}
                        externalProviderPhotos={
                          orderDetails.external_provider_evidence_photos
                        }
                      />
                    ))}
                    <FlexContainer container direction='column'>
                      <Typography fontWeight={700}>
                        Detalle de entrega
                      </Typography>
                      <FlexContainer container direction='column'>
                        {itemList && itemList.length !== 0 ? (
                          <DataTable
                            headers={headers.filter(header => header)}
                            data={itemList}
                            onChangePage={() => {}}
                            totalItems={0}
                            totalPages={0}
                            pageSize={0}
                            page={1}
                            showPagination={false}
                          />
                        ) : (
                          <Typography
                            color={THEME.colors.textSecondary}
                            margin='10px 0 5px'
                          >
                            Sin paquetes
                          </Typography>
                        )}
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
              </Grid>
              <Grid item xs={3}>
                <FlexContainer
                  container
                  backgroundColor={THEME.colors.backgroundColor}
                  borderColor={THEME.colors.borderColor}
                  borderRadius='10px'
                  margin='5px'
                >
                  <FlexContainer direction='column' padding='15px'>
                    <Typography fontWeight={700} margin='15px 0'>
                      Historial
                    </Typography>
                    {orderDetails.events &&
                      orderDetails.events.map((event, index) => (
                        <OrderEvent
                          key={event.label}
                          orderIndex={index + 1}
                          createdAt={moment(event.timestamp).format(
                            'dddd ll, h:mm a'
                          )}
                          userName={event.triggered_by_name}
                          eventName={event.label}
                          userType={event.user_type}
                        />
                      ))}
                  </FlexContainer>
                </FlexContainer>
              </Grid>
            </Grid>
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );
};

export default EditOrder;
