import React from 'react';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import { PhoneProps } from './types';

const Wrapper = styled.div`
  width: 100%;
  .special-label {
    display: none;
  }
  .react-tel-input {
    input {
      width: 100%;
      height: 35px;
      padding: 0 14px 0 58px;
      font-size: 13px;
      max-width: 100%;
      border: 1px solid ${props => props.theme.colors.borderColor};
      &::placeholder {
        color: ${props => props.theme.colors.placeholderColor};
        opacity: 1;
      }
      &:hover {
        border-color: ${props => props.theme.colors.borderColor};
      }
      &:focus {
        border-width: 1px;
        box-shadow: none;
        border-color: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const Phone: React.FC<PhoneProps> = ({
  value,
  placeholder,
  onChange,
  country,
  disabled = false,
}) => (
  <Wrapper>
    <PhoneInput
      inputStyle={{ background: `${disabled ? '#EFEFF5' : '#FFFFFF'}` }}
      countryCodeEditable={false}
      placeholder={placeholder}
      onChange={(phone, countryData, e, formatted) =>
        onChange(phone, formatted)
      }
      value={value}
      preferredCountries={['pe', 'mx']}
      country={country}
      disabled={disabled}
    />
  </Wrapper>
);

export default Phone;
