import styled from 'styled-components';
import { DividerProps } from './types';

const Divider = styled.div<DividerProps>`
  ${props => {
    switch (props.orientation) {
      case 'vertical':
        return `
          border-right: 1px ${props.type || 'solid'};
          height: 100%;
        `;
      case 'horizontal':
        return `
          border-bottom: 1px ${props.type || 'solid'};
          width: 100%;
        `;
      default:
        return '';
    }
  }}
  margin: ${props => props.margin || 0};
  border-color: ${props => props.color || props.theme.colors.borderColor};
`;

export default Divider;
