import axios from '../util/axios';
import { ParamType } from './types';

export const login = data => {
  return axios.post('/authz/login', {
    ...data,
    client_id: 'TQ5hgDTojWeO1GQK4bT2Te0Hu0uW69',
  });
};

// $ORGANIZATIONS

export const createOrganization = data => {
  return axios.post('/core/organizations/', data);
};

export const getOrganization = (id: string) => {
  return axios.get(`/core/organizations/${id}/`);
};

export const patchOrganization = (data, id: string) => {
  return axios.patch(`/core/organizations/${id}`, data);
};

export const chooseOrganization = (params?: ParamType) => {
  return axios.get('/core/organizations/', {
    params,
  });
};

// $ADMINS

export const getProfile = () => {
  return axios.get('/core/administrators/profile/');
};

export const getAdmins = (params: ParamType) => {
  return axios.get('/core/administrators/', {
    params,
  });
};

export const getAdmin = (id: string) => {
  return axios.get(`/core/administrators/${id}/`);
};

export const postAdmin = data => {
  return axios.post('/core/administrators/', data);
};

export const patchAdmin = (data, id: string) => {
  return axios.patch(`/core/administrators/${id}/`, data);
};

export const deleteAdmin = id => {
  return axios.delete(`/core/administrators/${id}/`);
};

// $ORDERS

export const getOrders = (params: ParamType = {}) => {
  return axios.get('/core/orders?omit=events,tasks', {
    params,
  });
};

export const getOrderTypes = params => {
  return axios.get('/core/order_types/', {
    params,
  });
};

export const getCategories = (params?: ParamType) => {
  return axios.get('/core/order-category-list/', {
    params,
  });
};

export const assignOrderWorkerVehicle = data => {
  return axios.post('core/orders/assign_worker/', data);
};

export const assignOrderProvider = data => {
  return axios.patch('/core/orders/assign_provider/', data);
};

export const changeOrderStatus = data => {
  return axios.patch('/core/orders/update_status/', data);
};

export const releaseOrders = data => {
  return axios.patch('/core/orders/release', data);
};

export const getOrder = (id: string) => {
  return axios.get(`/core/orders/${id}/`);
};

export const postOrder = data => {
  return axios.post('/core/orders/', data);
};

export const patchOrder = (data, id: string) => {
  return axios.patch(`/core/orders/${id}/`, data);
};

export const releaseOrdersRoute = data => {
  return axios.patch('/core/orders/release', data);
};

export const confirmOrdersProvider = data => {
  return axios.patch('/core/orders/confirm_provider', data);
};

export const downloadShippingLabels = data => {
  return axios.post('/core/orders/shipping_documents', {
    order_ids: data,
  });
};

// $PURCHASE ORDER
export const getPurchaseOrders = (params: ParamType = {}) => {
  return axios.get('/core/purchase_orders', {
    params,
  });
};

export const getPurchaseOrder = (id: string) => {
  return axios.get(`/core/purchase_orders/${id}/`);
};

export const changePurchaseOrderStatus = (data, id) => {
  return axios.patch(`/core/purchase_orders/${id}/`, data);
};

// $ROUTES

export const getRoutes = (params: ParamType = {}) => {
  return axios.get('/core/routes/', {
    params,
  });
};

export const createRoutes = data => {
  return axios.post('/core/routes/', data);
};

export const getRoute = (id: string) => {
  return axios.get(`/core/routes/${id}`);
};

export const assignRouteWorkerVehicle = data => {
  return axios.patch('/core/routes/assign_worker', data);
};

export const assignRouteProvider = data => {
  return axios.patch('/core/routes/assign_provider', data);
};

export const changeRouteStatus = data => {
  return axios.patch('/core/routes/update_status', data);
};

export const releaseRoutes = data => {
  return axios.patch('/core/routes/release_worker', data);
};

export const getRouteConfig = () => {
  return axios.get('/core/routes/configuration/');
};

export const updateRouteConfig = data => {
  return axios.patch('/core/routes/configuration/', data);
};

export const getRouteOrigins = (params: ParamType = {}) => {
  return axios.get('/core/routes/origins/', {
    params,
  });
};

export const getOrdersToSelect = (params: ParamType = {}) => {
  return axios.get('/core/routes/orders/', {
    params,
  });
};

export const getRoutesVrp = data => {
  return axios.post('/routing/vrp', data);
};

export const getJobConsult = (id: string) => {
  return axios.get(`/routing/jobs/${id}`);
};

export const getRoutesPreview = data => {
  return axios.post('/core/routes/preview-v2/', data);
};

export const updateRoutesPreview = data => {
  return axios.post('/core/routes/preview-v2/update/', data);
};

export const patchRouteTasks = (data, id) => {
  return axios.patch(`/core/routes/${id}/tasks/`, data);
};

// $CLIENTS

export const getClients = (params: ParamType = {}) => {
  return axios.get('/core/clients', {
    params,
  });
};

export const postClient = data => {
  return axios.post('/core/clients', data);
};

export const getClient = (id: string) => {
  return axios.get(`/core/clients/${id}`);
};

export const patchClient = (data, id) => {
  return axios.patch(`/core/clients/${id}`, data);
};

export const getSearchClient = search => {
  return axios.get(`/core/clients?search=${search}`);
};

// $STATUS

export const getStatuses = (params: ParamType = {}) => {
  return axios.get('/core/statuses/', {
    params,
  });
};

// $WORKERS

export const getWorkers = (params?: ParamType) => {
  return axios.get('/core/workers/', {
    params,
  });
};

export const getWorker = (id: string) => {
  return axios.get(`/core/workers/${id}/`);
};

export const postWorkers = data => {
  return axios.post('/core/workers/', data);
};

export const patchWorker = (data, id) => {
  return axios.patch(`/core/workers/${id}/`, data);
};

export const deleteWorker = id => {
  return axios.delete(`/core/workers/${id}/`);
};

// $PROVIDERS

export const getProviders = (params?: ParamType) => {
  return axios.get('/core/providers/', {
    params,
  });
};

export const getProvidersMinimal = (params?: ParamType) => {
  return axios.get('/core/providers/minimal/', {
    params,
  });
};

export const postProvider = data => {
  return axios.post('/core/providers/', data);
};

export const patchProvider = (data, id) => {
  return axios.patch(`/core/providers/${id}/`, data);
};

// $VEHICLES

export const getVehiclesList = (params?: ParamType) => {
  return axios.get('/core/vehicles/', {
    params,
  });
};

export const getVehiclesTypeList = (params?: ParamType) => {
  return axios.get('core/vehicle_types/', {
    params,
  });
};

export const patchVehicles = (data, id) => {
  return axios.patch(`/core/vehicles/${id}/`, data);
};

export const postVehicles = data => {
  return axios.post('core/vehicles/', data);
};

export const getVehiclesForOrders = (params?: ParamType) => {
  return axios.get('/core/vehicles/for-orders/', {
    params,
  });
};

export const getVehiclesForRoutes = (params?: ParamType) => {
  return axios.get('/core/vehicles?is_active=true&worker_is_active=true', {
    params,
  });
};

export const assignProviderVehicles = data => {
  return axios.post('/core/vehicles/assign_provider/', data);
};

export const getWorkersForVehicles = (params?: ParamType) => {
  return axios.get('/core/workers/minimal/', {
    params,
  });
};

// $ROLES
export const getRoles = (params?: ParamType) => {
  return axios.get('/core/roles/', {
    params,
  });
};

// $BRANCH_OFFICE
export const getBranchOffices = (params?: ParamType) => {
  return axios.get('/core/branch_offices/', {
    params,
  });
};

export const getBranchOffice = (id: string) => {
  return axios.get(`/core/branch_offices/${id}/`);
};

export const postBranchOffice = data => {
  return axios.post('/core/branch_offices/', data);
};

export const patchBranchOffice = (data, id) => {
  return axios.patch(`/core/branch_offices/${id}/`, data);
};

// $CITIES

export const getCities = (params?: ParamType) => {
  return axios.get('/core/cities/', {
    params,
  });
};

export const getDistricts = (params?: ParamType) => {
  return axios.get('/core/districts/', {
    params,
  });
};

// $VEHICLE_TYPES

export const getVehicleTypes = (params?: ParamType) => {
  return axios.get('/core/vehicle_types/', {
    params,
  });
};

export const postVehicleTypes = data => {
  return axios.post('/core/vehicle_types/', data);
};

export const patchVehicleTypes = (data, id) => {
  return axios.patch(`/core/vehicle_types/${id}/`, data);
};

// $ORDER_PROTOCOL

export const getOrderProtocols = (params?: ParamType) => {
  return axios.get('/core/order_protocols/', {
    params,
  });
};

export const getOrderProtocol = id => {
  return axios.get(`/core/order_protocols/${id}/`);
};

export const postOrderProtocol = data => {
  return axios.post('/core/order_protocols/', data);
};

export const patchOrderProtocol = (data, id) => {
  return axios.patch(`/core/order_protocols/${id}/`, data);
};

// $TRACKING_PROTOCOL

export const getTrackings = (params?: ParamType) => {
  return axios.get('/core/tracking_configurations/', {
    params,
  });
};

export const getTracking = id => {
  return axios.get(`/core/tracking_configurations/${id}/`);
};

export const postTracking = data => {
  return axios.post('/core/tracking_configurations/', data);
};

export const patchTracking = (data, id) => {
  return axios.patch(`/core/tracking_configurations/${id}/`, data);
};

// $PACKAGE_TYPES

export const getPackageTypes = (params?: ParamType) => {
  return axios.get('/core/package_types/', {
    params,
  });
};

export const postPackageTypes = data => {
  return axios.post('/core/package_types/', data);
};

export const patchPackageTypes = (data, id) => {
  return axios.patch(`/core/package_types/${id}/`, data);
};

// $ACCESS_TOKENS

export const getTokens = (params?: ParamType) => {
  return axios.get('/authz/access_tokens/', {
    params,
  });
};

export const postTokens = data => {
  return axios.post('/authz/access_tokens/', data);
};

export const deleteTokens = id => {
  return axios.delete(`/authz/access_tokens/${id}/`);
};

// $TAGS

export const getVehicleTags = (params?: ParamType) => {
  return axios.get('/core/vehicle_type_tags/', {
    params,
  });
};

// $ CATEGORIES

export const getVehicleCategories = (params?: ParamType) => {
  return axios.get('/core/vehicle_categories/', {
    params,
  });
};

// $ WEBHOOKS

export const getWebHooksEvents = (params?: ParamType) => {
  return axios.get('/core/event_names', {
    params,
  });
};

// $ COVERAGES

export const getCoverage = id => {
  return axios.patch(`/core/coverages/${id}/`);
};

export const getCoverages = (params?: ParamType) => {
  return axios.get('/core/coverages/', {
    params,
  });
};

export const patchCoverages = (data, id) => {
  return axios.patch(`/core/coverages/${id}/`, data);
};

export const postCoverages = data => {
  return axios.post('/core/coverages/', data);
};

// $ TIME_WINDOWS

export const getTimeWindow = id => {
  return axios.get(`/core/time_windows/${id}/`);
};

export const getTimeWindows = (params?: ParamType) => {
  return axios.get('/core/time_windows/', {
    params,
  });
};

export const patchTimeWindows = (data, id) => {
  return axios.patch(`/core/time_windows/${id}/`, data);
};

export const postTimeWindows = data => {
  return axios.post('/core/time_windows/', data);
};

// $ REPORTS

export const getReports = (params?: ParamType) => {
  return axios.get('/analytics/report', {
    params,
  });
};

export const postReports = data => {
  return axios.post('/analytics/report/create', data);
};

// $???

export const excelParse = data => {
  return axios.post('/core/orders/batch_xls/', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const excelParsePurchaseOrder = data => {
  return axios.post('/inventory/orders/batchXlsPurchaseOrder', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const excelParseProducts = data => {
  return axios.post('/inventory/stocks/batchXlsStocksUpdate', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getVehicles = () => {
  return axios.get('/core/vehicle_types/');
};

export const getPackingTypes = () => {
  return axios.get('/core/packing-types/packing/');
};

export const getMeasurementUnits = () => {
  return axios.get('/core/packing-types/criteria/');
};

export const getIframeContent = () => {
  return axios.get('/core/metrics/dashboard');
};

// $ROUTES TYPES

export const getRouteTypes = (params?: ParamType) => {
  return axios.get('/core/route_types/', {
    params,
  });
};

export const getRouteType = (id: string) => {
  return axios.get(`/core/route_types/${id}`);
};

export const patchRoutesTypes = (data, id) => {
  return axios.patch(`/core/route_types/${id}`, data);
};

// $JOBS

export const getMassiveCharge = (id: string) => {
  return axios.get(`/core/jobs/${id}/`);
};

export const getReportJob = (id: string) => {
  return axios.get(`/analytics/jobs/${id}`);
};

// $METRICS

export const getOrderByStatus = (params: ParamType) => {
  return axios.get('/analytics/metric/orders_by_status', {
    params,
  });
};

export const getOrdersGeneral = (params: ParamType) => {
  return axios.get('/analytics/metric/orders', {
    params,
  });
};

export const getFailedOrders = (params: ParamType) => {
  return axios.get('/analytics/metric/orders_by_failed_motive', {
    params,
  });
};

export const getOrderByType = (params: ParamType) => {
  return axios.get('/analytics/metric/orders_by_type', {
    params,
  });
};

export const getOrderByProvider = (params: ParamType) => {
  return axios.get('/analytics/metric/orders_by_provider', {
    params,
  });
};

export const getOrderByWorker = (params: ParamType) => {
  return axios.get('/analytics/metric/orders_by_worker', {
    params,
  });
};

export const getHistorical = (params: ParamType) => {
  return axios.get('/analytics/metric/orders_by_range', {
    params,
  });
};

export const getHeatMap = (params: ParamType) => {
  return axios.get('/analytics/metric/heatmap', {
    params,
  });
};

export const getOrganizationFeedback = (params: ParamType) => {
  return axios.get('analytics/metric/feedback_by_organization', {
    params,
  });
};

// $DASHBOARD

export const getDashboardWorkers = (params: ParamType, date: string) => {
  return axios.get(`/core/plans/${date}/workers`, {
    params,
  });
};

export const getDashboardRoutes = (params: ParamType, date: string) => {
  return axios.get(`/core/plans/${date}/routes`, {
    params,
  });
};

export const getDashboardOrders = (params: ParamType, date: string) => {
  return axios.get(`/core/plans/${date}/orders`, {
    params,
  });
};

export const getWorkerPositions = data => {
  return axios.post('/locations/byEntityId', data);
};

// $REGISTER
export const signUp = data => {
  return axios.post('/core/sign_up/', data);
};

export const verifyToken = data => {
  return axios.post('/core/sign_up/verify/', data);
};

export const resendToken = data => {
  return axios.post('/core/sign_up/resend_token/', data);
};

// $PASSWORD_RECOVERY
export const requestNewPassword = data => {
  return axios.post('/core/recovery_password/request_new_password/', data);
};

export const changePassword = data => {
  return axios.post('/core/recovery_password/change_password/', data);
};

// $INTEGRATIONS

export const getIntegrations = (params: ParamType) => {
  return axios.get('/core/provider_integrations', {
    params,
  });
};

export const getIntegration = (code: string) => {
  return axios.get(`/core/provider_integrations/${code}`);
};

export const patchIntegration = data => {
  return axios.post(`/core/provider_integrations`, data);
};

// $SALES CHANNELS

export const getChannels = (params?: ParamType) => {
  return axios.get('/core/sales_channels/', {
    params,
  });
};

// $DIGITAL CATALOGUE
export const postDigitalCatalogue = data => {
  return axios.post(`/core/sales_channels/digital_catalog/`, data);
};

export const getDigitalCatalogue = id => {
  return axios.get(`/core/sales_channels/digital_catalog/${id}`);
};

export const patchDigitalCatalogue = (id, data) => {
  return axios.patch(`/core/sales_channels/digital_catalog/${id}`, data);
};

export const deleteDigitalCatalogue = (id: string) => {
  return axios.delete(`/core/sales_channels/digital_catalog/${id}`);
};

// $SHOPIFY

export const getShopifyShops = (params?: ParamType) => {
  return axios.get('/core/sales_channels/shopify/', {
    params,
  });
};

export const getShopifyMarket = (id: string) => {
  return axios.get(`/core/sales_channels/shopify/${id}`);
};

export const patchShopifyMarket = (data, id) => {
  return axios.patch(`/core/sales_channels/shopify/${id}`, data);
};

export const connectShopify = (id: string) => {
  return axios.get(`/core/sales_channels/shopify/oauth_url?shop=${id}`);
};

export const installShopify = data => {
  return axios.post(`/core/sales_channels/shopify/oauth_confirm`, data);
};

export const disconnectedShopify = (id: string) => {
  return axios.delete(`/core/sales_channels/shopify/${id}`);
};

export const importShopifyOrders = data => {
  return axios.post('/core/sales_channels/shopify/import_orders', data);
};

// $INSTAGRAM

export const getInstagramOauthUrl = () => {
  return axios.get('/core/sales_channels/instagram/oauth_url');
};

export const getInstagramImages = () => {
  return axios.get('/core/sales_channels/instagram/get_images');
};

// $META_STORE

export const createMetaStore = data => {
  return axios.post('/core/sales_channels/meta_store', data);
};

export const updateMetaStore = (id: string, data) => {
  return axios.patch(`/core/sales_channels/meta_store/${id}`, data);
};

export const getMetaBusinesses = () => {
  return axios.get('/core/sales_channels/meta_store/get_businesses');
};

export const getMetaCatalogues = (params: ParamType = {}) => {
  return axios.get('/core/sales_channels/meta_store/get_catalogues', {
    params,
  });
};

export const postProductInCatalogue = data => {
  return axios.post(
    '/core/sales_channels/meta_store/post_product_in_catalogue',
    data
  );
};

// $USER_INVITATION

export const sendUserInvitation = data => {
  return axios.post('/core/administrators/invitations/', data);
};

export const invitationToAccept = data => {
  return axios.patch('/core/administrators/invitations/accept', data);
};

export const invitationToRegister = data => {
  return axios.patch('/core/administrators/invitations/register', data);
};

export const deletePendingInvitations = (id: string) => {
  return axios.delete(`/core/administrators/delete_invitation/${id}/`);
};

// $WALLET
export const getBalance = () => {
  return axios.get('/core/wallets/default');
};

export const getTransactions = (params: ParamType = {}) => {
  return axios.get('/core/wallet_transactions', {
    params,
  });
};

// $BILLING

export const getInvoices = () => {
  return axios.get('/core/invoices');
};

// $INVENTORY PRODUCTS

export const postProduct = data => {
  return axios.post('/inventory/products/create', data);
};

export const patchProduct = (data, id: string) => {
  return axios.patch(`/inventory/products/${id}`, data);
};

export const addPhotoToProduct = (data, id: string) => {
  return axios.post(`/inventory/products/${id}/addPhoto`, data);
};

export const removePhotoToProduct = (data, id: string) => {
  return axios.post(`/inventory/products/${id}/removePhoto`, data);
};

export const getProduct = (id: string) => {
  return axios.get(`/inventory/products/${id}`);
};

export const getProducts = (
  params: ParamType = {},
  headers: ParamType = {}
) => {
  return axios.get('/inventory/products/', {
    params,
    headers,
  });
};

// $INVENTORY CATEGORIES

export const postProductCategory = data => {
  return axios.post('/inventory/categories/', data);
};

export const getProductCategories = (params: ParamType = {}) => {
  return axios.get('/inventory/categories/', {
    params,
  });
};

export const patchProductCategory = (data, id: string) => {
  return axios.patch(`/inventory/categories/${id}`, data);
};

// $INVENTORY STOCKS

export const postStock = data => {
  return axios.post('/inventory/stocks', data);
};

export const patchStock = (id: string, data) => {
  return axios.patch(`/inventory/stocks/${id}`, data);
};

export const getStocks = (params: ParamType = {}) => {
  return axios.get(`/inventory/stocks`, { params });
};

// $INVENTORY PRODUCT LOGS

export const getProductLogs = (params: ParamType = {}) => {
  return axios.get(`/inventory/product-logs`, { params });
};

// $CUSTOMERS
export const getCustomers = (params: ParamType = {}) => {
  return axios.get('/core/customers/', { params });
};

export const getCustomer = (id: string) => {
  return axios.get(`/core/customers/${id}/`);
};

export const postCustomer = data => {
  return axios.post('/core/customers/', data);
};

export const patchCustomer = (data, id: string) => {
  return axios.patch(`/core/customers/${id}/`, data);
};

export const deleteCustomer = (id: string) => {
  return axios.delete(`/core/customers/${id}/`);
};
