import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { cloneDeep, omit, isEmpty, find } from 'lodash';
import { Collapse, Grid, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  Button,
  Divider,
  FlexContainer,
  Icon,
  Typography,
  Switch,
} from 'components/atoms';
import { FormControl, MultipleSelect, Spinner } from 'components/molecules';
import useForm from 'hooks/useForm';
import useRequest from 'hooks/useRequest';
import { useTypedSelector } from 'store';
import { optionFormatter } from 'util/functions';
import THEME from 'util/styledTheme';
import DefaultImage from 'assets/images/DefaultProfile.png';
import {
  getVehicles,
  postWorkers,
  patchWorker,
  getWorker,
  getBranchOffices,
} from 'services';
import { EditProps, VehicleForm } from './types';

const CameraIcon = styled(FlexContainer)`
  bottom: -2px;
  right: -6px;
`;

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const validationStateToCreate = {
  firstName: ['mandatory'],
  lastName: ['mandatory'],
  email: ['mandatory', 'email'],
  phone: ['mandatory', 'number'],
  password: ['mandatory'],
  passwordValidation: ['passwordValidation'],
  nationalIdentityDocument: ['identityDocument'],
};

const validationStateToUpdate = {
  firstName: ['mandatory'],
  lastName: ['mandatory'],
  email: ['mandatory', 'email'],
  phone: ['mandatory'],
  passwordValidation: ['passwordValidation'],
  nationalIdentityDocument: ['identityDocument'],
};

const vehicleForm = {
  id: 1,
  vehicle_type_id: null,
  plate: '',
  model: '',
};

const ComponentSkeleton = () => (
  <>
    <FlexContainer container margin='0 0 20px' direction='column'>
      <FlexContainer
        container
        padding='10px'
        direction='column'
        alignItems='center'
        justify='space-around'
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer
          width='90%'
          height='100px'
          justify='space-around'
          alignItems='center'
        >
          <Skeleton variant='rect' width='45%' height='40px' />
          <Skeleton variant='rect' width='45%' height='40px' />
        </FlexContainer>
        <FlexContainer
          width='90%'
          height='100px'
          justify='space-around'
          alignItems='center'
        >
          <Skeleton variant='rect' width='45%' height='40px' />
          <Skeleton variant='rect' width='45%' height='40px' />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer
        container
        padding='10px'
        direction='column'
        alignItems='center'
        justify='space-around'
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer
          width='90%'
          height='100px'
          justify='space-around'
          alignItems='center'
        >
          <Skeleton variant='rect' width='45%' height='40px' />
          <Skeleton variant='rect' width='45%' height='40px' />
        </FlexContainer>
        <FlexContainer
          width='90%'
          height='100px'
          justify='space-around'
          alignItems='center'
        >
          <Skeleton variant='rect' width='45%' height='40px' />
          <Skeleton variant='rect' width='45%' height='40px' />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  </>
);

const EditWorker: React.FC<EditProps> = ({
  data,
  onCreate,
  onClose,
  onEdit,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const selectedOffice = useTypedSelector(
    state => state.organization.selectedBranchOffice
  );
  const photoInput = useRef<HTMLInputElement>();
  const [form, onFormChange, validate, errors, reset, updateForm] = useForm(
    {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      phone_country: 'pe', // should be dynamic according to the country where it has logged
      documentType: null,
      nationalIdentityDocument: '',
      workLicense: '',
      birthDate: null,
      password: '',
      passwordValidation: '',
      isActive: false,
      isOnline: false,
      branchOffices: [],
    },
    !data ? validationStateToCreate : validationStateToUpdate
  );
  // API calls
  const [vehicleTypes, fetchVehicleTypes] = useRequest(getVehicles, []);
  const [
    createdWorker,
    createWorker,
    loadingCreate,
    ,
    errorsCreate,
  ] = useRequest(postWorkers);
  const [
    updatedWorker,
    updateWorker,
    loadingUpdate,
    ,
    errorsUpdate,
  ] = useRequest(patchWorker);
  const [worker, fetchWorker, loadingWorker] = useRequest(getWorker);
  const [branchOffices, fetchBranchOffices, loadingOffices] = useRequest(
    getBranchOffices,
    []
  );

  // Values
  const [typeOptions, setTypeOptions] = useState([]);
  const [vehicles, setVehicles] = useState<Array<VehicleForm>>([vehicleForm]);
  const [newPhoto, setNewPhoto] = useState<boolean>(false);
  const [photo, setPhoto] = useState(data && data.worker.photo);
  const [lastIndex, setLastIndex] = useState<number>(1);
  const [collapseAdditional, setCollapseAdditional] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [branchOfficeOptions, setBranchOfficeOptions] = useState([]);
  const [selectedBranchOffice, setSelectedBranchOffice] = useState([]);

  const resetForm = useCallback(() => {
    setLastIndex(1);
    setVehicles([vehicleForm]);
    reset();
    onCreate();
  }, [setLastIndex, setVehicles, reset]);

  useEffect(() => {
    if (!isEmpty(data)) {
      fetchWorker(data.id);
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(errors) && errors.nationalIdentityDocument) {
      setCollapseAdditional(true);
    }
  }, [errors]);

  useEffect(() => {
    fetchVehicleTypes();
    fetchBranchOffices({
      page_size: 0,
    });
  }, [fetchVehicleTypes, fetchBranchOffices]);

  useEffect(() => {
    setTypeOptions(optionFormatter(vehicleTypes));
  }, [vehicleTypes]);

  useEffect(() => {
    if (branchOffices.length > 0) {
      setBranchOfficeOptions(
        optionFormatter(branchOffices, {
          id: option => option.reference_code,
          name: option => option.name,
        })
      );
    }
  }, [branchOffices]);

  useEffect(() => {
    if (!data) {
      setSelectedBranchOffice([
        {
          id: selectedOffice.reference_code,
          name: selectedOffice.name,
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(worker)) {
      setSelectedBranchOffice(
        optionFormatter(worker.branch_offices, {
          id: option => option.reference_code,
          name: option => option.name,
        })
      );

      updateForm({
        firstName: worker ? worker.first_name : '',
        lastName: worker ? worker.last_name : '',
        email: worker ? worker.email : '',
        phone: worker ? worker.phone : '',
        documentType: worker ? worker.document_type : null,
        nationalIdentityDocument: worker
          ? worker.national_identity_document
          : '',
        workLicense: worker ? worker.work_license : '',
        birthDate: (worker && worker.birth_date) || null,
        password: '',
        passwordValidation: '',
        isActive: worker ? worker.is_active : false,
        isOnline: worker ? worker.is_online : false,
        branchOffices: worker ? worker.branch_offices : [],
      });
    }
  }, [worker]);

  useEffect(() => {
    if (!isEmpty(createdWorker)) {
      enqueueSnackbar('El worker fue creado correctamente.', {
        variant: 'success',
      });
      resetForm();
    }
  }, [createdWorker, enqueueSnackbar, resetForm]);

  useEffect(() => {
    if (!isEmpty(updatedWorker)) {
      enqueueSnackbar('El worker fue actualizado correctamente.', {
        variant: 'success',
      });
      onEdit();
    }
  }, [updatedWorker, enqueueSnackbar]);

  useEffect(() => {
    if (errorsCreate && errorsCreate.data && errorsCreate.data.errors) {
      if (errorsCreate.data.errors[0] === 'User exists') {
        enqueueSnackbar('Este worker ya existe.', {
          variant: 'error',
        });
      } else if (errorsCreate.data.errors[0].worker_data[0]) {
        if (errorsCreate.data.errors[0].worker_data[0].vehicles) {
          const errorVehicles =
            errorsCreate.data.errors[0].worker_data[0].vehicles[0];
          const keys = Object.keys(errorVehicles);
          let message = '';

          if (keys.length === 1) {
            if (keys[0] === 'plate') {
              message = 'Placa es requerido';
            } else {
              message = 'Tipo de vehículo es requerido';
            }
          } else {
            message = 'Tipo de vehículo y placa son requeridos';
          }
          enqueueSnackbar(message, {
            variant: 'error',
          });
        } else if (
          errorsCreate.data.errors[0].worker_data[0].phone[0].startsWith(
            'Enter a valid'
          )
        ) {
          enqueueSnackbar('Ingrese un número de teléfono válido', {
            variant: 'error',
          });
        }
      }
    }
  }, [errorsCreate]);

  useEffect(() => {
    if (errorsUpdate && errorsUpdate.data && errorsUpdate.data.errors) {
      if (
        errorsUpdate.data.errors[0].user_data &&
        errorsUpdate.data.errors[0].user_data.email[0] ===
          'User email already exists.'
      ) {
        enqueueSnackbar('Este correo ya está registrado.', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Hubo un problema, intenta de nuevo.', {
          variant: 'error',
        });
      }
    }
  }, [errorsUpdate]);

  const handleUploadFile = event => {
    if (event.target.files[0]) {
      setNewPhoto(true);
      setPhoto(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleVehicleChange = (value: string, name: string, index: number) => {
    const vehicleForms = cloneDeep(vehicles);
    vehicleForms[index][name] = value;
    setVehicles(vehicleForms);
  };

  const handleAddVehicle = () => {
    const vehicleForms = vehicles.concat({
      ...vehicleForm,
      id: lastIndex + 1,
    });
    setLastIndex(lastIndex + 1);
    setVehicles(vehicleForms);
  };

  const handleDeleteVehicle = () => {
    const vehicleForms = cloneDeep(vehicles);
    vehicleForms.pop();
    setVehicles(vehicleForms);
  };

  const validateForm = () => {
    if (validate()) {
      if (data) {
        const formData = new FormData();
        if (newPhoto) {
          formData.append('photo', photoInput.current.files[0]);
        }
        const editWorker = {
          user_data: {
            password: form.password,
            first_name: form.firstName,
            last_name: form.lastName,
            email: form.email,
          },
          document_type: form.documentType,
          national_identity_document: form.nationalIdentityDocument,
          worker_license: form.workLicense,
          birth_date: form.birthDate,
          phone: form.phone,
          is_active: form.isActive,
          is_online: form.isOnline,
          branch_offices: selectedBranchOffice.map(
            branchOffice => branchOffice.id
          ),
        };
        if (form.password === '') {
          delete editWorker.user_data.password;
        }
        if (newPhoto) {
          updateWorker(formData, data.id);
        } else {
          updateWorker(editWorker, data.id);
        }
      } else {
        const vehiclesArr = vehicles.map(vehicle => omit(vehicle, 'id'));
        const workerData = {
          first_name: form.firstName,
          last_name: form.lastName,
          email: form.email,
          phone: form.phone,
          password: form.password,
          document_type: form.documentType || '',
          national_identity_document: form.nationalIdentityDocument,
          worker_license: form.workLicense,
          birth_date: form.birthDate,
          vehicles: vehiclesArr,
          branch_offices: selectedBranchOffice.map(
            branchOffice => branchOffice.id
          ),
        };
        createWorker({
          worker_data: [workerData],
        });
      }
    }
  };

  return (
    <FlexContainer
      container
      minWidth='800px'
      position='relative'
      padding='40px 50px 50px'
      direction='column'
    >
      <CloseIcon onClick={() => onClose()}>
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <Typography fontSize={18} margin='10px 0 0'>
        {data ? 'Perfil worker' : 'Creación de Worker'}
      </Typography>
      <Divider orientation='horizontal' margin='15px 0 30px' />
      {loadingWorker ? (
        <ComponentSkeleton />
      ) : (
        <>
          <FlexContainer
            container
            direction='column'
            padding='30px 30px'
            margin='0 0 30px'
            borderRadius='10px'
            backgroundColor={THEME.colors.backgroundColor}
            borderColor={THEME.colors.borderColor}
          >
            <FlexContainer container alignItems='center' margin='0 0 30px'>
              {data && (
                <FlexContainer
                  direction='column'
                  alignItems='center'
                  margin='0 50px 0 0'
                  cursor='pointer'
                  onClick={() => photoInput.current.click()}
                >
                  <FlexContainer
                    borderRadius='100px'
                    borderColor={THEME.colors.primary}
                    padding='5px'
                    margin='0 0 15px'
                    position='relative'
                  >
                    <img
                      src={photo || DefaultImage}
                      alt='user_image'
                      height='80px'
                      width='80px'
                      style={{ borderRadius: '100px' }}
                    />
                    <CameraIcon
                      position='absolute'
                      backgroundColor={THEME.colors.primary}
                      borderRadius='40px'
                      padding='5px'
                      justify='center'
                      alignItems='center'
                    >
                      <Icon icon='camera-icon' size={20} />
                    </CameraIcon>
                  </FlexContainer>
                  <input
                    ref={photoInput}
                    type='file'
                    accept='image/*'
                    onChange={handleUploadFile}
                    style={{ display: 'none' }}
                  />
                </FlexContainer>
              )}

              <FlexContainer
                container
                justify='space-between'
                alignItems='center'
              >
                <Typography
                  color={THEME.colors.primary}
                  fontWeight={500}
                  fontSize={17}
                  margin='10px 0 0 0'
                >
                  Información básica
                </Typography>

                <FlexContainer alignItems='center' margin='10px 0 0 20px'>
                  <Typography fontSize={14}>Activo:</Typography>
                  <Switch
                    color='primary'
                    checked={form.isActive}
                    onChange={event =>
                      onFormChange(event.target.checked, 'isActive')
                    }
                  />
                </FlexContainer>

                <FlexContainer alignItems='center' margin='10px 0 0'>
                  <Typography fontSize={14}>Conectado:</Typography>
                  <Switch
                    color='primary'
                    checked={form.isOnline}
                    onChange={event =>
                      onFormChange(event.target.checked, 'isOnline')
                    }
                  />
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>

            <FlexContainer container direction='column'>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    label='Nombres'
                    placeholder='Escribe tus nombres'
                    onChange={value => onFormChange(value, 'firstName')}
                    value={form.firstName}
                    margin='0 0 20px'
                    error={errors.firstName}
                    width='100%'
                    disabled={loadingCreate || loadingUpdate}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    label='Apellidos'
                    placeholder='Escribe tus Apellidos'
                    onChange={value => onFormChange(value, 'lastName')}
                    value={form.lastName}
                    error={errors.lastName}
                    margin='0 0 20px'
                    width='100%'
                    disabled={loadingCreate || loadingUpdate}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    label='Correo electrónico'
                    placeholder='Escribe correo electrónico'
                    onChange={value => onFormChange(value, 'email')}
                    value={form.email}
                    margin='0 0 20px'
                    error={errors.email}
                    width='100%'
                    disabled={loadingCreate}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    control='phone'
                    country={form.phone_country}
                    label='Teléfono'
                    placeholder='Escribe tu número de teléfono'
                    onChange={value => onFormChange(value, 'phone')}
                    value={form.phone}
                    error={errors.phone}
                    margin='0 0 20px'
                    width='100%'
                    disabled={loadingCreate}
                  />
                </Grid>
              </Grid>

              {!data && (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      label='Contraseña'
                      placeholder='Escribe tu contraseña'
                      onChange={value => onFormChange(value, 'password')}
                      value={form.password}
                      type='password'
                      margin='0 0 20px'
                      error={errors.password}
                      width='100%'
                      disabled={loadingCreate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      label='Confirmación'
                      placeholder='Confirmación de contraseña'
                      onChange={value =>
                        onFormChange(value, 'passwordValidation')
                      }
                      value={form.passwordValidation}
                      type='password'
                      margin='0 0 20px'
                      error={errors.passwordValidation}
                      width='100%'
                      disabled={loadingCreate}
                    />
                  </Grid>
                </Grid>
              )}
            </FlexContainer>

            {data && (
              <FlexContainer container direction='column'>
                <FlexContainer
                  container
                  justify='space-between'
                  alignItems='center'
                  margin='0 0 10px 0'
                >
                  <Typography
                    color={THEME.colors.primary}
                    fontWeight={500}
                    fontSize={17}
                    margin='0 0 5px'
                  >
                    Cambiar Contraseña
                  </Typography>

                  <div
                    style={{
                      transform: changePassword ? 'rotate(180deg)' : 'none',
                      transition: 'all 0.3s',
                    }}
                  >
                    <IconButton
                      onClick={() => setChangePassword(!changePassword)}
                    >
                      <Icon icon='arrow-icon' size={15} />
                    </IconButton>
                  </div>
                </FlexContainer>

                <Collapse in={changePassword} timeout='auto'>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Contraseña'
                        placeholder='Escribe tu contraseña'
                        onChange={value => onFormChange(value, 'password')}
                        value={form.password}
                        margin='0 0 20px'
                        type='password'
                        error={errors.password}
                        width='100%'
                        disabled={loadingUpdate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        label='Confirmación'
                        placeholder='Confirmación de contraseña'
                        onChange={value =>
                          onFormChange(value, 'passwordValidation')
                        }
                        value={form.passwordValidation}
                        margin='0 0 20px'
                        type='password'
                        error={errors.passwordValidation}
                        width='100%'
                        disabled={loadingUpdate}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </FlexContainer>
            )}
          </FlexContainer>

          <FlexContainer
            container
            direction='column'
            padding='30px 30px'
            margin='0 0 30px'
            borderRadius='10px'
            backgroundColor={THEME.colors.backgroundColor}
            borderColor={THEME.colors.borderColor}
          >
            <FlexContainer
              container
              justify='space-between'
              alignItems='center'
            >
              <Typography
                color={THEME.colors.primary}
                fontWeight={500}
                fontSize={17}
                margin='0 0 5px'
              >
                Información Adicional
              </Typography>

              <div
                style={{
                  transform: collapseAdditional ? 'rotate(180deg)' : 'none',
                  transition: 'all 0.3s',
                }}
              >
                <IconButton
                  onClick={() => setCollapseAdditional(!collapseAdditional)}
                >
                  <Icon icon='arrow-icon' size={15} />
                </IconButton>
              </div>
            </FlexContainer>

            <Collapse in={collapseAdditional} timeout='auto'>
              <FlexContainer container direction='column' margin='30px 0 0'>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      control='select'
                      label='Tipo de documento'
                      defaultOption={{ id: '', name: 'Ninguno' }}
                      options={[{ id: 'dni', name: 'DNI' }]}
                      placeholder='Selecciona el tipo'
                      onChange={value => onFormChange(value, 'documentType')}
                      value={form.documentType}
                      margin='0 0 20px'
                      width='100%'
                      disabled={loadingCreate || loadingUpdate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      label='Documento'
                      placeholder='Escribe el documento'
                      onChange={value =>
                        onFormChange(value, 'nationalIdentityDocument')
                      }
                      value={form.nationalIdentityDocument}
                      margin='0 0 20px'
                      width='100%'
                      error={errors.nationalIdentityDocument}
                      disabled={loadingCreate || loadingUpdate}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      label='Licencia'
                      placeholder='Escribe tu licencia'
                      onChange={value => onFormChange(value, 'workLicense')}
                      value={form.workLicense}
                      margin='0 0 20px'
                      width='100%'
                      disabled={loadingCreate || loadingUpdate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      control='date'
                      label='Fecha de nacimiento'
                      placeholder='Ingresa tu nacimiento'
                      onChange={value => onFormChange(value, 'birthDate')}
                      value={form.birthDate}
                      width='100%'
                      disabled={loadingCreate || loadingUpdate}
                      toolbar={false}
                    />
                  </Grid>
                </Grid>
              </FlexContainer>
            </Collapse>
          </FlexContainer>

          {!data && (
            <FlexContainer
              container
              direction='column'
              padding='30px 30px'
              margin='0 0 20px'
              borderRadius='10px'
              backgroundColor={THEME.colors.backgroundColor}
              borderColor={THEME.colors.borderColor}
            >
              {vehicles.map((vehicle, index) => (
                <FlexContainer
                  container
                  direction='column'
                  margin='0 0 20px'
                  key={vehicle.id}
                >
                  <Typography
                    color={THEME.colors.primary}
                    fontWeight={500}
                    fontSize={17}
                    margin='0 0 30px'
                  >
                    {`Vehículo ${index + 1}`}
                  </Typography>

                  <FlexContainer container direction='column'>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          control='select'
                          options={typeOptions}
                          label='Tipo de vehículo'
                          placeholder='Selecciona el tipo'
                          onChange={value =>
                            handleVehicleChange(value, 'vehicle_type_id', index)
                          }
                          value={vehicle.vehicle_type_id}
                          margin='0 0 20px'
                          width='100%'
                          disabled={loadingCreate}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          label='Placa'
                          placeholder='Escribe la placa'
                          onChange={value =>
                            handleVehicleChange(value, 'plate', index)
                          }
                          value={vehicle.plate}
                          margin='0 0 20px'
                          width='100%'
                          disabled={loadingCreate}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          label='Modelo'
                          placeholder='Escribe el modelo'
                          onChange={value =>
                            handleVehicleChange(value, 'model', index)
                          }
                          value={vehicle.model}
                          margin='0 0 20px'
                          width='100%'
                          disabled={loadingCreate}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {index === vehicles.length - 1 && (
                          <FlexContainer
                            alignItems='center'
                            justify='space-between'
                            margin='27px 0 0'
                          >
                            <Button
                              variant='contained'
                              disableElevation
                              onClick={() => handleAddVehicle()}
                              disabled={loadingCreate}
                              startIcon={
                                <Icon
                                  icon='plus-icon'
                                  size={16}
                                  color={THEME.colors.textPrimary}
                                />
                              }
                            >
                              <Typography fontSize={13}>Agregar</Typography>
                            </Button>
                            {vehicles.length > 1 && (
                              <Button
                                variant='contained'
                                disableElevation
                                onClick={() => handleDeleteVehicle()}
                                disabled={loadingCreate}
                              >
                                <Icon
                                  icon='delete-icon'
                                  size={16}
                                  color={THEME.colors.textPrimary}
                                />
                              </Button>
                            )}
                          </FlexContainer>
                        )}
                      </Grid>
                    </Grid>
                  </FlexContainer>
                </FlexContainer>
              ))}
            </FlexContainer>
          )}

          <FlexContainer
            container
            direction='column'
            padding='30px 30px'
            margin='0 0 30px'
            borderRadius='10px'
            backgroundColor={THEME.colors.backgroundColor}
            borderColor={THEME.colors.borderColor}
          >
            <FlexContainer
              container
              justify='space-between'
              alignItems='center'
              margin='0 0 20px 0'
            >
              <Typography
                color={THEME.colors.primary}
                fontWeight={500}
                fontSize={17}
                margin='10px 0 0 0'
              >
                Sucursales
              </Typography>
            </FlexContainer>

            <FlexContainer container direction='column'>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MultipleSelect
                    label='Accesos a sucursales'
                    placeholder='Seleccione las sucursales'
                    options={branchOfficeOptions}
                    values={selectedBranchOffice}
                    onChange={option => {
                      const found = find(selectedBranchOffice, {
                        id: option.id,
                      });
                      if (found) {
                        setSelectedBranchOffice(
                          selectedBranchOffice.filter(
                            item => item.id !== option.id
                          )
                        );
                      } else {
                        setSelectedBranchOffice(
                          selectedBranchOffice.concat(option)
                        );
                      }
                    }}
                    onDelete={option => {
                      setSelectedBranchOffice(
                        selectedBranchOffice.filter(
                          item => item.id !== option.id
                        )
                      );
                    }}
                    margin='0 0 20px'
                    width='100%'
                    disabled={loadingCreate || loadingUpdate || loadingOffices}
                  />
                </Grid>
              </Grid>
            </FlexContainer>
          </FlexContainer>
        </>
      )}

      <Button
        variant='contained'
        color='primary'
        padding='5px 30px'
        onClick={() => validateForm()}
        disabled={
          loadingCreate || loadingUpdate || loadingOffices || loadingWorker
        }
      >
        {(loadingCreate || loadingUpdate) && (
          <Spinner height='15px' spinnerSize={20} margin='0 8px 0 0' />
        )}
        {data ? 'Guardar' : 'Crear'}
      </Button>
    </FlexContainer>
  );
};

export default EditWorker;
