import React from 'react';
import { Typography } from 'components/atoms';
import { Dropdown, DropdownIconOption } from 'components/molecules';
import { AssignProps } from './types';

const options = [
  {
    label: 'Por Worker',
    name: 'worker',
    icon: 'assign-worker-icon',
    permission: 'core.route.assign_worker',
  },
  {
    label: 'Por Provider',
    name: 'provider',
    icon: 'assign-provider-icon',
    permission: 'core.route.assign_provider',
  },
];

const AssignButton: React.FC<AssignProps> = ({ onClick, permissions }) => {
  const optionsDrop = options.map(option => {
    if (!permissions || permissions.includes(option.permission)) {
      return (
        <DropdownIconOption
          key={option.name}
          label={option.label}
          name={option.name}
          icon={option.icon}
          onClick={() => onClick(option.name)}
        />
      );
    }
    return null;
  });

  return (
    <Dropdown
      direction='bottom'
      variant='contained'
      options={optionsDrop.filter(option => option)}
      color='secondary'
      label={
        <Typography color='text.secondary' fontSize={13} padding='0 10px'>
          Asignar por
        </Typography>
      }
      hover
    />
  );
};

export default AssignButton;
