import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store';
import toggleMenu from 'store/sidebar/actions';
import { FlexContainer } from 'components/atoms';
import { Sidebar } from 'components/organisms';
import useWindowSize from 'hooks/useWindowSize';
import THEME from 'util/styledTheme';

const SidebarBackground = styled(FlexContainer)<{ show: boolean }>`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1009;
`;

const PageWrapper = styled(FlexContainer)`
  align-self: stretch;
`;

const AnimateContainer = styled(FlexContainer)<{ showMenu: boolean }>`
  transition: all 0.3s;
  margin-left: ${props => (props.showMenu ? '170px' : '0')};
  @media only screen and (min-width: 768px) {
    margin-left: ${props => (props.showMenu ? '170px' : '65px')};
  }
`;

const Layout: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const showMenu = useTypedSelector(state => state.sidebar);

  return (
    <FlexContainer container backgroundColor='#ffffff'>
      <SidebarBackground
        show={width <= 768 && showMenu}
        onClick={() => dispatch(toggleMenu(false))}
      />
      <Sidebar
        showMenu={showMenu}
        setShowMenu={bool => dispatch(toggleMenu(bool))}
      />

      <AnimateContainer
        direction='column'
        flex='1 1'
        showMenu={width > 768 && showMenu}
      >
        <FlexContainer container minHeight='100vh'>
          <PageWrapper backgroundColor={THEME.colors.backgroundColor} container>
            {children}
          </PageWrapper>
        </FlexContainer>
      </AnimateContainer>
    </FlexContainer>
  );
};

export default Layout;
