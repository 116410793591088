import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { FlexContainer, Typography } from 'components/atoms';
import { useTypedSelector } from 'store';
import menu from 'util/fleetMenu';
import { MenuOptionProps, OptionProps } from './types';

const Container = styled(FlexContainer)`
  border-right: 1px solid ${props => props.theme.colors.borderColor};
  overflow-y: auto;
  z-index: 2;
  &:hover::-webkit-scrollbar-thumb {
    background-color: #bebed3;
  }
`;

const OptionContainer = styled(FlexContainer)<OptionProps>`
  position: relative;
  color: ${props =>
    props.active
      ? props.theme.colors.primary
      : props.theme.colors.textSecondary};
  ${props =>
    props.active &&
    `
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 3px;
      background-color: ${props.theme.colors.primary};
    }
  `}
`;

const StyledLink = styled(Link)`
  width: 100%;
  font-size: 14px;
  display: flex;
  white-space: nowrap;
  text-decoration: none;
  color: inherit;
  padding: 5px 25px;
`;

const MenuOption: React.FC<MenuOptionProps> = ({ option }) => {
  const match = useRouteMatch({
    path: option.url,
    exact: false,
  });

  return (
    <OptionContainer
      container
      alignItems='center'
      margin='0 0 12px'
      active={!!match}
    >
      <StyledLink to={option.url}>{option.name}</StyledLink>
    </OptionContainer>
  );
};

const FleetSidebar: React.FC = () => {
  const user = useTypedSelector(state => state.user);

  return (
    <Container
      padding='80px 0'
      height='100%'
      direction='column'
      backgroundColor='#FFFFFF'
      position='fixed'
      width='200px'
    >
      {menu.map(block => (
        <FlexContainer
          container
          key={block.name}
          direction='column'
          margin='0 0 30px'
        >
          <FlexContainer container padding='5px 25px'>
            <Typography fontWeight={600}>{block.name}</Typography>
          </FlexContainer>
          {block.items.map(item => {
            if (
              item.permission === '' ||
              user.permissions.includes(item.permission)
            ) {
              return (
                <MenuOption key={item.name} option={item}>
                  {item.name}
                </MenuOption>
              );
            }
            return null;
          })}
        </FlexContainer>
      ))}
    </Container>
  );
};

export default FleetSidebar;
