import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import {
  FlexContainer,
  Icon,
  Typography,
  Status,
  Button,
} from 'components/atoms';
import { Dropdown, Spinner } from 'components/molecules';
import useWindowSize from 'hooks/useWindowSize';
import THEME from 'util/styledTheme';
import statusColors from 'util/statusColors';
import { OrderCardProps } from './types';
import { CustomTooltip } from '../RouteBlock/RouteCard';

const StatusCircle = styled.div<{ code?: string }>`
  width: 8px;
  height: 8px;
  border-radius: 20px;
  background-color: ${props =>
    statusColors[props.code] || statusColors.default};
`;

const OrderCard: React.FC<OrderCardProps> = ({
  data,
  toggleSelect,
  selected,
  onDetail,
  onEdit,
  toggleVisibility,
  showRoute,
  onRouteDetail,
  loadingAssignOrder,
}) => {
  const size = useWindowSize();
  const [showCheckbox, setShowCheckbox] = useState<boolean>(false);
  const [route, setRoute] = useState<any>({});

  useEffect(() => {
    if (data.route) {
      setRoute(data.route);
    }
  }, [data]);

  return (
    <FlexContainer
      container
      padding='10px 5px 10px 10px'
      backgroundColor='#FFFFFF'
      direction='column'
      alignItems='flex-end'
      borderColor={THEME.colors.borderColor}
      onMouseEnter={() => setShowCheckbox(true)}
      onMouseLeave={() => setShowCheckbox(false)}
    >
      <FlexContainer container alignItems='center' margin='0 0 5px'>
        <FlexContainer width='10%'>
          {(showCheckbox || selected || size.width <= 768) && (
            <Checkbox
              checked={selected}
              disableRipple
              color='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
              style={{ padding: 0 }}
              onClick={e => e.stopPropagation()}
              onChange={e => toggleSelect(e.target.checked)}
            />
          )}
        </FlexContainer>
        <FlexContainer
          width='90%'
          alignItems='center'
          justify='space-between'
          height='24px'
        >
          <FlexContainer container alignItems='center' justify='space-between'>
            <FlexContainer alignItems='center'>
              <Typography fontSize={13} fontWeight={600} margin='0 5px 0 0'>
                {data.reference_code || data.code}
              </Typography>
              {size.width > 768 && (
                <CustomTooltip arrow placement='top' title={data.status_label}>
                  <div style={{ display: 'inline-block' }}>
                    <StatusCircle code={data.status} />
                  </div>
                </CustomTooltip>
              )}
            </FlexContainer>
            {data.batch && (
              <Typography
                fontSize={11}
                margin='0 10px 0 0'
                color='text.secondary'
              >
                {data.batch}
              </Typography>
            )}
          </FlexContainer>
          {size.width > 768 ? (
            <FlexContainer alignItems='center'>
              {loadingAssignOrder && selected && (
                <Spinner height='10px' spinnerSize={12} margin='0 8px 0 0' />
              )}
              <FlexContainer
                margin='0 5px 0 0'
                onClick={e => {
                  e.stopPropagation();
                  toggleVisibility();
                }}
              >
                {showRoute ? (
                  <Icon icon='show-icon' size={20} />
                ) : (
                  <Icon icon='no-show-icon' size={20} />
                )}
              </FlexContainer>

              <Dropdown
                label={<Icon icon='options-icon' size={20} />}
                direction='bottom'
                position='right'
                showCaret={false}
                options={[
                  {
                    label: 'Detalle',
                    key: 'detail',
                    onClick: () => onDetail(),
                  },
                  {
                    label: 'Editar',
                    key: 'edit',
                    onClick: () => onEdit(),
                  },
                ]}
                padding='0 5px'
                height='20px'
              />
            </FlexContainer>
          ) : (
            <FlexContainer margin='0 10px 0 0'>
              <Status
                color={statusColors[data.status] || statusColors.default}
                label={data.status_label}
                fontSize={12}
              />
            </FlexContainer>
          )}
        </FlexContainer>
      </FlexContainer>
      <FlexContainer width='90%' direction='column'>
        <FlexContainer
          container
          alignItems='center'
          justify='space-between'
          margin='0 0 5px 0'
        >
          <Typography fontSize={12}>{data.contact_person}</Typography>
        </FlexContainer>

        <Typography fontSize={11} color='text.secondary'>
          {data.address}
        </Typography>
        {data.address_detail && (
          <Typography fontSize={11} color='text.secondary'>
            {data.address_detail}
          </Typography>
        )}

        {data.route && (
          <FlexContainer alignItems='center' margin='0 0 5px 0'>
            <Typography fontSize={10} margin='1px 0 0'>
              Ruta:
            </Typography>
            <Button
              padding='1px 5px'
              margin='0 5px'
              height='20px'
              fontSize='11px'
              onClick={() => {
                onRouteDetail(data.route.id);
              }}
            >
              {route.code}
            </Button>
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default OrderCard;
