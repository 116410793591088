import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { Button, FlexContainer, Icon, Typography } from 'components/atoms';
import { FormControl } from 'components/molecules';
import { getIntegration, patchIntegration } from 'services';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import Minutes99 from 'assets/images/providers/99minutos.png';
import Olva from 'assets/images/providers/olva.jpg';
import Glovo from 'assets/images/providers/glovo.svg';
import Savar from 'assets/images/providers/savar.png';
import Chazki from 'assets/images/providers/chazki.png';
import Moova from 'assets/images/providers/moova.png';
import Cabify from 'assets/images/providers/cabify.png';
import PedidosYa from 'assets/images/providers/pedidosya.png';
import { EditProps, FormType } from './types';

const SkeletonGroup = () => (
  <>
    <Skeleton width='50%' height={30} />
    <Skeleton width='100%' height={30} />
    <Skeleton width='50%' height={30} />
    <Skeleton width='100%' height={30} />
    <Skeleton width='50%' height={30} />
    <Skeleton width='100%' height={30} />
  </>
);

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const Edit: React.FC<EditProps> = ({
  data,
  onClose,
  onActivate,
  changeAimoTab,
  openSettings,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  // Values
  const [firstFetch, setFirstFetch] = useState<boolean>(true);
  const [form, setForm] = useState<FormType>({});
  // API calls
  const [integration, fetchIntegration, loadingFetch] = useRequest(
    getIntegration
  );
  const [updatedIntegration, updateIntegration, loadingUpdate] = useRequest(
    patchIntegration
  );
  const [detailProvider, setDetailProvider] = useState(null);

  useEffect(() => {
    if (data.object) {
      setForm(
        data.object.field_configurations.reduce((prev, next) => {
          // eslint-disable-next-line no-param-reassign
          prev[next.id] = next.registered_value || '';
          return prev;
        }, {})
      );
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(integration)) {
      setFirstFetch(false);
    }
  }, [integration]);

  useEffect(() => {
    if (data.object && data.object.is_active) {
      fetchIntegration(data.object.code);
    }
  }, [data]);

  useEffect(() => {
    if (!isEmpty(updatedIntegration)) {
      const message = isEmpty(integration)
        ? 'El proveedor fue activado correctamente.'
        : 'El proveedor fue actualizado correctamente.';
      enqueueSnackbar(message, {
        variant: 'success',
      });
      onActivate(data.object.id);
      if (isEmpty(integration)) {
        fetchIntegration(data.object.code);
      }
    }
  }, [updatedIntegration, enqueueSnackbar]);

  useEffect(() => {
    if (!isEmpty(data.object)) {
      switch (data.object.code) {
        case 'olva':
          setDetailProvider({
            cut_off_schedule: ['7pm para el ingreso de envíos.'],
            pickup_schedule: 'Desde las 9am hasta las 5pm.',
            extra_info:
              'Una orden dura hasta 5 días, después se cancela automáticamente.',
          });
          break;
        case '99minutos':
          setDetailProvider({
            cut_off_schedule: [
              'Sameday: 1pm. Si se ingresa un pedido pasada esa hora se recolecta pero se entrega el día siguiente.',
              'Nextday: 12pm (media noche).',
            ],
            extra_info:
              'Medidas máximas del paquete en cualquiera de sus lados: 40cm.',
          });
          break;
        default:
          setDetailProvider({ extra_info: '' });
          break;
      }
    }
  }, [data]);

  const handleUpdate = resell => {
    updateIntegration({
      code: data.object.code,
      is_resell: resell,
      is_active: true,
      field_values: Object.keys(form).map(key => ({
        field_configuration_id: key,
        text: form[key],
      })),
    });
  };

  const validateForm = () => {
    const isFullFilled = data.object.field_configurations.every(field => {
      return form[field.id] && form[field.id] !== '';
    });

    if (!isFullFilled) {
      enqueueSnackbar('Todos los campos son obligatorios.', {
        variant: 'error',
      });
      return;
    }

    handleUpdate(false);
  };

  const pickImage = code => {
    switch (code) {
      case '99minutos':
        return Minutes99;
      case 'olva':
        return Olva;
      case 'glovo':
        return Glovo;
      case 'savar':
        return Savar;
      case 'chazki':
        return Chazki;
      case 'moova':
        return Moova;
      case 'cabify':
        return Cabify;
      case 'pedidosya':
        return PedidosYa;
      default:
        return '';
    }
  };

  return (
    <FlexContainer
      width='530px'
      minHeight='100%'
      position='relative'
      padding='50px 40px'
    >
      <CloseIcon
        onClick={() => {
          if (!loadingUpdate) {
            onClose();
          }
        }}
      >
        <Icon
          icon='cancel-icon'
          color={THEME.colors.placeholderColor}
          size={25}
        />
      </CloseIcon>
      <FlexContainer
        container
        height='100%'
        direction='column'
        padding='60px 50px'
        borderRadius='10px'
        borderColor={THEME.colors.borderColor}
        backgroundColor={THEME.colors.backgroundColor}
      >
        {data.object && (
          <>
            <FlexContainer container justify='center'>
              <img
                src={pickImage(data.object.code)}
                alt='provider_logo'
                style={{
                  width: 'auto',
                  height: 'auto',
                  marginBottom: '50px',
                  maxWidth: '300px',
                  minWidth: '179px',
                  maxHeight: '65px',
                  minHeight: '55px',
                }}
              />
            </FlexContainer>
            <Typography fontSize={12} color='text.secondary' margin='0 0 20px'>
              {data.object.description}
            </Typography>
            {detailProvider && (
              <>
                {detailProvider.cut_off_schedule && (
                  <>
                    <FlexContainer
                      direction={
                        detailProvider.cut_off_schedule.length > 1
                          ? 'column'
                          : 'row'
                      }
                      margin='0 0 10px'
                    >
                      <Typography
                        fontSize={12}
                        color='text.secondary'
                        fontWeight={600}
                      >
                        Horario de corte:
                      </Typography>
                      <FlexContainer
                        direction='column'
                        margin={
                          detailProvider.cut_off_schedule.length > 1
                            ? '5px 0 0 10px'
                            : '0 0 0 5px'
                        }
                      >
                        {detailProvider.cut_off_schedule.map(schedule => (
                          <Typography
                            key={schedule}
                            fontSize={12}
                            color='text.secondary'
                            margin='0 0 5px'
                          >
                            {detailProvider.cut_off_schedule.length > 1 && '- '}
                            {schedule}
                          </Typography>
                        ))}
                      </FlexContainer>
                    </FlexContainer>
                    {detailProvider.pickup_schedule && (
                      <FlexContainer margin='0 0 10px'>
                        <Typography
                          fontSize={12}
                          color='text.secondary'
                          fontWeight={600}
                        >
                          Horario de recojo:
                        </Typography>
                        <Typography
                          fontSize={12}
                          color='text.secondary'
                          margin='0 0 0 5px'
                        >
                          {detailProvider.pickup_schedule}
                        </Typography>
                      </FlexContainer>
                    )}
                  </>
                )}

                {detailProvider.extra_info && (
                  <Typography
                    fontSize={12}
                    color='text.secondary'
                    margin='0 0 10px'
                  >
                    {detailProvider.extra_info}
                  </Typography>
                )}
              </>
            )}
          </>
        )}
        {data.editing && (
          <>
            {loadingFetch && firstFetch ? (
              <SkeletonGroup />
            ) : (
              <>
                {data.object.field_configurations.length !== 0 ? (
                  <>
                    <FlexContainer width='100%' direction='column'>
                      {data.object.field_configurations.map(field => (
                        <FormControl
                          key={field.id}
                          control={
                            field.input_type === 'text' ? 'input' : 'select'
                          }
                          options={
                            field.values
                              ? field.values.map(option => ({
                                  id: option.value,
                                  name: option.label,
                                }))
                              : []
                          }
                          label={field.label}
                          onChange={value =>
                            setForm({
                              ...form,
                              [field.id]: value,
                            })
                          }
                          value={form[field.id] || ''}
                          margin='0 0 20px'
                          width='100%'
                          disabled={loadingUpdate}
                        />
                      ))}
                    </FlexContainer>
                    <Button
                      variant='contained'
                      color='primary'
                      margin='0 0 20px'
                      fullWidth
                      onClick={() => {
                        validateForm();
                      }}
                      disabled={loadingUpdate || loadingFetch}
                    >
                      Guardar
                    </Button>
                    {data.object.can_work_through_aimo && openSettings && (
                      <Button
                        variant='contained'
                        color='secondary'
                        fullWidth
                        onClick={() => {
                          changeAimoTab();
                        }}
                      >
                        Utilizar con la cuenta de Aimo
                      </Button>
                    )}
                  </>
                ) : (
                  <Typography fontSize={13} color='text.secondary'>
                    * Para integrar este Operado Logístico comunícate con Aimo
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Edit;
