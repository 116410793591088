import React, { useState } from 'react';
import styled from 'styled-components';
import { FlexContainer, Typography } from 'components/atoms';
import PortalTracking from './PortalTracking';
import TrackingFeedback from './FeedbackControl';
import Notifications from './Notifications';

type ViewType = 'portalTracking' | 'trackingFeedback' | 'notifications';

type TabProps = {
  view: ViewType;
  onClick: (view: ViewType) => void;
};

const list = [
  {
    name: 'Portal de Tracking',
    value: 'portalTracking' as const,
  },
  {
    name: 'Tracking Feedback',
    value: 'trackingFeedback' as const,
  },
  {
    name: 'Notificaciones',
    value: 'notifications' as const,
  },
];

const Option = styled(FlexContainer)<{ active: boolean }>`
  text-decoration: none;
  cursor: pointer;
  ${props =>
    props.active && `border-bottom: 2px solid ${props.theme.colors.primary}`}
`;

const Navigation: React.FC<TabProps> = ({ view, onClick }) => {
  return (
    <FlexContainer
      container
      height='100%'
      justify='space-between'
      alignItems='center'
    >
      <FlexContainer alignItems='center'>
        {list.map(option => {
          return (
            <FlexContainer
              key={option.name}
              padding='0 30px 0 0'
              onClick={() => onClick(option.value)}
            >
              <Option
                alignItems='center'
                padding='14px 0'
                active={view === option.value}
              >
                <Typography>{option.name}</Typography>
              </Option>
            </FlexContainer>
          );
        })}
      </FlexContainer>
    </FlexContainer>
  );
};

const ClientExperience: React.FC = () => {
  // Values
  const [view, setView] = useState<
    'portalTracking' | 'trackingFeedback' | 'notifications'
  >('portalTracking');

  return (
    <FlexContainer container direction='column' padding='30px 40px'>
      <Typography fontSize={19} fontWeight={600} margin='0 0 10px'>
        Experiencia del cliente
      </Typography>
      <Navigation view={view} onClick={newView => setView(newView)} />
      <FlexContainer container>
        {view === 'portalTracking' && <PortalTracking />}
        {view === 'trackingFeedback' && <TrackingFeedback />}
        {view === 'notifications' && <Notifications />}
      </FlexContainer>
    </FlexContainer>
  );
};

export default ClientExperience;
