import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import session from './session/reducers';
import user from './user/reducers';
import organization from './organization/reducers';
import sidebar from './sidebar/reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['session', 'organization', 'user'],
};

const rootReducer = combineReducers({
  session,
  user,
  organization,
  sidebar,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

type RootState = ReturnType<typeof rootReducer>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

const configureStore = () => {
  const middlewares = [thunk];
  const enhancers = [applyMiddleware(...middlewares)];
  const composeEnhancers = compose;

  const store = createStore(persistedReducer, composeEnhancers(...enhancers));
  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
