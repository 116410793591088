import { Session, SESSION_LOGIN, SESSION_LOGOUT } from './types';

export const setSession = (session: Session) => {
  return {
    type: SESSION_LOGIN,
    payload: session,
  };
};

export const logout = () => {
  return {
    type: SESSION_LOGOUT,
  };
};
