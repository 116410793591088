import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Dialog } from '@material-ui/core';
import { FlexContainer, Typography, Button, Icon } from 'components/atoms';
import { Spinner } from 'components/molecules';
import { DataTable } from 'components/organisms';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import { getReports } from 'services';
import NoData from 'assets/images/no_data.svg';
import CreateReport from './Create';

const ReportsList: React.FC = () => {
  const user = useTypedSelector(store => store.user);
  // API calls
  const [reports, fetchReports, loadingReports, pageData] = useRequest(
    getReports,
    []
  );

  // Values
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [page, setPage] = useState<number>(1);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [firstReportRender, setFirstReportRender] = useState<boolean>(false);

  const requestReports = useCallback(() => {
    const params = {
      page,
    };

    fetchReports(params);
  }, [fetchReports, page]);

  useEffect(() => {
    fetchReports({
      page,
    });
  }, [fetchReports, page]);

  useEffect(() => {
    if (loadingReports && page === 1) {
      setFirstReportRender(true);
    } else {
      setFirstReportRender(false);
    }
  }, [loadingReports, page]);

  const headers = useMemo(
    () => [
      {
        label: 'Fecha de solicitud',
        id: 'created_at',
        cell: row => row.created_at,
      },
      {
        label: 'Reporte',
        id: 'report_type',
        cell: row => <Typography>{row.job_name}</Typography>,
      },
      {
        label: 'Estado',
        id: 'status',
        cell: row => row.status,
      },
      {
        label: '',
        id: '',
        cell: row =>
          row.job_file ? (
            <Typography>
              <a href={row.job_file}>Descargar</a>
            </Typography>
          ) : (
            <Typography>Generando reporte</Typography>
          ),
      },
    ],
    []
  );

  return (
    <FlexContainer container padding='30px 40px' direction='column'>
      <FlexContainer
        container
        alignItems='center'
        justify='flex-end'
        margin='0 10px 30px 10px'
      >
        {user.permissions.includes('analytics.report.create') && (
          <Button
            color='primary'
            margin='0 0 0 20px'
            fontSize='13px'
            startIcon={<Icon icon='plus-icon' size={18} />}
            onClick={() => setOpenCreate(true)}
          >
            Crear Reporte
          </Button>
        )}
      </FlexContainer>
      {firstReportRender ? (
        <FlexContainer
          container
          padding='30px 40px'
          direction='column'
          justify='center'
          alignItems='center'
          height='80vh'
        >
          <Spinner height='350px' />
        </FlexContainer>
      ) : (
        <>
          {reports.length === 0 ? (
            <FlexContainer
              container
              direction='column'
              justify='center'
              alignItems='center'
              height='70vh'
            >
              <img src={NoData} alt='no_data' />
              <Typography color='text.secondary'>
                No hay reportes registrados
              </Typography>
            </FlexContainer>
          ) : (
            <DataTable
              headers={headers}
              data={reports}
              selectedRows={selectedRows}
              onSelectRow={rows => setSelectedRows(rows)}
              onChangePage={selectedPage => setPage(selectedPage)}
              totalPages={pageData.totalPages}
              totalItems={pageData.totalItems}
              pageSize={pageData.pageSize}
              page={page}
              loading={loadingReports}
              selectable
            />
          )}
        </>
      )}
      <Dialog
        fullWidth
        maxWidth='sm'
        open={openCreate}
        onClose={() => setOpenCreate(false)}
      >
        <CreateReport
          onCreate={useCallback(() => requestReports(), [requestReports])}
          onClose={() => setOpenCreate(false)}
        />
      </Dialog>
    </FlexContainer>
  );
};

export default ReportsList;
