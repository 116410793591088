import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Skeleton } from '@material-ui/lab';
import { Grid, Drawer } from '@material-ui/core';
import {
  FlexContainer,
  Icon,
  Typography,
  Status,
  Divider,
} from 'components/atoms';
import { Cell } from 'components/molecules';
import { getRoute } from 'services';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import statusColors from 'util/statusColors';
import {
  DetailProps,
  BlockProps,
  OrderProps,
  EstimatedHourProps,
} from './types';
import { CustomTooltip } from '../List';
import DetailOrder from '../../../Orders/OrdersList/Detail';

const details = [
  {
    label: 'Peso',
    icon: 'weight-icon',
    key: 'weight_label',
  },
  {
    label: 'Kilometros',
    icon: 'gauge-icon',
    key: 'estimated_distance_label',
  },
  {
    label: 'Tiempo',
    icon: 'hourglass-icon',
    key: 'estimated_duration',
  },
];

const CloseIcon = styled(FlexContainer)`
  position: absolute;
  top: 15px;
  left: 15px;
`;

const DetailBox = styled(FlexContainer)`
  border-top: 1px dashed ${props => props.theme.colors.borderColor};
  border-bottom: 1px dashed ${props => props.theme.colors.borderColor};
`;

const BorderedBottom = styled(FlexContainer)`
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
`;

const BorderedLeft = styled(FlexContainer)`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: calc(100% - 26px);
    left: 50%;
    bottom: 0;
    border-right: 1px solid ${props => props.theme.colors.borderColor};
  }
`;

const CustomLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  &:hover {
    text-decoration: underline;
  }
`;

const StyledTypography = styled(Typography)`
  white-space: break-spaces;
`;

const OrderDetailButton = styled(FlexContainer)`
  cursor: pointer;

  & > :hover {
    color: #4131d3;
    text-decoration: underline;
  }
`;

const EstimatedHourBlock: React.FC<EstimatedHourProps> = ({
  realHour,
  estimatedHour,
}) => (
  <FlexContainer
    justify='space-around'
    alignItems='center'
    width='70%'
    backgroundColor={THEME.colors.backgroundColor}
    borderColor={THEME.colors.borderColor}
    borderRadius='4px'
  >
    <FlexContainer
      direction='column'
      padding='5px 10px'
      backgroundColor={THEME.colors.backgroundColor}
    >
      <Typography color={THEME.colors.textSecondary}>Actual</Typography>
      <Typography margin='0 0 0 2px'>{realHour || '-'}</Typography>
    </FlexContainer>
    <FlexContainer height='60%'>
      <Divider orientation='vertical' color='#e4e4f7' />
    </FlexContainer>
    <FlexContainer
      direction='column'
      padding='5px 10px'
      backgroundColor={THEME.colors.backgroundColor}
    >
      <Typography color={THEME.colors.textSecondary}>Original</Typography>
      <Typography margin='0 0 0 2px'>{estimatedHour || '-'}</Typography>
    </FlexContainer>
  </FlexContainer>
);

const DetailBlock: React.FC<BlockProps> = ({ data, value }) => (
  <FlexContainer direction='column' margin='0 60px 0 0'>
    <FlexContainer alignItems='center' margin='0 0 5px'>
      <Icon icon={data.icon} size={18} margin='0 10px 0 0' />
      <Typography color='text.secondary'>{data.label}</Typography>
    </FlexContainer>
    <Typography margin='0 0 0 30px'>{value}</Typography>
  </FlexContainer>
);

const OrderDetail: React.FC<OrderProps> = ({ data, openOrderDetail }) => (
  <>
    {data.is_start ? (
      <>
        <FlexContainer container alignItems='stretch'>
          <BorderedLeft>
            {data.is_final_status ? (
              <>
                {data.status === 'completed' ? (
                  <Icon icon='check-blue-icon' size={26} />
                ) : (
                  <Icon
                    icon='cancel-icon'
                    size={26}
                    color={THEME.colors.error}
                  />
                )}
              </>
            ) : (
              <FlexContainer
                width='26px'
                height='26px'
                alignItems='center'
                justify='center'
                borderRadius='50px'
                backgroundColor='#FFFFFF'
                borderColor={THEME.colors.borderColor}
              >
                {data.current_sequence}
              </FlexContainer>
            )}
          </BorderedLeft>
          <FlexContainer
            container
            direction='column'
            borderRadius='4px'
            borderColor={THEME.colors.borderColor}
            backgroundColor={THEME.colors.backgroundColor}
            margin='0 0 30px 16px'
            padding='15px 20px'
          >
            <FlexContainer container direction='column' justify='space-between'>
              <FlexContainer container margin='0 0 10px 0'>
                <FlexContainer alignItems='center' margin='0 0 15px'>
                  <Icon icon='marker-bold-icon' size={18} margin='0 10px 0 0' />
                  <Typography fontWeight={600}>Origen</Typography>
                </FlexContainer>
              </FlexContainer>
              <Grid container spacing={3}>
                <Grid container alignItems='center' spacing={3}>
                  <Grid item xs={9}>
                    <Grid container item xs={6}>
                      <EstimatedHourBlock
                        realHour={data.current_eta}
                        estimatedHour={data.original_eta}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Status
                      color={statusColors[data.status] || statusColors.default}
                      label={data.status_label}
                      fontSize={12}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <FlexContainer direction='column'>
                    <Typography color={THEME.colors.textSecondary}>
                      Contacto:
                    </Typography>
                    <Typography margin='0 0 10px'>
                      {data.contact_person}
                    </Typography>
                  </FlexContainer>
                </Grid>
                <Grid item xs={8}>
                  <FlexContainer direction='column' margin='0 0 0 20px'>
                    <Typography color={THEME.colors.textSecondary}>
                      Dirección:
                    </Typography>
                    <Typography fontSize={13}>{data.address}</Typography>
                    <Typography fontSize={13}>{data.address_detail}</Typography>
                  </FlexContainer>
                </Grid>
              </Grid>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer margin='0 0 0 50px' width='70%'>
          <Divider orientation='horizontal' margin='10px 0px 30px 0px' />
        </FlexContainer>
      </>
    ) : (
      <FlexContainer container margin='0 0 30px' alignItems='stretch'>
        <BorderedLeft>
          {data.is_final_status ? (
            <>
              {data.status === 'completed' ? (
                <Icon icon='check-blue-icon' size={26} />
              ) : (
                <Icon icon='cancel-icon' size={26} color={THEME.colors.error} />
              )}
            </>
          ) : (
            <FlexContainer
              width='26px'
              height='26px'
              alignItems='center'
              justify='center'
              borderRadius='50px'
              backgroundColor='#FFFFFF'
              borderColor={THEME.colors.borderColor}
            >
              {data.current_sequence}
            </FlexContainer>
          )}
        </BorderedLeft>
        <FlexContainer direction='column' margin='0 0 0 15px' flex='1'>
          <FlexContainer
            container
            alignItems='center'
            justify='space-between'
            margin='0 0 10px'
          >
            <FlexContainer
              container
              justify='space-between'
              alignItems='center'
            >
              <Grid container spacing={3} alignItems='center'>
                <Grid container item xs={9}>
                  <Grid container item xs={6}>
                    <EstimatedHourBlock
                      realHour={data.current_eta}
                      estimatedHour={data.original_eta}
                    />
                    <FlexContainer alignItems='center' margin='0 0 0 15px'>
                      <FlexContainer
                        width='26px'
                        height='26px'
                        alignItems='center'
                        justify='center'
                        borderRadius='50px'
                        backgroundColor='#FFFFFF'
                        borderColor={THEME.colors.borderColor}
                      >
                        {data.sequence_in_route}
                      </FlexContainer>
                    </FlexContainer>
                  </Grid>
                  <Grid item xs='auto'>
                    {!data.time_window_end || !data.time_window_start ? (
                      <FlexContainer
                        height='100%'
                        direction='column'
                        padding='5px 10px'
                        alignItems='center'
                        justify='center'
                      >
                        <Typography color={THEME.colors.textSecondary}>
                          Sin Ventana Horaria
                        </Typography>
                      </FlexContainer>
                    ) : (
                      <FlexContainer direction='column' padding='5px 10px'>
                        <Typography color={THEME.colors.textSecondary}>
                          Ventana Horaria
                        </Typography>
                        <Typography margin='0 0 0 2px'>
                          {`${data.time_window_start} : ${data.time_window_end}`}
                        </Typography>
                      </FlexContainer>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Status
                    color={statusColors[data.status] || statusColors.default}
                    label={data.status_label}
                    fontSize={12}
                  />
                </Grid>
              </Grid>
            </FlexContainer>
          </FlexContainer>

          <FlexContainer container>
            <Grid container spacing={3} alignItems='center'>
              <Grid container item xs={9}>
                <Grid item xs={6}>
                  <FlexContainer direction='column'>
                    <Typography
                      color={THEME.colors.textSecondary}
                      margin='0 0 5px 0'
                    >
                      Contacto:
                    </Typography>
                    {data.contact_phone && (
                      <CustomTooltip
                        interactive
                        arrow
                        placement='top'
                        title={data.contact_phone}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            margin: '0 0 10px',
                          }}
                        >
                          <FlexContainer>
                            <Cell.Icon icon='phone' size='15px'>
                              <StyledTypography>
                                {data.contact_person}
                              </StyledTypography>
                            </Cell.Icon>
                          </FlexContainer>
                        </div>
                      </CustomTooltip>
                    )}
                  </FlexContainer>
                </Grid>
                <Grid item xs='auto'>
                  <FlexContainer direction='column' padding='5px 10px'>
                    <Typography
                      color={THEME.colors.textSecondary}
                      margin='0 0 5px 0'
                    >
                      {`${data.task_type.name}: `}
                    </Typography>

                    <FlexContainer container alignItems='flex-end'>
                      <OrderDetailButton onClick={() => openOrderDetail()}>
                        <Typography fontWeight={600} margin='0 8px 0 0'>
                          {data.order.reference_code
                            ? `${data.order.reference_code}`
                            : `${data.order.code}`}
                        </Typography>
                      </OrderDetailButton>
                    </FlexContainer>
                  </FlexContainer>
                </Grid>
              </Grid>
            </Grid>
          </FlexContainer>

          <FlexContainer
            container
            // alignItems='center'
            // justify='space-between'
            margin='0 0 20px'
          >
            <Grid container spacing={3} alignItems='center'>
              <Grid container item xs={9}>
                <Grid item xs={6}>
                  <FlexContainer direction='column'>
                    <Typography
                      color={THEME.colors.textSecondary}
                      margin='0 0 5px 0'
                    >
                      Dirección:
                    </Typography>
                    <Typography>{data.address}</Typography>
                    <Typography fontSize={13}>{data.address_detail}</Typography>
                  </FlexContainer>
                </Grid>
                {data.order.batch !== '' && (
                  <Grid item xs='auto'>
                    <FlexContainer direction='column' padding='5px 10px'>
                      <Typography
                        color={THEME.colors.textSecondary}
                        margin='0 0 5px 0'
                      >
                        Order batch
                      </Typography>
                      <Typography margin='0 8px 0 0'>
                        {data.order.batch}
                      </Typography>
                    </FlexContainer>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </FlexContainer>

          <FlexContainer container justify='space-between'>
            <BorderedBottom flex='1' alignItems='center' padding='0 0 20px'>
              <Grid container spacing={3} alignItems='center'>
                <Grid container item xs={9}>
                  <Grid item xs={3}>
                    {data.items_quantity && (
                      <FlexContainer alignItems='center' margin='0 10px 0 0'>
                        <Icon icon='list-icon' size={20} margin='0 5px 0 0' />
                        <Typography>{data.items_quantity} item(s)</Typography>
                      </FlexContainer>
                    )}
                    {data.order.dimensions !== '-' && (
                      <FlexContainer alignItems='center' margin='0 10px'>
                        <Icon icon='delivery-icon' size={16} margin='0 10px' />
                        {data.order.dimensions}
                      </FlexContainer>
                    )}
                    {data.order.distance !== '-' && (
                      <FlexContainer alignItems='center'>
                        <Icon icon='gauge-icon' size={16} margin='0 5px 0 0' />
                        {data.order.distance}
                      </FlexContainer>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <FlexContainer>
                    <CustomLink target='_blank' href={data.order.tracking_link}>
                      <Icon
                        icon='marker-bold-icon'
                        size={16}
                        margin='0 5px 0 0'
                      />
                      Ver Tracking
                    </CustomLink>
                  </FlexContainer>
                </Grid>
              </Grid>
            </BorderedBottom>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    )}
  </>
);

const RouteDetail: React.FC<DetailProps> = ({ data, onClose }) => {
  const [routeDetail, fetchDetail, loadingDetail] = useRequest(getRoute);

  // Values
  const [detailData, setDetailData] = useState({});
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  useEffect(() => {
    fetchDetail(data.id);
  }, [fetchDetail]);

  return (
    <>
      <FlexContainer
        container
        minWidth='700px'
        position='relative'
        padding='40px 50px 50px'
        direction='column'
      >
        <CloseIcon onClick={() => onClose()}>
          <Icon
            icon='cancel-icon'
            color={THEME.colors.placeholderColor}
            size={25}
          />
        </CloseIcon>
        <FlexContainer
          container
          direction='column'
          padding='0 0 0 30px'
          margin='0 0 20px'
        >
          <FlexContainer
            container
            alignItems='center'
            justify='space-between'
            margin='0 0 20px'
          >
            <Typography fontSize={16}>Código de ruta</Typography>
            <Typography fontSize={16} fontWeight={600}>
              {data.code}
            </Typography>
          </FlexContainer>

          <DetailBox container padding='10px 0' justify='center'>
            {details.map(detail => (
              <DetailBlock
                key={detail.key}
                data={detail}
                value={data[detail.key]}
              />
            ))}
          </DetailBox>
        </FlexContainer>

        {!loadingDetail ? (
          <>
            {routeDetail.tasks &&
              routeDetail.tasks.map(task => (
                <OrderDetail
                  key={task.id}
                  data={task}
                  openOrderDetail={() => {
                    setDetailData(task);
                    setOpenDetail(true);
                  }}
                />
              ))}
          </>
        ) : (
          <>
            <FlexContainer container margin='0 0 30px'>
              <Skeleton variant='rect' width='100%' height='150px' />
            </FlexContainer>
            <FlexContainer container margin='0 0 30px'>
              <Skeleton variant='rect' width='100%' height='150px' />
            </FlexContainer>
          </>
        )}
      </FlexContainer>

      <Drawer
        anchor='right'
        open={openDetail}
        onClose={() => {
          setOpenDetail(false);
        }}
      >
        <DetailOrder
          data={detailData}
          onClose={() => {
            setOpenDetail(false);
          }}
          routeCode={data.code}
        />
      </Drawer>
    </>
  );
};

export default RouteDetail;
