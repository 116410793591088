import React from 'react';
import styled from 'styled-components';
import normalFeedback from 'assets/images/feedback/feedback-normal.svg';
import goodFeedback from 'assets/images/feedback/feedback-good.svg';
import badFeedback from 'assets/images/feedback/feedback-bad.svg';
import disabledFeedback from 'assets/images/feedback/feedback-disabled.svg';
import { FlexContainer, Typography } from 'components/atoms';
import { FeedbackProps } from './types';

const FeedbackIcon = styled.img<{ icon: string }>`
  width: 40px;
  height: 40px;
  background-image: url(${props => props.icon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 100px;
`;

const Feedback: React.FC<FeedbackProps> = ({ feedbackText }) => {
  let feedbackIcon;
  let feedbackDescription;
  switch (feedbackText) {
    case 'good':
      feedbackIcon = goodFeedback;
      feedbackDescription = 'Buena';
      break;
    case 'normal':
      feedbackIcon = normalFeedback;
      feedbackDescription = 'Regular';
      break;
    case 'bad':
      feedbackIcon = badFeedback;
      feedbackDescription = 'Mala';
      break;
    default:
      feedbackIcon = disabledFeedback;
      feedbackDescription = 'Sin feedback';
      break;
  }
  return (
    <FlexContainer direction='column' alignItems='center'>
      <FeedbackIcon icon={feedbackIcon} />
      <Typography
        fontSize='12px'
        fontWeight='500'
        margin='4px 0 0'
        color='secondary'
      >
        {feedbackDescription}
      </Typography>
    </FlexContainer>
  );
};

export default Feedback;
