import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Drawer, Dialog } from '@material-ui/core';
import {
  Button,
  FlexContainer,
  Icon,
  Typography,
  // Pill,
} from 'components/atoms';
import { Cell } from 'components/molecules';
import { SearchWithFilter, DataTable } from 'components/organisms';
import { getBranchOffices, getOrganization } from 'services';
import { useTypedSelector } from 'store';
import useRequest from 'hooks/useRequest';
import THEME from 'util/styledTheme';
import EditOffice from './Edit';

const options = [
  {
    name: 'Nombres',
    id: 'name',
  },
  {
    name: 'Ciudad',
    id: 'city',
  },
];

const BranchOffice: React.FC = () => {
  const user = useTypedSelector(state => state.user);
  const selectedOrganization = useTypedSelector(
    state => state.organization.selectedOrganization
  );

  // API calls
  const [organization, fetchOrganization] = useRequest(getOrganization);
  const [
    branchOffices,
    fetchBranchOffices,
    loadingOffices,
    pageData,
  ] = useRequest(getBranchOffices, []);

  // Values
  const [searchInput, setSearchInput] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [editData, setEditData] = useState(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openChangePlan, setOpenChangePlan] = useState<boolean>(false);

  const scriptURL =
    'https://script.google.com/macros/s/AKfycbxKyOjxjSquJYbnUSFGD5nYUixC2GihoI4FWYLZ3K5A8vBdKlZK5py2EYWXG3mrWdlF/exec';

  const requestOffices = useCallback(() => {
    const params = {
      page,
    };

    if (searchFilter !== '') {
      params[searchFilter] = searchInput;
    }

    fetchBranchOffices(params);
    fetchOrganization(selectedOrganization.id);
  }, [fetchBranchOffices, page, searchInput, searchFilter]);

  const handleChangePlan = () => {
    const message = 'Quisiera cambiar de plan por favor.';

    const data = `?callback=ctrlq&Nombre=${user.full_name}&Email=${
      user.email
    }&Telefono=${user.phone}&Organizacion=${organization.tradename}&Pais=${
      organization.country_name
    }&Mensaje=${message}&Fecha=${new Date().toLocaleString()}`;
    fetch(`${scriptURL}${data}`, { method: 'POST', mode: 'no-cors' })
      .then(() => {
        console.log('Successful!');
      })
      .catch(error => console.error('Error!', error.message));

    window.open(
      `https://wa.me/51946565757?text=¡Hola! Soy ${user.full_name} de la organización ${organization.business_name} (${organization.id}) y quisiera cambiar de plan por favor.`
    );
  };

  useEffect(() => {
    requestOffices();
  }, [page]);

  const headers = useMemo(
    () => [
      {
        label: (
          <Typography margin='0 0 0 20px' color='#6d7996'>
            Nombres
          </Typography>
        ),
        id: 'name',
        cell: row => (
          <FlexContainer margin='0 0 0 20px'>
            <Cell.Link
              onClick={() => {
                if (user.permissions.includes('core.branch_office.update')) {
                  setEditData(row);
                  setOpenEdit(true);
                }
              }}
            >
              {row.name}
            </Cell.Link>
          </FlexContainer>
        ),
      },
      {
        label: 'Código',
        id: 'reference_code',
        cell: row => row.reference_code || '-',
      },
      {
        label: 'Dirección',
        id: 'address',
        cell: row => row.address,
      },
      {
        label: 'Ciudad',
        id: 'city',
        cell: row => (row.city ? row.city.name : '-'),
      },
      // {
      //   label: 'Coberturas',
      //   id: 'city',
      //   cell: row => (row.coverages.length > 0
      //     ? (row.coverages.map(coverage => (
      //       <Pill key={coverage.id} padding='3px 10px' margin='5px'>
      //         {coverage.name}
      //       </Pill>
      //     ))
      //     ) : '-'
      //   ),
      // },
    ],
    []
  );

  return (
    <FlexContainer container padding='20px 15px' direction='column'>
      <FlexContainer
        container
        direction='column'
        margin='0 0 20px'
        padding='20px'
        backgroundColor='#FFFFFF'
        borderRadius='4px'
        borderColor={THEME.colors.borderColor}
      >
        <FlexContainer container justify='space-between'>
          <FlexContainer container>
            <SearchWithFilter
              filterLabel='Buscar por'
              filterValue={searchFilter}
              onChangeFilterValue={value => setSearchFilter(value)}
              options={options}
              onChangeSearchValue={value => setSearchInput(value)}
              searchValue={searchInput}
              onSubmit={() => requestOffices()}
              maxWidth='520px'
              placeholder='Buscar'
              inputWidth='360px'
            />
            <Button
              variant='contained'
              color='primary'
              margin='0 20px'
              onClick={() => requestOffices()}
            >
              Buscar
            </Button>
          </FlexContainer>
          {user.permissions.includes('core.branch_office.create') && (
            <Button
              color='primary'
              margin='0 0 0 20px'
              fontSize='13px'
              startIcon={<Icon icon='plus-icon' size={18} />}
              onClick={() => {
                if (organization.pricing_plan.code === 'ecommerce_starter') {
                  setOpenChangePlan(true);
                } else {
                  setOpenEdit(true);
                }
              }}
            >
              Crear Sucursal
            </Button>
          )}
        </FlexContainer>
      </FlexContainer>

      <DataTable
        headers={headers}
        data={branchOffices}
        onChangePage={selectedPage => setPage(selectedPage)}
        totalPages={pageData.totalPages}
        totalItems={pageData.totalItems}
        pageSize={pageData.pageSize}
        page={page}
        loading={loadingOffices}
      />

      <Drawer
        anchor='right'
        open={openEdit}
        onClose={() => {
          setEditData(null);
          setOpenEdit(false);
        }}
      >
        <FlexContainer
          width='1300px'
          position='relative'
          padding='20px 40px 50px'
          direction='column'
        >
          <EditOffice
            data={editData}
            onCreate={useCallback(() => requestOffices(), [requestOffices])}
            onClose={() => {
              setEditData(null);
              setOpenEdit(false);
            }}
          />
        </FlexContainer>
      </Drawer>

      <Dialog open={openChangePlan} onClose={() => setOpenChangePlan(false)}>
        <FlexContainer
          padding='30px'
          direction='column'
          alignItems='center'
          justify='center'
        >
          <Typography
            color={THEME.colors.primary}
            fontSize={15}
            margin='0 0 20px'
          >
            Permitido solo en plan Grow o PRO
          </Typography>
          <Typography margin='0 0 30px' fontSize={13}>
            ¿Quiere cambiar de plan?
          </Typography>
          <FlexContainer container alignItems='center' justify='space-between'>
            <Button
              variant='contained'
              color='primary'
              fontSize='13px'
              onClick={() => handleChangePlan()}
            >
              Cambiar
            </Button>

            <Button fontSize='14px' onClick={() => setOpenChangePlan(false)}>
              Cancelar
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Dialog>
    </FlexContainer>
  );
};

export default BranchOffice;
