import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import useRequest from 'hooks/useRequest';
import { FlexContainer } from 'components/atoms';
import { excelParseProducts, getBranchOffices } from 'services';
import { getLocalStorageClientId } from 'util/functions';
import Upload from './Upload';
import Success from './Success';
import Confirm from './Confirm';
import { MassiveProductChargeProps } from './types';

const Massive: React.FC<MassiveProductChargeProps> = ({
  clientId,
  hasClients,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [currentView, setCurrentView] = useState<string>('1');
  // Upload API calls
  const [stockReport, postExcel, , , errorStockReport] = useRequest(
    excelParseProducts
  );
  const [branchOffices, fetchBranchOffices] = useRequest(getBranchOffices);
  // Values
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchBranchOffices();
  }, []);

  useEffect(() => {
    if (!isEmpty(stockReport)) {
      if (stockReport.code === 201) {
        setLoading(false);
        setCurrentView('3');
      } else if (stockReport.code === 400) {
        setLoading(false);
        setCurrentView('2');
      } else {
        enqueueSnackbar('Ha ocurrido un error, inténtelo de nuevo.', {
          variant: 'error',
        });
      }
    }
  }, [stockReport]);

  const handleDropFile = acceptedFiles => {
    const clientIdNumber =
      getLocalStorageClientId() !== null
        ? getLocalStorageClientId().toString()
        : clientId !== null
        ? clientId.toString()
        : null;

    const branchOfficesCodes = branchOffices
      .map(({ reference_code }) => reference_code)
      .join(',');

    if (!hasClients || (hasClients && clientIdNumber)) {
      const formData = new FormData();
      formData.append('branch_offices', branchOfficesCodes);
      formData.append('client_id', clientIdNumber);
      formData.append('excel_file', acceptedFiles[0]);

      setLoading(true);
      postExcel(formData);
    } else {
      enqueueSnackbar('Debe seleccionar al menos un cliente', {
        variant: 'error',
      });
    }
  };

  const handleNewMassive = () => {
    setCurrentView('1');
  };

  const renderView = () => {
    switch (currentView) {
      case '1':
        return <Upload onDrop={handleDropFile} loading={loading} />;
      case '2':
        return (
          <Confirm
            data={stockReport.errors}
            onNewMassive={() => handleNewMassive()}
          />
        );
      case '3':
        return <Success onNewMassive={() => handleNewMassive()} />;
      default:
        return null;
    }
  };

  return (
    <FlexContainer
      container
      padding='30px 40px'
      direction='column'
      height='100%'
    >
      {renderView()}
    </FlexContainer>
  );
};

export default Massive;
